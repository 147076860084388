/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable no-eval */
import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import admin, { getEntities, getProductionLine, colors, getMenuItem, getNameForQuantity, menuItemReferences, getMenuItemById, getMenuCategoryById, addMenuItem, getFeature } from "../admin";
import { I18n } from "react-redux-i18n";
import SortedSet from "js-sorted-set";
import auth, { choiceDialog2, featureEnabled, getLocale, post, messageDialog, get, ajaxCallStart, ajaxCallEnd, isTop, _floatTableHead, _unfloatTableHead, getLocale2, touchStart, settingEnabled, confirmDialog, getImageById, getString, getGlobal, startpoll } from "../auth";
import utils from "../menuitem-utils";
import { showMenuItemCategory } from "../chooseMenuItem";
import Cleave from "cleave.js";
import { saveAs } from "file-saver";
import { crop, onPhotoDataSuccess } from "../camera-util";
import Chart, { platform } from "chart.js";
import "bootstrap-colorpicker";
import orderListUtil, { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import removeAccents from "remove-accents";
import JsBarcode from "jsbarcode";
import Color from "color";
import languages from "../../langs/languages";
import MenuItemGraph from "../../main/menuItemGraph/MenuItemGraph";
import { find } from "lodash";
import CategoryTemplate from "../../components/CategoryTemplate";
import { useMemo } from "react";
const { admin_local } = languages;

function darken(color, ratio) {
  return new Color(color).darken(ratio);
}

///var timing = ["inherit", "asap", "appetizer", "maincourse", "desert", "last"];
export const availableQuantities = {
  Meal: ["pcs", "g", "dkg", "kg", "ml", "cl", "dl", "l", "day", "hour", "min", "none"],
  Drink: ["pcs", "g", "dkg", "kg", "ml", "cl", "dl", "l", "day", "hour", "min", "none"],
  all: ["pcs", "ml", "cl", "dl", "l", "g", "dkg", "kg", "day", "hour", "min", "none"]
};

if (typeof localStorage.languageSelected == "undefined" || localStorage.languageSelected == "undefined") localStorage.languageSelected = localStorage.language;

if (typeof localStorage.menuitems_chart_height == "undefined") {
  localStorage.menuitems_chart_height = 300;
}

export const menuitemutil = {
  entityType: undefined,
  entitytype: undefined,
  filter: {},

  serverSideMessageHandler: message => {
    if (message.type == "timeout" && message.message == "true") return;
    //console.log(message.message, message);
    if (message.type == "refresh" && message.message == "menu changed") {
      $("button#refresh").addClass("btn-danger");
      $("button#refresh").addClass("blink");
    }
  },

  recoverFromEventSourceError: () => {

  },

  initialize: function (props) {

    startpoll(menuitemutil.serverSideMessageHandler, [
      {
        Restaurant: sessionStorage.restaurantSelected,
        Menu: "*"
      }
    ], undefined, menuitemutil.recoverFromEventSourceError);

    connectedRestaurants = {}
    menuitemutil.props = props;
    menuitemutil.filter = {};

    menuitemutil.reload = menuitemutil._reload;
    menuitemutil.updateMenu = menuitemutil._updateMenu;

    menuitemutil.getBarcodes();

    $("#menuItemsFreezeTable thead th input[type = 'checkbox']").prop("indeterminate", true);

    menuitemutil.menuItems = null;
    getEntities("Stores", function (data) {
      admin.stores = data;
      if (typeof localStorage.menuitems_start == "undefined") {
        var t = moment();
        localStorage.menuitems_end = moment()
          .toDate()
          .getTime();
        localStorage.menuitems_start = moment()
          .subtract(1, "month")
          .toDate()
          .getTime();
      }
      var start = moment(Number(localStorage.menuitems_start));
      var end = moment(Number(localStorage.menuitems_end));

      function cb(start, end) {
        $("#daterange span").html(start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"));
      }
      var ranges = {};
      ranges[I18n.t("admin_local.today")] = [moment(), moment()];
      ranges[I18n.t("admin_local.yesterday")] = [moment().subtract(1, "days"), moment().subtract(1, "days")];
      ranges[I18n.t("admin_local.last_7_days")] = [moment().subtract(6, "days"), moment()];
      ranges[I18n.t("admin_local.last_30_days")] = [moment().subtract(29, "days"), moment()];
      ranges[I18n.t("admin_local.last_3_months")] = [
        moment()
          .subtract(2, "month")
          .startOf("month"),
        moment()
      ];
      ranges[I18n.t("admin_local.this_month")] = [moment().startOf("month"), moment().endOf("month")];
      ranges[I18n.t("admin_local.last_month")] = [
        moment()
          .subtract(1, "month")
          .startOf("month"),
        moment()
          .subtract(1, "month")
          .endOf("month")
      ];

      $('#from_date.datepicker').datepicker({
        format: 'yyyy-mm-dd',
        uiLibrary: 'bootstrap4'
      });
      $('#to_date.datepicker').datepicker({
        format: 'yyyy-mm-dd',
        uiLibrary: 'bootstrap4'
      });
      $('#m_from_date.datepicker').datepicker({
        format: 'yyyy-mm-dd',
        uiLibrary: 'bootstrap4'
      });
      $('#m_to_date.datepicker').datepicker({
        format: 'yyyy-mm-dd',
        uiLibrary: 'bootstrap4'
      });

      $("#cntak_main_category").on('change', event => {
        menuitemutil.updateNtakCategories("c");
      });
      $("#mntak_main_category").on('change', event => {
        menuitemutil.updateNtakCategories("m");
      });


      $("#daterange").daterangepicker(
        {
          timePicker: false,
          autoApply: true,
          startDate: start,
          endDate: end,
          locale: {
            format: "MMM/DD",
            customRangeLabel: I18n.t("admin_local.customRange")
          },
          showButtonPanel: true,
          drops: "up",
          ranges: ranges
        },
        function (start, end, label) {
          localStorage.menuitems_start = start._d.getTime();
          localStorage.menuitems_end = end._d.getTime();
          cb(moment(Number(localStorage.menuitems_start)), moment(Number(localStorage.menuitems_end)));
          if (isCategory) populateCategoryCharts();
          else populateCharts();
        }
      );

      if (typeof localStorage.menuitems_range != "undefined") {
        $("[name='range'][id='" + localStorage.menuitems_range + "']")
          .parent()
          .button("toggle");
      }

      $("[name='range']").change(event => {
        localStorage.menuitems_range = $("[name='range']:checked").attr("id");
        drawChart();
      });

      $(".resizable").resizable({
        handles: "n",
        height: 2,
        resize: function () {
          var height = $(this)
            .css("height")
            .split("px")[0];
          localStorage.menuitems_chart_height = height;
          $(this).css("top", 0);
        }
      });

      cb(start, end);
    });
  },

  updateNtakCategories: (prefix, modal = $(".modal.show"), data) => {
    const main_category_item = $("#" + prefix + "ntak_main_category");
    const sub_category_item = $("#" + prefix + "ntak_sub_category");
    var main_category_id = main_category_item.val();

    var parentMenuCategoryId = data?.menuCategoryId | 0;

    if (parentMenuCategoryId > 0) {
      var parentMenuCategory = getMenuCategory(data);
      if (parentMenuCategory && parentMenuCategory.defaultMainCategoryId) {
        const main_category = admin_local.ntak.main_categories.find(c => c.id == parentMenuCategory.defaultMainCategoryId);
        if (main_category) {
          main_category_item.find("option[value='0']").html(admin_local.inherit + " (" + main_category.name + ")")
          if (parentMenuCategory.defaultSubCategoryId) {
            const sub_category = main_category.sub_categories.find(c => c.id == parentMenuCategory.defaultSubCategoryId);
            if (sub_category)
              sub_category_item.find("option[value='0']").html(admin_local.inherit + " (" + sub_category.name + ")")
          }
        }
      }

      if (!main_category_id) {
        main_category_id = parentMenuCategory.defaultMainCategoryId;
      }

    }


    const main_category = admin_local.ntak.main_categories.find(c => c.id == main_category_id);
    if (main_category) {
      sub_category_item.find("option[value != '0']").remove();
      main_category.sub_categories.forEach(sub => {
        $("<option value='" + sub.id + "'>" + sub.name + "</option>").appendTo(sub_category_item);
      })
    }
  },

  _reload: function (data) {
    $("button#refresh").removeClass('blink');
    $("button#refresh").removeClass('btn-danger');
    $('[data-toggle="tooltip"]').tooltip("hide");
    const def = $.Deferred();
    if (data && data.entityType) {
      menuitemutil.updateMenu(data)
      def.resolve();
      return def.promise();
    }
    if (data && data.length) {
      var ok = false;
      data.forEach(d => {
        if (d.entityType) {
          if (menuitemutil.updateMenu(d))
            ok = true;
        }
      })
      if (ok) {
        def.resolve();
        return def.promise();
      }
    }


    if (processMenuItems) {
      admin.getMenuItems((data) => {

        menuitemutil.filterByType(data);

        refreshConnectedRestaurants().done(() => processMenuItems(data));
        def.resolve()
      }, undefined, menuitemutil.filter.deleted);
    } else
      def.resolve();
    admin.getLabels(function (data) {
      admin.labels = data;
    });
    return def.promise();
  },

  filterByType: (data) => {
    if (data.children) {
      data.children = data.children.filter(node => node.recordState == "ACTIVE" || menuitemutil.filter.deleted);
      data.children.forEach(menuitemutil.filterByType);
    }
  },

  getRecursiveReferencers: (d, data) => {
    try {
      menuItemReferences(d, ['mandatory']).filter(dd => data.filter(d => d.id == dd.id && d.entityType == dd.entityType).length === 0).forEach(dd => {
        data.push(dd.menuItem);
        menuitemutil.getRecursiveReferencers(dd.menuItem, data);
      });
    } catch (ex) {

    }

    return data;

  },

  getBarcodes() {
    get("adminService/" + sessionStorage.restaurantSelected + "/getBarcodes", sessionStorage.restaurantSelected).done(data => {
      if (data === null) {
        console.log("Failed to get barcodes!");
      } else {
        menuitemutil.barcodes = data;
      }
    });
  },

  findBarcodes(term) {
    var t = menuitemutil.barcodes.map(b => { return { text: b.name + " " + b.quantity + "cl (" + b.id + ")", value: "" + b.id } });
    const result = [];
    t.filter(b => b.text && b.value).filter(b => b.text.indexOf(term) >= 0).forEach(b => result.push(b));
    return result;
  },

  getFlatMenuItems: (array = [], categories = admin.categories.children) => {
    categories.forEach(c => {
      if (c.entityType == menuitemutil.entityType || (!menuitemutil.entityType && c.entityType.indexOf("Category") === -1))
        array.push(c);
      else if (c.children)
        menuitemutil.getFlatMenuItems(array, c.children);
    });
    return array;
  },

  getMenuItems: function (search, proportional = true, type) {
    if (!search)
      return;
    //s = search;
    var set = new SortedSet({
      comparator: function (a, b) {
        if (a.value.split(";")[0] != b.value.split(";")[0])
          return a.value.localeCompare(b.value);
        if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase())) return -1;
        if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase())) return 1;
        return a.text.localeCompare(b.text);
      }
    });
    var menuItem = menuitemutil._getMenuItems(search, proportional, set, admin.categories.activeMenuCategories ? admin.categories.activeMenuCategories : admin.categories.children, type);
    if (menuItem == "") {
      return null;
    }
    var result = [];
    set.forEach(function (e) {
      result[result.length] = e;
    });
    //filter our recursive elements
    if (menuitemutil.references)
      result = result.filter(r => {
        let rr = r.value.split(";");
        return menuitemutil.references.filter(d => d != null && Number(rr[2]) == d.id && rr[4] == d.entityType).length === 0
      })
    return result;
  },

  getPath: (item, base = admin.categories) => {
    if (!item) return "";
    return (item.menuCategoryId ? (menuitemutil.getPath(menuitemutil.getMenuCategory(item.menuCategoryId, base), base) + "/") : "") + getLocale(item.name) + (item.manufacturer ? " (" + item.manufacturer + ")" : "");
  },
  getOrder: (item, base = admin.categories) => {
    if (!item) return "";
    return (item.menuCategoryId ? (menuitemutil.getMenuCategory(item.menuCategoryId, base).order * 1000 + "/") : "") + getLocale(item.name) + (item.manufacturer ? " (" + item.manufacturer + ")" : "");
  },

  openItem: item => {
    const value = $(item.currentTarget).attr("data-value").split(";");
    const type = value[0];
    const id = value[2];
    if (type === "menuItem") {
      const menuItem = getMenuItem(id);
      const oldModal = $('.modal.show');
      const modal = $('#editMenuItem.modal');
      if (menuItem) {
        const newContent = JSON.stringify(saveMenuItem(undefined, true, true));
        if (newContent === menuitemutil.oldMenuItemData) {
          populateEditMenuItem(modal, "edit", menuItem, menuItem.menuCategoryId);
        } else {
          choiceDialog2(I18n.t("local.warning"), I18n.t("admin_local.product_modified_save_it"), [I18n.t("admin_local.drop"), I18n.t("admin_local.save")]).done(choice => {
            switch (choice) {
              case 0:
                populateEditMenuItem(modal, "edit", menuItem, menuItem.menuCategoryId);
                break;
              case 1:
                saveMenuItem((data) => {
                  menuitemutil.reload(data);
                  populateEditMenuItem(modal, "edit", menuItem, menuItem.menuCategoryId);
                }, true, false);
                break;
              default:
            }
          })
        }
      }
    } else {
      const menuCategory = menuitemutil.getMenuCategory(id);
      const oldModal = $('.modal.show');
      const modal = $('#editMenuCategory.modal');
      if (menuCategory) {
        const newContent = JSON.stringify(saveMenuItem(undefined, true, true));
        if (newContent === menuitemutil.oldMenuItemData) {
          oldModal.modal("hide");
          modal.modal("show");
          populateEditCategory(modal, "edit", menuCategory, menuCategory.menuCategoryId);
        } else {
          choiceDialog2(I18n.t("local.warning"), I18n.t("admin_local.product_modified_save_it"), [I18n.t("admin_local.drop"), I18n.t("admin_local.save")]).done(choice => {
            switch (choice) {
              case 0:
                oldModal.modal("hide");
                modal.modal("show");
                populateEditCategory(modal, "edit", menuCategory, menuCategory.menuCategoryId);
                break;
              case 1:
                saveMenuItem((data) => {
                  menuitemutil.reload(data);
                  oldModal.modal("hide");
                  modal.modal("show");
                  populateEditCategory(modal, "edit", menuCategory, menuCategory.menuCategoryId);
                }, true, false);
                break;
              default:
            }
          })
        }
      }
    }
  },

  openItemCategory: item => {
    const value = $(item.currentTarget).attr("data-value").split(";");
    const type = value[0];
    const id = value[2];
    if (type === "menuItem") {
      const menuItem = getMenuItem(id);
      const oldModal = $('.modal.show');
      const modal = $('#editMenuItem.modal');
      if (menuItem) {
        const newContent = JSON.stringify(saveMenuCategory(undefined, true));
        if (newContent === menuitemutil.oldMenuCategoryData) {
          if (oldModal.attr("id") !== "editMenuItem") {
            oldModal.modal("hide");
            modal.modal("show");
          }
          populateEditMenuItem(modal, "edit", menuItem, menuItem.menuCategoryId);
        } else {
          choiceDialog2(I18n.t("local.warning"), I18n.t("admin_local.product_modified_save_it"), [I18n.t("admin_local.drop"), I18n.t("admin_local.save")]).done(choice => {
            switch (choice) {
              case 0:
                if (oldModal.attr("id") !== "editMenuItem") {
                  oldModal.modal("hide");
                  modal.modal("show");
                }
                populateEditMenuItem(modal, "edit", menuItem, menuItem.menuCategoryId);
                break;
              case 1:
                saveMenuItem((data) => {
                  menuitemutil.reload(data);
                  if (oldModal.attr("id") !== "editMenuItem") {
                    oldModal.modal("hide");
                    modal.modal("show");
                  }
                  populateEditMenuItem(modal, "edit", menuItem, menuItem.menuCategoryId);
                }, true, false);
                break;
              default:
            }
          })
        }
      }
    } else {
      const menuCategory = menuitemutil.getMenuCategory(id);
      const oldModal = $('.modal.show');
      const modal = $('#editMenuCategory.modal');
      if (menuCategory) {
        const newContent = JSON.stringify(saveMenuCategory(undefined, true, true));
        if (newContent === menuitemutil.oldMenuCategoryData) {
          populateEditCategory(modal, "edit", menuCategory, menuCategory.menuCategoryId);
        } else {
          choiceDialog2(I18n.t("local.warning"), I18n.t("admin_local.product_modified_save_it"), [I18n.t("admin_local.drop"), I18n.t("admin_local.save")]).done(choice => {
            switch (choice) {
              case 0:
                populateEditCategory(modal, "edit", menuCategory, menuCategory.menuCategoryId);
                break;
              case 1:
                saveMenuItem((data) => {
                  menuitemutil.reload(data);
                  populateEditCategory(modal, "edit", menuCategory, menuCategory.menuCategoryId);
                }, true, false);
                break;
              default:
            }
          })
        }
      }
    }
  },

  previousItem: () => {
    var modal = $("#editMenuItem.modal");
    var node = menuitemutil.menuItems.getActiveNode().getPrevSibling();
    const newContent = JSON.stringify(saveMenuItem(undefined, true, true));
    if (node) {
      if (newContent === menuitemutil.oldMenuItemData) {
        node.setActive(true);
        populateEditMenuItem(modal, "edit", menuitemutil.getMenuItem(node.data.id), node.data.menuCategoryId);
      } else {
        choiceDialog2(I18n.t("local.warning"), I18n.t("admin_local.product_modified_save_it"), [I18n.t("admin_local.drop"), I18n.t("admin_local.save")]).done(choice => {
          switch (choice) {
            case 0:
              node.setActive(true);
              populateEditMenuItem(modal, "edit", menuitemutil.getMenuItem(node.data.id), node.data.menuCategoryId);
              break;
            case 1:
              saveMenuItem((data) => {
                menuitemutil.reload(data);
                node.setActive(true);
                populateEditMenuItem(modal, "edit", node.data, node.data.menuCategoryId);
              }, true, false);
              break;
            default:
          }
        })
      }
    }
  },

  nextItem: () => {
    var modal = $("#editMenuItem.modal");
    var node = menuitemutil.menuItems.getActiveNode().getNextSibling();
    const newContent = JSON.stringify(saveMenuItem(undefined, true, true));
    if (node) {
      if (newContent === menuitemutil.oldMenuItemData) {
        node.setActive(true);
        populateEditMenuItem(modal, "edit", menuitemutil.getMenuItem(node.data.id), node.data.menuCategoryId);
      } else {
        choiceDialog2(I18n.t("local.warning"), I18n.t("admin_local.product_modified_save_it"), [I18n.t("admin_local.drop"), I18n.t("admin_local.save")]).done(choice => {
          switch (choice) {
            case 0:
              node.setActive(true);
              populateEditMenuItem(modal, "edit", menuitemutil.getMenuItem(node.data.id), node.data.menuCategoryId);
              break;
            case 1:
              saveMenuItem((data) => {
                menuitemutil.reload(data);
                node.setActive(true);
                populateEditMenuItem(modal, "edit", node.data, node.data.menuCategoryId);
              }, true, false);
              break;
            default:
          }
        })
      }
    }
  },

  _getMenuItems: function (s, proportional, set, categories, type) {
    var local = true;
    var takeaway = true;
    categories.forEach(function (item) {
      s = s.replace(/ /g, ".*");
      var regexp = new RegExp(s, "ig");
      if (typeof item.activeMenuItems == "undefined" && typeof item.children == "undefined") {
        var name = menuitemutil.getPath(item);
        var t = true; // item.entityType.startsWith(entityType);
        var r = regexp.test(name);
        if (t && r) {
          if (item.availableQuantities.length == 1)
            set.insert({
              value: "menuItem;0;" + item.id + ";" + (type == "mandatory" ? "" : item.availableQuantities[0].quantity) + ";" + item.entityType,
              text: menuitemutil.getPath(item) + menuitemutil.getLocalOption("", local) + menuitemutil.getTakeawayOption("", local) + menuitemutil.getProportionalOption("", proportional) + " " + menuitemutil.getQuantityLabel2(type, Math.round(1000 * parseFloat(item.availableQuantities[0].quantity)) / 1000, item.quantityType, getLocale(item.availableQuantities[0].name))
            });
          else if (item.availableQuantities.length == 0)
            set.insert({
              value: "menuItem;0;" + item.id + ";" + (type == "mandatory" ? "" : 1) + ";" + item.entityType,
              text: menuitemutil.getPath(item) + menuitemutil.getLocalOption("", local) + menuitemutil.getTakeawayOption("", local) + menuitemutil.getProportionalOption("", proportional) + " " + menuitemutil.getQuantityLabel2(type, 1, item.quantityType)
            });
          else {
            item.availableQuantities.forEach(function (q) {
              set.insert({
                value: "menuItem;0;" + item.id + ";" + (type == "mandatory" ? "" : q.quantity) + ";" + item.entityType,
                text: menuitemutil.getPath(item) + menuitemutil.getLocalOption("", local) + menuitemutil.getTakeawayOption("", local) + menuitemutil.getProportionalOption("", proportional) + " " + menuitemutil.getQuantityLabel2(type, Math.round(1000 * parseFloat(q.quantity)) / 1000, item.quantityType, getLocale(q.quantityType.name))
              });
            });
          }
        }
      } else {
        var name = menuitemutil.getPath(item);
        var t = true; // item.entityType.startsWith(entityType);
        var r = regexp.test(name);
        if (t && r && type !== "mandatory" && type !== "included") {
          set.insert({
            value: "menuCategory;0;" + item.id + ";" + ";" + item.entityType,
            text: menuitemutil.getPath(item) + "/" + menuitemutil.getProportionalOption(""),
            clazz: "category"
          });
        }
        if (item.activeMenuCategories && item.activeMenuCategories.length > 0) menuitemutil._getMenuItems(s, proportional, set, item.activeMenuCategories, type);
        if (item.activeMenuItems && item.activeMenuItems.length > 0) menuitemutil._getMenuItems(s, proportional, set, item.activeMenuItems, type);
        if (item.children && item.children.length > 0) menuitemutil._getMenuItems(s, proportional, set, item.children, type);
      }
    });
  },

  getLabels: function (search) {
    //s = search;
    var set = new SortedSet({
      comparator: function (a, b) {
        if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase())) return -1;
        if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase())) return 1;
        return a.text.localeCompare(b.text);
      }
    });
    var label = menuitemutil._getLabels(search, set, admin.labels);
    if (label == "") {
      return null;
    }
    var result = [];
    set.forEach(function (e) {
      result[result.length] = e;
    });
    return result;
  },

  _getLabels: function (s, set, labels) {
    labels.forEach(function (item) {

      function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
      }

      var regexp = new RegExp(escapeRegExp(s), "ig");
      var name = item.name;
      var r = regexp.test(name);
      if (r) {
        set.insert({
          value: "label;" + item.id + ";",
          text: name
        });
      }
    });
  },

  getLanguage: function () {
    return localStorage.languageSelected;
  },

  _updateMenu: function (item, cats) {
    if (item.children)
      menuitemutil.filterByType(item);
    admin.updateMenuItemMap([item]);
    var found = false;
    if (cats == undefined) {
      cats = admin.categories.children;
    }
    if (cats)
      if (Array.isArray(item)) {
        item.forEach(item => {
          cats.forEach((i, ind) => {
            if (i.id == item.id && i.entityType == item.entityType) {
              cats[ind] = item;
              if (item.availableQuantities)
                addMenuItem(item);
              found = true;
              processMenuItems(admin.categories);
            }
            if (i.children) found |= menuitemutil.updateMenu(item, i.children);
          });
        });
      } else {
        cats.forEach((i, ind) => {
          if (i.id == item.id && i.entityType == item.entityType) {
            cats[ind] = item;
            if (item.availableQuantities)
              addMenuItem(item);
            found = true;
            processMenuItems(admin.categories);
          }
          if (i.children) found |= menuitemutil.updateMenu(item, i.children);
        });
      }
    return found;
  },

  toggleChart: function () {
    var active = $("#graph").hasClass("btn-positive");
    $("#graph-block").addClass("hidden");
    $("#mgraph").addClass("btn-info");
    $("#mgraph").removeClass("btn-positive");
    if (ReactDOM.findDOMNode($("#graph-block").get(0)) !== null) ReactDOM.unmountComponentAtNode($("#graph-block").get(0));
    if (active) {
      $("#chart-block").addClass("hidden");
      $("#graph").addClass("btn-info");
      $("#graph").removeClass("btn-positive");
    } else {
      $("#chart-block").removeClass("hidden");
      $("#graph").addClass("btn-positive");
      $("#graph").removeClass("btn-info");
      if (typeof oldData == "undefined") populateCharts();
      else drawChart();
    }
  },

  toggleGraph: function () {
    var active = $("#mgraph").hasClass("btn-positive");
    const target = $("#graph-block");
    $("#chart-block").addClass("hidden");
    $("#graph").addClass("btn-info");
    $("#graph").removeClass("btn-positive");
    if (active) {
      target.addClass("hidden");
      $("#mgraph").addClass("btn-info");
      $("#mgraph").removeClass("btn-positive");
      if (ReactDOM.findDOMNode(target.get(0)) !== null) ReactDOM.unmountComponentAtNode(target.get(0));
    } else {
      target.removeClass("hidden");
      $("#mgraph").addClass("btn-positive");
      $("#mgraph").removeClass("btn-info");
      if (ReactDOM.findDOMNode(target.get(0)) !== null) ReactDOM.unmountComponentAtNode(target.get(0));
      ReactDOM.render(<MenuItemGraph categories={admin.categories.children} selected={menuitemutil.selected} />, target.get(0));
    }
  },

  populateGraph: function () {
    const target = $("#graph-block");
    if (target.hasClass("hidden")) return;
    if (ReactDOM.findDOMNode(target.get(0)) !== null) ReactDOM.unmountComponentAtNode(target.get(0));
    ReactDOM.render(<MenuItemGraph categories={admin.categories.children} selected={menuitemutil.selected} />, target.get(0));
  },

  toggleView: function () {
    var active = $("#menuitemview").hasClass("btn-positive");
    if (active) {
      $("#right_pane").addClass("hidden");
      $("#menuitemview").addClass("btn-info");
      $("#menuitemview").removeClass("btn-positive");
    } else {
      $("#right_pane").removeClass("hidden");
      $("#menuitemview").addClass("btn-positive");
      $("#menuitemview").removeClass("btn-info");
    }
  },

  tablehooks: function () {
    tooltip($("[data-toggle='tooltip']"));
    $("table td.availablequantity1 text").unbind("click");
    $("table td.availablequantity1 text span#serving").click(event => {
      var t = event.target.parentElement.parentElement;
      if ($(t).find("select").lenght > 0) return;
      var data = $($(t).parents('tr')[0]).data('node').data;
      const quantity = $(event.target).parents('text').attr("id");
      var value = null;
      data.availableQuantities.forEach(q => {
        if (q.quantity == quantity) value = q.quantityType;
      });
      var select = '<select id="quantityType' + data.id + '">';
      auth.myStatus.restaurant_quantity_types_all.forEach(q => {
        select += '<option id="' + q.id + '" value="' + q.id + '" ' + (value !== null && q.id == value.id ? "selected" : "") + ">" + getLocale(q.name, localStorage.languageSelected) + "</option>";
      });
      select += "</select>";
      var s = $(select);
      $(t)
        .find("select")
        .remove();
      var serv = $(t).find("span#serving");
      s.insertBefore(serv);
      serv.hide();

      $(t)
        .find("select")
        .click(event => {
          event.stopPropagation();
        });

      $(t).find("select").on('change', t => {
        serv.html(s.find("option:selected").html());
        var value = s.find("option:selected").attr("value");
        data.availableQuantities.forEach(q => {
          if (q.quantity == quantity) q.quantityType = { id: value };
        });
        s.remove();
        serv.show();
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      });
    });
    $("table td.description input").unbind("change");
    $("table td.description input").change(function (data) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      var value = $(t).val();
      var description = (data.description[localStorage.languageSelected] = value);

      data.description[localStorage.languageSelected] = value;
      if (data.entityType.indexOf("Category") < 0) {
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      } else {
        utils.modifyMenuCategory(
          data,
          d => {
            menuitemutil.updateMenu(d);
          }
        );
      }
    });
    $("table td.unitprice input").unbind("change");
    $("table td.unitprice input").change(function (data) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      var value = $(t).val();
      data.unitPrice = value;
      utils.modifyMenuItem(
        data.menuCategoryId,
        data,
        d => {
          menuitemutil.updateMenu(d);
        },
        menuitemutil.reload
      );
    });

    $("table td.availablequantity2 input").unbind("change");
    $("table td.availablequantity2 input").change(function (data) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      var value = $(t).val();
      if ($(t).hasClass("unitPrice")) {
        data.unitPrice = value;
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      } else {
        var quantity = $(t).attr("quantity");
        data.availableQuantities.forEach(q => {
          if (q.quantity == quantity) q.price = value;
        });
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      }
    });
    $("input.checkbox.isInventoryItem").unbind("change");
    $("input.checkbox.isInventoryItem").change(function (data) {
      handleCheckBoxChange(this, function (t) {
        var data = $($(t).parents('tr')[0]).data('node').data;
        if (data.entityType.indexOf("Category") < 0) {
          data.inventoryItem = $(t).prop("indeterminate") ? undefined : $(t).prop("checked");
          utils.modifyMenuItem(
            data.menuCategoryId,
            data,
            d => {
              menuitemutil.updateMenu(d);
            },
            menuitemutil.reload
          );
        } else {
          data.inventoryItem = $(t).prop("indeterminate") ? undefined : $(t).prop("checked");
          utils.modifyMenuCategory(
            data,
            d => {
              menuitemutil.updateMenu(d);
            });
        }
      });
    });
    $("input.checkbox.isActive").unbind("change");
    $("input.checkbox.isActive").change(function (event) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      data.isActive = $(t).prop("checked");
      if (data.entityType.indexOf("Category") < 0) {
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      } else {
        utils.modifyMenuCategory(
          data,
          d => {
            menuitemutil.updateMenu(d);
          }
        );
      }
    });
    $("input.checkbox.thirdParty").unbind("change");
    $("input.checkbox.thirdParty").change(function (event) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      const id = event.currentTarget.id.split("-");
      const platform = id[0];
      const name = id[1];
      const quantity = id[2];
      const settings = data.availableQuantities.find(q => q.quantity == quantity).settings;
      if (!settings[platform]) settings[platform] = {}
      settings[platform][name] = { ...settings[platform][name], enabled: event.target.checked }
      if (data.entityType.indexOf("Category") < 0) {
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      } else {
        utils.modifyMenuCategory(
          data,
          d => {
            menuitemutil.updateMenu(d);
          }
        );
      }
    });
    $("input.checkbox.isTop").unbind("change");
    $("input.checkbox.isTop").change(function (data) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      data.isTop = $(t).prop("checked");
      if (data.entityType.indexOf("Category") < 0) {
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      } else {
        utils.modifyMenuCategory(
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      }
    });
    $("input.checkbox.online").unbind("change");
    $("input.checkbox.online").change(function (data) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      data.online = $(t).prop("checked");
      if (data.entityType.indexOf("Category") < 0) {
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          }
        );
      } else {
        utils.modifyMenuCategory(
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      }
    });
    $("input.checkbox.available").unbind("change");
    $("input.checkbox.available").change(function (data) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      data.available = $(t).prop("checked");
      if (data.entityType.indexOf("Category") < 0) {
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          }
        );
      } else {
        utils.modifyMenuCategory(
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
          menuitemutil.reload
        );
      }
    });
    $("input.checkbox.freeQuantity").unbind("change");
    $("input.checkbox.freeQuantity").change(function (data) {
      var t = this;
      var data = $($(t).parents('tr')[0]).data('node').data;
      data.freeQuantity = !$(t).prop("checked");
      if (data.entityType.indexOf("Category") < 0) {
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          }
        );
      }
    });
    function fw(t, type) {
      var data = $($(t).parents('tr')[0]).data('node').data;
      if (!data.settings)
        data.settings = { foodpanda: {}, wolt: {}, falatozz: {}, takeaway: {} }
      var z = data.settings[type];
      if (!z)
        data.settings[type] = z = {};
      if (data.entityType.indexOf("Category") < 0) {
        if (![$(t).prop("name")])
          z[$(t).prop("name")] = {};
        z[$(t).prop("name")].enabled = $(t).prop("checked");
        utils.modifyMenuItem(
          data.menuCategoryId,
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
        );
      } else {
        if (!z[$(t).prop("name")])
          z[$(t).prop("name")] = {};
        z[$(t).prop("name")].enabled = $(t).prop("checked");
        utils.modifyMenuCategory(
          data,
          d => {
            menuitemutil.updateMenu(d);
          },
        );
      }
    }
    $("input.checkbox.foodpanda").unbind("change");
    $("input.checkbox.foodpanda").change(function (data) {
      fw(this, "foodpanda");
    });
    $("input.checkbox.wolt").unbind("change");
    $("input.checkbox.wolt").change(function (data) {
      fw(this, "wolt");
    });
    $("input.checkbox.falatozz").unbind("change");
    $("input.checkbox.falatozz").change(function (data) {
      fw(this, "falatozz");
    });

  },

  getRestaurant: id => {
    return menuitemutil.props.restaurants.find(rr => rr.globalId === id);
  },

  localFromGlobal: {

  },
  getLocalFromGlobal: (restaurant) => {
    const def = $.Deferred();
    if (menuitemutil.localFromGlobal[restaurant.globalId])
      def.resolve(menuitemutil.localFromGlobal[restaurant.globalId]);
    else
      return get(restaurant.serverUrl + "eatwithme.server/" + restaurant.instance + "/tableService/getLocalFromGlobalId/" + restaurant.globalId + "?instance=" + restaurant.instance, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false).done(id => {
        menuitemutil.localFromGlobal[restaurant.globalId] = id;
        def.resolve(id);
      })
    return def.promise();
  },

  updateMenuItemAdditionToGlobalIdRemote: (additions, globalId) => {
    additions = additions.map(a => {
      a = { ...a };
      if (a.additionGlobalId) {
        a.additionId = a.additionGlobalId;
        a.additionGlobalId = null;
      } else {
        a.additionGlobalId = a.additionId;
        a.additionId = null;
      }
      delete a.addition;
      delete a.id;
      return a;
    })
    return additions;
  },

  updateMenuCategoryAdditionToGlobalIdRemote: (additions, globalId) => {
    additions = additions.map(a => {
      a = { ...a };
      if (a.additionGlobalId) {
        a.additionId = a.additionGlobalId;
        a.additionGlobalId = null;
      } else {
        a.additionGlobalId = a.additionId;
        a.additionId = null;
      }
      delete a.addition;
      delete a.id;
      return a;
    })
    return additions;
  },

  updateRemote: (entity, globalId) => {
    const restaurant = menuitemutil.getRestaurant(globalId);
    console.log(entity);
    if (entity.availableQuantities) {
      var {
        entityType,
        id,
        barcodes,
        action,
        shortName,
        manufacturer,
        name,
        description,
        image,
        bigImage,
        quantityType,
        cost,
        unitPrice,
        availableQuantities,
        timing,
        production_lines,
        vat_category,
        takeaway_vat_category,
        active,
        isTop,
        online,
        available,
        inventoryItem,
        freeQuantity,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        mandatory_volume,
        places,
        preorder,
        toDate,
        fromDate,
        mainCategoryId,
        subCategoryId,
        woltImage,
        foodpandaImage,
        falatozzImage,
        minimumPrice
      } = entity;
      menuItemAdditions = menuitemutil.updateMenuItemAdditionToGlobalIdRemote(menuItemAdditions, globalId);
      menuCategoryAdditions = menuitemutil.updateMenuCategoryAdditionToGlobalIdRemote(menuCategoryAdditions, globalId);
      const e = {
        type: entityType.toLowerCase(),
        globalId: id,
        barcodes,
        action,
        shortName,
        manufacturer,
        name,
        description,
        imageSrc: image ? auth.myStatus.local_url + "eatwithme.server/tableService/1/getImage/" + image + "?instance=" + localStorage.instance : null,
        bigImageSrc: bigImage ? auth.myStatus.local_url + "eatwithme.server/tableService/1/getImage/" + bigImage + "?instance=" + localStorage.instance : null,
        quantityType,
        cost,
        unitPrice,
        availableQuantities,
        timing,
        production_lines,
        vat_category,
        takeaway_vat_category,
        active,
        isTop,
        online,
        available,
        inventoryItem,
        freeQuantity,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        mandatory_volume,
        places,
        preorder,
        toDate,
        fromDate,
        woltImage,
        foodpandaImage,
        falatozzImage,
        minimumPrice,
        mainCategory: mainCategoryId,
        subCategory: subCategoryId
      }
      menuitemutil.getLocalFromGlobal(restaurant).done(id => {

        const menuCategory = getMenuCategory(entity);

        if (entity.connectedRestaurants[restaurant.globalId]) {
          post(restaurant.serverUrl + auth.war + "/" + restaurant.instance + "/mergeService/" + id + "/" + (entity.menuCategoryId || 0) + "/modifyMenuItem?instance=" + restaurant.instance, e).done(() => refreshConnectedRestaurants(restaurant.globalId, true));
        } else {
          post(restaurant.serverUrl + auth.war + "/" + restaurant.instance + "/mergeService/" + id + "/" + (menuCategory.globalId || 0) + "/addMenuItem?instance=" + restaurant.instance, e).done(() => refreshConnectedRestaurants(restaurant.globalId, true));
        }
      });
    } else {
      var {
        entityType,
        id,
        name,
        description,
        image,
        timing,
        productionLines,
        vatCategory,
        takeawayVatCategory,
        isActive: active,
        isTop,
        online,
        available,
        inventoryItem,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        settings,
        fromDate,
        toDate,
        preorder,
        mainCategoryId,
        subCategoryId
      } = entity;
      menuItemAdditions = menuitemutil.updateMenuItemAdditionToGlobalIdRemote(menuItemAdditions, globalId);
      menuCategoryAdditions = menuitemutil.updateMenuCategoryAdditionToGlobalIdRemote(menuCategoryAdditions, globalId);
      const e = {
        globalId: id,
        type: entityType.toLowerCase(),
        name,
        description,
        imageSrc: image ? auth.myStatus.local_url + "eatwithme.server/tableService/1/getImage/" + image + "?instance=" + localStorage.instance : null,
        timing,
        productionLines,
        vatCategory,
        takeawayVatCategory,
        isActive: active,
        isTop,
        online,
        available,
        inventoryItem,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        settings,
        fromDate,
        toDate,
        preorder,
        mainCategory: mainCategoryId,
        subCategory: subCategoryId
      }
      menuitemutil.getLocalFromGlobal(restaurant).done(id => {
        if (entity.connectedRestaurants[restaurant.globalId])
          post(restaurant.serverUrl + auth.war + "/" + restaurant.instance + "/mergeService/" + id + "/" + (entity.menuCategoryId || 0) + "/modifyCategory?instance=" + restaurant.instance, e).done(() => refreshConnectedRestaurants(restaurant.globalId, true));
        else
          post(restaurant.serverUrl + auth.war + "/" + restaurant.instance + "/mergeService/" + id + "/" + (entity.menuCategoryId || 0) + "/addCategory?instance=" + restaurant.instance, e).done(() => refreshConnectedRestaurants(restaurant.globalId, true));
      });
    }
  },

  updateMenuItemAdditionToGlobalIdLocal: (additions, globalId) => {
    additions.forEach(a => {
      if (a.additionGlobalId) {
        a.additionId = a.additionGlobalId;
        a.additionGlobalId = null;
      } else {
        a.additionGlobalId = a.additionId;
        a.additionId = null;
      }
      delete a.id;
      delete a.addition;
    })
    return additions;
  },

  updateMenuCategoryAdditionToGlobalIdLocal: (additions, globalId) => {
    additions.forEach(a => {
      if (a.additionGlobalId) {
        a.additionId = a.additionGlobalId;
        a.additionGlobalId = null;
      } else {
        a.additionGlobalId = a.additionId;
        a.additionId = null;
      }
      delete a.id;
      delete a.addition;
    })
    return additions;
  },


  updateLocal: (entity, globalId) => {
    const restaurant = menuitemutil.getRestaurant(globalId);
    var remoteEntity = entity.connectedRestaurants[globalId];
    console.log(remoteEntity.name);
    if (remoteEntity.availableQuantities) {
      var {
        entityType,
        id,
        globalId,
        barcodes,
        action,
        shortName,
        manufacturer,
        name,
        description,
        image,
        bigImage,
        quantityType,
        cost,
        unitPrice,
        availableQuantities,
        timing,
        production_lines,
        vat_category,
        takeaway_vat_category,
        active,
        isTop,
        online,
        available,
        inventoryItem,
        freeQuantity,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        mandatory_volume,
        places,
        preorder,
        toDate,
        fromDate,
        mainCategoryId,
        subCategoryId,
        woltImage,
        foodpandaImage,
        falatozzImage,
        minimumPrice
      } = remoteEntity;
      menuItemAdditions = menuitemutil.updateMenuItemAdditionToGlobalIdLocal(menuItemAdditions, globalId);
      menuCategoryAdditions = menuitemutil.updateMenuCategoryAdditionToGlobalIdLocal(menuCategoryAdditions, globalId);
      const e = {
        type: entityType.toLowerCase(),
        id: entity.id,
        globalId: id,
        barcodes,
        action,
        shortName,
        manufacturer,
        name,
        description,
        imageSrc: image ? auth.myStatus.local_url + "eatwithme.server/tableService/1/getImage/" + image + "?instance=" + localStorage.instance : null,
        bigImageSrc: bigImage ? auth.myStatus.local_url + "eatwithme.server/tableService/1/getImage/" + bigImage + "?instance=" + localStorage.instance : null,
        quantityType,
        cost,
        unitPrice,
        availableQuantities,
        timing,
        production_lines,
        vat_category,
        takeaway_vat_category,
        active,
        isTop,
        online,
        available,
        inventoryItem,
        freeQuantity,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        mandatory_volume,
        places,
        preorder,
        toDate,
        fromDate,
        woltImage,
        foodpandaImage,
        falatozzImage,
        minimumPrice,
        mainCategory: mainCategoryId,
        subCategory: subCategoryId
      }
      if (entity.connectedRestaurants[0])
        post(localStorage.instance + "/mergeService/" + sessionStorage.restaurantSelected + "/" + (remoteEntity.menuCategoryId || 0) + "/modifyMenuItem", e).done(() => menuitemutil._reload());
      else {
        delete e.id;
        post(localStorage.instance + "/mergeService/" + sessionStorage.restaurantSelected + "/" + (remoteEntity.menuCategoryId || 0) + "/addMenuItem", e).done(() => menuitemutil._reload());
      }
    } else {
      var {
        entityType,
        globalId,
        id,
        name,
        description,
        image,
        timing,
        productionLines,
        vatCategory,
        takeawayVatCategory,
        isActive: active,
        isTop,
        online,
        available,
        inventoryItem,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        settings,
        fromDate,
        toDate,
        preorder,
        mainCategoryId,
        subCategoryId
      } = remoteEntity;
      menuItemAdditions = menuitemutil.updateMenuItemAdditionToGlobalIdLocal(menuItemAdditions, globalId);
      menuCategoryAdditions = menuitemutil.updateMenuCategoryAdditionToGlobalIdLocal(menuCategoryAdditions, globalId);
      const e = {
        id: entity.id,
        globalId: id,
        type: entityType.toLowerCase(),
        name,
        description,
        imageSrc: image ? restaurant.serverUrl + auth.war + "/" + restaurant.instance + "eatwithme.server/tableService/1/getImage/" + image + "?instance=" + restaurant.instance : null,
        timing,
        productionLines,
        vatCategory,
        takeawayVatCategory,
        isActive: active,
        isTop,
        online,
        available,
        inventoryItem,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        settings,
        fromDate,
        toDate,
        preorder,
        mainCategory: mainCategoryId,
        subCategory: subCategoryId
      }
      if (entity.connectedRestaurants[0])
        post(localStorage.instance + "/mergeService/" + sessionStorage.restaurantSelected + "/" + (entity.menuCategoryId || 0) + "/modifyCategory", e).done(() => menuitemutil._reload());
      else {
        delete e.id;
        post(localStorage.instance + "/mergeService/" + sessionStorage.restaurantSelected + "/" + (entity.menuCategoryId || 0) + "/addCategory", e).done(() => menuitemutil._reload());
      }
    }
  }
};

var showManufacturer = false;
var showDescription = false;
var minProfitRatio, maxProfitRatio, averageProfitRatioSum, averageProfitRatioCount, averageProfitRatio;

function findMenuCategory(children, menuCategoryId) {
  return children.find(c => {
    if (c.id === menuCategoryId)
      return c;
    if (c.children)
      return findMenuCategory(c.children, menuCategoryId);
  })
}

function getMenuCategory(entity) {
  if (!entity.connectedRestaurants || entity.connectedRestaurants[0]) {
    return admin.getMenuCategory(entity.menuCategoryId);
  }
  var data = connectedRestaurants[Object.keys(entity.connectedRestaurants)[0]];
  const c = findMenuCategory(data.data.children, entity.menuCategoryId);
  if (!c) {
    console.error("Category not found for ", entity);
  }
  return c;
}

function updateData(data, level, parent) {
  if (typeof level === "undefined") level = 0;
  if (!data.isActive) data.extraClasses = (data.extraClasses ? data.extraClasses : "") + " itemDeactivated";
  if (data.recordState === "DELETED") {
    data.extraClasses = (data.extraClasses ? data.extraClasses : "") + " deleted";
  }
  data.depth = level;
  if (data.name) {
    data.title = data.name[localStorage.languageSelected] ? data.name[localStorage.languageSelected] : "(" + getLocale(data.name) + ")";
  }
  data.minProfitRatio = 0;
  data.maxProfitRatio = 0;
  data.averageProfitRatioSum = 0;
  data.averageProfitRatioCount = 0;
  data.averageProfitRatio = 0;
  data.mainCategorySet = !data.availableQuantities || !!data.defaultMainCategoryId || !data.defaultTop || !data.defaultActive;
  data.subCategorySet = !data.availableQuantities || (!!data.defaultSubCategoryId && (!data.mainCategoryId || data.mainCategoryId == getMenuCategory(data)?.defaultMainCategoryId)) || !!data.subCategoryId || !data.defaultTop || !data.defaultActive;

  if (data.availableQuantities && data.costEstimation > 0) {
    data.availableQuantities.forEach(quantityAndPrice => {
      if (data.entityType !== menuitemutil.entityType) return;
      var price = quantityAndPrice.price;
      if (price === "") {
        price = data.unitPrice * quantityAndPrice.quantity
      } else {
        price = Number(price);
      }
      if (price > 0 && data.defaultVATCategory) {
        var pricePerCostRatio = 0;
        pricePerCostRatio = Math.round((price / (100 + data.defaultVATCategory.percent) * 100 - data.costEstimation * quantityAndPrice.quantity) / (data.costEstimation * quantityAndPrice.quantity) * 100);
        if (Math.abs(pricePerCostRatio) < 3000) {
          minProfitRatio = minProfitRatio ? Math.min(minProfitRatio, pricePerCostRatio) : pricePerCostRatio;
          maxProfitRatio = maxProfitRatio ? Math.max(maxProfitRatio, pricePerCostRatio) : pricePerCostRatio;
          averageProfitRatioSum += pricePerCostRatio;
          averageProfitRatioCount++;
          data.minProfitRatio = data.minProfitRatio ? Math.min(data.minProfitRatio, pricePerCostRatio) : pricePerCostRatio;
          data.maxProfitRatio = data.maxProfitRatio ? Math.max(data.maxProfitRatio, pricePerCostRatio) : pricePerCostRatio;
          data.averageProfitRatioSum += pricePerCostRatio;
          data.averageProfitRatioCount++;
          data.averageProfitRatio = data.averageProfitRatioSum / data.averageProfitRatioCount;
        }
      }

    });
  } else {
    data.minProfitRatio = 0;
    data.maxProfitRatio = 0;
    data.averageProfitRatioSum = 0;
    data.averageProfitRatioCount = 0;
  }
  if (data.shortName) data.title += " (<i>" + data.shortName + "</i>)";
  if (data.children)
    data.children.forEach(function (v) {
      updateData(v, level + 1, data);
      v.key = v.entityType + v.id;
      if (v.manufacturer)
        showManufacturer = true;
      if (v.description && Object.values(v.description).filter(v => !!v).length) {
        showDescription = true;
      }
      if (v.averageProfitRatioCount) {
        data.minProfitRatio = data.minProfitRatio ? Math.min(data.minProfitRatio, v.minProfitRatio) : v.minProfitRatio;
        data.maxProfitRatio = data.maxProfitRatio ? Math.max(data.maxProfitRatio, v.maxProfitRatio) : v.maxProfitRatio;
        data.averageProfitRatioSum += v.averageProfitRatioSum;
        data.averageProfitRatioCount += v.averageProfitRatioCount;
        data.averageProfitRatio = data.averageProfitRatioSum / data.averageProfitRatioCount;
      }
      // v.expanded = true;
    });

  if (data.children && !data.availableQuantities && data.isTop) {
    data.mainCategorySet = data.children.filter(c => !c.mainCategorySet).length == 0;
    data.subCategorySet = data.children.filter(c => !c.subCategorySet).length == 0;
  }

  /*
  if (parent && !data.mainCategorySet)
    parent.mainCategorySet = false;
  if (parent && !data.subCategorySet)
    parent.subCategorySet = false;
    */
}

var connectedRestaurants = {

}

function setConnectedRestaurant(data, id) {
  data.forEach(d => {
    d.connectedRestaurants = {};
    d.connectedRestaurants[id] = d;
    d.equalRestaurants = [];
    if (d.children)
      setConnectedRestaurant(d.children, id);
  })
}

function updateAvailableQuantities(availableQuantities) {
  if (!availableQuantities) return availableQuantities;
  availableQuantities = JSON.parse(JSON.stringify(availableQuantities));
  availableQuantities.forEach(a => {
    delete a.costEstimation;
    delete a.priceEstimation;
    delete a.selected;
    delete a.places;
  })
  return availableQuantities;
}

function compareLocalToRemote(local, remote) {
  var diff = "";
  if (local.unitPrice != remote.unitPrice) {
    diff += "<div class='tooltip-row' style='white-space:nowrap'>" + admin_local.differences_in_unitPrice + "</div>";
  }
  if (JSON.stringify(local.name) != JSON.stringify(remote.name)) {
    diff += "<div class='tooltip-row' style='white-space:nowrap'>" + admin_local.differences_in_name + "</div>";
  }
  if (JSON.stringify(local.menuItemAdditions) != JSON.stringify(remote.menuItemAdditions) || JSON.stringify(local.menuCategoryAdditions) != JSON.stringify(remote.menuCategoryAdditions))
    diff += "<div class='tooltip-row' style='white-space:nowrap'>" + admin_local.differences_in_additions + "</div>";
  if (JSON.stringify(local.availableQuantities) != JSON.stringify(remote.availableQuantities))
    diff += "<div class='tooltip-row' style='white-space:nowrap'>" + admin_local.differences_in_availableQuantities + "</div>";
  return diff;
}

function updateToGlobalIdsLocal(additions) {
  return additions.map(a => {
    var copy = { ...a };
    copy.additionId = copy.additionGlobalId || copy.additionId;
    delete copy.additionGlobalId;
    delete copy.id;
    delete copy.addition;
    return copy;
  })
}
function updateToGlobalIdsRemote(additions) {
  return additions.map(a => {
    var copy = { ...a };
    copy.additionId = copy.additionGlobalId || copy.additionId;
    delete copy.additionGlobalId;
    delete copy.id;
    delete copy.addition;
    return copy;
  })
}

function merge(into, from) {
  from.forEach(f => {
    const found = into.find(i => i.entityType == f.entityType && (i.id == f.globalId || i.globalId == f.id));
    if (found) {
      var { name, availableQuantities, unitPrice, minimumPrice, quantityType, menuItemAdditions, menuCategoryAdditions } = found;
      menuItemAdditions = updateToGlobalIdsLocal(menuItemAdditions);
      menuCategoryAdditions = updateToGlobalIdsLocal(menuCategoryAdditions);
      availableQuantities = updateAvailableQuantities(availableQuantities);
      const left = { name, availableQuantities, unitPrice, minimumPrice, quantityType, menuItemAdditions, menuCategoryAdditions }
      var { name, availableQuantities, unitPrice, minimumPrice, quantityType, menuItemAdditions, menuCategoryAdditions } = f;
      menuItemAdditions = updateToGlobalIdsRemote(menuItemAdditions);
      menuCategoryAdditions = updateToGlobalIdsRemote(menuCategoryAdditions);
      availableQuantities = updateAvailableQuantities(availableQuantities);
      const right = { name, availableQuantities, unitPrice, minimumPrice, quantityType, menuItemAdditions, menuCategoryAdditions };
      found.connectedRestaurants[Object.keys(f.connectedRestaurants)[0]] = f;
      if (JSON.stringify(left) === JSON.stringify(right)) {
        found.equalRestaurants.push(Number(Object.keys(f.connectedRestaurants)[0]));
        if (found.children && f.children)
          found.children = merge(found.children, f.children);
      } else {
        console.log(JSON.stringify(left));
        console.log(JSON.stringify(right));
        found.compareLocalToRemote = compareLocalToRemote(left, right);
        if (found.children && f.children)
          found.children = merge(found.children, f.children);
        /*if (found.children)
          found.children.push(f);*/
      }
    } else {
      into.push(f)
    }
  })
  return into;
}

function mergeWithConnectedRestaurants(data) {
  if (!Object.keys(connectedRestaurants).length)
    return data;
  var d = {
    children: JSON.parse(JSON.stringify(data.children))
  };

  setConnectedRestaurant(d.children, 0);

  Object.values(connectedRestaurants).forEach(r => {
    setConnectedRestaurant(r.data.children, r.restaurant.globalId);

    d.children = merge(d.children, r.data.children);
  })
  return d;
}

const renderCategories = () => {
  // Calculate tmplparams once before rendering
  const params = tmplparams();

  const categories = admin.categories.children.filter(
    (category) =>
      category.entityType === menuitemutil.entityType + "Category" &&
      category.isTop &&
      category.isActive
  );

  // Render the categories using React
  ReactDOM.hydrate(
    <React.Fragment>
      {categories.map((category) => (
        <CategoryTemplate key={category.id} {...category} {...params} />
      ))}
    </React.Fragment>,
    document.getElementById('right_pane')
  );
}

function processMenuItems(data) {
  $('[data-toggle="tooltip"]').tooltip("hide");
  var menu = $(".tab-pane.menu.active");
  var id = menu.find(".menuItemCategory.selected2").attr("id");
  var focus = $(":focus").attr("id");
  //$("#right_pane").empty();

  if (data == undefined) {
    if (!admin.categories)
      return;
    renderCategories();


    if (id != null) showMenuItemCategory(id);
    return;
  }

  admin.categories = {
    children: data.children
  };

  showManufacturer = false;
  showDescription = false;
  minProfitRatio = 0;
  maxProfitRatio = 0;
  averageProfitRatioSum = 0;
  averageProfitRatioCount = 0;

  var mergedData = mergeWithConnectedRestaurants(data);

  updateData(mergedData);
  averageProfitRatio = averageProfitRatioSum / averageProfitRatioCount;
  if (showManufacturer) {
    $('th.manufacturer').removeClass("hidden");
    //$('table thead tr:nth-child(2) th.name').attr('colspan', 2);
  } else {
    $('th.manufacturer').addClass("hidden");
    $('table thead tr:nth-child(2) th.name').attr('colspan', 1);
  }

  if (showDescription)
    $('th.description').removeClass("hidden");
  else
    $('th.description').addClass("hidden");

  renderCategories();

  $(".menuItemHead").each((ind, e) => e.addEventListener("touchstart", touchStart, {
    capture: true,
    passive: false
  }));
  $(".list-group-item.defaultQuantity").each((ind, e) => e.addEventListener("touchstart", touchStart, {
    capture: true,
    passive: false
  }));

  const enabledFeatures = auth.myStatus.restaurant_settings["enabled-features"];

  if (id != null) showMenuItemCategory(id);

  if (menuitemutil.menuItems == null) {
    //floatMenuItemsTableHead();
    menuitemutil.menuItems = createTree($("#menuItemsFreezeTable > table#menuItems"), {
      extensions: auth.device.platform !== "browser" ? ["persist", "table", "filter"] : ["persist", "table", "dnd", "filter", "contextMenu", "edit"],
      filter: {
        // override default settings
        counter: false, // No counter badges
        mode: "hide" // "dimm": Grayout unmatched nodes,
        // "hide": remove unmatched nodes
      },
      checkbox: false,
      source: mergedData,
      icon: false,
      keyboard: false,
      autoScroll: false,
      persist: {
        cookieDelimiter: "~",
        cookiePrefix: "fancytree-1-" + menuitemutil.entityType,
        expandLazy: true,
        overrideSource: true,
        store: "auto",
        types: "expanded"
        //types: "active expanded focus selected"
      },
      table: {
        checkboxColumnIdx: 0,
        indentation: 20,
        nodeColumnIdx: 0,
        iconColumnIdx: 0
        // render node expander, icon, and title to this column
        // (default:
        // #0)
      },
      gridnav: {
        autofocusInput: false, // Focus first embedded input if
        // node
        // gets activated
        handleCursorKeys: true
        // Allow UP/DOWN in inputs to move to prev/next node
      },
      dnd: {
        preventVoidMoves: true, // Prevent dropping nodes
        // 'before self', etc.
        preventRecursions: false, // Prevent dropping
        // nodes on own
        // descendants
        autoExpandMS: 2000,
        autoScroll: true,
        draggable: {
          // zIndex: 1000,
          // appendTo: "body",
          // helper: "clone",
          scroll: true,
          revert: "invalid"
        },
        dragStart: function (node, data, event) {
          var t = $(data.originalEvent.target).parents("td");
          return t.hasClass("dragged") ? "move" : false;
        },
        dragEnter: function (node, data) {
          // Prevent dropping a parent below another parent
          // (only sort
          // nodes under the same parent)
          if (data.node.data.id === data.otherNode.data.id)
            return false;
          const targetType = data.node.data.entityType;
          const draggedType = data.otherNode.data.entityType;
          const parentType = data.node.parent ? data.node.parent.data.entityType : null;
          var res;
          if (data.node === data.otherNode)
            res = [];
          if (draggedType === menuitemutil.entityType) {
            //Dragged is a menu item,
            if (targetType === menuitemutil.entityType) {
              //Target is a menu item
              res = ["before", "after"];
            } else {
              if (parentType) {
                res = ["before", "after", "over"];
              } else {
                //Target is a category item
                res = ["over"];
              }
            }
          } else {
            //Dragged is a category item
            if (targetType === menuitemutil.entityType + "Category") {
              res = ["before", "after", "over"];
            } else {
              if (parentType) {
                //Target is a menu item
                res = ["before", "after"];
              } else {
                //Target is a category item
                res = ["before", "after"];
              }
            }
          }
          return res;
        },
        dragDrop: function (node, data) {
          data.otherNode.moveTo(node, data.hitMode);
          var ids = [];
          if (data.hitMode == "over") {
            if (data.otherNode.data.entityType == menuitemutil.entityType)
              utils.setMenuItemParentCategory(data.otherNode.data.id, node.data.id, function () {
                node.children.forEach(function (d) {
                  ids[ids.length] = d.data.entityType == menuitemutil.entityType ? "0" : "1";
                  ids[ids.length] = d.data.id;
                });
                utils.updateMenuItemOrders(ids, function (data) {
                  processMenuItems(data);
                });
              });
            else
              utils.setMenuCategoryParentCategory(data.otherNode.data.id, node.data.id, function () {
                node.children.forEach(function (d) {
                  ids[ids.length] = d.data.entityType == menuitemutil.entityType ? "0" : "1";
                  ids[ids.length] = d.data.id;
                });
                utils.updateMenuItemOrders(ids, function (data) {
                  processMenuItems(data);
                });
              });
          } else {
            if (data.otherNode.data.entityType == menuitemutil.entityType)
              utils.setMenuItemParentCategory(data.otherNode.data.id, node.parent.data.entityType ? node.parent.data.id : -1, function () {
                node.parent.children.forEach(function (d) {
                  ids[ids.length] = d.data.entityType == menuitemutil.entityType ? 0 : 1;
                  ids[ids.length] = d.data.id;
                });
                utils.updateMenuItemOrders(ids, function (data) {
                  processMenuItems(data);
                });
              });
            else {
              utils.setMenuCategoryParentCategory(data.otherNode.data.id, node.parent.data.entityType ? node.parent.data.id : -1, function () {
                node.parent.children.forEach(function (d) {
                  ids[ids.length] = d.data.entityType == menuitemutil.entityType ? 0 : 1;
                  ids[ids.length] = d.data.id;
                });
                utils.updateMenuItemOrders(ids, function (data) {
                  processMenuItems(data);
                });
              });
            }
          }
        }
      },
      collapse: function (event, data) {
        localStorage.removeItem("categoryexpanded");
        if (window.innerWidth <= 800) {
          var node = data.node;
          if (node.parent.isRoot()) {
            node.tree.clearFilter();
            menuitemutil.filterNodes();
          } else {
            var node = data.node.parent;
            localStorage.categoryexpanded = node.data.id;
            node.tree.filterBranches(
              function (node) {
                if (!localStorage.categoryexpanded)
                  return true;
                if (node.data.entityType.indexOf("Category") === -1) {
                  return node.parent.data.id == localStorage.categoryexpanded;
                }
                return node.data.id == localStorage.categoryexpanded;
              },
              {
                mode: "hide"
              }
            );
          }
          node.makeVisible({ scrollIntoView: true });
        }

      },
      expand: function (event, data) {
        menuitemutil.tablehooks();
        if (window.innerWidth <= 800) {
          var node = data.node;
          if (node.data.entityType.indexOf("Category") === -1)
            return;
          localStorage.categoryexpanded = node.data.id;
          node.tree.filterBranches(
            function (node) {
              if (!localStorage.categoryexpanded)
                return true;
              if (node.data.entityType.indexOf("Category") === -1) {
                return node.parent.data.id == localStorage.categoryexpanded;
              }
              return node.data.id == localStorage.categoryexpanded;
            },
            {
              mode: "hide"
            }
          );
          node.makeVisible({ scrollIntoView: true });
        }
      },
      activate: function (event, data) {
        // A node was activated: display its title:
        var node = data.node;
        menuitemutil.selected = node.data;
        menuitemutil.populateGraph();
        if (menuitemutil.menuItems)
          menuitemutil.menuItems.activateTimestamp = new Date().getTime();
        $("#addMenuCategory").show();
        if (node.data.entityType == menuitemutil.entityType) {
          // is menu item
          $("button#editMenuCategory").hide();
          $("button#addMenuCategory").removeAttr("disabled");
          $("button#addMenuItem").removeAttr("disabled");
          $("button#addOption").removeAttr("disabled");
          $("button#editMenuItem").show();
          populateCharts();
        } else {
          // is menu category
          $("button#editMenuCategory").show();
          $("button#addMenuCategory").removeAttr("disabled");
          $("button#addMenuItem").removeAttr("disabled");
          $("button#addOption").removeAttr("disabled");
          $("button#editMenuItem").hide();
          populateCategoryCharts();
        }
        $("#delete").removeAttr("disabled");
        if (node.data.isActive) {
          $("#deactivate").removeAttr("disabled");
          $("#activate").prop("disabled", "true");
        } else {
          $("#deactivate").prop("disabled", "true");
          $("#activate").removeAttr("disabled");
        }
        $(node.tr)
          .parent()
          .find("td.availableQuantity1 select")
          .remove();
        $(node.tr)
          .parent()
          .find("td.availableQuantity1 span#serving")
          .show();
      },
      previousNode: null,
      click: (event, data) => {
        if ($(event.originalEvent.target).hasClass('fancytree-expander'))
          return;
        if (menuitemutil.clickTimestamp && event.originalEvent.originalEvent.target === menuitemutil.clickTarget && menuitemutil.selectedNodeKey === data.node.key && new Date().getTime() - menuitemutil.clickTimestamp < 300) {

          if (data.node.data.entityType === "Meal" || data.node.data.entityType === "Drink") {
            $('button#editMenuItem').trigger('click');
            $('button#editMenuItem').trigger('blur');
          } else {
            $('button#editMenuCategory').trigger('click');
            $('button#editMenuCategory').trigger('blur');
          }
          return;
        };
        menuitemutil.clickTimestamp = new Date().getTime();
        menuitemutil.selectedNodeKey = data.node.key;
        menuitemutil.clickTarget = event.originalEvent.originalEvent.target;
        if ($(event.originalEvent.target).hasClass('custom-control-label'))
          return;
        if (event.originalEvent.target.nodeName === "INPUT")
          return;
        if (data.node == menuitemutil.menuItems.previousNode && !$(event.toElement).hasClass("fancytree-expander")) {
          menuitemutil.menuItems.activateKey("");
          event.preventDefault();
          menuitemutil.menuItems.previousNode = null;
          $("#editMenuCategory").hide();
          $("#addMenuItem").attr("disabled", "true");
          $("#addMenuCategory").hide();
          $("#addOption").attr("disabled", "true");
          $("#editMenuItem").hide();
          $("#activate").prop("disabled", "true");
          $("#deactivate").prop("disabled", "true");
          $("#delete").prop("disabled", "true");
        } else {
          menuitemutil.menuItems.previousNode = data.node;
        }
      },
      edit: {
        // Available options with their default:
        adjustWidthOfs: 4, // null: don't adjust input size to content
        inputCss: { minWidth: "3em" },
        triggerStart: ["clickActive", "f2", " ", "shift+click", "mac+enter"],
        beforeEdit: (type, event) => {
          if (Math.min(window.innerHeight, window.innerWidth) <= 800) return false;
          if (new Date().getTime() - menuitemutil.menuItems.activateTimestamp > 100) {
            return true;
          }
          return false;
        }, // Return false to prevent edit mode
        edit: function (event, data) {
          $(data.input).val(data.node.data.name[localStorage.languageSelected]);
        },
        save: function (event, data) {
          var name = $(data.input).val();
          var data = data.node.data;
          data.name[localStorage.languageSelected] = name;
          if (data.entityType == menuitemutil.entityType) {
            utils.modifyMenuItem(
              data.menuCategoryId,
              data,
              d => {
                menuitemutil.updateMenu(d);
              },
              menuitemutil.reload
            );
          } else {
            utils.modifyMenuCategory(
              data,
              d => {
                menuitemutil.updateMenu(d);
              }
            );
          }
        },
        beforeClose: $.noop, // Return false to prevent cancel/save (data.input is available)
        close: $.noop // Editor was removed
      },
      contextMenu: {
        menu: {
          edit: { name: I18n.t("local.modify"), icon: "edit" },
          new_category: { name: I18n.t("admin_local.add_category"), icon: "add" },
          new_menu_item: { name: I18n.t("admin_local.add_item"), icon: "add" },
          new_menu_option: { name: I18n.t("admin_local.add_option"), icon: "add" },
          replace: {
            name: I18n.t("admin_local.replace"),
            icon: "refresh",
            disabled: function () {
              var node = menuitemutil.menuItems.getActiveNode();
              var type = typeof node.data.availableQuantities != "undefined";
              return !type;
            }
          },
          sort_option: {
            name: I18n.t("admin_local.sort_abc"),
            disabled: function () {
              var node = menuitemutil.menuItems.getActiveNode();
              var type = typeof node.data.availableQuantities != "undefined";
              return type;
            }
          },
          sep1: "---------",
          delete: { name: "Delete", icon: "delete" }
        },
        actions: function (node, action, options) {
          var node = menuitemutil.menuItems.getActiveNode();
          var type = typeof node.data.availableQuantities != "undefined";
          switch (action) {
            case "edit": {
              var modal = type ? $("#editMenuItem.modal") : $("#editMenuCategory.modal");
              modal.modal("show", type ? $("button#editMenuItem") : $("button#editMenuCategory"));
              break;
            }
            case "replace": {
              var modal = type ? $("#editMenuItem.modal") : $("#editMenuCategory.modal");
              modal.modal("show", type ? $("button#editMenuItem") : $("button#editMenuCategory"));
              modal.find("input#action").val("replace");
              break;
            }
            case "new_category": {
              var modal = $("#editMenuCategory.modal");
              modal.modal("show", $("button#addMenuCategory"));
              break;
            }
            case "new_menu_item": {
              var modal = $("#editMenuItem.modal");
              modal.modal("show", $("button#addMenuItem"));
              break;
            }
            case "new_menu_option": {
              var modal = $("#editMenuItem.modal");
              modal.modal("show", $("button#addOption"));
              break;
            }
            case "delete": {
              deleteMenuItem();
              break;
            }
            case "sort_option": {
              var node = menuitemutil.menuItems.getActiveNode();
              node.sortChildren((a, b) => {
                var xm = (a.data.manufacturer || "").toLowerCase(),
                  ym = (b.data.manufacturer || "").toLowerCase();
                var x = a.title.toLowerCase(),
                  y = b.title.toLowerCase();

                if (xm && ym && xm.localeCompare(ym))
                  return xm.localeCompare(ym);

                return x.localeCompare(y);

              });
              var ids = [];
              node.children.forEach(function (d) {
                ids.push(d.data.entityType == menuitemutil.entityType ? 0 : 1);
                ids.push(d.data.id);
              });
              utils.updateMenuItemOrders(ids, function (data) {
                processMenuItems(data);
              });
              break;
            }
          }
        }
      },
      renderColumns: function (event, data) {
        var node = data.node,
          $tdList = $(node.tr).find(">td");

        $(node.tr).attr("level", node.data.depth);
        $(node.tr).data('node', data.node);

        node.setSelected(false);

        var editModeEnabled = Math.min(window.innerHeight, window.innerWidth) > 600;

        $(node.tr).attr('menuitem-id', node.data.id);

        var c = 1;


        if (node.data.manufacturer != null) $tdList.eq(1).prepend($('<span class="manufacturer mobile-only">' + node.data.manufacturer + "</span>"));

        if (node.data.availableQuantities && !node.data.defaultInventoryItem && getMenuItemAdditions(node.data).filter(a => a.additionType === "mandatory" && a.local === a.takeaway).length == 1) {
          const referred = getMenuItemAdditions(node.data).filter(a => a.additionType === "mandatory" && a.local === a.takeaway)[0];
          const path = menuitemutil.getPath(getMenuItem(referred.additionId));
          $tdList.eq(3).append($('<div class="referred browser-only">&rarr; ' + path + "</div>"));
        }

        $tdList.eq(c++).html(node.data.manufacturer);

        if (node.data.connectedRestaurants && !node.data.connectedRestaurants[0]) {
          $tdList.eq(0).find(".fancytree-title").css("text-decoration", "line-through")
        }

        if (editModeEnabled) {
          $tdList.eq(c).find("input").val(node.data.description[localStorage.languageSelected]);
          $tdList.eq(c).find("input").attr("id", "d_" + node.data.entityType + node.data.id);
        } else $tdList.eq(c).html(node.data.description[localStorage.languageSelected]);
        if (!showDescription)
          $tdList.eq(2).addClass('hidden');


        if (!showManufacturer)
          $tdList.eq(1).addClass('hidden');

        c++;

        c++;
        var mainCategory = admin_local.ntak.main_categories.find(c => c.id == node.data.defaultMainCategoryId);
        var subCategory = mainCategory ? mainCategory.sub_categories.find(c => c.id == node.data.defaultSubCategoryId) : undefined;
        const mainCategoryName = mainCategory ? mainCategory.name : admin_local.ntak.mainCategoryNotSet;
        const subCategoryName = subCategory ? subCategory.name : admin_local.ntak.subCategoryNotSet;
        mainCategory = mainCategory ? mainCategory.shortName ? mainCategory.shortName : mainCategory.name : node.data.mainCategorySet ? "" : "<div class='icon-attention'/>";
        subCategory = subCategory ? subCategory.shortName ? subCategory.shortName : subCategory.name : node.data.subCategorySet ? "" : "<div class='icon-attention'/>";
        var text = "<text data-toggle='tooltip' title='" + mainCategoryName + "' style='display: block;" + (node.data.mainCategoryId ? "" : "color:gray") + (node.data.mainCategorySet ? "" : ";background:red;color:yellow") + "'>" + mainCategory + "</text>" +
          "<text data-toggle='tooltip' title='" + subCategoryName + "' style='display: block;font-size:.8rem;" + (node.data.subCategoryId ? "" : "color:gray") + (node.data.subCategorySet ? "" : ";background:red;color:yellow") + "'>" + subCategory + "</text>";
        if (!node.data.subCategorySet || !node.data.mainCategorySet) {
          $tdList.eq(c).css('background', 'red');
        }

        $tdList.eq(c).html(text);
        c++;
        if (featureEnabled('ordering/menuorder')) {
          if (node.data.preorder !== null) {
            $tdList.eq(c).find("input").prop("checked", node.data.preorder);
          } else {
            $tdList.eq(c).find("input").prop("indeterminate", true);
            if (!node.data.defaultPreorder) {
              $tdList.eq(c).find("input").addClass("parentUnchecked");
            }
          }
          $tdList.eq(c).find("input").attr("id", "p_" + node.data.entityType + node.data.id);
          $tdList.eq(c).find("label").attr("for", "p_" + node.data.entityType + node.data.id);
          $tdList.eq(c).find("input").addClass("checkbox");

          if (node.data.defaultFromDate) {
            $tdList.eq(c).css("background", 'yellow');
            $tdList.eq(c).find(".fromDate").html(moment(node.data.defaultFromDate).format("MM-DD ddd"))
          }
          if (node.data.defaultToDate && node.data.defaultToDate !== node.data.defaultFromDate)
            $tdList.eq(c).find(".toDate").html(moment(node.data.defaultToDate).format("MM-DD ddd"))
          if (!node.data.fromDate)
            $tdList.eq(c).find(".fromDate").css("color", "lightgray");
          if (!node.data.toDate)
            $tdList.eq(c).find(".toDate").css("color", "lightgray");

          c++;
        }

        c++;
        /*if (featureEnabled('inventory')) {
          c++;
        }*/

        if (typeof node.data.menuItems != "undefined") {
          $tdList
            .eq(c)
            .prop("colspan", 4)
            .nextAll()
            .remove();
        }

        $tdList
          .eq(c)
          .html(
            node.data.timing && node.data.timing !== "inherit"
              ? getLocale2(auth.myStatus.restaurant_settings.timings[node.data.timing].langs).name
              : node.data.defaultTiming
                ? "<text style='color:gray'>" + (node.data.defaultTiming === "inherit" ? I18n.t("admin_local.timing_" + node.data.defaultTiming) : getLocale2(auth.myStatus.restaurant_settings.timings[node.data.defaultTiming].langs).name) + "</text>"
                : ""
          );

        var productionLines = "";
        /*
        if (node.data.productionLines.length > 0) {
          node.data.productionLines.forEach(pl => {
            productionLines += pl.name + "\n";
          });
        } else if (node.data.defaultProductionLineIds) {
          */
        productionLines = "";
        if (node.data.defaultProductionLineIds)
          node.data.defaultProductionLineIds.split(",").forEach(pl => {
            if (isNaN(pl))
              return;
            if (node.data.productionLines.find(p => p.id == pl))
              productionLines += "<text style='color:black'>" + getProductionLine(pl).name + "\n" + "</text>";
            else
              productionLines += "<text style='color:gray'>" + getProductionLine(pl).name + "\n" + "</text>";
          });

        c++;
        $tdList.eq(c).html(productionLines);
        c++;
        $tdList
          .eq(c)
          .html(
            (node.data.vatCategory
              ? "<div>" + (node.data.vatCategory ? (node.data.vatCategory.percent ? " " + node.data.vatCategory.percent + "%" : "TAM") : "") + "</div>"
              : node.data.defaultVATCategory
                ? "<div style='color:gray'>" + (node.data.defaultVATCategory ? (node.data.defaultVATCategory.percent ? " " + node.data.defaultVATCategory.percent + "%" : "TAM") : "") + "</div>"
                : "") +
            (node.data.takeawayVatCategory
              ? "<div class='icon-cup'>" + (node.data.takeawayVatCategory ? (node.data.takeawayVatCategory.percent ? " " + node.data.takeawayVatCategory.percent + "%" : "TAM") : "") + "</div>"
              : "<div class='icon-cup' style='color:gray'>" + (node.data.defaultTakeawayVATCategory ? (node.data.defaultTakeawayVATCategory.percent ? " " + node.data.defaultTakeawayVATCategory.percent + "%" : "TAM") : "27%") + "</div>")
          );
        c++;
        if (node.data.isActive) {
          $tdList.eq(c).find("input").prop("checked", node.data.isActive);
          $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.active"));
        } else {
          $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.deactived"));
        }

        $tdList.eq(c).find("input").attr("id", "a_" + node.data.entityType + node.data.id);
        $tdList.eq(c).find("label").attr("for", "a_" + node.data.entityType + node.data.id);
        $tdList.eq(c).find("input").addClass("checkbox isActive");
        c++;
        if (node.data.isTop) {
          $tdList.eq(c).find("input").prop("checked", node.data.isTop);
          $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.on_menu"));
          if (!node.data.defaultTop) {
            $tdList.eq(c).find("input").prop("checked", false);
            $tdList.eq(c).find("input").prop("disabled", true);
            $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.not_on_menu"));
          }
        } else {
          $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.not_on_menu"));
        }
        $tdList.eq(c).find("input").attr("id", "t_" + node.data.entityType + node.data.id);
        $tdList.eq(c).find("label").attr("for", "t_" + node.data.entityType + node.data.id);
        $tdList.eq(c).find("input").addClass("checkbox isTop");

        var cc = c;

        if (featureEnabled("wolt"))
          getPlaces("wolt").filter(p => p.enabled).forEach(p => {
            const act = node.data.mergedSettings.wolt && node.data.mergedSettings.wolt[p.name] && node.data.mergedSettings.wolt[p.name].enabled;
            $tdList.eq(++c).find("input").attr("id", "td_wolt_" + p.name + "_" + node.data.entityType + node.data.id);
            $tdList.eq(c).find("label").attr("for", "td_wolt_" + p.name + "_" + node.data.entityType + node.data.id);
            $tdList.eq(c).find("input").prop("checked", act);
          })
        if (featureEnabled("foodpanda"))
          getPlaces("foodpanda").filter(p => p.enabled).forEach(p => {
            const act = node.data.settings.foodpanda && node.data.settings.foodpanda[p.name] && node.data.settings.foodpanda[p.name].enabled;
            $tdList.eq(++c).find("input").attr("id", "td_foodpanda_" + p.name + "_" + node.data.entityType + node.data.id);
            $tdList.eq(c).find("label").attr("for", "td_foodpanda_" + p.name + "_" + node.data.entityType + node.data.id);
            $tdList.eq(c).find("input").prop("checked", act);
          })
        if (featureEnabled("falatozz"))
          getPlaces("falatozz").filter(p => p.enabled).forEach(p => {
            const act = node.data.settings.falatozz && node.data.settings.falatozz[p.name] && node.data.settings.falatozz[p.name].enabled;
            $tdList.eq(++c).find("input").attr("id", "td_falatozz_" + p.name + "_" + node.data.entityType + node.data.id);
            $tdList.eq(c).find("label").attr("for", "td_falatozz_" + p.name + "_" + node.data.entityType + node.data.id);
            $tdList.eq(c).find("input").prop("checked", act);
          })

        if (featureEnabled('ordering') || featureEnabled('foodpanda') || featureEnabled('wolt') || featureEnabled('falatozz')) {
          c++;
          if (node.data.online) {
            $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.online"));
            $tdList.eq(c).find("input").prop("checked", node.data.online);
          } else {
            $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.not_online"));
          }
          $tdList.eq(c).find("input").attr("id", "o_" + node.data.entityType + node.data.id);
          $tdList.eq(c).find("label").attr("for", "o_" + node.data.entityType + node.data.id);
          $tdList.eq(c).find("input").addClass("checkbox online");

          c++;
          if (node.data.available) {
            $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.available_online"));
            $tdList.eq(c).find("input").prop("checked", node.data.available);
          } else {
            $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.not_available_online"));
          }
          $tdList.eq(c).find("input").attr("id", "av_" + node.data.entityType + node.data.id);
          $tdList.eq(c).find("label").attr("for", "av_" + node.data.entityType + node.data.id);
          $tdList.eq(c).find("input").addClass("checkbox available");
        }
        if (featureEnabled('inventory')) {
          c++;
          if (node.data.inventoryItem !== null) {
            if (node.data.inventoryItem)
              $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.inventory_item"));
            else
              $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.not_inventory_item"));
            $tdList.eq(c).find("input").prop("checked", node.data.inventoryItem);
          } else {
            if (node.data.defaultInventoryItem)
              $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.default_inventory_item"));
            else
              $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.default_not_inventory_item"));
            $tdList.eq(c).find("input").prop("indeterminate", true);
            if (!node.data.defaultInventoryItem) {
              $tdList.eq(c).find("input").addClass("parentUnchecked");
            }
          }
          $tdList.eq(c).find("input").attr("id", "i_" + node.data.entityType + node.data.id);
          $tdList.eq(c).find("label").attr("for", "i_" + node.data.entityType + node.data.id);
          $tdList.eq(c).find("input").addClass("checkbox isInventoryItem");
        }

        c++;
        if (node.data.quantityType != "none") {
          try {
            $tdList.eq(c).find("input").attr("id", "f_" + node.data.entityType + node.data.id);
            $tdList.eq(c).find("label").attr("for", "f_" + node.data.entityType + node.data.id);
            $tdList.eq(c).find("input").addClass("checkbox freeQuantity");

            if (!node.data.freeQuantity) {
              $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.not_free_quantity"));
              $tdList.eq(c).find("input").prop("checked", !node.data.freeQuantity);
            } else {
              $tdList.eq(c).attr("title", I18n.t("admin_local.menuitems.tooltip.free_quantity"));
            }

            if (node.data.quantityType)
              $tdList.eq(c).find('span').text(I18n.t("local." + node.data.quantityType));

          } catch (ex) {
            console.log(ex);
            if (node.data.quantityType)
              $tdList.eq(c).find('span').text(node.data.quantityType);
          }
        } else {
          $tdList.eq(1).addClass("option");
          const hiddenIndexes = [2, 3, 4, 5, 12, 13, 14, 15, 16, 17];

          hiddenIndexes.forEach(index => {
            $tdList.eq(index).css("visibility", "hidden");
          });
        }

        var strQuantity = "";
        var strPrice = "";
        var strPricePerCostRatio = "";
        var strPlatformPrice = { wolt: {}, foodpanda: {}, falatozz: {}, takeaway: {}, webshop: {} };
        var inventoryItemFound = false;

        if (node.data.availableQuantities) {
          node.data.availableQuantities.forEach(function (quantityAndPrice) {
            var label = quantityAndPrice?.quantityType?.name;
            label = label && (label[localStorage.languageSelected] ? label[localStorage.languageSelected] : label["all"] ? "(" + label["all"] + ")" : "");
            if (node.data.quantityType !== "none") {
              var clazz = node.data.defaultInventoryItem && quantityAndPrice.inventoryItem ? 'class="icon-exchange" ' : "";
              if (!quantityAndPrice.onMenu) strQuantity += "<text data-toggle='tooltip' title='" + I18n.t('admin_local.menuitems.tooltip.' + (quantityAndPrice.def ? "def_" : "") + (node.data.defaultInventoryItem && quantityAndPrice.inventoryItem ? 'is_inventory_item' : 'non_inventory_item')) + '" id="' + quantityAndPrice.quantity + "'" + clazz + " style='color:gray;background-color:" + (quantityAndPrice.def && node.data.defaultInventoryItem ? "yellow" : "") + "'>";
              else strQuantity += "<text data-toggle='tooltip' title='" + I18n.t('admin_local.menuitems.tooltip.' + (quantityAndPrice.def ? "def_" : "") + (node.data.defaultInventoryItem && quantityAndPrice.inventoryItem ? 'on_menu_is_inventory_item' : 'on_menu_non_inventory_item')) + "'  id='" + quantityAndPrice.quantity + "'" + clazz + '>';
              if (quantityAndPrice.def) strQuantity += "<b>";
              strQuantity += '<span id="serving">' + label + "</span><span>(" + quantityAndPrice.quantity + " " + I18n.t("local." + node.data.quantityType) + ")" + "</span><br/>";
              var price = quantityAndPrice.price;
              var priceAmount;
              if (price === "") {
                priceAmount = Math.round(node.data.unitPrice * quantityAndPrice.quantity);
                if (editModeEnabled) price = '<input class="unitPrice" style="text-align:right" pattern="[0-9]+\.[0-9]+%?" id="p_' + node.data.id + "-" + quantityAndPrice.quantity + '" quantity="' + quantityAndPrice.quantity + '" value="' + priceAmount + '" type="text"/>';
                else
                  price = "<span>" + Math.round(priceAmount) + "</span>";
              } else {
                priceAmount = price;
                if (editModeEnabled) price = '<input style="text-align:right" pattern="[0-9]+\.[0-9]+%?" id="p_' + node.data.id + "-" + quantityAndPrice.quantity + '" quantity="' + quantityAndPrice.quantity + '" " value="' + (isNaN(price) ? price : Math.round(price)) + '" type="text"/>';
                else price = "<span>" + price + "</span>";
              }
              //if (isNaN(price))
              //  strPrice += quantityAndPrice.localPrice + "<br/>";
              //else
              strPrice += price + "<br/>";
              if (quantityAndPrice.def) strQuantity += "</b>";
              strQuantity += "</text>";
              if (node.data.costEstimation > 0 && quantityAndPrice.localPrice > 0 && node.data.defaultVATCategory) {
                const pricePerCostRatio = Math.round((quantityAndPrice.localPrice / (100 + node.data.defaultVATCategory.percent) * 100 - node.data.costEstimation * quantityAndPrice.quantity) / (node.data.costEstimation * quantityAndPrice.quantity) * 100);
                const style = "width:100%; background: linear-gradient(90deg, rgb(222 121 45 / 44%) 0%, rgb(222 121 45 / 44%) " + (pricePerCostRatio / maxProfitRatio) * 100 + "%, rgba(0,0,0,0) " + (pricePerCostRatio / maxProfitRatio) * 100 + "%, rgba(0,0,0,0) 100%);";
                strPricePerCostRatio += "<div style=\"" + style + "\">" + pricePerCostRatio + "%</div>";
              }

              if (quantityAndPrice.settings) {

                // Helper function to add platform checkboxes
                function addPlatformCheckboxes(platform, enabledFeatures, quantityAndPrice, node, strPlatformPrice) {
                  enabledFeatures[platform]?.places && enabledFeatures[platform].places
                    .filter(place => place.enabled)
                    .forEach(place => {
                      const { name } = place;
                      const platformSettings = quantityAndPrice.settings?.[platform];
                      const platformNameSettings = platformSettings?.[name];
                      const disabled = platformNameSettings && !platformNameSettings.enabled;

                      const checkBoxId = `${platform}-${name}-${quantityAndPrice.quantity}-${node.data.id}`;
                      const missingImageClass = node.data.defaultActive && node.data.defaultTop && !disabled && !node.data.image && !node.data[platform + "Image"] ? "missing-image" : "";

                      // Ensure the platform key exists within strPlatformPrice
                      if (!strPlatformPrice[platform][name]) {
                        strPlatformPrice[platform][name] = "";
                      }

                      // Append HTML structure to the corresponding platform
                      strPlatformPrice[platform][name] += `
                        <div class="${missingImageClass} custom-control custom-checkbox" style="display: flex; justify-content: center; padding-left: 2.5rem">
                          <input type="checkbox" class="custom-control-input checkbox thirdParty" id="${checkBoxId}" ${disabled ? '' : 'checked'} />
                          <label class="custom-control-label" for="${checkBoxId}"></label>
                        </div>`;
                    });
                }

                // Call the helper function for each platform
                ['wolt', 'foodpanda', 'falatozz'].forEach(platform => {
                  addPlatformCheckboxes(platform, enabledFeatures, quantityAndPrice, node, strPlatformPrice);
                });


              }
              inventoryItemFound = inventoryItemFound || (node.data.defaultInventoryItem && quantityAndPrice.inventoryItem);
            }
          });


          const inventoryItemCount = node.data.availableQuantities.filter(a => a.inventoryItem).length;
          const nonInventoryItemCount = node.data.availableQuantities.filter(a => !a.inventoryItem).length;
          if (node.data.defaultInventoryItem) {
            if (inventoryItemCount == 0) {
              $(node.tr).find(".availablequantity.availablequantity1").css("background-color", "yellow");
              $(node.tr).find(".availablequantity.availablequantity1").attr("title", I18n.t("admin_local.menuitems.tooltip.no_inventory_quantity_selected"));
            } else if (nonInventoryItemCount > 0 && inventoryItemCount != 1) {
              $(node.tr).find(".availablequantity.availablequantity1").css("background-color", "yellow");
              $(node.tr).find(".availablequantity.availablequantity1").attr("title", I18n.t("admin_local.menuitems.tooltip.to_many_inventory_quantity_selected"));
            }
          }
        } else {
          node.addClass("menu-category");
        }

        if (featureEnabled('inventory')) {
          $tdList.eq(3).css("visibility", "visible");
          if (node.data.availableQuantities && node.data.availableQuantities.length) {
            const parent = getMenuCategory(node.data);
            const isOnMenu = isOnTop(node.data);
            const usedAsMandatoryOrIncluded = menuItemReferences(node.data, ['mandatory', 'included']).length > 0;
            const usedAsOptional = menuItemReferences(node.data, ['optional']).length + menuItemReferences(parent, ['optional']).length > 0;
            const linked = getMenuItemAdditions(node.data).filter(a => a.additionType === "mandatory").length == 1;
            var prefix = "";
            if (isOnMenu)
              prefix = "sold"
            if (inventoryItemFound) {
              prefix += "_inventory" + (usedAsMandatoryOrIncluded ? "_ingredient" : "") + (usedAsOptional ? "_option" : "");
            } else if (getMenuItemAdditions(node.data).length) {
              if (linked)
                prefix += "_linked_product" + (usedAsMandatoryOrIncluded ? "_ingredient" : "") + (usedAsOptional ? "_option" : "");
              else
                prefix += "_complex_product" + (usedAsMandatoryOrIncluded ? "_ingredient" : "") + (usedAsOptional ? "_option" : "");
            } else
              prefix += "_service" + (usedAsMandatoryOrIncluded ? "_ingredient" : "") + (usedAsOptional ? "_option" : "");
            $tdList.eq(3).find("text").html(I18n.t("admin_local.menuitems." + prefix));
            $tdList.eq(3).attr("title", I18n.t("admin_local.menuitems.tooltip." + prefix))
          } else {
            if (node.data.availableQuantities)
              $tdList.eq(3).find("text").html("opció");
            else if (node.data.menuCategoryId)
              $tdList.eq(3).find("text").html("alkategória");
            else
              $tdList.eq(3).find("text").html("főkategória");
            $tdList.eq(3).attr("title", "")
          }
        }
        if (node.data.entityType == "Drink" || node.data.entityType == "Meal") {


          if (node.data.availableQuantities) {
            $tdList.eq(++c).html(strQuantity);
            $tdList.eq(++c).html(strPrice);

            var costId = c;
            c++;
            if (node.data.cost + node.data.costEstimation > 0) {
              if (node.data.defaultInventoryItem && node.data.availableQuantities.filter(q => q.inventoryItem).length > 0 && node.data.defaultInventoryItem && node.data.availableQuantities.filter(q => !q.inventoryItem).length == 0) {
                var t = "";
                node.data.availableQuantities.filter(q => q.inventoryItem).forEach(qq => {
                  t += "<div>" + Math.round(((node.data.costEstimation < 10 ? node.data.costEstimation : Math.round(node.data.costEstimation)) * qq.quantity)) + "</div>";
                })
                $tdList.eq(c).html(t);
              } else if (node.data.defaultInventoryItem && node.data.availableQuantities.filter(q => q.inventoryItem).length === 1 && node.data.defaultInventoryItem && node.data.availableQuantities.filter(q => !q.inventoryItem).length > 0) {
                var t = "";
                node.data.availableQuantities.forEach(qq => {
                  t += "<div>" + Math.round(((node.data.costEstimation < 10 ? node.data.costEstimation : Math.round(node.data.costEstimation)) * qq.quantity)) + "</div>";
                })
                $tdList.eq(c).html(t);
              } else {
                $tdList.eq(c).html("<div>" + (node.data.costEstimation < 10 ? node.data.costEstimation : Math.round(node.data.costEstimation)) + "/" + I18n.t("local." + node.data.quantityType) + "</div>");
              }
            }
            c++;
            if (node.data.cost + node.data.costEstimation > 0) {

              if (true || node.data.defaultInventoryItem && node.data.availableQuantities.filter(q => q.inventoryItem).length > 0 && node.data.defaultInventoryItem && node.data.availableQuantities.filter(q => !q.inventoryItem).length == 0) {
                var t = "";
                node.data.availableQuantities/*.filter(q => q.inventoryItem)*/.forEach(qq => {
                  const p = Math.round(((qq.inventoryItem ? qq.cost : node.data.costEstimation * qq.quantity) * (2 * node.data.defaultPricePerCostRatio)) * (100 + (node.data?.defaultVATCategory?.percent || 0)) / 100);
                  if (p) {
                    t += (node.data.unitPriceEstimation > 0 ? "<div><span data-toggle='tooltip' title='" + I18n.t('admin_local.menuitems.tooltip.suggested_price') + "'>" + p + "</span>" : "") + (node.data.defaultInventoryItem ? "<span data-toggle='tooltip' title='" + I18n.t('admin_local.menuitems.tooltip.expected_profit') + "' style='font-size:.7rem'>(" + (node.data.defaultPricePerCostRatio * 100) + "%)</span></div>" : "");
                    if (p > qq.localPrice)
                      $tdList.eq(c).addClass("warning")
                  } else {
                    t += "<div>1</div>";
                  }
                })
                $tdList.eq(c).html(t);
              } else if (node.data.defaultInventoryItem && node.data.availableQuantities.filter(q => q.inventoryItem).length === 1 && node.data.defaultInventoryItem && node.data.availableQuantities.filter(q => !q.inventoryItem).length > 0) {
                var t = "";
                node.data.availableQuantities.forEach(qq => {
                  //t += "<div>" + Math.round(((node.data.costEstimation < 10 ? node.data.costEstimation : Math.round(node.data.costEstimation)) * qq.quantity)) + "</div>";
                })
                $tdList.eq(c).html(t);
              } else {
                $tdList.eq(c).html("<div>" + (node.data.costEstimation < 10 ? node.data.costEstimation : Math.round(node.data.costEstimation)) + "/" + I18n.t("local." + node.data.quantityType) + "</div>");
              }
              /*
              $tdList.eq(c).html("<div data-toggle='tooltip' title='" + I18n.t('admin_local.menuitems.tooltip.unit_price') + "'>" + Math.round(node.data.unitPrice) + "</div>" +
                (node.data.unitPriceEstimation > 0 ? "<div data-toggle='tooltip' title='" + I18n.t('admin_local.menuitems.tooltip.suggested_price') + "'>(" + Math.round(node.data.unitPriceEstimation) + ")</div>" : "") + (node.data.defaultInventoryItem ? "<div data-toggle='tooltip' title='" + I18n.t('admin_local.menuitems.tooltip.expected_profit') + "' style='font-size:.7rem'>(" + (node.data.defaultPricePerCostRatio * 100) + "%)</div>" : ""));
  
              if (node.data.unitPriceEstimation > 0 && Math.round(node.data.unitPriceEstimation) > Math.round(node.data.unitPrice)) {
                if (isOnTop(node.data) || menuItemReferences(node.data, ['included', 'optional']).length > 0)
                  $tdList.eq(c).addClass("warning")
              }*/
            }

            const parent = getMenuCategory(node.data);
            if (featureEnabled('inventory')) {
              const avg = (parent.averageProfitRatio / maxProfitRatio) * 100;
              const style = "position:absolute; left:0;top:0;width:100%;height:100%;background: linear-gradient(90deg, rgb(0,0,0,0) 0%, rgb(0,0,0,0) " + avg + "%, black " + (avg + 1) + "%, black " + (avg + 1) + "%, rgba(0,0,0,0) " + avg + "%, rgba(0,0,0,0) 100%);";
              $tdList.eq(++c).html("<div style=\"" + style + "\"/>" + strPricePerCostRatio);
            }
            if (featureEnabled("wolt"))
              getPlaces("wolt").filter(p => p.enabled).forEach(p => {
                const act = parent.settings.wolt && parent.settings.wolt[p.name] && parent.settings.wolt[p.name].enabled;
                if (act && strPlatformPrice["wolt"][p.name])
                  $tdList.eq(++cc).html(strPlatformPrice["wolt"][p.name]);
                else
                  $tdList.eq(++cc).html("");
              })
            if (featureEnabled("foodpanda"))
              getPlaces("foodpanda").filter(p => p.enabled).forEach(p => {
                const act = parent.settings.foodpanda && parent.settings.foodpanda[p.name] && parent.settings.foodpanda[p.name].enabled;
                if (act && strPlatformPrice["foodpanda"][p.name]) {
                  $tdList.eq(++cc).html(strPlatformPrice["foodpanda"][p.name]);
                } else {
                  $tdList.eq(++cc).html("");
                }
              })
            if (featureEnabled("falatozz"))
              getPlaces("falatozz").filter(p => p.enabled).forEach(p => {
                const act = parent.settings.falatozz && parent.settings.falatozz[p.name] && parent.settings.falatozz[p.name].enabled;
                if (act && strPlatformPrice["falatozz"][p.name]) {
                  $tdList.eq(++cc).html(strPlatformPrice["falatozz"][p.name]);
                } else {
                  $tdList.eq(++cc).html("");
                }
              })
          }
        } else {
          $tdList.eq(c++).css("visibility", "hidden");
          $tdList.eq(c++).css("visibility", "hidden");
          $tdList.eq(c++).css("visibility", "hidden");
          $tdList.eq(c++).css("visibility", "hidden");
          if (featureEnabled('inventory')) {
            const avg = (node.data.averageProfitRatio / maxProfitRatio) * 100;
            const min = (node.data.minProfitRatio / maxProfitRatio) * 100;
            const max = (node.data.maxProfitRatio / maxProfitRatio) * 100;
            const style = "display:flex;justify-content:center;align-items:center;position:absolute; left:0;top:0;width:100%;height:100%;background: linear-gradient(90deg, rgb(222 121 45 / 44%) 0%, rgb(222 121 45 / 44%) " + min + "%, rgb(187 102 64 / 75%) " + min + "%, rgb(187 102 64 / 75%) " + avg + "%, black " + avg + "%, black " + (avg + 1) + "%, rgb(187 102 64 / 75%) " + avg + 1 + "%, rgb(187 102 64 / 75%) " + max + "%, rgba(0,0,0,0) " + max + "%, rgba(0,0,0,0) 100%);";
            $tdList.eq(++c).html("<div style=\"" + style + "\"><text style=\"\">" + Math.round(node.data.averageProfitRatio) + "%</text></div>");
          }
        }

        if (node.title == "???") {
          $tdList.eq(1).addClass("itemWarning");
        }

        var labels = "";
        node.data.labels.forEach(function (label) {
          if (labels != "") labels += ", ";
          labels += label.name;
        });
        c++;
        $tdList.eq(c).text(labels);
        var $span = $(node.span);

        if (node.data.defaultColor != "") {
          const td = $(node.tr).find("td.picture");
          td.css("background-color", node.data.defaultColor);
        }

        if (node.data.image) {
          const td = $(node.tr).find("td.picture img");
          td.removeClass("hidden");

          function onVisible(element, callback) {
            new IntersectionObserver((entries, observer) => {
              entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                  callback(element);
                  observer.disconnect();
                }
              });
            }).observe(element);
            if (!callback) return new Promise(r => callback = r);
          }
          onVisible(node.tr, () => {
            setTimeout(() => {
              if (!data.node.data.connectedRestaurants || data.node.data.connectedRestaurants[0])
                td.attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + node.data.image + "?timestamp=" + node.data.modificationTimestamp);
              else {
                const r = menuitemutil.getRestaurant(Number(Object.keys(data.node.data.connectedRestaurants)[0]));
                td.attr("src", r.serverUrl + auth.war + "/" + r.instance + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + node.data.image + "?timestamp=" + node.data.modificationTimestamp + "&instance=" + r.instance);
              }
              $tdList.eq(0).find("img").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + node.data.image + "?timestamp=" + node.data.modificationTimestamp);
            }, Math.random() * 1000);
          })

          $tdList.eq(0).prepend($('<img class="mobile-only" src=""/>'));
          $tdList.eq(0).find("img").removeClass("hidden");

        } else {
          // $span.find("img").attr('src', getImageById(1));
        }
        $span.find("img").attr("style", "width:48px;height:48px");



        auth.myStatus.restaurant_copies.forEach(r => {
          c++;
          const t = $tdList.eq(c);
          const selected = localStorage['restaurant_copy_selected_' + r] == 'true';
          if (!selected)
            t.hide();
          else {
            t.show();
            if (data.node.data.connectedRestaurants) {
              if (data.node.data.connectedRestaurants[0] && data.node.data.connectedRestaurants[r]) {
                if (data.node.data.equalRestaurants.indexOf(r) !== -1) {
                  t.find("button.icon-edit").css("color", "red");
                  t.find("button.icon-edit").attr("title", "");
                } else {
                  t.find("button.icon-edit").css("color", "lightgreen");
                }
                t.find("button.icon-edit").attr("title", data.node.data.compareLocalToRemote);

                t.find("button.icon-edit").on("click", () => {
                  if (data.node.data.connectedRestaurants[r].availableQuantities) {
                    const modal = $('#editMenuItem.modal');
                    modal.modal("show");
                    populateEditMenuItem(modal, "edit", data.node.data.connectedRestaurants[r], data.node.data.connectedRestaurants[r].menuCategoryId)
                  } else {
                    const modal = $('#editMenuCategory.modal');
                    modal.modal("show");
                    populateEditCategory(modal, "edit", data.node.data.connectedRestaurants[r], data.node.data.connectedRestaurants[r].menuCategoryId)
                  }
                });
                t.find("button.icon-edit").css("visibility", "visible");
                t.find("button.icon-left").prop("disabled", false);
                t.find("button.icon-right").prop("disabled", false);
                t.find("button.icon-left").on("click", () => menuitemutil.updateLocal(data.node.data, r));
                t.find("button.icon-right").on("click", () => menuitemutil.updateRemote(data.node.data, r));
              } else if (data.node.data.connectedRestaurants[r]) {
                t.find("button.icon-left").prop("disabled", false);
                t.find("button.icon-left").on("click", () => menuitemutil.updateLocal(data.node.data, r));
                t.find("button.icon-edit").css("visibility", "hidden");
              } else if (data.node.data.connectedRestaurants[0]) {
                t.find("button.icon-right").prop("disabled", false);
                t.find("button.icon-right").on("click", () => menuitemutil.updateRemote(data.node.data, r));
                t.find("button.icon-edit").css("visibility", "hidden");
              }
            }
          }
        })

        if (!editModeEnabled) $tdList.find('input[type!="checkbox"]').prop("disabled", true);
      }
    });

    menuitemutil.menuItems.filterNodes(function (node) {
      if (node?.data?.entityType && node.data.entityType.startsWith(menuitemutil.entityType)) {
        if (menuitemutil.filter.name) {
          const c = removeAccents(getLocale(node.data.name, localStorage.languageSelected));
          if (c.toLowerCase().indexOf(removeAccents(menuitemutil.filter.name).toLowerCase()) === -1
          )
            return false;
        }
        return true;
      }
      return false;
    });

    if (window.innerWidth <= 800 && typeof localStorage.categoryexpanded !== "undefined" && localStorage.categoryexpanded && localStorage.categoryexpanded !== "null") {
      const m = menuitemutil.getMenuItem(localStorage.categoryexpanded);
      if (m && m.entityType.startsWith(menuitemutil.entityType)) {
        menuitemutil.menuItems.filterBranches(
          function (node) {
            if (!localStorage.categoryexpanded)
              return true;
            if (node.data.entityType.indexOf("Category") === -1) {
              return node.parent.data.id == localStorage.categoryexpanded;
            }
            return node.data.id == localStorage.categoryexpanded;
          },
          {
            mode: "hide"
          }
        );
      }
    }

  } else {
    $("#editMenuCategory").hide();
    $("#editMenuItem").hide();
    $("#addMenuItem").attr("disabled", "true");
    $("#addOption").attr("disabled", "true");
    $("#activate").prop("disabled", "true");
    $("#deactivate").prop("disabled", "true");
    $("#delete").prop("disabled", "true");
    menuitemutil.menuItems.reload(mergedData);
    $("input#" + focus).focus();
  }
  tooltip($("[data-toggle = 'tooltip']"));
  menuitemutil.tablehooks();
}

function tooltip(items) {
  items.tooltip({ trigger: 'hover' });
  items.on('shown.bs.tooltip', function () {
    if (auth.tooltip != this) {
      $(auth.tooltip).tooltip('hide')
      auth.tooltip = this;
      setTimeout(() => {
        $(auth.tooltip).tooltip('hide')
        delete auth.tooltip;
      }, 5000)
    }
  })
}


function handleCheckBoxChange(t, handler) {
  var indeterminate = $(t).prop("indeterminate");
  var checked_old = $(t).prop("checked_old");
  var checked = $(t).prop("checked");
  if ($(t).prop("checked") && checked_old != undefined && (indeterminate == undefined || indeterminate == false)) {
    $(t).prop("indeterminate", true);
    $(t).prop("checked", false);
    $(t).removeProp("checked_old");
    handler(t);
    return;
  }
  $(t).prop("checked_old", $(t).prop("checked"));
  handler(t);
}

var menuItemQuantities = null;
var inventoryItemsCount = 0;
var menuItemQuantitiesData;
function processMenuItemQuantities(data, menuitem) {
  if (data)
    data.forEach(d => {
      d.selected = d.def;
    });
  inventoryItemsCount = 0;
  menuItemQuantitiesData = data;

  const webshopEnabled = featureEnabled('ordering/enabled') && ((featureEnabled('pickup/enabled') && featureEnabled('pickup/webshop')) || (featureEnabled('homedelivery/enabled') && featureEnabled('homedelivery/webshop')));

  const data2 = [];
  data.forEach(d => {
    data2.push(d);
    d.places = [];

    if (webshopEnabled) {
      const p = {
        name: "default"
      }
      d.places.push(p);
      p.type = "webshop";
      d.rowCount++;
    }

    if (featureEnabled("wolt"))
      getPlaces("wolt").forEach(p => {
        d.places.push(p);
        p.type = "wolt";
        d.rowCount++;
      })
    if (featureEnabled("foodpanda"))
      getPlaces("foodpanda").forEach(p => {
        d.places.push(p);
        p.type = "foodpanda";
        d.rowCount++;
      })
    if (featureEnabled("falatozz"))
      getPlaces("falatozz").forEach(p => {
        d.places.push(p);
        p.type = "falatozz";
        d.rowCount++;
      })
  })
  $("div#editMenuItem .price-block").show();
  if (menuItemQuantities == null)
    menuItemQuantities = createTree($("table#menuItemQuantities"), {
      checkbox: "radio",
      titlesTabbable: false, // Add all node titles to
      // TAB chain
      source: data2,
      selectMode: 1,
      zindex: 1000,
      keyboard: false,
      extensions: ["table", "gridnav"],
      icon: false,
      activeVisible: true,
      table: {
        checkboxColumnIdx: 0, // render the checkboxes into
        // into the this
        // column index (default: nodeColumnIdx)
        indentation: 16, // indent every node level
        // by 16px
        nodeColumnIdx: 0,
        // render node expander, icon, and title to this
        // column (default:
        // #0)
      },
      gridnav: {
        autofocusInput: false, // Focus first embedded
        // input if node
        // gets activated
        handleCursorKeys: true,
        scrollIntoView: false,
        // Allow UP/DOWN in inputs to move to prev/next node
      },

      activate: function (event, data) {
        // A node was activated: display its title:
        var node = data.node;
        $("#deleteQuantity").removeAttr("disabled");
        if (node.data.isActive) {
          $("#deactivateQuantity").removeAttr("disabled");
          $("#activateQuantity").prop("disabled", "true");
        } else {
          $("#deactivateQuantity").prop("disabled", "true");
          $("#activateQuantity").removeAttr("disabled");
        }
      },
      previousNode: null,
      click: function (event, data) {
        if (event.ctrlKey != true) return;
        if (menuitemutil.menuItems == null) return;
        if (data.node == menuitemutil.menuItems.previousNode) {
          menuitemutil.menuItems.activateKey("");
          event.preventDefault();
          menuitemutil.menuItems.previousNode = null;
        } else {
          menuitemutil.menuItems.previousNode = data.node;
        }
      },
      renderColumns: function (event, data) {
        var node = data.node,
          $tdList = $(node.tr).find(">td");

        var c = 0;
        var onMenu = node.data.onMenu;
        $tdList
          .eq(++c)
          .find("input.onMenu")
          .attr("id", "m_" + node.key);
        $tdList
          .eq(c)
          .find("input.onMenu")
          .prop("checked", onMenu);
        $tdList
          .eq(c)
          .find("label[for='onMenu'")
          .attr("for", "m_" + node.key);
        $tdList
          .eq(c)
          .find("input")
          .addClass("checkbox");

        var inventory = $tdList.eq(++c).find("input");
        inventory.attr("id", "q_" + node.key);
        inventory.change(function () {
          if ($(this).prop("checked") && price.val() == "" && !isNaN(price.attr("placeholder"))) {
            price.val(price.attr("placeholder"));
          }
        });

        var unitPrice = $("#editMenuItem input#unit_price").val();
        var minimumPrice = $("#editMenuItem input#menuItem_minimumPrice").prop("checked");

        const parentCategoryId = menuitemutil?.data?.menuCategoryId || $("#editMenuItem #parentCategoryId").val();
        const parentCategory = menuitemutil.data ? getMenuCategory(menuitemutil.data) : menuitemutil.getMenuCategory(parentCategoryId);
        const parentCategoryInventoryItem = parentCategory.inventoryItem === null ? parentCategory.defaultInventoryItem : parentCategory.inventoryItem;
        if ($("#editMenuItem #mi_isInventoryItem").prop("checked")) {
          inventory.parent().parent().removeClass("hidden");
          $("th.mi_inventoryItem").removeClass('hidden');
          inventory.prop("checked", node.data.inventoryItem);
          unitPrice = menuitemutil.data ? menuitemutil.data.unitPrice : node.data.unitPrice;
        } else if ($("#editMenuItem #mi_isInventoryItem").prop("indeterminate") && parentCategoryInventoryItem) {
          inventory.parent().parent().removeClass("hidden");
          $("th.mi_inventoryItem").removeClass('hidden');
          inventory.prop("checked", node.data.inventoryItem);
          unitPrice = menuitemutil.data ? menuitemutil.data.unitPrice : node.data.unitPrice;
        } else {
          inventory.parent().parent().addClass("hidden");
          $("th.mi_inventoryItem").addClass('hidden');
        }
        $tdList.eq(c).find("label").attr("for", "q_" + node.key);
        inventory.addClass("checkbox");

        $tdList.eq(1).find("div.thirdParty").each((ind, d) => {
          const type = $(d).attr('type');
          const name = $(d).attr('name');
          $(d).find("input").prop("id", "onMenu_" + node.data.quantity + "_" + type + "_" + name);
          $(d).find("label").prop("for", "onMenu_" + node.data.quantity + "_" + type + "_" + name);
        })

        function showPlace(key, name) {
          const show = menuitemutil.data && menuitemutil.data.mergedSettings && menuitemutil.data.mergedSettings[key] && menuitemutil.data.mergedSettings[key][name] && menuitemutil.data.mergedSettings[key][name].enabled;
          const checkbox = $tdList.eq(1).find("div[type='" + key + "'][name='" + name + "']");
          const label = $tdList.eq(3).find("label#label_" + key + "_" + name);
          const price = $tdList.eq(5).find("input#menuItem_price_" + key + "_" + name);
          if (show) {
            checkbox.removeClass("hidden");
            label.removeClass("hidden");
            price.removeClass("hidden");
          } else {
            checkbox.addClass("hidden");
            label.addClass("hidden");
            price.addClass("hidden");
          }
          checkbox.find('input').prop('checked', true);
        }

        if (featureEnabled("wolt")) {
          getPlaces("wolt").filter(p => p.enabled).forEach(place => {
            const key = "wolt";
            const name = place.name;
            showPlace(key, name);
          });
        }
        if (featureEnabled("foodpanda")) {
          getPlaces("foodpanda").filter(p => p.enabled).forEach(place => {
            const key = "foodpanda";
            const name = place.name;
            showPlace(key, name);
          });
        }
        if (featureEnabled("falatozz")) {
          getPlaces("falatozz").filter(p => p.enabled).forEach(place => {
            const key = "falatozz";
            const name = place.name;
            showPlace(key, name);
          });
        }

        if (node.data.settings)
          Object.keys(node.data.settings).forEach(key => {
            Object.keys(node.data.settings[key]).forEach(name => {
              const checkbox = $tdList.eq(1).find("div[type='" + key + "'][name='" + name + "']");
              const price = $tdList.eq(5).find("input#menuItem_price_" + key + "_" + name);
              checkbox.find('input').prop('checked', node.data.settings[key][name].enabled)
              price.val(node.data.settings[key][name].price)
            });
          });

        const quantity = $($tdList.eq(++c).find("input"));
        quantity.val(node.data.quantity);
        const qt = $('#quantityType.filter').val()[0];
        if (qt)
          $tdList.eq(c).find('span').html(I18n.t('local.' + qt));

        ++c;
        if (node.data.quantityType) {
          const option = $($tdList.eq(c).find("select option#" + node.data.quantityType.id));
          option.prop("selected", true);
        }


        var unitCost = menuitemutil.data ? menuitemutil.data.costEstimation * node.data.quantity : 0;

        var price = $($tdList.eq(++c).find("input#menuItem_price"));

        $($tdList.eq(++c).find("input.form-control")).val(node.data.containerCharge);
        $($tdList.eq(c).find("input.checkbox")).prop("checked", node.data.containerChargeOnsite);
        $($tdList.eq(c).find("input.checkbox")).prop("id", "menuItem_containerChargeOnsite_" + node.data.quantity);
        $($tdList.eq(c).find("label")).prop("for", "menuItem_containerChargeOnsite_" + node.data.quantity);
        $($tdList.eq(++c).find("input.form-control")).val(node.data.labelCount);

        var costAndPriceRation = $($tdList.eq(++c).find("input"));
        if (menuitemutil.data && menuitemutil.data.defaultVATCategory) {
          if (node.data.price) price.val(node.data.price);
          if (typeof node.data.price == "undefined" || node.data.price == "") {
            var v = Math.round(1000 * unitPrice * node.data.quantity) / 1000;
            price.attr("placeholder", v);
            costAndPriceRation.val(Math.round((v / (100 + menuitemutil.data.defaultVATCategory.percent) * 100 - unitCost) / unitCost * 100));
          } else
            costAndPriceRation.val(Math.round((node.data.price / (100 + menuitemutil.data.defaultVATCategory.percent) * 100 - unitCost) / unitCost * 100));
          quantity.change(event => {
            var v = event.target.value;
            var unitCost = menuitemutil.data.costEstimation * v;
            costAndPriceRation.val(Math.round((price.val() / (100 + menuitemutil.data.defaultVATCategory.percent) * 100 - unitCost) / unitCost * 100));
          });
          price.change(event => {
            var v = event.target.value;
            var unitCost = menuitemutil.data.costEstimation * quantity.val();
            costAndPriceRation.val(Math.round((v / (100 + menuitemutil.data.defaultVATCategory.percent) * 100 - unitCost) / unitCost * 100));
          });
          costAndPriceRation.change(event => {
            var v = event.target.value;
            var unitCost = menuitemutil.data.costEstimation * quantity.val();
            price.val(Math.round(unitCost * (Number(v) + 100) / 100) * (100 + menuitemutil.data.defaultVATCategory.percent) / 100);
          });
        }
        $($tdList.eq(++c).find("input")).val(Math.round(1000 * node.data.priceEstimation) / 1000);
        $($tdList.eq(++c).find("input")).val(node.data.cost);
        if (typeof node.data.menuItems != "undefined") {
          $tdList
            .eq(c)
            .prop("colspan", 4)
            .nextAll()
            .remove();
        }
        tooltip($(node.tr).find("[data-toggle = 'tooltip']"));

      }
    });
  else {
    menuItemQuantities.reload(data);
  }

}
menuitemutil.processMenuItemQuantities = processMenuItemQuantities;

menuitemutil.addBarcode = (e) => {
  $('#barcodeTemplate').tmpl({ id: "" }).appendTo($(e).parent().prev());
}


function addQuantity() {
  var v = menuItemQuantities;
  var rootNode = menuItemQuantities.getRootNode();
  var childNode = rootNode.addChildren({
    title: "",
    folder: false,
    label: {},
    quantity: 1,
    price: 0,
    quantityType: {},
    onMenu: true
  });
}
menuitemutil.addQuantity = addQuantity;

function deleteQuantity() {
  if (menuItemQuantities.getActiveNode())
    menuItemQuantities.getActiveNode().remove();
}
menuitemutil.deleteQuantity = deleteQuantity;

menuitemutil.hooks = function () {

  $("#item_additions_mandatory,#category_additions_mandatory,#category_additions_included,#category_additions_optional,#item_additions_included,#item_additions_optional").on("tokenize:tokens:added", function (e, id) {
    const item = $(e.target)
      .next()
      .find('li[data-value = "' + id + '"] input');
    setTimeout(() => {
      $(item).focus();
      $(item).select();
    }, 100);
    tooltip($(e.target).next().find("[data-toggle = 'tooltip']"));

  });

  $("#unit_price").change(function (e, item) {
    menuItemQuantities.reload(menuItemQuantitiesData);
  });

  $("#category_additions_optional,#item_additions_optional").on("tokenize:tokens:added", function (e, id) {
    const item = e.target;
    if (id.startsWith("menuCategory")) {
      var radioButton =
        '<div class="custom-control custom-checkbox" data-toggle="tooltip" data-placement="bottom" title="' +
        I18n.t("admin_local.checkbox_option") +
        '" style="padding-left:2rem;padding-right:0rem;">' +
        '<input type="radio" class="custom-control-input" id="check' +
        id +
        '" name="coption' +
        id +
        '">' +
        '<label class="custom-control-label" for="check' +
        id +
        '"></label>' +
        "</div>" +
        '<div class="custom-control custom-checkbox" data-toggle="tooltip" style="display:flex" data-placement="bottom" title="' +
        I18n.t("admin_local.checkbox_option2") +
        '" style="padding-left:2rem;padding-right:0rem;">' +
        '<input type="radio" class="custom-control-input check2" id="check2' +
        id +
        '" name="coption' +
        id +
        '">' +
        '<label class="custom-control-label" for="check2' +
        id +
        '"></label>' +
        menuitemutil.getMinMaxOption() +
        "</div>" +
        '<div class="custom-control custom-radio" data-toggle="tooltip" data-placement="bottom" title="' +
        I18n.t("admin_local.optional_radio_option") +
        '" style="padding-left: 1.5rem;">' +
        '<input type="radio" class="custom-control-input" id="radio' +
        id +
        '" name="coption' +
        id +
        '">' +
        '<label class="custom-control-label" for="radio' +
        id +
        '"></label>' +
        "</div>" +
        '<div class="custom-control custom-radio compulsory" data-toggle="tooltip" data-placement="bottom" title="' +
        I18n.t("admin_local.mandatory_radio_option") +
        '" style="">' +
        '<input type="radio" class="custom-control-input" id="radio2' +
        id +
        '" name="coption' +
        id +
        '">' +
        '<label class="custom-control-label" for="radio2' +
        id +
        '"></label>' +
        "</div>";
      var $radioButton = $(radioButton);
      var h = $(item)
        .next("div")
        .find('li[data-value = "' + id + '"]');
      h.append($radioButton);
      //$radioButton.insertAfter($(item).find("abbr"));
      $radioButton.find('input[id="check' + id + '"]').prop("checked", true);
      tooltip($radioButton);
    }
    tooltip($(e.target).find("[data-toggle = 'tooltip']"));
  });

  menuItemQuantities = null;
  $("input#pricePerCostRatio").each((ind, item) => {
    $(item).data(
      "cleave",
      new Cleave($(item), {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        numeralPositiveOnly: true,
        numeralDecimalScale: 0,
        delimiter: " "
      })
    );
  });

  var barcodeOptions = {
    placeholder: I18n.t("local.enter_barcode"),
    tokensAllowCustom: true,
    sortable: true,
    searchMinLength: 1,
    searchHighlight: false,
    dataSource: function (term, object) {
      var p = $(object.element);
      object.trigger("tokenize:dropdown:fill", [menuitemutil.findBarcodes(term)]);
    },
    displayNoResultsMessage: true,
    noResultsMessageText: I18n.t("local.noResultsMessageText"),
    dropdownMaxItems: 200,
    searchFromStart: false,
  };


  var options = {
    placeholder: I18n.t("local.enter_additions"),
    tokensAllowCustom: false,
    sortable: true,
    searchMinLength: 1,
    searchHighlight: false,
    dataSource: function (term, object) {
      var p = $(object.element);
      object.trigger("tokenize:dropdown:fill", [menuitemutil.getMenuItems(term, true, p.attr("id").split("_")[2])]);
    },
    displayNoResultsMessage: true,
    noResultsMessageText: I18n.t("local.noResultsMessageText"),
    dropdownMaxItems: 200,
    searchFromStart: false,
    ondblclick: menuitemutil.openItem
  };

  var optionsc = {
    ...options,
    ondblclick: menuitemutil.openItemCategory
  };
  var optionsUnproportional = {
    placeholder: I18n.t("local.enter_additions"),
    tokensAllowCustom: false,
    sortable: true,
    searchMinLength: 1,
    searchHighlight: false,
    dataSource: function (term, object) {
      var p = $(object.element);
      object.trigger("tokenize:dropdown:fill", [menuitemutil.getMenuItems(term, false, p.attr("id").split("_")[2])]);
    },
    displayNoResultsMessage: true,
    noResultsMessageText: I18n.t("local.noResultsMessageText"),
    dropdownMaxItems: 200,
    searchFromStart: false,
    ondblclick: menuitemutil.openItem
  };
  var optionsUnproportionalc = {
    ...optionsUnproportional,
    ondblclick: menuitemutil.openItemCategory
  };
  var options1 = {
    placeholder: I18n.t("local.inherited_additions"),
    tokensAllowCustom: true,
    searchMinLength: 1,
    searchHighlight: false,
    dataSource: function (term, object) {
      var p = $(object.element);
      object.trigger("tokenize:dropdown:fill", [menuitemutil.getMenuItems(term, p.attr("id").split("_")[2])]);
    },
    displayNoResultsMessage: true,
    noResultsMessageText: I18n.t("local.noResultsMessageText"),
    dropdownMaxItems: 200,
    searchFromStart: false,
    ondblclick: menuitemutil.openItem
  };
  var options4 = {
    tokensAllowCustom: true,
    searchMinLength: 1,
    searchHighlight: false,
    dataSource: function (term, object) {
      var p = $(object.element);
      object.trigger("tokenize:dropdown:fill", [menuitemutil.getMenuItems(term, p.attr("id").split("_")[2])]);
    },
    displayNoResultsMessage: true,
    noResultsMessageText: I18n.t("local.noResultsMessageText"),
    dropdownMaxItems: 200,
    searchFromStart: false,
    ondblclick: menuitemutil.openItem
  };
  var options4c = {
    ...options4,
    ondblclick: menuitemutil.openItemCategory
  };
  var options2 = {
    placeholder: I18n.t("admin_local.enter_labels"),
    tokensAllowCustom: true,
    searchHighlight: false,
    searchMinLength: 1,
    dataSource: function (term, object) {
      object.trigger("tokenize:dropdown:fill", [menuitemutil.getLabels(term)]);
    },
    displayNoResultsMessage: true,
    noResultsMessageText: I18n.t("local.noResultsMessageText"),
    dropdownMaxItems: 200,
    searchFromStart: false,
    ondblclick: menuitemutil.openItem
  };
  var options2c = {
    ...options2,
    ondblclick: menuitemutil.openItemCategory
  };
  $("div#editMenuItem #item_barcodes").tokenize2(barcodeOptions);
  $("div#editMenuItem #item_additions_mandatory").tokenize2(options);
  $("div#editMenuItem #item_additions_included").tokenize2(options);


  $("div#editMenuItem #item_additions_optional").tokenize2(optionsUnproportional);
  $("div#editMenuItem #item_labels").tokenize2(options2);
  $("div#editMenuItem #inherited_item_additions_mandatory").tokenize2(options1);
  $("div#editMenuItem #inherited_item_additions_included").tokenize2(options1);
  $("div#editMenuItem #inherited_item_additions_optional").tokenize2(options1);
  $("div#editMenuItem #references").tokenize2(options4);
  $("div#editMenuItem #category_references").tokenize2(options4);

  $("div#editMenuCategory #category_additions_mandatory").tokenize2(optionsc);
  $("div#editMenuCategory #category_additions_included").tokenize2(optionsc);
  $("div#editMenuCategory #category_additions_optional").tokenize2(optionsUnproportionalc);
  $("div#editMenuCategory #category_labels").tokenize2(options2c);
  $("div#editMenuCategory #category_references").tokenize2(options4c);

  $("input#pricePerCostRatio").each((ind, item) => {
    $(item).data(
      "cleave",
      new Cleave($(item), {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        numeralPositiveOnly: true,
        numeralDecimalScale: 0,
        delimiter: " "
      })
    );
  });

  $("#mi_isInventoryItem").change(function (data) {
    if ($(this).prop("changeForced")) {
      $(this).prop("changeForced", false);
    } else {
      if ($(this).prop("checked") && !($(this).prop("checked_old") == undefined && $(this).prop("checked_old") == "") && !$(this).prop("indeterminate")) {
        $(this).prop("indeterminate", true);
        $(this).prop("checked", false);
        $(this).removeProp("checked_old");
        $(this).prop("changeForced", true);
      }
      $(this).prop("checked_old", $(this).prop("checked"));
    }

    const parentCategoryId = $("#editMenuItem #parentCategoryId").val();
    const parentCategory = menuitemutil.getMenuCategory(parentCategoryId);
    const parentCategoryInventoryItem = parentCategory.defaultInventoryItem;
    var modal = $("div#editMenuItem");
    var i = $(this).prop("indeterminate") ? parentCategoryInventoryItem : $(this).prop("checked");
    if (i === true) {
      modal.addClass("inventory");
      $("td .inventoryItem.checkbox").parent().parent().removeClass("hidden");
      $("th.mi_inventoryItem").removeClass('hidden');
      $("td .inventoryItem.checkbox").prop("disabled", null);
      if ($("td .inventoryItem.checkbox").length === 1) {
        $("td .inventoryItem.checkbox").prop('checked', true)
      }
      $("input#unit_price").parent().addClass("hidden");
      $("#editMenuItem input#menuItem_minimumPrice").parent().addClass("hidden");
      $("input#unit_cost").parent().addClass("hidden");
      $("input#pricePerCostRatio").parent().removeClass("hidden");
      //$(".input-group-mandatory").addClass("hidden");
      //$(".input-group-included").addClass("hidden");
      //clearTokens(modal.find("#item_additions_mandatory").tokenize2());
      //clearTokens(modal.find("#item_additions_included").tokenize2());
    } else {
      modal.removeClass("inventory");
      $("td .inventoryItem.checkbox").parent().parent().addClass('hidden');
      $("th.mi_inventoryItem").addClass('hidden');
      $("td .inventoryItem.checkbox").prop("disabled", true);
      $("td .inventoryItem.checkbox").prop('checked', false)
      $("input#unit_price").parent().removeClass("hidden");
      $("#editMenuItem input#menuItem_minimumPrice").parent().removeClass("hidden");
      $("input#unit_cost").parent().removeClass("hidden");
      $("input#pricePerCostRatio").parent().addClass("hidden");
      $(".input-group-mandatory").removeClass("hidden");
      //$(".input-group-included").removeClass("hidden");
    }
  });

  $("#mc_isInventoryItem").change(function (data) {
    if ($(this).prop("changeForced")) {
      $(this).prop("changeForced", false);
    } else {
      if ($(this).prop("checked") && !($(this).prop("checked_old") == undefined && $(this).prop("checked_old") == "") && !$(this).prop("indeterminate")) {
        $(this).prop("indeterminate", true);
        $(this).prop("checked", false);
        $(this).removeProp("checked_old");
        $(this).prop("changeForced", true);
      }
      $(this).prop("checked_old", $(this).prop("checked"));
    }
  });
  $("#mc_preorder").change(function (data) {
    if ($(this).prop("changeForced")) {
      $(this).prop("changeForced", false);
    } else {
      if ($(this).prop("checked") && !($(this).prop("checked_old") == undefined && $(this).prop("checked_old") == "") && !$(this).prop("indeterminate")) {
        $(this).prop("indeterminate", true);
        $(this).prop("checked", false);
        $(this).removeProp("checked_old");
        $(this).prop("changeForced", true);
      }
      $(this).prop("checked_old", $(this).prop("checked"));
    }
  });
  $("#m_preorder").change(function (data) {
    if ($(this).prop("changeForced")) {
      $(this).prop("changeForced", false);
    } else {
      if ($(this).prop("checked") && !($(this).prop("checked_old") == undefined && $(this).prop("checked_old") == "") && !$(this).prop("indeterminate")) {
        $(this).prop("indeterminate", true);
        $(this).prop("checked", false);
        $(this).removeProp("checked_old");
        $(this).prop("changeForced", true);
      }
      $(this).prop("checked_old", $(this).prop("checked"));
    }
  });
  $("div#editMenuItem").on("hide.bs.modal", function (event) {
    var modal = $(this);
    var sm = $(modal.find("img#smallImage"));
    if ($.data(sm.get(0), "rcrop")) {
      crop(sm);
    }
  });
  $("div#editMenuCategory").on("hide.bs.modal", function (event) {
    var modal = $(this);
    var sm = $(modal.find("img#smallImage"));
    if ($.data(sm.get(0), "rcrop")) {
      crop(sm);
    }
  });

  if ($("#menuItemsFreezeTable > table#menuItems").length > 0) {
    $("div#editMenuCategory").on("show.bs.modal", function (event) {
      var modal = $(this);
      var button = $(event.relatedTarget); // Button that triggered
      // the
      // modal
      var recipient = button.data("whatever"); // Extract info from
      // data-*
      // attributes
      var node = menuitemutil.menuItems.getActiveNode();
      if (node && node.data.entityType.indexOf("Category") === -1 && recipient !== "edit") {
        node = node.parent;
      }
      const top = button.attr("id") === "addTopMenuCategory";
      if (node)
        populateEditCategory(modal, recipient, recipient == "edit" ? node.data : null, top ? 0 : recipient == "edit" ? node.data.menuCategoryId : node != null ? node.data.id : 0);
    });

    $("div#editMenuItem").on("show.bs.modal", function (event) {
      var modal = $(this);
      var button = $(event.relatedTarget); // Button that triggered
      // the
      // modal
      var recipient = button.data("whatever"); // Extract info from
      // attributes
      var node = menuitemutil.menuItems.getActiveNode();
      if (node && node.data.entityType.indexOf("Category") === -1 && recipient !== "edit") {
        node = node.parent;
      }
      if (node)
        populateEditMenuItem(modal, recipient, recipient === "edit" ? node.data : null, recipient === "edit" ? node.data.menuCategoryId : node.data.id);
    });



  }

  $("#download").click(function (e) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    var node = menuitemutil.menuItems.getActiveNode();
    get("adminService/" + sessionStorage.restaurantSelected + "/getStores").done(stores => {
      get("adminService/" + sessionStorage.restaurantSelected + "/getStocks").done(stock => {
        var ws;
        var name = "";
        if (node) {
          var menuCategory = admin.getMenuCategory(node.data.id);
          if (!menuCategory)
            return;
          name = "_" + menuitemutil.getPath(menuCategory).replace(/\//g, "_");
          ws = window.XLSX.utils.json_to_sheet(menuitemutil.export(menuitemutil.getFlatMenuItems([], menuCategory.children), stores, stock));
        } else {
          ws = window.XLSX.utils.json_to_sheet(menuitemutil.export(menuitemutil.getFlatMenuItems([], admin.categories.activeMenuCategories), stores, stock));
        }
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = window.XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        saveAs(data, auth.myStatus.restaurant_name + "_" + menuitemutil.entityType + name + "s.xlsx");
      });
    });

  });

  menuitemutil.export = (array, stores, stocks) => {

    const wolt = featureEnabled("wolt");
    const foodpanda = featureEnabled("foodpanda");
    const falatozz = featureEnabled("falatozz");
    const takeaway = settingEnabled("takeaway/price");

    const quantities = [];
    array.forEach(m => {
      m.availableQuantities.forEach(q => {
        const qq = q;
        if (wolt && qq && qq.settings && qq.settings.wolt) {
          Object.keys(qq.settings.wolt).forEach(key => {
            if (quantities.indexOf(key + "_a " + qq.quantity) === -1) {
              quantities.push("wolt_" + key + "_a " + qq.quantity);
              quantities.push("wolt_" + key + "_p " + qq.quantity);
            }
          });
        }
        if (foodpanda && qq && qq.settings && qq.settings.foodpanda) {
          Object.keys(qq.settings.foodpanda).forEach(key => {
            if (quantities.indexOf(key + "_a " + qq.quantity) === -1) {
              quantities.push("wolt_" + key + "_a " + qq.quantity);
              quantities.push("wolt_" + key + "_p " + qq.quantity);
            }
          });
        }
        if (falatozz && qq && qq.settings && qq.settings.falatozz) {
          Object.keys(qq.settings.falatozz).forEach(key => {
            if (quantities.indexOf(key + "_a " + qq.quantity) === -1) {
              quantities.push("wolt_" + key + "_a " + qq.quantity);
              quantities.push("wolt_" + key + "_p " + qq.quantity);
            }
          });
        }
        if (takeaway && qq && qq.settings && qq.settings.takeaway) {
          Object.keys(qq.settings.takeaway).forEach(key => {
            if (quantities.indexOf(key + "_a " + qq.quantity) === -1) {
              quantities.push("takeaway_" + key + "_a " + qq.quantity);
              quantities.push("takeaway_" + key + "_p " + qq.quantity);
            }
          });
        }
        if (m.defaultInventoryItem && q.quantity == menuitemutil.getInventoryQuantity(m))
          return;
        if (quantities.indexOf(q.quantity) === -1) {
          quantities.push(q.quantity)
        }

      })
    });
    stores.forEach(store => {
      quantities.push("a_" + store.name);
    })
    quantities.sort((a, b) => ("" + a).localeCompare("" + b));
    return array.map(m => {
      const path = menuitemutil.getPath(m).split("/");
      getLocale(m.name).split("/").forEach(() => path.pop());
      const iq = m.availableQuantities.find(q => q.quantity == menuitemutil.getInventoryQuantity(m));
      const c = {
        id: m.id,
        productOnMenu: m.isTop ? "yes" : "no",
        category: path.join("/"),
        name: getLocale(m.name),
        shortName: m.shortName,
        description: getLocale(m.description),
        manufacturer: m.manufacturer,
        quantityType: m.quantityType,
        freeQuantity: m.freeQuantity,
        imageSrc: m.image ? auth.server + "/eatwithme.server/tableService/1/getImage/" + m.image + "?instance=" + localStorage.instance : "",
        woltImageSrc: m.woltImage ? auth.server + "/eatwithme.server/tableService/1/getImage/" + m.woltImage + "?instance=" + localStorage.instance : "",
        foodpandaImageSrc: m.foodpandaImage ? auth.server + "/eatwithme.server/tableService/1/getImage/" + m.foodpandaImage + "?instance=" + localStorage.instance : "",
        falatozzImageSrc: m.falatozzImage ? auth.server + "/eatwithme.server/tableService/1/getImage/" + m.falatozzImage + "?instance=" + localStorage.instance : "",
        smallImageSrc: m.smallImage ? auth.server + "/eatwithme.server/tableService/1/getImage/" + m.smallImage + "?instance=" + localStorage.instance : "",
        fromDate: m.fromDate ? moment(m.fromDate).format("YYYY-MM-DD") : null,
        inventory: m.inventoryItem === true ? 'YES' : m.inventoryItem === false ? 'NO' : m.defaultInventoryItem ? 'yes' : 'no',
        "inventory quantity unit": menuitemutil.getInventoryQuantity(m),
        "inventory quantity price": iq && iq.localPrice,
        "inventory quantity price margin": iq && (iq.localPrice / iq.cost),
        "inventory quantity takeaway price": iq && iq.takeawayPrice,
        "inventory quantity takeaway price margin": iq && (iq.takeawayPrice / iq.cost),
        onMenu: !iq || iq.onMenu ? "yes" : "no",
        cost: iq ? iq.cost : 0,
        price: iq && iq.price ? iq.price : m.unitPrice ? m.unitPrice : 0,
        serving_name: getLocale(iq?.quantityType.name),
        label: m.labels.map(l => l.name).join(" / ")
      }
      stores.forEach(store => {
        const stock = stocks.find(stock => stock.menuItem.id === m.id && stock.store.id === store.id);
        if (stock) {
          c["s_" + store.name] = stock.count;
        }
      })
      quantities.forEach(quantity => {
        if (isNaN(quantity)) {
          c[quantity] = "";
          return;
        }
        c["qa " + quantity] = "-";
        c["qp " + quantity] = "";
        c["qs " + quantity] = "";
        const qq = m.availableQuantities.find(q => q.quantity == quantity);
        if (qq) {
          c["qa " + quantity] = qq.onMenu ? "yes" : "no";
          c["qp " + quantity] = qq.price;
          c["qs " + quantity] = getLocale(qq.quantityType.name);

        }
      })

      function f(quantity, settings, platform) {
        Object.keys(settings[platform]).forEach(key => {
          c[platform + "_" + key + "_a " + quantity] = settings[platform][key].enabled ? "yes" : "";
          c[platform + "_" + key + "_p " + quantity] = settings[platform][key].price;
        });
      }

      quantities.forEach(quantity => {
        if (isNaN(quantity)) return;
        const qq = m.availableQuantities.find(q => q.quantity == quantity);
        if (wolt && qq && qq.settings && qq.settings.wolt) {
          f(quantity, qq.settings, "wolt");
        }
        if (foodpanda && qq && qq.settings && qq.settings.foodpanda) {
          f(quantity, qq.settings, "foodpanda");
        }
        if (falatozz && qq && qq.settings && qq.settings.falatozz) {
          f(quantity, qq.settings, "falatozz");
        }
        if (takeaway && qq && qq.settings && qq.settings.takeaway) {
          f(quantity, qq.settings, "takeaway");
        }
      })

      const qq = m.availableQuantities.find(q => q.quantity == menuitemutil.getInventoryQuantity(m));
      if (wolt && qq && qq.settings && qq.settings.wolt) {
        f(qq.quantity, qq.settings, "wolt");
      }
      if (foodpanda && qq && qq.settings && qq.settings.foodpanda) {
        f(qq.quantity, qq.settings, "foodpanda");
      }
      if (falatozz && qq && qq.settings && qq.settings.falatozz) {
        f(qq.quantity, qq.settings, "falatozz");
      }
      if (takeaway && qq && qq.settings && qq.settings.takeaway) {
        f(qq.quantity, qq.settings, "takeaway");
      }

      return c;
    })
  }

  $("#translate").click(function () {
    var node = menuitemutil.menuItems.getActiveNode();
    if (node) {
      if (node.data.entityType == menuitemutil.entityType + "Category") {
        get("restaurantService/" + sessionStorage.restaurantSelected + "/translateCategory/" + node.data.id + "/" + localStorage.languageSelected).done(data => {
          menuitemutil._reload(data);
        })
      } else {
        get("restaurantService/" + sessionStorage.restaurantSelected + "/translateMenuitem/" + node.data.id + "/" + localStorage.languageSelected).done(data => {
          menuitemutil._reload([data]);
        })
      }
    } else {
      get("restaurantService/" + sessionStorage.restaurantSelected + "/translateAll/" + localStorage.languageSelected).done(data => {
        menuitemutil._reload([data]);
      })
    }
  })

  $("#upload").click(function () {
    $("#file").attr("files", "");
    $("#file").get(0).value = null;
    $("#file").click();
  });
  $("#foodorasync").click(function () {
    $("#foodorafile").attr("files", "");
    $("#foodorafile").get(0).value = null;
    $("#foodorafile").click();
  });
  $("#woltsync").click(function () {
    $("#woltfile").attr("files", "");
    $("#woltfile").get(0).value = null;
    $("#woltfile").click();
  });

  $("#foodorafile").change(foodorasync);
  $("#woltfile").change(woltsync);

  $("#file").change(function () {
    if (document.getElementById("file").files[0] == "") return;
    var reader = new FileReader();

    reader.onload = () => {
      try {

        var node = menuitemutil.menuItems.getActiveNode();
        var menuCategory = node ? admin.getMenuCategory(node.data.id) : null;

        var workbook = window.XLSX.read(reader.result, { type: "binary" });

        admin.getMenuItems((data) => {

          let rowObject = [];

          if (workbook.SheetNames.find(sheet => sheet === "Adatbázis1")) {
            console.log("this is a planteen worksheet");
            var log = "";
            workbook.SheetNames.filter(sheet => sheet.startsWith("P")).forEach(s => {
              const sheet = workbook.Sheets[s];
              const name = sheet.B2.v.trim();
              const receiptUnit = sheet.B8.v;
              var menuItem = findMenuItemByName(data.children, name);
              if (!menuItem) {
                menuItem = findMenuItemByName(data.children, name, true);
              }
              if (menuItem) {
                console.log(">>>", name, receiptUnit, menuItem)
                menuItem.menuItemAdditions = menuItem.menuItemAdditions.filter(a => a.additionType != "mandatory");
                for (let rownr = 11; sheet["A" + rownr] && sheet["B" + rownr]; rownr++) {
                  try {
                    const ingredient = sheet["A" + rownr].v.trim();
                    const quantity = sheet["B" + rownr].v;
                    const ingredientMenuItem = findMenuItemByName(data.children, ingredient);
                    if (ingredientMenuItem) {
                      console.log(ingredient, quantity);
                      menuItem.mandatory_volume = receiptUnit ? receiptUnit : 1;
                      menuItem.menuItemAdditions.push(
                        {
                          "id": 0,
                          "type": "meal_addition",
                          "quantity": quantity,
                          "additionType": "mandatory",
                          "proportional": true,
                          "takeaway": true,
                          "local": true,
                          "order": menuItem.menuItemAdditions.length,
                          "addition": {
                            "type": ingredientMenuItem.entityType.toLowerCase(),
                            "id": ingredientMenuItem.id
                          }
                        });

                    } else {
                      console._error("Ingredient not found for ", "'" + ingredient + "'");
                      log += "Ingredient not found for '" + ingredient + "'<br/>";
                    }
                  } catch (ex) {
                    console._error(ex);
                    console.log("B" + rownr, sheet["B" + rownr])
                    log += "B" + rownr + " error: " + ex + "\n";
                  }
                }
                console.log(">>>>>>", name, receiptUnit, menuItem)
              } else {
                console._error(s, "Menu item not found with name", "'" + name + "'");
                log += s + ": Menu item not found with name '" + name + "'<br/>"
              }

            });

            function transform(menuCategories) {
              return menuCategories.map(existingCateg => {
                var category = Object.assign({}, existingCateg);
                category.menuCategories = transform(category.children.filter(c => c.entityType === menuitemutil.entityType + "Category"));
                category.menuItems = category.children.filter(c => c.entityType === menuitemutil.entityType).map(m => {
                  m.type = m.entityType.toLowerCase();
                  m.mainCategory = m.mainCategoryId;
                  m.subCategory = m.subCategoryId;
                  if (m.image && !m.imageSrc)
                    m.imageSrc = menuitemutil.getImageUrl(m.image)
                  if (m.bigImage && !m.bigImageSrc)
                    m.bigImageSrc = menuitemutil.getImageUrl(m.bigImage)
                  return m;
                });
                if (category.image && !category.imageSrc)
                  category.imageSrc = menuitemutil.getImageUrl(category.image)
                category.mainCategory = category.mainCategoryId;
                category.subCategory = category.subCategoryId;
                delete category.children;
                category.type = category.entityType.toLowerCase();
                return category;
              })
            }
            confirmDialog(I18n.t("local.error_message"), log).done(() => {

              post("restaurantService/" + sessionStorage.restaurantSelected + "/update" + menuitemutil.entityType + "s", { menuCategories: transform(data.children) }, undefined, undefined, 2, undefined, 60000).done(function (data) {
                // eslint-disable-next-line no-restricted-globals
                menuitemutil.reload();
              });
            });
            return;
          }

          workbook.SheetNames.forEach(sheet => {
            let json = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
            console.log("Process worksheet " + sheet + " with " + json.length + " elements");
            json.forEach(a => rowObject.push(a));
          }, undefined, true);

          var base = [];

          var node = menuitemutil.menuItems.getActiveNode();
          if (node) {
            const menuCategory = admin.getMenuCategory(node.data.id);

            base = Object.assign([], data.children);
          }


          function transform(menuCategories) {
            return menuCategories.map(existingCateg => {
              var category = Object.assign({}, existingCateg);
              category.menuCategories = transform(category.children.filter(c => c.entityType === menuitemutil.entityType + "Category"));
              category.menuItems = category.children.filter(c => c.entityType === menuitemutil.entityType).map(m => {
                m.type = m.entityType.toLowerCase();
                m.mainCategory = m.mainCategoryId;
                m.subCategory = m.subCategoryId;
                return m;
              });
              category.mainCategory = category.mainCategoryId;
              category.subCategory = category.subCategoryId;
              delete category.children;
              category.type = category.entityType.toLowerCase();
              return category;
            })
          }

          base = transform(base);

          if (menuCategory) {
            const path = menuitemutil.getPath(menuCategory);
            rowObject = rowObject.filter(row => row.category.startsWith(path));
          }

          let jsonObject = convert(rowObject, data.children, base);

          function transform2(menuCategories) {
            return menuCategories.filter(c => c.recordState !== "DELETED").map(category => {
              category.menuItems = category.menuItems.filter(c => c.recordState !== "DELETED");
              category.menuCategories = transform2(category.menuCategories.filter(c => c => c.recordState !== "DELETED"));
              return category;
            })
          }
          jsonObject = { menuCategories: transform2(jsonObject.menuCategories) };

          post("restaurantService/" + sessionStorage.restaurantSelected + "/update" + menuitemutil.entityType + "s", jsonObject, undefined, undefined, 2, undefined, 60000).done(function (data) {
            // eslint-disable-next-line no-restricted-globals
            menuitemutil.reload();
          });

        });


        /*
          const newmenuItems = JSON.parse(reader.result);
          */
      } catch (ex) {
        console.log(ex);
      }

    }


    //if (file) {
    reader.readAsBinaryString(document.getElementById("file").files[0]);
    //}

  });
};

const convert = (menuItems, menuCategories, base = []) => {
  const categories = { menuCategories: base };
  menuItems.forEach(menuitem => {
    var categs = categories.menuCategories;
    var category;
    var children = menuCategories;
    menuitem.category.split("/").forEach(categname => {
      const existingCateg = categs.find(c => c.entityType === menuitemutil.entityType + "Category" && getLocale(c.name) === getLocale(categname));
      if (!existingCateg) {
        category = findOrCreateCategory(categname, children);
        category.type = menuitemutil.entityType.toLowerCase() + "category";
        delete category.children;
        category.menuItems = [];
        category.menuCategories = [];
        children = category.oldMenuCategories;
        categs.push(category);
      } else {
        category = existingCateg;
        children = existingCateg.oldMenuCategories;
      }
      categs = category.menuCategories;
    })
    const m = findOrCreateMenuItem(menuitem, menuCategories);
    var existing = category.menuItems.find(m1 => (m1.id && m.id && m1.id === m.id) || getLocale(m.name) === getLocale(m1.name));
    if (existing)
      category.menuItems = category.menuItems.map(m1 => (m1.id && m.id && m1.id === m.id) || getLocale(m.name) === getLocale(m1.name) ? m : m1);
    else category.menuItems.push(m);
  })
  return categories;
}

function findOrCreateCategory(name, categories) {
  const category = categories?.find(c => getLocale(c.name) === name && c.entityType === menuitemutil.entityType + "Category");
  if (category) {
    const catcopy = JSON.parse(JSON.stringify(category));
    catcopy.oldMenuCategories = catcopy.children;
    return catcopy;
  }
  const n = {};
  n[localStorage.language] = name;
  const newcat = {
    name: n,
    isActive: true,
    isTop: true,
    entityType: menuitemutil.entityType + "Category",
    oldMenuCategories: []
  }
  // categories.push(newcat);
  return newcat;
}

function _findOrCreateMenuItem(menuitem, categories, base) {
  var m;

  categories.forEach(c_m => {
    if (c_m.entityType === menuitemutil.entityType + "Category") {
      var m1 = _findOrCreateMenuItem(menuitem, c_m.children, base);
      if (m1) {
        if (!m || !m.id)
          m = m1;
      }
    } else if (c_m.entityType === menuitemutil.entityType) {
      if (c_m.id === menuitem.id || (!c_m.id && menuitemutil.getPath(c_m, { children: base }) + c_m.dateFrom === menuitem.category + "/" + menuitem.name)) {
        if (!m || !m.id) {
          m = c_m;
        }
      }
    }
  })

  return m;
}

function findOrCreateMenuItem(menuitem, categories) {
  var m = _findOrCreateMenuItem(menuitem, categories, categories);

  if (m) {
    m = JSON.parse(JSON.stringify(m));
    m.type = menuitemutil.entityType.toLowerCase();
  } else {
    m = {
      "recordState": "ACTIVE",
      "name": {},
      "settings": {
        "foodpanda": {},
        "wolt": {},
        "falatozz": {},
        "takeaway": {}
      },
      shortName: "",
      "available": true,
      "freeQuantity": false,
      barcodes: [],
      "inventoryItem": null,
      "timing": "inherit",
      "productionLines": [],
      "vatCategory": null,
      "takeawayVatCategory": null,
      "availableQuantities": [],
      "hasImage": false,
      "type": menuitemutil.entityType.toLowerCase(),
      isActive: true
    };
  }
  m.recordState = "ACTIVE";
  m.manufacturer = menuitem.manufacturer;
  m.name[localStorage.language] = menuitem.name;
  m.quantityType = menuitem.quantityType;
  m.image = menuitem.image ? getImageById(menuitem.image) : null;
  m.bigImage = menuitem.bigImage ? getImageById(menuitem.bigImage) : null;
  m.unitPrice = Number(menuitem.price);
  if (menuitem.freeQuantity === false)
    m.freeQuantity = false;
  else if (menuitem.freeQuantity === true)
    m.freeQuantity = true;

  if (typeof menuitem.shortName !== "undefined" || menuitem.shortName !== undefined) {
    m.shortName = menuitem.shortName
  } else {
    m.shortName = "";
  }

  if (menuitem.fromDate) {
    if (isNaN(menuitem.fromDate)) {
      const d = moment(menuitem.fromDate).startOf("day");
      m.fromDate = d.valueOf();
    } else {
      const d = moment('1900-01-01').add(Number(menuitem.fromDate - 2), "day");
      m.fromDate = d.valueOf();
    }
  }

  m.inventoryItem = menuitem.inventory == "yes" ? true : menuitem.inventory == "no" ? false : null;
  m.labels = [];
  if (menuitem.label)
    menuitem.label.split("/").forEach(l => {
      if (l.trim()) {
        l = l.trim();
        if (m.labels.find(label => label.name.trim() === l))
          return;
        m.labels.push({ name: l })
      }
    })
  const inventoryQuantity = Number(menuitem["inventory quantity unit"]);
  var q = menuitemutil.getInventoryQuantity(m, inventoryQuantity);
  if (!q)
    q = inventoryQuantity;
  m.availableQuantities = [];
  if (q && !isNaN(inventoryQuantity)) {
    const qq = m.availableQuantities.find(qq => qq.quantity === q);
    if (qq) {
      qq.price = Number(menuitem.price);
      qq.cost = Number(menuitem.cost);
      qq.quantity = inventoryQuantity;
      qq.onMenu = menuitem.onMenu === "yes";
      qq.def = true;
      qq.quantityType = auth.myStatus.restaurant_quantity_types.find(q => getLocale(q.name) === menuitem.serving_name)
    } else {
      const quantityType = auth.myStatus.restaurant_quantity_types.find(q => getLocale(q.name) === menuitem.serving_name);
      m.availableQuantities.push(
        {
          "quantity": inventoryQuantity,
          "price": menuitem.price ? Number(menuitem.price) : "",
          cost: Number(menuitem.cost),
          "onMenu": menuitem.onMenu === undefined || menuitem.onMenu === "yes",
          "online": false,
          "available": true,
          "inventoryItem": true,
          def: true,
          quantityType: quantityType ? quantityType : { id: 1 },
          "settings": {
            "foodpanda": {},
            "wolt": {},
            "falatozz": {}
          },
        }
      )
    }
  }

  Object.keys(menuitem).forEach(key => {
    if (key.startsWith("qa")) {
      var quantity = Number(key.split(" ")[1]);
      const qq = m.availableQuantities.find(qq => qq.quantity === quantity);
      if (qq) {
        if (menuitem[key] === "-") {
          m.availableQuantities = m.availableQuantities.filter(qqq => qqq !== qq);
          return;
        } else {
          qq.onMenu = menuitem[key] === "yes";
          qq.price = "";
        }
      } else if (menuitem[key] !== "-") {
        if (!quantity || isNaN(quantity)) {
          console.log("hoppa");
        }
        const serving_name = menuitem[key.replace(/qa/g, "qs")];
        const quantityType = auth.myStatus.restaurant_quantity_types.find(q => getLocale(q.name) === serving_name);
        m.availableQuantities.push(
          {
            "quantity": quantity,
            "price": "",
            "onMenu": menuitem[key] === "yes",
            "online": false,
            "available": true,
            "inventoryItem": false,
            "quantityType": quantityType ? quantityType : {
              "id": 1
            },
            "settings": {
              "foodpanda": {},
              "wolt": {},
              "falatozz": {}
            },
          }
        )
      }
    }
    if (key.startsWith("qp")) {
      const keys = key.split(" ");
      const pp = keys[1];
      const quantity = Number(pp);
      const qq = m.availableQuantities.find(qq => qq.quantity === quantity);
      if (qq) {
        qq.price = Number(menuitem[key]);
      }
    }
  })

  const columns = ["id", "category", "name", "manufacturer", "quantityType", "inventory quantity", "cost", "price", "description"];

  Object.keys(menuitem).forEach(key => {
    if (key.startsWith("qa") || key.startsWith("qp") || columns.indexOf(key) !== -1)
      return;
    const platform = key.split(" ")[0].split("_")[0];
    if (platform != "takeaway" && platform != "wolt" && platform != "foodpanda" && platform != "falatozz")
      return;
    var thirdpartyname = key.split(" ")[0].split("_")[1];
    const thirdpartyfield = key.split(" ")[0].split("_")[2];
    var thirdpartyquantity = key.split(" ")[1];

    if (!thirdpartyname)
      thirdpartyname = platform;

    if (!thirdpartyquantity)
      thirdpartyquantity = inventoryQuantity;

    const quantity = Number(thirdpartyquantity);
    const qq = m.availableQuantities.find(qq => qq.quantity === quantity);
    if (qq) {
      if (!qq.settings) {
        qq.settings = { foodpanda: {}, wolt: {}, takeaway: {}, falatozz: {} };
      }
      if (!qq.settings[platform]) qq.settings[platform] = {};
      if (thirdpartyfield === "a" && menuitem[key]) {
        qq.settings[platform][thirdpartyname] = { enabled: menuitem[key] === "yes", price: "" };
      } else if (thirdpartyfield === "p" && menuitem[key])
        if (qq.settings[platform][thirdpartyname])
          qq.settings[platform][thirdpartyname].price = menuitem[key];
    }
  })
  m.isTop = menuitem.productOnMenu === undefined || menuitem.productOnMenu === "yes";
  m.name[localStorage.language] = menuitem.name;
  if (menuitem.description) {
    if (!m.description) m.description = {};
    m.description[localStorage.language] = menuitem.description;
  }

  if (menuitem.imageSrc) {
    m.imageSrc = menuitem.imageSrc;
  }
  if (menuitem.foodpandaImageSrc) {
    m.foodpandaImageSrc = menuitem.foodpandaImageSrc;
  }
  if (menuitem.woltImageSrc) {
    m.woltImageSrc = menuitem.woltImageSrc;
  }
  if (menuitem.falatozzImageSrc) {
    m.falatozzImageSrc = menuitem.falatozzImageSrc;
  }
  if (menuitem.smallImageSrc) {
    m.smallImageSrc = menuitem.smallImageSrc;
  }

  return m;
}

function findMenuItemByName(children, name, startWith) {
  for (let i in children) {
    const c = children[i];
    if (c.entityType === menuitemutil.entityType) {
      //console.log(getLocale(c.manufacturer+" "+c.name, "hu").trim().toLowerCase(), "?=", name);
      if (startWith) {
        var n = ((c?.manufacturer || "").toLowerCase() + " " + getLocale(c.name, "hu")).replace(/ /g, "").trim().replace(/\(\d+\)/g, "").replace(/ +,/g, ",").trim().toLowerCase();
        n = n.trim();
        //console.log(n);
        if (n.startsWith(name.trim().replace(/ /g, "").toLowerCase()) || name.replace(/ /g, "").trim().toLowerCase().startsWith(n)) {
          return c;
        }
        var m1 = getLocale(c.name, "hu").match(/(\d+) /);
        var m2 = name.match(/(\d+) /);
        if (m1 && m2 && m1[1] == m2[1]) {
          return c;
        }
      } else if ((c.manufacturer + " " + getLocale(c.name, "hu")).trim().toLowerCase() === name.toLowerCase()) {
        return c;
      }
    } else if (c.entityType === menuitemutil.entityType + "Category") {
      const r = findMenuItemByName(c.children, name, startWith);
      if (r)
        return r;
    }
  }
}

function foodorasync() {
  if (document.getElementById("foodorafile").files[0] == "") return;
  var reader = new FileReader();

  reader.onload = () => {
    try {

      function replaceCommasInQuotes(text) {
        return text.replace(/"([^"\n\r]*)"/g, (match, p1) => `"${p1.replace(/,/g, '###')}"`);
      }

      const text = replaceCommasInQuotes(reader.result).replaceAll('"', "");
      //console.log(text);

      var workbook = window.XLSX.read(text, { type: "binary", raw: false, /*codepage: 65001*/ });




      admin.getMenuItems((data) => {
        let rowObject = [];
        const encoder = new TextEncoder("UTF-8");
        const decoder = new TextDecoder();
        workbook.SheetNames.forEach(sheet => {
          let json = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
          console.log("Process worksheet " + sheet + " with " + json.length + " elements");
          json.forEach(a => rowObject.push(a));

        }, undefined, true);


        rowObject.filter(row => {
          var name = row.Title_hu_HU;
          if (!name) {
            console.log("Row skipped", row);
            return;
          }
          name = name.replaceAll("###", ",").trim();
          var menuItem = findMenuItemByName(data.children, name);
          if (!menuItem) {
            menuItem = findMenuItemByName(data.children, name, true);
          }
          if (menuItem) {
            if (row.Description_hu_HU)
              menuItem.description.hu = row.Description_hu_HU.replaceAll("###", ",");
            if (row.Description_en) {
              menuItem.description.en = row.Description_en.replaceAll("###", ",");
            }
            if (!menuItem.name.en && row.Title_en) {
              menuItem.name.en = row.Title_en.replaceAll("###", ",");
            }

            var image = row.Image_URL;

            if (!image || !image.indexOf || image.indexOf("https:") === -1) {
              image = Object.values(row).find(v => v.indexOf && v.indexOf("https:") >= 0);
            }
            console.log(name, image, row);

            if (image && !menuItem.image) {
              menuItem.foodpandaImageSrc = image;
            } else {
              menuItem.foodpandaImageSrc = menuItem.foodpandaImage;
            }

            const aq = (menuItem.availableQuantities.find(q => q.quantity == 1));

            if (aq && row.Price) {
              const place = getFeature("foodpanda")?.places?.[0]?.name;
              var setting = aq?.settings?.foodpanda?.[place];
              if (!setting) {
                if (!aq?.settings) {
                  aq.settings = { foodpanda: {}, wolt: {}, falatozz: {}, takeaway: {}, webshop: {} };
                }
                if (!aq.settings.foodpanda[place])
                  setting = aq.settings.foodpanda[place] = {
                    enabled: true,
                  }
              }
              setting.price = row.Price
              setting.enabled = true
            }
            //console.log(name, "=>", menuItem?.id, row, menuItem);
          } else
            if (row.Image_URL)
              console.log(name, "=> not found but has image in foodora");
        })

        function transform(menuCategories) {
          return menuCategories.map(existingCateg => {
            var category = Object.assign({}, existingCateg);
            category.menuCategories = transform(category.children.filter(c => c.entityType === menuitemutil.entityType + "Category"));
            category.menuItems = category.children.filter(c => c.entityType === menuitemutil.entityType).map(m => {
              m.type = m.entityType.toLowerCase();
              m.mainCategory = m.mainCategoryId;
              m.subCategory = m.subCategoryId;
              if (m.image && !m.imageSrc)
                m.imageSrc = menuitemutil.getImageUrl(m.image)
              if (m.bigImage && !m.bigImageSrc)
                m.bigImageSrc = menuitemutil.getImageUrl(m.bigImage)
              if (m.woltImage && !m.woltImageSrc) m.woltImageSrc = m.woltImage;
              if (m.foodpandaImage && !m.foodpandaImageSrc) m.foodpandaImageSrc = m.foodpandaImage;
              m.falatozzImageSrc = m.falatozzImage;
              return m;
            });
            if (category.image && !category.imageSrc)
              category.imageSrc = menuitemutil.getImageUrl(category.image)
            category.mainCategory = category.mainCategoryId;
            category.subCategory = category.subCategoryId;
            delete category.children;
            category.type = category.entityType.toLowerCase();
            return category;
          })
        }

        post("restaurantService/" + sessionStorage.restaurantSelected + "/update" + menuitemutil.entityType + "s", { menuCategories: transform(data.children) }, undefined, undefined, 2, undefined, 60000).done(function (data) {
          // eslint-disable-next-line no-restricted-globals
          menuitemutil.reload();
        });
      });
    } catch (ex) {
      console.log(ex);
    }

  }

  if (document.getElementById("foodorafile").files[0].type === "text/csv")
    reader.readAsText(document.getElementById("foodorafile").files[0]);
  else
    reader.readAsBinaryString(document.getElementById("foodorafile").files[0]);

}

function woltsync() {
  if (document.getElementById("woltfile").files[0] == "") return;
  var reader = new FileReader();

  reader.onload = () => {
    try {

      var workbook = window.XLSX.read(reader.result, { type: "binary", raw: false, /*codepage: 65001*/ });

      admin.getMenuItems((data) => {
        let rowObject = [];
        const encoder = new TextEncoder("UTF-8");
        const decoder = new TextDecoder();
        workbook.SheetNames.forEach(sheet => {
          let json = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
          console.log("Process worksheet " + sheet + " with " + json.length + " elements");
          json.forEach(a => rowObject.push(a));

        }, undefined, true);

        rowObject.filter(row => {
          const name = row.name;
          if (!name) {
            console.log("Row skipped", row);
            return;
          }
          var menuItem = findMenuItemByName(data.children, name);
          if (!menuItem) {
            console.log("Not found " + name);
            menuItem = findMenuItemByName(data.children, name, true);
          }
          if (menuItem) {
            if (!menuItem.description.hu && row.description)
              menuItem.description.hu = row.description;


            if (row.image_url/* && !menuItem.woltImage*/) {
              menuItem.woltImageSrc = row.image_url;
            } else {
              menuItem.woltImageSrc = menuItem.woltImage;
            }

            const aq = (menuItem.availableQuantities.find(q => q.quantity == 1));

            if (aq && row.price) {
              const place = getFeature("wolt")?.places?.[0]?.name;
              var setting = aq?.settings?.wolt?.[place];
              if (!setting) {
                if (!aq?.settings) {
                  aq.settings = { wolt: {}, wolt: {}, falatozz: {}, takeaway: {}, webshop: {} };
                }
                if (!aq.settings.wolt[place])
                  setting = aq.settings.wolt[place] = {
                    enabled: true,
                  }
              }
              setting.price = row.price
              setting.enabled = true
            }
            //console.log(name, "=>", menuItem?.id, row, menuItem);
          } else
            if (row.image_url)
              console.log(name, "=> not found but has image in wolt");
        })

        function transform(menuCategories) {
          return menuCategories.map(existingCateg => {
            var category = Object.assign({}, existingCateg);
            category.menuCategories = transform(category.children.filter(c => c.entityType === menuitemutil.entityType + "Category"));
            category.menuItems = category.children.filter(c => c.entityType === menuitemutil.entityType).map(m => {
              m.type = m.entityType.toLowerCase();
              m.mainCategory = m.mainCategoryId;
              m.subCategory = m.subCategoryId;
              if (m.image && !m.imageSrc)
                m.imageSrc = menuitemutil.getImageUrl(m.image)
              if (m.bigImage && !m.bigImageSrc)
                m.bigImageSrc = menuitemutil.getImageUrl(m.bigImage)
              if (m.woltImage && !m.woltImageSrc) m.woltImageSrc = m.woltImage;
              if (m.foodpandaImage && !m.foodpandaImageSrc) m.foodpandaImageSrc = m.foodpandaImage;
              m.falatozzImageSrc = m.falatozzImage;
              return m;
            });
            if (category.image && !category.imageSrc)
              category.imageSrc = menuitemutil.getImageUrl(category.image)
            category.mainCategory = category.mainCategoryId;
            category.subCategory = category.subCategoryId;
            delete category.children;
            category.type = category.entityType.toLowerCase();
            return category;
          })
        }

        post("restaurantService/" + sessionStorage.restaurantSelected + "/update" + menuitemutil.entityType + "s", { menuCategories: transform(data.children) }, undefined, undefined, 2, undefined, 60000).done(function (data) {
          // eslint-disable-next-line no-restricted-globals
          menuitemutil.reload();
        });
      });
    } catch (ex) {
      console.log(ex);
    }

  }

  if (document.getElementById("woltfile").files[0].type === "text/csv")
    reader.readAsText(document.getElementById("woltfile").files[0]);
  else
    reader.readAsBinaryString(document.getElementById("woltfile").files[0]);

}


function activateMenuItem(active) {
  var data = menuitemutil.menuItems.getActiveNode().data;
  if (data.entityType.indexOf("Category") < 0) {
    utils.activateMenuItem(data.id, active, menuitemutil.reload);
  } else {
    utils.activateMenuCategory(data.id, active, menuitemutil.reload);
  }
}

function deleteMenuItem() {
  var data = menuitemutil.menuItems.getActiveNode().data;
  if (data.entityType.indexOf("Category") < 0) {
    utils.deleteMenuItem(data.id, menuitemutil.reload);
  } else {
    utils.deleteMenuCategory(data.id, menuitemutil.reload);
  }
}
menuitemutil.deleteMenuItem = deleteMenuItem;

menuitemutil.getImageUrl = (id) => {
  return auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + id;
}

function saveMenuCategory(onsave, test = false) {
  var modal = $("div#editMenuCategory");
  var sm = $(modal.find("img#smallImage"));
  if ($.data(sm.get(0), "rcrop")) {
    crop(sm);
  }
  var id = modal.find("#cid").val();
  var globalId = modal.find("#calias").val();
  var parentCategoryId = Number(modal.find("#cparentCategoryId").val());
  var name = {};
  modal.find(".categoryName").each(function (ind, v) {
    if ($(v).val() != "") {
      name[$(v).attr("lang")] = $(v).val();
    }
  });

  if (!name.all && !name[auth.myStatus.restaurant_settings["default-language"]]) {
    modal.find(".categoryName[lang='all']").removeClass("highlight-3");
    modal.find(".categoryName[lang='" + auth.myStatus.restaurant_settings["default-language"] + "']").removeClass("highlight-3");
    setTimeout(() => {
      modal.find(".categoryName[lang='all']").addClass("highlight-3");
      modal.find(".categoryName[lang='" + auth.myStatus.restaurant_settings["default-language"] + "']").addClass("highlight-3");
    }, 10);
    modal.find(".categoryName[lang='all']").focus();
    return;
  }


  var description = {};
  modal.find(".description").each(function (ind, v) {
    if ($(v).val() != "") {
      description[$(v).attr("lang")] = $(v).val();
    }
  });
  var timing = modal.find("#ctiming").val()[0];
  if (timing === "") {
    timing = null;
  }
  if (!parentCategoryId && (!timing || timing === "inherit")) {
    modal.find("#ctiming").removeClass("highlight-3");
    setTimeout(() => {
      modal.find("#ctiming").addClass("highlight-3");
    }, 10);
    return;
  }
  var production_lines = [];
  modal.find("div#production_lines input:checked").each(function (ind, pl) {
    production_lines.push(
      $(pl)
        .attr("id")
        .substring(4)
    );
  });

  var mainCategory = modal.find("#cntak_main_category").val();
  if (!mainCategory) mainCategory = 0;
  var subCategory = modal.find("#cntak_sub_category").val();
  if (!subCategory) subCategory = 0;

  var vat_category = modal.find("#cvat_category").val()[0];
  if (!vat_category) vat_category = -1;

  if (!parentCategoryId && vat_category === -1) {
    modal.find("#cvat_category").removeClass("highlight-3");
    setTimeout(() => {
      modal.find("#cvat_category").addClass("highlight-3");
    }, 10);
    return;
  }

  var takeaway_vat_category = modal.find("#ctakeaway_vat_category").val()[0];
  if (!takeaway_vat_category) takeaway_vat_category = -1;

  var image = $(modal.find("#smallImage")).attr("src");
  if (image == "") image = null;
  var active = modal.find("#mc_active").prop("checked");
  var isTop = modal.find("#mc_isTop").prop("checked");
  var online = modal.find("#mc_online").prop("checked");
  var local = modal.find("#mc_local").prop("checked");
  var takeaway = modal.find("#mc_takeaway").prop("checked");
  var settings = { wolt: {}, foodpanda: {}, falatozz: {} };
  modal.find(".onMenuThirdParty:checked").each((ind, item) => {
    settings[$(item).attr("party")][item.name] = { enabled: true };
  })

  var fromDate = moment(modal.find('input#from_date').val()).toDate().getTime();
  var toDate = moment(modal.find('input#to_date').val()).toDate().getTime();
  var preorder = modal.find("#mc_preorder").prop("indeterminate") ? null : modal.find("#mc_preorder").prop("checked");

  var available = modal.find("#mc_available").prop("checked");
  var inventoryItem = modal.find("#mc_isInventoryItem").prop("indeterminate") ? null : modal.find("#mc_isInventoryItem").prop("checked");
  var pricePerCostRatio = menuitemutil.parseNumber(modal.find("input#cpricePerCostRatio").val()) / 100;
  var color = modal.find("#color").find("input#colorCheckbox").prop('checked') ? modal.find("#color").find("input#color").val() : null;
  if (color == "") color = null;
  var menuItemAdditions = [];
  var menuCategoryAdditions = [];
  var a = modal.find("#category_additions_mandatory");
  var ok = true;
  var order = 0;
  modal
    .find("#category_additions_mandatory")
    .tokenize2()
    .toArray()
    .forEach(function (addition) {
      var quantity = modal.find("#category_additions_mandatory");
      quantity = quantity.next();
      quantity = modal.find('li.token[data-value="' + addition + '"] input').val();
      var id = addition.split(";");
      id.push(quantity);
      if (id[0] == "menuItem") {
        var quantity = modal.find('li[data-value="' + addition + '"] input#quantity').val();
        if (typeof quantity == "undefined") quantity = id[3];
        if (!quantity || isNaN(quantity) || quantity == 0) {
          ok = false;
          modal.find('li[data-value="' + addition + '"]').removeClass("highlight-3");
          setTimeout(() => {
            modal.find('li[data-value="' + addition + '"]').addClass("highlight-3");
          }, 100)
        }
        var menuItem = menuitemutil.getMenuItem(id[2]);
        if (menuItem)
          menuItemAdditions.push({
            id: id[1],
            addition: {
              id: id[2],
              type: menuItem.entityType.toLowerCase()
            },
            order: order++,
            proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
            local: modal.find('li.token[data-value="' + addition + '"] input.local').prop('checked'),
            takeaway: modal.find('li.token[data-value="' + addition + '"] input.takeaway').prop('checked'),
            type: menuItem.entityType.toLowerCase() + "_addition",
            additionType: "mandatory",
            quantity: quantity
          });
      } else {
        var menuItem = menuitemutil.getMenuCategory(id[2]);
        menuCategoryAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: menuItem.entityType.toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          type: menuItem.entityType.toLowerCase() + "_addition",
          additionType: "mandatory",
          min: modal.find('li.token[data-value="' + addition + '"] input#min').val(),
          max: modal.find('li.token[data-value="' + addition + '"] input#max').val()
        });
      }
    });
  order = 0;
  modal
    .find("#category_additions_included")
    .tokenize2()
    .toArray()
    .forEach(function (addition) {
      var id = addition.split(";");
      if (id[0] == "menuItem") {
        var quantity = modal.find('li[data-value="' + addition + '"] input#quantity').val();
        if (typeof quantity == "undefined") quantity = id[3];
        if (!quantity || isNaN(quantity) || quantity == 0) {
          ok = false;
          modal.find('li[data-value="' + addition + '"]').removeClass("highlight-3");
          setTimeout(() => {
            modal.find('li[data-value="' + addition + '"]').addClass("highlight-3");
          }, 100)
        }
        var menuItem = menuitemutil.getMenuItem(id[2]);
        menuItemAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: menuItem.entityType.toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          local: modal.find('li.token[data-value="' + addition + '"] input.local').prop('checked'),
          takeaway: modal.find('li.token[data-value="' + addition + '"] input.takeaway').prop('checked'),
          type: menuItem.entityType.toLowerCase() + "_addition",
          additionType: "included",
          quantity: quantity
        });
      } else {
        var menuItem = menuitemutil.getMenuCategory(id[2]);
        menuCategoryAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: menuItem.entityType.toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          type: menuItem.entityType.toLowerCase() + "_addition",
          additionType: "included",
          min: modal.find('li.token[data-value="' + addition + '"] input#min').val(),
          max: modal.find('li.token[data-value="' + addition + '"] input#max').val()
        });
      }
    });
  order = 0;
  modal
    .find("#category_additions_optional")
    .tokenize2()
    .toArray()
    .forEach(function (addition) {
      var id = addition.split(";");
      if (id[0] == "menuItem") {
        var menuItem = menuitemutil.getMenuItem(id[2]);
        var quantity = modal.find('li[data-value="' + addition + '"] input#quantity').val();
        if (typeof quantity == "undefined") quantity = id[3];
        if (!quantity || isNaN(quantity) || quantity == 0) {
          ok = false;
          modal.find('li[data-value="' + addition + '"]').removeClass("highlight-3");
          setTimeout(() => {
            modal.find('li[data-value="' + addition + '"]').addClass("highlight-3");
          }, 100)
        }
        menuItemAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: id[4].toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          local: modal.find('li.token[data-value="' + addition + '"] input.local').prop('checked'),
          takeaway: modal.find('li.token[data-value="' + addition + '"] input.takeaway').prop('checked'),
          type: id[4].toLowerCase() + "_addition",
          additionType: "optional",
          quantity: quantity
        });
      } else {
        var menuItem = menuitemutil.getMenuCategory(id[2]);
        var optionType = modal.find('input[id="check' + addition + '"]').prop("checked") ? "multiple" :
          modal.find('input[id="check2' + addition + '"]').prop("checked") ? "separate" :
            modal.find('input[id="radio' + addition + '"]').prop("checked") ? "maxone" : "one";
        menuCategoryAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: id[4].toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          type: id[4].toLowerCase() + "_addition",
          additionType: "optional",
          optionType: optionType,
          min: modal.find('li.token[data-value="' + addition + '"] input#min').val(),
          max: modal.find('li.token[data-value="' + addition + '"] input#max').val()
        });
      }
    });
  var labels = [];
  modal
    .find("#category_labels")
    .tokenize2({
      tokensAllowCustom: true
    })
    .toArray()
    .forEach(function (addition) {
      if (addition.indexOf(";") !== -1) {
        var id = addition.split(";");
        labels.push({
          id: id[1]
        });
      } else {
        labels.push({
          id: 0,
          name: addition
        });
      }
    });


  if (test) {
    const r = {
      name,
      globalId,
      description,
      image,
      timing,
      productionLines: production_lines,
      vatCategory: { id: vat_category },
      takeawayVatCategory: { id: takeaway_vat_category },
      isActive: active,
      isTop,
      online,
      local,
      takeaway,
      available,
      inventoryItem,
      menuItemAdditions,
      menuCategoryAdditions,
      labels,
      pricePerCostRatio,
      color,
      settings,
      fromDate,
      toDate,
      preorder,
      mainCategory,
      subCategory
    };
    return r;
  }

  if (!ok)
    return;
  if (id) {
    utils.modifyMenuCategory(
      {
        id,
        globalId,
        name,
        description,
        image,
        timing,
        productionLines: production_lines,
        vatCategory: { id: vat_category },
        takeawayVatCategory: { id: takeaway_vat_category },
        isActive: active,
        isTop,
        online,
        local,
        takeaway,
        available,
        inventoryItem,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        settings,
        fromDate,
        toDate,
        preorder,
        mainCategory,
        subCategory
      },
      function (data) {
        modal.modal("hide");
        onsave();
      }
    );
  } else {
    utils.addMenuCategory(
      parentCategoryId,
      {
        globalId,
        name,
        description,
        image,
        timing,
        productionLines: production_lines,
        vatCategory: { id: vat_category },
        takeawayVatCategory: { id: takeaway_vat_category },
        isActive: active,
        isTop,
        online,
        local,
        takeaway,
        available,
        inventoryItem,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        settings,
        fromDate,
        toDate,
        preorder,
        mainCategory,
        subCategory
      },
      function (data) {
        modal.modal("hide");
        onsave().done(() => {
          menuitemutil.menuItems.activateKey(data.entityType + data.id);
        });
      }
    );
  }
}
menuitemutil.saveMenuCategory = saveMenuCategory;

function saveMenuItemAndNew(onsave) {
  saveMenuItem(onsave, true).done(() => {
    var modal = $("div#editMenuItem");
    modal.find(".menuItemName").each(function (ind, v) {
      $(v).val("");
    });
    modal.find("textarea.description").each(function (ind, v) {
      $(v).val("");
    });
    modal.find("#id").val("");
    modal.find("#smallImage").attr("src", null);
    modal.find("input#short_name").val("");
  });
}

menuitemutil.saveMenuItemAndNew = saveMenuItemAndNew;

function saveMenuItem(onsave, keep, test = false, force = {}) {
  const def = $.Deferred();
  if (keep == undefined) keep = false;
  var modal = $("div#editMenuItem");
  var sm = $(modal.find("img#smallImage"));
  if ($.data(sm.get(0), "rcrop")) {
    crop(sm);
  }
  var id = modal.find("#id").val();
  var globalId = modal.find("#alias").val();
  var action = modal.find("#action").val();
  var parentCategoryId = modal.find("#parentCategoryId").val();
  var shortName = modal.find("input#short_name").val();
  var manufacturer = modal.find("input#manufacturer").val();
  var mandatory_volume = Number(modal.find("input#mandatory_volume").val());
  var name = {};
  modal.find(".menuItemName").each(function (ind, v) {
    if ($(v).val() != "") {
      name[$(v).attr("lang")] = $(v).val();
    }
  });
  var description = {};
  modal.find(".description").each(function (ind, v) {
    if ($(v).val() != "") {
      description[$(v).attr("lang")] = $(v).val();
    }
  });
  var timing = modal.find("#timing").val()[0];
  var production_lines = [];
  modal.find("div#production_lines input:checked").each(function (ind, pl) {
    production_lines.push(
      $(pl)
        .attr("id")
        .substring(4)
    );
  });
  var mainCategory = modal.find("#mntak_main_category").val();
  if (!mainCategory) mainCategory = 0;
  var subCategory = modal.find("#mntak_sub_category").val();
  if (!subCategory) subCategory = 0;

  var vat_category = modal.find("#vat_category").val()[0];
  if (!vat_category) vat_category = -1;
  var takeaway_vat_category = modal.find("#takeaway_vat_category").val()[0];
  if (!takeaway_vat_category) takeaway_vat_category = -1;
  var quantityType = modal.find("#quantityType").val()[0];
  var unitPrice = modal.find("#unit_price").val();
  var minimumPrice = $("#editMenuItem input#menuItem_minimumPrice").prop("checked");
  var cost = modal.find("#unit_cost").val();
  var image = $(modal.find("#smallImage")).attr("src");
  if (image == "") image = null;

  var woltImage = $(modal.find("#smallImageWolt")).attr("src");
  if (woltImage == "") woltImage = null;

  var foodpandaImage = $(modal.find("#smallImageFoodpanda")).attr("src");
  if (foodpandaImage == "") foodpandaImage = null;

  var falatozzImage = $(modal.find("#smallImageFalatozz")).attr("src");
  if (falatozzImage == "") falatozzImage = null;


  var bigImage = $(modal.find("#smallImage")).data("bigImage");
  var active = modal.find("#mi_active").prop("checked");
  var isTop = modal.find("#mi_isTop").prop("checked");
  var freeQuantity = !modal.find("#mi_freequantity").prop("checked");
  var online = modal.find("#mi_online").prop("checked");
  var available = modal.find("#mi_available").prop("checked");
  var color = modal.find("#color").find("input#colorCheckbox").prop('checked') ? modal.find("#color").find("input#color").val() : null;
  if (color == "") color = null;
  var inventoryItem = modal.find("#mi_isInventoryItem").prop("indeterminate") ? undefined : modal.find("#mi_isInventoryItem").prop("checked");
  var pricePerCostRatio = menuitemutil.parseNumber(modal.find("input#pricePerCostRatio").val()) / 100;
  var availableQuantities = [];

  var fromDate = moment(modal.find('input#m_from_date').val()).toDate().getTime();
  var toDate = moment(modal.find('input#m_to_date').val()).toDate().getTime();
  var preorder = modal.find("#m_preorder").prop("indeterminate") ? null : modal.find("#m_preorder").prop("checked");


  const places = { foodpanda: {}, wolt: {}, falatozz: {} };

  if (!name.all && !name[auth.myStatus.restaurant_settings["default-language"]]) {
    modal.find(".menuItemName[lang='all']").removeClass("highlight-3");
    modal.find(".menuItemName[lang='" + auth.myStatus.restaurant_settings["default-language"] + "']").removeClass("highlight-3");
    setTimeout(() => {
      modal.find(".menuItemName[lang='all']").addClass("highlight-3");
      modal.find(".menuItemName[lang='" + auth.myStatus.restaurant_settings["default-language"] + "']").addClass("highlight-3");
    }, 10);
    modal.find(".menuItemName[lang='all']").focus();
    return def.promise();
  }

  const menuCategory = admin.getMenuCategory(parentCategoryId);

  if (quantityType == null) {
    modal.find("#quantityType").removeClass("highlight-3");
    setTimeout(() => {
      modal.find("#quantityType").addClass("highlight-3");
    }, 10);
    modal.find("#quantityType").focus();
    return def.promise();
  }

  var quantities = [];
  var ok = true;
  var barcodes = [];
  if (menuItemQuantities.getRootNode().getChildren() != null)
    menuItemQuantities
      .getRootNode()
      .getChildren()
      .forEach(function (node) {
        if (!ok) return;
        var labels = {};
        $(node.tr)
          .find("input.menuItemTypeName")
          .each(function (ind, n) {
            if ($(n).val() != null && $(n).val() != "") labels[$(n).attr("lang")] = $(n).val();
          });
        var q = $(node.tr)
          .find("#menuItem_quantity")
          .val();

        if (quantities.indexOf(q) != -1) {
          messageDialog(I18n.t("local.error_message"), I18n.t("admin_local.quantity_must_be_unique")).done(() => {
            $("#editMenuItem #menuItem_quantity").removeClass("highlight-3");
            setTimeout(() => {
              $("#editMenuItem #menuItem_quantity").addClass("highlight-3");
            }, 10);
          });
          ok = false;
          return;
        }
        quantities.push(q);

        var p = $(node.tr)
          .find("#menuItem_price")
          .val();
        if (p == 0) {
          p = "";
        }
        var cost = $(node.tr).find("#menuItem_cost").val();
        var inventoryItem = $(node.tr).find("input.inventoryItem").prop("checked");
        var onMenu = $(node.tr).find("input.onMenu").prop("checked");
        var def = node.selected == true ? true : false;
        var quantityType2 = $(node.tr).find("#quantityType option:selected").attr("id");
        const settings = { foodpanda: {}, wolt: {}, falatozz: {}, takeaway: {}, webshop: {} }
        var containerCharge = $(node.tr).find("input.menuItem_containerCharge").val() || undefined;
        var containerChargeOnsite = $(node.tr).find("input.menuItem_containerChargeOnsite").prop("checked");
        var labelCount = $(node.tr).find("input.menuItem_labelCount").val() || 0;

        $(node.tr).find(".thirdParty[type='checkbox']").each((ind, item) => {
          const parent = $(item).parent();
          const type = parent.attr('type');
          const name = $(item).parent().attr("name");
          if (type)
            settings[type][name] = {
              enabled: $(item).prop("checked"),
              price: $(item).parents("tr").find("input#menuItem_price_" + type + "_" + name).val()
            };
        });

        if (q)
          availableQuantities.push({ quantity: q, price: p, quantityType: { id: quantityType2 }, def: def, onMenu: onMenu, inventoryItem: inventoryItem, cost: cost, settings: settings, containerCharge, containerChargeOnsite, labelCount });
      });

  if (!test && quantityType !== "none" && inventoryItem !== false && (inventoryItem === true || menuCategory.defaultInventoryItem) && availableQuantities.filter(q => q.inventoryItem).length == 0) {
    messageDialog(I18n.t("local.error_message"), I18n.t("admin_local.no_inventory_quantity_selected"));
    modal.find("input.inventoryItem + label").removeClass("highlight-3");
    setTimeout(() => {
      modal.find("input.inventoryItem + label").addClass("highlight-3");
    }, 100);
    return;
  }

  if (!test && !force.inventoryItem && inventoryItem !== false && (inventoryItem === true || menuCategory.defaultInventoryItem) && availableQuantities.filter(q => q.inventoryItem).length > 1) {
    const new_q = availableQuantities.filter(q => q.inventoryItem).length;
    const old_q = id && getMenuItem(id).availableQuantities.filter(q => q.inventoryItem).length;
    if (new_q != old_q) {
      modal.find("input.inventoryItem + label").removeClass("highlight-3");
      setTimeout(() => {
        modal.find("input.inventoryItem + label").addClass("highlight-3");
      }, 100);
      confirmDialog(I18n.t("local.error_message"), I18n.t("admin_local.multiple_inventory_quantity_selected")).done(() => {
        saveMenuItem(onsave, keep, test, { ...force, inventoryItem: true });
      });
      return;
    }
  }

  modal
    .find("#item_barcodes")
    .tokenize2()
    .toArray()
    .forEach(function (barcode, ind) {
      barcodes.push({ id: barcode.replace("`", "0"), quantity: 0 })
    });

  if (!ok && !test) return def.promise();
  var menuItemAdditions = [];
  var menuCategoryAdditions = [];
  var order = 0;
  modal
    .find("#item_additions_mandatory")
    .tokenize2()
    .toArray()
    .forEach(function (addition, ind) {
      var quantity = modal.find("#item_additions_mandatory");
      quantity = quantity.next();
      quantity = modal.find('li.token[data-value="' + addition + '"] input').val();
      var id = addition.split(";");
      id.push(quantity);
      if (id[0] == "menuItem") {
        var quantity = modal.find('li[data-value="' + addition + '"] input#quantity').val();
        if (typeof quantity == "undefined") quantity = id[3];
        if (!quantity || isNaN(quantity) || quantity == 0) {
          ok = false;
          modal.find('li[data-value="' + addition + '"]').removeClass("highlight-3");
          setTimeout(() => {
            modal.find('li[data-value="' + addition + '"]').addClass("highlight-3");
          }, 100)
        }
        menuItemAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: id[4].toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          local: modal.find('li.token[data-value="' + addition + '"] input.local').prop('checked'),
          takeaway: modal.find('li.token[data-value="' + addition + '"] input.takeaway').prop('checked'),
          type: id[4].toLowerCase() + "_addition",
          additionType: "mandatory",
          quantity: quantity / mandatory_volume
        });
      } else {
        menuCategoryAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: id[4].toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          type: id[4].toLowerCase() + "_addition",
          additionType: "mandatory",
          min: modal.find('li.token[data-value="' + addition + '"] input#min').val(),
          max: modal.find('li.token[data-value="' + addition + '"] input#max').val()
        });
      }
    });
  order = 0;
  modal
    .find("#item_additions_included")
    .tokenize2()
    .toArray()
    .forEach(function (addition) {
      var quantity = modal.find('li[data-value="' + addition + '"] input#quantity').val();
      var id = addition.split(";");
      if (id[0] == "menuItem") {
        if (typeof quantity == "undefined") {
          quantity = id[3];
        } else if (!quantity || isNaN(quantity) || quantity == 0) {
          ok = false;
          modal.find('li[data-value="' + addition + '"]').removeClass("highlight-3");
          setTimeout(() => {
            modal.find('li[data-value="' + addition + '"]').addClass("highlight-3");
          }, 100)
        }
        menuItemAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: id[4].toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          local: modal.find('li.token[data-value="' + addition + '"] input.local').prop('checked'),
          takeaway: modal.find('li.token[data-value="' + addition + '"] input.takeaway').prop('checked'),
          type: id[4].toLowerCase() + "_addition",
          additionType: "included",
          quantity: quantity
        });
      } else {
        menuCategoryAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: id[4].toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          type: id[4].toLowerCase() + "_addition",
          additionType: "included",
          min: modal.find('li.token[data-value="' + addition + '"] input#min').val(),
          max: modal.find('li.token[data-value="' + addition + '"] input#max').val()
        });
      }
    });
  order = 0;
  modal
    .find("#item_additions_optional")
    .tokenize2()
    .toArray()
    .forEach((addition, ind) => {
      var quantity = modal.find('li[data-value="' + addition + '"] input#quantity').val();
      var id = addition.split(";");
      if (id[0] == "menuItem") {
        if (typeof quantity == "undefined") {
          quantity = id[3];
        } else if (!quantity || isNaN(quantity) || quantity == 0) {
          ok = false;
          modal.find('li[data-value="' + addition + '"]').removeClass("highlight-3");
          setTimeout(() => {
            modal.find('li[data-value="' + addition + '"]').addClass("highlight-3");
          }, 100)
        }
        menuItemAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: id[4].toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          local: modal.find('li.token[data-value="' + addition + '"] input.local').prop('checked'),
          takeaway: modal.find('li.token[data-value="' + addition + '"] input.takeaway').prop('checked'),
          type: id[4].toLowerCase() + "_addition",
          additionType: "optional",
          quantity: quantity
        });
      } else {
        var optionType = modal.find('input[id="check' + addition + '"]').prop("checked") ? "multiple" :
          modal.find('input[id="check2' + addition + '"]').prop("checked") ? "separate" :
            modal.find('input[id="radio' + addition + '"]').prop("checked") ? "maxone" : "one";
        menuCategoryAdditions.push({
          id: id[1],
          addition: {
            id: id[2],
            type: id[4].toLowerCase()
          },
          order: order++,
          proportional: modal.find('li.token[data-value="' + addition + '"] input.proportional').prop('checked'),
          type: id[4].toLowerCase() + "_addition",
          additionType: "optional",
          optionType: optionType,
          min: modal.find('li.token[data-value="' + addition + '"] input#min').val(),
          max: modal.find('li.token[data-value="' + addition + '"] input#max').val()
        });
      }
    });
  var labels = [];
  modal
    .find("#item_labels")
    .tokenize2({
      tokensAllowCustom: true
    })
    .toArray()
    .forEach(function (addition) {
      if (addition.indexOf(";") !== -1) {
        var id = addition.split(";");
        labels.push({
          id: id[1]
        });
      } else {
        labels.push({
          id: 0,
          name: addition
        });
      }
    });
  if (!ok && !test) return def.promise();

  const newContent = {
    id,
    globalId,
    barcodes,
    action,
    shortName,
    manufacturer,
    name,
    description,
    image,
    bigImage,
    quantityType,
    cost,
    unitPrice,
    availableQuantities,
    timing,
    productionLines: production_lines,
    vatCategory: { id: vat_category },
    takeawayVatCategory: { id: takeaway_vat_category },
    active,
    isTop,
    online,
    available,
    inventoryItem,
    freeQuantity,
    menuItemAdditions,
    menuCategoryAdditions,
    labels,
    pricePerCostRatio,
    color,
    mandatory_volume,
    places,
    preorder,
    toDate,
    fromDate,
    mainCategory,
    subCategory,
    woltImage,
    foodpandaImage,
    falatozzImage,
    minimumPrice
  }

  if (test) {
    return newContent;
  }

  modal.find('#save').prop('disabled', true);
  modal.find('#saveAndNew').prop('disabled', true);
  modal.find('#saveAndOpen').prop('disabled', true);

  if (id != "") {
    const newMenuItemAdditionReceipe = newContent.menuItemAdditions.filter(a => a.additionType === "mandatory" || a.additionType === "included").filter(a => getMenuItem(a.addition.id).defaultInventoryItem);
    var oldMenuItemAdditionReceipe = {};
    try {
      oldMenuItemAdditionReceipe = JSON.parse(menuitemutil.oldMenuItemData).menuItemAdditions.filter(a => a.additionType === "mandatory" || a.additionType === "included").filter(a => getMenuItem(a.addition.id).defaultInventoryItem);
    } catch (ex) { }
    if (menuitemutil.oldMenuItemData !== JSON.stringify(newContent)) {
      const send = (recalculateInventory = false) => utils.modifyMenuItem(
        parentCategoryId,
        {
          id,
          globalId,
          barcodes,
          action,
          shortName,
          manufacturer,
          name,
          description,
          image,
          bigImage,
          quantityType,
          cost,
          unitPrice,
          availableQuantities,
          timing,
          productionLines: production_lines,
          vatCategory: { id: vat_category },
          takeawayVatCategory: { id: takeaway_vat_category },
          isActive: active,
          isTop,
          online,
          available,
          inventoryItem,
          freeQuantity,
          menuItemAdditions,
          menuCategoryAdditions,
          labels,
          pricePerCostRatio,
          color,
          mandatory_volume,
          places,
          preorder,
          toDate,
          fromDate,
          mainCategory,
          subCategory,
          woltImage,
          foodpandaImage,
          falatozzImage,
          minimumPrice
        },
        function (data) {
          modal.find('#save').prop('disabled', false);
          modal.find('#saveAndNew').prop('disabled', false);
          modal.find('#saveAndOpen').prop('disabled', false);
          if (!keep) {
            modal.modal("hide");
          }
          onsave([data]);
          if (quantityType != JSON.parse(menuitemutil.oldMenuItemData).quantityType)
            menuitemutil._reload();
          def.resolve();
        },
        () => {
          modal.find('#save').prop('disabled', false);
          modal.find('#saveAndNew').prop('disabled', false);
          modal.find('#saveAndOpen').prop('disabled', false);
        },
        recalculateInventory
      )

      if (JSON.stringify(oldMenuItemAdditionReceipe) !== JSON.stringify(newMenuItemAdditionReceipe)) {
        choiceDialog2(I18n.t("local.question"), I18n.t("admin_local.product_receipe_modified_recalculate_it"), [I18n.t("admin_local.yes"), I18n.t("admin_local.no")]).done(choice => {
          switch (choice) {
            case 0:
              send(true);
              break;
            case 1:
              send(false);
              break;
            default:
          }
        });
      } else
        send();

    }
    else {
      modal.find('#save').prop('disabled', false);
      modal.find('#saveAndNew').prop('disabled', false);
      modal.find('#saveAndOpen').prop('disabled', false);
      if (!keep) {
        modal.modal("hide");
      }
      onsave([menuitemutil.data]);
      def.resolve();
    }
    return def.promise();
  } else {
    menuItemAdditions.forEach(a => delete a.id);
    menuCategoryAdditions.forEach(a => delete a.id);
    utils.addMenuItem(
      parentCategoryId,
      {
        globalId,
        barcodes,
        shortName,
        manufacturer,
        name,
        description,
        image,
        bigImage,
        quantityType,
        cost,
        unitPrice,
        availableQuantities,
        timing,
        productionLines: production_lines,
        vatCategory: { id: vat_category },
        takeawayVatCategory: { id: takeaway_vat_category },
        isActive: active,
        isTop,
        online,
        available,
        inventoryItem,
        freeQuantity,
        menuItemAdditions,
        menuCategoryAdditions,
        labels,
        pricePerCostRatio,
        color,
        mandatory_volume,
        places,
        preorder,
        toDate,
        fromDate,
        mainCategory,
        subCategory,
        woltImage,
        foodpandaImage,
        falatozzImage,
        minimumPrice
      },
      function (data) {
        modal.find('#save').prop('disabled', false);
        modal.find('#saveAndNew').prop('disabled', false);
        modal.find('#saveAndOpen').prop('disabled', false);
        if (!keep) {
          modal.modal("hide");
        }
        $('#editMenuItem .modal-body #id').val(data.id);
        onsave([data]).done(() => {
          menuitemutil.menuItems.activateKey(data.entityType + data.id);
        });
        def.resolve();
      },
      () => {
        modal.find('#save').prop('disabled', false);
        modal.find('#saveAndNew').prop('disabled', false);
        modal.find('#saveAndOpen').prop('disabled', false);
      }
    );
    return def.promise();
  }
}

menuitemutil.saveMenuItem = saveMenuItem;

function clearTokens(tokenizer) {
  tokenizer.trigger("tokenize:clear");

  /*
  tokenizer.toArray().forEach(function (addition) {
    tokenizer.trigger("tokenize:tokens:remove", addition);
  });*/
}

var deletedMenuItems = {}
menuitemutil.getMenuItem2 = (id, handler) => {
  var menuItem = menuitemutil._getMenuItem(id, admin.categories.activeMenuCategories ? admin.categories.activeMenuCategories : admin.categories.children);
  if (menuItem === null || menuItem === '') {
    if (deletedMenuItems[id]) {
      handler(deletedMenuItems[id]);
      return deletedMenuItems[id];
    }
    getMenuItemById(id, menuItem => {
      menuItem.type = 'deleted';
      menuItem.name = admin_local.deleted + ' --- ' + getLocale(menuItem.name) + " --- ";
      deletedMenuItems[id] = menuItem;
      menuitemutil.getMenuCategory2(menuItem.menuCategoryId, cat => {
        handler(menuItem);
      })
    });
    return;
  }
  handler(menuItem);
  return menuItem;
}

var deletedMenuCategories = {}
menuitemutil.getMenuCategory2 = (id, handler) => {
  var menuItem = menuitemutil._getMenuCategory(id, admin.categories.activeMenuCategories ? admin.categories.activeMenuCategories : admin.categories.children);
  if (menuItem === null || menuItem === '') {
    if (deletedMenuCategories[id]) {
      handler(deletedMenuCategories[id]);
      return deletedMenuCategories[id];
    }
    getMenuCategoryById(id, menuItem => {
      menuItem.type = 'deleted';
      menuItem.name = admin_local.deleted + ' --- ' + getLocale(menuItem.name) + " --- ";
      deletedMenuCategories[id] = menuItem;
      if (menuItem.menuCategoryId)
        menuitemutil.getMenuCategory2(menuItem.menuCategoryId, cat => {
          handler(menuItem);
        })
      else
        handler(menuItem);

    });
    return;
  }
  handler(menuItem);
  return menuItem;
}

menuitemutil.getMenuItem = function (id, base = admin.categories) {
  var menuItem = menuitemutil._getMenuItem(id, base.activeMenuCategories ? base.activeMenuCategories : base.children);
  if (menuItem == "") {
    console.log("failed to find menu item " + id);
    return null;
  }
  return menuItem;
};

menuitemutil._getMenuItem = function (id, categories) {
  var val = null;
  categories.forEach(function (item) {
    if (val != null) return;
    if (item.entityType.indexOf("Category") < 0 && item.id == id) {
      val = item;
    } else {
      if (item.activeMenuCategories && item.activeMenuCategories.length > 0) val = menuitemutil._getMenuItem(id, item.activeMenuCategories);
      if (val == null && item.activeMenuItems && item.activeMenuItems.length > 0) val = menuitemutil._getMenuItem(id, item.activeMenuItems);
      if (val == null && item.children && item.children.length > 0) val = menuitemutil._getMenuItem(id, item.children);
    }
  });
  return val;
};

menuitemutil.getMenuCategory = function (id, base = admin.categories) {
  var menuItem = menuitemutil._getMenuCategory(id, base.activeMenuCategories ? base.activeMenuCategories : base.children);
  if (!menuItem) {
    if (deletedMenuCategories[id]) {
      return deletedMenuCategories[id];
    }
    console.log("failed to find menu category " + id);
    return null;
  }
  return menuItem;
};

menuitemutil._getMenuCategory = function (id, categories) {
  var val = null;
  categories.forEach(function (item) {
    if (val != null) return;
    if (item.entityType.indexOf("Category") > 0 && item.id == id) {
      val = item;
    } else {
      if (item.activeMenuCategories && item.activeMenuCategories.length > 0) val = menuitemutil._getMenuCategory(id, item.activeMenuCategories);
      if (val == null && item.activeMenuItems && item.activeMenuItems.length > 0) val = menuitemutil._getMenuCategory(id, item.activeMenuItems);
      if (val == null && item.children && item.children.length > 0) val = menuitemutil._getMenuCategory(id, item.children);
    }
  });
  return val;
};

var modalOpen = null;
function paste(event) {
  if (modalOpen == null) return;
  const modal = modalOpen;
  var items = (event.clipboardData || event.originalEvent.clipboardData).items;
  for (let index in items) {
    var item = items[index];
    if (item.kind === "file") {
      var blob = item.getAsFile();
      var reader = new FileReader();
      reader.onload = function (event) {
        $(modal.find("#smallImageDiv")).removeClass("hidden");
        onPhotoDataSuccess(event.target.result, $(modal.find("#smallImageDiv img")), 160, 160, 160, 160, true);
      }; // data url!
      reader.readAsDataURL(blob);
    }
  }
}

export const populateEditCategory = (modal, recipient, data, menuCategoryId) => {
  if (!recipient) return;
  menuitemutil.data = data;
  if (typeof menuitemutil.entityType == "undefined" && data != null) {
    menuitemutil.entityType = data.entityType;
    if (data.entityType == "Drink" || data.entityType == "DrinkCategory") {
      menuitemutil.entityType = "drink";
      menuitemutil.entityType = "Drink";
    } else {
      menuitemutil.entityType = "meal";
      menuitemutil.entityType = "Meal";
    }
  }

  modalOpen = modal;
  $("body").unbind("paste");
  $("body").on("paste", paste);

  modal.find("#save").prop("disabled", false);
  modal.find("#saveAndOpen").prop("disabled", false);
  modal.find("#saveAndNew").prop("disabled", false);

  $("#crop").hide();

  if (data && data.color) {
    modal.find("#color").find("input#color").val(data.color);
    modal.find("#color").find("input#colorCheckbox").prop("checked", true);
  } else {
    modal.find("#color").find("input#color").val("#FFFFFF");
    modal.find("#color").find("input#colorCheckbox").prop("checked", false);
  }
  modal.find("#input#color").find("input").change(() => { });

  modal.find(".highlight-3").removeClass("highlight-3");

  clearTokens(modal.find("#category_additions_mandatory").tokenize2());
  clearTokens(modal.find("#category_additions_included").tokenize2());
  clearTokens(modal.find("#category_additions_optional").tokenize2());
  clearTokens(modal.find("#category_labels").tokenize2());
  clearTokens(modal.find("#category_references").tokenize2());
  modal.find("div#production_lines input").prop("checked", false);
  modal.find("div#production_lines input").parent().removeClass("active");
  modal.find("div#production_lines input").parent().attr("default", false);
  modal.find(".onMenuThirdParty").prop('checked', false);
  modal.find(".onMenuThirdParty").removeClass("active");

  modal.find('input.datepicker').val("");
  modal.find('input.datepicker').prop("placeholder", "");

  modal.find("#cntak_main_category").val("");
  menuitemutil.updateNtakCategories("c", modal, data);
  modal.find("#cntak_sub_category").val("");
  modal.find("#cparentCategoryId").val(menuCategoryId);
  modal.find("#cvat_category").val("");
  modal.find("#ctakeaway_vat_category").val("");


  if (recipient == "edit") {
    modal.find("#cid").val(data.id);
    modal.find("#calias").val(data.globalId);
    auth.myStatus.restaurant_languages.forEach(function (lang) {
      modal.find("#cname_" + lang).val(data.name[lang]);
      modal.find("#cdescription_" + lang).val(data.description[lang]);
    });
    if (data.image && data.image != "") {
      modal.find("#smallImage").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + data.image);
      $(modal.find("#smallImageDiv")).removeClass("hidden");
    } else {
      modal.find("#smallImage").attr("src", "");
      // $(modal.find('#smallImageDiv')).addClass('hidden');
    }
    modal.find("#ctiming").val(data.timing);
    data.productionLines.forEach(function (pl) {
      modal.find("div#production_lines input#cpl_" + pl.id).prop("checked", "true");
      modal.find("div#production_lines input#cpl_" + pl.id).parent().addClass("active");
    });
    if (data.defaultProductionLineIds)
      data.defaultProductionLineIds.split(",").forEach(function (pl) {
        modal.find("div#production_lines input#cpl_" + pl).parent().attr("default", "true");
      });

    if (data.settings) {
      if (data.settings.wolt)
        Object.keys(data.settings.wolt).forEach(key => {
          modal.find(".onMenuThirdParty#conMenu_wolt_" + key).prop('checked', data.settings.wolt[key].enabled);
        })
      if (data.settings.foodpanda)
        Object.keys(data.settings.foodpanda).forEach(key => {
          modal.find(".onMenuThirdParty#conMenu_foodpanda_" + key).prop('checked', data.settings.foodpanda[key].enabled);
        })
    }


    modal.find("#cvat_category").val(data.vatCategory ? data.vatCategory.id : "");
    modal.find("#ctakeaway_vat_category").val(data.takeawayVatCategory ? data.takeawayVatCategory.id : "");
    modal.find("#cname_all").val(data.name["all"]);
    modal.find("#cdescription_all").val(data.description["all"]);
    if (data.pricePerCostRatio)
      new Cleave(modal.find("#cpricePerCostRatio"), {
        numeral: true
      }).onInput("" + data.pricePerCostRatio * 100);
    else
      new Cleave(modal.find("#cpricePerCostRatio"), {
        numeral: true
      }).onInput("");

    modal.find("#cpricePerCostRatio").attr("placeholder", "");
    if (menuCategoryId) {
      var menuCategory = data ? getMenuCategory(data) : admin.getMenuCategory(menuCategoryId);
      modal.find("#cpricePerCostRatio").attr("placeholder", menuCategory.defaultPricePerCostRatio * 100);
    }

    modal.find("#cntak_main_category").val(data.mainCategoryId);
    menuitemutil.updateNtakCategories("c", modal);
    modal.find("#cntak_sub_category").val(data.subCategoryId);

    const additions = [...data.menuItemAdditions, ...data.menuCategoryAdditions];
    additions.sort((a, b) => a.order - b.order);
    var local = true;
    var takeaway = true;
    additions.forEach((addition, ind) => {
      if (addition.type === "meal_addition" || addition.type === "drink_addition") {
        menuitemutil.getMenuItem2(addition.additionId, menuItem => {
          const quantity = Math.round(1000 * parseFloat(addition.quantity)) / 1000 * (addition.additionType === "mandatory" && data.mandatory_volume ? data.mandatory_volume : 1);
          modal
            .find("#category_additions_" + addition.additionType)
            .trigger("tokenize:tokens:add", [
              "menuItem;" + addition.id + ";" + menuItem.id + ";" + (addition.additionType == "mandatory" ? "" : (quantity)) + ";" + addition.type.split("_")[0],
              menuitemutil.getPath(menuItem) +
              menuitemutil.getLocalOption("al_" + ind, addition.local) +
              menuitemutil.getTakeawayOption("at_" + ind, addition.takeaway) +
              menuitemutil.getProportionalOption("aa_" + ind, addition.proportional) +
              "\n" +
              menuitemutil.getQuantityLabel2(addition.additionType, quantity, menuItem.quantityType, getLocale(getNameForQuantity(menuItem, quantity))) +
              (menuItem.costEstimation * quantity > 0 ? "&nbsp;(" + Math.round(menuItem.costEstimation * quantity * 1000) / 1000 + " " + auth.myStatus.restaurant_base_currency.name + ")" : ""),
              true
            ]);
          modal.find("#category_additions_" + addition.additionType).trigger("tokenize:tokens:reorder");
          const it = modal.find("#category_additions_" + addition.additionType).parent().find("span#" + "aa_" + ind + " , " + "span#" + "al_" + ind + " , " + "span#" + "at_" + ind);
          tooltip(it);
        });
      } else {
        menuitemutil.getMenuCategory2(addition.additionId, menuCategory => {
          modal.find("#category_additions_" + addition.additionType).trigger("tokenize:tokens:add", [
            "menuCategory;" + addition.id + ";" + menuCategory.id + ";;" + addition.type.split("_")[0],
            menuitemutil.getPath(menuCategory) + menuitemutil.getProportionalOption("aaa_" + ind, addition.proportional),
            true,
            "category",
            true]
          );
          modal.find("#category_additions_" + addition.additionType).trigger("tokenize:tokens:reorder");
          const it = modal.find("#category_additions_" + addition.additionType).parent().find("span#" + "aaa_" + ind);
          tooltip(it);
          if (addition.optionType) {
            switch (addition.optionType) {
              case "separate":
                modal.find('input[id="check2' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"]').prop("checked", true);
                modal.find('input[id="check2' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"] ~ span > input#min').val(addition.min);
                modal.find('input[id="check2' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"] ~ span > input#max').val(addition.max);
                break;
              case "maxone":
                modal.find('input[id="radio' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"]').prop("checked", true);
                break;
              case "one":
                modal.find('input[id="radio2' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"]').prop("checked", true);
                break;
              default:
                modal.find('input[id="check' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"]').prop("checked", true);
                break;
            }
          }
        });
      }

    });

    menuItemReferences(data).forEach(({ menuItem, addition }, ind) => {
      const key = (menuItem.entityType.indexOf("Category") === -1 ? "menuItem" : "menuCategory") + ";" + addition.id + ";" + menuItem.id + ";" + (addition.additionType == "mandatory" ? "" : addition.quantity) + ";" + addition.type.split("_")[0];
      if (menuItem) {
        modal
          .find("#category_references")
          .trigger("tokenize:tokens:add", [
            key,
            menuitemutil.getPath(menuItem),
            true,
            "category"
          ]);
      }
      const it = modal.find("#category_references").next().find("*[data-value = '" + key + "']");
      it.find('input').remove();
      it.find('.dismiss').remove();
    });
    modal.find("#category_references").next().find(".token-search input").remove();

    if (data.labels)
      data.labels.forEach(function (label) {
        modal.find("#category_labels").trigger("tokenize:tokens:add", ["label;" + label.id + ";", label.name]);
      });
    if (data.isActive == true) modal.find("#mc_active").prop("checked", "true");
    else modal.find("#mc_active").prop("checked", false);
    if (data.isTop == true) modal.find("#mc_isTop").prop("checked", "true");
    else modal.find("#mc_isTop").prop("checked", false);
    if (data.freeQuantity == true) modal.find("#mc_freequantity").prop("checked", false);
    else modal.find("#mc_freequantity").prop("checked", true);
    if (data.online == true) modal.find("#mc_online").prop("checked", "true");
    else modal.find("#mc_online").prop("checked", false);
    if (data.local == true) modal.find("#mc_local").prop("checked", "true");
    else modal.find("#mc_local").prop("checked", false);
    if (data.takeaway == true) modal.find("#mc_takeaway").prop("checked", "true");
    else modal.find("#mc_takeaway").prop("checked", false);
    if (data.available == true) modal.find("#mc_available").prop("checked", "true");
    else modal.find("#mc_available").prop("checked", false);
    if (data.inventoryItem != undefined) {
      if (data.inventoryItem == true) modal.find("#mc_isInventoryItem").prop("checked", true);
      else modal.find("#mc_isInventoryItem").prop("checked", false);
      modal.find("#mc_isInventoryItem").prop("indeterminate", false);
    } else {
      modal.find("#mc_isInventoryItem").prop("indeterminate", true);
      modal.find("#mc_isInventoryItem").prop("checked", false);
    }
    if (data.preorder != undefined) {
      if (data.preorder == true) modal.find("#mc_preorder").prop("checked", true);
      else modal.find("#mc_preorder").prop("checked", false);
      modal.find("#mc_preorder").prop("indeterminate", false);
    } else {
      modal.find("#mc_preorder").prop("indeterminate", true);
      modal.find("#mc_preorder").prop("checked", false);
    }
    if (data.toDate) {
      var formattedDate = moment(data.toDate).format('YYYY-MM-DD');
      modal.find('input#to_date').val(formattedDate);
    }
    if (data.defaultToDate) {
      formattedDate = moment(data.defaultToDate).format('YYYY-MM-DD');
      modal.find('input#to_date').attr("placeholder", formattedDate);
    }
    if (data.fromDate) {
      var formattedDate = moment(data.fromDate).format('YYYY-MM-DD');
      modal.find('input#from_date').val(formattedDate);
    }
    if (data.defaultFromDate) {
      formattedDate = moment(data.defaultFromDate).format('YYYY-MM-DD');
      modal.find('input#from_date').attr("placeholder", formattedDate);
    }

  } else {
    modal.find("#smallImage").attr("src", "");
    $(modal.find("#smallImageDiv")).addClass("hidden");

    auth.myStatus.restaurant_languages.forEach(function (lang) {
      modal.find("#cname_" + lang).val("");
      modal.find("#cdescription_" + lang).val("");
    });
    modal.find("#cname_all").val("");
    modal.find("#cdescription_all").val("");
    modal.find("#production_line").val("");
    modal.find("#cvat_category").val("");
    modal.find("#ctakeaway_vat_category").val("");
    modal.find("#cid").val("");
    modal.find("#calias").val("");
    modal.find("#mc_active").prop("checked", "true");
    modal.find("#mc_isTop").prop("checked", menuCategoryId ? false : true);
    modal.find("#mc_online").prop("checked", "true");
    modal.find("#mc_available").prop("checked", "true");
    modal.find("#mc_isInventoryItem").prop("checked", null);
    modal.find("#mc_isInventoryItem").prop("indeterminate", true);
    modal.find("#mc_preorder").prop("checked", null);
    modal.find("#mc_preorder").prop("indeterminate", true);
    modal.find("#mc_local").prop("checked", true);
    modal.find("#mc_takeaway").prop("checked", true);

    new Cleave(modal.find("#cpricePerCostRatio"), {}).onInput("");
    modal.find("#cpricePerCostRatio").attr("placeholder", "");
    if (menuCategoryId) {
      var menuCategory = data ? getMenuCategory(data) : admin.getMenuCategory(menuCategoryId);
      if (menuCategory.defaultProductionLineIds)
        menuCategory.defaultProductionLineIds.split(",").forEach(function (pl) {
          modal.find("div#production_lines input#cpl_" + pl).parent().attr("default", "true");
        });
      modal.find("#cpricePerCostRatio").attr("placeholder", menuCategory.defaultPricePerCostRatio * 100);
      modal.find("#ctiming").val("inherit");

      if (menuCategory.defaultToDate) {
        formattedDate = moment(menuCategory.defaultToDate).format('YYYY-MM-DD');
        modal.find('input#to_date').attr("placeholder", formattedDate);
      }
      if (menuCategory.defaultFromDate) {
        formattedDate = moment(menuCategory.defaultFromDate).format('YYYY-MM-DD');
        modal.find('input#from_date').attr("placeholder", formattedDate);
      }

    } else {
      modal.find("#ctiming").val(auth.myStatus.restaurant_timings[0]);
    }
  }

  $("#ctiming option[value='inherit']").prop("disabled", !menuCategoryId);
  $("#cvat_category option[value='']").prop("disabled", !menuCategoryId);

  menuitemutil.oldMenuCategoryData = JSON.stringify(saveMenuCategory(undefined, true));



};

export const populateEditMenuItem = (modal, recipient, data, menuCategoryId) => {
  if (!recipient)
    return;
  modal.find('#save').prop('disabled', false);
  modal.find('#saveAndNew').prop('disabled', false);
  modal.find('#saveAndOpen').prop('disabled', false);

  menuitemutil.data = data;
  menuitemutil.references = menuitemutil.getRecursiveReferencers(data, [data]);

  if (typeof menuitemutil.entityType == "undefined" && data != null) {
    menuitemutil.entityType = data.entityType;
    if (data.entityType == "Drink") {
      menuitemutil.entitytype = "drink";
      //utils = drinkutils;
    } else {
      menuitemutil.entitytype = "meal";
      //utils = mealutils;
    }
  }

  modal.find(".highlight-3").removeClass("highlight-3");

  //menuitemEdited = data;

  modalOpen = modal;
  $("body").unbind("paste");
  $("body").on("paste", paste);

  $("#crop").hide();
  if (data && data.color) {
    modal.find("#color").find("input#color").val(data.color);
    modal.find("#color").find("input#colorCheckbox").prop("checked", true);
  } else {
    modal.find("#color").find("input#color").val("#FFFFFF");
    modal.find("#color").find("input#colorCheckbox").prop("checked", false);
  }

  modal.find("#smallImage").attr("src", "");
  modal.find("#smallImage").data("bigImage", null)

  modal.find("#smallImageWolt").attr("src", "");
  modal.find("#smallImageFoodpanda").attr("src", "");
  modal.find("#smallImageFalatozz").attr("src", "");
  modal.find("#smallImageWoltDiv i").addClass("hidden");
  modal.find("#smallImageFoodpandaDiv i").addClass("hidden");

  modal.find("#input#color").find("input").change(() => { });

  admin.initializeSelect2($('select.select2'));

  clearTokens(modal.find("#item_barcodes").tokenize2());
  clearTokens(modal.find("#item_additions_mandatory").tokenize2());
  clearTokens(modal.find("#item_additions_included").tokenize2());
  clearTokens(modal.find("#item_additions_optional").tokenize2());
  clearTokens(modal.find("#inherited_item_additions_mandatory").tokenize2());
  clearTokens(modal.find("#inherited_item_additions_included").tokenize2());
  clearTokens(modal.find("#inherited_item_additions_optional").tokenize2());
  clearTokens(modal.find("#item_labels").tokenize2());
  clearTokens(modal.find("#references").tokenize2());
  if (data) {
    if (data.inventoryItem === true || (data.inventoryItem !== false && data.defaultInventoryItem === true)) {
      modal.addClass("inventory");
      $("td .inventoryItem.checkbox").parent().parent().removeClass("hidden");
      $("th.mi_inventoryItem").removeClass('hidden');
      $("input#unit_price").parent().addClass("hidden");
      $("input#unit_cost").parent().addClass("hidden");
      $("input#pricePerCostRatio").parent().removeClass("hidden");
      //$(".input-group-mandatory").addClass("hidden");
      //$(".input-group-included").addClass("hidden");
      //clearTokens(modal.find("#item_additions_mandatory").tokenize2());
      //clearTokens(modal.find("#item_additions_included").tokenize2());
    } else {
      modal.removeClass("inventory");
      $("td .inventoryItem.checkbox").parent().parent().addClass("hidden");
      $("th.mi_inventoryItem").addClass('hidden');
      $("input#unit_price").parent().removeClass("hidden");
      $("input#unit_cost").parent().addClass("hidden");
      $("input#pricePerCostRatio").parent().addClass("hidden");
      $(".input-group-mandatory").removeClass("hidden");
      //$(".input-group-included").removeClass("hidden");
    }
  } else {
    const parentCategory = menuitemutil.getMenuCategory(menuCategoryId);
    const parentCategoryInventoryItem = parentCategory.defaultInventoryItem;
    if (parentCategoryInventoryItem) {
      modal.addClass("inventory");
      $("td .inventoryItem.checkbox").parent().parent().removeClass("hidden");
      $("th.mi_inventoryItem").removeClass('hidden');
      $("input#unit_price").parent().addClass("hidden");
      $("input#unit_cost").parent().addClass("hidden");
      $("input#pricePerCostRatio").parent().removeClass("hidden");
      //$(".input-group-mandatory").addClass("hidden");
      //$(".input-group-included").addClass("hidden");
      //clearTokens(modal.find("#item_additions_mandatory").tokenize2());
      //clearTokens(modal.find("#item_additions_included").tokenize2());
    } else {
      modal.removeClass("inventory");
      $("td .inventoryItem.checkbox").parent().parent().addClass("hidden");
      $("th.mi_inventoryItem").addClass('hidden');
      $("input#unit_price").parent().removeClass("hidden");
      $("input#unit_cost").parent().addClass("hidden");
      $("input#pricePerCostRatio").parent().addClass("hidden");
      $(".input-group-mandatory").removeClass("hidden");
      //$(".input-group-included").removeClass("hidden");
    }
  }
  modal.find("textarea").css("height", "");
  modal.find("div#production_lines input").prop("checked", false);
  modal.find("div#production_lines input").parent().removeClass("active");
  modal.find("div#production_lines input").parent().attr("default", false);
  modal.find("#action").val("");

  modal.find("#crop").hide();
  modal.find("#cost_estimation").val(0);
  modal.find("#unit_price_estimation").val(0);
  modal.find("#menuItem_minimumPrice").prop("checked", false);
  modal.find("#save").prop("disabled", false);
  modal.find("#saveAndOpen").prop("disabled", false);
  modal.find("#saveAndNew").prop("disabled", false);
  modal.find("#vat_category").val("");
  modal.find("#takeaway_vat_category").val("");



  modal.find("#parentCategoryId").val(menuCategoryId);
  var menuCategory = getMenuCategory(menuCategoryId);
  if (menuCategory) {
    modal.find("input#parent_category").val(menuitemutil.getPath(menuCategory));
    modal.find("#timing option[value='inherit']").html(admin_local.inherit + (menuCategory.defaultTiming && menuCategory.defaultTiming !== "inherit" ? "(" + getLocale2(auth.myStatus.restaurant_settings.timings[menuCategory.defaultTiming].langs).name + ")" : ""));
    if (menuCategory.defaultVATCategory)
      modal.find("#vat_category option[value='']").html(admin_local.inherit + "(" + modal.find("#vat_category option[value=" + menuCategory.defaultVATCategory.id + "]").html() + ")");
    else
      modal.find("#vat_category option[value='']").html(admin_local.inherit);
    if (menuCategory.defaultTakeawayVATCategory)
      modal.find("#takeaway_vat_category option[value='']").html(admin_local.inherit + "(" + modal.find("#takeaway_vat_category option[value=" + menuCategory.defaultTakeawayVATCategory.id + "]").html() + ")");
    else
      modal.find("#takeaway_vat_category option[value='']").html(admin_local.inherit);
  } else {
    modal.find("#timing option[value='inherit']").html(admin_local.inherit);
    modal.find("#vat_category option[value='']").html(admin_local.inherit);
    modal.find("#takeaway_vat_category option[value='']").html(admin_local.inherit);
  }
  const barcodes = modal.find("div.barcodes");
  barcodes.empty();
  menuitemutil.oldMenuItemData = "{menuItemAdditions:[]}";

  modal.find('input.datepicker').val("");
  modal.find('input.datepicker').prop("placeholder", "");

  modal.find("#mntak_main_category").val("");
  menuitemutil.updateNtakCategories("m", modal, data);
  modal.find("#mntak_sub_category").val("");


  if (recipient == "edit") {

    modal.find("#id").val(data.id);
    modal.find("#alias").val(data.globalId);
    modal.find("#short_name").val(data.shortName);
    modal.find("#manufacturer").val(data.manufacturer);
    auth.myStatus.restaurant_languages.forEach(function (lang) {
      modal.find("#name_" + lang).val(data.name[lang]);
      modal.find("#description_" + lang).val(data.description[lang]);
    });
    if (data.image && data.image != "") {
      if (data.bigImage && data.bigImage != "") modal.find("#smallImage").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + data.bigImage + "?timestamp=" + data.bigImageTimestamp);
      else modal.find("#smallImage").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + data.image + "?timestamp=" + data.imageTimestamp);
      $(modal.find("#smallImageDiv")).removeClass("hidden");
    } else {
      modal.find("#smallImage").attr("src", "");
      $(modal.find("#smallImageDiv")).addClass("hidden");
    }

    if (data.woltImage && data.woltImage != "") {
      modal.find("#smallImageWolt").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + data.woltImage);
      $(modal.find("#smallImageWoltDiv i")).removeClass("hidden");
    }

    if (data.foodpandaImage && data.foodpandaImage != "") {
      modal.find("#smallImageFoodpanda").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + data.foodpandaImage);
      $(modal.find("#smallImageFoodpandaDiv i")).removeClass("hidden");
    }
    if (data.falatozzImage && data.falatozzImage != "") {
      modal.find("#smallImageFalatozz").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + data.falatozzImage);
      $(modal.find("#smallImageFalatozzDiv i")).removeClass("hidden");
    }


    modal.find("#name_all").val(data.name["all"]);
    modal.find("#description_all").val(data.description["all"]);
    if (data.isActive == true) modal.find("#mi_active").prop("checked", "true");
    else modal.find("#mi_active").prop("checked", false);
    if (data.isTop == true) modal.find("#mi_isTop").prop("checked", "true");
    else modal.find("#mi_isTop").prop("checked", false);
    if (data.freeQuantity == true) modal.find("#mi_freequantity").prop("checked", false);
    else modal.find("#mi_freequantity").prop("checked", true);
    if (data.online == true) modal.find("#mi_online").prop("checked", "true");
    else modal.find("#mi_online").prop("checked", false);
    if (data.available == true) modal.find("#mi_available").prop("checked", "true");
    else modal.find("#mi_available").prop("checked", false);
    if (data.inventoryItem !== undefined && data.inventoryItem !== null) {
      modal.find("#mi_isInventoryItem").prop("checked", data.inventoryItem);
      modal.find("#mi_isInventoryItem").prop("indeterminate", false);
    } else {
      modal.find("#mi_isInventoryItem").prop("indeterminate", true);
      modal.find("#mi_isInventoryItem").prop("checked", false);
    }
    modal.find("#mi_isDefaultInventoryItem").prop("checked", data.defaultInventoryItem);
    modal.find("#quantityType").val(data.quantityType);
    modal.find("#quantityType").data('oldValue', data.quantityType);
    modal.find('span.q_quantity_type').html(I18n.t('local.' + data.quantityType));
    modal.find('input#mandatory_volume').val(data.mandatory_volume ? data.mandatory_volume : 1);

    modal.find("#timing").val(data.timing);
    data.productionLines.forEach(function (pl) {
      modal.find("div#production_lines input#mpl_" + pl.id).prop("checked", "true");
      modal.find("div#production_lines input#mpl_" + pl.id).parent().addClass("active");
    });
    if (data.defaultProductionLineIds)
      data.defaultProductionLineIds.split(",").forEach(function (pl) {
        modal.find("div#production_lines input#mpl_" + pl).parent().attr("default", "true");
      });
    modal.find("#vat_category").val(data.vatCategory ? data.vatCategory.id : "");
    modal.find("#takeaway_vat_category").val(data.takeawayVatCategory ? data.takeawayVatCategory.id : "");
    modal.find("#unit_cost").val(data.cost);
    modal.find("#cost_estimation").val(Math.round((data.costEstimation) * 1000) / 1000);
    modal.find("#unit_price").val(Math.round(data.unitPrice * 1000) / 1000);
    modal.find("#menuItem_minimumPrice").prop("checked", data.minimumPrice);
    modal.find("#unit_price_estimation").val(Math.round(data.unitPriceEstimation * 1000) / 1000);

    modal.find("#mntak_main_category").val(data.mainCategoryId);
    menuitemutil.updateNtakCategories("m", modal, data);
    modal.find("#mntak_sub_category").val(data.subCategoryId);


    if (data.barcodes)
      data.barcodes.forEach(barcode => {
        //$('#barcodeTemplate').tmpl(barcode).appendTo(barcodes);

        modal
          .find("#item_barcodes")
          .trigger("tokenize:tokens:add", [
            barcode.id,
            barcode.name + " " + barcode.quantity + "cl (" + barcode.id + ")"
          ]);


      });


    processMenuItemQuantities(data.availableQuantities, data);
    $("#saveAndNew").addClass("hidden");

    function populateAdditions(item, prefix) {

      const additions = [...item.menuItemAdditions, ...item.menuCategoryAdditions];
      additions.sort((a, b) => a.order - b.order);

      additions.forEach((addition, ind) => {
        if (addition.type === "meal_addition" || addition.type === "drink_addition") {
          menuitemutil.getMenuItem2(addition.additionId, menuItem => {
            const quantity = Math.round(1000 * parseFloat(addition.quantity) * (addition.additionType === "mandatory" && data.mandatory_volume ? data.mandatory_volume : 1)) / 1000;
            modal
              .find("#" + prefix + "item_additions_" + addition.additionType)
              .trigger("tokenize:tokens:add", [
                "menuItem;" + addition.id + ";" + menuItem.id + ";" + (addition.additionType == "mandatory" ? "" : (quantity)) + ";" + addition.type.split("_")[0],
                menuitemutil.getPath(menuItem) +
                menuitemutil.getLocalOption("al_" + ind, addition.local) +
                menuitemutil.getTakeawayOption("at_" + ind, addition.takeaway) +
                menuitemutil.getProportionalOption("aa_" + ind, addition.proportional) +
                "\n" +
                menuitemutil.getQuantityLabel2(addition.additionType, quantity, menuItem.quantityType, getLocale(getNameForQuantity(menuItem, quantity))) +
                (menuItem.costEstimation * quantity > 0 ? "&nbsp;(" + Math.round(menuItem.costEstimation * quantity * 1000) / 1000 + " " + auth.myStatus.restaurant_base_currency.name + ")" : ""),
                true
              ]);

            modal
              .find("#" + prefix + "item_additions_" + addition.additionType)
              .trigger("tokenize:tokens:reorder");

            const it = modal.find("#" + prefix + "item_additions_" + addition.additionType).next().find("span#" + "aa_" + ind + " , " + "span#" + "al_" + ind + " , " + "span#" + "at_" + ind);
            if (prefix)
              it.find('input.proportional').parent().remove();
            tooltip(it);
            if (prefix)
              modal.find("#" + prefix + "item_additions_" + addition.additionType).next().find('.token-search input').remove();
          });
        } else {
          try {
            menuitemutil.getMenuCategory2(addition.additionId, menuCategory => {
              modal.find("#" + prefix + "item_additions_" + addition.additionType).trigger("tokenize:tokens:add", [
                "menuCategory;" + addition.id + ";" + menuCategory.id + ";;" + addition.type.split("_")[0],
                menuitemutil.getPath(menuCategory) + (!prefix ? menuitemutil.getProportionalOption("aaa_" + ind) : ""),
                true,
                "category",
                true
              ]);
              modal.find("#" + prefix + "item_additions_" + addition.additionType).trigger("tokenize:tokens:reorder");
              const select = $(modal.find("#" + prefix + "item_additions_" + addition.additionType).next().get(0));
              const it = select.find("span#" + "aaa_" + ind);
              if (prefix) {
                select.find('.dismiss').remove();
                select.find('input.proportional').parent().remove();
                select.find('.token-search input').remove();
              } else {
                it.find('input.proportional').prop('checked', addition.proportional);
              }
              tooltip(it);
              if (addition.optionType) {
                switch (addition.optionType) {
                  case "separate":
                    select.find('input[id="check2' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"]').prop("checked", true);
                    select.find('input[id="check2' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"] ~ span > input#min').val(addition.min);
                    select.find('input[id="check2' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"] ~ span > input#max').val(addition.max);
                    break;
                  case "maxone":
                    select.find('input[id="radio' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"]').prop("checked", true);
                    break;
                  case "one":
                    select.find('input[id="radio2' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"]').prop("checked", true);
                    break;
                  default:
                    select.find('input[id="check' + "menuCategory;" + addition.id + ";" + menuCategory.id + ';;' + addition.type.split("_")[0] + '"]').prop("checked", true);
                    break;
                }
              }
            });
          } catch (ex) {
            console.log(ex);
          }

        }
      });
      if (prefix && item.menuCategoryId) {
        populateAdditions(getMenuCategory(item), prefix);
      }

    }


    populateAdditions(data, "");

    populateAdditions(getMenuCategory(data), "inherited_");

    menuItemReferences(data).forEach(({ menuItem, addition }, ind) => {
      const key = (menuItem.entityType.indexOf("Category") === -1 ? "menuItem" : "menuCategory") + ";" + addition.id + ";" + menuItem.id + ";" + (addition.additionType == "mandatory" ? "" : addition.quantity) + ";" + addition.type.split("_")[0];
      if (menuItem != null) {
        modal
          .find("#references")
          .trigger("tokenize:tokens:add", [
            key,
            menuitemutil.getPath(menuItem) +
            "\n&nbsp;" +
            menuitemutil.getQuantityLabel2(addition.additionType, Math.round(1000 * parseFloat(addition.quantity)) / 1000, data.quantityType, getLocale(getNameForQuantity(data, addition.quantity)))
          ]);
      }
      const it = modal.find("#references").next().find("*[data-value = '" + key + "']");
      it.find('input').remove();
      it.find('.dismiss').remove();
    });
    modal.find("#references").next().find(".token-search input").remove();


    if (data.labels)
      data.labels.forEach(function (label) {
        modal.find("#item_labels").trigger("tokenize:tokens:add", ["label;" + label.id + ";", label.name]);
      });

    if (data.pricePerCostRatio) new Cleave(modal.find("#pricePerCostRatio"), { numeral: true }).onInput("" + data.pricePerCostRatio * 100);
    else new Cleave(modal.find("#pricePerCostRatio"), { numeral: true }).onInput("");
    modal.find("#pricePerCostRatio").attr("placeholder", "");
    if (menuCategoryId) {
      var menuCategory = data ? getMenuCategory(data) : admin.getMenuCategory(menuCategoryId);
      modal.find("#pricePerCostRatio").attr("placeholder", menuCategory.defaultPricePerCostRatio * 100);
    }
    if (data.quantityType == "none") {
      modal.find("#quantityType").val("none");
      modal.find("#quantityType").data('oldValue', "none");
      modal.find("#quantityType").prop("disabled", true);
      $(".menuItemSpecific").hide();
      $(".optionSpecific").show();
      modal.find('select#quantityType option[value="none"]').prop("disabled", null);
    } else {
      modal.find("#quantityType").val(data.quantityType);
      modal.find("#quantityType").data('oldValue', data.quantityType);
      modal.find("#quantityType").prop("disabled", null);
      $(".optionSpecific").hide();
      $(".menuItemSpecific").show();
      modal.find('select#quantityType option[value="none"]').prop("disabled", true);
    }
    if (data.preorder != undefined) {
      if (data.preorder == true) modal.find("#m_preorder").prop("checked", true);
      else modal.find("#m_preorder").prop("checked", false);
      modal.find("#m_preorder").prop("indeterminate", false);
    } else {
      modal.find("#m_preorder").prop("indeterminate", true);
      modal.find("#m_preorder").prop("checked", false);
    }
    if (data.toDate) {
      var formattedDate = moment(data.toDate).format('YYYY-MM-DD');
      modal.find('input#m_to_date').val(formattedDate);
    }
    if (data.defaultToDate) {
      formattedDate = moment(data.defaultToDate).format('YYYY-MM-DD');
      modal.find('input#m_to_date').attr("placeholder", formattedDate);
    }
    if (data.fromDate) {
      var formattedDate = moment(data.fromDate).format('YYYY-MM-DD');
      modal.find('input#m_from_date').val(formattedDate);
    }
    if (data.defaultFromDate) {
      formattedDate = moment(data.defaultFromDate).format('YYYY-MM-DD');
      modal.find('input#m_from_date').attr("placeholder", formattedDate);
    }

  } else {
    modal.find("#id").val("");
    auth.myStatus.restaurant_languages.forEach(function (lang) {
      modal.find("#name_" + lang).val("");
      modal.find("#description_" + lang).val("");
    });
    modal.find("#short_name").val("");
    modal.find("#manufacturer").val("");
    modal.find("#name_all").val("");
    modal.find("#description_all").val("");
    if (recipient == "new-option") {
      modal.find("#quantityType").val("none");
      modal.find("#quantityType").data('oldValue', "none");
      modal.find("#quantityType").prop("disabled", true);
    }
    modal.find("#unit_price").val("");
    modal.find("#unit_cost").val("");
    modal.find("#timing").val("inherit");
    modal.find("#production_line").val("");
    modal.find("#vat_category").val("");
    modal.find("#smallImage").attr("src", "");
    $(modal.find("#smallImageDiv")).addClass("hidden");
    auth.myStatus.restaurant_languages.forEach(function (lang) {
      modal.find("#name_" + lang).val("");
    });
    modal.find("#mi_active").prop("checked", "true");
    modal.find("#mi_isTop").prop("checked", "true");
    modal.find("#mi_freequantity").prop("checked", true);
    modal.find("#mi_online").prop("checked", "true");
    modal.find("#mi_available").prop("checked", "true");
    modal.find("#mi_isInventoryItem").prop("checked", null);
    modal.find("#mi_isInventoryItem").prop("indeterminate", true);
    modal.find("#quantityType").val("");
    modal.find("#quantityType").data('oldValue', "");
    modal.find("#unit_price").val(0);
    modal.find('span.q_quantity_type').html("")
    modal.find('input#mandatory_volume').val(1);

    var menuCategory = data ? getMenuCategory(data) : admin.getMenuCategory(menuCategoryId);

    if (menuCategory.defaultToDate) {
      formattedDate = moment(menuCategory.defaultToDate).format('YYYY-MM-DD');
      modal.find('input#m_to_date').attr("placeholder", formattedDate);
    }
    if (menuCategory.defaultFromDate) {
      formattedDate = moment(menuCategory.defaultFromDate).format('YYYY-MM-DD');
      modal.find('input#m_from_date').attr("placeholder", formattedDate);
    }

    if (menuCategory.defaultProductionLineIds)
      menuCategory.defaultProductionLineIds.split(",").forEach(function (pl) {
        modal.find("div#production_lines input#mpl_" + pl).parent().attr("default", "true");
      });
    modal.find("#pricePerCostRatio").attr("placeholder", menuCategory.defaultPricePerCostRatio * 100);
    if (recipient == "new-option") {
      modal.find("#mi_isTop").prop("checked", null);
      modal.find("#mi_online").prop("checked", null);
      modal.find("#mi_available").prop("checked", null);
      modal.find("#quantityType").val("none");
      modal.find("#quantityType").data('oldValue', "none");
      modal.find("#quantityType").prop("disabled", true);
      processMenuItemQuantities([], {});
      $(".menuItemSpecific").hide();
      $(".optionSpecific").show();
      modal.find('select#quantityType option[value="none"]').prop("disabled", null);
    } else {
      modal.find("#mi_isTop").prop("checked", "true");
      modal.find("#mi_online").prop("checked", "true");
      modal.find("#mi_available").prop("checked", "true");
      modal.find("#quantityType").prop("disabled", null);
      processMenuItemQuantities(
        [
          {
            title: "",
            folder: false,
            label: {},
            quantity: 1,
            price: undefined,
            def: true,
            inventoryItem: menuCategory.defaultInventoryItem
          }
        ],
        {}
      );
      $(".optionSpecific").hide();
      $(".menuItemSpecific").show();
      modal.find('select#quantityType option[value="none"]').prop("disabled", true);
    }
    new Cleave(modal.find("#pricePerCostRatio"), {}).onInput("");
    modal.find("#pricePerCostRatio").attr("placeholder", "");
  }
  $("#saveAndNew").removeClass("hidden");

  menuitemutil.oldMenuItemData = JSON.stringify(saveMenuItem(undefined, true, true));

  // Function to make tokens draggable
  function makeTokensDraggable(tokenizeElement) {
    tokenizeElement.find('.tokens-container .token').draggable({
      //helper: 'clone',
      revert: 'invalid',
      start: function (event, ui) {
        ui.helper.addClass('dragging');
      },
      stop: function (event, ui) {
        ui.helper.removeClass('dragging');
      }
    });
  }

  // Function to make containers droppable
  function makeContainersDroppable(tokenizeElement) {

    tokenizeElement.find('+').find(".tokens-container").droppable({
      accept: '.tokens-container .token',
      drop: function (event, ui) {

        var originalTokenizeElement = ui.helper.closest('.tokenize').prev('select');

        if (originalTokenizeElement.get(0) == tokenizeElement.get(0))
          return;

        var tokenValue = ui.helper.data('value');
        var tokenLabel = ui.helper.get(0).cloneNode(true);
        $(tokenLabel).css("left", "");
        $(tokenLabel).css("top", "");
        $(tokenLabel).css("width", "");
        $(tokenLabel).css("height", "");
        $(tokenLabel).removeClass("dragged");
        setTimeout(() => {
          // Remove token from the original container
          originalTokenizeElement.trigger('tokenize:tokens:remove', [tokenValue]);
          originalTokenizeElement.trigger("tokenize:tokens:reorder");
        }, 100);

        // Add token to the dropped container
        tokenizeElement.trigger('tokenize:tokens:add', [tokenValue, tokenLabel, true]);
        tokenizeElement.trigger("tokenize:tokens:reorder");

        makeTokensDraggable($("div#editMenuItem #item_additions_mandatory").parent());
        makeTokensDraggable($("div#editMenuItem #item_additions_included").parent());

      }
    });

  }
  makeTokensDraggable($("div#editMenuItem #item_additions_mandatory").parent());
  makeTokensDraggable($("div#editMenuItem #item_additions_included").parent());
  makeContainersDroppable($("div#editMenuItem #item_additions_mandatory"));
  makeContainersDroppable($("div#editMenuItem #item_additions_included"));

  // Add tokens dynamically
  $("div#editMenuItem #item_additions_mandatory").on('tokenize:tokens:add tokenize:tokens:remove', function () {
    makeTokensDraggable($(this).parent());
  });

  $("div#editMenuItem #item_additions_included").on('tokenize:tokens:add tokenize:tokens:remove', function () {
    makeTokensDraggable($(this).parent());
  });

};

menuitemutil.getProportionalOption = (id, value = false) => {
  return '<span class="proportional" id="' + id + '" data-toggle="tooltip" data-placement="bottom" data-original-title="' + I18n.t('admin_local.proportional_addition_tooltip') + '"><input type="checkbox" class="checkbox proportional" id="' + id + '" style="height: 1rem;align-self: center;" ' + (value ? 'checked' : '') + '></span>';
  //return '<div class="custom-control custom-checkbox" style="display: flex;justify-content: center;padding-left:2.5rem"><input type="checkbox" class="custom-control-input checkbox isActive" id="a_' + id + '"><label class="custom-control-label" for="a_' + id + '">aaa</label></div>';
}

menuitemutil.getMinMaxOption = () => {
  return '<span class="min" id="min" data-toggle="tooltip" data-placement="bottom" data-original-title="' + I18n.t('admin_local.min_addition_tooltip') + '"><input type="number" class="checkbox proportional" id="min" style="height: 1rem;align-self: center;"></span><span>-</span>' +
    '<span class="max" id="max" data-toggle="tooltip" data-placement="bottom" data-original-title="' + I18n.t('admin_local.max_addition_tooltip') + '"><input type="number" class="checkbox proportional" id="max" style="height: 1rem;align-self: center;"></span>';
  //return '<div class="custom-control custom-checkbox" style="display: flex;justify-content: center;padding-left:2.5rem"><input type="checkbox" class="custom-control-input checkbox isActive" id="a_' + id + '"><label class="custom-control-label" for="a_' + id + '">aaa</label></div>';
}

menuitemutil.getLocalOption = (id, value = false) => {
  return '<span class="local" id="' + id + '" data-toggle="tooltip" data-placement="bottom" data-original-title="' + I18n.t('admin_local.local_addition_tooltip') + '"><input type="checkbox" class="checkbox local" id="' + id + '" style="height: 1rem;align-self: center;" ' + (value ? 'checked' : '') + '></span>';
}
menuitemutil.getTakeawayOption = (id, value = false) => {
  return '<span class="takeaway" id="' + id + '" data-toggle="tooltip" data-placement="bottom" data-original-title="' + I18n.t('admin_local.takeaway_addition_tooltip') + '"><input type="checkbox" class="checkbox takeaway" id="' + id + '" style="height: 1rem;align-self: center;" ' + (value ? 'checked' : '') + '></span>';
}


menuitemutil.getQuantityLabel2 = function (additionType, quantity, quantityType, label) {
  var res;
  if (label !== undefined && label !== "")
    label = " (" + label + ")";
  else
    label = ""
      ; if (quantityType == "none") return "";

  if (!label) label = "";

  if (additionType == "mandatory" || additionType == "included" || additionType == "optional")
    res = '<input id="quantity" type="number" value="' + quantity + '"></input><span class="quantity">' + quantity + " </span><span>" + (quantityType == "none" ? "" : I18n.t("local." + quantityType)) + label + "</span>";
  else res = quantityType == "pcs" && quantity == 1 ? "" : quantity + (quantityType == "none" ? "" : " " + I18n.t("local." + quantityType)) + label;
  return res;
};

menuitemutil.getQuantityLabel3 = function (additionType, quantity, quantityType) {
  var res;
  if (quantityType == "none") return "";
  if (additionType == "mandatory" || additionType == "included") res = quantity + " " + (quantityType == "none" ? "" : I18n.t("local." + quantityType));
  else res = quantityType == "pcs" && quantity == 1 ? "" : quantity + (quantityType == "none" ? "" : " " + I18n.t("local." + quantityType));
  return res;
};

menuitemutil.parseNumber = function (number) {
  if (typeof number == "undefined") return "";
  if (typeof number == "number") return number;
  number = number.replace(" ", "");
  number = number.replace(",", ".");
  number = Number(number);
  return number;
};

menuitemutil.handleFilterDeletedChange = event => {
  const t = event.target;
  var checked = $(t).prop("checked");
  menuitemutil.filter.deleted = checked;
  menuitemutil.reload();
};
menuitemutil.handleFilterCheckBoxChange = event => {
  const t = event.target;
  const attr = $(t)
    .parents("th")
    .attr("class");
  var indeterminate = $(t).prop("indeterminate");
  var checked_old = $(t).prop("checked_old");
  var checked = $(t).prop("checked");
  if (checked && checked_old != undefined && (indeterminate == undefined || indeterminate == false)) {
    $(t).prop("indeterminate", true);
    $(t).prop("checked", false);
    $(t).removeProp("checked_old");
  } else {
    $(t).prop("checked_old", $(t).prop("checked"));
  }
  var indeterminate = $(t).prop("indeterminate");
  var checked = $(t).prop("checked");
  menuitemutil.filter[attr] = indeterminate ? undefined : checked;
  const allCheckBoxes = $("table thead tr th." + attr + " input[type = 'checkbox']");
  allCheckBoxes.prop("checked", checked);
  allCheckBoxes.prop("indeterminate", indeterminate);
  menuitemutil.filterNodes();
};

menuitemutil.filterNode = (node) => {
  if (!menuitemutil.entityType || (node.data.entityType && node.data.entityType.startsWith(menuitemutil.entityType))) {
    if (menuitemutil.filter.name) {
      if (
        removeAccents(node.data.manufacturer + " " + getLocale(node.data.name, localStorage.languageSelected))
          .toLowerCase()
          .indexOf(removeAccents(menuitemutil.filter.name).toLowerCase()) === -1
      )
        return false;
    }
    /*
    if (menuitemutil.filter.manufacturer && node.data.manufacturer) {
      var c = removeAccents(node.data.manufacturer ? node.data.manufacturer : "").toLowerCase();
      if (c.indexOf(removeAccents(menuitemutil.filter.manufacturer).toLowerCase()) === -1)
        return false;
    }*/
    if (menuitemutil.filter.labels) {
      var ok = false;
      node.data.labels.forEach(l => {
        if (removeAccents(l.name).toLowerCase().indexOf(removeAccents(menuitemutil.filter.labels)).toLowerCase() !== -1)
          ok = true;
      });
      return ok;
    }
    if (menuitemutil.filter.active !== undefined) {
      if (menuitemutil.filter.active !== Boolean(node.data.isActive)) return null;
    }
    if (menuitemutil.filter.inventory !== undefined) {
      if (node.data.inventoryItem !== undefined) {
        if (menuitemutil.filter.inventory !== node.data.inventoryItem) return false;
      } else if (menuitemutil.filter.inventory !== node.data.defaultInventoryItem) return false;
    }
    if (menuitemutil.filter.top !== undefined) {
      if (menuitemutil.filter.top !== node.data.isTop) {
        return null;
      }
    }
    if (menuitemutil.filter.online !== undefined) {
      if (menuitemutil.filter.online !== node.data.online) return null;
    }
    if (menuitemutil.filter.availablequantity !== undefined) {
      const v = node.data.availableQuantities ? node.data.availableQuantities.length > 0 : false;
      if (!node.data.availableQuantities || menuitemutil.filter.availablequantity !== v) return false;
    }
    return true;
  }
  return false;
}

menuitemutil.filterNode2 = (node) => {
  const rest = menuitemutil.filterNode(node);
  if (rest === null)
    return false;
  if (rest)
    return true;
  if (node.parent && node.parent.data && node.parent.data.name)
    return menuitemutil.filterNode2(node.parent);
  return false;
}

menuitemutil.filterNodes = () => {
  menuitemutil.menuItems.filterNodes(function (node) {
    return menuitemutil.filterNode2(node);

  });
};

export const filterChanged = e => {
  menuitemutil.filter["name"] = e.target.value.toLowerCase();
  menuitemutil.filter["manufacturer"] = e.target.value.toLowerCase();
  menuitemutil.filterNodes();
};

var myChart;
var chartUrl;
var isCategory = false;
function populateCharts() {
  if ($("#chart-block").hasClass("hidden")) return;
  isCategory = false;
  var menuItem = menuitemutil.menuItems.getActiveNode();
  if (menuItem === null)
    return;
  chartUrl = "adminService/" + sessionStorage.restaurantSelected + "/getComponentStockHistory/" + 0 + "/" + menuItem.data.id + "?from=" + localStorage.menuitems_start + "&to=" + localStorage.menuitems_end;
  var chartUrl2 = chartUrl;
  get(chartUrl, false).done(function (data) {
    if (chartUrl == chartUrl2) drawChart(data);
  });
}

var menuItemIds = [];
function populateCategoryCharts() {
  if ($("#chart-block").hasClass("hidden")) return;
  isCategory = true;
  var category = menuitemutil.menuItems.getActiveNode();
  menuItemIds = [];
  function addChildren(category) {
    category.children.forEach(c => {
      if (c.data.availableQuantities)
        menuItemIds.push(c.data.id);
      if (c.children)
        addChildren(c);
    });
  }
  if (category.children)
    addChildren(category);
  chartUrl = "adminService/" + sessionStorage.restaurantSelected + "/getMenuItemsHistory/0?from=" + localStorage.menuitems_start + "&to=" + localStorage.menuitems_end + "&timestamp=" + new Date().getTime();
  var chartUrl2 = chartUrl;
  post(chartUrl, menuItemIds, undefined, false).done(function (data) {
    if (chartUrl == chartUrl2) drawChart(data);
  });
}

function trimDate(date, range) {
  date = new Date(date);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (range == "week") {
    date = getMonday(date);
    return date;
  } else if (range == "month") {
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    return date;
  }
  return date;
}

var oldData;

function drawChart(data) {
  if ($("#chart-block").hasClass("hidden")) return;
  var range = $("[name='range']:checked").attr("id");
  ajaxCallStart("processing");
  setTimeout(() => {
    try {
      if (typeof data == "undefined") data = oldData;
      else oldData = data;
      if (!menuitemutil.menuItems.getActiveNode())
        return;
      var menuItem = menuitemutil.menuItems.getActiveNode().data;
      var $tdList = $(menuitemutil.menuItems.getActiveNode().tr).find(">td");
      var currentQuantityType = [];
      //$tdList.eq(3).html();
      //if (currentQuantityType.split(" ")[0] == 1) currentQuantityType = currentQuantityType.split(" ")[1];

      var focus = localStorage["menuitems_dataset_focus"] == "true";

      var ctx = document.getElementById("myChart").getContext("2d");
      var historyData = [];
      var datasets = {};
      var stores = [];
      data.CostAndPriceHistory.forEach(h => {
        h.trimmed_date = trimDate(h.date, range);
        if (stores.indexOf(h.store.id) == -1) stores.push(h.store.id);
      });

      var consumptionsPerOrder = {};
      data.ConsumptionStockEntry.forEach(c => {
        c.trimmed_date = trimDate(c.date, range);
        var cs = consumptionsPerOrder[c.order.id];
        if (cs == undefined) consumptionsPerOrder[c.order.id] = [c];
        else cs.push(c);
      });

      var label = I18n.t("admin_local.stock");
      datasets["k"] = { yAxisID: "y-axis-1", steppedLine: "middle", type: "line", label: label, data: [], fill: false, backgroundColor: colors[0] };

      data.Orders.forEach(h => {
        h.trimmed_created = trimDate(h.created, range);
      });

      //currentQuantityType = [];
      data.Orders.forEach(h => {
        var menuItem = getMenuItem(h.menuItemId, true);
        if (currentQuantityType.indexOf(I18n.t("local." + menuItem.quantityType)) == -1) currentQuantityType.push(I18n.t("local." + menuItem.quantityType));
        var key = "f";
        if (datasets[key]) {
        } else {
          var label = I18n.t("admin_local.consumption");
          datasets[key] = { yAxisID: "y-axis-1", pointRadius: 3, lineTension: 0.1, borderWidth: 3, pointStyle: "rect", type: "bar", label: label, data: [], fill: false, backgroundColor: colors[1] };
        }
        var date = h.trimmed_created;
        if (datasets[key].data.length > 0) {
          var dd = datasets[key].data[datasets[key].data.length - 1];
          if (dd.x.getTime() == date.getTime()) {
            dd.y += h.quantity;
          } else {
            var hh = Object.assign({}, h);
            hh.date = date;
            datasets[key].data.push({ x: date, y: h.quantity, data: hh, currentQuantityType: I18n.t("local." + menuItem.quantityType) });
          }
        } else {
          var hh = Object.assign({}, h);
          datasets[key].data.push({ x: date, y: h.quantity, data: hh, currentQuantityType: I18n.t("local." + menuItem.quantityType) });
        }
      });

      if (!isCategory)
        data.Orders.forEach(h => {
          var key = "p";
          if (datasets[key]) {
          } else {
            var label = I18n.t("admin_local.price");
            datasets[key] = { yAxisID: "y-axis-2", pointRadius: 5, lineTension: 0.1, pointStyle: "rect", type: "line", label: label, data: [], fill: true, borderColor: colors[2] };
          }
          var date = h.trimmed_created;
          var fullPrice = h.fullPrice - h.discount - h.tableMateDiscount;
          if (datasets[key].data.length > 0) {
            var dd = datasets[key].data[datasets[key].data.length - 1];
            if (dd.x.getTime() == date.getTime()) {
              dd.totalPrice += fullPrice;
              dd.totalCount += h.quantity;
              dd.y = Math.round((1000 * dd.totalPrice) / dd.totalCount) / 1000;
            } else {
              var hh = Object.assign({}, h);
              hh.date = date;
              datasets[key].data.push({ x: date, y: Math.round((1000 * fullPrice) / h.quantity) / 1000, totalPrice: fullPrice, totalCount: h.quantity, data: hh });
            }
          } else {
            var hh = Object.assign({}, h);
            datasets[key].data.push({ x: date, y: Math.round((1000 * fullPrice) / h.quantity) / 1000, totalPrice: fullPrice, totalCount: h.quantity, data: hh });
          }
        });

      if (!isCategory)
        data.Orders.forEach(h => {
          var key = "r";

          if (datasets[key]) {
          } else {
            var label = I18n.t("admin_local.price_goal");
            datasets[key] = { yAxisID: "y-axis-2", borderDash: [10, 5], borderWidth: 1, pointRadius: 0, lineTension: 0.1, pointStyle: "rectRot", type: "line", label: label, data: [], fill: true, borderColor: colors[3] };
          }
          var cost = 0;
          var quantity = 0;
          var cs = consumptionsPerOrder[h.id];
          if (cs != undefined)
            cs.forEach(c => {
              cost += c.cost;
            });
          var date = h.trimmed_created;
          if (datasets[key].data.length > 0) {
            var dd = datasets[key].data[datasets[key].data.length - 1];
            if (dd.x.getTime() == date.getTime()) {
              dd.totalCost += cost * menuItem.defaultPricePerCostRatio;
              dd.totalCount += h.quantity;
              dd.y = Math.round((1000 * dd.totalCost) / dd.totalCount) / 1000;
            } else {
              var hh = Object.assign({}, h);
              hh.date = date;
              datasets[key].data.push({ x: date, y: cost * menuItem.defaultPricePerCostRatio, totalCost: cost * menuItem.defaultPricePerCostRatio, totalCount: h.quantity, data: hh });
            }
          } else {
            var hh = Object.assign({}, h);
            datasets[key].data.push({ x: date, y: cost * menuItem.defaultPricePerCostRatio, totalCost: cost * menuItem.defaultPricePerCostRatio, totalCount: h.quantity, data: hh });
          }
        });

      if (!isCategory)
        data.Orders.forEach(h => {
          var key = "c";
          if (datasets[key]) {
          } else {
            var label = I18n.t("admin_local.cost");
            datasets[key] = { yAxisID: "y-axis-2", pointRadius: 5, borderWidth: 2, lineTension: 0.1, pointStyle: "rectRot", type: "line", label: label, data: [], fill: true, borderColor: colors[4] };
          }
          var cost = 0;
          var quantity = 0;
          var cs = consumptionsPerOrder[h.id];
          if (cs != undefined)
            cs.forEach(c => {
              cost += c.cost;
            });
          var date = h.trimmed_created;
          if (datasets[key].data.length > 0) {
            var dd = datasets[key].data[datasets[key].data.length - 1];
            if (dd.x.getTime() == date.getTime()) {
              dd.totalCost += cost;
              dd.totalCount += h.quantity;
              dd.y = Math.round((1000 * dd.totalCost) / dd.totalCount) / 1000;
            } else {
              var hh = Object.assign({}, h);
              hh.date = date;
              datasets[key].data.push({ x: date, y: cost, totalCost: cost, totalCount: h.quantity, data: hh });
            }
          } else {
            var hh = Object.assign({}, h);
            datasets[key].data.push({ x: date, y: cost, totalCost: cost, totalCount: h.quantity, data: hh });
          }
        });

      //Bevétel - income
      data.Orders.forEach(h => {
        var key = "o";
        if (datasets[key]) {
        } else {
          var label = I18n.t("admin_local.income");
          datasets[key] = { yAxisID: "y-axis-2", pointRadius: 5, pointStyle: "rect", lineTension: 0.1, type: "line", label: label, data: [], fill: true, borderColor: colors[5] };
        }
        var fullPrice = h.fullPrice - h.discount - h.tableMateDiscount;
        var date = h.trimmed_created;
        if (datasets[key].data.length > 0) {
          var dd = datasets[key].data[datasets[key].data.length - 1];
          if (dd.x.getTime() == date.getTime()) {
            dd.y += fullPrice;
          } else {
            datasets[key].data.push({ x: date, y: fullPrice });
          }
        } else {
          datasets[key].data.push({ x: date, y: fullPrice });
        }
      });

      //Árrés - profit margin
      data.Orders.forEach(h => {
        var key = "m";
        if (datasets[key]) {
        } else {
          var label = I18n.t("admin_local.profit_margin");
          datasets[key] = { yAxisID: "y-axis-2", pointRadius: 5, pointStyle: "rect", lineTension: 0.1, type: "line", label: label, data: [], fill: true, borderColor: colors[6] };
        }
        var fullPrice = h.fullPrice - h.discount - h.tableMateDiscount;
        var cost = 0;
        var quantity = 0;
        var cs = consumptionsPerOrder[h.id];
        if (cs != undefined)
          cs.forEach(c => {
            cost += c.cost;
          });
        var date = h.trimmed_created;
        if (datasets[key].data.length > 0) {
          var dd = datasets[key].data[datasets[key].data.length - 1];
          if (dd.x.getTime() == date.getTime()) {
            dd.y += fullPrice - cost;
          } else {
            datasets[key].data.push({ x: date, y: fullPrice - cost });
          }
        } else {
          datasets[key].data.push({ x: date, y: fullPrice - cost });
        }
      });

      //Árrés - profit margin %
      data.Orders.forEach(h => {
        var key = "pr";
        if (datasets[key]) {
        } else {
          var label = I18n.t("admin_local.profit_margin") + " %";
          datasets[key] = { yAxisID: "y-axis-5", pointRadius: 5, borderWidth: 2, pointStyle: "rectRot", lineTension: 0.1, type: "line", label: label, data: [], fill: true, borderColor: colors[7] };
        }
        var fullPrice = h.fullPrice - h.discount - h.tableMateDiscount;
        var cost = 0;
        var quantity = 0;
        var cs = consumptionsPerOrder[h.id];
        if (cs != undefined)
          cs.forEach(c => {
            cost += c.cost;
          });
        var date = h.trimmed_created;
        if (datasets[key].data.length > 0) {
          var dd = datasets[key].data[datasets[key].data.length - 1];
          if (dd.x.getTime() == date.getTime()) {
            dd.totalPrice += fullPrice;
            dd.totalCost += cost;
          } else {
            datasets[key].data.push((dd = { x: date, totalPrice: fullPrice, totalCost: cost }));
          }
        } else {
          datasets[key].data.push((dd = { x: date, totalPrice: fullPrice, totalCost: cost }));
        }
        dd.y = dd.totalCost == 0 ? 100 : Math.round(((dd.totalPrice - dd.totalCost) / dd.totalCost) * 100);
      });

      if (!isCategory)
        data.IncomingStockEntry.forEach(h => {
          var key = "b-" + h.store.id;
          if (datasets[key]) {
          } else {
            var label = stores.length == 1 ? I18n.t("admin_local.incoming_stock") : getStore(h.store.id).name + " " + I18n.t("admin_local.incoming_stock").toLowerCase();
            datasets[key] = { yAxisID: "y-axis-1", type: "bar", label: label, data: [], fill: false, backgroundColor: getStore(h.store.id).color };
          }
          var date = trimDate(h.date, range);
          if (datasets[key].data.length > 0) {
            var dd = datasets[key].data[datasets[key].data.length - 1];
            if (dd.x.getTime() == date.getTime()) {
              var hh = Object.assign({}, h);
              //datasets[key].data.push({ x: date, y: h.count * h.quantity, data: hh });
              dd.y += h.count * h.quantity;
              dd.data.net_unit_price = (dd.data.count * dd.data.net_unit_price + h.count * h.net_unit_price) / (dd.data.count + h.count);
              dd.data.count += h.count * h.quantity;
            } else {
              var hh = Object.assign({}, h);
              hh.date = date;
              datasets[key].data.push({ x: date, y: h.count * h.quantity, data: hh });
            }
          } else {
            var hh = Object.assign({}, h);
            datasets[key].data.push({ x: date, y: h.count * h.quantity, data: hh });
          }
        });

      if (!isCategory)
        data.InventoryEntry.forEach(h => {
          var key = "i-" + h.store.id;
          if (datasets[key]) {
          } else {
            var label = stores.length == 1 ? I18n.t("admin_local.inventory") : getStore(h.store.id).name + " " + I18n.t("admin_local.inventory").toLowerCase();
            datasets[key] = {
              yAxisID: "y-axis-1",
              type: "bar",
              label: label,
              data: [],
              fill: false,
              backgroundColor: darken(getStore(h.store.id).color, 20)
            };
          }
          var date = trimDate(h.date, range);
          datasets[key].data.push({ x: date, y: h.count - h.originalCount, data: h });
        });

      var yaxes = [];
      var datas = [];
      var datasetsSelected = 0;
      var mainFocusPrefix = "";
      Object.keys(datasets).forEach((key, index) => {
        datasets[key].hidden = localStorage["menuitems_dataset_" + key] == "true";
        datas.push(datasets[key]);
        if (!(localStorage["menuitems_dataset_" + key] == "true")) {
          datasetsSelected++;
          mainFocusPrefix = key.split("-")[0];
        } else {
          if (focus) datasets[key].hidden = true;
        }
      });

      if (datasetsSelected != 1) {
        localStorage["menuitems_dataset_focus"] = false;
      }

      function getEntry(data, x) {
        var v = 0;
        var index = 0;
        data.forEach((d, ind) => {
          if (d.x.getTime() == x.getTime()) {
            v = d;
            index = ind;
          }
        });
        return { index: index, value: v };
      }
      var dates = new SortedSet({ comparator: function (a, b) { return a.getTime() - b.getTime(); } });
      data.CostAndPriceHistory.forEach(h => {
        if (SortedSet.find(dates, h.trimmed_date) === null) {
          dates.insert(h.trimmed_date);
        }
      });
      var dataExample = [];
      dates.forEach(d => {
        dataExample.push({ x: d, y: 0 });
      });

      var getKey = function (focusPrefix, menuItem, quantity) {
        if (menuItem.defaultInventoryItem) {
          return focusPrefix + "-" + menuItem.id + "-" + menuitemutil.getInventoryQuantity(menuItem, quantity);
        } else return focusPrefix + "-" + menuItem.id;
      };
      var getLabel = function (focusPrefix, menuItem, quantity) {
        //if (menuItem.defaultInventoryItem) return getLocale(menuItem.name) + /*" " + menuitemutil.getInventoryQuantity(menuItem, quantity) + " " + I18n.t("local." + menuItem.quantityType)*/;
        //else 
        return getLocale(menuItem.name);
      };

      var focusPrefix = "";
      var previousEntry = null;
      if (datasetsSelected == 1 && mainFocusPrefix == "k" && focus) {
        currentQuantityType = [];
        focusPrefix = "kk";
        datasets["k"].data = [];
        datasets["k"].yAxisID = "y-axis-6";
        data.CostAndPriceHistory.forEach(h => {
          var menuItem = getMenuItem(h.menuItem);
          if (currentQuantityType.indexOf(I18n.t("local." + menuItem.quantityType)) == -1) currentQuantityType.push(I18n.t("local." + menuItem.quantityType));
          h.count = Math.round(h.count * 1000) / 1000;
          var quantity = Math.abs(h.quantity);
          var key = getKey(focusPrefix, menuItem, quantity);
          var dataset = datasets[key];
          if (!dataset) {
            let datas = $.extend(true, [], dataExample);
            var label = getLabel(focusPrefix, menuItem, quantity);
            datasets[key] = dataset = {
              yAxisID: "y-axis-6",
              type: "line",
              lineTension: 0.1,
              showLine: true,
              pointBorderColor: colors[Object.keys(datasets).length],
              pointStyle: "circle",
              pointBackgroundColor: colors[Object.keys(datasets).length],
              lineWidth: 0,
              pointBorderWidth: 0,
              borderWidth: 0,
              label: label,
              data: datas,
              fill: false,
              backgroundColor: colors[Object.keys(datasets).length],
              borderColor: colors[Object.keys(datasets).length]
            };
          }
          var date = h.trimmed_date;
          var entry = getEntry(dataset.data, date);
          var dd = entry.value;
          if (previousEntry !== entry && previousEntry !== null) {
            for (let index = previousEntry.index; index < entry.index; index++) {
              dataset.data[index].y = previousEntry.value.y;
            }
          }
          previousEntry = entry;
          dd.y = h.count * h.quantity;
          dd.currentQuantityType = I18n.t("local." + menuItem.quantityType);
          for (let index = entry.index + 1; index < dataset.data.length; index++) {
            dataset.data[index].y = previousEntry.value.y;
          }

        });
      } else if (datasetsSelected == 1 && mainFocusPrefix == "f" && focus) {
        currentQuantityType = [];
        datasets["f"].data = [];
        datasets["f"].yAxisID = "y-axis-6";
        focusPrefix = "ff";
        data.Orders.forEach(h => {
          var menuItem = getMenuItem(h.menuItemId, true);
          if (currentQuantityType.indexOf(I18n.t("local." + menuItem.quantityType)) == -1) currentQuantityType.push(I18n.t("local." + menuItem.quantityType));
          var quantity = Math.abs(h.quantity);
          var key = getKey(focusPrefix, menuItem, quantity);
          if (datasets[key]) {
          } else {
            var label = getLabel(focusPrefix, menuItem, quantity);
            datasets[key] = {
              yAxisID: "y-axis-6",
              pointRadius: 3,
              lineTension: 0.1,
              borderWidth: 3,
              pointStyle: "rect",
              type: "line",
              label: label,
              data: [],
              fill: false,
              backgroundColor: colors[Object.keys(datasets).length],
              borderColor: colors[Object.keys(datasets).length]
            };
          }
          var date = h.trimmed_created;
          if (datasets[key].data.length > 0) {
            var dd = datasets[key].data[datasets[key].data.length - 1];
            if (dd.x.getTime() == date.getTime()) {
              dd.y += h.quantity;
            } else {
              var hh = Object.assign({}, h);
              hh.date = date;
              datasets[key].data.push({ x: date, y: quantity, data: hh, currentQuantityType: I18n.t("local." + menuItem.quantityType) });
            }
          } else {
            var hh = Object.assign({}, h);
            datasets[key].data.push({ x: date, y: h.quantity, data: hh, currentQuantityType: I18n.t("local." + menuItem.quantityType) });
          }
        });
      } else if (datasetsSelected == 1 && mainFocusPrefix == "m" && focus) {
        currentQuantityType = [];
        datasets["mm"].data = [];
        datasets["mm"].yAxisID = "y-axis-2";
        focusPrefix = "mm";
        data.Orders.forEach(h => {
          var menuItem = getMenuItem(h.menuItemId, true);
          if (currentQuantityType.indexOf(I18n.t("local." + menuItem.quantityType)) == -1) currentQuantityType.push(I18n.t("local." + menuItem.quantityType));
          var quantity = Math.abs(h.quantity);
          var fullPrice = h.fullPrice - h.discount - h.tableMateDiscount;
          var key = getKey(focusPrefix, menuItem, quantity);
          if (datasets[key]) {
          } else {
            var label = getLabel(focusPrefix, menuItem, quantity);
            datasets[key] = {
              yAxisID: "y-axis-2",
              pointRadius: 3,
              lineTension: 0.1,
              borderWidth: 3,
              pointStyle: "rect",
              type: "line",
              label: label,
              data: [],
              fill: false,
              backgroundColor: colors[Object.keys(datasets).length],
              borderColor: colors[Object.keys(datasets).length]
            };
          }

          var fullPrice = h.fullPrice - h.discount - h.tableMateDiscount;
          var cost = 0;
          var quantity = 0;
          var cs = consumptionsPerOrder[h.id];
          if (cs != undefined)
            cs.forEach(c => {
              cost += c.cost;
            });
          var date = h.trimmed_created;
          if (datasets[key].data.length > 0) {
            var dd = datasets[key].data[datasets[key].data.length - 1];
            if (dd.x.getTime() == date.getTime()) {
              dd.y += fullPrice - cost;
            } else {
              datasets[key].data.push({ x: date, y: fullPrice - cost });
            }
          } else {
            datasets[key].data.push({ x: date, y: fullPrice - cost });
          }

          /*
      	
      	
        var date = h.trimmed_created;
        if (datasets[key].data.length>0) {
          var dd = datasets[key].data[datasets[key].data.length-1];
          if (dd.x.getTime()==date.getTime()) {
            dd.count += quantity;
            dd.totalPrice += fullPrice*quantity;
            dd.y = Math.round(dd.totalPrice/dd.count);
          } else {
            var hh = Object.assign({}, h);
            hh.date=date;
            datasets[key].data.push({ x: date, y: fullPrice*quantity, totalPrice:fullPrice*quantity, count:quantity, currentQuantityType : local[menuItem.quantityType]} );
          }
        } else {
          var hh = Object.assign({}, h);
          datasets[key].data.push({ x: date, y: h.fullPrice*quantity, totalPrice:fullPrice*quantity, count:quantity, currentQuantityType : local[menuItem.quantityType]} );
        }
        */
        });
      } else if (datasetsSelected == 1 && mainFocusPrefix == "o" && focus) {
        currentQuantityType = [];
        datasets["o"].data = [];
        datasets["o"].yAxisID = "y-axis-2";
        focusPrefix = "oo";
        data.Orders.forEach(h => {
          var menuItem = getMenuItem(h.menuItemId, true);
          if (currentQuantityType.indexOf(I18n.t("local." + menuItem.quantityType)) == -1) currentQuantityType.push(I18n.t("local." + menuItem.quantityType));
          var quantity = Math.abs(h.quantity);
          var key = getKey(focusPrefix, menuItem, quantity);
          var fullPrice = h.fullPrice - h.discount - h.tableMateDiscount;

          if (datasets[key]) {
          } else {
            var label = getLabel(focusPrefix, menuItem, quantity);
            datasets[key] = {
              yAxisID: "y-axis-2",
              pointRadius: 3,
              lineTension: 0.1,
              borderWidth: 3,
              pointStyle: "rect",
              type: "line",
              label: label,
              data: [],
              fill: false,
              backgroundColor: colors[Object.keys(datasets).length],
              borderColor: colors[Object.keys(datasets).length]
            };
          }
          var date = h.trimmed_created;
          if (datasets[key].data.length > 0) {
            var dd = datasets[key].data[datasets[key].data.length - 1];
            if (dd.x.getTime() == date.getTime()) {
              dd.y += h.fullPrice;
            } else {
              var hh = Object.assign({}, h);
              hh.date = date;
              datasets[key].data.push({ x: date, y: h.fullPrice, data: hh, currentQuantityType: I18n.t("local." + menuItem.quantityType) });
            }
          } else {
            var hh = Object.assign({}, h);
            datasets[key].data.push({ x: date, y: h.fullPrice, data: hh, currentQuantityType: I18n.t("local." + menuItem.quantityType) });
          }
        });
      } else if (datasetsSelected == 1 && mainFocusPrefix == "c" && focus) {
        datasets["c"].data = [];
        datasets["c"].yAxisID = "y-axis-4";
        data.ConsumptionStockEntry.forEach(h => {
          h.count = Math.round(h.count * 1000) / 1000;
          var quantity = Math.abs(h.quantity);
          var key = getKey(focusPrefix, menuItem, quantity);
          var dataset = datasets[key];
          if (!dataset) {
            let datas = $.extend(true, [], dataExample);
            var label = getLabel(focusPrefix, menuItem, quantity);
            datasets[key] = dataset = {
              yAxisID: "y-axis-4",
              type: "line",
              lineTension: 0.1,
              showLine: true,
              pointBorderColor: colors[Object.keys(datasets).length],
              pointStyle: "circle",
              pointBackgroundColor: colors[Object.keys(datasets).length],
              lineWidth: 0,
              pointBorderWidth: 0,
              borderWidth: 0,
              label: label,
              data: datas,
              fill: true,
              backgroundColor: colors[Object.keys(datasets).length]
            };
          }
          var date = h.trimmed_date;

          var dd = getEntry(dataset.data, date).value;
          if (typeof dd.totalCost == "undefined") {
            dd.totalCost = 0;
            dd.totalQuantity = 0;
          }
          dd.totalCost += h.cost;
          dd.totalQuantity += 1;
          dd.y = dd.totalCost / dd.totalQuantity;
        });
        focusPrefix = "pc";
      } else if (datasetsSelected == 1 && mainFocusPrefix == "oo" && focus) {
        data.ConsumptionStockEntry.forEach(h => {
          datasets["oo-" + h.stock.store.id].data = [];
          datasets["oo-" + h.stock.store.id].yAxisID = "y-axis-4";
          h.count = Math.round(h.count * 1000) / 1000;
          var quantity = Math.abs(h.quantity);
          var key = getKey(focusPrefix, menuItem, quantity);
          var dataset = datasets[key];
          if (!dataset) {
            let datas = $.extend(true, [], dataExample);
            var label = getLabel(focusPrefix, menuItem, quantity);
            datasets[key] = dataset = {
              yAxisID: "y-axis-4",
              type: "bar",
              lineTension: 0.1,
              showLine: true,
              pointBorderColor: colors[Object.keys(datasets).length],
              pointStyle: "circle",
              pointBackgroundColor: colors[Object.keys(datasets).length],
              lineWidth: 0,
              pointBorderWidth: 0,
              borderWidth: 0,
              label: label,
              data: datas,
              fill: true,
              backgroundColor: colors[Object.keys(datasets).length]
            };
          }
          var date = h.trimmed_date;

          var dd = getEntry(dataset.data, date).value;
          dd.y += h.price - h.cost;
        });
        focusPrefix = "pc";
      } else if (datasetsSelected == 1 && mainFocusPrefix == "pr" && focus) {
        data.ConsumptionStockEntry.forEach(h => {
          datasets["pr-" + h.stock.store.id].data = [];
          datasets["pr-" + h.stock.store.id].yAxisID = "y-axis-5";
          h.count = Math.round(h.count * 1000) / 1000;
          var quantity = Math.abs(h.quantity);
          var key = getKey(focusPrefix, menuItem, quantity);
          var dataset = datasets[key];
          if (!dataset) {
            let datas = $.extend(true, [], dataExample);
            var label = getLabel(focusPrefix, menuItem, quantity);
            datasets[key] = dataset = {
              yAxisID: "y-axis-5",
              type: "line",
              lineTension: 0.1,
              showLine: true,
              pointBorderColor: colors[Object.keys(datasets).length],
              pointStyle: "circle",
              pointBackgroundColor: colors[Object.keys(datasets).length],
              lineWidth: 3,
              pointBorderWidth: 2,
              borderWidth: 3,
              label: label,
              data: datas,
              fill: false,
              backgroundColor: colors[Object.keys(datasets).length]
            };
          }
          var date = h.trimmed_date;
          var dd = getEntry(dataset.data, date).value;
          dd.totalPrice = (dd.totalPrice ? dd.totalPrice : 0) + h.price;
          dd.totalCost = (dd.totalCost ? dd.totalCost : 0) + h.cost;
          dd.y = Math.round((100 * dd.totalPrice) / dd.totalCost);
        });
        focusPrefix = "ppr";
        Object.keys(datasets).forEach(key => {
          if (key.split("-")[0] == "ppr") {
            var dataset = datasets[key];
            var data2 = [];
            dataset.data.forEach(d => {
              if (d.y > 0) {
                data2.push(d);
              }
            });
            dataset.data = data2;
          }
        });
      }

      var datas = [];
      Object.keys(datasets).forEach((key, index) => {
        datasets[key].hidden = localStorage["menuitems_dataset_" + key] == "true";
        datasets[key].key = key;
        if (!datasets[key].hidden) {
          datas.push(datasets[key]);
          yaxes.push(datasets[key].yAxisID);
        } else {
          if (!focus || key.split("-")[0] == focusPrefix) datas.push(datasets[key]);
        }
      });

      datas.sort(function (a, b) {
        var aid = a.key;
        var bid = b.key;
        var aa = a.key.split("-");
        var bb = b.key.split("-");
        var aMenuItem = aa.length == 3 ? aa[1] : null;
        var bMenuItem = bb.length == 3 ? bb[1] : null;
        if (aMenuItem == null && bMenuItem == null) return aid - bid;
        if (aMenuItem == null) return -1;
        if (bMenuItem == null) return 1;
        var r = menuItemIds.indexOf(Number(aMenuItem)) - menuItemIds.indexOf(Number(bMenuItem));
        if (r == 0) {
          return aa[2] - bb[2];
        } else {
          return r;
        }
      });

      if (myChart) myChart.destroy();
      myChart = new Chart(ctx, {
        type: "bar",
        data: {
          datasets: datas
        },
        events: {
          redraw: function () {
            ajaxCallEnd("processing");
          }
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  //parser: timeFormat,
                  tooltipFormat: "ll",
                  unit: $("[name='range']:checked").attr("id"),
                  //min: Number(localStorage.menuitems_start),
                  max: Number(localStorage.menuitems_end)
                },
                scaleLabel: {
                  display: true,
                  labelString: "Date"
                },
                gridLines: {
                  offsetGridLines: true
                },
                stacked: focus,
                barPercentage: 0.9,
                categoryPercentage: 1,
                offset: true
                //barThickness: '70%',
              }
            ],
            yAxes: [
              {
                type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: yaxes.indexOf("y-axis-1") >= 0 && !focus,
                position: "left",
                id: "y-axis-1",
                scaleLabel: {
                  display: !focus,
                  labelString: currentQuantityType
                },
                ticks: {
                  beginAtZero: true
                }
              },
              {
                type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: yaxes.indexOf("y-axis-3") >= 0,
                position: "left",
                id: "y-axis-3",
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: currentQuantityType
                },
                ticks: {
                  beginAtZero: true
                }
              },
              {
                type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: yaxes.indexOf("y-axis-6") >= 0,
                position: "left",
                id: "y-axis-6",
                scaleLabel: {
                  display: true,
                  labelString: currentQuantityType
                },
                ticks: {
                  beginAtZero: true
                }
              },
              {
                //type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: yaxes.indexOf("y-axis-2") >= 0,
                position: "right",
                id: "y-axis-2",
                scaleLabel: {
                  display: true,
                  labelString: auth.myStatus.restaurant_base_currency.name
                },
                gridLines: {
                  drawOnChartArea: false
                },
                ticks: {
                  beginAtZero: true
                }
              },
              {
                //type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: yaxes.indexOf("y-axis-4") >= 0,
                position: "right",
                id: "y-axis-4",
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: auth.myStatus.restaurant_base_currency.name
                },
                gridLines: {
                  drawOnChartArea: false
                }
              },
              {
                //type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: yaxes.indexOf("y-axis-5") >= 0,
                position: "right",
                id: "y-axis-5",
                stacked: false,
                scaleLabel: {
                  display: true,
                  labelString: "%"
                },
                gridLines: {
                  drawOnChartArea: true
                },
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var d = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].data;
                var dd = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                var key = data.datasets[tooltipItem.datasetIndex].key.split("-")[0];
                var label;
                switch (key) {
                  case "p": // ár
                  case "c": // költség
                  case "m": // árrés
                  case "mm": // árrés
                  case "pc":
                  case "o":
                  case "oo":
                  case "opc":
                    label = data.datasets[tooltipItem.datasetIndex].label + ":" + Math.round(tooltipItem.value * 1000) / 1000 + " " + auth.myStatus.restaurant_base_currency.name;
                    break;
                  case "k": // készlet
                  case "cc":
                  case "f": // forgalom
                  case "ff": // forgalom
                  case "kk": // forgalom
                  case "b": // bevét
                    label = data.datasets[tooltipItem.datasetIndex].label + ":" + Math.round(tooltipItem.value * 1000) / 1000 + " " + dd.currentQuantityType;
                    break;
                  case "i": // leltár
                    var label = [data.datasets[6].label + ":" + d.count];
                    if (d.count - d.originalCount > 0) label.push(I18n.t("admin_local.inventory_surplus") + ":" + (d.count - d.originalCount));
                    else if (d.count - d.originalCount < 0) label.push(I18n.t("admin_local.inventory_shortage") + ":" + (-d.count + d.originalCount));
                    //else label = data.datasets[tooltipItem.datasetIndex].label + ":" + Math.round(tooltipItem.value * 1000) / 1000 + " " + dd.currentQuantityType;
                    break;
                  case "ppr":
                  case "pr":
                    label = data.datasets[tooltipItem.datasetIndex].label + ":" + Math.round(tooltipItem.value * 1000) / 1000 + " %";
                    break;
                }
                return label;
              }
            }
          },
          legend: {
            labels: {
              filter: function (item, chart) {
                return true;
              }
            },
            onClick: function (e, legendItem) {
              var index = legendItem.datasetIndex;
              var key = datas[index].key.split("-")[0];
              var hidden = localStorage["menuitems_dataset_" + datas[index].key] == "true";

              if (key == "k" && !focus) {
                ["k", "f", "o", "p", "pr", "oo", "c"].forEach(i => {
                  localStorage["menuitems_dataset_" + i] = true;
                });
                localStorage["menuitems_dataset_" + datas[index].key] = !hidden;
                localStorage["menuitems_dataset_focus"] = hidden;
                drawChart();
                return;
              } else if (datasetsSelected == 1 && (key == "p" || key == "c" || key == "k") && !focus && !hidden) {
                localStorage["menuitems_dataset_focus"] = true;
                drawChart();
                return;
              } else if (isCategory == true && datasetsSelected == 1 && (key == "f" || key == "o") && !focus && !hidden) {
                localStorage["menuitems_dataset_focus"] = true;
                drawChart();
                return;
              }
              if (e.ctrlKey == true) {
                if (key != focusPrefix) {
                  //unhide all
                  datas.forEach(data => {
                    if (data.key.split("-")[0] == focusPrefix) localStorage["menuitems_dataset_" + data.key] = false;
                  });
                } else {
                  //hide all but the one selected
                  datas.forEach(data => {
                    if (data.key.split("-")[0] == focusPrefix) localStorage["menuitems_dataset_" + data.key] = true;
                  });
                  localStorage["menuitems_dataset_" + datas[index].key] = false;
                }
              } else if (key != focusPrefix && focus) {
                localStorage["menuitems_dataset_" + datas[index].key] = true;
                localStorage["menuitems_dataset_focus"] = false;
                drawChart();
                return;
              } else {
                localStorage["menuitems_dataset_" + datas[index].key] = !hidden;
              }
              drawChart();
            }
          }
        }
      });
    } finally {
      ajaxCallEnd("processing");
    }
  }, 20);
}

function getStore(id) {
  return admin.stores.find(store => store.id == id);
}

function getMonday(date) {
  var day = date.getDay() || 7;
  if (day !== 1) date.setHours(-24 * (day - 1));
  return date;
}

menuitemutil.parseNumber = function (number) {
  if (typeof number == "undefined") return "";
  if (typeof number == "number") return number;
  number = number.replace(" ", "");
  number = number.replace(",", ".");
  number = Number(number);
  return number;
};

function random_bg_color() {
  var x = Math.floor(Math.random() * 256);
  var y = Math.floor(Math.random() * 256);
  var z = Math.floor(Math.random() * 256);
  return "rgb(" + x + "," + y + "," + z + ")";
}

var quantityCache = {};
menuitemutil.getInventoryQuantity = function (menuItem, quantity) {
  var ret = quantityCache[menuItem.id + "-" + quantity];
  if (ret != undefined) return ret;
  menuItem.availableQuantities.forEach(q => {
    if (q.inventoryItem && q.quantity == quantity) ret = quantity;
  });
  if (ret != undefined && ret != "") {
    quantityCache[menuItem.id + "-" + quantity] = ret;
    return ret;
  }
  for (let i = menuItem.availableQuantities.length; i > 0; i--) {
    var q = menuItem.availableQuantities[i - 1];
    if (q.inventoryItem) ret = q.quantity;
  }
  if (ret == undefined) {
    if (menuItem.entityType == "Drink") ret = "1";
    else ret = "";
  }
  quantityCache[menuItem.id + "-" + quantity] = ret;
  return ret;
};

menuitemutil.updateQuantityType = (t, event) => {
  var newValue = $(t).val();
  var oldValue = $(t).data('oldValue');
  if (typeof newValue !== "string")
    newValue = newValue[0];
  if (oldValue && typeof oldValue !== "string")
    oldValue = oldValue[0];
  if (oldValue == newValue)
    return;
  $(t).data('oldValue', newValue);
  var newQuantityTypeQuantity = 1000;
  var oldQuantityTypeQuantity = 1000;
  switch (newValue) {
    case 'ml':
    case 'g':
      newQuantityTypeQuantity = 1;
      break;
    case 'cl':
    case 'dkg':
      newQuantityTypeQuantity = 10;
      break;
    case 'dl':
      newQuantityTypeQuantity = 100;
      break;
    case 'kg':
    case 'l':
      newQuantityTypeQuantity = 1000;
      break;
  }
  switch (oldValue) {
    case 'ml':
    case 'g':
      oldQuantityTypeQuantity = 1;
      break;
    case 'dkg':
    case 'cl':
      oldQuantityTypeQuantity = 10;
      break;
    case 'dl':
      oldQuantityTypeQuantity = 100;
      break;
    case 'kg':
    case 'l':
      oldQuantityTypeQuantity = 1000;
      break;
  }
  if (newValue)
    $('span.q_quantity_type').html(I18n.t('local.' + newValue))
  $('td input#menuItem_quantity').each((ind, e) => {
    e.value = Math.round(e.value * oldQuantityTypeQuantity / newQuantityTypeQuantity * 1000) / 1000;
  })
}

menuitemutil.generateBarCode = (e) => {
  const v = $(e).val();
  if (v)
    JsBarcode($(e).next().get(0), v, { height: 30 });
  else
    $(e).next().attr('src', '');
}

menuitemutil.scanbarcode = (e) => {
  //eslint-disable-next-line no-undef
  const cor = cordova;
  cor.plugins.barcodeScanner.scan(
    function (result) {
      if (!result.cancelled) {
        if (!isNaN(result.text)) {
          $(e).next().val(result.text);
          //qrcode_callback(result.text);
        }
      }
      if (cor.plugins.backgroundMode)
        setTimeout(() => { cor.plugins.backgroundMode.enableOverrideBackButton = true }, 1000);
    },
    function (error) {
      alert("Scanning failed: " + error);
      if (cor.plugins.backgroundMode)
        setTimeout(() => { cor.plugins.backgroundMode.enableOverrideBackButton = true }, 1000);
    }
  );
}

function getMenuItemAdditions(item) {
  const additions = [];
  if (item.menuItemAdditions) {
    item.menuItemAdditions.filter(addition => addition.additionType === "mandatory" || addition.additionType === "included").forEach(a => {
      additions.push(a)
    }
    );
  }
  if (item.menuCategoryId) {
    const parentCategory = getMenuCategory(item);
    if (parentCategory)
      getMenuItemAdditions(parentCategory).forEach(a => additions.push(a));
    else {
      getMenuCategory(item);
    }
  }

  return additions;
}

function isOnTop(item) {
  if (item.menuCategoryId)
    return item.isTop && isOnTop(getMenuCategory(item));
  else
    return item.isTop;

}

export const getPlaces = (platform) => {
  return auth.myStatus.restaurant_settings["enabled-features"][platform]?.places || []
}

export const refreshConnectedRestaurants = (globalId, refresh = false) => {
  var def = $.Deferred();
  if (!featureEnabled('franchize')) {
    def.resolve();
    return def.promise();
  }
  $(".connectedRestaurant").show();
  const unselected = auth.myStatus.restaurant_copies.filter(id => localStorage['restaurant_copy_selected_' + id] != 'true');
  const selected = auth.myStatus.restaurant_copies.filter(id => localStorage['restaurant_copy_selected_' + id] == 'true');
  if (auth.myStatus.restaurant_copies.length - unselected.length === 0)
    $(".connectedRestaurants").hide();
  else
    $(".connectedRestaurants").show();
  unselected.forEach(id => {
    //delete connectedRestaurants[id];
    $(".connectedRestaurant_" + id).hide();
    //if (refresh)
    //processMenuItems(admin.categories);
  })
  var count = selected.map(id => menuitemutil.getRestaurant(id)).filter(r => !globalId || globalId == r.globalId).length;
  //connectedRestaurants = { 0: { restaurant: {}, data: { data: children : admin.categories } } };
  selected.map(id => menuitemutil.getRestaurant(id)).filter(r => !globalId || globalId == r.globalId).forEach(restaurant => {
    function getMenuItems() {
      menuitemutil.getLocalFromGlobal(restaurant).done(id => {
        get(restaurant.serverUrl +
          "eatwithme.server/" + restaurant.instance + "/restaurantService/" + id + "/getMenuItems?" + (typeof lang === "undefined" ? "" : "lang=" + localStorage.language) + "&deleted=" + false + "&active=" + false + "&instance=" + restaurant.instance, undefined, undefined, undefined, undefined, undefined, undefined, undefined, false
        ).done(function (data) {
          connectedRestaurants[restaurant.globalId] = {
            restaurant,
            data
          };
          count--;
          if (!count) {
            if (refresh)
              processMenuItems(admin.categories);
            def.resolve();
          }
        }).fail(data => {
          auth.ajaxError = false;
          login();
        });
      }).fail(data => {
        auth.ajaxError = false;
        login();
      });
    }
    function login() {
      getGlobal("login/getLoginToken").done(token => {
        //var loginUrl = serv + "/eatwithme.server/login?token=" + encodeURIComponent(token.id) + "&globalId=" + (restaurant.globalId ? restaurant.globalId : 0) + "&localId=" + (restaurant.id ? restaurant.id : 0) + "&user=true";
        //get(loginUrl, undefined, undefined, 30000)
        post(restaurant.serverUrl + "eatwithme.server/" + restaurant.instance + "/login?instance=" + restaurant.instance, "username=" + encodeURIComponent("token:" + restaurant.globalId + "/" + token.id) + "&password=" + encodeURIComponent(token.id) + "&user=true", {}, true, 0, false, 30000).done(() => {
          getMenuItems();
        })
      })
    }
    getMenuItems();
  });
  if (!count) {
    def.resolve();
  }
  return def.promise();
}

export default menuitemutil;
