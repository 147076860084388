import { get, post } from "./auth";
import { admin, resultHandler } from "./admin";

// cash registers
function getBankTerminals(handler, restaurantId) {
    get("adminService/" + (typeof restaurantId === "undefined" ? sessionStorage.restaurantSelected : restaurantId) + "/getBankTerminals?lang=" + localStorage.language, sessionStorage.restaurantSelected).done(function (data) {
        if (data === null) {
            console.log("Failed to get BankTerminals!");
        } else {
            handler(data);
        }
    });
}
admin.getBankTerminals = getBankTerminals;
function getBankTerminalsStatus(handler, restaurantId) {
    get("adminService/" + (typeof restaurantId === "undefined" ? sessionStorage.restaurantSelected : restaurantId) + "/getBankTerminalsStatus", sessionStorage.restaurantSelected).done(function (data) {
        if (data === null) {
            console.log("Failed to get BankTerminals status!");
        } else {
            handler(data);
        }
    });
}
admin.getBankTerminalsStatus = getBankTerminalsStatus;
function modifyBankTerminal(bankTerminal, handler, errorHandler) {
    post("adminService/" + sessionStorage.restaurantSelected + "/modifyBankTerminal", bankTerminal).done(function (data) {
        resultHandler(data, handler, errorHandler);
    });
}
admin.modifyBankTerminal = modifyBankTerminal;
function addBankTerminal(bankTerminal, handler, errorHandler) {
    post("adminService/" + sessionStorage.restaurantSelected + "/addBankTerminal", bankTerminal).done(function (data) {
        resultHandler(data, handler, errorHandler);
    });
}
admin.addBankTerminal = addBankTerminal;
function deleteBankTerminal(id, handler, errorHandler) {
    get("adminService/" + sessionStorage.restaurantSelected + "/deleteBankTerminal/" + id).done(function (data) {
        resultHandler(data, handler, errorHandler);
    });
}
admin.deleteBankTerminal = deleteBankTerminal;
