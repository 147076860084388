import $ from "jquery";
import ReactDOM from "react-dom";
import moment from "moment";
import auth, { updateHooks, startpoll, get, post, _floatTableHead, _resizefloatTableHead, avoidMultipleClick, confirmDialog, choiceDialog, featureEnabled, loadMyImage, getLocale, getLocale2 } from "../auth";
import admin, { resultHandler, printHtml } from "../admin";
import languages from "../../langs/languages";
import FullCalendar from "@fullcalendar/react";
import { Calendar } from "@fullcalendar/core";
import resourceTimeline from "@fullcalendar/resource-timeline";
import interaction from "@fullcalendar/interaction";
import "@fullcalendar/timeline/main.css";
import "@fullcalendar/resource-timeline/main.css";
import Cleave from "cleave.js";
import SortedSet from "js-sorted-set";
import { allstates } from "../order-grouping";
import { getOrderById, getTableName, tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import { __tableMatePaid, _tableMatePaid } from "../tables-overview";
import { checkPayments, getPaymentMethod } from "../tableMatePaid";
import { I18n } from "react-redux-i18n";


const { admin_local, local } = languages;

const channels = ["console", "webshop_homedelivery", "webshop_pickup", "webshop_onsite", "wolt", "foodora", "falatozz", "console_homedelivery", "console_pickup"];

var paymentMethodsData;
var currenciesData;

if (typeof localStorage.tableOccupations_fromDate === 'undefined' || localStorage.tableOccupations_fromDate === 'NaN') {
	localStorage.tableOccupations_toDate = moment().toDate().getTime();
	localStorage.tableOccupations_fromDate = moment().subtract(1, 'month').toDate().getTime();
}

if (typeof localStorage.tableOccupations_view === "undefined") {
	localStorage.tableOccupations_view = 'list';
}
if (typeof localStorage.tableOccupations_filterBy === "undefined") {
	localStorage.tableOccupations_filterBy = 'byOrder';
}

var calendar = null;
var calendarResources = [];

var ss, ee;
var tableOccupations_data = [];

var tableOccupations = {

}

export const clearData = () => {
	tableOccupations = {
	}
}

export const ready = (props) => {
	clearData();
	const def = $.Deferred();
	$('#barTemplate').tmpl().appendTo('.bar');

	$('label > input#' + localStorage.tableOccupations_view).parent().click();
	$('label > input#' + localStorage.tableOccupations_view).parent().blur();

	$('span#back').click(function () {
		props.history.back();
	})

	admin.getAllPaymentMethods(localStorage.language, function (pm) {
		paymentMethodsData = pm;
		admin.getAllCurrencies(localStorage.language, function (c) {
			currenciesData = c;
			admin.getMenuItems(function (cats) {
				admin.categories = cats;
				$('#tableOccupationsTemplate').tmpl({
					paymentMethods: paymentMethodsData,
					currencies: currenciesData,
					...tmplparams()
				}).appendTo("#main");

				$('div#toppart > *').addClass('hidden');
				$('div#toppart > #' + localStorage.tableOccupations_view).removeClass('hidden');


				$('input#search-deleted').prop('checked', localStorage.tableOccupations_deleted === "true");
				$('input#search-active').prop('checked', localStorage.tableOccupations_active === "true");
				$('input#search-deleted').parent().toggleClass('active', localStorage.tableOccupations_deleted === "true");
				$('input#search-active').parent().toggleClass('active', localStorage.tableOccupations_active === "true");

				var tt = $('input[name="view"][value= "' + localStorage.tableOccupations_view + '"');
				tt.prop('checked', true);
				tt.parent().addClass("active");

				tt = $('input[name="filterBy"][value= "' + localStorage.tableOccupations_filterBy + '"');
				tt.prop('checked', true);
				tt.parent().addClass("active");

				function r() { reload() };

				$('input#tableNumber').on('change', r);
				$('input#search-active').on('change', r);
				$('input#search-deleted').on('change', () => { processTableOccupations() });
				$('input#search-orderedBy').on('keyup', () => { processTableOccupations() });
				$('input#search-receivedBy').on('keyup', () => { processTableOccupations() });
				$('input#search-menuItem').on('change', r);
				$('input#search-menuItem').on('keyup', r);
				$('input#search-comment').on('change', r);
				$('input#search-comment').on('keyup', r);
				$('input#search-deliveredby').on('change', r);
				$('input#search-deliveredby').on('keyup', r);
				$('input#search-ntak').on('change', r);
				$('input#for_whom').on('change', r);
				$('input.day').change(r);

				updateHooks();

				//_floatTableHead("#tableOccupationsFreezeTable");
				//_floatTableHead("#ordersFreezeTable");
				//_floatTableHead("#paymentsFreezeTable");

				function getTableCountFor(roomId, seats) {
					var count = 0;
					admin.getRoom(roomId).tables.forEach(table => {
						if (table.seats === Number(seats))
							count++;
					});
					return count;
				}

				auth.myStatus.restaurant_rooms.forEach(r => {

					if (r.tableCount) {

					} else {
						r.tableCount = {}
					}

					if (r.isActive === false)
						return;

					var toproom = {
						id: "room-" + r.id,
						title: r.name + " - " + String.format(admin_local.room_tables, r.tables.length),
						children: [],
					}
					calendarResources.push(toproom);

					r.tables.forEach(table => {
						r.tableCount[table.seats] = getTableCountFor(r.id, table.seats);
					})
					Object.keys(r.tableCount).forEach(seats => {
						var room = {
							id: "room-" + r.id + "-" + seats,
							title: r.name + " - " + String.format(admin_local.room_tables_seats, r.tableCount[seats], seats),
							children: [],
						}
						toproom.children.push(room);
						r.tables.forEach(table => {
							if (table.seats === Number(seats))
								room.children.push({
									id: "" + table.number,
									title: table.name !== null && typeof table.name !== 'undefined' && table.name !== '' ? table.name : ("" + table.number)
								})
						})

					});


				})

				auth.myStatus.restaurant_tables.forEach(table => {
					if (table.roomId == null)
						calendarResources.push({
							id: "" + table.number,
							title: table.name !== null && typeof table.name !== 'undefined' && table.name !== '' ? table.name : ("" + table.number)
						})
				})


				var startTime = "0" + auth.myStatus.restaurant_settings['business-day-starts-at'];
				if (startTime.length === 3)
					startTime = startTime.substring(1, 3);
				var endTime = "0" + auth.myStatus.restaurant_settings['business-day-ends-at'];
				if (endTime.length === 3)
					endTime = endTime.substring(1, 3);

				var businessHours = [];

				if (Number(auth.myStatus.restaurant_settings['business-day-starts-at']) < Number(auth.myStatus.restaurant_settings['business-day-ends-at'])) {
					businessHours.push({
						daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
						startTime: startTime + ":00",
						endTime: endTime + ":01",

					});
				} else {
					businessHours.push({
						daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
						startTime: "00:00",
						endTime: endTime + ":00",
						showStart: false,
						showEnd: true
					});
					businessHours.push({
						daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
						startTime: startTime + ":00",
						endTime: "23:59",
						showEnd: false,
						showStart: true
					});

				}


				ss = moment(Number(localStorage.tableOccupations_fromDate));
				ss.add('Hour', auth.myStatus.restaurant_settings['business-day-starts-at']);

				ee = moment(Number(localStorage.tableOccupations_toDate));
				ee.add('Second', 1);
				if (auth.myStatus.restaurant_settings['business-day-starts-at'] > auth.myStatus.restaurant_settings['business-day-ends-at'])
					ee.add('Hour', auth.myStatus.restaurant_settings['business-day-ends-at']);
				else
					ee.add('Hour', auth.myStatus.restaurant_settings['business-day-ends-at'] - 24);

				var calendarEl = document.getElementById('calendar_widget');

				const slotDuration = ee.diff(ss, 'day') < 2 ? "00:15" : ee.diff(ss, 'day') < 8 ? "01:00" : "04:00";
				const slotMinutes = ee.diff(ss, 'day') < 2 ? 15 : ee.diff(ss, 'day') < 8 ? 60 : 240;

				calendar = new Calendar(calendarEl, {
					schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
					//plugins: [daygrid, resourceCommon, timeline, resourceTimeline, interaction],
					plugins: [resourceTimeline, interaction],

					resourceLabelText: admin_local.tables,
					initialResources: calendarResources,

					editable: true, // enable draggable events
					aspectRatio: 1.8,
					resourceAreaWidth: "200px",
					scrollTime: '00:00', // undo default 6am scrollTime
					slotDuration: slotDuration,
					slotWidth: 20,
					slotheight: 20,
					resourcesInitiallyExpanded: false,
					showNonBusinessHours: false,
					eventOrder: ["extendedProps.seats", "seats", "title"],
					height: "parent",
					handleWindowResize: true,
					headerToolbar: {
						left: 'today prev,next',
						center: 'title',
						right: 'resourceTimelineDay,resourceTimelineThreeDays,timeGridWeek,dayGridMonth,listWeek'
					},
					//visibleRange: visibleRange,
					//					validRange: visibleRange,
					initialView: 'resourceTimeline',
					businessHours: businessHours,
					dayRender: function (dayRenderInfo) {
						var date = dayRenderInfo.date;
						var el = dayRenderInfo.el;
						//var offset = ee.utcOffset()*60000 - date.utcOffset()*60000;

						calendar.optionsManager.overrides.businessHours.forEach(businessHour => {
							var day = date.getDay();
							if (businessHour.daysOfWeek.indexOf(day) === -1)
								return;
							var d = new Date(date.getTime());
							d.setSeconds(0);
							d.setMilliseconds(0);
							var startHour = new Date(date.getTime());
							startHour.setHours(businessHour.startTime.split(':')[0]);
							startHour.setMinutes(businessHour.startTime.split(':')[1]);
							startHour.setSeconds(0);
							startHour.setMilliseconds(0);
							var endHour = new Date(date.getTime());
							endHour.setHours(businessHour.endTime.split(':')[0]);
							endHour.setMinutes(businessHour.endTime.split(':')[1]);
							endHour.setSeconds(0);
							endHour.setMilliseconds(0);
							endHour = moment(endHour).add(-calendar.optionsManager.overrides.slotDuration.split(':')[1], "minute").toDate();
							if (date.getTime() === startHour.getTime() && businessHour.showStart === true) {
								el.style['border-left'] = "5px dashed darkslategray";
								el.style['margin-left'] = "3px";
							} else if (date.getTime() === endHour.getTime() && businessHour.showEnd === true) {
								el.style['border-right'] = "5px dashed darkslategray";
								el.style['margin-right'] = "3px";
							}
						});
					},
					events: async function (args, callback) {
						var events = [];
						var roomEvents = [];

						function getRoomEvent(roomId, start, seats) {
							return roomEvents.find(e => e.id === "room-" + roomId + "-" + seats + "-" + start.toDate().getTime())
						}


						tableOccupations_data.forEach(function (v, i) {
							v.title = "";
							v.selected = false;
							//v.isActive = v.active;
							delete v.active;
							v.folder = false;
							var start = new Date(v.occupationStart);
							var end = v.occupationEnd == null ? new Date() : new Date(v.occupationEnd);

							var startQuarter = moment(start);
							var endQuarter = moment(end);
							if (slotMinutes >= 60) {
								startQuarter = startQuarter.set({ 'hours': Math.floor(startQuarter.get('hour') / slotMinutes / 60) * slotMinutes / 60, 'minutes': 0, seconds: 0, milliseconds: 0 });
								endQuarter = endQuarter.set({ 'hours': Math.floor(startQuarter.get('hour') / endQuarter / 60) * slotMinutes / 60, 'minutes': 0, seconds: 0, milliseconds: 0 });
							} else {
								startQuarter = startQuarter.set({ 'minutes': Math.floor(startQuarter.get('minute') / slotMinutes) * slotMinutes, seconds: 0, milliseconds: 0 });
								endQuarter = endQuarter.set({ 'minutes': Math.ceil(endQuarter.get('minute') / slotMinutes) * slotMinutes, seconds: 0, milliseconds: 0 });
							}


							v.tableNumbers.forEach(table => {
								events.push({
									start: start,
									end: end,
									id: v.id + "-" + table,
									resourceId: "" + table,
									title: ""
								});
								var t = admin.getTable(table);
								if (t && t.roomId !== null) {
									var room = admin.getRoom(t.roomId);
									if (room.isActive === false)
										return;
									for (let tt = startQuarter; tt.isBefore(endQuarter); tt.add(slotMinutes, 'minutes')) {
										var seats = Object.keys(room.tableCount).sort(function (a, b) { return a - b });
										seats.forEach(seatCount => {
											var event = getRoomEvent(t.roomId, tt, seatCount);
											if (event == null) {
												events.push(event = {
													start: tt.toDate(),
													end: tt.clone().add(slotMinutes, 'minutes').toDate(),
													id: "room-" + t.roomId + "-" + seatCount + "-" + tt.toDate().getTime(),
													resourceId: "room-" + t.roomId + "-" + seatCount,
													title: "",
													editable: false,
													resourceEditable: false,
													extendedProps: {
														tables: room.tableCount[seatCount],
														occupied: 0,
														seats: seatCount,
													}
												});
												roomEvents.push(event);
											}
										})
										var event = getRoomEvent(t.roomId, tt, t.seats);
										event.extendedProps.occupied++;
										var lines = "\r\n";
										event.title = lines + event.extendedProps.occupied;

										event = getRoomEvent(t.roomId, tt, "undefined");
										if (event == null) {
											events.push(event = {
												start: tt.toDate(),
												end: tt.clone().add(slotMinutes, 'minutes').toDate(),
												id: "room-" + t.roomId + "-undefined-" + tt.toDate().getTime(),
												resourceId: "room-" + t.roomId,
												title: "",
												editable: false,
												resourceEditable: false,
												extendedProps: {
													tables: room.tables.length,
													occupied: 0,
												}
											});
											roomEvents.push(event);
										}
										event = getRoomEvent(t.roomId, tt, "undefined");
										event.extendedProps.occupied++;
										lines = "\r\n";
										event.title = lines + event.extendedProps.occupied;


									}
								}
							});
						});

						callback(events);
					},
					eventRender: function (eventInfo) {
						var el = eventInfo.el;
						var event = eventInfo.event;
						if (event.extendedProps.tables) {
							if (event.extendedProps.occupied === event.extendedProps.tables) {
								el.style.background = "#F05050";
								el.style.color = "white";
							} else if (event.extendedProps.occupied - event.extendedProps.tables === -1) {
								el.style.background = "orange";
							} else {
								el.style.background = "linear-gradient(to top, darkgray, darkgray " + (Math.round(100 * event.extendedProps.occupied / event.extendedProps.tables)) + "%, white " + (Math.round(100 * event.extendedProps.occupied / event.extendedProps.tables)) + "%, white 100%)";
							}
							el.style.border = "unset";
							el.style['border-left'] = "1px dotted #ddd";
							el.style['border-top'] = "1px solid #ddd";
							el.style.bottom = "0";
							el.style['margin-bottom'] = "0";
						}
					},
					resourceRender: function (resource, cellEls, bodyTds) {
						if (resource.resource.id.startsWith('room') && resource.resource.id.split('-').length === 2) {
							if (resource.view.calendar)
								resource.view.calendar.dispatch({
									type: 'SET_RESOURCE_ENTITY_EXPANDED',
									id: resource.resource.id,
									isExpanded: true
								});
							resource.el.style['font-style'] = "italic";
							resource.el.style['outline'] = "2px solid darkslategray";
						}
					},

					views: {
						resourceTimelineThreeDays: {
							type: 'resourceTimeline',
							duration: { days: 3 },
							buttonText: '3 days'
						}
					},
				});

				calendar.render();


				//getAllTableOccupations(processTableOccupations);

				startpoll(serverSideMessageHandler);

				$('#tableOccupationsFreezeTable').on("click", () => {
					$('#tableOccupationsFreezeTable').css("flex-basis", "60%");
				});
				$('#tableOccupationsDetails').on("click", () => {
					$('#tableOccupationsFreezeTable').css("flex-basis", "20%");
				});

				def.resolve();
				// updateHooks();
			});
		});
	});
	return def.promise();

}

export const reload = (props) => {
	getAllTableOccupations(props, processTableOccupations);
}

export const fullreload = (props) => {
	tableOccupations.oldSearch = null;
	reload(props);
}
function getAllTableOccupations(props, handler, errorHandler) {
	props = props ? props : tableOccupations.props;
	tableOccupations.props = props;
	var activeOnly = $('input#search-active').prop('checked');
	const start = props.startDate.clone();
	//const end = props.endDate.clone().startOf("day");
	const end = props.endDate.startOf("day").clone();
	start.add(props.timeValue.start.hours, "hour");
	end.add(props.timeValue.end.hours, "hour");
	start.add(props.timeValue.start.minutes, "minute");
	end.add(props.timeValue.end.minutes, "minute");
	start.add(-auth.myStatus.restaurant_settings['business-day-starts-at'], "hour");
	end.add(-auth.myStatus.restaurant_settings['business-day-starts-at'], "hour");
	var search = {
		fromDate: start.valueOf(),
		toDate: end.valueOf(),
		fromTime: null,//props.timeValue.start.hours * 60 + props.timeValue.start.minutes,
		toTime: null, //props.timeValue.end.hours * 60 + props.timeValue.end.minutes,
		tableNumber: $('input#tableNumber').val() ? $('input#tableNumber').val() : "",
		active: localStorage.tableOccupations_active = (activeOnly ? true : undefined),
		days: [],
		menuItem: $('input#search-menuItem').val(),
		comment: $('input#search-comment').val(),
		deliveredBy: $('input#search-deliveredby').val(),
		ntak: $('input#search-ntak').prop('checked'),
		filterBy: localStorage.tableOccupations_filterBy,
		customer: $('input#for_whom').val(),
	}
	$('input.day:checked').each(function (ind, d) {
		search.days.push($(d).attr('id'));
	});
	if (tableOccupations.oldSearch === JSON.stringify(search))
		return;
	tableOccupations.oldSearch = JSON.stringify(search);
	post("adminService/" + sessionStorage.restaurantSelected + "/filterTableOccupations?lang=" + localStorage.language, search, undefined, true, 1, false, 60000).done(function (data) {
		resultHandler(data, handler, errorHandler)
	});
}

function serverSideMessageHandler(data) {

}

var NumeralFormatter = new Cleave.NumeralFormatter(0, 0, 0, 'thousand', false, true, " ");


var deleted = false;
var active = false;

function processTableOccupations(tableOccupationsD) {

	if (tableOccupationsD && Array.isArray(tableOccupationsD)) {
		tableOccupationsD.forEach(to => {
			to.title = moment(to.occupationStart).format("YY-MM-DD")
			to.key = to.id;
			to.isActive = to.active;
			to.active = false;
		})
	}

	if (!Array.isArray(tableOccupationsD))
		tableOccupationsD = tableOccupations_data;
	tableOccupations_data = tableOccupationsD;

	ss = moment(Number(localStorage.tableOccupations_fromDate));
	ss.add('Hour', auth.myStatus.restaurant_settings['business-day-starts-at']);

	ss = moment(Number(localStorage.tableOccupations_fromDate));
	ss.add('Hour', auth.myStatus.restaurant_settings['business-day-starts-at']);

	ee = moment(Number(localStorage.tableOccupations_toDate));
	ee.add('Second', 1);
	if (auth.myStatus.restaurant_settings['business-day-starts-at'] > auth.myStatus.restaurant_settings['business-day-ends-at'])
		ee.add('Hour', auth.myStatus.restaurant_settings['business-day-ends-at']);
	else
		ee.add('Hour', auth.myStatus.restaurant_settings['business-day-ends-at'] - 24);

	var visibleRange = {
		start: ss.toDate(),
		end: ee.toDate()
	}


	//try {

	calendar.gotoDate(visibleRange.start)

	//calendar.setOption('now', visibleRange.start);

	/*
	try {
		calendar.setOption('validRange', visibleRange);
		calendar.setOption('visibleRange', visibleRange);
	} catch (ex) {
		console.error(ex);
	}*/

	calendar.render();
	calendar.refetchEvents();

	/*
} catch (ex) {
	console.error("ERROR:", ex)
	console.error(ex);
}*/



	tableOccupations.orderedBy = $('#search-orderedBy').val();
	tableOccupations.receivedBy = $('#search-receivedBy').val();
	tableOccupations.for_whom = "";

	deleted = localStorage.tableOccupations_deleted = $('input#search-deleted:checked').length > 0;
	active = localStorage.tableOccupations_active = $('input#search-active:checked').length > 0;
	if (!(tableOccupations.r)) {
		tableOccupations.r = createTree("table#tableOccupations", {
			checkbox: false,
			source: tableOccupationsD,
			scrollParent: false,
			zindex: 1000,
			noAnimation: true,
			activeVisible: false,
			keyboard: true,
			icon: false,
			extensions: ["persist", "table", "gridnav", "filter"],
			filter: {
				counter: false,
				mode: "hide"
			},
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				// column index (default: nodeColumnIdx)
				indentation: 0, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				// gets
				// activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},

			persist: {
				cookiePrefix: "fancytree-1-tableOccupations",
				//expandLazy: false,
				//overrideSource: false,
				store: "auto"
			},

			activate: function (event, data) {
				var node = data.node;
				if (node.data.id)
					admin.getTableOccupationWithOrders(data.node.data.id, function (data) {
						processOrders(data[0]);
						processPayments(data[0]);
					});
			},

			// renderStatusColumns: false, // false: default renderer
			// true: generate renderColumns events, even for status nodes
			// function: specific callback for status nodes

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = false;

				// (Column #0 is rendered by fancytree by adding the checkbox)

				// Column #1 should contain the index as plain text, e.g.
				// '2.7.1'
				// $tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");

				// (Column #2 is rendered by fancytree)
				// ...otherwise render remaining columns
				$(node.tr).data("data", node.data);
				var c = 0;
				var todeliver = node.data.todeliver ? node.data.todeliver.split(",").map(t => "<div>" + moment.unix(t).format("YY-MM-DD") + "</div>").join("") : undefined;
				$tdList.eq(c++).html(todeliver ? todeliver : moment(node.data.occupationStart).format("YY-MM-DD"));
				$tdList.eq(c++).text(formatTime2(new Date(node.data.occupationStart)));
				$tdList.eq(c++).text(node.data.seqNr);
				$tdList.eq(c++).text(formatTime(Math.max(0, node.data.occupationEnd > 0 ? ((node.data.occupationEnd) - (node.data.bookedOccupationStart ? node.data.bookedOccupationStart : node.data.occupationStart)) / 60000 : (new Date().getTime() - node.data.occupationStart) / 60000)));
				var tableText = node.data.tableNumbers.map(t => getTableName(t));
				if (node.data.bookedProperties && node.data.bookedProperties.name) {
					tableText += "<br/>" + node.data.bookedProperties.name + (node.data.bookedProperties["thirdparty-order-number"] ? " " + node.data.bookedProperties["thirdparty-order-number"] : "");
				} else if (node.data.customer) {
					tableText += "<br/>" + node.data.customer;
				}
				if (node.data.bookedProperties && node.data.bookedProperties.address && node.data.bookedProperties.address != "null") {
					tableText += "<br/><span style='font-size:.8rem'>" + node.data.bookedProperties.address + "</span>";
				}
				if (node.data.bookedProperties && node.data.bookedProperties.phone) {
					tableText += "<br/><a href='tel://" + node.data.bookedProperties.phone + "' style='font-size:.8rem' class='i-phone'>" + node.data.bookedProperties.phone + "</a>";
				}
				$tdList.eq(c).css("line-height", "1");
				$tdList.eq(c++).html(tableText);
				$tdList.eq(c++).html(node.data.persons);
				var price = node.data.fullPrice;
				if (price === 0 && node.data.discount + node.data.tm_discount > 0)
					price = node.data.discount + node.data.tm_discount;
				$tdList.eq(c++).text(NumeralFormatter.format("" + price));
				$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.discount + node.data.tm_discount)));
				var amount = price - node.data.discount - node.data.tm_discount;
				$tdList.eq(c++).text(NumeralFormatter.format("" + (price - node.data.discount - node.data.tm_discount)));
				$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.paid));
				amount = node.data.serviceFee;
				$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.serviceFee));
				$tdList.eq(c++).text(NumeralFormatter.format("" + Math.round(node.data.tip)));
				$tdList.eq(c++).text(NumeralFormatter.format("" + Math.round(price - node.data.discount - node.data.tm_discount - node.data.paid + node.data.tip + node.data.serviceFee)));
				$tdList.eq(c++).text(node.data.orderCount);
				$tdList.eq(c++).text(node.data.deletedOrderCount);
				var orderedBy = node.data.orderedBy.map(t => {
					const r = /(.*)\(channel-(.*)\)/.exec(t);
					if (r) {
						if (r[2] !== "0")
							t = r[1] + " (" + I18n.t("admin_local.channels." + channels[Number(r[2])]) + ")"
						else
							t = r[1];
					} else if (t.startsWith("channel-")) {
						t = I18n.t("admin_local.channels." + channels[Number(t.substring(8))]);
					}
					t = t.replace("(console_homedelivery)", "").replace("(console_pickup)", "").trim();
					return t
				});
				$tdList.eq(c++).text(orderedBy);
				$tdList.eq(c++).text(node.data.receivedBy);
				$tdList.eq(c++).text(node.data.deliveredBy);
				if (featureEnabled("ntak")) {
					$tdList.eq(c).find("div[title]").html(node.data.ntakStatus);
					$tdList.eq(c).find("div[title]").prop("title", JSON.stringify(node.data.ntakValasz));
					//$tdList.eq(c).find("div[title]").tooltip();
					if (node.data.ntakStatus && node.data.ntakStatus !== "TELJESEN_SIKERES") {
						$tdList.eq(c).find("div.icon-arrows-ccw").removeClass("hidden");
					}
					c++;
				}
				if (!Boolean(node.data.isActive)) {
					$tdList.addClass('itemDeactivated');
					$tdList.eq(c++).addClass("icon-edit");
				}

				// $tdList.eq(1)
			}
		});
		tableOccupations.r.filterNodes(function (node) {
			if (deleted && node.data.deletedOrderCount === 0)
				return false;
			if (active && !node.data.isActive)
				return false;
			if (tableOccupations.orderedBy) {
				return node.data.orderedBy.filter(o => o.toLowerCase().indexOf(tableOccupations.orderedBy.toLowerCase()) !== -1).length > 0;
			}
			if (tableOccupations.receivedBy) {
				return node.data.receivedBy.filter(o => o.toLowerCase().indexOf(tableOccupations.receivedBy.toLowerCase()) !== -1).length > 0;
			}
			if (tableOccupations.for_whom) {
				if (node.data.bookedProperties) {
					var r = node.data.bookedProperties && node.data.bookedProperties.name && node.data.bookedProperties.name.toLowerCase().indexOf(tableOccupations.for_whom.toLowerCase()) >= 0;
					if (r) return r;
					r = node.data.bookedProperties && node.data.bookedProperties.surname && node.data.bookedProperties.surname.toLowerCase().indexOf(tableOccupations.for_whom.toLowerCase()) >= 0;
					if (r) return r;
					r = node.data.bookedProperties && node.data.bookedProperties.address && node.data.bookedProperties.address.toLowerCase().indexOf(tableOccupations.for_whom.toLowerCase()) >= 0;
					if (r) return r;
					r = node.data.bookedProperties && node.data.bookedProperties.phone && node.data.bookedProperties.phone.toLowerCase().indexOf(tableOccupations.for_whom.toLowerCase()) >= 0;
					if (r) return r;
				}
				return false;
			}
			return true;
		});

	} else {
		tableOccupations.r.reload(tableOccupationsD);
	}
	//_resizefloatTableHead("#tableOccupationsFreezeTable");
}

var orderSelected = null;
var paymentSelected = null;
function processOrders(data) {
	if (typeof data != "undefined") {
		tableOccupations.tableOccupationData = data;
		orderSelected = null;
	}

	var ordersByMenuItem = new SortedSet({
		comparator: menuItemComparator
	});
	tableOccupations.tableOccupationData.orders.filter(o => o.state !== "sselected").forEach(function (v, i) {
		var order = SortedSet.find(ordersByMenuItem, v);
		if (order == null) {
			v.count = 1;
			var v2;
			ordersByMenuItem.insert(v2 = Object.assign({}, v));
			v2.title = "";
		} else {
			if (order.children) {
			} else {
				v2 = Object.assign({}, order);
				order.children = [v2];
			}
			order.children.push(v2 = Object.assign({}, v));
			v2.count = 1;
			v2.title = "";
			order.count += 1;
		}
	});
	var ordersByMenuItemArray = [];
	ordersByMenuItem.forEach(function (v, i) {
		if (v.children) {
			v.children.forEach(function (c) {
				c.childOrders.forEach(function (co) {
					co.title = (co.addition ? 1 : -1) * co.quantity;
					co.childOrder = true;
				});
				c.children = c.childOrders;

			})
		} else {
			v.childOrders.forEach(function (co) {
				co.title = (co.addition ? 1 : -1) * co.quantity;
				co.childOrder = true;
			});
			v.children = v.childOrders;
		}
		ordersByMenuItemArray.push(v);
	});

	ordersByMenuItemArray.forEach(function (v, i) {
		v.title = null;
	});

	if (!(tableOccupations.orders)) {
		tableOccupations.orders = createTree("table#orders", {
			checkbox: false,
			source: ordersByMenuItemArray,
			zindex: 1000,
			extensions: ["table", "gridnav", "filter"],
			selectMode: 1,
			filter: {
				counter: false,
				mode: "dimm"
			},
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				// column index (default: nodeColumnIdx)
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			icon: false,
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},

			// renderStatusColumns: false, // false: default renderer
			// true: generate renderColumns events, even for status nodes
			// function: specific callback for status nodes
			dblclick: function (event, data) {
				// A node was activated: display its title:
				orderSelected = null;
				paymentSelected = null;
				processOrders();
				processPayments();
			},
			activate: function (event, data) {
				// A node was activated: display its title:
				var node = data.node;
				orderSelected = node.data;
				paymentSelected = null;
				processPayments();
			},

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = false;
				var c = 1;
				$tdList.eq(c++).text(node.data.count);
				$tdList.eq(c++).text(admin_local[node.data.menuItem.type]);
				$tdList.eq(c++).text(node.data.menuItem.name + " " + (node.data.todeliver ? "(" + moment(node.data.todeliver).format("MM-DD") : "") + ")");

				var restaurantComment = node.data.restaurantComment;

				if (restaurantComment && restaurantComment.startsWith("$"))
					restaurantComment = local.rejection_reason[restaurantComment.substring(1)];

				$tdList.eq(c++).text(restaurantComment ? restaurantComment : node.data.comment);
				$tdList.eq(c++).text(node.data.quantityLabel + (node.data.menuItem.freeQuantity ? " (" + node.data.quantity + " " + local[node.data.menuItem.quantityType] + ")" : ""));
				$tdList.eq(c++).text(getLocale2(auth.myStatus.restaurant_settings.timings[node.data.timing].langs).name);
				$tdList.eq(c++).text(moment(node.data.created).format("HH:mm:ss"));
				$tdList.eq(c++).text(local[node.data.state]);
				if (node.data.recordState === "DELETED" || node.data.state === "cancelled") {
					$(node.tr).css("text-decoration", "line-through");
				}
				$tdList.eq(c++).text(node.data.recordState === "DELETED" || node.data.state === "cancelled" ? admin_local["yes"] : '');
				$tdList.eq(c++).html(node.data.orderedByCustomer ? "<i>" + node.data.orderedByName + "</i>" : node.data.orderedByName);
				$tdList.eq(c++).text(node.data.orderSequenceNumber);
				$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.addition ? 1 : -1) * node.data.fullPrice));
				$tdList.eq(c++).text(NumeralFormatter.format("" + (-1 * (node.data.discount + node.data.tableMateDiscount))));
				var payments = "";
				var paymentTypes = "";
				tableOccupations.tableOccupationData.tableMatePayments.forEach(function (tableMatePayment) {
					if (Number(node.data.tableMatePaymentId) === Number(tableMatePayment.id)) {
						payments += payments !== '' ? ", " : "";
						payments += String.format(local.unknown_person, tableMatePayment.tableMate.seqNr);
						tableMatePayment.payments.forEach(function (payment) {
							paymentTypes += paymentTypes !== '' ? "<br/> " : "";
							paymentTypes += NumeralFormatter.format("" + (payment.amount)) + " " + payment.currency.name + " " + (payment.paymentMethod !== null ? payment.paymentMethod.name : "N/A");
						});
					}
				});
				$tdList.eq(c++).text(payments);
				$tdList.eq(c++).html(paymentTypes);

				if (node.data.childOrder)
					$tdList.addClass('childOrder');
				if (node.data.state === "cancelled" || node.data.recordState === "DELETED")
					$tdList.addClass('deletedOrder');
			},

		});
		if (tableOccupations.orders)
			tableOccupations.orders.filterNodes(function (node) {
				if (typeof paymentSelected === "undefined" || paymentSelected == null)
					return true;
				return paymentSelected.id === node.data.tableMatePaymentId;
			});

	} else
		tableOccupations.orders.reload(ordersByMenuItemArray);

	//_resizefloatTableHead("#ordersFreezeTable");
}

function processPayments(data) {
	if (typeof data != "undefined")
		tableOccupations.tableOccupationData = data;
	tableOccupations.tableOccupationData.tableMatePayments.forEach(function (tableMatePayment) {
		if (tableMatePayment.payments.length > 1) {
			tableMatePayment.payments.forEach(function (p) {
				p.title = "";
			});
			tableMatePayment.children = tableMatePayment.payments;
			tableMatePayment.expanded = true;
		}
		tableMatePayment.title = "";
	});
	if (tableOccupations.payments == null) {
		tableOccupations.payments = createTree("table#payments", {
			checkbox: false,
			source: tableOccupations.tableOccupationData.tableMatePayments,
			zindex: 1000,
			extensions: ["table", "gridnav", "filter"],
			filter: {
				counter: true,
				mode: "dimm"
			},
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				// column index (default: nodeColumnIdx)
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			icon: false,
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},

			// renderStatusColumns: false, // false: default renderer
			// true: generate renderColumns events, even for status nodes
			// function: specific callback for status nodes
			dblclick: function (event, data) {
				// A node was activated: display its title:
				paymentSelected = null;
				orderSelected = null;
				processPayments();
				processOrders();
			},
			activate: function (event, data) {
				// A node was activated: display its title:
				var node = data.node;
				paymentSelected = node.data;
				orderSelected = null;
				processOrders();
			},

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");
				if (node.data.payments) {
					var c = 1;
					$(node.tr).data("key", node.data.id);
					$tdList.eq(c++).addClass("icon-printed");
					$tdList.eq(c - 1).attr('tableMatePaymentId', node.data.id);
					$tdList.eq(c++).addClass("icon-dollar");
					$tdList.eq(c - 1).data('payment', node.data);
					$tdList.eq(c++).addClass("icon-cancel");
					$tdList.eq(c++).text(String.format(local.unknown_person, node.data.tableMate.seqNr));
					$tdList.eq(c++).text(node.data.payments.length === 0 ? "" : moment(node.data.payments[0].date).format("YY-MM-DD"));
					$tdList.eq(c++).text(node.data.payments.length === 0 ? "" : formatTime2(new Date(node.data.payments[0].date)));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.totalAmountPayable));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.totalAmountPaid));
					if (node.data.payments.length === 1) {
						$tdList.eq(c++).text(node.data.payments[0].paymentMethod !== null ? node.data.payments[0].paymentMethod.name : "N/A");
						$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.payments[0].amount * node.data.payments[0].rate)));
						$tdList.eq(c++).text(node.data.payments[0].currency.name);
						$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.payments[0].rate !== 1 ? node.data.payments[0].rate : "")));
					} else c += 4;
					$tdList.eq(c++).text(node.data.receivedByName);
				} else {
					c = 2;
					$tdList.eq(c++).text("");
					$tdList.eq(c++).text("");
					$tdList.eq(c++).text("");
					$tdList.eq(c++).text("");
					$tdList.eq(c++).text("");
					$tdList.eq(c++).text(node.data.paymentMethod !== null ? node.data.paymentMethod.name : "");
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.amount * node.data.rate)));
					$tdList.eq(c++).text(node.data.currency.name);
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.rate !== 1 ? node.data.rate : "")));
					$tdList.addClass('childOrder');
				}
			},

		});
		tableOccupations.payments.filterNodes(function (node) {
			if (typeof orderSelected === "undefined" || orderSelected == null)
				return true;
			if (node.data.payments)
				return Number(orderSelected.tableMatePaymentId) === Number(node.data.id);
			return true;
		});

	} else
		tableOccupations.payments.reload(tableOccupations.tableOccupationData.tableMatePayments);

	//_resizefloatTableHead("#paymentsFreezeTable");
}

function formatTime(data) {
	var h = ('0' + Math.floor(data / 60));
	var m = ('0' + Math.floor(data % 60));
	return h.substring(h.length - 2, h.length) + ":" + m.substring(m.length - 2, m.length);
}

function formatTime2(data) {
	var h = ('0' + Math.round(data.getHours()));
	var m = ('0' + data.getMinutes());
	return h.substring(h.length - 2, h.length) + ":" + m.substring(m.length - 2, m.length);
}

function menuItemComparator(a, b) {
	var aMenuItem = a.menuItem;
	var bMenuItem = b.menuItem;
	if (a.state !== b.state) {
		return allstates.indexOf(a.state) - allstates.indexOf(b.state);
	}
	if (a.todeliver && b.todeliver && a.todeliver === b.todeliver) {
		if (aMenuItem !== null && bMenuItem !== null && aMenuItem.type === bMenuItem.type) {
			if (aMenuItem.id === bMenuItem.id) {
				if (a.quantity === b.quantity) {
					if (a.comment === b.comment) {
						if (a.restaurantComment === b.restaurantComment) {
							if (compareChildOrders(a, b) === 0) {
								if (a.discountComment === b.discountComment) {
									if (a.selected_order_discountComment === b.selected_order_discountComment) {
										if (a.paid === b.paid) {
											if (a.tableMatePaymentId === b.tableMatePaymentId) {
												return 0;
											} else {
												return a.tableMatePaymentId - b.tableMatePaymentId;
											}
										}
										else if (a.paid)
											return -1;
										return 1;
									} else {
										return a.selected_order_discountComment.localeCompare(b.selected_order_discountComment);
									}
								} else {
									return a.discountComment.localeCompare(b.discountComment);
								}
							} else {
								return compareChildOrders(a, b);
							}
						} else {
							return a.restaurantComment.localeCompare(b.restaurantComment);
						}
					} else {
						return a.comment.localeCompare(b.comment);
					}
				} else {
					return a.quantity - b.quantity;
				}
			}
		}
	} else {
		if (a.todeliver && !b.todeliver) {
			return -1;
		} else if (!a.todeliver && b.todeliver) {
			return 1;
		}
		return a.todeliver - b.todeliver;
	}
	if (aMenuItem.type && bMenuItem.type && aMenuItem.type.localeCompare(bMenuItem.type) !== 0)
		return aMenuItem.type.localeCompare(bMenuItem.type);
	else
		return aMenuItem.name.localeCompare(bMenuItem.name);
}

function compareChildOrders(a, b) {
	if (a.childOrders.length === 0 && b.childOrders.length === 0)
		return 0;
	if (a.childOrders.length !== b.childOrders.length)
		return a.childOrders.length - b.childOrders.length;
	var aChildren = new SortedSet({
		comparator: menuItemComparator
	});
	var bChildren = new SortedSet({
		comparator: menuItemComparator
	});
	a.childOrders.forEach(function (o) {
		if (SortedSet.find(aChildren, o) === null)
			aChildren.insert(o)
	});
	b.childOrders.forEach(function (o) {
		if (SortedSet.find(bChildren, o) === null)
			bChildren.insert(o)
	});
	var aChildrenArray = aChildren.toArray();
	var bChildrenArray = bChildren.toArray();
	var r = 0;
	aChildrenArray.forEach(function (a, index) {
		var b = bChildrenArray[index];
		if (r !== 0)
			return;
		r = menuItemComparator(a, b);
	})
	return r;
}

export const reprintInvoice = (item) => {
	const def = new $.Deferred();
	admin.reprintInvoice($(item).attr('tableMatePaymentId'), localStorage.language, function (data) {
		if (data === '{"success":"true"}') {
			if ($(item).attr('orderIds')) {
				$(item).attr('orderIds').split(",").map(o => getOrderById(o)).forEach(o => o.wasMateSelectedForPayment = true);
			}
			def.resolve();
		} else
			printHtml(data, admin.getPrinterForPrinterAction("printInvoice").id);
	});
	return def.promise();
}

var payment;

export const repayInvoice = (item) => {
	payment = $(item).data('payment');
	confirmDialog(local.confirmation, admin_local.repay_invoice_dialog).done(tableMatePaid);
}

export const tableMatePaid = () => {
	redoTableMatePaid(payment, tableOccupations.tableOccupationData.orders);
}

export const redoTableMatePaid = (paymentt, orderss, handler = reload) => {

	var modal = $("#tableMatePaidDialog");
	modal.empty();

	orders = orderss;
	payment = paymentt;

	var restaurant_payment_methods = [];
	auth.myStatus.restaurant_payment_methods.forEach(function (paymentMethod) {
		if (!paymentMethod.method.isOnline) restaurant_payment_methods.push(paymentMethod);
	});

	/*
	var amount = payment.totalAmountPayable - payment.tableMate.discount;
	if (payment.tableMate.service && payment.tableMate.service !== "0")
		amount = payment.tableMate.service.indexOf("%") != -1 ? (amount * 100 / (100 + Number(payment.tableMate.service.split("%")[0]))) : amount + Number(payment.tableMate);

	amount = Math.round(amount);
	*/

	//var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
	//eslint-disable-next-line no-eval
	//const roundedAmount = eval(roundFunction);

	const tableMateChoices = [{ ...payment.tableMate, amountToPay: payment.totalAmountPayable, selected: true, paymentId: payment.id }];
	window.setAmount = setAmount;
	window.amountChanged = amountChanged;

	$("#tableMatePaidTemplate")
		.tmpl({
			tableMates: tableMateChoices,
			restaurant_payment_methods: restaurant_payment_methods,
			repay: true,
			preselectedPaymentMethod: payment.payment_method,
			...tmplparams()
		})
		.appendTo(modal);

	modal = $("#tableMatePaidDialog #tableMatePaid");
	loadMyImage();


	modal.modal("show", {
		keyboard: false
	});

	payment.payments.forEach(p => {
		$("#tableMatePaidDialog li.mate .input-group.amountPaid[paymentmethod = '" + p.paymentMethod.id + "'] input").val(p.amount);
	});



	$("#tableMatePaid.modal button#save").click(function (event) {
		avoidMultipleClick(this, event);

		function ff() {
			_tableMatePaid({
				modal, tableMateChoices, tableOccupation: payment.tableMate.tableOccupationId, orderss, handler: (data) => {
					$("#tableMatePaid.modal").modal("hide");
					$("#tableMatePaid.modal").hide();
					tableOccupations.oldSearch = "";
					handler(data);
				}
			});
		}

		if ($(event.target).hasClass("btn-danger")) confirmDialog(local.confirmation, admin_local.tip_amount_seems_excesive).done(ff);
		else ff();
	});

};

var orders = [];

export const setAmount = t => {
	var amountToPay = $($(t).parents("li")[0]).attr("amountToPay");
	var paymentMethod = getPaymentMethod($(t).parent().parent().attr("paymentmethod"));
	var currencyid = $(t).parents('li').find(".paymentCurrency").val();
	var currency = auth.myStatus.currencies.find(c => c.id == currencyid);
	if (currency.javaScriptRoundFunction && paymentMethod.method.type === "cash") {
		amountToPay = eval(currency.javaScriptRoundFunction.replace("amount", amountToPay));
	}
	//console.log(currency, paymentMethod);
	$(t).parent().parent().parent().find("input").each((ind, t) => {
		//if (t.value === amountToPay)
		$(t).val("");
	})
	$(t).parent().parent().find("input").val(
		Math.ceil(100 * amountToPay / $(t).parent().parent().find("input").attr("exchangerate")) / 100
	);
	$(t).parent().parent().find("input").focus();
	checkPayments(orders.filter(o => Number(o.tableMatePaymentId) === Number(payment.id)), payment);
};

export const setAmount2 = t => {
	var amountToPay = $($(t).parents("li")[0]).attr("amountToPay");
	$(t).parent().parent().parent().find("input").each((ind, t) => {
		if (t.value === amountToPay)
			$(t).val("");
	})
	$(t).parent().parent().find("input").val(
		Math.ceil(100 * amountToPay / $(t).parent().parent().find("input").attr("exchangerate")) / 100
	);
	$(t).parent().parent().find("input").focus();
	checkPayments(orders.filter(o => Number(o.tableMatePaymentId) === Number(payment.id)), payment);
};

export const amountChanged = (t, e) => {
	if (t && t.value)
		t.value = t.value.replace(/\D/g, "");
	var nextInput = $($(t).parent().nextAll("div.amountPaid")).find("input");
	var amountToPay = $(t).attr("amountToPay");
	var amountPaid = $(t).val();
	var amountPaidExchangeRate = $(t).attr("exchangeRate");
	var amountRemaingExchangeRate = nextInput.attr("exchangeRate");
	nextInput.attr("placeholder", Math.max(0, Math.round((100 * (amountToPay - amountPaid * amountPaidExchangeRate)) / amountRemaingExchangeRate) / 100));
	nextInput.attr("amountToPay", Math.round(100 * Math.max(0, amountToPay - amountPaid * amountPaidExchangeRate)) / 100);
	checkPayments(orders.filter(o => Number(o.tableMatePaymentId) === Number(payment.id)), payment);
}

export const cashierChanged = () => {
	if (payment || tableOccupations && tableOccupations.tableOccupationData) {
		checkPayments(orders.filter(o => Number(o.tableMatePaymentId) === Number(payment.id)), payment);
	}
}

export const checkPayments2 = () => {
	var ok = true;
	var toohigh = false;
	let count = 0;
	let pcount = 0;
	var allDelivered = true;
	$("#tableMatePaidDialog li.mate").each((ind, m) => {
		count++;
		var currentAmount = 0;
		var mateId = $(m).attr("id");
		if ($("div#pos-qrcode" + mateId + " canvas").length > 0)
			ReactDOM.unmountComponentAtNode($("div#pos-qrcode" + mateId).get(0));

		if (!$(m).hasClass("active")) return;
		pcount++;

		var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
		// eslint-disable-next-line no-unused-vars
		var amount = $(m).attr("amountToPay");
		// eslint-disable-next-line no-eval
		const amountToPay = eval(roundFunction);

		var amountPaidExchangeRate = 1;

		$(m)
			.find("input")
			.each(function (ind, payment) {
				if (Number($(payment).val()) > 0) {
					amountPaidExchangeRate = $(payment).attr("exchangeRate");
					currentAmount += Number($(payment).val() * amountPaidExchangeRate);
				}
			});

		var tip = 0;
		amount = currentAmount - amountToPay;
		// eslint-disable-next-line no-eval
		tip = eval(roundFunction);

		$(m)
			.find("text#tip")
			.text(Number(Math.max(0, tip)));
		ok = ok && Math.round(currentAmount * 100) >= Math.round(amountToPay * 100);
		toohigh = toohigh || currentAmount > amountToPay * 3;

	});

	var allbuttons = $("#tableMatePaid button#save,#tableMatePaid button#save-and-unlock,#tableMatePaid button#print");
	var buttons = $("#tableMatePaid button#save,#tableMatePaid button#print");
	var buttons2 = $("#tableMatePaid button#save-and-unlock");

	const autoDeliverOnUnlock = auth.myStatus.restaurant_settings["tables-overview"]["autoDeliverOnUnlock"];
	allbuttons.prop("disabled", "true");
	if (ok) {
		if (toohigh) {
			buttons.removeAttr("disabled");
			buttons.removeClass("btn-negative");
			buttons.removeClass("btn-positive");
			buttons.addClass("btn-danger");
			if (count === pcount && (allDelivered || autoDeliverOnUnlock)) {
				buttons2.removeAttr("disabled");
				buttons2.removeClass("btn-positive");
				buttons2.removeClass("btn-negative");
				buttons2.addClass("btn-danger");
			}
		} else {
			buttons.removeAttr("disabled");
			buttons.removeClass("btn-negative");
			buttons.removeClass("btn-danger");
			buttons.addClass("btn-positive");
			if (count === pcount && (allDelivered || autoDeliverOnUnlock)) {
				buttons2.removeAttr("disabled");
				buttons2.removeClass("btn-negative");
				buttons2.removeClass("btn-danger");
				buttons2.addClass("btn-positive");
			}
		}
	} else {
		allbuttons.prop("disabled", "true");
		allbuttons.addClass("btn-negative");
		allbuttons.removeClass("btn-positive");
		allbuttons.removeClass("btn-danger");
	}
};

export const changeView = (view) => {
	localStorage.tableOccupations_view = view;
	$('div#toppart > *').addClass('hidden');
	$('div#toppart > #' + view).removeClass('hidden');
	if (view === 'calendar')
		calendar.updateSize();
}

export const changeFilterBy = (view) => {
	localStorage.tableOccupations_filterBy = view;
	reload();
}

export const modifyTableOccupation = (item) => {
	const tableOccupation = $(item).parent().data("data").id;
	console.log($(item).parent().data("data"));
	if ($(item).parent().data("data").isActive)
		return;
	admin.getTableOccupationWithOrders(tableOccupation, data => {
		if (data.error) {
			return;
		}
		if (data[0].tableMatePayments.length === 0) {
			confirmDialog(local.confirmation, admin_local.revert_table_occupation).done(() => {
				get("adminService/" + sessionStorage.restaurantSelected + "/revertTableOccupation/" + tableOccupation + "/false").done(() => {
					tableOccupations.oldSearch = null;
					reload();
				})
			});
		} else if (data[0].tableMatePayments.length === 1) {
			choiceDialog(local.confirmation, admin_local.revert_table_occupation, admin_local.revert, admin_local.revert_table_mate_payment).done(choice => {
				get("adminService/" + sessionStorage.restaurantSelected + "/revertTableOccupation/" + tableOccupation + "/" + (choice === 1)).done(() => {
					tableOccupations.oldSearch = null;
					reload();
				})

			});
		} else {
			confirmDialog(local.confirmation, admin_local.revert_table_occupation_multi_payment).done(() => {
				get("adminService/" + sessionStorage.restaurantSelected + "/revertTableOccupation/" + tableOccupation + "/false").done(() => {
					tableOccupations.oldSearch = null;
					reload();
				})
			});
		}
	});
}
export const revertPayment = (item) => {
	const payment = $(item).parent().data("key");
	if (payment)
		confirmDialog(local.confirmation, admin_local.revert_payment).done(choice => {
			get("adminService/" + sessionStorage.restaurantSelected + "/revertTableMatePayment/" + payment).done(() => {
				tableOccupations.oldSearch = null;
				reload();
			})
		});

}

export const resendNtak = (item) => {
	const tableOccupation = $(item).parent().parent().parent().data("data").id;
	if (tableOccupation)
		get("adminService/" + sessionStorage.restaurantSelected + "/resendTableMatePayment/" + tableOccupation).done(() => {
			tableOccupations.oldSearch = null;
			reload();
		})
}
