import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { I18n, Translate } from "react-redux-i18n";
import Select from 'react-select';
import languages from "../langs/languages";
import { MuiThemeProvider } from "material-ui/styles";
import { MuiKeyboard } from "@autosys/react-mui-keyboard";
import { cashin_fiscat } from "../js/cashiers/fiscat";
import $ from "jquery";
import auth, { post } from "../js/auth";
import { sendToCashier } from "../js/tableMatePaid";
import { ThreeSixty } from "@material-ui/icons";
import { cashin_a234 } from "../js/cashiers/a234";
import { cashin_sam4s } from "../js/cashiers/sam4s";
const { admin_local } = languages;

class CashinDialog extends Component {
  state = {
    show: this.props.show,
    reason: 0,
    amount: "",
  };

  constructor(props) {
    super(props);
    this.state.show = this.props.show;
  }

  unmount() {
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  componentDidMount() {
  }

  inputChange = value => {
    this.setState({ amount: value(this.state.amount) })
  }

  send = () => {
    var cashregister = Number($("#cashregisters input:checked").prop("id"));
    const c = auth.myStatus.restaurant_cash_registers.find(r => r.id === cashregister);
    var text = "";
    switch (c.type) {
      case "fiscat":
      case "fiscat2":
      case "fiscat3":
      case "fiscat3a":
      case "fiscat4":
      case "fiscat5":
        text = cashin_fiscat(this.state.reason, this.state.amount);
        break;
      case "sam4s":
        text = cashin_sam4s(this.state.reason, this.state.amount);;
        break;
      case "cashcube":
        //text = dayClose_cashcube();
        break;
      case "jota_m":
        //text = dayClose_jotam();
        break;
      case "sento":
        //text = dayClose_sento();
        break;
      case "a234":
        text = cashin_a234(this.state.reason, this.state.amount);
        break;
      default:
    }
    if (this.state.reason && this.state.amount && text) {
      if (c.format === "http" || c.format === "rs232")
        post("adminService/" + sessionStorage.restaurantSelected + "/sendToCashier?cashregister=" + (c ? c.id : 0) + "&lang=" + localStorage.language, text).done(data => {
          this.unmount();
        });
      else {
        sendToCashier(c, text);
        this.unmount();
      }

    }
  }

  render = () => {

    var cashregister = Number($("#cashregisters input:checked").prop("id"));
    const c = auth.myStatus.restaurant_cash_registers.find(r => r.id === cashregister);

    var type = c.type;

    if (type.startsWith("fiscat")) {
      type = "fiscat";
    }

    const options = Object.keys(admin_local.cashin[type]).map(key => { return { value: key, label: admin_local.cashin[type][key] } });

    if (this.state.show === false) {
      return null;
    }
    return (
      <Modal style={{ zIndex: 10000 }} show={this.state.show} onHide={this.props.def.reject} centered >
        <Modal.Header className="eatwithme no-close">
          <h1>{I18n.t("admin_local.cashin.title")}</h1>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "27px" }}>
          <Form.Group className="mb-3">
            <Select id="reason"
              onChange={this.optionChange}
              options={options}
              placeholder={I18n.t("admin_local.cashin.reason")}
            />
            <Form.Control onChange={this.change} id="amount" type="text" value={this.state.amount} placeholder={I18n.t("admin_local.amount")} />
            <MuiThemeProvider>
              <MuiKeyboard
                slide
                direction="up"
                checked={true}
                setInputValue={this.inputChange}
                numbers={['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0']} // Assuming numbers and other variables are defined
                firstLanguage={null}
                firstLangLabel="EN"
                numbersRows="3"
                numbersColumns={this.props.type === "number" ? 3 : 12}
                keyboardWidth={this.props.type === "number" ? '8rem' : 'auto'}
                buttonSize={this.props.type === "number" ? "LARGE" : "medium"}
                sx={{ display: 'flex' }}
              />
            </MuiThemeProvider>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {this.props.buttons.map((button, ind) => {
            return (
              <Button
                key={ind}
                onClick={() => {
                  this.send();
                }}
              >
                <Translate value={button} />
              </Button>
            );
          })}
          <Button
            variant="danger"
            onClick={() => {
              this.unmount();
            }}
          >
            <Translate value="local.cancel" />
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
  optionChange = (event) => {
    this.setState({ reason: event.value });

  }
  change = (event) => {
    const state = {};
    state[event.currentTarget.id] = event.currentTarget.value;
    this.setState(state)

  }
  apchange = (event) => {
    const state = {};
    state[event.currentTarget.id] = event.currentTarget.value;
    localStorage.ap = event.currentTarget.value;
    this.setState(state)

  }
}

export default CashinDialog;
