import $ from "jquery";
import auth, { startpoll, confirmDialog, get, post, scanbarcode, scaleMeasure, getGlobal, getString, getLocale } from "../js/auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../js/order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../components/EntityPageCode";
import admin, { resultHandler, getMenuItem } from "../js/admin";
import { capturePhotoWithData, crop, onPhotoDataSuccess } from "../js/camera-util";

class BarcodesCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveBarcode', 'deleteBarcode', 'capturePhotoWithData', 'crop', 'phoneScanBarcode', 'scaleMeasure', 'setSelectedScale', 'calculate', 'syncBarcodes', 'downloadBarcodes', 'uploadBarcodes', 'isInventoryDisposalItem']);
	}

	clearData = () => {

	}

	filter = {}

	ready = () => {

		if (auth.myStatus.restaurant_scales && auth.myStatus.restaurant_scales.length > 0 && (typeof localStorage.selectedScale === "undefined" || auth.myStatus.restaurant_scales.filter(s => s.name === localStorage.selectedScale).length === 0))
			localStorage.selectedScale = auth.myStatus.restaurant_scales[0].name;

		$('#main').replaceWith($('#barcodesMainTemplate').tmpl({
			...tmplparams()
		}));

		admin.getMenuItems(this.loadBarcodes);

		startpoll(this.serverSideMessageHandler);

		admin.initializeSelect2($('select.select2')).done(() => {
		});

		this.updateHooks();

	}

	isInventoryDisposalItem = (data) => {
		return data.defaultInventoryItem;
	}

	reload = () => {
		this.loadBarcodes();
	}

	loadBarcodes = () => {
		this.getBarcodes(data => { this.processBarcodes(data); this.syncBarcodes(); this.barcodes.selectAll(false) });
	}

	serverSideMessageHandler(data) {
	}

	filterNodes = node => {
		if (this.filter.barcode && ("" + node.data.id).indexOf(this.filter.barcode) === -1) {
			return false;
		}
		if (this.filter.serving_type && ("" + node.data.quantity).indexOf(this.filter.serving_type) === -1) {
			return false;
		}
		if (this.filter.name && node.data.name.indexOf(this.filter.name) === -1) {
			return false;
		}
		if (this.filter.linked_product && (!node.data.linked_product || node.data.linked_product.indexOf(this.filter.linked_product) === -1)) {
			return false;
		}
		return true;
	}

	barcodes = null;
	barcodesData = null;
	localData = null;
	processBarcodes = (data) => {
		this.barcodesData = data;
		data.forEach(function (v, i) {
			v.key = v.id;
			v.title = v.number;
			v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";
			if (v.shareable)
				v.extraClasses += " itemShareable";
			if (v.menuItem)
				v.linked_product = getLocale(getMenuItem(v.menuItem.id).name)
		});
		if (this.barcodes == null) {
			this.barcodes = createTree("table#barcodes", {
				checkbox: true,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist", "filter"],
				filter: {
					counter: false,
					mode: "hide"
				},
				persist: {
					cookieDelimiter: "~",
					overrideSource: true,
					types: "active focus selected",
					cookiePrefix: 'prefix-barcodes-',
					store: 'local',
				},
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 2
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				click: (event, data) => {
					if (this.clickTimestamp && this.selectedNodeKey === data.node.key && new Date().getTime() - this.clickTimestamp < 1000) {
						$('button#edit').click();
					};
					this.clickTimestamp = new Date().getTime();
					this.selectedNodeKey = data.node.key;
				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");
					node.expanded = true;
					if (node.data.image) {
						var imageUrl = (node.data.status === "new" ? auth.globalserver : auth.server) + "/" + auth.war + "/adminService/" + (node.data.status === "new" ? 2 : sessionStorage.restaurantSelected) + "/getImage/" + node.data.image + "?a=" + (new Date().getTime() + Math.random()) /*+ node.data.image*/
						//var imageUrl = (node.data.status === "new" ? auth.globalserver : auth.server) + "/" + auth.war + "/adminService/restaurantService/isAlive?id=" + node.data.image;


						function onVisible(element, callback) {
							new IntersectionObserver((entries, observer) => {
								entries.forEach(entry => {
									if (entry.intersectionRatio > 0) {
										callback(element);
										observer.disconnect();
									}
								});
							}).observe(element);
							if (!callback) return new Promise(r => callback = r);
						}
						onVisible(node.tr, () => {
							setTimeout(() => {
								$tdList.eq(1).find('img').attr('src', imageUrl);
							}, Math.random() * 1000);
						})


						//$tdList.eq(1).find('img').attr('src', (node.data.status === "new" ? auth.globalserver : auth.server) + "/" + auth.war + "/adminService/" + (node.data.status === "new" ? 2 : sessionStorage.restaurantSelected) + "/getImage/" + node.data.image);
						/*if ($tdList.eq(1).find('img').attr('src') != imageUrl)
							$tdList.eq(1).find('img').attr('src', imageUrl);*/
					}
					$tdList.eq(2).text(node.data.id);
					$tdList.eq(3).text(node.data.name);
					$tdList.eq(4).text(node.data.quantity);
					$tdList.eq(5).text(node.data.grossWeight);
					$tdList.eq(6).text(node.data.tareWeight);
					switch (node.data.status) {
						case "new":
							$tdList.css("background", "yellow");
							$tdList.eq(2).html(I18n.t("admin_local.new_in_central_database") + "<br/>" + node.data.id);
							break;
						case "equal":
							$tdList.css("background", "lightgreen");
							break;
						case "notequal":
							$tdList.css("background", "red");
							$tdList.css("color", "white");
							$tdList.eq(2).html(I18n.t("admin_local.exists_in_central_database") + "<br/>" + node.data.id);
							if (node.data.name != node.data.global.name) {
								$tdList.eq(3).html(node.data.name + "(" + I18n.t("admin_local.local_value") + ")<br/>" + node.data.global.name + "(" + I18n.t("admin_local.global_value") + ")");
							}
							if (node.data.quantity != node.data.global.quantity) {
								$tdList.eq(4).html(node.data.quantity + "(" + I18n.t("admin_local.local_value") + ")<br/>" + node.data.global.quantity + "(" + I18n.t("admin_local.global_value") + ")");
							}
							if (node.data.grossWeight != node.data.global.grossWeight) {
								$tdList.eq(5).html(node.data.grossWeight + "(" + I18n.t("admin_local.local_value") + ")<br/>" + node.data.global.grossWeight + "(" + I18n.t("admin_local.global_value") + ")");
							}
							if (node.data.tareWeight != node.data.global.tareWeight) {
								$tdList.eq(6).html(node.data.tareWeight + "(" + I18n.t("admin_local.local_value") + ")<br/>" + node.data.global.tareWeight + "(" + I18n.t("admin_local.global_value") + ")");
							}
							break;
						case "local":
							$tdList.eq(2).html(I18n.t("admin_local.doesnt_exists_in_central_database") + "<br/>" + node.data.id);
							break;
						default:
					}
					if (node.data.linked_product) {
						$tdList.eq(7).text(node.data.linked_product);
					}
				},

			});
		} else {
			this.barcodes.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editBarcode').on('show.bs.modal', function (event) {
			var modal = $(this)

			instance.modalOpen = modal;
			$("body").off("paste");
			$("body").on("paste", instance.paste);

			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			if (recipient === 'edit') {
				var data = instance.barcodes.getActiveNode().data;
				modal.find('input#id').val(data.id);
				modal.find('input#id').prop('readonly', true);
				modal.find('input#name').val(data.name);
				modal.find('input#quantity').val(data.quantity);
				modal.find('input#tare-weight').val(data.tareWeight);
				modal.find('input#gross-weight').val(data.grossWeight);
				if (data.image && data.image !== "") {
					modal.find("#smallImage").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + data.image);
					$(modal.find("#smallImageDiv")).removeClass("hidden");
				} else {
					modal.find("#smallImage").attr("src", "");
					// $(modal.find('#smallImageDiv')).addClass('hidden');
				}
				modal.find('input#gross-weight').val(data.grossWeight);
				if (data.menuItem && data.menuItem.id) {
					modal.find('select.product').select2().val(data.menuItem.id);
					modal.find('select.product').trigger('change');
				} else {
					modal.find('select.product').select2().val(null);
					modal.find('select.product').trigger('change');
				}

			} else {
				modal.find('input#id').val("");
				modal.find('input#id').prop('readonly', false);
				modal.find('input#name').val("");
				modal.find('input#quantity').val("");
				modal.find('input#tare-weight').val("");
				modal.find('input#gross-weight').val("");
				modal.find("#smallImage").attr("src", "");
				modal.find('select.product').select2().val(null);
				modal.find('select.product').trigger('change');
			}
		})

		$('div#editBarcode').on('hide.bs.modal', function (event) {
			var modal = $(this);
			modal.find("#smallImage").attr("src", "");
		});

		$("tr th input").on("keyup", (event) => {
			this.filter[event.currentTarget.id] = event.currentTarget.value;
			this.barcodes.filterNodes(this.filterNodes, true);
		})

	}

	getBarcodes(handler, restaurantId) {
		get("adminService/" + (typeof restaurantId === "undefined" ? sessionStorage.restaurantSelected : restaurantId) + "/getBarcodes", sessionStorage.restaurantSelected).done(data => {
			if (data === null) {
				console.log("Failed to get barcodes!");
			} else {
				this.localData = data;
				handler([...data]);
			}
		});
	}

	saveBarcode = () => {
		var modal = $('div#editBarcode');
		var id = modal.find('input#id').val();
		var name = modal.find('#name').val();
		var quantity = modal.find('#quantity').val();
		var tareWeight = modal.find('input#tare-weight').val();
		if (!tareWeight)
			tareWeight = null;
		var grossWeight = modal.find('input#gross-weight').val();
		if (!grossWeight)
			grossWeight = null;
		var sm = $(modal.find("img#smallImage"));

		var menuItem = modal.find('select.product').select2().val();
		if ($.data(sm.get(0), "rcrop")) {
			this.crop(sm.get(0));
		}
		modal.find('input').removeClass('highlight-3');
		if (!id) {
			setTimeout(() => {
				modal.find('#id').addClass('highlight-3');
			}, 10)
			return;
		}
		if (!quantity) {
			setTimeout(() => {
				modal.find('#quantity').addClass('highlight-3');
			}, 10)
			return;
		}
		var image = $(modal.find("#smallImage")).attr("src");
		if (image === "") image = null;
		if (menuItem === "null")
			menuItem = null;
		var type;
		if (menuItem) {
			type = getMenuItem(menuItem).entityType.toLowerCase();
		}
		if (modal.find('input#id').prop('readonly')) {
			this.modifyBarcode({ id: id, name: name, quantity: quantity, tareWeight: tareWeight, grossWeight: grossWeight, imageSrc: image, menuItem: menuItem ? { id: menuItem, type: type } : null }, this.loadBarcodes);
		} else {
			this.addBarcode({ id: id, name: name, quantity: quantity, tareWeight: tareWeight, grossWeight: grossWeight, imageSrc: image, menuItem: menuItem ? { id: menuItem, type: type } : null }, this.loadBarcodes);
		}
		modal.modal('hide');
	}

	deleteBarcode = () => {
		var data = this.barcodes.getActiveNode().data;
		const instance = this;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete'), data.name)).done(function () {
			get("adminService/" + sessionStorage.restaurantSelected + "/deleteBarcode/" + data.id).done(function (data) {
				resultHandler(data, instance.loadBarcodes);
			});
		});

	}

	modifyBarcode(data, handler, errorHandler) {
		post("adminService/" + sessionStorage.restaurantSelected + "/modifyBarcode", data).done(function (data) {
			resultHandler(data, handler, errorHandler);
		});
	}

	addBarcode(data, handler, errorHandler) {
		post("adminService/" + sessionStorage.restaurantSelected + "/addBarcode", data).done(function (data) {
			resultHandler(data, handler, errorHandler);
		});
	}

	capturePhotoWithData(a, b) {
		capturePhotoWithData(a, b)
	}

	crop(a) {
		crop($(a));
	}

	phoneScanBarcode = () => {
		scanbarcode().done(barcode => {
			$('#editBarcode input#id').val(barcode)
		})
	}

	scaleMeasure = (t) => {
		scaleMeasure(localStorage.selected_scale).done(amount => {
			$(t).prev('input').val(amount)
		});
	}

	setSelectedScale(scale) {
		localStorage.selected_scale = scale;
		$('#editBarcode #selectedScale').html(scale);
	}

	modalOpen = null;
	paste = (event) => {
		console.log("paste");
		if (this.modalOpen == null) return;
		const modal = this.modalOpen;
		var items = (event.clipboardData || event.originalEvent.clipboardData).items;
		for (let index in items) {
			var item = items[index];
			if (item.kind === "file") {
				var blob = item.getAsFile();
				var reader = new FileReader();
				reader.onload = function (event) {
					$(modal.find("#smallImageDiv")).removeClass("hidden");
					onPhotoDataSuccess(event.target.result, $(modal.find("#smallImageDiv img")));
				}; // data url!
				reader.readAsDataURL(blob);
			}
		}
	}

	calculate = type => {
		var modal = $('div#editBarcode');
		var quantity = Number(modal.find('#quantity').val());
		switch (type) {
			case "gross":
				var tareWeight = Number(modal.find('input#tare-weight').val());
				if (tareWeight > 0 && quantity > 0) {
					modal.find('input#gross-weight').val(Math.round(tareWeight * 100 + quantity) / 100);
				}
				break;
			case "tare":
				var grossWeight = Number(modal.find('input#gross-weight').val());
				if (grossWeight > 0 && quantity > 0) {
					modal.find('input#tare-weight').val(Math.round(grossWeight * 100 - quantity) / 100);
				}
				break;
			default:
		}
	}

	syncBarcodes = () => {
		const def = new $.Deferred()
		getGlobal("adminService/2/getBarcodes").done(globalBarcodes => {
			const map = new Map();
			this.localData.forEach(barcode => {
				barcode.status = "local";
				map.set(barcode.id, barcode);
			});
			this.barcodesData = [...this.localData];
			globalBarcodes.forEach(barcode => {
				var m;
				if (m = map.get(barcode.id)) {
					//already exists
					m.status = "equal";
					if (barcode.quantity != m.quantity) {
						m.status = "notequal";
					}
					if (barcode.grossWeight != m.grossWeight) {
						m.status = "notequal";
					}
					if (barcode.tareWeight != m.tareWeight) {
						m.status = "notequal";
					}
					map.get(barcode.id).global = barcode;
				} else {
					//new
					barcode.status = "new";
					this.barcodesData.push(barcode);
				}
			})
			this.processBarcodes(this.barcodesData);
			def.resolve(this.barcodesData);
		});
		return def.promise();
	}
	downloadBarcodes = () => {
		this.syncBarcodes().done(barcodes => {
			if (this.barcodes.getSelectedNodes().length) {
				console.log(this.barcodes.getSelectedNodes().map(n => n.data));
				this.downloadOneBarcode(this.barcodes.getSelectedNodes().map(n => n.data).filter(barcode => barcode.name && barcode.quantity && barcode.tareWeight && barcode.grossWeight));
			} else {
				//this.downloadOneBarcode(barcodes.filter(barcode => barcode.status === "new" && this.selected(barcode)).filter(barcode => barcode.name && barcode.quantity && barcode.tareWeight && barcode.grossWeight));
			}
		});
	}

	uploadBarcodes = () => {
		this.syncBarcodes().done(barcodes => {
			this.uploadOneBarcode(barcodes.filter(barcode => barcode.status === "local").filter(barcode => barcode.name && barcode.quantity && barcode.tareWeight && barcode.grossWeight));
		});

	}
	uploadOneBarcode = (barcodes) => {
		const barcode = barcodes.shift();
		if (barcode) {
			delete barcode.extraClasses;
			delete barcode.folder;
			delete barcode.status;
			delete barcode.menuItem;
			if (barcode.image)
				getString("adminService/" + sessionStorage.restaurantSelected + "/getRawImage/" + barcode.image).done(image => {
					barcode.imageSrc = image;
					post(auth.globalserver + "/" + auth.war + "/adminService/2/addBarcode", barcode).done(data => {
						this.uploadOneBarcode(barcodes);
					});
				})
			else
				post(auth.globalserver + "/" + auth.war + "/adminService/2/addBarcode", barcode).done(data => {
					this.uploadOneBarcode(barcodes);
				});
		} else {
			this.getBarcodes(this.syncBarcodes);
		}

	}
	downloadOneBarcode = (barcodes) => {
		var barcode = barcodes.shift();
		if (barcode) {
			barcode = { ...barcode };
			delete barcode.extraClasses;
			delete barcode.folder;
			delete barcode.menuItem;
			if (barcode.status === "notequal" || barcode.status === "equal") {
				barcode = barcode.global;
				delete barcode.status;
				if (barcode.image)
					getString(auth.globalserver + "/" + auth.war + "/adminService/2/getRawImage/" + barcode.image).done(image => {
						barcode.imageSrc = image;
						post("adminService/" + sessionStorage.restaurantSelected + "/modifyBarcode", barcode).done(data => {
							this.downloadOneBarcode(barcodes);
						});
					})
				else
					post("adminService/" + sessionStorage.restaurantSelected + "/modifyBarcode", barcode).done(data => {
						this.downloadOneBarcode(barcodes);
					});
			} else {
				delete barcode.status;
				if (barcode.image)
					getString(auth.globalserver + "/" + auth.war + "/adminService/2/getRawImage/" + barcode.image).done(image => {
						barcode.imageSrc = image;
						post("adminService/" + sessionStorage.restaurantSelected + "/addBarcode", barcode).done(data => {
							this.downloadOneBarcode(barcodes);
						});
					})
				else
					post("adminService/" + sessionStorage.restaurantSelected + "/addBarcode", barcode).done(data => {
						this.downloadOneBarcode(barcodes);
					});
			}
		} else {
			this.barcodes.selectAll(false);
			this.getBarcodes(this.syncBarcodes);
		}

	}

}

export default BarcodesCode;