import { I18n } from "react-redux-i18n";
import auth, { _log, getString } from "../auth";
import { getPaymentMethod } from "../tableMatePaid";

export const generate_cashcube = (def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat) => {
    var url = "";
    var cashcube = {
        5: "A00",
        18: "B00",
        27: "C00",
        s5: "A00",
        s18: "B00",
        s27: "C00",
        null: "E00",
        undefined: "E00"
    };
    url = "QR0<c><dt>PRINTRECEIPT</dt><d>";
    var data = "<taxableReceipt><items>";
    var total = 0;

    var services = {};
    var count = 1;

    var totalAmount = 0;

    Object.values(amounts).sort((a, b) => a.entryOrder - b.entryOrder).filter(category => category.amount + category.service_amount).forEach(category => {
        if (category.amount) {
            data +=
                "<item><id></id><itemType>NORMAL</itemType><name>" +
                category.name +
                "</name><unitPrice>" +
                category.amount +
                "</unitPrice><quantity>" + category.count + "</quantity><unit>1</unit><vat>" +
                cashcube[category.vat] +
                "</vat><description></description></item>";
        }
        if (category.service_amount > 0) {
            if (!services[category.vat]) {
                services[category.vat] = 0;
            }
            services[category.vat] += category.service_amount * category.count;
            totalAmount += category.service_amount * category.count;
        }
    });


    var serviceUrl = "";
    Object.keys(services).forEach(key => {

        serviceUrl += "<item><id></id><itemType>NORMAL</itemType><name>" +
            I18n.t("admin_local.fiscat_service_fee") + " " + key + "%</name><unitPrice>" +
            ("" + services[key]).replace(",", ".") +
            "</unitPrice><quantity>1</quantity><unit>1</unit><vat>" +
            cashcube[key] +
            "</vat><description></description></item>";
        count++;
    });

    data += serviceUrl;


    if ((typeof auth.myStatus.restaurant_settings['register-tip'] === "undefined" || auth.myStatus.restaurant_settings['register-tip'])) {
        var a = 0;
        types.forEach(t => a = a + t.amount * t.exchangeRate);
        var done = false;
        types.forEach(payment => {
            if (done) return;
            const paymentMethod = getPaymentMethod(payment.type);
            if ("cash" === paymentMethod.method.type) {
                done = true;
                if (payment.currency == auth.myStatus.restaurant_base_currency.name) {
                    payment.amount = (payment.amount * payment.exchangeRate - (a - payable)) / payment.exchangeRate;
                    var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
                    var amount = payment.amount;
                    // eslint-disable-next-line no-eval
                    payment.amount = eval(roundFunction.replace("amount", payment.amount));
                }
            }
        })
    }


    data += "</items>";
    data += "<payments>";

    var paymentMethods = {
        "cash": "CASH",
        "card": "CARD",
        "hellopay": "CARD",
        "teya": "CARD",
        "online": "CARD",
        "bizpay": "CARD",
        "paynance": "CARD",
    }

    types.filter(t => t.amount > 0).forEach((t, ind) => {
        const paymentMethod = getPaymentMethod(t.type);

        if (!paymentMethods[paymentMethod.method.type])
            return;

        data += "<payment>";

        var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
        var amount = t.amount;
        // eslint-disable-next-line no-eval
        amount = eval(roundFunction.replace("amount", t.amount));

        if (t.currency === "EUR" && paymentMethod.method.type === "cash")
            data += "<currency>" + t.currency + "</currency><method>" + paymentMethods[paymentMethod.method.type] + "</method><value>" + Math.round(100 * t.amount) / 100 + "</value><exchangeRate>" + amountPaidExchangeRate + "</exchangeRate>";
        else
            data += "<currency>" + t.currency + "</currency><method>" + paymentMethods[paymentMethod.method.type] + "</method><value>" + Math.round(100 * t.amount) / 100 + "</value><exchangeRate>1</exchangeRate>";

        data += "</payment>";

    });
    data += "</payments>";

    data += "</taxableReceipt>";

    if (types.filter(t => t.amount > 0).length === 0) data = "";

    console.log(data);

    if (data)
        getString("adminService/" + sessionStorage.restaurantSelected + "/gzip?data=" + encodeURIComponent(data)).done(data => {
            url += data;
            url += "</d><z>true</z></c>";
            def.resolve(url);
        });
    else
        def.resolve("")
}

export const storno_cashcube = (data) => {
    return `CASHCUBE|STORNO|${data.customerName}|${data.customerPostCode}|${data.customerTown}|${data.customerAddress}|||${data.originalDate}|${data.originalReceipt}|${data.reason}`;
}

export const cashin_cashcube = (amount) => {
    return `CASHCUBE|CASHIN|HUF|${amount}`;
}

export const cashout_cashcube = (amount) => {
    return `CASHCUBE|CASHOUT|HUF|${amount}`;
}
