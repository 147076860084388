/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import $ from "jquery";
import jQuery from "jquery";
import "jquery-contextmenu/dist/jquery.contextMenu.js";
import "jquery-contextmenu/dist/jquery.contextMenu.min.css";
import "@codevadmin/jquery-tmpl";
import "jquery-ui/ui/widgets/resizable";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/droppable";
import "../lib/jquery-loadingModal/js/jquery.loadingModal.js";
import "../lib/jquery-loadingModal/css/jquery.loadingModal.css";
import "jquery-qrcode";
import "cleave.js";
import "bootstrap";
import { EventSourcePolyfill } from "./patch/eventsource";
import "select2";
import languages from "../langs/languages";
import MessageDialog from "../components/MessageDialog";
import ChoiceDialog from "../components/ChoiceDialog";
import "jquery-freeze-table";
import orderListUtil, { initClickSound, playClickSound } from "./order-list-util.js";
import "./zoom";
import { store } from "..";
import EatWithMeActions from "../actions/EatWithMeActions";
import "jquery.fancytree/dist/modules/jquery.fancytree.persist";
import "jquery.fancytree/dist/modules/jquery.fancytree.table";
import "jquery.fancytree/dist/modules/jquery.fancytree.dnd";
import "jquery.fancytree/dist/modules/jquery.fancytree.edit";
import "jquery.fancytree/dist/modules/jquery.fancytree.filter";
import "jquery.fancytree/dist/modules/jquery.fancytree.gridnav";
import "jquery.fancytree/dist/skin-lion/ui.fancytree.min.css";
import "./patch/jquery.fancytree.contextMenu";
import "./patch/jquery.progress";
import "./patch/DragDropTouch";
import "bootstrap/dist/css/bootstrap.css";
import "glyphicons-only-bootstrap/css/bootstrap.css";
import "daterangepicker/daterangepicker.css";
import "./patch/gijgo";
import "./patch/gijgo.css";
import "select2/dist/css/select2.css"
import moment from "moment";
import "intro.js/minified/introjs.min.css";
import admin from "./admin.js";
import 'source-map-support/register';
import sourceMapSupport from 'source-map-support'
import { I18n } from "react-redux-i18n";

if (location.href.startsWith("http://console.eatwithme.online")) {
  navigateTo("https://console.eatwithme.online");
}

const latestIOSVersion = "2.0.47";
const latestAndroidVersion = "2.0.43";

sourceMapSupport.install();

const searchParams = new URLSearchParams(window.location.search);

if (searchParams.get("server_url")) {
  localStorage.server = searchParams.get("server_url");
}
if (searchParams.get("instance")) {
  localStorage.instance = searchParams.get("instance");
}

window.onerror = function (message, source, lineno, colno, error) {
  _logError(message, source, lineno, colno, error ? error.stack : null);
}

if (typeof console._error === "undefined")
  console._error = console.error;

console.error = (message, detail) => {
  if (typeof console._error !== "undefined" && typeof console.error !== "undefined" && console._error != console.error) {
    logError(message, detail);
    console._error(message, detail);
  }
}

export const logError = (message, error) => {
  _logError(message, "", 0, 0, error);
}

//const ggg = "https://restaurant.eatwithme.online:8444/kakas";
//const ggg = "https://restaurant.eatwithme.online:8448/main";
export const isBackup = () => { return location.hostname === "console-backup.eatwithme.online" };
const ggg = isBackup() ? "https://backup.eatwithme.online" : "https://instance.eatwithme.online";
export const backupserver = "https://restaurant2.eatwithme.online";
//const ggg = "https://restaurant.eatwithme.online:8444/demo";

var appVersion = "";
var appLabel = "";

export const _logError = (message, source, lineno, colno, error) => {
  if (location.href.indexOf(":30001/") !== -1 || ("" + message).indexOf("tableService/clientError") !== -1 || source?.indexOf("cordova.js") > 0)
    return;
  if (message && message.indexOf && message.indexOf("exec proxy not found") >= 0) return;
  if (message && message.indexOf && message.indexOf("%creact") >= 0) return;
  if (message && message.indexOf && message.indexOf("Warning:") >= 0) return;
  post('tableService/clientError', {
    level: "ERROR",
    short_message: message,
    file: source,
    server: localStorage.instance,
    pod: auth.server ? auth.server.split('/').slice(-1).pop() : "",
    lineno,
    colno,
    full_message: error,
    application: "EatWithMe Console",
    restaurant: auth?.myStatus?.restaurant_name ? auth.myStatus.restaurant_name : "N/A",
    appVersion,
    appLabel,
    device: auth?.device?.platform,
    device_model: auth?.device?.model,
    device_manufacturer: auth?.device?.manufacturer,
    url: window.location.href,
    email: typeof localStorage.email != "undefined" ? localStorage.email : ""
  }, undefined, false, undefined, true);
}

export const _log = (message, source, lineno, colno, error) => {
  if (location.href.indexOf(":30001/") !== -1 || ("" + message).indexOf("tableService/clientError") !== -1 || (source && source?.indexOf("cordova.js") > 0))
    return;
  post('tableService/clientError', {
    level: "INFO",
    short_message: message,
    file: source,
    server: localStorage.instance,
    pod: auth.server ? auth.server.split('/').slice(-1).pop() : "",
    lineno,
    colno,
    full_message: error,
    application: "EatWithMe Console",
    restaurant: auth?.myStatus?.restaurant_name ? auth.myStatus.restaurant_name : "N/A",
    appVersion,
    appLabel,
    device: auth?.device?.platform,
    device_model: auth?.device?.model,
    device_manufacturer: auth?.device?.manufacturer,
    url: window.location.href,
    email: typeof localStorage.email != "undefined" ? localStorage.email : ""
  }, undefined, false, undefined, true);
}

if (typeof localStorage.instance === "undefined")
  localStorage.instance = "eatwithme";

export const appRoot = location.protocol.startsWith("file:") ? "" : "/Restaurant.app/";

console.log("protocol", location.protocol);
console.log("appRoot", appRoot);

// admin_local is needed in eval(*)
const { local, admin_local } = languages;

if (typeof localStorage.skin === "undefined") {
  localStorage.skin = 'default';
}
if (!admin_local.skins.find(s => s.id === localStorage.skin)) {
  localStorage.skin = 'default';
}


//if (localStorage.server === ggg)
//localStorage.server = ggg;

//localStorage.server = globalserver;

export const globalServers = ["https://console.eatwithme.online", "https://console-backup.eatwithme.online", "https://shop.eatwithme.online", ggg, "https://backup.eatwithme.online", backupserver, "https://restaurant.eatwithme.online", "https://instance.eatwithme.online"];
var tmpglobalserver = globalServers.find(s => location.href.indexOf(s) !== -1);
if (!tmpglobalserver) tmpglobalserver = ggg;
var globalserver = tmpglobalserver;

export const getGlobalAppServer = globalserver + "/Restaurant.app/";

export const isGlobalServer = (server) => {
  return globalServers.indexOf(server) !== -1;
}

export const mainserver = ggg.replace(/:447/g, "");
export const appmainserver = ggg.replace(/:447/g, "");

export const courierColors = ["darkslateGray", "white", "darkslateGray", "darkslateGray", "white", "darkslateGray", "darkslateGray"];
export const courierBackgroundColors = ["yellow", "blue", "green", "orange", "darkgray", "lightgreen", "lightgray"];

var auth = {
  roles: ["superadmin", "admin", "waiter", "cook", "store", "finance", "courier", "tester"],
  roles2: ["admin", "waiter", "cook", "store", "finance", "courier"],
  minfontsize: 6,
  scrollEventTimestamp: 0,
  war: "eatwithme.server",
  mainserver: mainserver,
  backupserver: backupserver,
  incomingcall: localStorage.incomingcall == "true"
};

export const incomingCallChanged = (event) => {
  if (!auth.incomingcall) {
    confirmDialog(local.question, admin_local.do_you_want_to_catch_incoming_calls).done(() => {
      auth.incomingcall = !auth.incomingcall;
      $(event.target).addClass("active");
      handleIncomingCalls();
      localStorage.incomingcall = auth.incomingcall;
    });
  } else {
    auth.incomingcall = !auth.incomingcall;
    $(event.target).removeClass("active");
    handleIncomingCalls();
    localStorage.incomingcall = auth.incomingcall;
  }


}

function handleIncomingCalls() {
  if (window.AdcCallReceiver) {
    if (auth.incomingcall) {
      if (!auth.incomingcallinitialized) {
        auth.incomingcallinitialized = true;
        window.AdcCallReceiver.onCall(function (intent) {
          if (auth.incomingcall) {
            intent = JSON.parse(intent);
            _log("Incoming call: " + intent.state + " " + intent.number);
            if (auth?.myStatus?.id && auth?.myStatus.restaurant_id) {
              if (intent.state === "RINGING" || intent.state === "OFFHOOK")
                get("adminService/" + sessionStorage.restaurantSelected + "/call/ringing/" + intent.number).fail(() => auth.ajaxError = false);
              else
                get("adminService/" + sessionStorage.restaurantSelected + "/call/end/" + intent.number).fail(() => auth.ajaxError = false);
            }
          }
        });
      }
    }
  }
  else {
    console.log("plugin not found");
  }
}

var framesLoaded = $.Deferred();
//eslint-disable no-restricted-globals

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;

  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};


const isIncludedInFrame = getParameterByName("frame") == "true";
auth.isIncludedInFrame = isIncludedInFrame;

var totalFrameCount = null;

const startofloading = new Date().getTime();

function frameLoaded(event) {
  try {
    var z = event.target.src.split("?")[0];
    //console.log("Frame " + z + " loaded");
    var path = z.split("/");
    var p = path[path.length - 1];
    var html = sessionStorage[path[path.length - 1]];
    var doc = $(html);
    sessionStorage.removeItem(path[path.length - 1]);
    doc.each((ind, d) => {
      //alert(path[path.length-1]+d.tagName);
      if (d.textContent.indexOf("onLoad") >= 0) {
        return;
      }
      try {
        $("head").append($(d));
      } catch (ex) {
        logger.error("Failed to load " + path, ex);
      }
    });
    $("iframe[src *= '" + p + "']").remove();
    if (totalFrameCount == null) {
      totalFrameCount = $("iframe[w3-include-html]").length;
    }
    var frameCount = $("iframe[w3-include-html]").length;
    $("body .jquery-loading-modal__text").html(Math.round(100 * (totalFrameCount - frameCount) / totalFrameCount) + "%");
    $("body #download-progress text").html(Math.round(100 * (totalFrameCount - frameCount) / totalFrameCount) + "%");
    $("body .jquery-loading-modal__text").show();
    if (frameCount == 0) {
      try {
        //$("body .jquery-loading-modal__text").hide();
        $("body #download-progress text").html("");
        /*
        if (new Date().getTime() - startofloading < 2000)
          setTimeout(framesLoaded.resolve, 2000 - (new Date().getTime() - startofloading));
        else
        */
        framesLoaded.resolve();
      } catch (ex) {
        console.error(path, ex);
        alert(ex);
      }
    }
  } catch (ex) {
    alert(ex);
  }
}

auth.frameLoaded = frameLoaded;


function findLiveServer(readyHandler) {

  if (!window.navigator.onLine) {
    window.addEventListener('online', () => {
      if (auth.messageDialogHandle) {
        auth.messageDialogHandle.resolve();
        findLiveServer(readyHandler);
      }
    });
    auth.messageDialogHandle = messageDialogTemp(local.information, local.no_internet);
    auth.messageDialogHandle.done(() => {
      delete auth.messageDialogHandle;
    });

    return;
  }

  getString(mainserver + "/eatwithme.server/restaurantService/isAlive?instance=eatwithme").done((status) => {
    auth.globalserver = globalserver = setGlobalServer(mainserver);
    if (typeof localStorage.server != "undefined" && localStorage.server === auth.backupserver)
      localStorage.server = mainserver;
    auth.deviceready(readyHandler);
  }).fail((status) => {
    getString(backupserver + "/eatwithme.server/restaurantService/isAlive").done(() => {
      auth.globalserver = globalserver = setGlobalServer(backupserver);
      auth.deviceready(readyHandler);
    }).fail(() => {
      messageDialog(I18n.t("local.error_message"), String.format(I18n.t("local.communication_error"), status.statusText)).done(() => {
        findLiveServer(readyHandler);
      })
    });
  })

}

function waitForFrames(readyHandler) {
  if ($('iframe[w3-include-html = "true"]').length == 0) {
    //$("body .jquery-loading-modal__text").hide();
    $("body .jquery-loading-modal__text").html("Quick loaded");
    findLiveServer(readyHandler);
    return;
  }
  console.log("wait for frames....");
  framesLoaded.promise().done(() => {
    setTimeout(() => {
      findLiveServer(readyHandler);
    }, 300);
  });
  setTimeout(() => {
    if ($("iframe[w3-include-html]").length > 0) {
      try {
        var text = "";
        $("iframe[w3-include-html]").each((ind, f) => {
          text += $(f).attr("src") + "\r\n";
        });
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } catch (ex) {
        alert(ex);
      }
    }
  }, 60000);
}

auth.waitForFrames = waitForFrames;

$.ajaxSetup({ cache: true });

if (typeof sessionStorage.restaurantSelected != "undefined" || sessionStorage.restaurantSelected == "undefined") {
  try {
    var r = Number(sessionStorage.restaurantSelected);
    if (isNaN(r)) {
      sessionStorage.restaurantSelected = 0;
    }
  } catch (ex) {
    sessionStorage.restaurantSelected = 0;
  }
}

if (typeof localStorage.zoom == "undefined" || localStorage.zoom == "NaN") localStorage.zoom = 1;

var communication_error_img = "img/cloud-thumb-down-512.png";
var error_code_500_img = "img/cloud-rejected-512.png";
var error_code_404_img = "img/cloud-rejected-512.png";

export const getOrientation = () => {
  return (window.innerWidth == window.innerHeight && (window.orientation == 0 || window.orientation == 180)) || window.innerWidth < window.innerHeight ? "portrait" : "landscape";
};

let _zoom, _cssZoom;

function getIPhoneType() {
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  if (screenHeight === 812 && screenWidth === 375) return ("iPhone X, XS, 11 Pro");
  else if (screenHeight === 896 && screenWidth === 414) return ("iPhone XR, 11");
  else if (screenHeight === 844 || screenHeight === 926) return ("iPhone 12, 13, 14, 15");
  else if (screenHeight === 874 || screenHeight >= 980) return ("Future Pro models (iPhone 16 Pro, 17 Pro, etc.)");
  else if (screenHeight >= 900) return ("Future Plus models (iPhone 16 Plus, 17 Plus, etc.)");
  else if (screenHeight === 852 || screenHeight === 932) return ("iPhone 14 Pro, 15 Pro");
}

function getNotchHeight() {
  const ua = navigator.userAgent;
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  // Known iPhone notch heights
  if (/iPhone/.test(ua)) {
    try {

      if (screenHeight === 812 && screenWidth === 375) return 44 + 35; // iPhone X, XS, 11 Pro
      if (screenHeight === 896 && screenWidth === 414) return 44 + 35; // iPhone XR, 11
      if (screenHeight === 844 || screenHeight === 926) return 47 + 35; // iPhone 12, 13, 14, 15
      if (screenHeight === 874) return 59; // Future Pro models (iPhone 16 Pro, 17 Pro, etc.)
      if (screenHeight >= 980) return 59 + 54; // Future Pro models (iPhone 16 Pro, 17 Pro, etc.)
      if (screenHeight >= 900) return 47; // Future Plus models (iPhone 16 Plus, 17 Plus, etc.)
      if (screenHeight === 852 || screenHeight === 932) return 59 + 30; // iPhone 14 Pro, 15 Pro
    } catch (ex) {
      _log("" + ex);
    }
  }

  return 0; // Default for older iPhones or unknown devices
}


export const setRem = () => {
  try {
    auth.orientation = getOrientation();
    if (auth.orientation == "portrait" && auth.device.platform == "iOS") {
      try {
        _log("setRem " + auth.device.platform + " " + auth.orientation);
        const n = getNotchHeight();
        document.body.style.height = `${window.screen.height - n}px`;
        _log("height set to " + document?.body?.style?.height);
        _log(getIPhoneType() + " height:" + window.screen.height + " " + window.visualViewport.height + "-" + n + " height=" + document.body.style.height);
      } catch (Ex) {
        _log("" + Ex);
      }
    } else {
      document.body.style.height = `${window.visualViewport.height}px`;
    }

    var $html = $("html");
    auth.zoom = Number(localStorage.zoom);
    var zzz = Math.round(window.devicePixelRatio * 100) / 100;
    auth.cssZoom = $html.css("zoom");
    if (typeof cssZoom == "undefined") auth.cssZoom = 1;
    else {
      if (auth.cssZoom != 1 && auth.zoom != 1) {
        // $('html').css('zoom',1);
        // localStorage.zoom = 1;
        auth.zoom = Number(localStorage.zoom);
      }
    }
    auth.prevSize = $html.css("font-size").split("px")[0];
    auth.newSize = Math.max(auth.minfontsize, Math.min(window.innerWidth, window.innerHeight) / 70) * auth.zoom * auth.cssZoom;
    _zoom = auth.zoom;
    _cssZoom = auth.cssZoom;
    function rr(c) {
      $("#zoomlevel2").html(Math.round(auth.zoom * auth.cssZoom * 100));
      if (_zoom == auth.zoom && _cssZoom == auth.cssZoom) {
        $html.css("font-size", auth.newSize);
        if (typeof auth.resize != "undefined") {
          auth.resize(c || Math.abs(auth.prevSize - auth.newSize) > 1);
        }
      }
    }
    if (auth.previousorientation != auth.orientation || Math.abs(auth.prevSize - auth.newSize) > 0.1) {
      if (auth.previousorientation != auth.orientation && device.platform == "iOS") {
        setTimeout(() => {
          rr(true);
        }, 1000);
        setTimeout(() => {
          rr(true);
        }, 2000);
        setTimeout(() => {
          rr(true);
        }, 3000);
      } else {
        rr(auth.previousorientation != auth.orientation);
      }
    }
    auth.previousorientation = auth.orientation;
  } catch (ex) {
    console.log(ex);
  }
};

var zoomTimeout = undefined;
export const increaseZoom = () => {
  localStorage.zoom = Number(localStorage.zoom) + 0.1;
  localStorage.zoom = Math.min(2, localStorage.zoom);
  setRem();
  if (zoomTimeout)
    clearTimeout(zoomTimeout);
  zoomTimeout = $("#zoomlevel").html(Math.round(localStorage.zoom * 100) + "%");
  setTimeout(() => {
    $("#zoomlevel").html("");
    zoomTimeout = undefined;
  }, 300)

}

export const decreaseZoom = () => {
  localStorage.zoom = Number(localStorage.zoom) - 0.1;
  localStorage.zoom = Math.max(0.5, localStorage.zoom);
  setRem();
  if (zoomTimeout)
    clearTimeout(zoomTimeout);
  zoomTimeout = $("#zoomlevel").html(Math.round(localStorage.zoom * 100) + "%");
  setTimeout(() => {
    $("#zoomlevel").html("");
    zoomTimeout = undefined;
  }, 300)

}

auth.myStatus = undefined;

auth.setMyStatus = function (myStatus, skipLocationService = false) {
  auth.myStatus = myStatus;
  if (myStatus.restaurant_global_id)
    localStorage.globalId = myStatus.restaurant_global_id;
  console.log("Reset my status");
  store.dispatch(EatWithMeActions.resetMyStatus(myStatus));
  if (!skipLocationService)
    if (myStatus.roles.find(role => role.role === "courier" && role.entityId == myStatus.restaurant_id) && myStatus.courierState !== "OFF") {
      startBackgroundLocationTraking();
    } else {
      stopBackgroundLocationTraking();
    }
};

var unload = false;

/*
document.addEventListener(
  "deviceready",
  () => {
    console.log("Cordova ready");
    ForFrames();
  },
  false
);*/

var liveServerUnavailableCount = 0;

function pingMainServer() {
  if (auth.globalserver) {
    getString(auth.globalserver + "/eatwithme.server/restaurantService/isAlive?instance=eatwithme", undefined, undefined, undefined, false).fail(() => {
      auth.ajaxError = false;
    });
  }
}


auth.deviceready = function (readyHandler) {

  //_log("deviceready");

  setInterval(pingMainServer, 60000);

  handleIncomingCalls();


  window.addEventListener('online', () => {
    if (auth.messageDialogHandle) {
      auth.messageDialogHandle.resolve();
    }
  });
  window.addEventListener('offline', () => {
    auth.messageDialogHandle = messageDialogTemp(local.information, local.no_internet);
    auth.messageDialogHandle.done(() => {
      delete auth.messageDialogHandle;
    });
  });

  if (!window.navigator.onLine) {
    auth.messageDialogHandle = messageDialogTemp(local.information, local.no_internet);
    auth.messageDialogHandle.done(() => {
      delete auth.messageDialogHandle;
    });
  }


  initClickSound();

  device = window.device;

  /*$('#download-progress').addClass('hidden');*/

  if (typeof bluetoothle !== "undefined") {
    bluetoothle.initialize(result => {
      bluetoothle.requestPermission(requestPermissionSuccess => {
        try {
          if (bluetoothle.requestPermissionBtConnect)
            bluetoothle.requestPermissionBtConnect(requestPermissionSuccess => {
              bluetoothle.requestPermissionBtScan(requestPermissionSuccess => {
              }, requestPermissionError => {
              });
            }, requestPermissionError => {
            });
        } catch (ex) {
          alert(ex)
        }
      }, requestPermissionError => {
        console.error(JSON.stringify(requestPermissionError));
      });

    }, { request: true });
  } else {

  }

  $('#download-progress').addClass('hidden');

  auth.device = device;

  moment.locale(localStorage.language);

  window.increaseZoom = increaseZoom;
  window.decreaseZoom = decreaseZoom;

  $("body").resize(setRem);

  setRem();

  document.onmousewheel = function (event) {
    if (event.ctrlKey == true) {
      if (event.wheelDelta > 0) increaseZoom();
      else decreaseZoom();
    }
  };

  $('#errorTemplate').tmpl({ local: local }).appendTo($('body'));

  try {
    // eslint-disable no-restricted-globals
    if (/*(device.platform != "browser" || location.href.indexOf(":3000/") !== -1 || location.href.indexOf("https://hotbackup.eatwithme.online") !== -1) && */ typeof localStorage.server !== "undefined") {
      auth.server = localStorage.server;
    } else {
      var server = getParameterByName("s");
      // eslint-disable no-restricted-globals
      auth.server =
        location.href != null && location.href.indexOf(":3000/") !== -1
          ? "https://restaurant.eatwithme.online"
          : location.href.indexOf("https://hotbackup.eatwithme.online") !== -1
            ? "https://backup.eatwithme.online"
            : location.protocol !== "file" && location.protocol != "file:"
              ? server ? server : globalserver
              : globalserver;
    }
    localStorage.server = auth.server;
    // eslint-disable no-restricted-globals
    auth.appServer = location.href;
    // eslint-disable no-restricted-globals
    if (location.protocol == "file:" || location.href.indexOf(":3000/") !== -1) {
      auth.appServer = auth.server + "/Restaurant.app/";
    } else if (location.href.indexOf("https://hotbackup.eatwithme.online") !== -1) {
      auth.appServer = "https://hotbackup.eatwithme.online/";
    } else {
      auth.appServer = auth.appServer.split("?")[0];
      var index = auth.appServer.indexOf(".app/");
      if (index >= 0) {
        auth.appServer = auth.appServer.substring(0, index + 4);
      }
      if (auth.appServer.lastIndexOf("/") != auth.appServer.length - 1) {
        auth.appServer = auth.appServer + "/";
      }
    }

    if (device.platform == "browser" && getParameterByName("r") != null) {
      sessionStorage.restaurantSelected = getParameterByName("r");
      getMyStatus(data => {
        localStorage.id = data.id;
        navigateTo(location.href.split("?")[0]);
      }, data => {
        console.log("Navigate to login");
        auth.ajaxError = false;
        localStorage.removeItem("restaurantSelected");
        auth.server = auth.globalserver;
        localStorage.server = auth.globalserver;
        localStorage.removeItem("globalId");
        navigateToLogin();
      });
      return;
    }

    //console.error("Device ready");

    if (typeof localStorage.id != "undefined" && localStorage.id != "undefined")
      try {
        if (device.platform != "browser") {
          const push = PushNotification.init({
            android: {},
            ios: {
              alert: "true",
              badge: "true",
              sound: "true"
            },
            windows: {}
          });

          push.on("registration", data => {
            try {
              /*
             if (typeof localStorage.pushToken != "undefined" && localStorage.pushToken==data.registrationId)
               return;
               */
              console.log("Push notification deviceId " + data.registrationId);
              localStorage.pushToken = data.registrationId;
              getGlobal("customerService/setPushNotification?deviceId=" + encodeURIComponent(localStorage.pushToken)).fail(() => {
                auth.ajaxError = false;
              });
            } catch (w) {
              alert("Could not register for notifications! " + w);
            }
          });

          push.on("notification", data => {
            console.log(data);
            try {
              var restaurant = sessionStorage.restaurantSelected;
              var serverURL = auth.server;
              var d = device.platform == "android" ? data.additionalData : data.additionalData;
              var restaurant_url = d.restaurant_url;
              if (restaurant_url.charAt(restaurant_url.length - 1) == "/") restaurant_url = restaurant_url.substring(0, restaurant_url.length - 1);
              var restaurant_id = d.restaurant;
              var restaurant_name = d.restaurant_name;
              var click_action = data.additionalData.click_action;
              if (data.additionalData.foreground == false) {
                switch (click_action) {
                  default:
                    switchServer({
                      globalId: restaurant_id,
                      serverUrl: restaurant_url
                    }).done(() => {
                      switch (click_action) {
                        default:
                          if (restaurant != sessionStorage.restaurantSelected || serverURL != auth.server) fullreload();
                      }
                    });
                }
              } else {
                if (auth.server != restaurant_url || restaurant_id != auth.myStatus.restaurant_global_id) {
                  // a message was received from a different server
                  var message = data.additionalData.body;
                  var title = data.additionalData.title;
                  function handler() {
                    switch (click_action) {
                      default:
                        switchServer({
                          globalId: restaurant_id,
                          serverUrl: restaurant_url
                        }).done(() => {
                          switch (click_action) {
                            default:
                              if (restaurant != sessionStorage.restaurantSelected || serverURL != auth.server) fullreload();
                          }
                        });
                    }
                  }
                  if (auth.myStatus.restaurant_global_id != undefined) {
                    choiceDialog2(
                      String.format(local.message_received_from, title),
                      String.format(local.message_received_from_a_different_restaurant, message),
                      [String.format(local.change_restaurant, restaurant_name), local.continue_here],
                      false
                    ).done(choice => {
                      switch (choice.number) {
                        case 0:
                          handler();
                      }
                    });
                  } else {
                    handler();
                  }
                }
              }
            } catch (ex) {
              alert(ex);
            }
          });

          push.on("error", e => {
            console.log(e);
            // e.message
          });

          PushNotification.hasPermission(data => { });
        } else {
          if (navigator.serviceWorker) {
            /*
                        var firebaseConfig = {
                          apiKey: "AIzaSyD_ubC_WOHvUX4LSrXixHHaJg-LYClfW9k",
                          authDomain: "eatwithme-1562773624255.firebaseapp.com",
                          databaseURL: "https://eatwithme-1562773624255.firebaseio.com",
                          projectId: "eatwithme-1562773624255",
                          storageBucket: "",
                          messagingSenderId: "1058609076239",
                          appId: "1:1058609076239:web:2337153a2f19281b"
                        };
            */
            var firebaseConfig = {
              apiKey: "AIzaSyBlssQ1HW3mxEdJSdksH4P1tIefTUn_jCM",
              authDomain: "eatwithme-console.firebaseapp.com",
              databaseURL: "https://eatwithme-console.firebaseio.com",
              projectId: "eatwithme-console",
              storageBucket: "eatwithme-console.appspot.com",
              messagingSenderId: "905206339004",
              appId: "1:905206339004:web:1e47d303819dd348f0c7b9",
              measurementId: "G-LCEN3ZV794"
            };


            // Initialize Firebase
            if (typeof firebase !== "undefined") firebase.initializeApp(firebaseConfig);

            navigator.serviceWorker.register("/js/firebase.js").then(registration => {
              const messaging = firebase.messaging();
              messaging.useServiceWorker(registration);
              //messaging.usePublicVapidKey("BFE00-ubuO384wKQ793FJ2iT3VcZPzqCD0EX3m5wEOLYMlHvtyCMiZvtmPPI4zbnopanX5LIKARgImqEMhKOJ9k");
              messaging.usePublicVapidKey("BJZJtsDv3Mwbn2udiY-kcRcz5S20vLiVjVBrnsdg_NdP28g060ukoI6sqn6qfi1FU-lPnFhFxgoI-gjFsQvvvfk");

              // Request permission and get token.....
              messaging
                .requestPermission()
                .then(function () {
                  console.log("Notification permission granted.");
                  return messaging.getToken();
                })
                .then(function (token) {
                  localStorage.pushToken = token;
                  if (auth.myStatus.restaurant_id && typeof localStorage.pushToken != "undefined" && localStorage.pushToken == token)
                    getGlobal("customerService/setPushNotification?deviceId=" + encodeURIComponent(token));
                })
                .catch(function (err) {
                  console.log("Unable to get permission to notify.", err);
                });

              messaging.onMessage(function (payload) {
                console.log(payload);
              });
            });
          }

        }
      } catch (ex) {
        console.log(ex);
      }

    $("body").attr("device", device.platform);
    $("html").attr("device", device.platform);

    ajaxCallStart("processing");
    includeHTML().done(function () {
      try {
        $("body").dblclick(function (e) {
          touchtime = 0;
        });

        $("body").resize(function () {
          setRem();
        });
        $("body").on("mousemove", resetLockTimeout);
        $("body").on("keydown", resetLockTimeout);
        $("body").on("wheel", resetLockTimeout);
        $("body").on("mousedown", resetLockTimeout);
        /*
                $("body").loadingModal({
                  position: "auto",
                  text: "",
                  color: "yellow",
                  opacity: ".2",
                  backgroundColor: "rgb(0,0,0)",
                  animation: "circle"
                });
                */

        $("body").keydown(function (e) {
          switch (e.which) {
            case 112: // F1
              if (orderListUtil.help) {
                orderListUtil.help.initHelp();
                orderListUtil.help.showHelp();
              }
              //showHelp();
              e.preventDefault();
              e.stopPropagation();
              break;
            default:
              return;
          }
        });
        var res;

        if (typeof StatusBar != "undefined") {
          StatusBar.overlaysWebView(false);
          StatusBar.show();
          /*
        setTimeout(() => {
            StatusBar.overlaysWebView(false);
            StatusBar.show();
          }, 10);
          */
        }
        if (typeof AndroidFullScreen !== "undefined") {
          AndroidFullScreen.showUnderStatusBar(() => { }, () => { });
          AndroidFullScreen.setSystemUiVisibility(AndroidFullScreen.SYSTEM_UI_FLAG_FULLSCREEN | AndroidFullScreen.SYSTEM_UI_FLAG_LOW_PROFILE, () => { }, () => { });
        }


        if (typeof readyHandler !== "undefined") res = readyHandler();
        else res = ready();
        if (res != undefined) {
          res.done(() => {
            if (typeof initHelpButton != "undefined") initHelpButton();
            if (localStorage.locked == "true" && $("html[dontlock]").length == 0) {
              $(".modal").modal("hide");
              $("div#locked").show();
              // localStorage.removeItem('hash');
            }
            ajaxCallEnd("processing");
          });
        } else {
          if (typeof initHelpButton != "undefined") initHelpButton();

          if (localStorage.locked == "true" && $("html[dontlock]").length == 0) {
            $(".modal").modal("hide");
            $("div#locked").show();
            // localStorage.removeItem('hash');
          }
          ajaxCallEnd("processing");
        }
      } catch (ex) {
        console.log(ex);
        console.log(ex.stack);
      }
    });
  } catch (ex) {
    console.error("" + ex, ex.stack);
    //console.error(ex.stack);
  }
};

window.addEventListener("beforeunload", function (event) {
  unload = true;
});

window.addEventListener("pagebeforehide", function (event) {
  unload = true;
});

window.addEventListener("unload", function (event) {
  unload = true;
});

document.addEventListener("beforeunload", function () {
  unload = true;
});

export const stopPool = () => {
  if (eventSource != null) {
    eventSource.close();
    eventSource = null;
  }
}


function initPool(force) {
  if (auth.myStatus.restaurant_id) {
    if (auth.myStatus.status != "none")
      startpoll(
        serverSideMessageHandler,
        [
          {
            Restaurant: auth.myStatus.restaurant_id,
            TableOccupation: auth.myStatus.tableOccupation
          }
        ],
        "eatwithme",
        force
      );
    else startpoll(serverSideMessageHandler, [{ Restaurant: auth.myStatus.restaurant_id, Customer: localStorage.id }], "eatwithme", force);
  } else if (typeof sessionStorage.restaurantSelected != "undefined") {
    startpoll(
      serverSideMessageHandler,
      [
        {
          Restaurant: sessionStorage.restaurantSelected,
          Customer: localStorage.id
        }
      ],
      "eatwithme",
      force
    );
  } else {
    startpoll(serverSideMessageHandler, [{ Customer: localStorage.id }], "eatwithme", force);
  }
}

function serverSideMessageHandler(data) {
  try {
    if (data == null) {
      console.log("Failed wait for refresh!");
      auth.toBeRestarted = true;
    } else {
      if (data.type == "timeout") {
        auth.toBeRestarted = true;
      } else {
        auth.success = true;
        //console.log("Refresh event received");
        if (data.type == "refresh" && data.message == "true") {
          //console.log("Refresh event received");
          if (auth.reload) auth.reload();
          else {
            admin.getMyStatus();
            //location.reload();
          }
        } else if (data.type == "message") {
          messageDialog(String.format(local.message_from, data.from), String.format(local[data.message], data.from));
        }
      }
    }
  } catch (ex) {
    console.log(ex);
  }
}
auth.serverSideMessageHandler = serverSideMessageHandler;

function codepush() {

  function downloadProgress(downloadProgress) {
    if (downloadProgress) {
      $('#download-progress').removeClass('hidden');
      const progress = String.format(local.download_progress, Math.round(100 * downloadProgress.receivedBytes / downloadProgress.totalBytes));
      console.log(progress);
      $('#download-progress text').html(progress);
    }
  }

  function syncStatus(status) {
    switch (status) {
      case SyncStatus.DOWNLOADING_PACKAGE:
        $('#download-progress').removeClass('hidden');
        break;
      case SyncStatus.INSTALLING_UPDATE:
        $('#download-progress text').html(local.installing_update);
        // Hide "downloading" modal
        break;
      case SyncStatus.ERROR:
        $('#download-progress').addClass('hidden');
        console.error("install update failed", JSON.stringify(status));
        break;
    }
  }

  function getDeploymentKey() {
    const update_versions = typeof localStorage.update_versions !== "undefined" ? localStorage.update_versions : "stable";
    if (device.platform === 'iOS') {
      if (update_versions === "test")
        //return "4A6FNFqkfmwi9FLLmcEbNU6cI9KxA-eI1QaYc";
        return "JpqQfxi5CZQyspvo1HJeZwq3jF7W4ksvOXqog";
      else
        //return "JQ_eqkrKZKHJEdN3XyTLdLTLO44g3UVnUzpaQ";
        return "UCoYYnS5GVdSOFTIpAhAPz0cgf6T4ksvOXqog";
    } else if (device.platform === 'Android') {
      if (update_versions === "test")
        //  return "lC209qAoSP93SHE5nG97b3na5lm9ZHq0n3rtD";
        return "Juz4HJI94K1GiQYPXbZq2Ka4YkXJ4ksvOXqog";
      else
        //return "nx_Wt0Msg7fkpsnRrl0P4xlEnLOeoJQMGEChj";
        return "Bv0tszXWmv8AF3MmixoTXUdT7MVv4ksvOXqog";
    }
  }


  function getRelease(developmentKey) {
    if (developmentKey === "JQ_eqkrKZKHJEdN3XyTLdLTLO44g3UVnUzpaQ") return "production";
    if (developmentKey === "4A6FNFqkfmwi9FLLmcEbNU6cI9KxA-eI1QaYc") return "test";
    if (developmentKey === "nx_Wt0Msg7fkpsnRrl0P4xlEnLOeoJQMGEChj") return "production"
    if (developmentKey === "lC209qAoSP93SHE5nG97b3na5lm9ZHq0n3rtD") return "test";
    if (developmentKey === "UCoYYnS5GVdSOFTIpAhAPz0cgf6T4ksvOXqog") return "production";
    if (developmentKey === "JpqQfxi5CZQyspvo1HJeZwq3jF7W4ksvOXqog") return "test";
    if (developmentKey === "Bv0tszXWmv8AF3MmixoTXUdT7MVv4ksvOXqog") return "production";
    if (developmentKey === "Juz4HJI94K1GiQYPXbZq2Ka4YkXJ4ksvOXqog") return "test";
    return developmentKey;
  }

  document.addEventListener("resume", function () {
    if (typeof codePush != 'undefined') {
      console.log("codepush sync with " + getDeploymentKey());
      try {
        codePush.sync(syncStatus, {
          installMode: InstallMode.IMMEDIATE,
          deploymentKey: getDeploymentKey(),
          serverURL: "http://eatwithme.online:3001"
        },
          downloadProgress);
      } catch (ex) {
        console.error(ex);
      }
    }
  });
  if (device.platform !== 'browser') {
    if (typeof codePush != 'undefined') {
      try {
        codePush.sync(null, { installMode: InstallMode.IMMEDIATE, deploymentKey: getDeploymentKey(), serverURL: "http://eatwithme.online:3001" }, downloadProgress);
        codePush.getCurrentPackage(data => {
          if (data != null) {
            appVersion = data.appVersion;
            appLabel = data.label;
            _log("version");
            if (typeof localStorage["latestIOSVersion" + latestIOSVersion] == undefined && device.platform === 'iOS' && data.appVersion.replace(/\./g, '') < latestIOSVersion.replace(/\./g, '')) {
              messageDialog(local.information, local.new_version_is_available).done(() => {
                localStorage["latestIOSVersion" + latestIOSVersion] = true;
                window.open("itms-apps://itunes.apple.com/app/1484468996");
              });
            }
            if (typeof localStorage["latestAndroidVersion" + latestAndroidVersion] == undefined && device.platform === 'Android' && data.appVersion.replace(/\./g, '') < latestAndroidVersion.replace(/\./g, '')) {
              messageDialog(local.information, local.new_version_is_available).done(() => {
                localStorage["latestAndroidVersion" + latestAndroidVersion] = true;
                window.open("https://play.google.com/store/apps/details?id=online.eatwithme.restaurant");
                //window.open("market://details?id=online.eatwithme.eatwithme");
              })
            }

            var props = { email: localStorage.email };
            if (data.appVersion)
              props[device.platform.toLowerCase() + 'appversion'] = data.appVersion;
            if (data.label)
              props[device.platform.toLowerCase() + 'applabel'] = + getRelease(data.deploymentKey) + " " + data.label;
            props[device.platform.toLowerCase() + 'user'] = true;

            try {

              mt('send', 'pageview', props, () => {
                console.log("Sending data to mautic success");
              }, (error) => {
                console.log("Error while sending data to mautic");
                console.log(error);
              })

            } catch (ex) {
              console.log(ex);
            }

            $('#updateLabel').html(data.appVersion + " " + getRelease(data.deploymentKey) + " " + data.label);
            $('#updateLabel').attr('title', data.description)
          }
        });
      } catch (ex) {
        console.error(ex);
      }

    }
  }

  cp = true;
}

window.sessionCrc = Math.random().toString(36).substring(2, 15);

var eventSource = null;
localStorage.eatwithmeWaitError = -1;
var previousFilter = null;
auth.suppressNetworkError = false;
var cp = false;
auth.restart = false;
var activeServerSideMessageHandler = null;
var user_id = null;
var recoverFromError = null;
export const startpoll = (handler, filter, role, recoverFromEventSourceError) => {
  localStorage.eatwithmeWaitError = 0;
  _startpoll(handler, filter, role, recoverFromEventSourceError);
}
export const _startpoll = (handler, filter, role, recoverFromEventSourceError) => {
  if (typeof filter == "undefined") filter = [];
  if (typeof role == "undefined") role = "admin";
  activeServerSideMessageHandler = handler;

  if (eventSource == null || (typeof filter != "undefined" && JSON.stringify(previousFilter) != JSON.stringify(filter))) {
    try {
      previousFilter = filter;

      if (eventSource != null) {
        eventSource.removeEventListener("message");
        eventSource.close();
      }

      user_id = localStorage.id;
      try {
        eventSource = new window.EventSourcePolyfill(
          auth.server + "/eatwithme.server/waitFilter/" + sessionStorage.restaurantSelected + "/" + auth.device.platform + "/" + role + "?sessionId=" + auth.myStatus.sessionId + (isIncludedInFrame ? "&frame=true" : "") + "&filter=" + encodeURIComponent(JSON.stringify(filter)) + "&sessionCrc=" + window.sessionCrc,
          {
            heartbeatTimeout: 35000,
            lastEventId: localStorage.lastEventId,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
            withCredentials: true
          }
        );

        const activeEventSource = eventSource;

        eventSource.addEventListener("message", function (message) {
          if (message.data) localStorage.lastEventId = message.lastEventId;
          try {
            var data = JSON.parse(message.data);
            //console.log(data.type + " " + location.href);
            if (data.type === "restart") {
              console.log("Server is being restarted");
              auth.restart = true;
              if (typeof auth.messageDialogHandle === 'undefined') {
                auth.messageDialogHandle = messageDialogTemp(local.information, local.server_restart);
                auth.messageDialogHandle.done(() => {
                  delete auth.messageDialogHandle;
                });
              }
              return;
            } else if (data.type === "ready") {

              if (auth.restart) {
                try {
                  console.log("SSE reconnected")
                  if (typeof auth.messageDialogHandle !== 'undefined') {
                    console.log("Close message")
                    auth.messageDialogHandle.resolve();
                  }
                } catch (ex) {
                  console.error(ex)
                }
              } else {
                console.log("SSE connected");
              }
              if (Number(localStorage.eatwithmeWaitError) > 0) {
                console.log("Recoverig from SSE error");
                $("body").removeClass("disconnected");
                if (typeof auth.networkErrorHandler != "undefined") auth.networkErrorHandler.connected();
                ajaxErrorEnd("error");
                localStorage.eatwithmeWaitError = 0;
                console.log("Refresh...", !(!recoverFromEventSourceError));
                if (recoverFromEventSourceError) {
                  console.log("Resynchronizing...");
                  recoverFromEventSourceError();
                  if (typeof auth.networkErrorHandler != "undefined") auth.networkErrorHandler.connected();
                  ajaxErrorEndForced();
                }
              }
              localStorage.eatwithmeWaitError = 0;
              $("body").removeClass("disconnected");
              ajaxErrorEnd("error");
              ajaxErrorEndForced();

              auth.restart = false;
              /*
              if (typeof localStorage.pushToken != undefined && localStorage.pushToken !== "undefined") 
                get("customerService/setPushNotification?deviceId=" + encodeURIComponent(localStorage.pushToken)).fail(() => {
                  auth.ajaxError = false;
                });
              */

              try {
                if (!cp) {
                  setTimeout(codepush, 10000);
                }
              } catch (ex) {
                console.log(ex);
              }

              return;
            }
            if (data.type === "refresh" && data.message === "refresh needed") {
              if (recoverFromEventSourceError) {
                _log("Resynchronizing...");
                recoverFromEventSourceError();
              }
            }
            if (data.type === "close" && data.message === "true") {
              if (eventSource)
                eventSource.close();
              eventSource = null;
              _startpoll(activeServerSideMessageHandler, filter, role);
            }
            if (data.type == "logout") {
              if (location.href != undefined && location.href.indexOf("/#/login") == -1) {
                console.log("#######################################################");
                console.log("navigation to login page because recevied logout from wait");
                navigateToLogin();
              }
              return;
            }
            if (data.data) {
              try {
                data.data = JSON.parse(data.data)
              } catch (ex) { }
            }
            activeServerSideMessageHandler(data);
          } catch (ex) {
            console.log(ex);
            console.log(ex.stack)
            console.log(message.data);
          }
          if (user_id != localStorage.id) {
            if (eventSource)
              eventSource.close();
            eventSource = null;
            _startpoll(activeServerSideMessageHandler, filter, role);
          }
        });

        eventSource.addEventListener("open", function (data) {
          if (localStorage.eatwithmeWaitError > 1)
            console.log("SSE Open after ", localStorage.eatwithmeWaitError, "errors");
          recoverFromError = recoverFromEventSourceError;
          auth.recoverFromError = recoverFromEventSourceError;
        });

        eventSource.addEventListener("close", function (data) {
          //localStorage.eatwithmeWaitError = 1;
          console.log("SSE Closed");
          recoverFromError = null;
        });



        eventSource.addEventListener("error", function (data) {
          console.log("SSE error")
          checkIfSessionExpired("", "error", data).done(() => {
            if (auth.restart == true && (data.resultText == undefined || data.resultText != '{"error":"unauthorized"}')) {
              if (eventSource)
                eventSource.close();
              eventSource = null;
              setTimeout(function () {
                try {
                  console.log("EventSource restarted");
                  _startpoll(activeServerSideMessageHandler, filter, role, recoverFromEventSourceError);
                } catch (ex) {
                  console.log("EventSource connection failed");
                }
              }, 2000);
              return;
            }
            if (data.type == "error" && localStorage.eatwithmeWaitError < 100) {
              if (data.resultText && data.resultText == '{"error":"unauthorized"}') {
                console.log("########## SSE ERROR2 #############################################");
                console.log(auth.myStatus);
                if (auth.server !== auth.globalserver && auth.myStatus.restaurant_global_id) {
                  const s = auth.server;
                  auth.server = auth.globalserver;
                  eventSource.close();
                  switchServer({ globalId: auth.myStatus.restaurant_global_id, serverUrl: s }).done(() => {
                    eventSource = null;
                    _startpoll(activeServerSideMessageHandler, filter, role, recoverFromEventSourceError);
                  }).fail(() => {
                    if (location.href.indexOf("/login") === -1)
                      navigateToLogin(location.href);
                  });
                  return;
                }
                if (location.href.indexOf("/login") === -1)
                  navigateToLogin(location.href);
              }
              if (auth.suppressNetworkError) {
                $("body").addClass("disconnected");
                if (typeof auth.networkErrorHandler != "undefined") auth.networkErrorHandler.disconnected();
                localStorage.eatwithmeWaitError = 0;
              } else {
                if (localStorage.eatwithmeWaitError > 3) {
                  auth.restart = true;
                  if (typeof auth.messageDialogHandle === 'undefined') {
                    auth.messageDialogHandle = messageDialogTemp(local.information, local.server_unavailable);
                    auth.messageDialogHandle.done(() => {
                      delete auth.messageDialogHandle;
                    });
                  }

                  //ajaxErrorStart(local.server_unavailable, communication_error_img);
                }
              }
              if (eventSource)
                eventSource.close();
              if (data.target.readyState == 2 && !(data.resultText && data.resultText == '{"error":"unauthorized"}')) {
                setTimeout(function () {
                  eventSource = null;
                  _startpoll(activeServerSideMessageHandler, filter, role, recoverFromEventSourceError);
                }, 2000);
              }
              localStorage.eatwithmeWaitError = Number(localStorage.eatwithmeWaitError) + 1;
              return;
            }
            ajaxCallStart("processing3");
            console.log("########## SSE ERROR3 #############################################");
            navigateToLogin();
          }).fail(data => {
            if (data.type == "error") {
              if ((data.resultText && data.resultText == '{"error":"unauthorized"}') || data.status == 403) {
                console.log("########## SSE ERROR2 #############################################");
                console.log(auth.myStatus);
                if (auth.server !== auth.globalserver && auth.myStatus.restaurant_global_id) {
                  const s = auth.server;
                  auth.server = auth.globalserver;
                  eventSource.close();
                  switchServer({ globalId: auth.myStatus.restaurant_global_id, serverUrl: s }).done(() => {
                    eventSource = null;
                    _startpoll(activeServerSideMessageHandler, filter, role, recoverFromEventSourceError);
                  }).fail(() => {
                    if (location.href.indexOf("/login") === -1)
                      navigateToLogin(location.href);
                  });
                  return;
                }
                if (location.href.indexOf("/login") === -1)
                  navigateToLogin(location.href);
              }
              if (auth.suppressNetworkError) {
                $("body").addClass("disconnected");
                if (typeof auth.networkErrorHandler != "undefined") auth.networkErrorHandler.disconnected();
                localStorage.eatwithmeWaitError = 0;
              } else {
                if (localStorage.eatwithmeWaitError > 3) {
                  auth.restart = true;
                  if (typeof auth.messageDialogHandle === 'undefined') {
                    auth.messageDialogHandle = messageDialogTemp(local.information, local.server_unavailable);
                    auth.messageDialogHandle.done(() => {
                      delete auth.messageDialogHandle;
                    });
                  }

                  //ajaxErrorStart(local.server_unavailable, communication_error_img);
                }
              }
              if (eventSource)
                eventSource.close();
              if (data.target.readyState == 2 && !(data.resultText && data.resultText == '{"error":"unauthorized"}')) {
                setTimeout(function () {
                  eventSource = null;
                  _startpoll(activeServerSideMessageHandler, filter, role, recoverFromEventSourceError);
                }, 2000);
              }
              localStorage.eatwithmeWaitError = Number(localStorage.eatwithmeWaitError) + 1;
              return;
            }
            ajaxCallStart("processing3");
            console.log("########## SSE ERROR3 #############################################");
            navigateToLogin();
          });
        });
      } catch (ex) {
        console.log(ex);
        console.log("EventSource failed to start");
      }

      window.onunload = function () {
        if (eventSource)
          eventSource.close();
      };
    } catch (ex) {
      console.log(ex.stack);
      console.log("########## SSE ERROR4 " + ex + " #############################################");
      navigateToLogin();
    }
  }
};
auth.startpoll = startpoll;

function resolve(where, what) {
  if (typeof what !== "string") return what;

  if (what.startsWith("&") && what.indexOf(":") > 0) {
    type2 = what.substring(1).split(":")[0];
    what2 = what.substring(1).split(":")[1];
  }

  var path = "$..*[?(@.type=='" + type2 + "' && @.id=='" + what2 + "')]";
  var result = jsonPath(where, path)[0];
  if (result == null) {
    console.log("Failed to find reference: " + what);
  }
  return result;
}

export const confirmDialog = (title, msg) => {
  return choiceDialog2(title, msg, [local.ok]);
};
auth.confirmDialog = confirmDialog;

export const choiceDialog = (title, msg, button1, button2) => {
  return choiceDialog2(title, msg, [button1, button2]);
};
auth.choiceDialog = choiceDialog;

export const choiceDialog2 = (title, msg, buttonsArray) => {
  var def = $.Deferred();
  const dialog = $("<div id='dialog'/>");
  $("body").append(dialog);
  render(<ChoiceDialog def={def} title={title} message={msg} buttons={buttonsArray} show={true} container={dialog.get(0)} />, dialog.get(0));
  def.promise().always(() => {
    dialog.remove();
  })
  return def.promise();
};
auth.choiceDialog2 = choiceDialog2;

export const messageDialog = (title, msg = "") => {
  if (msg)
    _log(msg);
  if ($('div.modal-dialog div.modal-body > div').filter((ind, div) => msg && $(div).html().trim() == msg.trim()).length)
    return;
  var def = $.Deferred();
  const dialog = $("<div id='dialog'/>");
  $("body").append(dialog);
  render(<MessageDialog def={def} title={title} message={msg} show={true} container={dialog.get(0)} />, dialog.get(0));
  def.promise().always(() => {
    dialog.remove();
  })
  return def.promise();
};
auth.messageDialog = messageDialog;

export const messageDialogTemp = (title, msg, noOk = true) => {
  var def = $.Deferred();
  const dialog = $("<div id='dialog'/>");
  $("body").append(dialog);
  render(<MessageDialog def={def} title={title} message={msg} show={true} container={dialog.get(0)} noOk={noOk} />, dialog.get(0));
  def.promise().always(() => {
    dialog.remove();
  })
  return def;
};

export const getInputDialog = (title, msg, defaultValue = "", type = "number") => {

  function onChange(event) {
    console.log(event);
  }

  var def = $.Deferred();
  const dialog = $("<div id='dialog'/>");
  $("body").append(dialog);
  render(<ChoiceDialog def={def} message={msg} input="true" title={title} buttons={[local.ok]} show={true} container={dialog.get(0)} defaultValue={defaultValue} type={type} showKeyboard={true}>

  </ChoiceDialog>, dialog.get(0));
  def.promise().always(() => {
    dialog.remove();
  })
  return def.promise();
};
auth.getInputDialog = getInputDialog;

export const getInputDialog2 = (title, msg, defaultValue = "", pattern) => {
  var dialog = $("<div class='messageDialog' title='" + title + "' style='display:flex;flex-flow:column;justify-content:center'>" + msg + "<input style='margin-top:20px' type='text' value='" + defaultValue + "'/></div>");
  var def = $.Deferred();

  setTimeout(() => {
    const d = $(dialog).dialog({
      resizable: false,
      autoOpen: true,
      modal: true,
      dialogClass: "eatwithme no-close",
      width: 'auto',
      height: 'auto',
      create: function (e, ui) {
        $(this).parent().css("z-index", "20000");
      },
      buttons: [{
        id: "CANCEL",
        text: local.cancel,
        class: 'negative',
        click: function () {
          $(this).dialog("close");
          def.reject({
            choice: "CANCEL"
          });
        }
      }, {
        id: "OK",
        text: local.ok,
        class: 'btn btn-primary',
        click: function () {
          const val = dialog.find("input").val();
          if (!pattern.test(val)) {
            dialog.find("input").removeClass("highlight-3");
            setTimeout(() => dialog.find("input").addClass("highlight-3"), 100);
            return false;
          }
          dialog.dialog("close");
          def.resolve(dialog.find("input").val());
        }
      }],
      close: function () {
        $(this).dialog("destroy");
      },
      open: function () {
        var b = $(this).parents('.ui-dialog');
        /*b.css('z-index',10000);*/
        b = b.find("input");
        b.focus();
        b.select();
      }
    });


    def.always(() => {
      try {
        dialog.dialog("destroy");
      } catch (ex) { }
    })
  }, 100);
  return def.promise();
}

function sendMessage(email, msg, type, value) {
  get("messageServer/" + sessionStorage.restaurantSelected + "/send" + "?to=" + email + "&key=refresh&message=" + msg + "&type=" + type + "&value=" + value).done(function (data) {
    if (data == null) {
      console.log("Failed to send message " + msg + " to " + email);
    } else {
      if (data.error != null) {
        messageDialog("Error message", data.error);
      } else if (data.success != null) {
      }
    }
  });
}
auth.sendMessage = sendMessage;

String.format = function () {
  var s = arguments[0];
  try {
    for (var i = 0; i < arguments.length - 1; i++) {
      var reg = new RegExp("\\{" + i + "\\}", "gm");
      s = s.replace(reg, arguments[i + 1]);
    }
  } catch (ex) {
    return s;
  }

  return s;
};

function getImage(email) {
  var r;
  $.ajax({
    type: "GET",
    crossDomain: true,
    async: true,
    contentType: "application/x-www-form-urlencoded;charset=UTF-8",
    url: auth.server + "/eatwithme.server/customerService/getImage?email=" + email,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-16"
      // "Authorization" : localStorage.hash
    },
    success: function (data) {
      r = data;
    }
  });
  if (r == null) return "";
  return r;
}

export const getImageUrl = (image) => {
  if (image)
    return auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + image;
  return "";
}

export const getMenuItemImageUrl = (menuItem, quantity) => {
  quantity = getStandardQuantity(quantity, menuItem.quantityType)
  const barcode = menuItem.barcodes.find(barcode => barcode.quantity === quantity);
  if (barcode) {
    return getImageUrl(barcode.image)
  }
  if (menuItem.image)
    return getImageUrl(menuItem.image)
  return "";
}

var imageCache = new Map();

function storeImage(id, src) {
  imageCache.set(Number(id), src);
}

export const getImageById = id => {
  return auth.globalserver + "/eatwithme.server/customerService/getImageById?id=" + id;// + "&r=" + new Date().getTime();
};

function insertImage(id, target) {
  image = imageCache.get(id);
  target.attr("src", image);
}

export const loadImage = (id) => {
  var def = new $.Deferred();
  var image = imageCache.get(id);

  var headers;

  // var ETag = (typeof image == 'undefined') ? '0' : id;
  $.ajax({
    type: "GET",
    crossDomain: true,
    async: true,
    contentType: "application/x-www-form-urlencoded;charset=UTF-8",
    url: auth.server + "/eatwithme.server/customerService/getImageById?id=" + id,// + "&r=" + new Date().getTime(),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-16"
      // "Authorization" : localStorage.hash
    }
  })
    .done(function (image) {
      storeImage(id, image);
      def.resolve();
    })
    .fail(function (image) {
      def.reject();
    });

  return def.promise();
}

function loadImages(ids) {
  var def = new $.Deferred();
  loadImagesByIndex(ids, 0, def);
  return def.promise();
}

var previousIds = new Set();
function loadImagesForTableMates(tableMates, ids) {
  if (typeof ids == "undefined") ids = new Set();
  previousIds.forEach(function (id) {
    ids.add(id);
  });
  tableMates.forEach(function (tableMateId) {
    var tableMate = getTableMate(tableMateId);
    if (tableMate.tableMate != null) {
      ids.add(tableMate.tableMate.id);
    }
  });
  var def = new $.Deferred();
  var idArray = [];
  ids.forEach(function (id) {
    if (!previousIds.has(id)) {
      idArray[idArray.length] = id;
      previousIds.add(id);
    }
  });
  loadImagesByIndex(idArray, 0, def);
  return def.promise();
}

function loadImagesByIndex(ids, index, def) {
  if (ids.length == 0) {
    def.resolveWith("a");
    return;
  }
  if (ids[index])
    return loadImage(ids[index]).done(function () {
      if (ids.length == index + 1) {
        def.resolveWith("a");
        return;
      }
      loadImagesByIndex(ids, index + 1, def);
    });
  else {
    if (ids.length == index + 1) {
      def.resolveWith("a");
      return;
    }
    loadImagesByIndex(ids, index + 1, def);
  }
}

function createMouseEvent(t, eventName, originalEvent) {
  var e = new MouseEvent(eventName, {
    view: window,
    detail: originalEvent.detail,
    bubbles: true,
    cancelable: true,
    clientX: originalEvent.clientX,
    clientY: originalEvent.clientY,
    screenX: originalEvent.screenX,
    screenY: originalEvent.screenY,
    ctrlKey: originalEvent.ctrlKey,
    shiftKey: originalEvent.shiftKey,
    altKey: originalEvent.altKey,
    metaKey: originalEvent.metaKey,
    button: originalEvent.button
  });

  e.synthetic = true;
  e._finger = t;

  return e;
}

export const clickHandler123 = trigger => {
  resetLockTimeout();
  if (typeof trigger.selectionDone != "undefined" || (typeof trigger != "undefined" && typeof trigger.originalEvent != "undefined" && typeof trigger.originalEvent.selectionDone != "undefined")) return;
  if (trigger.isDefaultPrevented()) return;
  if (trigger.type == "focusin") return;
  if (select(trigger.currentTarget, trigger)) {
    trigger.stopPropagation();
    if (typeof trigger != "undefined" && typeof trigger.originalEvent != "undefined") trigger.originalEvent.selectionDone = true;
    else trigger.selectionDone = true;
  }
};
auth.clickHandler = clickHandler123;

var clickHandler122 = function (e) {
  resetLockTimeout();
  e.target.dispatchEvent(createMouseEvent(e.target, "touchend", e));
};

var touchtime = 0;
var dblclick = false;

function touchHandler(event) {
  if ($(event.target).hasClass('select2-selection__rendered')) return;
  if ($(event.target).hasClass('select2-results__option')) return;
  if ($(event.target).parents('.select2').length > 0) return;
  if ($(event.target).parents('.daterangepicker').length > 0) return;
  if ($(event.target).hasClass('custom-control-label')) return;

  var touches = event.changedTouches,
    first = touches[0],
    type = "";
  switch (event.type) {
    case "touchstart":
      type = "mousedown";
      dblclick = false;
      touchtime = new Date().getTime();
      var touchtime2 = touchtime;
      setTimeout(function () {
        try {
          if (touchtime2 != touchtime) return;
          var simulatedEvent = document.createEvent("MouseEvent");
          simulatedEvent.initMouseEvent("dblclick", true, true, window, 1, first.screenX, first.screenY, first.clientX, first.clientY, false, false, false, false, 0 /* left */, null);
          first.target.dispatchEvent(simulatedEvent);
          dblclick = true;
          return;
        } catch (ex) {
          alert(ex);
        }
      }, 1000);
      break;
    case "touchmove":
      type = "mousemove";
      touchtime = new Date().getTime();
      if (typeof drag != "undefined" && drag) event.preventDefault();
      //	return;
      break;
    case "touchend":
      type = "mouseup";
      touchtime = new Date().getTime();
      break;
    default:
      return;
  }

  if (dblclick) return;

  var simulatedEvent = document.createEvent("MouseEvent");
  simulatedEvent.initMouseEvent("mouseover", true, true, window, 1, first.screenX, first.screenY, first.clientX, first.clientY, false, false, false, false, 0, null);
  first.target.dispatchEvent(simulatedEvent);

  var simulatedEvent = document.createEvent("MouseEvent");
  simulatedEvent.initMouseEvent(type, true, true, window, 1, first.screenX, first.screenY, first.clientX, first.clientY, false, false, false, false, 0, null);
  first.target.dispatchEvent(simulatedEvent);


  //event.defaultPrevented=true;
}

function init() {
  document.addEventListener("touchstart", touchHandler, {
    capture: true,
    passive: false
  });
  document.addEventListener("touchmove", touchHandler, {
    capture: true,
    passive: false
  });
  document.addEventListener("touchend", touchHandler, {
    capture: true,
    passive: false
  });
  document.addEventListener("touchcancel", touchHandler, {
    capture: true,
    passive: false
  });
}

init();

export const updateHooks = () => {
  var s = $(".selectable");
  s.off("click", clickHandler123);
  s.on("click", clickHandler123);
}
auth.updateHooks = updateHooks;

export const updateCheckBoxesById = (id, handler) => {
  $(id).each(function (ind, t) {
    _updateCheckBoxes(t, handler);
  });
};

function updateCheckBoxes(handler) {
  $(".list-group.checked-list-box .list-group-item:not(:disabled)").each(function (ind, t) {
    _updateCheckBoxes(t, handler);
  });
}

function _updateCheckBoxes(t, handler) {
  // Settings

  var $widget = $(t);
  var active = $widget.hasClass("active");
  var $checkbox = $('<input type="checkbox" class="hidden" ' + (active ? 'checked' : '') + '/>'),
    color = $widget.data("color") ? $widget.data("color") : "primary",
    style = $widget.data("style") == "button" ? "btn-" : "list-group-item-",
    settings = {
      on: {
        icon: "glyphicon glyphicon-check"
      },
      off: {
        icon: "glyphicon glyphicon-unchecked"
      }
    };


  $widget.css("cursor", "pointer");
  $widget.append($checkbox);
  updateDisplay();
  // Event Handlers
  $widget.off("click");
  $widget.on("click", function (event) {
    const c = $checkbox.is(":checked");
    $checkbox.prop("checked", !c);
    const c1 = $checkbox.is(":checked");
    $checkbox.triggerHandler("change");
    //updateDisplay();
  });
  $widget.off("change");
  $checkbox.on("change", function (e) {
    const c = $checkbox.get(0).checked;
    updateDisplay();
    if (typeof handler != "undefined") handler(e);
  });

  // Actions
  function updateDisplay() {
    var isChecked = $checkbox.get(0).checked;
    //console.log($widget.attr("id"), isChecked);

    // Set the button's state
    $widget.data("state", isChecked ? "on" : "off");

    // Set the button's icon
    $widget
      .find(".state-icon")
      .removeClass()
      .addClass("state-icon " + settings[$widget.data("state")].icon);

    // Update the button's color
    if (isChecked) {
      $widget.addClass(style + color + " active");
    } else {
      $widget.removeClass(style + color);
      $widget.removeClass("active");
    }
    $widget.triggerHandler("change");
  }

  // Initialization
  function init() {
    if ($widget.data("checked") == true || $widget.hasClass("active")) {
      $checkbox.prop("checked", true);
    }

    updateDisplay();

    // Inject the icon if applicable
    if ($widget.find(".state-icon").length == 0) {
      $widget.prepend('<span class="state-icon ' + settings[$widget.data("state")].icon + '"></span>');
    }
  }
  init();
}

export const executeLongJob = (jobName, url, isModal, data, handler) => {
  url = url.startsWith("http:") || url.startsWith("https:") ? url : auth.server + "/eatwithme.server/" + url;

  if (isIncludedInFrame) {
    if (url.indexOf("?") == -1) url += "?frame=true";
    else url += "&frame=true";
  }
  if (url.indexOf("?") == -1) url += "?sessionCrc=" + window.sessionCrc;
  else url += "&sessionCrc=" + window.sessionCrc;


  var def = new $.Deferred();
  if (isModal == undefined) isModal = true;

  if (isModal) ajaxCallStart(url);

  var job = startJob(jobName);
  try {
    var eventSource = new window.EventSourcePolyfill(url, {
      heartbeatTimeout: 300000,
      timeout: 300000,
      lastEventId: localStorage.lastEventId,
      data: data,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json"
      },
      withCredentials: true,
      onmessage: function (message) {
        var result = JSON.parse(message.data.replace('\t', ''));
        if (result.progress) {
          jobProgress(job, result.progress);
        } else if (result.start) {
          jobProgressName(job, result.start);
        } else if (result.progressGoal) {
          jobProgressGoal(job, result.progressGoal);
        } else if (result.result) {
          if (isModal) ajaxCallEnd(url);
          def.resolve(result.result);
        } else if (result.error) {
          messageDialog(local.error_message, job.data('name') + "<br/><i>" + result.error + "</i>");
          if (isModal) ajaxCallEnd(url);
          def.reject(result.error);
        } else if (result.finished) {
          if (handler)
            handler(result.finished);
          endJob(job);
          eventSource.close();
        }
      },
      onError: function (message) {
        //alert(1);
        if (isModal) ajaxCallEnd(url);
        def.reject();
        eventSource.close();
        endJob(job);
      }
    });
  } catch (ex) {
    alert(ex);
    throw ex;
  }

  return def.promise();
};

export const getString = (url, ETAG, headers, timeout, isModal, withCredentials) => {
  return get(
    url,
    ETAG,
    {
      Accept: "text/html",
      "Content-Type": "text/html"
    },
    timeout,
    isModal,
    withCredentials,
    true, "text"
  );
};

export const updateServerUrl = () => {
  const regexp = /https:\/\/.*(:\d+)?\/([^\/]+)/;
  const match = auth.server.match(regexp);
  const def = new $.Deferred();
  if (match) {
    const instance = match[2];
    getGlobal("tableService/getRestaurantByInstance2/" + instance + "/" + localStorage.instance).done(d => {
      const newserver = d.serverUrl.substring(0, d.serverUrl.length - 1);
      if (auth.server !== newserver) {
        console.log("Changed to " + newserver);
        auth.server = newserver;
        localStorage.globalId = d.globalId;
      }
      def.resolve();
    }).fail(() => {
      def.resolve();
    });
  } else {
    def.resolve();
  }
  return def.promise();
}

var checkIfSessionExpiredDef = undefined;


function checkIfSessionExpired(url, exception, jqXHR, relogin = true) {
  if (checkIfSessionExpiredDef)
    return checkIfSessionExpiredDef.promise();
  const def = new $.Deferred();

  if (!relogin || url.indexOf("getMyStatus") !== -1 || url.indexOf("logout") !== -1 || url.indexOf("login") !== -1) {
    def.reject(jqXHR, exception);
    return def.promise();
  }


  checkIfSessionExpiredDef = def;

  if (!(jqXHR.status == 403 || jqXHR.status == 302 || jqXHR.status == 401)) {
    checkIfSessionExpiredDef = undefined;
    def.reject(jqXHR, exception);
  } else {
    if (exception === "error" && url.indexOf("eatwithme.server/adminService/0/getMyStatus") === -1 && url.indexOf("eatwithme.server/login/changeSession") === -1) {
      setTimeout(() =>
        get("adminService/" + sessionStorage.restaurantSelected + "/getMyStatus?failedUrl=" + url).done(data => {
          checkIfSessionExpiredDef = undefined;
          def.resolve();
        }).fail(data => {
          ajaxCallEnd(url);
          auth.ajaxError = false;
          updateServerUrl().always(() => {
            auth.ajaxError = false;
            if ((data.status === 403 || data.status === 400) && auth.server !== auth.globalserver) {
              if (!isGlobalServer(auth.server) && location.href.indexOf("/#/login") == -1) {
                const s = auth.server;
                auth.server = auth.globalserver;
                switchServer({ globalId: localStorage.globalId || sessionStorage.restaurantSelected, serverUrl: s, instance: localStorage.instance }).done(() => {
                  checkIfSessionExpiredDef = undefined;
                  def.resolve();
                  //location.reload();
                }).fail(() => {
                  checkIfSessionExpiredDef = undefined;
                  if (location.href.indexOf("/login") === -1)
                    navigateToLogin(location.href);
                });
              } else {
                checkIfSessionExpiredDef = undefined;
                if (!location.href.indexOf("/#/login"))
                  def.resolve();
              }
            } else {
              checkIfSessionExpiredDef = undefined;
              if (location.href.indexOf("/login") === -1)
                navigateToLogin(location.href);
              def.reject(jqXHR, exception);
            }
          });
        }), 1000);
    } else {
      checkIfSessionExpiredDef = undefined;
      def.reject(jqXHR, exception);
    }
  }
  /*
} else {
  console.log("checkIfSessionExpired resolved");
  checkIfSessionExpiredDef = undefined;
  def.resolve();
}*/
  return def.promise();
}

export const get = (url, ETAG, headers, timeout, isModal, withCredentials, cache, datatype, relogin = true) => {
  return gett("GET", url, ETAG, headers, timeout, isModal, withCredentials, cache, datatype, relogin);
}

export const del = (url, ETAG, headers, timeout, isModal, withCredentials, cache, datatype, relogin = true) => {
  return gett("DELETE", url, ETAG, headers, timeout, isModal, withCredentials, cache, datatype, relogin);
}

export const gett = (method, url, ETAG, headers, timeout, isModal = true, withCredentials = true, cache = true, datatype, relogin = true) => {
  url = url.startsWith("http:") || url.startsWith("https:") ? url : auth.server + "/eatwithme.server/" + url;

  if (isIncludedInFrame) {
    if (url.indexOf("?") == -1) url += "?frame=true";
    else url += "&frame=true";
  }
  if (!url.startsWith("http:") && !url.startsWith("https:")) {
    if (url.indexOf("?") == -1) url += "?sessionCrc=" + window.sessionCrc;
    else url += "&sessionCrc=" + window.sessionCrc;
  }

  const def = new $.Deferred();
  headers = headers
    ? headers
    : {
      Accept: "application/json",
      "Content-Type": "application/json"
      //"X-Requested-With" : "XMLHttpRequest",
    };
  console.log("GET " + url);
  if (isModal) ajaxCallStart(url);
  try {
    $.ajax({
      type: method,
      timeout: typeof timeout != "undefined" ? timeout : 60000,
      crossDomain: true,
      async: true,
      cache: cache,
      contentType: "application/x-www-form-urlencoded;charset=UTF-8",
      dataType: datatype,
      url: url,
      headers: headers,
      success: function (data, status, xhr) {
        console.log("GET SUCCESS:" + url);
        if (isModal) ajaxCallEnd(url);
        def.resolve(data, status, xhr);
      },
      xhrFields: {
        withCredentials: withCredentials
      },
      error: function (jqXHR, exception) {
        console.log("GET FAILED:" + url + " " + jqXHR?.status);
        if (relogin && url.startsWith(auth.server) && !checkIfSessionExpiredDef && url.indexOf("getMyStatus") === -1 && url.indexOf("logout") === -1)
          checkIfSessionExpired(url, exception, jqXHR, relogin).done(() => {
            auth.ajaxError = false;
            ajaxCallEnd(url);
            gett(method, url, ETAG, headers, timeout, isModal, withCredentials, cache, datatype).done(data => {
              def.resolve(data)
            }).fail(def.reject);
          }).fail((jqXHR, exception) => {
            //console.log("checkIfSessionExpired: not, onload:" + unload);
            if (unload) {
              def.reject(jqXHR, exception);
              return;
            }
            auth.ajaxError = true;
            if (jqXHR?.status != 502 && jqXHR?.status != 503)
              def.reject(jqXHR, exception);
            //console.log("checkIfSessionExpired: not " + jqXHR.status + " " + auth.ajaxError);
            if (auth.ajaxError === true && (jqXHR.status == 403 || jqXHR.status == 302 || jqXHR.status == 401)) {
              // eslint-disable no-restricted-globals
              console.log("Received " + jqXHR.status + " from " + auth.server);
              if (jqXHR.status === 0 && exception != "timeout") {
                if (auth.ajaxError) ajaxErrorStart(exception, communication_error_img, exception + " " + url);
                return;
              } else if (location.href != undefined && location.href.indexOf("/#/login") == -1) {
                console.log("#######################################################");
                console.log("navigation to login page because recevied " + jqXHR.status + " for " + url);
                navigateToLogin();
              }
              return;
            }
            if (!auth.ajaxError) {
              if (isModal) ajaxCallEndForced(url);
              return;
            }
            if (jqXHR.status == 302) {
              console.log(jqXHR.responseText);
            } else if (jqXHR.status == 502 || jqXHR.status == 503) {
              setTimeout(() => {
                //if (!(isModal == false)) ajaxCallCountNonModal++;
                gett(method, url, ETAG, headers, timeout, isModal, withCredentials, cache, datatype).done(data => {
                  if (!(isModal == false)) ajaxCallEndForced(url);
                  def.resolve(data)
                });
              }, 2000);
            } else if (jqXHR.status == 400) {
              console.error("Requested resource not found. [400] for " + url);
              if (recoverFromError) {
                if (!(isModal == false)) ajaxCallEndForced(url);
                messageDialog(local.error_message, admin_local.something_strange_happened_resync).done(r => recoverFromError(false));
                return;
              }
              try {
                var v = eval(jqXHR.responseText);
                ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, v);
                return;
              } catch (ex) {
                ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
              }
              console.error("Requested page not found. [400] for " + url);
            } else if (jqXHR.status == 404) {
              if (!(isModal == false)) ajaxCallEndForced(url);
              ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
              console.error("Bad request. [404] for " + url);
            } else if (jqXHR.status == 409) {
              if (!(isModal == false)) ajaxCallEndForced(url);
              ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
              console.error("Bad request. [409] for " + url);
            } else if (jqXHR.status == 500) {
              if (!(isModal == false)) ajaxCallEndForced(url);
              ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
              console.error("Internal Server Error [500] for " + url);
            } else if (exception === "parsererror") {
              console.error("Requested JSON parse failed for " + url, jqXHR.responseText);
            } else if (exception === "timeout") {
              def.promise().fail(() => {
                if (auth.ajaxError) ajaxErrorStart("Timeout", error_code_500_img, "");
              });
            } else if (exception === "abort") {
              console.log("Ajax request aborted.");
            } else {
              //ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
              return;
            }
          });
        else {
          if (isModal) ajaxCallEndForced(url);
          def.reject(jqXHR);
        }
      }
    });
  } catch (ex) {
    alert(ex);
    throw ex;
  }

  return def.promise();
};
auth.get = get;

export const getGlobal = (url, ETAG, headers, cache = true) => {
  console.log(url, auth.globalserver);
  url = url.startsWith("http:") || url.startsWith("https:") ? url : auth.globalserver + "/eatwithme.server/" + url;
  var def = new $.Deferred();

  if (url.indexOf("?") == -1) url += "?sessionCrc=" + window.sessionCrc;
  else url += "&sessionCrc=" + window.sessionCrc;

  headers = headers
    ? headers
    : {
      Accept: "application/json",
      "Content-Type": "application/json"
      //"X-Requested-With": "XMLHttpRequest"
    };
  ajaxCallStart(url);
  $.ajax({
    type: "GET",
    timeout: 30000,
    crossDomain: true,
    async: true,
    contentType: "application/x-www-form-urlencoded;charset=UTF-8",
    url: url,
    cache: cache,
    headers: headers,
    success: function (data, status, xhr) {
      ajaxCallEnd(url);
      def.resolve(data, status, xhr);
    },
    xhrFields: {
      withCredentials: true
    },
    error: function (jqXHR, exception) {
      ajaxCallEndForced(url);
      auth.ajaxError = true;

      if (unload) {
        return;
      }
      def.reject(jqXHR);
      if (!auth.ajaxError)
        return;
      if (jqXHR.status === 0 && exception != "timeout") {
        def.promise().fail(() => {
          if (auth.ajaxError) ajaxErrorStart(exception, communication_error_img, exception + " " + url);
        });
        return;
      } else if (jqXHR.status == 403 || jqXHR.status == 401) {
        if (auth.ajaxError) {
          if (location.href != undefined && location.href.indexOf("/#/login") == -1) {
            console.log("#######################################################");
            console.log("navigation to login page because recevied " + jqXHR.status + " for " + url);
            navigateToLogin();
          }
        }
        return;
      } else if (jqXHR.status == 302) {
        console.log(jqXHR.responseText);
      } else if (jqXHR.status == 400) {
        console.error("Requested resource not found. [400] for " + url);
        if (recoverFromError) {
          if (!(isModal == false)) ajaxCallEndForced(url);
          messageDialog(local.error_message, admin_local.something_strange_happened_resync).done(r => (false));
          return;
        }
        try {
          var v = eval(jqXHR.responseText);
          ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, v);
          return;
        } catch (ex) {
          ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
        }
      } else if (jqXHR.status == 404) {
        if (!(isModal == false)) ajaxCallEndForced(url);
        ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
        console.error("Bad request. [404] for " + url);
      } else if (jqXHR.status == 500) {
        if (!(isModal == false)) ajaxCallEndForced(url);
        ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
        console.error("Internal Server Error [500] for " + url);
      } else if (exception === "parsererror") {
        console.error("Requested JSON parse failed for " + url, jqXHR.responseText);
      } else if (exception === "timeout") {
        def.promise().fail(() => {
          if (auth.ajaxError) ajaxErrorStart("Timeout", error_code_500_img, "");
        });
        console.log("Timeout error.");
      } else if (exception === "abort") {
        console.log("Ajax request aborted.");
      } else {
        console.log(exception);
        ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
        return;
      }
      def.reject(jqXHR.status);
    }
  });

  return def.promise();
};
auth.getGlobal = getGlobal;

export const post = (url, data, headers, isModal, retryCount, silentError, timeout, withCredentials = true) => {
  return postt("POST", url, data, headers, isModal, retryCount, silentError, timeout, withCredentials);
}

export const put = (url, data, headers, isModal, retryCount, silentError, timeout, withCredentials = true) => {
  return postt("PUT", url, data, headers, isModal, retryCount, silentError, timeout, withCredentials);
}

export const patch = (url, data, headers, isModal = true, retryCount, silentError, timeout, withCredentials = true) => {
  return postt("PATCH", url, data, headers, isModal, retryCount, silentError, timeout, withCredentials);
}

export const postt = (method, url, data, headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
}, isModal, retryCount, silentError, timeout, withCredentials = true) => {
  if (typeof retryCount == "undefined") retryCount = 1;
  if (typeof silentError == "undefined") silentError = false;
  if (typeof timeout == "undefined") timeout = 30000;

  var d;
  if (Array.isArray(data)) {
    d = data;
    delete d._key;
  } else d = data;

  if (isIncludedInFrame) {
    if (url.indexOf("?") == -1) url += "?frame=true";
    else url += "&frame=true";
  }
  if (!url.startsWith("https:")) {
    if (url.indexOf("?") == -1) url += "?sessionCrc=" + window.sessionCrc;
    else url += "&sessionCrc=" + window.sessionCrc;
  }


  var def = new $.Deferred();
  if (!(isModal == false)) ajaxCallStart(url);
  else ajaxCallCountNonModal++;

  console.log("POST " + url);

  //console.log("ajaxCallCountNonModal", ajaxCallCountNonModal);
  //console.log("ajaxCallCount", ajaxCallCount);
  $.ajax({
    type: method,
    crossDomain: true,
    timeout: timeout,
    async: true,
    url: url.startsWith("http:") || url.startsWith("https:") ? url : (auth.server || localStorage.server || "https:restaurant.eatwithme.online") + "/eatwithme.server/" + url,
    headers: headers,
    data: typeof d === "string" ? d : JSON.stringify(d),
    dataType: !headers['Accept'] || headers['Accept'] === "application/json" ? "json" : "text",
    xhrFields: {
      withCredentials: withCredentials
    },
    error: function (jqXHR, exception) {
      console.log("POST FAILED for " + url);
      checkIfSessionExpired(url, exception, jqXHR, !silentError).done(() => {
        auth.ajaxError = false;
        ajaxCallEnd(url);
        postt(method, url, data, headers, isModal, retryCount, silentError, timeout, withCredentials).done(data => {
          def.resolve(data)
        }).fail(def.reject);
      }).fail((jqXHR, exception) => {
        if (url.indexOf("tableService/clientError") !== -1) {
          return;
        }
        if (unload) {
          return;
        }
        if (jqXHR.status === 0) {
          if (retryCount > 1) {
            setTimeout(function () {
              post(url, data, headers, isModal, retryCount - 1, silentError, timeout)
                .done(data => {
                  def.resolve(data);
                })
                .fail(data => {
                  def.reject(data);
                });
            }, 2000);
            return;
          } else {
            /*
            def.reject({ error: "'" + exception + "'" });
            if (!silentError) ajaxErrorStart(exception, communication_error_img);
            return;
            */
          }
        }
        auth.ajaxError = true;
        if (jqXHR?.status != 502 && jqXHR?.status != 503) {
          def.reject(jqXHR);
        }
        if (!auth.ajaxError) {
          if (!(isModal == false)) ajaxCallEndForced(url);
          else ajaxCallCountNonModal--;
          return;
        }

        if (jqXHR.status == 403) {
          ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
          navigateToLogin();
          return;
        } else if (jqXHR.status == 502 || jqXHR.status == 503) {
          setTimeout(() => {
            //if (!(isModal == false)) ajaxCallEndForced(url);
            //else ajaxCallCountNonModal--;
            postt(method, url, data, headers, isModal, 2, silentError, timeout, withCredentials).done(data => {
              if (!(isModal == false)) ajaxCallEndForced(url);
              def.resolve(data)
            }).fail(def.reject);
          }, 2000);
        } else if (jqXHR.status == 400) {
          console.error("Requested resource not found. [400] for " + url, "post=" + JSON.stringify(data));
          if (recoverFromError) {
            if (!(isModal == false)) ajaxCallEndForced(url);
            messageDialog(local.error_message, admin_local.something_strange_happened_resync).done(r => recoverFromError(false));
            return;
          }
          try {
            var v = eval(jqXHR.responseText);
            ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, v);
            return;
          } catch (ex) {
            ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
          }
        } else if (jqXHR.status == 404) {
          if (!(isModal == false)) ajaxCallEndForced(url);
          ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
          console.error("Bad request. [404] for " + url);
        } else if (jqXHR.status == 500) {
          if (!(isModal == false)) ajaxCallEndForced(url);
          ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
          console.error("Internal Server Error [500] for " + url, "post=" + JSON.stringify(data) + "\n\nresponse=" + jqXHR.responseText);
        } else if (exception === "parsererror") {
          console.error("Requested JSON parse failed for " + url, "post=" + JSON.stringify(data) + "\n\nresponse=" + jqXHR.responseText);
        } else if (exception === "timeout") {
          if (retryCount > 1) {
            setTimeout(function () {
              post(url, data, headers, false, retryCount - 1)
                .done(data => {
                  def.resolve(data);
                })
                .fail(data => {
                  def.reject(data);
                });
            }, 1000);
          }
          console.log("Time out error.");
        } else if (exception === "abort") {
          console.log("Ajax request aborted.");
        } else {
          //console.error(exception);
          //ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
          return;
        }
      });
    },
    success: function (data) {
      if (!(isModal == false)) ajaxCallEnd(url);
      else ajaxCallCountNonModal--;
      def.resolve(data);
    }
  });
  return def.promise();
};
auth.post = post;

export const startJob = jobName => {
  var now = new Date().getTime();
  if ($("body #progress").length == 0) $('<div id="progress" style="flex-shrink:0;display:flex;justify-content: center;align-items: center;flex-wrap:wrap"></div>').appendTo("body");
  var job = $(
    '<div id="progress' + now + '" style="display:flex;margin-top:2px"><span style="padding-left:10px;padding-right:10px;line-height: 20px;">' +
    jobName +
    ' :</span><svg class="progress" style="position:relative;bottom:0;flex-shrink:0;height:18px"/></div>'
  );
  $("#progress").append(job);
  job = $("body #progress #progress" + now);
  job.data(
    "p",
    job.find('.progress').Progress({
      percent: 0,
      width: 200,
      height: 20,
      backgroundColor: "gray",
      increaseSpeed: 1000000
    })
  );
  job.data("goal", 100);
  return job;
};

export const jobProgress = (job, percent) => {
  if (job && job.data("p"))
    job.data("p").percent(Math.round((100 * percent) / job.data("goal")));
}

export const jobProgressGoal = (job, goal) => {
  if (job)
    job.data("goal", goal);
}

export const jobProgressName = (job, name) => {
  var name = eval(name);
  job.data('name', name)
  job.find("span").html(name);
}

export const endJob = (job) => {
  job.remove();
}

var ajaxCallCount = 0;
var ajaxCallCountNonModal = 0;
var callStack = [];
var ajaxCallClickTimeout = null;
auth.ajaxError = false;
export const ajaxCallStart = (url, timeout = 100) => {
  if (ajaxCallCount == 0) {
    if (timeout == 0) {
      $('.jquery-loading-modal').show();
      $("body").loadingModal("show");
    } else {
      setTimeout(function () {
        if (ajaxCallCount > 0) {
          $('.jquery-loading-modal').show();
          $("body").loadingModal("show");
        }
      }, timeout);
    }
  }
  $("body .jquery-loading-modal .sk-circle").removeClass('icon-arrows-ccw');
  if (ajaxCallEnd)
    clearTimeout(ajaxCallClickTimeout);
  ajaxCallClickTimeout = setTimeout(() => {
    $("body .jquery-loading-modal .sk-circle").on('click', () => location.reload());
    $("body .jquery-loading-modal .sk-circle").addClass('icon-arrows-ccw');
  }, 30000)

  ajaxCallCount++;
  callStack.push(url);
  //console.log("ajaxCallStart", ajaxCallCount, url, callStack);
  //console.log('ajaxCallStart('+url+') '+ajaxCallCount);
};
auth.ajaxCallStart = ajaxCallStart;

export const getAjaxCallCount = () => {
  return ajaxCallCount + ajaxCallCountNonModal;
};

export const ajaxCallEnd = url => {
  //

  const found = callStack.indexOf(url) >= 0;


  if (ajaxCallCount == 0)
    _log("ajaxCallEnd", ajaxCallCount, url, callStack);
  //console.info("ajaxCallEnd", ajaxCallCount, url, callStack);

  if (found)
    callStack.splice(callStack.indexOf(url), 1);
  else {
    _log("ajaxCallEnd not found:" + url);
  }

  //if (found && ajaxCallCount > 0) ajaxCallCount--;
  if (ajaxCallCount > 0) ajaxCallCount--;

  //console.log('ajaxCallEnd(' + url + ') ' + ajaxCallCount);

  if (ajaxCallCount == 0) {
    //console.log("ajaxCallEnd",ajaxCallCount, url, callStack);
    //$("body").loadingModal("hide");
    $(".jquery-loading-modal").hide();
    if (ajaxCallEnd)
      clearTimeout(ajaxCallClickTimeout);
    ajaxCallClickTimeout = null;
    $("body .jquery-loading-modal .sk-circle").removeClass('icon-arrows-ccw');
  }
};
auth.ajaxCallEnd = ajaxCallEnd;

export const ajaxCallEndForced = url => {
  ajaxCallCount = 0;
  $(".jquery-loading-modal").hide();
  //$("body").loadingModal("hide");
};

export const ajaxErrorStart = (message, img, errorText) => {
  ajaxCallEndForced();
  if (unload) {
    return;
  }
  auth.ajaxError = true;
  $("#errorModal #errorMessage").html(local[message] ? local[message] : message);
  if (typeof errorText == undefined) $("#errorModal #errorText").html("");
  else $("#errorModal #errorText").html(errorText);
  $("#errorModal #errorImg").attr("src", img);
  setTimeout(function () {
    if (auth.ajaxError == true) {
      $("#errorModal").modal("show");
      $("#errorModal")
        .modal()
        .show();
    }
  }, 200);
};

export const ajaxErrorEnd = () => {
  if (auth.ajaxError) {
    auth.ajaxError = false;
    if ($("#errorModal.show").length) {
      $("#errorModal.show").modal("hide");
      //$(".modal-backdrop").hide();
    }
    //$(".modal-backdrop").hide();
  }
};

export const ajaxErrorEndForced = () => {
  auth.ajaxError = false;
  if ($("#errorModal.show").length) {
    $("#errorModal.show").modal("hide");
    //$(".modal-backdrop").hide();
  }
};

function postString(url, data, headers) {
  var def = new $.Deferred();
  $.ajax({
    type: "POST",
    crossDomain: true,
    async: true,
    url: auth.server + "/eatwithme.server/" + url,
    headers: headers
      ? headers
      : {
        Accept: "application/json",
        "Content-Type": "text/html"
      },
    xhrFields: {
      withCredentials: true
    },
    data: data,
    success: function (data) {
      def.resolve(data);
    },
    error: function (jqXHR, exception) {
      if (unload) {
        return;
      }
      if (jqXHR.status === 0) {
        if (exception != "timeout") {
          console.log(exception + ": " + url);
        }
        def.reject(local[exception]);
        return;
      } else if (jqXHR.status == 400) {
        console.log(jqXHR.responseText);
      } else if (jqXHR.status == 404) {
        console.log("Requested page not found. [404]");
      } else if (jqXHR.status == 500) {
        ajaxErrorStart("error_code_" + jqXHR.status, error_code_500_img, jqXHR.responseText);
        console.log("Internal Server Error [500].");
      } else if (exception === "parsererror") {
        console.log("Requested JSON parse failed.");
      } else if (exception === "timeout") {
        console.log("Time out error.");
      } else if (exception === "abort") {
        console.log("Ajax request aborted.");
      } else {
        console.log(exception);
        return;
      }
      def.reject(jqXHR.status);
    },
    statusCode: {
      404: function () {
        console.log("Service " + url + " not found. [404]");
      },
      405: function () {
        alert("Requested operation forbidden. [403]");
      },
      403: function () {
        console.log("Service " + url + " not found. [405]");
      }
    }
  });
  return def.promise();
}
auth.postString = postString;

function facebookLogin(code, appServer) {
  var def = $.Deferred();
  getGlobal("login/facebookAuth?accessToken=" + code + "&redirect=" + globalAppServer)
    .done(function (data) {
      loginBackground(auth.globalserver + "/eatwithme.server/login/facebookAuth?accessToken=" + code + "&redirect=" + globalAppServer)
        .done(() => {
          if (data == null) {
            alert("Failed to authenticate user!");
            return;
          }
          localStorage.id = data.customer.id;
          localStorage.email = data.customer.email;
          localStorage.name = data.customer.name;
          localStorage.surname = data.customer.surname;
          localStorage.sessionId = data.sessionId;
          localStorage.type = "eatwithme";
          localStorage.removeItem("locked");
          if (typeof auth.myStatus != "undefined") auth.myStatus.customers = data.customers;

          if (!isGlobalServer(auth.server)) {
            console.log("Trying to log into the global server as well");
            getGlobal("login/getLoginToken")
              .done(token => {
                var loginUrl =
                  auth.server +
                  "/eatwithme.server/login/loginWithToken?token=" +
                  token.id +
                  "&globalId=" +
                  (data.restaurant_global_id ? restaurant.restaurant_global_id : 0) +
                  "&localId=" +
                  (data.restaurant_id ? data.restaurant_id : 0);
                get(loginUrl, undefined, undefined, 30000).done(data => {
                  localStorage.sessionId = data.sessionId;

                  loginBackground(loginUrl)
                    .done(() => {
                      def.resolve();
                    })
                    .fail(() => {
                      def.reject();
                    });
                });
              })
              .fail(() => {
                def.reject();
              });
          } else {
            def.resolve();
          }
        })
        .fail(() => {
          def.reject();
        });
    })
    .fail(function (data) {
      def.reject();
    });
  return def.promise();
}

function checkSocialLoginStatus() {
  var def = new $.Deferred();
  var code = getParameterByName("code");
  var state = getParameterByName("state");
  if (code != null && state == localStorage.state) {
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    post("login/facebookAuth", {
      email: "",
      password: code,
      redirect: appServer,
      authenticationType: "facebook",
      firstName: "",
      surName: "",
      email: "",
      image: ""
    })
      .done(function (data) {
        if (data == null) {
          navigateToLogin();
        } else {
          localStorage.email = data.customer.email;
          localStorage.id = data.customer.id;
          localStorage.name = data.customer.name;
          localStorage.type = "facebook";
          localStorage.locked = false;
          if (typeof localStorage.redirectUrl != "undefined") {
            var url = localStorage.redirectUrl;
            localStorage.removeItem("redirectUrl");
            navigateTo(url);
          } else navigateTo("index.html");
        }
      })
      .fail(function (data) {
      })
      .always(function (data) {
        def.resolve();
      });
  } else {
    if (typeof localStorage.id == "undefined" || localStorage.id == null) {
      def.reject();
    } else def.resolve();
  }
  return def.promise();
}
auth.checkSocialLoginStatus = checkSocialLoginStatus;

function clearlocalStorage() {
  localStorage.removeItem("getOccupiedTable");
  localStorage.removeItem("getSubprofiles");
  localStorage.removeItem("myImage");
  localStorage.removeItem("getMyStatus");
}

export const loadMyImage = () => {
  //$(".user-img").each(function (ind, userimg) {
  //if ($(userimg).attr("src") != "") return;
  //var id = $(userimg).attr("id");
  //if (id == null) id = localStorage.id;
  //$(userimg).attr("src", auth.globalserver + "/eatwithme.server/customerService/getImageById?id=" + localStorage.id);
  /*
  loadImage(id).done(function() {
    $(userimg).attr('src', getImageById(id));
  });
  */
  //});
  $("#user_img,.myuser_img").each(function (ind, userimg) {
    //if ($(userimg).attr("src") != "") return;
    var id = $(userimg).attr("id");
    //if (id == null) id = localStorage.id;
    if (auth.myStatus && auth.myStatus.global_id) {
      if ($(userimg).attr("src") !== getImageById(auth.myStatus.global_id)) {
        $(userimg).attr("src", "");
        $(userimg).attr("src", getImageById(auth.myStatus.global_id));
      }
    }
    /*
    loadImage(id).done(function() {
      $(userimg).attr('src', getImageById(id));
    });
    */
  });
};

auth.loadMyImage = loadMyImage;

export const getMyImageUrl = () => {
  if (auth.myStatus)
    return auth.globalserver + "/eatwithme.server/customerService/getImageById?id=" + (auth.myStatus.global_id ? auth.myStatus.global_id : auth.myStatus.id) + "&timestamp=" + auth.myStatus.imageTimestamp;// + "&r=" + new Date().getTime();
  else
    return "img/profle-48.png";
};

auth.getMyImageUrl = getMyImageUrl;

var tawkTo = false;
function getMyStatus(handler, failHandler) {
  if (typeof sessionStorage.restaurantSelected == "undefined" || sessionStorage.restaurantSelected == "undefined") {
    sessionStorage.restaurantSelected = 0;
    get("login/setRestaurant/" + sessionStorage.restaurantSelected).done(() => {
      get("tableService/" + sessionStorage.restaurantSelected + "/getMyStatus?lang=" + localStorage.language, localStorage.id).done(function (data) {
        auth.setMyStatus(data);
        handler(data);
        if (data.restaurant_global_id == 2 && tawkTo == false) {
          tawkTo = true;
          var Tawk_API = Tawk_API || {},
            Tawk_LoadStart = new Date();
          (function () {
            var s1 = document.createElement("script"),
              s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/5dc4670b1936a27a715fcddc/default";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
          })();
        }
      });
    }).fail(failHandler);
  } else
    get("tableService/" + sessionStorage.restaurantSelected + "/getMyStatus?lang=" + localStorage.language, localStorage.id).done(function (data) {
      localStorage.getMyStatus = JSON.stringify(data);
      if (handler)
        handler(data);
    }).fail(failHandler);
}

auth.getMyStatus = getMyStatus;

function getOccupiedTable(handler) {
  i_pay_all = false;
  allow = $("#allow_to_join");
  $("#allow_to_join").classList = "toggle-handle active";
  tableNumber = null;
  get("tableService/getOccupiedTable", localStorage.id).done(function (data) {
    localStorage.getOccupiedTable = JSON.stringify(data);
    handler(data);
  });
}

function getJoinedTable(handler) {
  i_pay_all = false;
  allow = $("#allow_to_join");
  $("#allow_to_join").classList = "toggle-handle active";
  tableNumber = null;
  get("tableService/getJoinedTable", localStorage.id).done(function (data) {
    localStorage.getOccupiedTable = JSON.stringify(data);
    handler(data);
  });
}

function getSubprofiles(handler) {
  get("customerService/getSubprofiles", localStorage.id).done(function (data) {
    if (data == null) {
      console.log("Failed to get subprofiles!");
    } else {
      localStorage.getSubprofiles = JSON.stringify(data);
      handler(data);
    }
  });
}

function getTableStatus(restaurant, table, handler) {
  get("tableService/getTableStatus?restaurant=" + restaurant + "&table=" + table).done(function (data) {
    if (data == null) {
      console.log("Failed to get table status!");
    } else {
      handler(data);
    }
  });
}

function getImages(tableMates, tables, handler) {
  var ids = [];
  if (typeof tableMates != "undefined" && tableMates.length > 0)
    $(tableMates).each(function (index, mate) {
      if (mate.tableMate != null) {
        ids.push(mate.tableMate.id);
      }
    });
  if (typeof tables != "undefined" && tables.length > 0)
    tables.forEach(function (tableOccupation) {
      tableOccupation.tableMates.forEach(function (mate) {
        if (mate.tableMate != null) {
          ids.push(mate.tableMate.id);
        }
      });
    });

  loadImages(ids).done(function () {
    handler();
  });
}

function getLanguage(language) {
  if (typeof localStorage.language != undefined) {
    return localStorage.language;
  }
  language = language.split("-")[0];
  return language;
}

function pickLanguage(language) {
  localStorage.language = language;
  location.reload();
}

export const isTop = $item => {
  if ($item.length == 0) return false;
  var item = $item.get(0);
  var rect = item.getBoundingClientRect();
  var e = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2);

  if ($(e).parents(".jquery-loading-modal").length > 0) {
    var e1 = e;
    var p = $(e1).parents(".jquery-loading-modal");
    p.hide();
    e = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2);
    p.show();
  } else if ($(e).hasClass("jquery-loading-modal__animation")) {
    var e1 = e;
    $(e1)
      .parent()
      .parent()
      .hide();
    e = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2);
    $(e1)
      .parent()
      .parent()
      .show();
  } else if ($(e).hasClass("modal-backdrop")) {
    var e1 = e;
    $(e1).hide();
    e = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2);
    $(e1).show();
  } else if ($(e).hasClass("jquery-loading-modal__bg")) {
    var e1 = e;
    $(e1)
      .parent()
      .hide();
    e = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2);
    $(e1)
      .parent()
      .show();
  }
  if (!item.isSameNode(e)) {
    while (e != null) {
      e = e.parentNode;
      if (item.isSameNode(e)) return true;
    }
    if (e == null) return false;
  }
  return true;
};

export const isVisible = $element => {
  if ($element.length == 0) return false;
  if ($element.is("html")) return true;
  if ($element.css("display") == "none") return false;
  var element = $element.parent();
  if (element == null) return true;
  return isVisible($(element[0]));
};

export const select = (item, trigger) => {
  if (!$(item).hasClass("selectable")) {
    if (trigger != null) console.log(trigger.isDefaultPrevented() + " : " + trigger.originalEvent.isPropagationStopped);
    return false;
  }
  if (trigger) {
    var t = $(trigger.target)
      .parents(".tab-pane")
      .get(0);
    localStorage.atTheTableSelectedTab = $(t).attr("id");
  }

  if (typeof drag != "undefined" && drag == true) return;
  if ($(item).hasClass("selected")) {
    var top = $(item).parents('.selectable[level = "0"]');
    if (top.hasClass("selectedTop")) {
      return true;
    }
    $('.selectable[level = "0"]').removeClass("selectedTop");
    top.addClass("selectedTop");
    return true;
  }
  if ($(item).attr("level") == 0) {
    $('.selectable[level = "0"]').removeClass("selectedTop");
    $('.selectable').removeClass("selected");
    $(item).addClass("selectedTop");
    /*    
    if ($(item).find(".selectable.selected").length == 0) {
      $(
        $(item)
          .find(".selectable")
          .get(0)
      ).addClass("selected");
    }*/
    $('.selectable[level = "0"]').removeClass("selectedTop");
    $('.selectable[level = "0"]').removeClass("selected");
    return false;
  }
  $('.selectable[level = "0"]').removeClass("selectedTop");
  $('.selectable[level = "0"]').removeClass("selected");
  var top = $(item).parents('.selectable[level = "0"]');
  top.addClass("selectedTop");
  if ($(item).hasClass("selected")) return false;

  top.find(".selectable.selected").removeClass("selected");
  top.find(".selectable.selectedParent").removeClass("selectedParent");
  var id = $(item).attr("id");
  var clazz = $(item).hasClass('eatwithme-seat') ? clazz = '[eatwithme-seat-number = "' + $(item).attr('eatwithme-seat-number') + '"]' : $(item).hasClass('eatwithme-table') ? '.eatwithme-table' : '';
  const i = top.find("#" + id + ".selectable" + clazz);
  i.parents(".selectable").addClass("selectedParent")
  i.addClass("selected");
  /*
  i.get(0).scrollIntoView({
    behavior: 'smooth',  // Enables smooth scrolling
    block: 'start',      // Aligns the element to the top of the view
  });*/
  return true;
};

export const selectNext = () => {
  var $selected = $(".selectable.selectedTop").find(".selectable.selected:not(.hidden)");
  if ($selected.length == 0) {
    var r = $('.selectable.selectedTop .selectable[level = "2"]:not(".hidden")');
    if (r.length == 0) r = $('.selectable.selectedTop .selectable[level = "1"]:not(".hidden")');
    if (r.length > 0) select(r.get(0));
  } else {
    var level = $selected.attr("level");
    var s = $selected;
    loop: while (s.length == 1) {
      if (s.hasClass("selectable") && s.hasClass("selectedTop")) {
        break loop;
      }
      var v = $(s.nextAll('.selectable:not(".hidden")'));
      if (v.length > 0) {
        for (let i = 0; i < v.length; i++) {
          var s1 = $(v.get(i));
          if (!isVisible(s1)) continue;
          if (s1.attr("level") != level) {
            s1 = s1.find('.selectable:not(".hidden")[level = "' + level + '"]');
            if (s1.length == 0) continue;
            s1 = $(s1.get(0));
          }
          select(s1);
          s1.get(0).scrollIntoView({
            behavior: "instant",
            block: "nearest",
            inline: "nearest"
          });
          break loop;
        }
        if (!(s.hasClass("selectable") && s.hasClass("selectedTop"))) {
          s = s.parent();
        } else {
          break loop;
        }
        break loop;
      } else {
        if (!(s.hasClass("selectable") && s.hasClass("selectedTop"))) {
          s = s.parent();
        } else {
          break loop;
        }
      }
    }
  }
};

export const nextDirection = () => {
  var $selected = $(".selectable.selectedTop").find(".selectable.selected");
  var $parent = $($selected.parents(".selectable").get(0));
  return $parent.attr("direction");
};

function getSelected() {
  var $selected = $(".selectable.selectedTop").find(".selectable.selected");
  return $selected;
}

function getSelectedTop() {
  var $selected = $(".selectable.selectedTop");
  return $selected;
}

export const selectPrevious = () => {
  var $selected = $(".selectable.selectedTop").find(".selectable.selected:not(.hidden)");
  if ($selected.length == 0) {
    var r = $('.selectable.selectedTop .selectable[level = "2"]:not(".hidden")');
    if (r.length == 0) r = $('.selectable.selectedTop .selectable[level = "1"]:not(".hidden")');
    if (r.length > 0) select(r.get(0));
  } else {
    var level = $selected.attr("level");
    var s = $selected;
    loop: while (s.length == 1) {
      if (s.hasClass("selectable") && s.hasClass("selectedTop")) {
        break loop;
      }
      var v = $(s.prevAll('.selectable:not(".hidden")'));
      if (v.length > 0) {
        for (let i = 0; i < v.length; i++) {
          var s1 = $(v.get(i));
          if (!isVisible(s1)) continue;
          if (s1.attr("level") != level) {
            var direction = s1.attr("direction");
            s1 = s1.find('.selectable:not(".hidden")[level= "' + level + '"]');
            if (s1.length == 0) continue;
            if (direction == "horizontal") {
              s1 = $(s1.get(0));
            } else {
              s1 = $(s1.get(s1.length - 1));
            }
          }
          select(s1);
          s1.get(0).scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest"
          });
          break loop;
        }
        if (!(s.hasClass("selectable") && s.hasClass("selectedTop"))) {
          s = s.parent();
        } else {
          break loop;
        }
      } else {
        if (!(s.hasClass("selectable") && s.hasClass("selectedTop"))) {
          s = s.parent();
        } else {
          break loop;
        }
      }
    }
  }
};

export const clickSelected = () => {
  var $selected = $(".selectable.selectedTop").find(".selectable.selected");
  $selected.click();
};

export const levelIn = last => {
  var $selected = $(".selectable.selectedTop").find(".selectable.selected");
  if ($selected.length == 0) {
  } else {
    var level = Number($selected.attr("level")) + 1;
    var s = $selected;
    var direction = s.attr("direction");
    s = s.find('.selectable:not(".hidden")[level = "' + level + '"]');
    if (s.length == 0) return;
    if (last == true && direction == "vertical") s = $(s.get(s.length - 1));
    else s = $(s.get(0));
    select(s);
    s.get(0).scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest"
    });
  }
};

export const levelOut = () => {
  var $selected = $(".selectable.selectedTop").find(".selectable.selected");
  if ($selected.length == 0) {
  } else {
    var level = Number($selected.attr("level")) - 1;
    if (level == 0) return;
    var s = $selected;
    while (s.length == 1) {
      s = s.parent();
      if (s.hasClass("selectable") && s.attr("level") == level) {
        break;
      }
    }
    if (s.length == 1) {
      select(s);
      s.get(0).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    }
  }
};

export const nextTab = () => {
  var v = $('.nav-link[href="#' + localStorage.atTheTableSelectedTab + '"]')
    .parent()
    .next()
    .find(".nav-link")
    .get(0);
  if (v != undefined) v.click();
};
export const previousTab = () => {
  var v = $('.nav-link[href="#' + localStorage.atTheTableSelectedTab + '"]')
    .parent()
    .prev()
    .find(".nav-link")
    .get(0);
  if (v != undefined) v.click();
};

$.fn.scrollTo = function (elem) {
  if (this.length == 0) return;
  $(this).scrollTop($(this).scrollTop() - $(this).offset().top + $(elem).offset().top);
  return this;
};

$.fn.centerTo = function (elem) {
  if (this.length == 0) return;
  var height = $(this).height();
  $(this).scrollTop($(this).scrollTop() - $(this).offset().top + $(elem).offset().top - height / 2);
  return this;
};

export const setDropdownButton = t => {
  var b = $(t)
    .parent()
    .parent()
    .find("button");
  var classType = b.attr("classType");
  b.removeClass(classType + b.attr("value"));
  b.text($(t).text());
  if (b.attr("value") != null) {
    b.attr("value", $(t).attr("value"));
  } else b.attr("value", $(t).text());
  if ($(t).css("background-image") != null) b.attr("css", $(t).css("background-image"));
  b.addClass(classType + b.attr("value"));
  b.dropdown("toggle");
};

function includeHTML() {
  var def = $.Deferred();
  var z, i, elmnt, file, xhttp;
  /* loop through a collection of all HTML elements: */
  z = $("script[w3-include-html]");
  if (z.length == 0) {
    def.resolve();
    return def.promise();
  }
  for (let i = 0; i < z.length; z++) {
    elmnt = z[i];
    /* search for elements with a certain atrribute: */
    var file = elmnt.getAttribute("w3-include-html");
    if (file) {
      /* make an HTTP request using the attribute value as the file name: */
      if (typeof cordova.file != "undefined" && !cordova.file.applicationDirectory.startsWith("http:") && !cordova.file.applicationDirectory.startsWith("https:")) {
        readFromFile(file, function (data) {
          $(elmnt).replaceWith($(data));
          includeHTML().done(function () {
            def.resolve();
          });
        });
      } else {
        if (file.startsWith("/")) {
          file = appServer + file;
        }
        xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function () {
          if (this.readyState == 4) {
            if (this.status == 200) {
              $(elmnt).replaceWith($(this.responseText));
            } else if (this.status == 404) {
              elmnt.innerHTML = "Page not found.";
              // remove the attribute, and call this function once more:
              elmnt.removeAttribute("w3-include-html");
            }
            includeHTML().done(function () {
              def.resolve();
            });
          } else {
          }
        };
        xhttp.open("GET", file, true);
        xhttp.send();
      }
      return def.promise();
    }
  }
  return def.promise();
}

function readFromFile(fileName, cb) {
  var pathToFile = (cordova.file.applicationDirectory.startsWith("http") ? appServer : cordova.file.applicationDirectory + "www/") + fileName;
  window.resolveLocalFileSystemURL(pathToFile, function (fileEntry) {
    fileEntry.file(function (file) {
      var reader = new FileReader();

      reader.onloadend = function (e) {
        cb(this.result);
      };

      reader.readAsText(file);
    });
  });
}

export const getStandardQuantity = (q, quantityType) => {
  let standardquantity = q;
  switch (quantityType) {
    case "dl":
      standardquantity = q / 10;
      break;
    case "cl":
      standardquantity = q / 100;
      break;
    case "ml":
      standardquantity = q / 1000;
      break;
    case "dkg":
      standardquantity = q / 100;
      break;
    case "g":
      standardquantity = q / 1000;
      break;
  }
  return standardquantity;
}

export const getInventoryQuantity2 = (q, quantityType) => {
  let standardquantity = q;
  switch (quantityType) {
    case "dl":
      standardquantity = q * 10;
      break;
    case "cl":
      standardquantity = q * 100;
      break;
    case "ml":
      standardquantity = q * 1000;
      break;
    case "dkg":
      standardquantity = q * 100;
      break;
    case "g":
      standardquantity = q * 1000;
      break;
  }
  return standardquantity;
}

export const hightlightError = item => {
  item.focus();
  item.removeClass("highlight-error");
  setTimeout(function () {
    item.addClass("highlight-error");
  }, 1);
};
auth.hightlightError = hightlightError;

export const formatRelativeDate = (timestamp) => {
  if (!timestamp) {
    return "-";
  }
  const givenDate = moment(timestamp);
  const currentDate = moment();

  const differenceInDays = currentDate.diff(givenDate, 'days');
  const differenceInMonths = currentDate.diff(givenDate, 'months');
  const differenceInYears = currentDate.diff(givenDate, 'years');

  var label = "";

  if (differenceInDays > 0 && differenceInDays < 60) {
    label = String.format(admin_local.daysAgo, differenceInDays);
  } else if (differenceInDays < 0 && differenceInDays > -60) {
    label = String.format(admin_local.inDays, differenceInDays);
  } else if (differenceInDays === 0) {
    label = admin_local.today;
  } else if (differenceInDays > 0 && differenceInDays < 365) {
    label = String.format(admin_local.monthsAgo, differenceInMonths);
  } else if (differenceInDays < 0 && differenceInDays > -365) {
    label = String.format(admin_local.inMonths, differenceInMonths);
  } else if (differenceInDays < 0) {
    label = String.format(admin_local.yearsAgo, differenceInYears);
  } else {
    label = String.format(admin_local.inYears, differenceInYears);
  }

  return label;

}

export const getReportUrl = (name) => {
  var url = "";
  auth.myStatus.reports.forEach(function (report) {
    if (report.name == name) url = encodeURI(auth.server + "/eatwithme.server/reports/" + sessionStorage.restaurantSelected + "/" + report.id + "/get");
  });
  return url;
}

export const getLocale = (object, lang) => {
  try {
    object = JSON.parse(object);
  } catch (ex) { }
  if (typeof object === "undefined") return object;
  if (typeof object === "string") return object;
  if (typeof lang === "undefined") lang = localStorage.language;
  if (object[lang]) {
    if (typeof object.lang) return object[lang];
    else return "";
  }
  if (object.all) return object.all;
  if (object[auth.myStatus.restaurant_settings["default-language"]]) {
    return object[auth.myStatus.restaurant_settings["default-language"]];
  }
  if (typeof object === "undefined") return "";
  var r = JSON.stringify(object);
  return r == "{}" ? "" : r;
};

export const getLocale2 = (object, lang) => {
  if (typeof object === "undefined") return object;
  if (typeof object === "string") return object;
  if (typeof lang === "undefined") lang = localStorage.language;
  var res = null;

  object.forEach(loc => {
    if (loc.lang == lang) {
      res = loc;
    }
  });
  if (res === null)
    object.forEach(loc => {
      if (loc.lang == auth.myStatus.restaurant_settings["default-language"]) {
        res = loc;
      }
    });
  return res;
};

export const getSetting = (prop, def) => {
  var setting = auth.myStatus.restaurant_settings;
  prop.split(".").forEach(function (p) {
    if (typeof setting == "undefined") return;
    setting = setting[p];
  });
  if (typeof setting == "undefined") return def;
  return setting;
};

localStorage.removeItem("logins");

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2)
    return parts
      .pop()
      .split(";")
      .shift();
}

export const cleanLogin = id => {
  if (id && auth.myStatus.customers.length > 1) {
    const globalId = auth.myStatus.customers.find(c => c.id === id).globalId;
    getGlobal("login/logout?user=" + globalId).done(() => {
      get("login/logout?user=" + id)
        .fail(function (data) {
          auth.ajaxError = false;
          if (data.status == 403) {
            messageDialog(local.error_message, local.missing_role);
          } else if (data.status == 404) {
            messageDialog(local.error_message, local.login_failed);
          } else {
            messageDialog(local.error_message, local.login_failed);
          }
        })
        .done(function (data) {
          localStorage.id = data.customer.id;
          localStorage.email = data.customer.email;
          localStorage.name = data.customer.name;
          localStorage.surname = data.customer.surname;
          localStorage.sessionId = data.sessionId;
          localStorage.type = "eatwithme";
          localStorage.locked = false;
          auth.myStatus.id = data.customer.id;
          auth.myStatus.email = data.customer.email;
          auth.myStatus.name = data.customer.name;
          auth.myStatus.surname = data.customer.surname;
          auth.myStatus.roles = data.roles;
          store.dispatch(EatWithMeActions.resetMyStatus(auth.myStatus));
          if (typeof auth.myStatus != "undefined") auth.myStatus.customers = data.customers;
          admin.getMyStatus(() => {
            if (admin.userChanged) admin.userChanged();
            unlock();
          });
        });
    });
    return;
  }
  logout(true)
    .done(data => {
      if (data.customers) {
        $("div#getCode.modal").modal("hide");
        if (typeof auth.myStatus != "undefined") {
          admin.getMyStatus(data => {
            if (typeof userChanged != "undefined") userChanged();
          });
        }
      } else {
        login();
      }
    })
    .fail(() => {
      location.reload();
    });
};

export const relogin = () => {
  var def = $.Deferred();
  var password = $("input#password").val();
  var email = $("input#email.text").val();
  var keep = true;
  if (password == "Password" || email == "Email") {
    def.reject();
    return def.promise();
  }
  if (password == "Password" || password == "") {
    hightlightError($("input#password"));
    def.reject();
    return def.promise();
  }
  if (email == "Email" || email == "") {
    hightlightError($("input#email.text"));
    def.reject();
    return def.promise();
  }
  localStorage.defaultemail = email;
  console.error("addlogin?user=" + email + "&password=" + password + "&keep=" + keep);
  getGlobal("addlogin?user=" + email + "&password=" + password + "&keep=" + keep + "&instance=eatwithme")
    .fail(function (data) {
      auth.ajaxError = false;
      console.error(JSON.stringify(data));
      if (data.status == 403) {
        messageDialog(local.error_message, local.missing_role);
      } else if (data.status == 404) {
        messageDialog(local.error_message, local.login_failed);
      } else {
        messageDialog(local.error_message, local.login_failed);
      }
    })
    .done(function (data) {
      getGlobal("login/getLoginToken?instance=eatwithme").done(token => {
        get("addlogin?user=" + encodeURIComponent("token:" + auth.myStatus.restaurant_global_id + "/" + token.id) + "&password=" + encodeURIComponent(token.id) + "&keep=true&instance=" + localStorage.instance, {}, true, 0, false, 30000)
          .done(data => {
            localStorage.id = data.customer.id;
            localStorage.email = data.customer.email;
            localStorage.name = data.customer.name;
            localStorage.surname = data.customer.surname;
            localStorage.sessionId = data.sessionId;
            localStorage.type = "eatwithme";
            localStorage.locked = false;
            auth.myStatus.id = data.customer.id;
            auth.myStatus.global_id = data.customer.globalId;
            auth.myStatus.email = data.customer.email;
            auth.myStatus.name = data.customer.name;
            auth.myStatus.surname = data.customer.surname;
            auth.myStatus.roles = data.roles;
            store.dispatch(EatWithMeActions.resetMyStatus(auth.myStatus));
            if (typeof auth.myStatus != "undefined") auth.myStatus.customers = data.customers;
            admin.getMyStatus(() => {
              if (admin.userChanged) admin.userChanged();
            });

            def.resolve(data);
          }).fail(error => {
            if (error.status == 403) {
              messageDialog(local.error_message, local.missing_role);
            } else if (data.status == 404) {
              messageDialog(local.error_message, local.login_failed);
            } else {
              messageDialog(local.error_message, local.login_failed);
            }
            auth.ajaxError = false;
            def.reject();
          });
      });
    });
  def.promise().done(data => {
    $("div#getCode.modal").modal("hide");
    localStorage.removeItem("myImage");
    localStorage.locked = false;
    if (typeof userChanged != "undefined") userChanged();
  });
  return def.promise();
};

export const login = () => {
  navigateTo("/#login");
};

auth.login = login;

function getObject(list, id) {
  var obj = null;
  list.forEach(function (item) {
    if (item.id == id) obj = item;
  });
  return obj;
}

function swapNodes(node1, node2) {
  const temp = document.createComment('')
  node2.replaceWith(temp)
  node1.replaceWith(node2)
  temp.replaceWith(node1)
}

export const replaceContent3 = (oldRoot, newRoot, main = true) => {
  const startTime = performance.now();

  if (!oldRoot || oldRoot.length === 0) return;

  const a = Array.from(oldRoot.get(0).childNodes);
  const b = Array.from(newRoot.get(0).childNodes);

  const aMap = new Map();
  a.forEach((node, index) => {
    const key = getNodeKey(node);
    if (key) aMap.set(key, { node, index });
  });

  let aindex = 0;

  b.forEach((bNode) => {
    const key = getNodeKey(bNode);
    const foundNode = aMap.get(key);

    if (foundNode) {
      const { node: aNode, index: aIndex } = foundNode;

      if (aindex !== aIndex) {
        swapNodes(aNode, a[aindex]);
        a[aindex] = aNode;
        a[aIndex] = a[aindex];
      }

      updateAttributes(aNode, bNode);
      updateData(aNode, bNode);

      if (bNode.nodeType === Node.TEXT_NODE) {
        if (aNode.nodeValue !== bNode.nodeValue) {
          aNode.nodeValue = bNode.nodeValue;
        }
      } else {
        replaceContent($(aNode), $(bNode), false);
      }

      aMap.delete(key);
      aindex++;
    } else {
      if (a.length > aindex) {
        $(bNode).insertBefore($(a[aindex]));
      } else {
        try {
          $(bNode).appendTo(oldRoot);
        } catch (ex) {
          console.error(ex);
        }
      }
    }
  });

  // Remove any extra elements that were not found in newRoot
  aMap.forEach(({ node }) => $(node).remove());

  // Cleanup and validation
  const finalA = Array.from(oldRoot.get(0).childNodes).filter(isValidNode);
  const finalB = b.filter(isValidNode);

  if (finalA.length !== finalB.length) {
    console.warn("Something went wrong with the refresh");
  }

  const duration = performance.now() - startTime;
  if (main && duration > 300) console.warn(`Refresh took ${duration.toFixed(2)}ms`);
};

// Generates a unique key for nodes for fast lookups
const getNodeKey = (node) => {
  if (node.nodeType === Node.TEXT_NODE) return `#text#${node.nodeValue.trim()}`;
  if (node.id) return `${node.nodeName}#${node.id}`;
  return null;
};

// Updates only changed attributes
const updateAttributes = (oldNode, newNode) => {
  const oldAttrs = oldNode.attributes || [];
  const newAttrs = newNode.attributes || [];

  const oldAttrMap = new Map([...oldAttrs].map(attr => [attr.name, attr.value]));

  [...newAttrs].forEach(attr => {
    if (oldAttrMap.get(attr.name) !== attr.value) {
      oldNode.setAttribute(attr.name, attr.value);
    }
    oldAttrMap.delete(attr.name);
  });

  oldAttrMap.forEach((_, attrName) => oldNode.removeAttribute(attrName));
};

// Efficiently updates dataset attributes
const updateData = (oldNode, newNode) => {
  const newData = $(newNode).data();
  if (newData) $(oldNode).data(newData);
};

// Validates if a node should be considered
const isValidNode = (node) => node.nodeName !== "#text" || (node.data && node.data.trim());


export const replaceContent2 = (oldRoot, newRoot) => {

  if (!oldRoot || oldRoot.length === 0)
    return;
  var a = [];
  var b = [];
  $(oldRoot.get(0).childNodes).each(function (ind, e) {
    $(e).data("found", false);
    a.push(e);
  });
  $(newRoot.get(0).childNodes).each(function (ind, e) {
    $(e).data("found", false);
    b.push(e);
  });


  var aindex = 0;
  b.forEach(function (b1) {
    var bid = b1.nodeName;
    if (bid == "#text") {
      bid += "#" + b1.nodeValue;
    } else bid += "#" + $(b1).attr("id");
    var found = false;
    var aindex2 = aindex;
    if (bid != undefined) {
      while (aindex2 < a.length) {
        const ai = a[aindex2];
        const $ai = $(ai);
        var aid = ai.nodeName;
        if (aid == "#text") {
          aid = "#" + ai.nodeValue;
        } else aid += "#" + $ai.attr("id");
        if (aid == bid) {
          if (aindex != aindex2) {
            swapNodes(a[aindex2], a[aindex]);
            const aa = a[aindex];
            a[aindex] = a[aindex2];
            a[aindex2] = aa;
            aindex2 = aindex;
          }
          $(ai).data("found", true);
          const ra = $ai.attr("refreshtimestamp");
          const rb = $(b1).attr("refreshtimestamp");
          if (!ra || !rb || ra !== rb) {
            //console.log("refresh needed");
            // updateAtttributes
            var existingAttrs = [];
            if (ai.attributes)
              for (let i = 0; i < ai.attributes.length; i++) {
                const attr = ai.attributes[i];
                existingAttrs.push(attr.localName);
                // preserve the selected state
                var wasSelected = attr.localName == "class" && $ai.hasClass("selectable") && $ai.hasClass("selected");
                if (typeof $(b1).attr(attr.localName) != "undefined") {
                  var aattr = $ai.attr(attr.localName);
                  var battr = $(b1).attr(attr.localName);
                  if (aattr != battr) {
                    $ai.attr(attr.localName, $(b1).attr(attr.localName));
                  }
                } else {
                  $ai.attr(attr.localName, "");
                }
                if (attr.localName == "class" && wasSelected) {
                  $ai.addClass("selected");
                }
              }
            if (b1.attributes)
              for (let i = 0; i < b1.attributes.length; i++) {
                const attr = b1.attributes[i];
                existingAttrs.splice(existingAttrs.indexOf(attr.localName), 1);
                if ($ai.attr(attr.localName) == null) {
                  try {
                    $ai.attr(attr.localName, $(b1).attr(attr.localName));
                  } catch (ex) {
                    console.error(ex);
                  }
                }
              }
            existingAttrs.forEach(attr => {
              if (attr != "selected") $ai.attr(attr, null);
            });
            if ($(b1).data("data"))
              $ai.data("data", $(b1).data("data"));
            // updatechildren
            if (b1.nodeName == "#text") {
              if (a[aindex2].nodeValue != b1.nodeValue) {
                a[aindex2].nodeValue = b1.nodeValue;
              }
            } else {
              if (a[aindex2].childNodes.length == 1 && a[aindex2].childNodes[0].nodeName == "#text" && b1.childNodes.length == 1 && b1.childNodes[0].nodeName == "#text") {
                a[aindex2].childNodes[0].nodeValue = b1.childNodes[0].nodeValue;
              }
              replaceContent($ai, $(b1));
            }

            // $(a[aindex]).replaceWith($(b1));
            while (aindex < aindex2) {
              var aid = a[aindex].nodeName + "#" + $(a[aindex]).attr("id");
              $(a[aindex]).remove();
              aindex++;
            }
            aindex2++;
            aindex++;
            found = true;
            break;
          } else {
            aindex2++;
            aindex++;
            found = true;
            break;
            //console.log("refresh not needed");
          }
        } else {
          //console.log(aid, bid);
        }
        aindex2++;
      }
    }
    if (!found) {
      if (a.length > aindex) {
        $(b1).insertBefore($(a[aindex]));
      } else {
        try {
          $(b1).appendTo(oldRoot);
        } catch (ex) {
          console.error(ex);
        }
      }
    } else {
    }
  });
  a.filter(a1 => $(a1).data("found") === false).forEach(aa => {
    $(aa).remove();
    aindex++;
  })

  while (aindex < a.length) {
    var aid = a[aindex].nodeName;
    if (aid == "#text") aid += "#" + a[aindex].nodeValue;
    else aid += "#" + $(a[aindex]).attr("id");
    $(a[aindex]).remove();
    aindex++;
  }

  var a = [];
  $(oldRoot.get(0).childNodes).each(function (ind, e) {
    a.push(e);
  });

  a = a.filter(a => a?.nodeName !== "#text" && !(a?.data && a?.data.trim()));
  b = b.filter(a => a?.nodeName !== "#text" && !(a?.data && a?.data.trim()));

  if (a.length != b.length) {
    console.log("something wrong happened with the refresh");
  }

  /*
   * oldRoot.find('> *').each(function(ind, e) { $(e).remove(); });
   * newRoot.find('> *').each(function(ind, e) { oldRoot.append($(e)); });
   */
};

export const replaceContent = (oldRoot, newRoot, main = true) => {

  const now = new Date().getTime();

  if (!oldRoot || oldRoot.length === 0)
    return;
  var a = [];
  var b = [];
  $(oldRoot.get(0).childNodes).each(function (ind, e) {
    $(e).data("found", false);
    a.push(e);
  });
  $(newRoot.get(0).childNodes).each(function (ind, e) {
    $(e).data("found", false);
    b.push(e);
  });


  var aindex = 0;
  b.forEach(function (b1) {
    var bid = b1.nodeName;
    if (bid == "#text") {
      bid += "#" + b1.nodeValue;
    } else bid += "#" + $(b1).attr("id");
    var found = false;
    var aindex2 = aindex;
    if (bid != undefined) {
      while (aindex2 < a.length) {
        const ai = a[aindex2];
        const $ai = $(ai);
        var aid = ai.nodeName;
        if (aid == "#text") {
          aid = "#" + ai.nodeValue;
        } else aid += "#" + $ai.attr("id");
        if (aid == bid) {
          if (aindex != aindex2) {
            swapNodes(a[aindex2], a[aindex]);
            const aa = a[aindex];
            a[aindex] = a[aindex2];
            a[aindex2] = aa;
            aindex2 = aindex;
          }
          $(ai).data("found", true);
          const ra = $ai.attr("refreshtimestamp");
          const rb = $(b1).attr("refreshtimestamp");
          if (!ra || !rb || ra !== rb) {
            //console.log("refresh needed");
            // updateAtttributes
            var existingAttrs = [];
            if (ai.attributes)
              for (let i = 0; i < ai.attributes.length; i++) {
                const attr = ai.attributes[i];
                existingAttrs.push(attr.localName);
                // preserve the selected state
                var wasSelected = attr.localName == "class" && $ai.hasClass("selectable") && $ai.hasClass("selected");
                if (typeof $(b1).attr(attr.localName) != "undefined") {
                  var aattr = $ai.attr(attr.localName);
                  var battr = $(b1).attr(attr.localName);
                  if (aattr != battr) {
                    $ai.attr(attr.localName, $(b1).attr(attr.localName));
                  }
                } else {
                  $ai.attr(attr.localName, "");
                }
                if (attr.localName == "class" && wasSelected) {
                  $ai.addClass("selected");
                }
              }
            if (b1.attributes)
              for (let i = 0; i < b1.attributes.length; i++) {
                const attr = b1.attributes[i];
                existingAttrs.splice(existingAttrs.indexOf(attr.localName), 1);
                if ($ai.attr(attr.localName) == null) {
                  try {
                    $ai.attr(attr.localName, $(b1).attr(attr.localName));
                  } catch (ex) {
                    console.error(ex);
                  }
                }
              }
            existingAttrs.forEach(attr => {
              if (attr != "selected") $ai.attr(attr, null);
            });
            if ($(b1).data("data"))
              $ai.data("data", $(b1).data("data"));
            // updatechildren
            if (b1.nodeName == "#text") {
              if (a[aindex2].nodeValue != b1.nodeValue) {
                a[aindex2].nodeValue = b1.nodeValue;
              }
            } else {
              if (a[aindex2].childNodes.length == 1 && a[aindex2].childNodes[0].nodeName == "#text" && b1.childNodes.length == 1 && b1.childNodes[0].nodeName == "#text") {
                a[aindex2].childNodes[0].nodeValue = b1.childNodes[0].nodeValue;
              }
              replaceContent($ai, $(b1), false);
            }

            // $(a[aindex]).replaceWith($(b1));
            while (aindex < aindex2) {
              var aid = a[aindex].nodeName + "#" + $(a[aindex]).attr("id");
              $(a[aindex]).remove();
              aindex++;
            }
            aindex2++;
            aindex++;
            found = true;
            break;
          } else {
            aindex2++;
            aindex++;
            found = true;
            break;
            //console.log("refresh not needed");
          }
        } else {
          //console.log(aid, bid);
        }
        aindex2++;
      }
    }
    if (!found) {
      if (a.length > aindex) {
        $(b1).insertBefore($(a[aindex]));
      } else {
        try {
          $(b1).appendTo(oldRoot);
        } catch (ex) {
          console.error(ex);
        }
      }
    } else {
    }
  });
  a.filter(a1 => $(a1).data("found") === false).forEach(aa => {
    $(aa).remove();
    aindex++;
  })

  while (aindex < a.length) {
    var aid = a[aindex].nodeName;
    if (aid == "#text") aid += "#" + a[aindex].nodeValue;
    else aid += "#" + $(a[aindex]).attr("id");
    $(a[aindex]).remove();
    aindex++;
  }

  var a = [];
  $(oldRoot.get(0).childNodes).each(function (ind, e) {
    a.push(e);
  });

  a = a.filter(a => a?.nodeName !== "#text" && !(a?.data && a?.data.trim()));
  b = b.filter(a => a?.nodeName !== "#text" && !(a?.data && a?.data.trim()));

  if (a.length != b.length) {
    _log("something wrong happened with the refresh");
  }

  /*
   * oldRoot.find('> *').each(function(ind, e) { $(e).remove(); });
   * newRoot.find('> *').each(function(ind, e) { oldRoot.append($(e)); });
   */

  const time = new Date().getTime() - now;
  if (main && time > 300) _log("refresh took " + time + "ms");
};

function strhash(str) {
  if (str.length % 32 > 0) str += Array(33 - (str.length % 32)).join("z");
  var hash = "",
    bytes = [],
    i = (j = k = a = 0),
    dict = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  for (let i = 0; i < str.length; i++) {
    ch = str.charCodeAt(i);
    bytes[j++] = ch < 127 ? ch & 0xff : 127;
  }
  var chunk_len = Math.ceil(bytes.length / 32);
  for (let i = 0; i < bytes.length; i++) {
    j += bytes[i];
    k++;
    if (k == chunk_len || i == bytes.length - 1) {
      a = Math.floor(j / k);
      if (a < 32) hash += "0";
      else if (a > 126) hash += "z";
      else hash += dict[Math.floor((a - 32) / 2.76)];
      j = k = 0;
    }
  }
  return hash;
}

export const updateToolTips = () => {
  //$('[data-toggle="tooltip"]:visible').tooltip('dispose');
  /*
  $('[data-toggle="tooltip"]:visible').tooltip({
    html : true
  });
  */
};

export const deactivateToggleRadioButton = (item, event) => {
  if ($(item).hasClass("active")) {
    $(item).triggerHandler("change");
    $(item).removeClass("active");
    event.stopPropagation();
  }
};

var touchStartEventX = 0;
var touchStartEventY = 0;
export const touchStart = event => {
  touchStartEventX = event.changedTouches[0].screenX;
  touchStartEventY = event.changedTouches[0].screenY;
};

export const touchClick = event => {
  var touchStartEventX2 = event.changedTouches[0].screenX;
  var touchStartEventY2 = event.changedTouches[0].screenY;
  return Math.abs(touchStartEventX2 - touchStartEventX) < 10 && Math.abs(touchStartEventY2 - touchStartEventY) < 10;
};

var lastClickEventTimestamp = 0;
var lastClickEventTarget = null;
var lastClickEventX = 0;
var lastClickEventY = 0;
var lastClickEventType = null;
export const avoidMultipleClick = (t, event) => {
  playClickSound();
  event = event.originalEvent || event;
  if (typeof event != "undefined") {
    lastClickEventTarget = event.target;
    lastClickEventX = event.clientX ? event.clientX : event.changedTouches ? event.changedTouches[0].pageX : 0;
    lastClickEventY = event.clientY ? event.clientY : event.changedTouches ? event.changedTouches[0].pageY : 0;
    if (event.timeStamp < 31611742280)
      lastClickEventTimestamp = event.timeStamp;
    lastClickEventType = event.type;
  }

  $(t).attr("disabled", true);
  setTimeout(function () {
    $(t).attr("disabled", null);
  }, 1000);
};

export const avoidMultipleClick2 = t => {
  $(t).hide();
  setTimeout(function () {
    $(t).show();
  }, 500);
};

export const isDuplicateClickEvent = event => {
  event = event.originalEvent || event;
  var clickEventX = event.clientX ? event.clientX : event.changedTouches ? event.changedTouches[0].pageX : 0;
  var clickEventY = event.clientY ? event.clientY : event.changedTouches ? event.changedTouches[0].pageY : 0;
  if (event.timeStamp - lastClickEventTimestamp < 20) {
    return true;
  }
  if (typeof dragged != "undefined" && dragged == true) return true;
  if (/*event.target!=lastClickEventTarget && */ lastClickEventType != event.type && lastClickEventX == clickEventX && lastClickEventY == clickEventY && event.timeStamp - lastClickEventTimestamp < 1500) {
    return true;
  }
  return false;
};


window.mobileAndTabletcheck = function () {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check || auth.device.platform !== "browser";
};


export const isElementInViewport = (parent, el) => {
  //special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }

  if (!el) return false;

  var rect = el.getBoundingClientRect();

  var prect = parent.get(0).getBoundingClientRect();

  var r = rect.top >= prect.top && rect.left >= prect.left && rect.bottom <= prect.bottom && rect.right <= prect.right;
  return r;
};

export const convertHex = (hex, opacity) => {
  if (!hex)
    hex = "#FFFFFF";
  hex = hex.replace("#", "");
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  var result = "rgba(" + r + ", " + g + ", " + b + ", " + opacity / 100 + ")";
  return result;
}

/* Suma el porcentaje indicado a un color (RR, GG o BB) hexadecimal para aclararlo */
const addLight = function (color, amount) {
  let cc = parseInt(color, 16) + amount;
  let c = cc > 255 ? 255 : cc;
  c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
  return c;
};

/* const hexToRGB = (color) => {
  color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
  let colorRGB = [color.substring(0,2)]
} */

/* Aclara un color hexadecimal de 6 caracteres #RRGGBB segun el porcentaje indicado */
export const lighten = (color, amount) => {
  color = color.indexOf("#") >= 0 ? color.substring(1, color.length) : color;
  amount = parseInt((255 * amount) / 100);
  return (color = `#${addLight(color.substring(0, 2), amount)}${addLight(color.substring(2, 4), amount)}${addLight(color.substring(4, 6), amount)}`);
};

/* Resta el porcentaje indicado a un color (RR, GG o BB) hexadecimal para oscurecerlo */
const subtractLight = function (color, amount) {
  let cc = parseInt(color, 16) - amount;
  let c = cc < 0 ? 0 : cc;
  c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
  return c;
};

/* Oscurece un color hexadecimal de 6 caracteres #RRGGBB segun el porcentaje indicado */
const darken = (color, amount) => {
  color = color.indexOf("#") >= 0 ? color.substring(1, color.length) : color;
  amount = parseInt((255 * amount) / 100);
  return (color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`);
};

/* Clase para crear comodamente elementos DIV */
class div {
  constructor(type, className, text, color) {
    this.type = type;
    this.className = className;
    this.text = text;
    this.color = color;
  }
  render() {
    let tag = document.createElement(this.type);
    tag.setAttribute("class", this.className);
    tag.setAttribute("style", `background-color:${this.color}`);
    tag.textContent = this.text;
    return tag;
  }
}

export const loginBackground = url => {
  function LoginImg(props) {
    return <img onLoad={props.onLoad} onError={props.onError} src={props.url + "&background=true"} />;
  }

  console.log("Background login " + url);
  var def = $.Deferred();
  if (device.platform == "iOS") {
    console.log("Background login1");

    function onLoad() {
      console.log("Background login Accepted");
      ReactDOM.unmountComponentAtNode(document.getElementById("loginImg"));
      def.resolve();
    }

    function onError() {
      console.log("Background login rejected");
      ReactDOM.unmountComponentAtNode(document.getElementById("loginImg"));
      def.reject();
    }

    ReactDOM.render(<LoginImg url={url} onLoad={onLoad} onError={onError} />, document.getElementById("loginImg"));
  } else {
    def.resolve();
  }
  return def.promise();
};
auth.loginBackground = loginBackground;

export const logoutBackground = url => {
  function LoginImg(props) {
    return <img onLoad={props.onLoad} onError={props.onError} src={props.url + "&background=true"} />;
  }

  console.log("Background logout " + url);
  var def = $.Deferred();
  if (device.platform == "iOS") {
    function onLoad() {
      console.log("Background logout Accepted");
      ReactDOM.unmountComponentAtNode(document.getElementById("loginImg"));
      def.resolve();
    }

    function onError() {
      console.log("Background logout rejected");
      ReactDOM.unmountComponentAtNode(document.getElementById("loginImg"));
      def.reject();
    }

    ReactDOM.render(<LoginImg url={url} onLoad={onLoad} onError={onError} />, document.getElementById("loginImg"));
  } else {
    def.resolve();
  }
  return def.promise();
};
auth.logoutBackground = logoutBackground;

export const logoutFacebook = () => {
  var def = $.Deferred();
  def.resolve();
  /*
  try {
    console.log("Check facebook login status...")
    window.facebookConnectPlugin.getLoginStatus(function (response) {
      //console.log("success", response);
      if (response.status === "connected") {
        window.facebookConnectPlugin.logout(function (response) {
          console.log("Logged out from facebook");
          def.resolve();
        }, function () {
          def.resolve();
        });
      } else
        def.resolve();
    }, function (response) {
      console.log(response);
      if (response.status === "connected") {
        window.facebookConnectPlugin.logout("failure", function (response) {
          console.log("Logged out from facebook");
          def.resolve();
        }, function () {
          def.resolve();
        });
      } else
        def.resolve();
    });
  } catch (ex) {
    console.log("Exception occured while checkig facebook login status", ex);
    console.log(ex);
    def.resolve();
  }
  */
  return def.promise();
}

export const logout = all => {
  var def = $.Deferred();
  if (typeof auth.myStatus == "undefined" || auth.myStatus == null) {
    def.resolve();
    return def.promise();
  }

  stopPool();
  function r() {
    get("logout?user=" + auth.myStatus.id)
      .done(data => {
        stopBackgroundLocationTraking();
        if (auth.server.indexOf("8444") === -1 && !isBackup())
          get(auth.server.replace("instance,eatwithme.", "backup.eatwithme") + "/eatwithme.server/logout?user=" + auth.myStatus.id, 1, undefined, undefined, false);
        if (data.customers) {
          auth.myStatus.customers = data.customers;
          /*
          if (data.customers.length == 0) {
            localStorage.removeItem("sessionId");
            store.dispatch(EatWithMeActions.logout);
            login();
          } else {
            localStorage.id = data.customer.id;
          }*/
          if (all === true && auth.server !== auth.globalserver) {
            function rr() {
              console.log("logout from global server")
              getGlobal("logout?user=" + auth.myStatus.global_id)
                .done(function () {
                  store.dispatch(EatWithMeActions.logout);
                  logoutFacebook().done(() => { def.resolve(data); });
                })
                .fail(() => {
                  auth.ajaxError = false;
                  def.resolve(data);
                });
            }
            rr();
          } else {
            logoutFacebook().done(() => { def.resolve(data); });
          }
        } else {
          auth.myStatus.customers = [];
          if (all === true && auth.server !== auth.globalserver) {
            function rr() {
              console.log("logout from global server")
              getGlobal("logout?user=" + auth.myStatus.global_id)
                .done(function () {
                  store.dispatch(EatWithMeActions.logout);
                  logoutFacebook().done(() => { def.resolve(data); });
                })
                .fail(() => {
                  auth.ajaxError = false;
                  def.resolve(data);
                });
            }
            rr();
          } else {
            logoutFacebook().done(() => { def.resolve(data); });
          }
        }
      }).fail(() => {
        localStorage.removeItem("sessionId");
        store.dispatch(EatWithMeActions.logout);
        login();
        auth.ajaxError = false;
        def.resolve();
      });
  }

  r();

  return def.promise();
};

auth.logout = logout;

auth.delayedOrder = () => {
  try {
    const table = getTable(sessionStorage.tableNumberSelected);
    if (device.platform === "browser") {
      if (table.pos) {
        return !!getSetting("tables-overview.pos_delayed_orders_browser");
      } else {
        return !!getSetting("tables-overview.delayed_orders_browser");
      }
    } else {
      if (table.pos) {
        return !!getSetting("tables-overview.pos_delayed_orders_mobile");
      } else {
        return !!getSetting("tables-overview.delayed_orders_mobile");
      }
    }
    return device.platform !== "browser" && table.pos;
  } catch (ex) {
    return false;
  }
}

var switchServerDef = undefined;

export const setRestaurant = (id, def) => {
  //store.dispatch(EatWithMeActions.restaurantSelected(id));
  if (typeof sessionStorage.restaurantSelected != "undefined" && sessionStorage.restaurantSelected != "undefined")
    get("login/setRestaurant/" + sessionStorage.restaurantSelected)
      .done(def.resolve)
      .fail(() => {
        sessionStorage.restaurantSelected = 0;
        localStorage.server = auth.globalserver;
        auth.ajaxError = false;
        def.resolve();
      });
}


export const switchServer = (restaurant1) => {
  // Ensure instance is defined
  const restaurant = { ...restaurant1, instance: restaurant1?.instance || "" };

  // Use existing promise if switchServerDef is active
  if (switchServerDef) return switchServerDef.promise();

  // Initialize Deferred object
  let def = $.Deferred();
  switchServerDef = def;

  // Reset switchServerDef after timeout
  setTimeout(() => {
    switchServerDef = undefined;
  }, 4000);

  switchServerDef.always(() => {
    stopPool();
    switchServerDef = undefined;
    console.log("switchServerDef => undefined");
  });

  console.log("Switch server to:", JSON.stringify(restaurant1));

  // Adjust server URL if global and backup servers are the same
  if (auth.globalserver === auth.backupserver) {
    if (restaurant.serverUrl.includes("https://restaurant.eatwithme.online:8448")) {
      restaurant.serverUrl = restaurant.serverUrl.replace(
        "https://restaurant.eatwithme.online",
        "https://restaurant2.eatwithme.online"
      );
    }
  }

  // Handle undefined server URL
  if (!restaurant.serverUrl && restaurant.globalId !== 2) {
    const id = restaurant.id || restaurant.globalId;

    if (id) {
      getGlobal(`tableService/getRestaurant/${id}`).done((fetchedRestaurant) => {
        const oldSwitchServerDef = switchServerDef;
        switchServerDef = undefined;
        if (fetchedRestaurant.serverUrl)
          switchServer(fetchedRestaurant)
            .done(() => def.resolve())
            .always(() => oldSwitchServerDef.resolve());
      });
    } else {
      switchServerDef = undefined;
      def.reject();
    }

    return def.promise();
  }

  // Helper function to switch to the specified server
  const switchToServer = (serverUrl) => {
    localStorage.removeItem("ordersCache");

    if (serverUrl?.endsWith("/")) {
      serverUrl = serverUrl.slice(0, -1);
    }

    console.log("Switching to server:", serverUrl);

    ajaxCallStart("switch");
    def.always(() => {
      console.log("Switch server end");
      ajaxCallEnd("switch");
    });

    if (serverUrl !== auth.server || restaurant.instance !== localStorage.instance) {
      if (!serverUrl || serverUrl === auth.globalserver) {
        const id = restaurant.id || restaurant.globalId;

        console.log("Switching back to global server");

        if (auth.server !== auth.globalserver) {
          logout().always(() => {
            updateLocalStorageAndAuth(auth.globalserver, restaurant.instance, id);
            setRestaurant(sessionStorage.restaurantSelected, def);
            def.resolve();
          });
        } else {
          updateLocalStorageAndAuth(auth.globalserver, restaurant.instance, id);
          setRestaurant(sessionStorage.restaurantSelected, def);
          def.resolve();
        }
      } else {
        loginToServer(serverUrl);
      }
    } else {
      finalizeSwitchForCurrentServer();
    }
  };

  // Helper function to handle login logic
  const loginToServer = (serverUrl) => {
    const loginToBackup = () => {
      getGlobal("login/getLoginToken").done((token) => {
        setTimeout(() => {
          post(
            serverUrl.replace("instance.eatwithme", "backup.eatwithme") +
            `/eatwithme.server/login?instance=${restaurant.instance || localStorage.instance}`,
            `username=${encodeURIComponent(`token:${restaurant.globalId}/${token.id}`)}&password=${encodeURIComponent(
              token.id
            )}&user=true`,
            {},
            false,
            0,
            false,
            30000
          ).fail((error) => {
            auth.ajaxError = false;
            console.error("Failed to log in to backup!");
          });
        }, 1500);
      });
    };

    getGlobal("login/getLoginToken").done((token) => {
      post(
        `${serverUrl}/eatwithme.server/login?instance=${restaurant.instance || localStorage.instance}`,
        `username=${encodeURIComponent(`token:${restaurant.globalId}/${token.id}`)}&password=${encodeURIComponent(
          token.id
        )}&user=true`,
        {},
        true,
        0,
        false,
        30000
      )
        .done((data) => {
          handleLoginSuccess(data, serverUrl);
          if (!serverUrl.includes("8444") && !isBackup()) setTimeout(loginToBackup, 5000);
        })
        .fail(() => {
          auth.ajaxError = false;
          console.error("Login failed");
          def.reject();
        });
    }).fail(() => {
      def.reject();
    });
  };

  // Helper function to handle successful login
  const handleLoginSuccess = (data, serverUrl) => {
    if (!data) {
      alert("Failed to authenticate user! Username or password is incorrect.");
      def.reject();
      return;
    }

    localStorage.id = data.customer.id;
    localStorage.email = data.customer.email;
    localStorage.name = data.customer.name;
    localStorage.surname = data.customer.surname;
    localStorage.sessionId = data.sessionId;
    localStorage.instance = restaurant.instance;

    get(`${serverUrl}/eatwithme.server/tableService/getLocalFromGlobalId/${restaurant.globalId}`).done((id) => {
      sessionStorage.restaurantSelected = id;
      localStorage.globalId = restaurant.globalId;
      auth.server = serverUrl;
      localStorage.server = serverUrl;
      setRestaurant(sessionStorage.restaurantSelected, def);
    });
  };

  // Helper function to finalize switch for the current server
  const finalizeSwitchForCurrentServer = () => {
    if (restaurant.id || restaurant.globalId) {
      sessionStorage.restaurantSelected = restaurant.id || restaurant.globalId;
      setRestaurant(sessionStorage.restaurantSelected, def);
    } else {
      def.resolve();
    }
  };

  // Helper function to update localStorage and auth
  const updateLocalStorageAndAuth = (server, instance, id) => {
    localStorage.server = auth.server = server;
    localStorage.instance = instance;
    sessionStorage.restaurantSelected = id;
  };

  // Start switching process
  switchToServer(isBackup() ? restaurant.backupUrl || restaurant.serverUrl : restaurant.serverUrl);

  return def.promise();
};

auth.switchServer = switchServer;

function convertBluetoothScaleMeasurment(t) {
  var t = atob(t);
  var a = 0;
  var p = 256;
  for (let i = 5; i < 7; i++) {
    const c = t.charCodeAt(i);
    a += c * p;
    p /= 256;

  }
  a /= 1000;
  return a;
}

function disconnectBLE(address) {
  bluetoothle.disconnect(disconnectSuccess => {
    console.log("BLE Disconnected")
  }, disconnectError => {
    console.log("BLE Disconnect error")
    console.log(disconnectError)
  }, {
    address: address
  })
}

function discoverSuccessHander(discoverSuccess, def, updateMeasure) {
  console.log("discover services")
  bluetoothle.discover(discoverSuccess => {
    discoverSuccess.services.forEach(service => {
      service.characteristics.forEach(characteristic => {
        if (characteristic.uuid !== "FFF1")
          return;
        if (characteristic.descriptors.length === 0)
          return;
        console.log(characteristic);
        bluetoothle.addService(success => {
          console.log(success)
        }, error => {
          console.log(error)
        }, {
          service: service.uuid,
          characteristics: [
            {
              uuid: characteristic.uuid,
              permissions: {
                read: true,
                //write: true,
                //readEncryptionRequired: true,
                //writeEncryptionRequired: true,
              },
              properties: {
                read: true,
                writeWithoutResponse: true,
                write: true,
                notify: true,
                indicate: true,
                //authenticatedSignedWrites: true,
                //notifyEncryptionRequired: true,
                //indicateEncryptionRequired: true,
              }
            }
          ]
        });

        bluetoothle[service.uuid + characteristic.uuid] = [];

        const measureTimeout = setTimeout(() => {
          def.reject("");
          bluetoothle.unsubscribe(unsubscribeSuccess => {
            console.log("unsubscribeSuccess");
            console.log(unsubscribeSuccess);
            disconnectBLE(discoverSuccess.address);
          }, unsubscribeError => {
            console.log("unsubscribeError");
            console.log(unsubscribeError);
            disconnectBLE(discoverSuccess.address);
          }, {
            "address": discoverSuccess.address,
            "service": service.uuid,
            "characteristic": characteristic.uuid,
          });
        }, 10000)

        bluetoothle.subscribe(subscribeSuccess => {
          console.log("subscribeSuccess", service.uuid, characteristic.uuid, subscribeSuccess.value);
          //console.log(subscribeSuccess)
          if (subscribeSuccess.value) {
            const value = convertBluetoothScaleMeasurment(subscribeSuccess.value);
            if (value === 0)
              return;
            if (bluetoothle[service.uuid + characteristic.uuid].filter(v => v !== value).length) {
              bluetoothle[service.uuid + characteristic.uuid] = [];
            }
            bluetoothle[service.uuid + characteristic.uuid].push(value)
            if (bluetoothle[service.uuid + characteristic.uuid].length === 5) {
              updateMeasure(value);
              clearTimeout(measureTimeout);
              def.resolve(value);
              bluetoothle.unsubscribe(unsubscribeSuccess => {
                console.log("unsubscribeSuccess");
                console.log(unsubscribeSuccess);
                disconnectBLE(discoverSuccess.address);
              }, unsubscribeError => {
                console.log("unsubscribeError");
                console.log(unsubscribeError);
                disconnectBLE(discoverSuccess.address);
              }, {
                "address": discoverSuccess.address,
                "service": service.uuid,
                "characteristic": characteristic.uuid,
              });
            }
          }
        }, subscribeError => {
          console.log(subscribeError);
        }, {
          "address": discoverSuccess.address,
          "service": service.uuid,
          "characteristic": characteristic.uuid,
        });

      });
    })
  }, discoverError => {
    console.log("Discover error");
    console.log(discoverError);
  }, {
    address: discoverSuccess.address,
    "clearCache": true
  });

}

function startScan(def, updateMeasure) {

  const stopTimer = setTimeout(() => {
    console.log("Not found any scales, givingup scanning");
    def.reject();
    bluetoothle.stopScan(success => {
    }, error => {
    });
  }, 60000);
  try {
    bluetoothle.startScan(startScanSuccess => {

      if (startScanSuccess.name !== "Chipsea-BLE")
        return;

      clearTimeout(stopTimer);

      bluetoothle.stopScan(success => {
        //console.log(success);
      }, error => {
        //console.log(error);
      });

      if (startScanSuccess.status === "scanResult") {
        bluetoothle.isConnected(status => {
          if (status.isConnected)
            discoverSuccessHander(startScanSuccess, def, updateMeasure);
          else {
            bluetoothle.disconnect(status => {
              console.log("Not connected, connecting")
              bluetoothle.reconnect(status => discoverSuccessHander(status, def, updateMeasure),
                status => {
                  console.log("Connect error")
                  console.log(status);
                  discoverSuccessHander(startScanSuccess, def, updateMeasure);
                }, {
                address: startScanSuccess.address
              })
            },
              status => {
                console.log("disconnect error")
                console.log(status)
                bluetoothle.reconnect(status => discoverSuccessHander(status, def, updateMeasure),
                  status => {
                    console.log("Connect error")
                    console.log(status);
                    discoverSuccessHander(startScanSuccess, def, updateMeasure);
                  }, {
                  address: startScanSuccess.address
                })
              }, {
              address: startScanSuccess.address
            })

          }

        }, status => {
          bluetoothle.connect(success => {
            discoverSuccessHander(success, def, updateMeasure);
          },
            status => {
              console.log("Connect error")
              console.log(status)
            }, {
            address: startScanSuccess.address
          })
        }, {
          address: startScanSuccess.address
        })

      }
    }, startScanError => {
      console.log(JSON.stringify(startScanError));
    }, {
      /*
      "services": [
        "1800", "1801", "180A"
      ],*/
      "allowDuplicates": false,
      "scanMode": bluetoothle.SCAN_MODE_LOW_LATENCY,
      "matchMode": bluetoothle.MATCH_MODE_AGGRESSIVE,
      "matchNum": bluetoothle.MATCH_NUM_MAX_ADVERTISEMENT,
      "callbackType": bluetoothle.CALLBACK_TYPE_ALL_MATCHES,
    });
  } catch (ex) {
    logger.error(ex);
  }
}

export const scaleMeasure = (name, quantityType) => {

  if (!quantityType)
    quantityType = "g";
  const def = $.Deferred();
  if (name === "Bluetooth") {
    try {
      ajaxCallStart("scaleMeasure", 0);
      console.log("stopScan")
      bluetoothle.stopScan(success => {
        startScan(def, def.resolve);
      }, error => {
        startScan(def, def.resolve);
      });
    } catch (ex) {
      console.log("Error occured while scanning for bluetooth devices")
      console.log(ex);
    }
    def.promise().always(() => {
      ajaxCallEnd("scaleMeasure");
    })
    return def.promise();
  }

  if (!auth.myStatus.restaurant_scales.find(s => s.name === name)) {
    //def.resolve(.600);
    def.resolve(0);
    return def.promise();
  }

  const scale = auth.myStatus.restaurant_scales.find(s => s.name === name);
  const pattern = {
    cas: {
      text: "\\0x05\\0x00\\0x17",
      pattern: "F(\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d)\r", antipattern: "F0000000000000\r", g: 1, d: 1, timeout: 4000, group: 1
    },
    "casPRII": {
      text: "\\0x05\\0x00\\0x17",
      pattern: "\\x06\\x01\\x02S[ ]+([\\d]*\\.[\\d]*)kg.\\x03\\x04", pattern2: /S[\+]*([\d]*\.[\d]*)kg/, pattern3: "([\\d]*\\.[\\d]*)kg", d: 10000, antipattern: "F0000000000000\r", timeout: 4000, group: 1,

    },
    "casER": {
      text: "sp",
      pattern: "s\\x02 +0\\x10\\x03\\x02S +([\\d]*.[\\d]*)kg.\\x03\\x02 +0\\x10\\x03", d: 10000, antipattern: "F0000000000000\r", timeout: 4000, group: 1
    }
  };

  if (scale.format === "rs232") {
    const now = new Date().getTime();
    _log("scaleMeasure start");
    post("adminService/" + sessionStorage.restaurantSelected + "/scaleMeasure/" + encodeURIComponent(name) + "?timestamp=" + (new Date().getTime()), pattern[scale.type]).done(data => {
      if (data.response) {
        const now2 = new Date().getTime();
        //updateMeasure((Number(data.response.substring(1)) / 10000));
        var d = pattern[scale.type].d;
        switch (quantityType) {
          case 'g': d = 10 / d; break;
          case 'dkg': d = 100 / d; break;
          case 'kg': d = 10000 / d; break;
        }
        if (isNaN(data.response)) {
          const m = data.response.match(pattern[scale.type].pattern2);
          def.resolve(Math.round(1000 * (Number(m[pattern[scale.type].group]) / d)) / 1000);
        } else {
          const m = Math.round(1000 * (Number(data.response) / d)) / 1000;
          _log("scaleMeasure in " + (now2 - now) + " " + m);
          def.resolve(m);
        }
      }
    }).fail(data => {
      const now2 = new Date().getTime();
      _log("scaleMeasure failed " + (now2 - now));
      console.log(data);
    });
  } else if (scale.format === "android") {


    //var serial = UART;

    const opts = {
      //vid: '067B',
      //pid: '23A3',
      //driver: 'ProlificSerialDriver',
      baudRate: 9600,
      //databits: 8,
      //stopBits: 1,

      //dtr: false,
      //rts: false,
      sleepOnPause: false
    }

    function sleep(milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    var log = "";

    async function close() {
      return new Promise(resolve => {
        serial.close(successMessage => {
          resolve(successMessage);
        }, errorMessage => {
          resolve(errorMessage);
        })
      })
    }

    async function write(text) {
      return new Promise(resolve => {
        try {
          serial.write(text, successMessage => {
            log += "Serial write:" + text + " " + successMessage + "\r\n";
            resolve();
          }, errorMessage => {
            console.error("Write failed:" + errorMessage);
            log += "Serial write fail:" + errorMessage + "\r\n";
          })
        } catch (ex) {
          console.log(ex)
        }
      })
    }

    async function read() {
      return new Promise(resolve => {
        const len = serial.read(yourArrayBuffer => {
          let uint8Array = new Uint8Array(yourArrayBuffer);

          if (uint8Array.length === 0) {
            resolve(-1);
            return;
          }


          // Convert Uint8Array to a regular JavaScript array
          let byteArray = Array.from(uint8Array);

          if (byteArray.length === 0) {
            resolve(-1);
            return;
          }

          // Now 'byteArray' contains the content of the ArrayBuffer as a regular array
          resolve(byteArray);


        }, 1000);
        if (len == -1) {
          console.error("Read failed");
          resolve(len);
        }
      })
    }

    try {

      //eslint-disable-next-line no-undef
      serial.requestPermission(opts, function success(res) {
        log += "Serial granted" + "\r\n";

        //eslint-disable-next-line no-undef
        serial.open(opts, function success() {
          log += "Serial openned" + "\r\n";

          async function serialCheck() {

            //_log("Serial check");

            //await sleep(2000);
            //eslint-disable-next-line no-undef
            await write(pattern[scale.type].text);
            await sleep(100);
            const now = new Date().getTime();

            async function checkScale() {
              try {

                var result = "";

                var regexp = new RegExp(pattern[scale.type].pattern);
                var regexp2 = pattern[scale.type].pattern2 ? new RegExp(pattern[scale.type].pattern2) : null;
                var regexp3 = pattern[scale.type].pattern3 ? new RegExp(pattern[scale.type].pattern3) : null;

                var ok = false;

                while (new Date().getTime() - now < pattern[scale.type].timeout) {
                  const received = await read();

                  //_log("" + received)

                  await sleep(100);

                  if (received != -1)
                    result += String.fromCharCode.apply(null, received);

                  var match = result.match(regexp);

                  if (!match && regexp2)
                    match = result.match(regexp2);
                  if (!match && regexp3)
                    match = result.match(regexp3);

                  if (match) {
                    result = Number(match[1]);

                    //_log(result);

                    var d = pattern[scale.type].d;
                    switch (quantityType) {
                      case 'g': d = 10 / d; break;
                      case 'dkg': d = 100 / d; break;
                      case 'kg': d = 10000 / d; break;
                    }
                    //_log(quantityType + ":" + d);
                    if (isNaN(result)) {
                      const m = result.match(pattern[scale.type].pattern2);
                      def.resolve(Math.round(1000 * (Number(m[pattern[scale.type].group]) * d)) / 1000);
                    } else {
                      const m = Math.round(1000 * (Number(result) / d)) / 1000;
                      log += "Result:" + m + "\r\n";
                      _log(log);
                      def.resolve(m);
                    }
                    ok = true;
                    break;
                  }
                }
                log += "received:" + result + "\r\n";
                log += "closing..." + "\r\n";
                log += await close();
                if (!ok) {
                  _log(log);
                  def.reject();
                }
              } catch (ex) {
                console.error("error:" + ex)
              }
            }

            checkScale();

          }
          serialCheck();

        }, function error(error) {
          console.error("Serial failed to open(0)", error);
        });
      }, function error(error) {
        console.error("Serial denied", error);
      });
    } catch (Ex) {
      console.error("Error:" + Ex);
    }
  } else if (scale.format === "android_rs232") {

    uart.list(dev => {
      conole.error(dev);
    }, error => {
      console.error("UART list error " + JSON.stringify(error));
      var dev = "/dev/ttyS3";
      //eslint-disable-next-line no-undef
      uart.write({ dev: dev, text: text + "\r\n" }, function success(successMessage) {
        console.error("Serial write:" + successMessage);
      }, function error(error) {
        console.error("Serial failed to write", error);
      });
    })

    if (1 < 2)
      return;

    const text = pattern[scale.type].timeout;
    const opts = {
      baudRate: 9600,
      databits: 8,
      stopBits: 1,

      dtr: false,
      rts: false,
      sleepOnPause: false
    }

    function sleep(milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    var log = "";

    async function close() {
      return new Promise(resolve => {
        serial.close(successMessage => {
          resolve(successMessage);
        }, errorMessage => {
          resolve(errorMessage);
        })
      })
    }

    async function write(text) {
      return new Promise(resolve => {
        try {
          console.error("Write " + text);
          serial.writeHex(text, successMessage => {
            log += "Serial write:" + text + " " + successMessage + "\r\n";
            resolve();
          }, errorMessage => {
            console.error("Write failed:" + errorMessage);
            log += "Serial write fail:" + errorMessage + "\r\n";
          })
        } catch (ex) {
          console.log(ex)
        }
      })
    }

    async function read() {
      return new Promise(resolve => {
        const len = serial.read(yourArrayBuffer => {
          let uint8Array = new Uint8Array(yourArrayBuffer);

          if (uint8Array.length === 0) {
            resolve(-1);
            return;
          }


          // Convert Uint8Array to a regular JavaScript array
          let byteArray = Array.from(uint8Array);

          if (byteArray.length === 0) {
            resolve(-1);
            return;
          }

          // Now 'byteArray' contains the content of the ArrayBuffer as a regular array
          console.error(byteArray);
          resolve(byteArray);


        }, 1000);
        if (len == -1) {
          console.error("Read failed");
          resolve(len);
        }
      })
    }


    //eslint-disable-next-line no-undef
    serial.requestPermission(opts, function success() {
      log += "Serial granted" + "\r\n";


      //eslint-disable-next-line no-undef
      serial.open(opts, function success() {
        log += "Serial openned" + "\r\n";

        async function serialCheck() {


          //eslint-disable-next-line no-undef
          await write("73700a");
          await sleep(400);
          //await write("p");
          //await sleep(400);
          //await write("\r");
          //await sleep(100);
          //await write("\n");
          //await sleep(400);
          const now = new Date().getTime();

          async function checkScale() {
            log += "waiting..." + "\r\n";
            while (new Date().getTime() - now < pattern[scale.type].timeout) {
              const received = await read();
              await sleep(100);

              if (received != -1)
                log += "received:" + received + "\r\n";

            }
            log += "closing..." + "\r\n";
            log += await close();
            console.error(log, log);
            def.reject();
          }

          checkScale();

        }
        serialCheck();

      }, function error(error) {
        console.error("Serial failed to open(0)", error);
      });
    }, function error(error) {
      console.error("Serial denied", error);
    });
  }
  return def.promise();
}

export const scanbarcode = () => {
  const def = $.Deferred();
  const cor = cordova;

  cor.plugins.barcodeScanner.scan(
    function (result) {
      if (!result.cancelled) {
        if (!isNaN(result.text)) {
          def.resolve(Number(result.text));
        }
      }
      if (cor.plugins.backgroundMode)
        setTimeout(() => { cor.plugins.backgroundMode.enableOverrideBackButton = true }, 1000);
    },
    function (error) {
      def.reject(error);
      if (cor.plugins.backgroundMode)
        setTimeout(() => { cor.plugins.backgroundMode.enableOverrideBackButton = true }, 1000);
    }
  );

  //def.resolve("4099200017716")
  return def.promise();
}

export const settingEnabled = (path) => {
  try {
    var settings = auth.myStatus.restaurant_settings;
    path.split('/').forEach(prop => {
      settings = settings[prop];
    })
    return settings == true || settings.enabled == true;
  } catch (ex) {
    console.log("Restaurant setting missing: " + path);
  }
  return false;
}

export const getShortName = menuItem => {
  if (menuItem.shortName) return menuItem.shortName;
  return getLocale(menuItem.name);
};

var autoScroll = false;

auth.setupScroll = function (t) {
  var t1 = t.find(".floatThead-container");
  var t2 = t.find(".scrollable-y");

  var t3 = t.find("table:not(.floatThead-table)");
  var t4 = t.find("table.floatThead-table");

  t4.css("width", t3.css("width"));

  t1.css("overflow-y", "hidden");
  t1.css("overflow-x", "auto");
  t1.css("width", t3.css("width"));

  t1.scroll(event => {
    if (autoScroll) {
      autoScroll = false;
      return;
    }
    var pos = t1.scrollLeft();
    autoScroll = true;
    t2.scrollLeft(pos);
  });
  t2.scroll(event => {
    if (autoScroll) {
      autoScroll = false;
      return;
    }
    t1.css("width", "100%");
    t4.css("width", t3.css("wstartidth"));
    var pos = t2.scrollLeft();
    autoScroll = true;
    t1.scrollLeft(pos);
  });
};

const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
const thousandSeparator = (1000).toLocaleString().substring(1, 2);


export const parseNumber = (number) => {
  if (number == null)
    return "";
  if (typeof number == "undefined")
    return "";
  if (typeof number == "number")
    return number;
  number = number.replace(' ', '');
  number = number.replace(',', '.');
  number = Number(number);
  return number;
}

export const _floatTableHead = (id, floatColumnHead) => {
  const $id = $(id);

  if ($id.length == 0) return;

  if ($id.data("tableHeadFloated") === true) {
    _resizefloatTableHead(id);
    return;
  }

  floatColumnHead = floatColumnHead === undefined ? false : floatColumnHead,

    $id.data("tableHeadFloated", true);



  $id.data(
    "freezeTable",
    new window.FreezeTable(id, {
      namespace: id,
      scrollable: true,
      scrollBar: true,
      freezeColumn: floatColumnHead,
      freezeColumnHead: floatColumnHead
      //fastMode: true,
    })
  );
}

export const _unfloatTableHead = (id) => {
  const $id = $(id);
  if ($id.data("tableHeadFloated") !== true) return;
  $id.data("freezeTable").destroy();
  $id.data("tableHeadFloated", false);
}

export const _resizefloatTableHead = (id) => {
  const $id = $(id);
  if ($id.data("tableHeadFloated") !== true) return;
  $id.data("freezeTable").update();
}

export const getKeyboardFocusableElements = (element = document) => {
  return [$(...element).find(
    'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
  )]
    .filter(el => !el.prop('disabled'))
}


export const getQuantityLabel2 = (additionType, quantity, quantityType) => {
  var res;
  if (quantityType == "none") return "";
  if (additionType == "mandatory" || additionType == "included")
    res = '<input id="quantity" type="number" value="' + quantity + '"></input><span>' + quantity + " </span><span>" + (quantityType == "none" ? "" : local[quantityType]) + "</span>";
  else res = quantityType == "pcs" && quantity == 1 ? "" : quantity + (quantityType == "none" ? "" : " " + (local[quantityType] ? local[quantityType] : ""));
  return res;
};

export const getQuantityLabel = (q) => {
  if (q.label) return q.label;
  else !q.quantityType || q.quantityType.name === "N/A" ? "" : q.quantityType.name;
}

export const calcCount = (value, quantityType, quantity) => {
  if (value && isNaN(value)) {
    const found = value.match("(\\d*[,\\.]?\\d+) ?(cl|dl|l|g|dkg|kg|" + local.pcs + ")");
    if (!found) {
      return value;
    }
    value = found[1];
    switch (found[2]) {
      case 'cl':
        value = value / 100;
        break;
      case 'dl':
        value = value / 10;
        break;
      case 'l':
        break;
      case 'g':
        value = value / 1000;
        break;
      case 'dkg':
        value = value / 100;
        break;
    }
    switch (quantityType) {
      case 'cl':
        value = value * 100;
        break;
      case 'dl':
        value = value * 10;
        break;
      case 'l':
        break;
      case 'g':
        value = value * 1000;
        break;
      case 'dkg':
        value = value * 100;
        break;
      case 'kg':
    }
    value = Math.round(1000 * value / quantity) / 1000;
    return value;
  } else {
    return value;
  }

}


export const navigateTo = (url) => {
  if (url.startsWith("app:"))
    url = "/#" + url.split("#")[1];
  _log("navigateTo: " + url);
  setTimeout(() => {
    location.href = url;
  }, 100);
}

export const navigateToLogin = redirect => {
  if (localStorage.instance !== "" && localStorage.server !== auth.globalserver && typeof localStorage.globalId != "undefined" && localStorage.globalId > 0) {
    const s = auth.localStorage;
    auth.server = auth.globalserver;
    switchServer({ globalId: localStorage.globalId, serverUrl: s }).done(() => {
      location.reload();
    }).fail(() => {
      if (location.href.indexOf("/login") === -1) {
        localStorage.removeItem('globalId');
        navigateToLogin(location.href);
      }
    });
  } else {
    if (redirect == undefined) navigateTo("#/login");
    else navigateTo("#/login?redirect=" + redirect);
  }
};

auth.round = function (amount) {
  return Math.round(amount);
};

export const whatDecimalSeparator = () => {
  var n = 1.1;
  n = n.toLocaleString().substring(1, 2);
  return n;
}

export const featureEnabled = (feature) => {
  if (auth.myStatus && auth.myStatus.restaurant_settings) {
    var features = auth.myStatus.restaurant_settings["enabled-features"];
    feature.split("/").forEach(f => features = features ? features[f] : null);
    return features && (features === true || features.enabled);
  }
  return false;
}
export const booleanSetting = (feature) => {
  if (auth.myStatus && auth.myStatus.restaurant_settings) {
    var features = auth.myStatus.restaurant_settings;
    feature.split("/").forEach(f => features = features ? features[f] : null);
    return features && (features === true || features.enabled);
  }
  return false;
}

export const setGlobalServer = (server) => {
  if (server !== auth.globalserver) {
    console.log("set global server from ", auth.globalserver, "to", server);
    auth.globalserver = server;
  }
  return auth.globalserver;
}

export const vibrateOk = (timeout = 200) => {
  try {
    if (device.platform !== "browser" && navigator.vibrate) {
      navigator.vibrate(timeout);
    }
  } catch (ex) { }
}

auth.groupBy = (arr, key) => {
  var a = arr.reduce((acc, currentValue) => {
    let groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
  const result = [];
  Object.values(a).forEach(v => {
    v.forEach(vv => { vv.firstRow = false; vv.lastRow == false; });
    v[v.length - 1].lastRow = true;
    v[0].firstRow = true;
    v.forEach(vv => result.push(vv));
  })
  return result;
}

export const resetLockTimeout = () => {
  auth.lastEventTimestamp = new Date().getTime();
}


auth.vibrateOk = vibrateOk;

export const colors = [
  "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1",
  "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",
  "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00",
  "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e",
  "#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8",
  "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179",
  "#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
  "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
  "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
  "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
  "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
  "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
  "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
  "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
  "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
  "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
  "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
  "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
  "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
  "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
  "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
  "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
  "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
  "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
  "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
  "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
  "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
  "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
  "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
  "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
  "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
  "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
  "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
  "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
  "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
  "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
  "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
  "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
  "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
  "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"]
auth.colors = colors;

export default auth;

export const stopBackgroundLocationTraking = () => {
  try {
    if (typeof BackgroundGeolocation !== "undefined") {
      console.log("BackgroundGeolocation stoping")
      _log("BackgroundGeolocation stoping");
      BackgroundGeolocation.stop();
      backgroundLocationStarted = false;
      delete localStorage.deliveryId;
      delete localStorage.deliveryTableOccupation;
      if (auth?.myStatus?.id && typeof BackgroundGeolocation != "undefined")
        get("adminService/" + sessionStorage.restaurantSelected + "/updateCourierHeading/0/0", undefined, undefined, undefined, false).fail(() => { auth.ajaxError = false });
      _log("BackgroundGeolocation stopped");
      BackgroundGeolocation.removeAllListeners('location');
    }
  } catch (ex) {
    console.error(ex);
  }
}

var backgroundLocationStarted = false;

var locc;

function saveLastLocation(loc) {
  locc = loc;
}

export const getBackgroundLocation = (success, error) => {
  if (locc && new Date().getTime() - locc.time < 60000) {
    setTimeout(() => success(locc), 200);
  } else {
    navigator.geolocation.getCurrentPosition(
      position => success(position.coords),
      error => error && error("No location available"),
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
    );

  }
}

export const startBackgroundLocationTraking = (deliveryId2, deliveryTableOccupation2) => {
  try {
    const url = auth.server + '/eatwithme.server/adminService/' + sessionStorage.restaurantSelected + '/updateCourierLocation/' + auth?.myStatus?.id + "?" + "&deliveryId=" + (did || 0) + "&deliveryTableOccupationId=" + (dtid || 0) + "&instance=" + localStorage.instance
  } catch (Ex) { }
  if (typeof BackgroundGeolocation === "undefined") {
    return;
  }
  if (backgroundLocationStarted) {
    return;
  }

  BackgroundGeolocation.on('location', saveLastLocation);

  if (deliveryId2 !== undefined)
    localStorage.deliveryId = deliveryId2;
  else
    localStorage.removeItem("deliveryId");
  if (deliveryTableOccupation2 !== undefined)
    localStorage.deliveryTableOccupation = deliveryTableOccupation2;
  else
    localStorage.removeItem("deliveryTableOccupation");

  const did = typeof localStorage.deliveryId !== undefined ? localStorage.deliveryId : 0;
  const dtid = typeof localStorage.deliveryTableOccupation !== undefined ? localStorage.deliveryTableOccupation : 0;

  if (auth?.myStatus?.id)
    try {
      const url = auth.server + '/eatwithme.server/adminService/' + sessionStorage.restaurantSelected + '/updateCourierLocation/' + auth?.myStatus?.id + "?" + "&deliveryId=" + (did || 0) + "&deliveryTableOccupationId=" + (dtid || 0) + "&instance=" + localStorage.instance
      BackgroundGeolocation.configure({
        //locationProvider: BackgroundGeolocation.ACTIVITY_FILTER_PROVIDER,
        locationProvider: BackgroundGeolocation.DISTANCE_FILTER_PROVIDER,
        desiredAccuracy: BackgroundGeolocation.HIGH_ACCURACY,
        stationaryRadius: 40,
        distanceFilter: 10,
        notificationTitle: 'Background tracking',
        notificationText: 'enabled',
        debug: false,
        interval: 30000,
        fastestInterval: 2000,
        stopOnTerminate: false,
        activitiesInterval: 2000,
        url: url, // Your backend server URL
        // customize post properties
        postTemplate: {
          location: {
            latitude: '@latitude',
            longitude: '@longitude'
          }
        },
      });
      // Start tracking location
      BackgroundGeolocation.start();
      backgroundLocationStarted = true;
    } catch (ex) {
      console.error(ex);
    }
}