/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable no-eval */
import $ from "jquery";
import moment from "moment";
import admin, { getEntities, colors, getMenuItem, resultHandler, hasRole } from "../admin";
import { I18n } from "react-redux-i18n";
import SortedSet from "js-sorted-set";
import auth, { getLocale, post, messageDialog, get, ajaxCallStart, ajaxCallEnd, startpoll, convertHex, lighten, startJob, endJob, jobProgress, getQuantityLabel, formatRelativeDate } from "../auth";
import utils from "../menuitem-utils";
import removeAccents from "remove-accents";
import { onPhotoDataSuccess } from "../camera-util";
import Chart from "chart.js";
import "bootstrap-colorpicker";
import { tmplparams, resetOrderListUtil, getQuantityLabel3 } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import Color from "color";
import languages, { locals } from "../../langs/languages";
import { menuitemutil as _menuitemutil, availableQuantities, } from "./menuitem";
import { startsWith } from "lodash";

const { local, admin_local } = languages;

export const conversion = {
	'10 cl': '1 dl',
	'100 cl': '1 l',
	'10 dl': '1 l',
	'1000 ml': '1l',
	'100 g': '1 dkg',
	'100 dkg': '1 kg',
	'1000 g': '1 kg'
}

if (typeof localStorage.admin_stocklist_chart_height == "undefined") {
	localStorage.admin_stocklist_chart_height = 300;
}

function darken(color, ratio) {
	return new Color(color).darken(ratio);
}

export const reload = (data) => {
	const def = $.Deferred();
	if (data != undefined && data.name) {
		updateMenu(data);
		processMenuItems(admin.categories);
		return;
	}
	if (processMenuItems) {
		getStocks((data) => {
			menuitemutil.stocks = {};
			data.forEach((stock) => {
				if (typeof menuitemutil.stocks[stock.menuItem.id + ";" + stock.quantity] == "undefined") {
					menuitemutil.stocks[stock.menuItem.id + ";" + stock.quantity] = {}
				}
				if (menuitemutil.stocks[stock.menuItem.id + ";" + stock.quantity][stock.store.id])
					menuitemutil.stocks[stock.menuItem.id + ";" + stock.quantity][stock.store.id].push(stock);
				else
					menuitemutil.stocks[stock.menuItem.id + ";" + stock.quantity][stock.store.id] = [stock];
			});
			admin.getMenuItems(data => { processMenuItems(data); def.resolve() });
		});
	}
	admin.getLabels(function (data) {
		menuitemutil.labels = data;
	});
	return def.promise();
}

var menuitemutil = {
	reload: reload
}

export const clearData = () => {
	menuitemutil = {
	}
	_menuitemutil.menuItems = null;
	_menuitemutil.filter = {};
}


export const ready = () => {
	const def = $.Deferred();

	clearData();

	_menuitemutil.reload = reload;
	_menuitemutil.updateMenu = updateMenu;

	_menuitemutil.getBarcodes();

	if (typeof localStorage.admin_stocklist_graph_start == 'undefined') {
		var t = moment();
		localStorage.admin_stocklist_graph_end = moment().toDate().getTime();
		localStorage.admin_stocklist_graph_start = moment().subtract(1, 'month').toDate().getTime();
	}

	localStorage.admin_stocklist_range = localStorage.admin_stocklist_range || 'day';

	resetOrderListUtil();

	getEntities("Stores", function (data) {
		menuitemutil.stores = data;

		menuitemutil.stores.forEach(store => {
			if (typeof localStorage['stocklist_stores_selected_' + store.id] == undefined) {
				localStorage['stocklist_stores_selected_' + store.id] = true;
			}
		});

		$('#stocklistTemplate').tmpl({ stores: menuitemutil.stores, ...tmplparams() }).appendTo('#main');
		$("#editMenuCategoryTemplate")
			.tmpl({ onsave: "window.reload", entityType: 'all', entitytype: 'all', availableQuantities: availableQuantities, ...tmplparams() })
			.appendTo("#main");
		$("#editMenuItemTemplate")
			.tmpl({ onsave: "window.reload", entityType: 'all', entitytype: 'all', availableQuantities: availableQuantities, ...tmplparams() })
			.appendTo("#main");

		var start = moment(Number(localStorage.admin_stocklist_graph_start));
		var end = moment(Number(localStorage.admin_stocklist_graph_end));

		function cb(start, end) {
			$('#daterange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
		}
		var ranges = {};
		ranges[admin_local.today] = [moment(), moment()];
		ranges[admin_local.yesterday] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
		ranges[admin_local.last_7_days] = [moment().subtract(6, 'days'), moment()];
		ranges[admin_local.last_30_days] = [moment().subtract(29, 'days'), moment()];
		ranges[admin_local.last_3_months] = [moment().subtract(2, 'month').startOf('month'), moment()];
		ranges[admin_local.this_month] = [moment().startOf('month'), moment().endOf('month')];
		ranges[admin_local.last_month] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
		moment.locale(localStorage.language);
		$('#daterange').daterangepicker({
			linkedCalendars: false,
			timePicker: false,
			autoApply: true,
			startDate: start,
			endDate: end,
			locale: {
				format: 'MMM/DD',
				customRangeLabel: I18n.t("admin_local.custom_range"),
				cancelLabel: I18n.t("local.cancel"),
				applyLabel: I18n.t("local.apply"),
			},
			showButtonPanel: true,
			drops: 'up',
			ranges: ranges,
		}, function (start, end, label) {
			localStorage.admin_stocklist_graph_start = start._d.getTime();
			localStorage.admin_stocklist_graph_end = end._d.getTime();
			cb(moment(Number(localStorage.admin_stocklist_graph_start)), moment(Number(localStorage.admin_stocklist_graph_end)));
			if (isCategory)
				populateCategoryCharts()
			else
				populateCharts();
		});

		if (typeof localStorage.admin_stocklist_range != 'undefined') {
			$("[name='range'][id='" + localStorage.admin_stocklist_range + "']").parent().button('toggle');
		}

		$("[name='range']").change((event) => {
			localStorage.admin_stocklist_range = $("[name='range']:checked").attr('id');
			drawChart();
		});

		_menuitemutil.hooks();
		reload();
		startpoll(serverSideMessageHandler, [{
			Restaurant: sessionStorage.restaurantSelected,
			Menu: "*"
		}]);
		if (typeof localStorage.admin_stocklist_range != 'undefined') {
			$("[name='range'][id='" + localStorage.admin_stocklist_range + "']").parent().button('toggle');
		}

		$("[name='range']").change((event) => {
			localStorage.admin_stocklist_range = $("[name='range']:checked").attr('id');
			drawChart();
		});

		cb(start, end);

		$(".resizable").resizable({
			handles: 'n',
			height: 2,
			resize: function () {
				var height = $(this).css('height').split('px')[0];
				localStorage.admin_stocklist_chart_height = height;
				$(this).css('top', 0);
			}
		});

		$('#chart-block').loadingModal({
			position: 'auto',
			text: '',
			color: 'yellow',
			opacity: '.2',
			backgroundColor: 'rgb(0,0,0)',
			animation: 'circle'
		});

		$('#chart-block').loadingModal('hide');

		auth.setupScroll($('div#menuItems'));

		function updateStoreFilter() {
			menuitemutil.stores.forEach(store => {
				if (localStorage['stocklist_stores_selected_' + store.id] == 'true') {
					$('th[store=' + store.id + '].store').show();
					$('td[store=' + store.id + '].store').show();
				} else {
					$('th[store=' + store.id + '].store').hide();
					$('td[store=' + store.id + '].store').hide();
				}
			})
		}

		$('input.store').on("change", event => {
			var id = $(event.currentTarget).attr('id');
			var checked = $(event.currentTarget).prop('checked');
			localStorage['stocklist_stores_selected_' + id] = checked;
			updateStoreFilter();
			drawChart();
		});

		$('input.filter').on("change", event => {
			var id = $(event.currentTarget).attr('id');
			var checked = $(event.currentTarget).prop('checked');
			localStorage['stocklist_filter_' + id] = checked;
			processMenuItems();
		});

		updateStoreFilter();

		$('#chart-block').removeClass('hidden');
		$('#graph').addClass('btn-positive');
		$('#graph').removeClass('btn-info');

		def.resolve();
	});

	return def.promise()

}


function serverSideMessageHandler(message) {
	if (message.type == "timeout" && message.message == "true")
		return;
	if (message.type == "refresh") {
		if (message.message == "menu changed") {
			admin.getMenuItems(processMenuItems);
		} else {
		}
	}
}



function updateMenu(item, cats) {
	if (cats == undefined) {
		cats = admin.categories.children;
	}
	if (cats)
		cats.forEach((i, ind) => {
			if (i.id == item.id && i.entityType == item.entityType) {
				cats[ind] = item;
				//processMenuItems(admin.categories);
			}
			if (i.children)
				updateMenu(item, i.children);
		})
}


function updateData(data, level) {
	if (typeof level === "undefined") level = 0;
	if (data.name) {
		data.title = data.name[localStorage.language] ? data.name[localStorage.language] : "(" + getLocale(data.name, localStorage.language) + ")";
	}
	if (data.description) {
		data.title = data.title
			+ (data.description[localStorage.language] != undefined ? (" - " + data.description[localStorage.language])
				: ((data.description['all'] ? (" - (" + data.description['all'] + ")") : "")));
	}
	data.depth = level;
	if (data.shortName)
		data.title += ' <i>(' + data.shortName + ')</i>';
	if (data.children) {
		if (!data.originalChildren)
			data.originalChildren = data.children;
		data.stores = {
		};
		var children = [];
		data.originalChildren.forEach(function (v) {
			v.stores = {};
			if (v.availableQuantities) {
				if (v.availableQuantities.length > 1) {
					var o = false;
					v.availableQuantities.forEach((a) => {
						if (a.inventoryItem == true) {
							o = true;
							var v1 = Object.assign({}, v);
							v1.quantity = a.quantity;

							children.push(v1);

							menuitemutil.stores.forEach((store) => {
								if (!data.stores[store.id])
									data.stores[store.id] = {
										minCount: 0,
										buyCount: 0
									}
								if (!v.stores[store.id])
									v.stores[store.id] = {
										minCount: 0,
										buyCount: 0
									}

								if (menuitemutil.stocks[v.id + ";" + a.quantity] && menuitemutil.stocks[v.id + ";" + a.quantity][store.id]?.length > 0) {
									var stock = menuitemutil.stocks[v.id + ";" + a.quantity][store.id][0];

									if (stock && stock.minCount && stock.minCount > stock.count) {
										data.stores[store.id].minCount++;
										v.stores[store.id].minCount++;
									} else if (stock && stock.buyCount && stock.buyCount > stock.count) {
										data.stores[store.id].buyCount++;
										v.stores[store.id].buyCount++;
									}
								}
							});
						}
					});
					if (!o) {
						children.push(v);
					}
				} else if (v.availableQuantities.length == 1 && (v.defaultInventoryItem == true || v.availableQuantities[0].inventoryItem == true)) {
					v.quantity = v.availableQuantities[0].quantity;

					menuitemutil.stores.forEach((store) => {
						if (!data.stores[store.id])
							data.stores[store.id] = {
								minCount: 0,
								buyCount: 0
							}

						if (menuitemutil.stocks[v.id + ";" + v.quantity] && menuitemutil.stocks[v.id + ";" + v.quantity][store.id]?.length > 0) {
							var stock = menuitemutil.stocks[v.id + ";" + v.quantity][store.id][0];

							if (stock && stock.minCount && stock.minCount > stock.count) {
								data.stores[store.id].minCount++;
							} else if (stock && stock.buyCount && stock.buyCount > stock.count) {
								data.stores[store.id].buyCount++;
							}
						}
					});

					children.push(v);
				} else {
					children.push(v);
				}
			} else {
				v.quantity = 1;
				children.push(v);
			}
		});
		data.children = children;
		data.children.forEach(function (v) {
			//if (!v.isActive)
			//	v.extraClasses += " itemDeactivated";
			updateData(v, level + 1);
			if (!v.availableQuantities) {
				menuitemutil.stores.forEach((store) => {
					if (!data.stores[store.id])
						data.stores[store.id] = {
							minCount: 0,
							buyCount: 0
						}
					if (v.stores[store.id]?.minCount) {
						data.stores[store.id].minCount += v.stores[store.id]?.minCount;
					}
					if (v.stores[store.id]?.buyCount) {
						data.stores[store.id].buyCount += v.stores[store.id]?.buyCount;
					}
				});
			}
			v.key = v.entityType + v.id + "-" + v.quantity;
		});
	}
}

var originalDataOld;

function processMenuItems(originalData = originalDataOld) {

	const f = $(":focus").attr("id");

	originalDataOld = $.extend(true, {}, originalData);

	admin.categories = {
		children: $.extend(true, [], originalData.children)
	};

	updateData(originalData);

	if (_menuitemutil.menuItems == null) {

		_menuitemutil.menuItems = createTree("table#menuItems",
			{
				extensions: ["persist", "table", "gridnav", "filter"],
				filter: {  // override default settings
					counter: false,
					mode: "hide"
				},
				checkbox: false,
				source: originalData,
				icon: false,
				keyboard: true,
				autoScroll: false,
				persist: {
					cookiePrefix: 'fancytree-1-stocklist',
					expandLazy: false,
					overrideSource: false,
					store: "auto"
				},
				table: {
					checkboxColumnIdx: 0,
					indentation: 5, // indent every node level by 16px
					nodeColumnIdx: 0,
					iconColumnIdx: 0
					// render node expander, icon, and title to this column
					// (default:
					// #0)
				},
				gridnav: {
					autofocusInput: true, // Focus first embedded input if
					// node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					if (node.data.entityType == 'Meal' || node.data.entityType == 'Drink') {
						// is menu item
						$('button#editMenuCategory').hide();
						$('button#addMenuItem').attr('disabled', 'true');
						$('button#addMenuCategory').attr('disabled', 'true');
						$('button#editMenuItem').show();
						$('span#recalculateConsumption-label').html(I18n.t("admin_local.recalculateConsumption") + ": " + data.node.title);
						populateCharts();
					} else {
						// is menu category
						$('button#editMenuCategory').show();
						$('button#addMenuCategory').removeAttr('disabled');
						$('button#addMenuItem').removeAttr('disabled');
						$('button#editMenuItem').hide();
						$('span#recalculateConsumption-label').html(I18n.t("admin_local.recalculateConsumption") + ": " + data.node.title);
						populateCategoryCharts();
					}
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}
				},
				previousNode: null,
				click: function (event, data) {
					if ($(event.originalEvent.target).hasClass('custom-control-label'))
						return;
					if (event.originalEvent.target.nodeName === "INPUT")
						return;

					if (data.node == _menuitemutil.menuItems.previousNode && !$(event.toElement).hasClass("fancytree-expander")) {
						_menuitemutil.menuItems.activateKey("");
						event.preventDefault();
						_menuitemutil.menuItems.previousNode = null;
						$("#editMenuCategory").hide();
						$("#addMenuItem").attr("disabled", "true");
						$("#addOption").attr("disabled", "true");
						$("#editMenuItem").hide();
						$("#activate").prop("disabled", "true");
						$("#deactivate").prop("disabled", "true");
						$("#delete").prop("disabled", "true");
					} else {
						_menuitemutil.menuItems.previousNode = data.node;
					}
				},
				deactivate: function () {
					$('span#recalculateConsumption-label').html(I18n.t("admin_local.recalculateConsumptionAll"));
				},
				expand: () => {
				},
				collapse: () => {
				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					$(node.tr).attr("level", node.data.depth);
					$(node.tr).data('node', data.node);

					var c = 0;

					// ...otherwise render remaining columns
					var str = "";
					c++;
					var quantity = undefined;
					var cost = undefined;
					var costEstimation = undefined;
					var priceEstimation = undefined;
					var price = undefined;
					var isTop = false;
					var quantityBaseIsInventory = true;
					var quantityInventory = 1;
					if (node.data.entityType == 'Drink' || node.data.entityType == 'Meal') {
						if (node.data.availableQuantities) {
							node.data.availableQuantities.forEach(function (quantityAndPrice) {
								if (quantityAndPrice.inventoryItem == false) {
									quantityBaseIsInventory = false;
								} else {
									quantityInventory = quantityAndPrice.quantity;
								}
								if (quantityAndPrice.inventoryItem == true && quantityAndPrice.quantity == node.data.quantity) {
									quantity = node.data.quantity;
									isTop = quantityAndPrice.onMenu;
									cost = quantityAndPrice.cost;
									costEstimation = quantityAndPrice.costEstimation;
									priceEstimation = quantityAndPrice.priceEstimation;
									price = quantityAndPrice.price == null ? undefined : quantityAndPrice.price.indexOf('%') >= 0 ? quantityAndPrice.price.split('%')[0] * node.data.unitPrice / 100 : quantityAndPrice.price;
									var label = quantityAndPrice.quantityType.name;
									label = label[localStorage.language] ? label[localStorage.language] : label['all'] ? "(" + label['all'] + ")" : ""
									var q = quantityAndPrice.quantity + " " + local[node.data.quantityType];
									if (conversion[q])
										q = conversion[q];
									str = q + (label != '' ? " - " + label : '');
								}
							});
							if (quantityBaseIsInventory)
								$tdList.eq(c).html(str);
							else
								$tdList.eq(c).html("1 " + local[node.data.quantityType]);
						}
						node.data.quantityBaseIsInventory = quantityBaseIsInventory;
						if (quantity == undefined) {
							str = 1 + " " + local[node.data.quantityType];
							$tdList.eq(c).html(str);
							quantity = 1;
						}
					}
					var totalCount = 0;
					var totalExpired = 0;
					var ct = ++c;
					menuitemutil.stores.forEach((store) => {
						++c;
						var count = 0;
						var expired = 0;
						var lastSale = "";
						var lastBuy = "";
						var lastInventory = "";
						var nextInventory = "";
						var s = {
							minCount: 0,
							buyCount: 0,
							targetCount: 0,
							menuItem: {
								id: node.data.id,
								type: node.data.entityType.toLowerCase()
							},
							quantity: quantity,
							store: {
								id: store.id
							}
						};

						var multiplier = quantityBaseIsInventory ? 1 : quantityInventory;
						if (menuitemutil.stocks[node.data.id + ";" + quantity] && menuitemutil.stocks[node.data.id + ";" + quantity][store.id]) {
							const v = menuitemutil.stocks[node.data.id + ";" + quantity][store.id];
							v.forEach((stock) => {
								s = stock;
								if (stock.expiryDate != null && stock.expiryDate < new Date().getTime()) {
									expired += stock.count * multiplier;
									totalExpired += stock.count * multiplier;
								} else {
									count += stock.count * multiplier;
									totalCount += stock.count * multiplier;
									lastSale = stock.lastSale;
									lastBuy = stock.lastBuy;
									lastInventory = stock.lastInventory;
									nextInventory = stock.inventoryPeriod;
								}
							});
						}
						count = Math.round(count * 1000) / 1000;
						expired = Math.round(expired * 1000) / 1000;
						if (node.data.availableQuantities) {
							if (count != 0 || expired != 0) {
								if (expired > 0) {
									$tdList.eq(c).html('<span>' + count + '</span> + <text style="color:red;text-decoration:line-through">' + expired + "</text>");
								} else {
									$tdList.eq(c).html("<div><span class='mobile-only'>" + store.name + "</span><span class='" + (s.minCount == 0 && s.buyCount == 0 ? "" : count <= s.minCount && s.minCount ? "icon-attention stock-critical" : s.buyCount && count <= s.buyCount ? "icon-attention stock-buy" : "") + "'>" + count + "</span>" +
										"</div>");
									$tdList.eq(c + 1).html("<div>" +
										"<div style='white-space:nowrap;display:flex; align-items:flex-start;flex-flow:column'>" +
										"<span class='icon-attention' style='color:red' data-toggle='tooltip' title='" + I18n.t("admin_local.criticalStock") + "'><input id='" + node.data.id + "-" + node.data.quantity + "-criticalStock' type='number' class='stock-critical' prop='minCount' value='" + Math.round(1000 * s.minCount * (quantityBaseIsInventory ? 1 : s.quantity) / 1000) + "'/></span>" +
										"<span class='icon-attention' style='color:green' data-toggle='tooltip' title='" + I18n.t("admin_local.minimumStock") + "'><input id='" + node.data.id + "-" + node.data.quantity + "-minimumStock' type='number' class='stock-buy' prop='buyCount' value='" + Math.round(1000 * s.buyCount * (quantityBaseIsInventory ? 1 : s.quantity)) / 1000 + "'/></span>" +
										"<span class='icon-cart-plus'  data-toggle='tooltip' title='" + I18n.t("admin_local.refillStock") + "'><input id='" + node.data.id + "-" + node.data.quantity + "-refillStock' type='number' class='stock_refill' prop='targetCount' value='" + Math.round(1000 * s.targetCount * (quantityBaseIsInventory ? 1 : s.quantity) / 1000) + "'/></span>" +
										"</div>" +
										"</div>");
									$tdList.eq(c + 1).find("input").on("change", event => {
										s[event.currentTarget.attributes.prop.nodeValue] = Math.round(1000000 * event.currentTarget.value / (quantityBaseIsInventory ? 1 : s.quantity)) / 1000000 || 0;
										post("adminService/" + sessionStorage.restaurantSelected + "/modifyStock", { ...s, menuItem: { id: s.menuItem.id, type: s.menuItem.type } }).done(d => {
											Object.keys(d).filter(key => key != "menuItem" && key != "store").forEach(key => s[key] = d[key]);
											processMenuItems(Object.assign({}, originalData));
										})
									})
								}
							} else {
								$tdList.eq(c).html("<div><span class='mobile-only'>" + store.name + "</span>" + "<span>" + count + "</span>"
									+ "</div>");
								$tdList.eq(c + 1).html("<div>" +
									"<div style='white-space:nowrap;display:flex; align-items:flex-start;flex-flow:column'>" +
									"<span class='icon-attention' style='color:red' data-toggle='tooltip' title='" + I18n.t("admin_local.criticalStock") + "'><input id='" + node.data.id + "-" + node.data.quantity + "-criticalStock' type='number' class='stock-critical' prop='minCount' value='" + s?.minCount + "'/></span>" +
									"<span class='icon-attention' style='color:green' data-toggle='tooltip' title='" + I18n.t("admin_local.minimumStock") + "'><input id='" + node.data.id + "-" + node.data.quantity + "-minimumStock' type='number' class='stock-buy' prop='buyCount' value='" + s?.buyCount + "'/></span>" +
									"<span class='icon-cart-plus'  data-toggle='tooltip' title='" + I18n.t("admin_local.refillStock") + "'><input id='" + node.data.id + "-" + node.data.quantity + "-refillStock' type='number' class='stock_refill' prop='targetCount' value='" + s?.targetCount + "'/></span>" +
									"</div></div>");

								$tdList.eq(c + 1).find("input").on("change", event => {
									s[event.currentTarget.attributes.prop.nodeValue] = event.currentTarget.value || 0;
									post("adminService/" + sessionStorage.restaurantSelected + "/modifyStock", { ...s, menuItem: { id: s.menuItem.id, type: s.menuItem.type } }).done(d => {
										Object.keys(d).filter(key => key != "menuItem" && key != "store").forEach(key => s[key] = d[key]);
										if (!menuitemutil.stocks[node.data.id + ";" + quantity]) {
											menuitemutil.stocks[node.data.id + ";" + quantity] = {};
										}
										if (!menuitemutil.stocks[node.data.id + ";" + quantity][store.id]) {
											menuitemutil.stocks[node.data.id + ";" + quantity][store.id] = [];
										}
										if (!menuitemutil.stocks[node.data.id + ";" + quantity][store.id].length)
											menuitemutil.stocks[node.data.id + ";" + quantity][store.id].push(d);
										processMenuItems(Object.assign({}, originalData));
									})
								})

							}
						} else {

							$tdList.eq(c).html("<div>" +
								"<div style='white-space:nowrap;display:flex; align-items:flex-start;flex-flow:row;justify-content: center;'>" +
								(node.data.stores[store.id]?.minCount > 0 ? "<span class='icon-attention' style='color:red' data-toggle='tooltip' title='" + I18n.t("admin_local.below_criticalStock") + "'>" + Math.round(1000 * node.data.stores[store.id].minCount) / 1000 + "</span>" : "") +
								(node.data.stores[store.id]?.buyCount > 0 ? "<span class='icon-attention' style='color:green' data-toggle='tooltip' title='" + I18n.t("admin_local.below_minimumStock") + "'>" + Math.round(1000 * node.data.stores[store.id].buyCount / 1000) + "</span>" : "") +
								"</div>" +
								"</div>");

						}
						if (localStorage['stocklist_stores_selected_' + store.id] == 'false') {
							$tdList.eq(c).hide();
							$tdList.eq(c + 1).hide();
							$tdList.eq(c + 2).hide();
						} else {
							$tdList.eq(c).show();
							$tdList.eq(c + 1).show();
							$tdList.eq(c + 2).show();
						}
						++c;
						++c;
						if (node.data.availableQuantities) {
							$tdList.eq(c).html("<div><div style='white-space:nowrap;display:flex; align-items:flex-start;flex-flow:column'>"
								+ "<div class='icon-download' data-toggle='tooltip' title='" + I18n.t("admin_local.lastBuy") + "'>" + formatRelativeDate(lastBuy) + "</div>"
								+ "<div class='icon-upload' data-toggle='tooltip' title='" + I18n.t("admin_local.lastSale") + "'>" + formatRelativeDate(lastSale) + "</div>"
								+ "<div  class='icon-check' data-toggle='tooltip' title='" + I18n.t("admin_local.lastInventory") + "'>" + formatRelativeDate(lastInventory) + "</div>"
								+ "</div>");
						}
					});
					totalCount = Math.round(totalCount * 1000) / 1000;
					totalExpired = Math.round(totalExpired * 1000) / 1000;

					if (totalCount != 0 || totalExpired != 0) {
						if (totalExpired > 0) {
							$tdList.eq(ct).html(totalCount + ' + <text style="color:red;text-decoration:line-through">' + totalExpired + "</text>");
						} else {
							$tdList.eq(ct).html("<div><span class='mobile-only'>Σ</span>" + "<span >" + totalCount + "</span>" + "</div>");
						}
					} else {
						$tdList.eq(ct).html("<div><span class='mobile-only'>Σ</span>" + "<span '></span></div>");
					}

					c++;
					if (node.data.inventoryItem !== null) {
						$tdList.eq(c).find("input").prop("checked", node.data.inventoryItem);
					} else {
						$tdList.eq(c).find("input").prop("indeterminate", true);
						if (!node.data.defaultInventoryItem) {
							$tdList.eq(c).find("input").addClass("parentUnchecked");
						}
					}
					$tdList.eq(c).find("input").attr("id", "i_" + node.data.entityType + node.data.id);
					$tdList.eq(c).find("label").attr("for", "i_" + node.data.entityType + node.data.id);
					$tdList.eq(c).find("input").addClass("checkbox isInventoryItem");

					if (node.data.entityType == 'Drink' || node.data.entityType == 'Meal') {
						c++;
						cost = cost != undefined ? cost : node.data.cost;
						costEstimation = costEstimation != undefined ? costEstimation : node.data.costEstimation;
						if (cost + costEstimation > 0) {
							if (costEstimation > 0 && cost != costEstimation)
								$tdList.eq(c).html(cost + '<br/><span class="browser-only">(' + Math.round(costEstimation) + '</span>)');
							else
								$tdList.eq(c).html(cost);
						}
					} else {
						c += 3;
					}
					var $span = $(node.span);
					$span.find("img").attr('style', "width:48px;height:48px");

				}
			});

		_menuitemutil.menuItems.filterNodes(filterNode);

	} else {
		$('#editMenuCategory').hide();
		$('#editMenuItem').hide();
		$('#addMenuItem').attr('disabled', 'true');
		$('#activate').prop('disabled', 'true');
		$('#deactivate').prop('disabled', 'true');
		$('#delete').prop('disabled', 'true');
		_menuitemutil.menuItems.reload(originalData);
	}

	_menuitemutil.tablehooks();

	if (f) {
		var t = $("input[id='" + f + "']");
		t.trigger("focus");
		t.trigger("select");
	}

}



function handleCheckBoxChange(t, handler) {
	var indeterminate = $(t).prop('indeterminate');
	var checked_old = $(t).prop('checked_old');
	var checked = $(t).prop('checked');
	if ($(t).prop('checked') && checked_old != undefined && (indeterminate == undefined || indeterminate == false)) {
		$(t).prop('indeterminate', true);
		$(t).prop('checked', false);
		$(t).removeProp('checked_old');
		handler(t);
		return;
	}
	$(t).prop('checked_old', $(t).prop('checked'));
	handler(t);
}

export const recalculateConsumption = (all = false) => {

	if (all) {
		const date = $("#recalcdate").val() ? new Date($("#recalcdate").val()).getTime() : "";
		utils.recalculateConsumption("", "", date, () => {
			reload();
		});
		return;
	}
	const date = $("#recalcdate").val() ? new Date($("#recalcdate").val()).getTime() : "";

	if (!date) {
		const previousMonthBeginning = moment().startOf('month').add(-1, "month");
		if (hasRole(auth.myStatus.roles, ["superadmin"])) {
		} else {
			$("#recalcdate").val(previousMonthBeginning.format("YYYY-MM-DD"));
			return;
		}
	} else {
		if (!hasRole(auth.myStatus.roles, ["superadmin"])) {
			const previousMonthBeginning = moment().startOf('month').add(-1, "month");
			const d = moment(date);
			if (d.isBefore(previousMonthBeginning)) {
				$("#recalcdate").val(previousMonthBeginning.format("YYYY-MM-DD"));
				return;
			}
		}
	}

	if (_menuitemutil.menuItems.getActiveNode() === null) {
		utils.recalculateConsumption("", "", date, () => {
			reload();
		});
		return;
	}
	var data = _menuitemutil.menuItems.getActiveNode().data;
	var ids = [];
	if (data.entityType == 'DrinkCategory' || data.entityType == 'MealCategory') {
		var node = _menuitemutil.menuItems.getActiveNode();
		function getChildren(node) {
			var children = node.getChildren();
			children && children.forEach((node) => {
				if (node.data.entityType == 'Drink' || node.data.entityType == 'Meal') {
					if (node.data.defaultInventoryItem && node.data.quantity != undefined)
						ids.push({ id: node.data.id, quantity: node.data.quantity });
				} else {
					getChildren(node);
				}
			});
		}
		getChildren(node);
	} else {
		ids.push({ id: data.id, quantity: data.quantity });
	}
	if (data.quantity == undefined) {
		messageDialog(admin_local.failed_to_execute_action, admin_local.no_inventory_quantity);
	} else {
		const total = ids.length;
		const job = total > 1 ? startJob(I18n.t("admin_local.recalculateConsumption") + " " + getLocale(data.name)) : null;

		function recalc(ids) {
			try {
				if (ids.length > 0) {
					utils.recalculateConsumption(ids[0].id, ids[0].quantity, date, () => {
						ids.splice(0, 1);
						jobProgress(job, 100 * (total - ids.length) / total);
						recalc(ids);
					});
				} else {
					if (total > 1)
						endJob(job);
					reload();
				}
			} catch (ex) {
				if (total > 1)
					endJob(job);
				reload();
			}
		}
		recalc(ids);
	}
}

function paste(event) {
	var items = (event.clipboardData || event.originalEvent.clipboardData).items;
	for (let index in items) {
		var item = items[index];
		if (item.kind === 'file') {
			var blob = item.getAsFile();
			var reader = new FileReader();
			const modalOpen = menuitemutil.modalOpen;
			reader.onload = function (event) {
				$(modalOpen.find('#smallImageDiv')).removeClass('hidden');
				onPhotoDataSuccess(event.target.result, $(modalOpen.find('#smallImageDiv img')));
			}; // data url!
			reader.readAsDataURL(blob);
		}
	}
}

const menuItemTableId = "#menuItemsFreezeTable";


function getStocks(handler, errorHandler) {
	get("adminService/" + sessionStorage.restaurantSelected + "/getStocks").done(function (data) { resultHandler(data, handler, errorHandler) });
}

var myChart;
var isCategory = false;
var toShow = "";
function populateCharts() {
	if ($('#chart-block').hasClass('hidden'))
		return;
	if (isCategory) {
		localStorage.admin_stocklist_dataset_focus = false;
	}
	isCategory = false;
	if (!_menuitemutil.menuItems || _menuitemutil.menuItems.getActiveNode() === null)
		return;
	var menuItem = _menuitemutil.menuItems.getActiveNode().data;
	if (typeof menuItem.quantity == 'undefined')
		return;
	$('#chart-block').loadingModal('show');
	toShow = menuItem.id;
	get("adminService/" + sessionStorage.restaurantSelected + "/getStockHistory/" + 0 + "/" + menuItem.id + "/" + menuItem.quantity + "?from=" + localStorage.admin_stocklist_graph_start + "&to=" + localStorage.admin_stocklist_graph_end, undefined, undefined, undefined, false).done(function (data) {
		if (toShow == menuItem.id) {
			drawChart(data);
			$('#chart-block').loadingModal('hide');
		}
	});
}

var menuItemIds = [];
function populateCategoryCharts() {
	if (!_menuitemutil.menuItems)
		return;
	if ($('#chart-block').hasClass('hidden'))
		return;
	if (!isCategory) {
		localStorage.admin_stocklist_dataset_focus = false;
	}
	isCategory = true;
	var category = _menuitemutil.menuItems.getActiveNode();
	menuItemIds = [];
	category.children.forEach((c) => {
		if (menuItemIds.indexOf(c.data.id) == -1 && c.data.defaultInventoryItem && c.data.availableQuantities && c.data.availableQuantities.filter(q => q.inventoryItem).length > 0)
			menuItemIds.push(c.data.id);
	});
	const chartUrl = "adminService/" + sessionStorage.restaurantSelected + "/getMenuItemsHistory/0?from=" + localStorage.admin_stocklist_graph_start + "&to=" + localStorage.admin_stocklist_graph_end + "&timestamp=" + (new Date().getTime())
	$('#chart-block').loadingModal('show');
	const toShow = menuItemIds;
	post(chartUrl, menuItemIds, undefined, false, undefined, undefined, 120000).done(function (data) {
		if (toShow == menuItemIds)
			drawChart(data);
		$('#chart-block').loadingModal('hide');
	});
}

const filterNode = (node) => {
	const stockDanger = localStorage['stocklist_filter_stockDanger'] == "true";
	const stockBuy = localStorage['stocklist_filter_stockBuy'] == "true";

	function inventoryChild(data) {
		if (_menuitemutil.filter.name) {
			const c = removeAccents(getLocale(node.data.name, localStorage.languageSelected));
			if (c.toLowerCase().indexOf(removeAccents(_menuitemutil.filter.name).toLowerCase()) === -1
			)
				return false;
		}

		if ((stockDanger || stockBuy) && !menuitemutil.stores.find((store) => {
			if (stockDanger && data.stores && data.stores[store.id] && data.stores[store.id].minCount) {
				return true;
			} else if (stockBuy && data.stores && data.stores[store.id] && data.stores[store.id].buyCount) {
				return true;
			}
			return false;
		}))
			return false;

		if (node.data.defaultInventoryItem == true && node.data.inventoryItem !== false)
			return true;
		var t = false;
		if (data.children)
			data.children.forEach((data) => {
				t = t || inventoryChild(data);
			});


		return t;

	}
	return inventoryChild(node.data);
}

const filterNode2 = (node) => {
	if (filterNode(node))
		return true;
	if (node.parent && node.parent.data && node.parent.data.name)
		return filterNode2(node.parent);
	return false;
}

const filterNodes = () => {
	_menuitemutil.menuItems.filterNodes(function (node) {
		return filterNode2(node);

	});
};

export const filterChanged = e => {
	_menuitemutil.filter["name"] = e.target.value.toLowerCase();
	_menuitemutil.filter["manufacturer"] = e.target.value.toLowerCase();
	filterNodes();
};


function trimDate(date, range) {
	date = new Date(date);
	date.setMilliseconds(0);
	date.setSeconds(0);
	if (range == 'week') {
		date = getMonday(date);
		date.setHours(0);
		date.setMinutes(0);
	} else if (range == 'month') {
		date = new Date(date.getFullYear(), date.getMonth(), 1);
		date.setHours(0);
		date.setMinutes(0);
	} else if (range == 'day') {
		date.setHours(0);
		date.setMinutes(0);
	} else if (range == 'hour') {
		date.setMinutes(0);
	}
	return date;
}

const defaults = {
	"k": "false", "f": "false", "d": "false", "p": "true", "c": "true", "r": "true", "i": "false", "b": "false", "o": "true", "oo": "true", "pr": "true", "t": "false"
};


var oldData;
function drawChart(data) {
	if ($('#chart-block').hasClass('hidden'))
		return;
	var range = $("[name='range']:checked").attr('id');
	ajaxCallStart("drawchart", 0);
	setTimeout(() => {
		try {
			if (typeof data == "undefined")
				data = oldData;
			else
				oldData = data;
			if (!_menuitemutil.menuItems || _menuitemutil.menuItems.getActiveNode() === null)
				return;
			var menuItem = _menuitemutil.menuItems.getActiveNode().data;
			var $tdList = $(_menuitemutil.menuItems.getActiveNode().tr).find(">td");
			var currentQuantityType = getInventoryQuantity(menuItem, 1) + " " + I18n.t("local." + menuItem.quantityType);
			if (currentQuantityType.split(" ")[0] == 1) currentQuantityType = currentQuantityType.split(' ')[1];
			if (typeof menuItem.quantity == 'undefined')
				return;

			var focus = localStorage.admin_stocklist_dataset_focus == "true";

			var ctx = document.getElementById('myChart').getContext('2d');
			var historyData = [];
			var datasets = {};
			var stores = [];

			var dates = new SortedSet({ comparator: function (a, b) { return a.getTime() - b.getTime(); } })
			data.ConsumptionStockEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
					return;
				}
				var date = trimDate(h.date, range);
				if (SortedSet.find(dates, date) === null)
					dates.insert(date);
			});
			data.CostAndPriceHistory.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
					return;
				}
				var date = trimDate(h.date, range);
				if (SortedSet.find(dates, date) === null)
					dates.insert(date);
				if (stores.indexOf(h.store.id) == -1)
					stores.push(h.store.id);
			});
			var dataExample = [];
			dates.forEach((d) => {
				dataExample.push({ x: d, y: 0 })
			});
			/*if (isCategory) {
				var key = "k";
				var label = admin_local.stock;
				datasets[key] = { yAxisID: 'y-axis-1', steppedLine:'middle', type:"line", label: label, data:  $.extend(true,[],dataExample), fill:false, borderColor: colors[0] }
			} else {*/
			data.CostAndPriceHistory.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
					return;
				}
				var key = "k-" + h.store.id;
				if (datasets[key]) {

				} else {
					var label = stores.length == 1 ? admin_local.stock : getStore(h.store.id).name + " " + admin_local.stock.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-1', steppedLine: 'right', type: "line", label: label, data: [], fill: false, borderColor: convertHex(getStore(h.store.id).color, 100) }
				}
				var date = trimDate(h.date, range);
				const menuItem = getMenuItem(h.menuItem);
				const inventoryQuantity = getInventoryQuantity(menuItem, h.quantity);
				const quantity = h.quantity / inventoryQuantity;
				if (datasets[key].data.length > 0) {
					var dd = datasets[key].data[datasets[key].data.length - 1];
					if (dd.x.getTime() == date.getTime()) {
						dd.count[h.menuItem + "-" + h.quantity] = h.count;
						var count = 0;
						Object.values(dd.count).forEach(c => { count = count + c });
						dd.y = count * quantity;
						dd.data.count = h.count * quantity;
					} else if (datasets[key].data.length > 0) {
						dd = { x: date, count: $.extend(true, {}, dd.count), data: { count: dd.data.count } };
						datasets[key].data.push(dd);
						dd.count[h.menuItem + "-" + h.quantity] = h.count;
						var count = 0;
						Object.values(dd.count).forEach(c => { count = count + c });
						dd.y = count * quantity;
						dd.data.count = h.count * quantity;
					} else {
						var hh = Object.assign({}, h);
						hh.date = date;
						datasets[key].data.push(dd = { x: date, y: h.count * quantity, data: hh, count: {} });
						dd.count[h.menuItem + "-" + h.quantity] = h.count;
					}
				} else {
					var hh = Object.assign({}, h);
					var dd;
					datasets[key].data.push(dd = { x: date, y: h.count * h.quantity / inventoryQuantity, data: hh, count: {} });
					dd.count[h.menuItem + "-" + h.quantity] = h.count * h.quantity / inventoryQuantity;
				}
			});

			//}
			data.CostAndPriceHistory.forEach((h) => {
				if (h.type !== "sale" && h.type !== "stock_disposal")
					return;
				if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
					return;
				}
				var key = h.type === "sale" ? "f-" + h.store.id : "d-" + h.store.id;
				var dataset = datasets[key];
				if (dataset) {

				} else {
					const l = h.type === "sale" ? admin_local.consumption : admin_local.inventory_disposal;
					var label = stores.length == 1 ? l : getStore(h.store.id).name + " " + l.toLowerCase();
					dataset = datasets[key] = { yAxisID: 'y-axis-1', pointRadius: 3, width: 200, borderWidth: 3, pointStyle: "rect", type: "bar", label: label, stacked: true, data: $.extend(true, [], dataExample), fill: false, backgroundColor: h.type === "sale" ? convertHex(getStore(h.store.id).color, 20) : darken(getStore(h.store.id).color, 40) }
				}
				var date = trimDate(h.date, range);
				var dd = getEntry(dataset.data, date);
				const menuItem = getMenuItem(h.menuItem);
				const inventoryQuantity = getInventoryQuantity(menuItem, h.quantity);
				const quantity = h.quantity / inventoryQuantity;
				if (!dd.comment) dd.comment = [];
				dd.y = Math.round(1000 * (dd.y - h.consumption * quantity)) / 1000;
			});


			data.ConsumptionStockEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
					return;
				}
				var key = "p-" + h.stock.store.id;
				if (datasets[key]) {

				} else {
					const store = getStore(h.stock.store.id);
					var label = stores.length == 1 ? admin_local.price : store.name + " " + admin_local.price.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-2', pointRadius: 5, pointStyle: "rect", steppedLine: 'middle', type: "line", label: label, data: [], fill: true, borderColor: convertHex(store.color, 70) }
				}
				var date = trimDate(h.date, range);
				if (datasets[key].data.length > 0) {
					var dd = datasets[key].data[datasets[key].data.length - 1];
					if (dd.x.getTime() == date.getTime()) {
						dd.totalPrice += h.price;
						dd.totalCount += h.count;
						dd.y = dd.price = Math.round(1000 * dd.totalPrice / dd.totalCount) / 1000;
						dd.data.price = h.price;
					} else {
						var hh = Object.assign({}, h);
						hh.date = date;
						datasets[key].data.push({ x: date, y: Math.round(1000 * h.price / h.count) / 1000, price: h.price, totalPrice: h.price, totalCount: h.count, data: hh });
					}
					//console.log(h, dd);
				} else {
					var hh = Object.assign({}, h);
					datasets[key].data.push({ x: date, y: Math.round(1000 * h.price / h.count) / 1000, totalPrice: h.price, totalCount: h.count, data: hh });
				}
			});
			data.CostAndPriceHistory.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
					return;
				}
				if (h.type != 'sale')
					return;
				var key = "r-" + h.store.id;
				if (datasets[key]) {
				} else {
					var label = stores.length == 1 ? admin_local.price_goal : getStore(h.store.id).name + " " + admin_local.price_goal.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-2', borderDash: [10, 5], borderWidth: 1, pointRadius: 0, steppedLine: 'middle', type: "line", label: label, data: [], fill: false, borderColor: darken(getStore(h.store.id).color, 30) }
				}
				var date = trimDate(h.date, range);
				if (datasets[key].data.length > 0) {
					var dd = datasets[key].data[datasets[key].data.length - 1];
					if (dd.x.getTime() == date.getTime()) {
						dd.y = h.cost * menuItem.defaultPricePerCostRatio / h.quantity;
					} else {
						var hh = Object.assign({}, h);
						hh.date = date;
						datasets[key].data.push({ x: date, y: h.cost * menuItem.defaultPricePerCostRatio / h.quantity, data: hh });
					}
				} else {
					var hh = Object.assign({}, h);
					datasets[key].data.push({ x: date, y: h.cost * menuItem.defaultPricePerCostRatio / h.quantity, data: hh });
				}
			});
			data.CostAndPriceHistory.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
					return;
				}
				if (h.type != 'sale')
					return;
				var key = "c-" + h.store.id;
				if (datasets[key]) {

				} else {
					var label = stores.length == 1 ? admin_local.cost : getStore(h.store.id).name + " " + admin_local.cost.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-2', pointRadius: 5, borderWidth: 2, pointStyle: "rectRot", steppedLine: 'middle', type: "line", label: label, data: [], fill: true, borderColor: convertHex(getStore(h.store.id).color, 70) }
				}
				var date = trimDate(h.date, range);
				if (datasets[key].data.length > 0) {
					var dd = datasets[key].data[datasets[key].data.length - 1];
					if (dd.x.getTime() == date.getTime()) {
						dd.y = h.cost;
						dd.data.cost = h.cost /*/ h.quantity*/;
					} else {
						var hh = Object.assign({}, h);
						hh.date = date;
						//hh.cost = hh.cost /*/ hh.quantity*/;
						datasets[key].data.push({ x: date, y: h.cost /*/ h.quantity*/, data: hh });
					}
				} else {
					var hh = Object.assign({}, h);
					//hh.cost = hh.cost /*/ hh.quantity*/;
					datasets[key].data.push({ x: date, y: h.cost /*/ h.quantity*/, data: hh });
				}
			});
			//Bevétel - income
			data.ConsumptionStockEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
					return;
				}
				//if (h.type != 'sale')
				//return;
				var key = "o-" + h.stock.store.id;
				if (datasets[key]) {

				} else {
					const store = getStore(h.stock.store.id);
					var label = stores.length == 1 ? admin_local.income : store.name + " " + admin_local.income.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-2', pointRadius: 5, pointStyle: "rect", steppedLine: 'middle', type: "line", label: label, data: [], fill: true, borderColor: convertHex(store.color, 70) }
				}
				var date = trimDate(h.date, range);
				if (datasets[key].data.length > 0) {
					var dd = datasets[key].data[datasets[key].data.length - 1];
					if (dd.x.getTime() == date.getTime()) {
						dd.y += h.price;
					} else {
						var hh = Object.assign({}, h);
						hh.date = date;
						datasets[key].data.push({ x: date, y: h.price });
					}
				} else {
					var hh = Object.assign({}, h);
					datasets[key].data.push({ x: date, y: h.price });
				}
			});
			//Árrés - profit margin
			data.ConsumptionStockEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
					return;
				}
				var key = "oo-" + h.stock.store.id;
				if (datasets[key]) {

				} else {
					const store = getStore(h.stock.store.id);
					var label = stores.length == 1 ? admin_local.profit_margin : store.name + " " + admin_local.profit_margin.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-2', pointRadius: 5, pointStyle: "rect", steppedLine: 'middle', type: "line", label: label, data: [], fill: true, borderColor: convertHex(store.color, 70) }
				}
				var date = trimDate(h.date, range);
				if (datasets[key].data.length > 0) {
					var dd = datasets[key].data[datasets[key].data.length - 1];
					if (dd.x.getTime() == date.getTime()) {
						dd.y = dd.income += (h.price - h.cost);
					} else {
						var hh = Object.assign({}, h);
						hh.date = date;
						datasets[key].data.push({ x: date, y: (h.price - h.cost), income: (h.price - h.cost) });
					}
				} else {
					var hh = Object.assign({}, h);
					datasets[key].data.push({ x: date, y: (h.price - h.cost), income: (h.price - h.cost) });
				}
			});
			data.ConsumptionStockEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
					return;
				}
				var key = "pr-" + h.stock.store.id;
				if (datasets[key]) {

				} else {
					const store = getStore(h.stock.store.id);
					var label = (stores.length == 1 ? admin_local.profit_margin : store.name + " " + admin_local.profit_margin.toLowerCase()) + " %";
					datasets[key] = { yAxisID: 'y-axis-5', pointRadius: 5, borderWidth: 2, pointStyle: "rectRot", steppedLine: 'middle', type: "line", label: label, data: [], fill: true, borderColor: convertHex(store.color, 70) }
				}
				const p = h.price;
				const c = h.cost;
				var date = trimDate(h.date, range);
				if (datasets[key].data.length > 0) {
					var dd = datasets[key].data[datasets[key].data.length - 1];
					if (dd.x.getTime() == date.getTime()) {
						dd.totalPrice += p;
						dd.cost += c;
						dd.y = Math.round(100 * (dd.totalPrice - dd.cost) / dd.cost);
					} else {
						var hh = Object.assign({}, h);
						hh.date = date;
						datasets[key].data.push({ x: date, y: Math.round(100 * (p - c) / c), totalPrice: p, cost: c });
					}
				} else {
					var hh = Object.assign({}, h);
					datasets[key].data.push({ x: date, y: Math.round(100 * (p - c) / c), totalPrice: p, cost: c });
				}
			});
			data.IncomingStockEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
					return;
				}
				var key = "b-" + h.store.id;
				if (datasets[key]) {

				} else {
					var label = stores.length == 1 ? admin_local.incoming_stock : getStore(h.store.id).name + " " + admin_local.incoming_stock.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-1', type: "bar", label: label, data: $.extend(true, [], dataExample), fill: false, backgroundColor: lighten(getStore(h.store.id).color, 20) }
				}
				const menuItem = getMenuItem(h.menuItem.id);
				const inventoryQuantity = getInventoryQuantity(menuItem, h.quantity);
				const quantity = h.quantity / inventoryQuantity;

				var date = trimDate(h.date, range);
				var dd = getEntry(datasets[key].data, date);
				if (dd.data == undefined) {
					var hh = Object.assign({}, h);
					dd.data = hh;
					dd.y = h.count * quantity;
					return;
				}



				dd.y += h.count * quantity;
				dd.data.net_unit_price = (dd.data.count * dd.data.net_unit_price + h.count * h.net_unit_price) / (dd.data.count + h.count);
				dd.data.count += h.count;
			});

			data.InventoryEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
					return;
				}
				var key = "i-" + h.store.id;
				if (datasets[key]) {

				} else {
					var label = stores.length == 1 ? admin_local.inventory : getStore(h.store.id).name + " " + admin_local.inventory.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-1', type: "bar", label: label, data: $.extend(true, [], dataExample), fill: false, backgroundColor: darken(getStore(h.store.id).color, 20) }
				}

				const menuItem = getMenuItem(h.menuItem.id);
				const inventoryQuantity = getInventoryQuantity(menuItem, h.quantity);
				const quantity = h.quantity / inventoryQuantity;

				var date = trimDate(h.date, range);
				var dd = getEntry(datasets[key].data, date);
				if (dd.data == undefined) {
					dd.y = h.count * quantity;
					var hh = Object.assign({}, h);
					dd.data = hh;
					hh.originalCount *= quantity;
					dd.data.count = h.count * quantity;
					return;
				}
				dd.y = h.count * quantity;
				dd.data.count = h.count * quantity;
			});
			/*
			data.InventoryDisposalEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
					return;
				}
				var key = "d-" + h.store.id;
				if (datasets[key]) {

				} else {
					var label = stores.length == 1 ? admin_local.inventory_disposal : getStore(h.store.id).name + " " + admin_local.inventory.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-1', type: "bar", label: label, data: $.extend(true, [], dataExample), fill: false, backgroundColor: darken(getStore(h.store.id).color, 40) }
				}
				const menuItem = getMenuItem(h.menuItem.id);
				const inventoryQuantity = getInventoryQuantity(menuItem, h.quantity);
				const quantity = h.quantity / inventoryQuantity;

				var date = trimDate(h.date, range);
				var dd = getEntry(datasets[key].data, date);
				if (dd.comment == undefined) {
					dd.y = 0;
					dd.comment = [];
				}
				dd.y -= h.count * quantity;
				dd.comment.push(h.count + " " + h.comment);
			});
*/
			data.StockMovementEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.fromStore.id] == 'false') {
					return;
				}
				var key = "t-" + h.fromStore.id;
				if (datasets[key]) {

				} else {
					var label = stores.length == 1 ? admin_local.stock_movement : getStore(h.fromStore.id).name + " " + admin_local.stock_movement.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-1', type: "bar", label: label, data: $.extend(true, [], dataExample), fill: false, backgroundColor: darken(getStore(h.fromStore.id).color, 30) }
				}
				var date = trimDate(h.date, range);
				var dd = getEntry(datasets[key].data, date);
				if (dd.comment == undefined) {
					dd.y = 0;
					dd.comment = [];
				}
				const menuItem = getMenuItem(h.menuItem.id);
				const inventoryQuantity = getInventoryQuantity(menuItem, h.quantity);
				const quantity = h.quantity / inventoryQuantity;

				dd.y -= h.count * quantity;
				dd.comment.push(h.count + " " + h.comment);
			});
			data.StockMovementEntry.forEach((h) => {
				if (localStorage['stocklist_stores_selected_' + h.toStore.id] == 'false') {
					return;
				}
				var key = "t-" + h.toStore.id;
				if (datasets[key]) {

				} else {
					var label = stores.length == 1 ? admin_local.stock_movement : getStore(h.toStore.id).name + " " + admin_local.stock_movement.toLowerCase();
					datasets[key] = { yAxisID: 'y-axis-1', type: "bar", label: label, data: $.extend(true, [], dataExample), fill: false, backgroundColor: darken(getStore(h.toStore.id).color, 20) }
				}
				const menuItem = getMenuItem(h.menuItem.id);
				const inventoryQuantity = getInventoryQuantity(menuItem, h.quantity);
				const quantity = h.quantity / inventoryQuantity;

				var date = trimDate(h.date, range);
				var dd = getEntry(datasets[key].data, date);
				if (dd.comment == undefined) {
					dd.y = 0;
					dd.comment = [];
				}
				dd.y += h.count * quantity;
				dd.comment.push((-h.count) + " " + h.comment);
			});

			var yaxes = [];
			var datas = [];
			var datasetsSelected = 0;
			var mainFocusPrefix = "";
			Object.keys(datasets).forEach((key, index) => {
				const state = (localStorage['admin_stocklist_dataset_' + key] || defaults[key.split("-")[0]] || "true");
				console.log(key, state);
				datasets[key].hidden = state == "true";
				datas.push(datasets[key]);
				if (state != "true") {
					datasetsSelected++;
					mainFocusPrefix = key.split('-')[0];
				} else {
					//if (focus)
					datasets[key].hidden = true;
				}
			});

			if (datasetsSelected != 1) {
				localStorage['admin_stocklist_dataset_focus'] = false;
			}

			function getEntry(data, x) {
				var v = {};
				data.forEach((d) => {
					if (d.x.getTime() == x.getTime())
						v = d;
				});
				return v;
			}

			var focusPrefix = "";
			if (isCategory && datasetsSelected == 1 && mainFocusPrefix == "k" && focus) {
				currentQuantityType = [];
				focusPrefix = "kk";
				data.CostAndPriceHistory.forEach((h) => {
					if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
						return;
					}
					/*
					if (h.type != 'sale')
						return;
						*/
					datasets["k-" + h.store.id].data = [];
					datasets["k-" + h.store.id].yAxisID = 'y-axis-6';
					var menuItem = getMenuItem(h.menuItem);
					if (currentQuantityType.indexOf(local[menuItem.quantityType]) == -1)
						currentQuantityType.push(local[menuItem.quantityType]);
					h.count = Math.round(h.count * 1000) / 1000;
					var quantity = Math.abs(h.quantity);
					var key = focusPrefix + "-" + h.menuItem + "-" + quantity;
					var dataset = datasets[key];
					if (!dataset) {
						let datas = $.extend(true, [], dataExample);
						var label = (stores.length == 1 ? "" : getStore(h.store.id).name + " ") + getLocale(menuItem.name, localStorage.language) + " " + quantity + " " + local[menuItem.quantityType];
						datasets[key] = dataset = { yAxisID: 'y-axis-6', type: "line", lineTension: .1, showLine: true, pointBorderColor: colors[Object.keys(datasets).length], pointStyle: 'circle', pointBackgroundColor: colors[Object.keys(datasets).length], lineWidth: 0, pointBorderWidth: 0, borderWidth: 0, label: label, data: [], fill: false, backgroundColor: colors[Object.keys(datasets).length], borderColor: colors[Object.keys(datasets).length] }
					}

					const inventoryQuantity = getInventoryQuantity(menuItem, h.quantity);
					quantity = h.quantity / inventoryQuantity;

					var date = trimDate(h.date, range);
					if (datasets[key].data.length > 0) {
						var dd = datasets[key].data[datasets[key].data.length - 1];
						if (dd.x.getTime() == date.getTime()) {
							dd.y = h.count;
							dd.data.count = h.count;
						} else {
							var hh = Object.assign({}, h);
							hh.date = date;
							datasets[key].data.push({ x: date, y: h.count, data: hh });
						}
					} else {
						var hh = Object.assign({}, h);
						datasets[key].data.push({ x: date, y: h.count, data: hh });
					}

					//var dd = getEntry(dataset.data, date);
					//dd.y=h.count;
					//dd.currentQuantityType = local[menuItem.quantityType];
				});
			} else if (datasetsSelected == 1 && mainFocusPrefix == "f" && focus) {
				currentQuantityType = [];
				focusPrefix = "ff";
				Object.keys(datasets).forEach((key) => {
					if (key.split('-')[0] == 'f') {
						datasets[key].data = [];
						//datasets[key].yAxisID = 'y-axis-6';
					}
				});
				if (isCategory)
					data.Orders.forEach((h) => {
						var menuItem = getMenuItem(h.menuItemId);
						if (currentQuantityType.indexOf(local[menuItem.quantityType]) == -1)
							currentQuantityType.push(local[menuItem.quantityType]);
						var quantity = Math.abs(h.quantity);
						var key = focusPrefix + "-" + h.menuItemId + "-" + quantity;
						if (datasets[key]) {

						} else {
							var label = getLocale(menuItem.name, localStorage.language) + " " + h.quantity + " " + local[menuItem.quantityType];
							datasets[key] = { yAxisID: 'y-axis-6', pointRadius: 3, lineTension: .1, borderWidth: 3, pointStyle: "rect", type: "line", label: label, data: [], fill: false, backgroundColor: colors[Object.keys(datasets).length], borderColor: colors[Object.keys(datasets).length] }
						}
						var date = trimDate(h.created, range);
						if (datasets[key].data.length > 0) {
							var dd = datasets[key].data[datasets[key].data.length - 1];
							if (dd.x.getTime() == date.getTime()) {
								dd.y += h.quantity;
							} else {
								var hh = Object.assign({}, h);
								hh.date = date;
								datasets[key].data.push({ x: date, y: quantity, currentQuantityType: local[menuItem.quantityType] });
							}
						} else {
							var hh = Object.assign({}, h);
							datasets[key].data.push({ x: date, y: h.quantity, currentQuantityType: local[menuItem.quantityType] });
						}
					});
				else
					data.ConsumptionStockEntry.forEach((h) => {
						if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
							return;
						}
						datasets["f-" + h.stock.store.id].data = [];
						h.count = Math.round(h.count * 1000) / 1000;
						var key = focusPrefix + "-" + h.usedMenuItem + "-" + h.quantity + "-" + h.stock.store.id;
						var dataset = datasets[key];
						if (!dataset) {
							let datas = $.extend(true, [], dataExample);
							var label = (stores.length == 1 ? "" : getStore(h.store.id).name + " ") + getLocale(getMenuItem(h.usedMenuItem).name, localStorage.language);
							datasets[key] = dataset = { yAxisID: 'y-axis-3', type: "bar", lineTension: .1, showLine: true, pointBorderColor: colors[Object.keys(datasets).length], pointStyle: 'circle', pointBackgroundColor: colors[Object.keys(datasets).length], lineWidth: 0, pointBorderWidth: 0, borderWidth: 0, label: label, data: datas, fill: true, backgroundColor: colors[Object.keys(datasets).length] }
						}
						var date = trimDate(h.date, range);

						var dd = getEntry(dataset.data, date);
						dd.y += h.count * h.quantity;
					});
			} else if (datasetsSelected == 1 && mainFocusPrefix == "o" && focus) {
				data.ConsumptionStockEntry.forEach((h) => {
					if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
						return;
					}
					datasets["o-" + h.stock.store.id].data = [];
					datasets["o-" + h.stock.store.id].yAxisID = 'y-axis-4';
					h.count = Math.round(h.count * 1000) / 1000;
					var key = "pc-" + h.usedMenuItem + "-" + h.quantity + "-" + h.stock.store.id;
					var dataset = datasets[key];
					if (!dataset) {
						let datas = $.extend(true, [], dataExample);
						var label = (stores.length == 1 ? "" : getStore(h.store.id).name + " ") + getLocale(getMenuItem(h.usedMenuItem).name, localStorage.language);
						datasets[key] = dataset = { yAxisID: 'y-axis-4', type: "bar", lineTension: .1, showLine: true, pointBorderColor: colors[Object.keys(datasets).length], pointStyle: 'circle', pointBackgroundColor: colors[Object.keys(datasets).length], lineWidth: 0, pointBorderWidth: 0, borderWidth: 0, label: label, data: datas, fill: true, backgroundColor: colors[Object.keys(datasets).length] }
					}
					var date = trimDate(h.date, range);

					var dd = getEntry(dataset.data, date);
					dd.y += h.price;
				});
				focusPrefix = "pc";
			} else if (datasetsSelected == 1 && mainFocusPrefix == 'p' && focus) {
				data.CostAndPriceHistory.forEach((h) => {
					if (localStorage['stocklist_stores_selected_' + h.store.id] == 'false') {
						return;
					}
					if (h.type != 'sale')
						return;
					datasets["p-" + h.store.id].data = [];
					datasets["p-" + h.store.id].yAxisID = 'y-axis-2';
					h.count = Math.round(h.count * 1000) / 1000;
					var key = "pp-" + h.menuItem.id + "-" + h.quantity + "-" + h.store.id;
					var dataset = datasets[key];
					if (!dataset) {
						var label = (stores.length == 1 ? "" : getStore(h.store.id).name + " ") + getLocale(getMenuItem(h.menuItem).name, localStorage.language);
						//datasets[key] = dataset = {  yAxisID: 'y-axis-4', type:"line", lineTension:.1, showLine:true, pointBorderColor:colors[Object.keys(datasets).length],  pointStyle: 'circle', pointBackgroundColor: colors[Object.keys(datasets).length], lineWidth:0, pointBorderWidth:0, borderWidth:0, label: label, data: [], fill:false, backgroundColor: colors[Object.keys(datasets).length] }
						datasets[key] = dataset = { yAxisID: 'y-axis-2', pointRadius: 3, lineTension: .1, borderWidth: 3, pointStyle: "rect", type: "line", label: label, data: [], fill: false, backgroundColor: colors[Object.keys(datasets).length], borderColor: colors[Object.keys(datasets).length] }
					}
					var date = trimDate(h.date, range);
					if (dataset.data.length > 0) {
						var dd = dataset.data[dataset.data.length - 1];
						if (dd.x.getTime() == date.getTime()) {
							dd.totalPrice += h.price * h.consumption;
							dd.totalCount += h.consumption;
							dd.y = dd.price = Math.round(1000 * dd.totalPrice / dd.totalCount) / 1000;
							dd.data.price = h.price;
						} else {
							var hh = Object.assign({}, h);
							hh.date = date;
							dataset.data.push({ x: date, y: h.price, price: h.price, totalPrice: h.price * h.consumption, totalCount: h.consumption, data: hh });
						}
					} else {
						var hh = Object.assign({}, h);
						dataset.data.push({ x: date, y: h.price, totalPrice: h.price * h.consumption, totalCount: h.consumption, data: hh });
					}
				});

			} else if (datasetsSelected == 1 && mainFocusPrefix == "oo" && focus) {
				data.ConsumptionStockEntry.forEach((h) => {
					if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
						return;
					}
					datasets["oo-" + h.stock.store.id].data = [];
					datasets["oo-" + h.stock.store.id].yAxisID = 'y-axis-4';
					h.count = Math.round(h.count * 1000) / 1000;
					var key = "opc-" + h.usedMenuItem + "-" + h.quantity + "-" + h.stock.store.id;
					var dataset = datasets[key];
					if (!dataset) {
						let datas = $.extend(true, [], dataExample);
						var label = (stores.length == 1 ? "" : getStore(h.store.id).name + " ") + getLocale(getMenuItem(h.usedMenuItem).name, localStorage.language);
						datasets[key] = dataset = { yAxisID: 'y-axis-4', type: "bar", lineTension: .1, showLine: true, pointBorderColor: colors[Object.keys(datasets).length], pointStyle: 'circle', pointBackgroundColor: colors[Object.keys(datasets).length], lineWidth: 0, pointBorderWidth: 0, borderWidth: 0, label: label, data: datas, fill: true, backgroundColor: colors[Object.keys(datasets).length] }
					}
					var date = trimDate(h.date, range);

					var dd = getEntry(dataset.data, date);
					dd.y += (h.price - h.cost);
				});
				focusPrefix = "pc";
			} else if (datasetsSelected == 1 && mainFocusPrefix == "pr" && focus) {
				data.ConsumptionStockEntry.forEach((h) => {
					if (localStorage['stocklist_stores_selected_' + h.stock.store.id] == 'false') {
						return;
					}
					datasets["pr-" + h.stock.store.id].data = [];
					datasets["pr-" + h.stock.store.id].yAxisID = 'y-axis-5';
					h.count = Math.round(h.count * 1000) / 1000;
					var key = "ppr-" + h.usedMenuItem + "-" + h.quantity + "-" + h.stock.store.id;
					var dataset = datasets[key];
					if (!dataset) {
						let datas = $.extend(true, [], dataExample);
						var label = (stores.length == 1 ? "" : getStore(h.store.id).name + " ") + getLocale(getMenuItem(h.usedMenuItem).name, localStorage.language);
						datasets[key] = dataset = { yAxisID: 'y-axis-5', type: "line", lineTension: .1, showLine: true, pointBorderColor: colors[Object.keys(datasets).length], pointStyle: 'circle', pointBackgroundColor: colors[Object.keys(datasets).length], lineWidth: 3, pointBorderWidth: 2, borderWidth: 3, label: label, data: datas, fill: false, backgroundColor: colors[Object.keys(datasets).length] }
					}
					var date = trimDate(h.date, range);
					var dd = getEntry(dataset.data, date);
					dd.totalPrice = (dd.totalPrice ? dd.totalPrice : 0) + h.price;
					dd.totalCost = (dd.totalCost ? dd.totalCost : 0) + h.cost;
					dd.y = Math.round(100 * dd.totalPrice / dd.totalCost);
				});
				focusPrefix = "ppr";
				Object.keys(datasets).forEach((key) => {
					if (key.split('-')[0] == 'ppr') {
						var dataset = datasets[key];
						var data2 = [];
						dataset.data.forEach((d) => {
							if (d.y > 0) {
								data2.push(d)
							}
						});
						dataset.data = data2;
					}
				});
			}
			var datas = [];
			Object.keys(datasets).forEach((key, index) => {

				const state = (localStorage['admin_stocklist_dataset_' + key] || defaults[key.split("-")[0]] || "true");
				console.log(key, state);
				datasets[key].hidden = state == "true";

				datasets[key].key = key;
				if (!datasets[key].hidden) {
					datas.push(datasets[key]);
					yaxes.push(datasets[key].yAxisID);
				} else {
					if (!focus || key.split('-')[0] == focusPrefix)
						datas.push(datasets[key]);
				}
			});

			datas.sort(function (a, b) {
				var aid = a.key;
				var bid = b.key;
				var aa = a.key.split('-');
				var bb = b.key.split('-');
				var aMenuItem = aa.length == 3 ? aa[1] : null;
				var bMenuItem = bb.length == 3 ? bb[1] : null;
				if (aMenuItem == null && bMenuItem == null)
					return aid - bid;
				if (aMenuItem == null)
					return -1;
				if (bMenuItem == null)
					return 1;
				var r = menuItemIds.indexOf(Number(aMenuItem)) - menuItemIds.indexOf(Number(bMenuItem));
				if (r == 0) {
					return aa[2] - bb[2];
				} else {
					return r;
				}
			})

			if (myChart)
				myChart.destroy();
			myChart = new Chart(ctx, {
				type: "bar",
				data: {
					datasets: datas,
				},
				options: {
					maintainAspectRatio: false,
					responsive: true,
					scales: {
						xAxes: [{
							type: "time",
							time: {
								//parser: timeFormat,
								tooltipFormat: range == 'minute' || range == 'hour' ? 'llll' : 'll',
								unit: $("[name='range']:checked").attr('id'),
								//min: Number(localStorage.admin_stocklist_graph_start),
								max: Number(localStorage.admin_stocklist_graph_end),
							},
							scaleLabel: {
								display: true,
								labelString: 'Date'
							},
							gridLines: {
								offsetGridLines: true,
							},
							stacked: focus || true,
							barPercentage: .9,
							categoryPercentage: 1,
							offset: true,
							ticks: {
								autoSkip: true,
							},
						}],
						yAxes: [{
							type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
							display: yaxes.indexOf('y-axis-1') >= 0 && !focus,
							position: 'left',
							id: 'y-axis-1',
							stacked: true,
							scaleLabel: {
								display: !focus,
								labelString: currentQuantityType
							},
							ticks: {
								beginAtZero: true
							}
						}, {
							type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
							display: yaxes.indexOf('y-axis-3') >= 0,
							position: 'left',
							id: 'y-axis-3',
							stacked: true,
							scaleLabel: {
								display: true,
								labelString: currentQuantityType
							},
							ticks: {
								beginAtZero: true
							}
						}, {
							//type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
							display: yaxes.indexOf('y-axis-2') >= 0,
							position: 'right',
							id: 'y-axis-2',
							scaleLabel: {
								display: true,
								labelString: auth.myStatus.restaurant_base_currency.name
							},
							gridLines: {
								drawOnChartArea: false
							}
						}, {
							//type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
							display: yaxes.indexOf('y-axis-4') >= 0,
							position: 'right',
							id: 'y-axis-4',
							stacked: true,
							scaleLabel: {
								display: true,
								labelString: auth.myStatus.restaurant_base_currency.name
							},
							gridLines: {
								drawOnChartArea: false
							}
						}, {
							//type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
							display: yaxes.indexOf('y-axis-5') >= 0,
							position: 'right',
							id: 'y-axis-5',
							stacked: false,
							scaleLabel: {
								display: true,
								labelString: "%"
							},
							gridLines: {
								drawOnChartArea: true
							}
						}, {
							type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
							display: yaxes.indexOf('y-axis-6') >= 0,
							position: 'left',
							id: 'y-axis-6',
							scaleLabel: {
								display: true,
								labelString: currentQuantityType
							},
							ticks: {
								beginAtZero: true
							}
						}],
					},
					tooltips: {
						callbacks: {
							label: function (tooltipItem, data) {
								var d = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].data;
								var dd = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
								var key = data.datasets[tooltipItem.datasetIndex].key.split('-')[0];
								var label;
								switch (key) {
									case 'p': // ár
									case 'pp': // célár
									case 'c': // költség
									case 'pc':
									case 'o':
									case 'oo':
									case 'opc':
										label = data.datasets[tooltipItem.datasetIndex].label + ":" + (Math.round(tooltipItem.value * 1000) / 1000) + " " + auth.myStatus.restaurant_base_currency.name;
										break;
									case 'k': // készlet
									case 'cc':
									case 'f': // forgalom
									case 't': // raktármozgás
									case 'b': // bevét
										label = data.datasets[tooltipItem.datasetIndex].label + ":" + (Math.round(tooltipItem.value * 1000) / 1000) + " " + currentQuantityType;
										break;

									case 'kk':
									case 'ff':
										label = data.datasets[tooltipItem.datasetIndex].label + ":" + (Math.round(tooltipItem.value * 1000) / 1000) + " " + currentQuantityType;
										break;

									case 'd': // készlet
										label = [data.datasets[tooltipItem.datasetIndex].label + ":" + (Math.round(tooltipItem.value * 1000) / 1000) + " " + currentQuantityType];
										dd.comment.forEach(c => { label.push(c) });
										break;
									case 'i': // leltár
										if (d) {
											var label = [data.datasets[tooltipItem.datasetIndex].label, admin_local.stock + ":" + d.count + " " + currentQuantityType];
											if (d.count - d.originalCount > 0)
												label.push(admin_local.inventory_surplus + ":" + Math.round(1000 * (d.count - d.originalCount)) / 1000 + " " + currentQuantityType);
											else if (d.count - d.originalCount < 0)
												label.push(admin_local.inventory_shortage + ":" + Math.round(1000 * (-d.count + d.originalCount)) / 1000 + " " + currentQuantityType);
										}
										break;
									case 'ppr':
									case 'pr':
										label = data.datasets[tooltipItem.datasetIndex].label + ":" + (Math.round(tooltipItem.value * 1000) / 1000) + " %";
										break;
								}
								return label;
							}
						}
					},
					legend: {
						labels: {
							filter: function (item, chart) {
								//if (focus && datasetsSelected==1 && item.hidden)
								//	return false;
								return true;
							}
						},
						onClick: function (e, legendItem) {
							var index = legendItem.datasetIndex;
							var key = datas[index].key.split('-')[0];
							var hidden = localStorage['admin_stocklist_dataset_' + datas[index].key] == "true";
							/*
							if (key=="k" && !focus) {
								['f','o','p','pr','oo','c'].forEach((i) => {
									datas.forEach((data) => {
										if (data.key.split('-')[0]==i)
											localStorage['admin_stocklist_dataset_'+data.key] = true;
									});
								})
								localStorage['admin_stocklist_dataset_'+datas[index].key] = !hidden;
								localStorage['admin_stocklist_dataset_focus']=true;
								drawChart();
								return;
							}*/
							if (datasetsSelected == 1 && !isCategory && (key == "f" || key == "o" || key == "pr" || key == "oo") && !focus && !hidden) {
								localStorage['admin_stocklist_dataset_focus'] = true;
								drawChart();
								return;
							} else if (isCategory == true && datasetsSelected == 1 && (key == "k" || key == "f" || key == "o" || key == "p") && !focus && !hidden) {
								localStorage['admin_stocklist_dataset_focus'] = true;
								drawChart();
								return;
							}
							if (e.ctrlKey == true) {
								if (key != focusPrefix) {
									//unhide all
									datas.forEach((data) => {
										if (data.key.split('-')[0] == focusPrefix)
											localStorage['admin_stocklist_dataset_' + data.key] = false;
									});
								} else {
									//hide all but the one selected
									datas.forEach((data) => {
										if (data.key.split('-')[0] == focusPrefix)
											localStorage['admin_stocklist_dataset_' + data.key] = true;
									});
									localStorage['admin_stocklist_dataset_' + datas[index].key] = false;
								}
							} else if (key != focusPrefix && focus) {
								localStorage['admin_stocklist_dataset_' + datas[index].key] = true;
								localStorage['admin_stocklist_dataset_focus'] = false;
							} else {
								localStorage['admin_stocklist_dataset_' + datas[index].key] = !hidden;
							}
							drawChart();
						},
						onDblClick: function (e, legendItem) {
							console.log('dblclick')
						}
					}
				}
			});
		} finally {
			ajaxCallEnd("drawchart");
		}
	}, 10);

}

function getStore(id) {
	var r = menuitemutil.stores.find(store => store.id === Number(id));
	if (r && !r.color) {
		r.color = "#000000";
	}
	return r;
}

function getMonday(date) {
	var day = date.getDay() || 7;
	if (day !== 1)
		date.setHours(-24 * (day - 1));
	return date;
}

export const toggleChart = () => {
	var active = $('#graph').hasClass('btn-positive');
	if (active) {
		$('#chart-block').addClass('hidden');
		$('#graph').addClass('btn-info');
		$('#graph').removeClass('btn-positive');
	} else {
		$('#chart-block').removeClass('hidden');
		$('#graph').addClass('btn-positive');
		$('#graph').removeClass('btn-info');
		populateCharts();
	}
}


function random_bg_color() {
	var x = Math.floor(Math.random() * 256);
	var y = Math.floor(Math.random() * 256);
	var z = Math.floor(Math.random() * 256);
	return "rgb(" + x + "," + y + "," + z + ")";
}

var quantityCache = {};
export const getInventoryQuantity = function (menuItem, quantity) {
	var ret = undefined;//quantityCache[menuItem.id + "-" + quantity];
	var onlyInventoryQuantity = true;
	if (ret)
		return ret;
	menuItem.availableQuantities && menuItem.availableQuantities.forEach((q) => {
		onlyInventoryQuantity = onlyInventoryQuantity && q.inventoryItem;
		if (q.inventoryItem && Number(q.quantity) === Math.abs(quantity))
			ret = quantity;
	});
	/*
if (!onlyInventoryQuantity) {
	quantityCache[menuItem.id + "-" + quantity] = 1;
	return 1;
}*/
	if (ret) {
		quantityCache[menuItem.id + "-" + quantity] = ret;
		return ret;
	}
	if (menuItem.availableQuantities)
		for (let i = menuItem.availableQuantities.length; i > 0; i--) {
			var q = menuItem.availableQuantities[i - 1];
			if (q.inventoryItem)
				ret = q.quantity;
		}
	if (!ret)
		ret = "";
	quantityCache[menuItem.id + "-" + quantity] = ret;
	return ret;
}
menuitemutil.getInventoryQuantity = getInventoryQuantity;

