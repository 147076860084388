import moment from 'moment';
import React, { Component } from 'react';
import { TimePickerInput } from 'react-datetime-range-super-picker';
import { I18n } from 'react-redux-i18n';
import auth, { del, get, put, post, ajaxCallEnd, ajaxCallStart } from '../js/auth';
import "../css/openingDay.css";
import { Button } from '@material-ui/core';
import { Add, ArrowBack, ArrowForward, Delete, } from '@material-ui/icons';
import { IconButton } from 'material-ui';


class OpeningDay extends Component {
    state = {
        openingHours: [{
            start: moment(),
            end: moment()
        }]
    }

    componentDidMount = () => {
        if (this.props.myStatus.restaurant_id) {
            ajaxCallStart("findByRestaurantAndDate");
            get("api/businessDays/search/findByRestaurantAndDate?restaurant=" + this.props.myStatus.restaurant_id + "&date=" + moment().format("YYYY-MM-DD HH:mm:ss ZZ").replace("+", "%2B")).done(d => {
                ajaxCallEnd("findByRestaurantAndDate");
                if (d._embedded.businessDays.length > 0) {
                    this.setState({
                        openingHours: d._embedded.businessDays.map(d => {
                            return {
                                id: d.id,
                                restaurant: auth.server + "/eatwithme.server/api/restaurants/" + this.props.myStatus.restaurant_id,
                                date: d.date,
                                start: moment(d.start),
                                end: moment(d.end)
                            }
                        })
                    })
                } else {
                    ajaxCallStart("findByRestaurantAndDate");
                    get("api/businessDays/search/findByRestaurantAndDate?restaurant=" + this.props.myStatus.restaurant_id + "&date=" + moment().startOf("day").add(-7, 'day').format("YYYY-MM-DD HH:mm:ss ZZ").replace("+", "%2B")).done(d => {
                        ajaxCallEnd("findByRestaurantAndDate");
                        if (d._embedded.businessDays.filter(b => b.start).length > 0) {
                            this.setState({
                                openingHours: d._embedded.businessDays.map(d => {
                                    return {
                                        restaurant: auth.server + "/eatwithme.server/api/restaurants/" + this.props.myStatus.restaurant_id,
                                        date: moment().startOf('day'),
                                        start: moment().startOf('day').add('hour', moment(d.start).hour()).add('minute', moment(d.start).minute()),
                                        end: moment().startOf('day').add('hour', moment(d.end).hour()).add('minute', moment(d.end).minute())
                                    }
                                })
                            })
                        } else {
                            ajaxCallStart("findByRestaurantAndDate");
                            get("api/businessDays/search/findByRestaurantAndDate?restaurant=" + this.props.myStatus.restaurant_id + "&date=" + moment().startOf("day").add(-14, 'day').format("YYYY-MM-DD HH:mm:ss ZZ").replace("+", "%2B")).done(d => {
                                ajaxCallEnd("findByRestaurantAndDate");
                                if (d._embedded.businessDays.filter(b => b.start).length > 0) {
                                    this.setState({
                                        openingHours: d._embedded.businessDays.map(d => {
                                            return {
                                                restaurant: auth.server + "/eatwithme.server/api/restaurants/" + this.props.myStatus.restaurant_id,
                                                date: moment().startOf('day'),
                                                start: moment().startOf('day').add('hour', moment(d.start).hour()).add('minute', moment(d.start).minute()),
                                                end: moment().startOf('day').add('hour', moment(d.end).hour()).add('minute', moment(d.end).minute())
                                            }
                                        })
                                    })
                                } else {
                                    this.setState({
                                        openingHours: [{
                                            restaurant: auth.server + "/eatwithme.server/api/restaurants/" + this.props.myStatus.restaurant_id,
                                            date: moment().startOf('day'),
                                            start: moment().startOf('day').add('hour', this.props.myStatus.restaurant_settings["business-day-starts-at"]),
                                            end: moment().startOf('day').add('hour', this.props.myStatus.restaurant_settings["business-day-ends-at"])
                                        }]
                                    })
                                }
                            });
                        }
                    });
                }
            })
        }
    }

    render() {
        return (
            <div className="openingDay" style={{ width: "100%", flexGrow: "1", overflow: "auto" }} >
                <div style={{ width: "100%", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }} >
                    {this.state.openingHours.map((d, ind) => {
                        return d.deleted ? null :
                            <div key={ind} style={{ width: "40rem", display: "flex", flexFlow: "row", justifyContent: "center", alignItems: "center", borderBottom: "1px solid lightgray" }}>
                                <div key="t" style={{ width: "33rem", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", margin: ".5rem" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <label style={{ textAlign: "center", width: "20rem" }}>{I18n.t("admin_local.opening_time")}</label>
                                        <TimePickerInput className="timePicker" format={"HH:mm"} weekStartsOn={1} time={d.start.format("HH:mm")} onTimeUpdate={({ time }) => this.handleToDateUpdateStart(time, ind)} />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <label style={{ textAlign: "center", width: "20rem" }}>{I18n.t("admin_local.planned_closing_time")}</label>
                                        <TimePickerInput className="timePicker" format={"HH:mm"} weekStartsOn={1} time={d.end.format("HH:mm")} onTimeUpdate={({ time }) => this.handleToDateUpdateEnd(time, ind)} />
                                    </div>
                                </div>
                                <div key="b" style={{ width: "4rem" }}>
                                    {ind ?
                                        <IconButton className="icon-ok" color="positive" variant="contained" size="large" onClick={() => this.deleteTime(ind)} >
                                            <Delete fontSize="large" />
                                        </IconButton>
                                        : null}
                                </div>
                            </div>
                    })}
                    < div key="plus" style={{ fontSize: "2rem", gap: "1rem", height: "8rem", display: "flex", flexFlow: "row", justifyContent: "space-evenly", alignItems: "center", margin: ".5rem", flexWrap: "wrap" }}>
                        <Button style={{ height: "3rem", width: "3rem" }} color="primary" variant="contained" size="large" onClick={this.addTime} startIcon={<Add fontSize="large" />}>

                        </Button>
                        <Button color="secondary" variant="contained" size="large" onClick={this.continue} endIcon={<ArrowForward variant="contained" fontSize="large" />} >
                            {I18n.t("admin_local.continue_without_open_day")}
                        </Button>
                        <Button color="default" variant="contained" size="large" onClick={this.save} endIcon={<ArrowForward fontSize="large" />}>{I18n.t("admin_local.open_day")}
                        </Button>
                    </div>
                </div>
            </div >
        );
    }

    continue = () => {
        this.props.onContinue(false);
    }

    save = () => {

        const createNapiZaras = async (day) => {
            await post("ntak/napiZaras/napiZaras", {
                businessDay: {
                    id: day._links.self.href.split("/").pop()
                }
            })
        }

        const createDay = async (openingHours) => {
            if (openingHours.length === 0) {
                this.props.onContinue(true);
                return;
            }
            const d = openingHours.pop();
            if (d.end.isBefore(d.start))
                d.end.add('day', 1);
            var day;
            if (d.deleted && d.id) {
                day = await del("api/businessDays/" + d.id);
            } else if (d.id) {
                day = await put("api/businessDays/" + d.id, d);
            } else {
                day = await post("ntak/napiZaras/businessDay", { ...d, restaurant: { id: d.restaurant.split("/").pop() } });
                //day.restaurant = "api/businessDays/" + day.restaurant.id;
                day._links = { self: { href: "api/businessDays/" + day.id } }
            }
            await createNapiZaras(day);
            createDay(openingHours);
        }
        createDay(this.state.openingHours);
    }

    deleteTime = ind => {
        const openingHours = [...this.state.openingHours];
        if (openingHours[ind].id)
            openingHours[ind].deleted = true;
        else
            openingHours.splice(ind, 1);
        this.setState({ openingHours })
    }

    addTime = () => {
        const openingHours = [...this.state.openingHours];
        openingHours.push({
            date: moment().startOf('day'),
            start: moment(),
            end: moment().startOf('day').add('hour', this.props.myStatus.restaurant_settings["business-day-ends-at"])
        });
        this.setState({ openingHours })
    }

    handleToDateUpdateStart = (time, ind) => {
        var t = moment().startOf('day').add(time.hour24, "hour").add(time.minute, "minute");
        const openingHours = [...this.state.openingHours];
        openingHours[ind].start = t;
        this.setState({ openingHours })
    }

    handleToDateUpdateEnd = (time, ind) => {
        var t = moment().startOf('day').add(time.hour24, "hour").add(time.minute, "minute");
        const openingHours = [...this.state.openingHours];
        openingHours[ind].end = t;
        this.setState({ openingHours })
    }
}


export default OpeningDay;