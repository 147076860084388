const admin_local_en = {
  "confirm_customer_deletion": "Sei sicuro di voler eliminare l'utente <b>{0}<b>?",
  "confirm_role_deletion": "Sei sicuro di voler revocare l'accesso <b>{1}</b> a <b>{2}</b> da <b>{0}</b>?",
  "do_you_really_want_to_delete_the_menu_category": "Sei sicuro di voler eliminare la categoria del menu?",
  "do_you_really_want_to_delete_the_menu_item": "Sei sicuro di voler eliminare l'elemento del menu?",
  "failed_to_delete_user": "Eliminazione fallita. <br>Errore: {0}",
  "failed_to_delete_user_anonimize": "L'utente non può essere eliminato! Vuoi anonimizzare invece? Nome, email, password saranno eliminati!",
  "are_you_sure_you_want_to_delete_the_table": "Sei sicuro di voler eliminare il <b>tavolo {0}</b>?",
  "are_you_sure_you_want_to_delete_the_room": "Sei sicuro di voler eliminare la <b>stanza {0}</b>?",
  "failed_to_delete_table_in_use": "Il tavolo non può essere eliminato! È già stato utilizzato! Disattivalo invece!",
  "failed_to_create_table_not_unique": "Impossibile creare il tavolo. Il numero del tavolo è già in uso!",
  "failed_to_delete_drink_category_in_use": "Impossibile eliminare la categoria delle bevande! È già in uso. Disattivala invece!",
  "failed_to_delete_drink_in_use": "Impossibile eliminare la bevanda! È già in uso. Disattivala invece!",
  "failed_to_delete_meal_category_in_use": "Impossibile eliminare la categoria dei pasti! È già in uso. Disattivala invece!",
  "failed_to_delete_meal_in_use": "Impossibile eliminare il pasto! È già in uso. Disattivalo invece!",
  "failed_to_delete_discount_type_in_use": "Impossibile eliminare il tipo di sconto! È già in uso. Disattivalo invece!",
  "failed_to_delete_label_in_use": "Impossibile eliminare l'etichetta! È già in uso.",

  "table_number_label": "Numero del tavolo",
  "number_of_seats": "Posti disponibili",
  "shareable": "Condivisibile",
  "free_seats": "Posti liberi",
  "active": "Attivo",
  "_active": "Att.",
  "table_editor": "Editor tavolo",

  "add": "Aggiungi",
  "edit": "Modifica",
  "delete": "Elimina",
  "activate": "Attiva",
  "deactivate": "Disattiva",
  "make_shareable": "Rendi il tavolo condivisibile",
  "make_unshareable": "Rendi il tavolo non condivisibile",
  "make_pos": "È un punto vendita",
  "make_non_pos": "Non è un punto vendita",
  "lock": "Occupato",
  "new_lock": "Occupazione aggiuntiva",
  "unlock": "Libera",

  "tables": "Tavoli",
  "enter_table_number": "Inserisci il numero del tavolo",
  "enter_table_seats": "Inserisci i posti disponibili",
  "table_is_shareable": "Il tavolo è condivisibile",
  "table_is_active": "Il tavolo è attivo",
  "table_free_seats": "Posti liberi disponibili",

  "unit_price": "Prezzo unitario",
  "_unit_price": "Prezzo",

  "drinks": "Bevande",
  "drink_name": "Nome",
  "drink_quantity_type": "Tipo di quantità",
  "drink_available_quantity": "Quantità disponibili",
  "drink_quantity_name": "Nome della quantità",
  "drink_quantity": "Quantità",
  "drink_price": "Prezzo",
  "drink_editor": "Editor bevanda",
  "drink_category_name": "Categoria",
  "drink_category_editor": "Editor categoria bevande",
  "add_drink_category": "Nuova categoria",
  "add_drink": "Nuova bevanda",
  "add_category": "Nuova categoria",
  "_add_category": "Nuova cat.",
  "add_item": "Nuovo elemento",
  "_add_item": "Nuovo elemento",

  "meals": "Pasti",
  "meal_name": "Nome",
  "meal_quantity_type": "Tipo di quantità",
  "meal_available_quantity": "Quantità disponibili",
  "meal_quantity_name": "Nome della quantità",
  "meal_quantity": "Quantità",
  "meal_price": "Prezzo",
  "meal_editor": "Editor pasto",
  "meal_category_name": "Categoria",
  "meal_category_editor": "Editor categoria pasti",
  "add_meal_category": "Nuova categoria",
  "add_meal": "Nuovo pasto",

  "available_quantity": "Quantità disponibili",
  "_available_quantity": "Quantità",

  "description": "Descrizione",
  "enter_description": "Inserisci una descrizione se necessario",

  "language": "Lingua",
  "default_language": "Predefinita",
  "not_defined": "???",
  "close": "Chiudi",
  "close_order": "Chiudi",
  "save_and_new": "Salva e duplica",
  "pay": "Paga",
  "save_and_unlock": "Salva e chiudi",

  "rejection_reason": "Motivo del rifiuto",
  "typical_rejection_reasons": "Motivi tipici",
  "custom_reason": "Motivo personalizzato",

  "table_number": "Numero tavolo: {0}",
  "team_number": "Squadra: {0}",
  "organizer": "Organizzatore: {0}",
  "order_number": "Numero ordine: {0}",
  "oldest_order": "Ordine più vecchio: {0}",
  "table_occupation_number": "Occupazione n° {0}",

  "orders_tab": "Elenco ordini",
  "by_person_tab": "Per persona",
  "by_timing_tab": "Per portata",
  "by_drink_or_meal_tab": "Per bevanda o pasto",

  "inherit": "Eredita",
  "meal_timing": "Tempistica",
  "timing_inherit": "Eredita",
  "timing_asap": "Il prima possibile",
  "timing_appetizer": "Antipasto",
  "timing_maincourse": "Piatto principale",
  "timing_desert": "Dolce",
  "timing_last": "Alla fine",
  "timing_null": "",
  "timing": "Tempo",

  "production_lines": "Linee di produzione",
  "production_line_editor": "Editor linea di produzione",

  "production_line": "Linea di produzione",
  "production_line_waiting": "Linea di produzione dipendente",
  "production_line_length": "Coda",
  "production_line_title": "Linea di produzione {0}",
  "all_production_lines": "Tutte le linee di produzione",
  "default_production_line": "Linea di produzione predefinita",
  "_default_production_line": "Linea prod.",
  "production_line_name": "Nome",
  "enter_production_line_name": "Inserisci il nome per la linea di produzione",
  "production_line_is_active": "Linea di produzione attiva",
  "failed_to_delete_production_line_in_use": "Impossibile eliminare la linea di produzione! È già in uso. Disattivala invece!",
  "failed_to_create_production_line_not_unique": "Impossibile creare la linea di produzione! Non unica!",

  "vat_categories": "Categorie IVA",
  "vat_category_name": "Nome categoria IVA",
  "vat_category": "Categoria IVA",
  "_vat_category": "IVA",
  "vat_category_editor": "Editor categoria IVA",
  "enter_vat_category_name": "Inserisci il nome della categoria IVA",
  "percent_name": "Percentuale",
  "enter_percent": "Inserisci il valore IVA in percentuale (es. 27)",
  "default_vat_category": "IVA",
  "_default_vat_category": "IVA",
  "default_takeaway_vat_category": "IVA da asporto",
  "_default_takeaway_vat_category": "Iva asporto",

  "occupy": "Occupare",
  "number_of_persons": "Numero di persone",
  "unoccupy": "Liberare",
  "new_order": "Nuovo ordine",
  "submit_new_orders": "Invia nuovi ordini",
  "submit_and_accept_new_orders": "Invia ed accetta nuovi ordini",
  "submit_and_accept_and_print_new_orders": "Stampa nuovi ordini",
  "submit_and_accept_and_print_and_pay_new_orders": "Pagamento",
  "paying": "Pagamento",
  "cancel_paying": "Annulla pagamento",
  "ordering_allowed": "Fattura annullata, è possibile ordinare nuovamente!",
  "paid": "Pagato",

  "please_choose_mate_wanting_to_pay": "Chi vuole pagare?",
  "please_choose_mate_who_paid": "Chi ha pagato?",
  "print_invoice": "Stampa fattura",

  "roles": "Ruoli",
  "currencies": "Valute",
  "payment_methods": "Metodi di pagamento",

  "role": "Ruolo",
  "payment_method": "Metodo di pagamento",
  "user": "Utente",
  "users": "Utenti",
  "entity_type": "Tipo di entità",
  "entity": "Entità",
  "entities": "Entità",

  "restaurant_editor": "Ristorante",
  "name": "Nome",
  "_name": "Nome",
  "languages": "Lingue",
  "town": "Città",
  "longitude": "Longitudine",
  "latitude": "Latitudine",

  "exchange_rate": "Tasso di cambio",

  "please_select_a_restaurant": "Seleziona un ristorante",

  "invoice_heading": "Intestazione fattura",
  "enter_invoice_heading": "Fornisci l'intestazione delle fatture in formato HTML!",
  "settings": "Impostazioni",

  "not_allowed_order_paid": "Azione non consentita con un ordine pagato!",
  "not_allowed_order_is_being_paid": "Azione non consentita con un ordine in fase di pagamento!",

  "more_than_a_day": "> 1 giorno",

  "active_average_acceptance_duration": "Tempo medio di accettazione dell'ordine",
  "active_average_delivering_duration": "Tempo medio di consegna dell'ordine",
  "active_max_acceptance_duration": "Tempo massimo di accettazione dell'ordine",
  "active_max_delivering_duration": "Tempo massimo di consegna dell'ordine",
  "active_min_acceptance_duration": "Tempo minimo di accettazione dell'ordine",
  "active_min_delivering_duration": "Tempo minimo di consegna dell'ordine",

  "mandatory": "Obbligatorio",
  "included": "Rimovibile",
  "optional": "Opzioni",
  "references": "Riferimenti",
  "isTop": "Nel menu",
  "_isTop": "M.",

  "failed_to_get_restaurant_layout": "Nessun layout del ristorante definito! Carica prima uno!",

  "qr_code": "Codice QR",
  "person": "Persona",

  "printers": "Stampanti",
  "printer": "Stampante",
  "connected_from": "Connesso",

  "collaborating_production_lines": "Collaborazione",
  "short_name": "Nome breve",
  "do_you_really_want_to_print_the_orders_until_timing": "Vuoi davvero stampare gli ordini solo fino al corso <b>{0}</b>?",
  "do_you_really_want_to_print_only_the_selected_orders": "Vuoi davvero stampare solo gli ordini selezionati?",
  "do_you_really_want_to_reprint_the_orders_until_timing": "Vuoi davvero <b><u>ristampare</u></b> gli ordini fino al corso <b>{0}</b>?",
  "do_you_really_want_to_reprint_only_the_selected_orders": "Vuoi davvero <b><u>ristampare</u></b> solo gli ordini selezionati?",
  "enable_edit_mode": "Cambia il layout dei tavoli",
  "disable_edit_mode": "Ripristina il layout dei tavoli",
  "change_table_number": "Cambia numero del tavolo",
  "save_layout": "Salva il layout del tavolo",
  "layout_succesfully_saved": "Layout del tavolo salvato!",
  "table": "Tavolo",
  "seat": "Posto",
  "rotate_right": "Ruota a destra (45°)",
  "rotate_left": "Ruota a sinistra (45°)",
  "duplicate": "Duplica",
  "enter_barion_pos_key": "Fornisci la tua chiave segreta Barion (POSKey)",

  "printer_server_name": "Nome del server di stampa",
  "printInvoice": "Stampa fattura",
  "printTableSlip": "Stampa il codice QR del tavolo",
  "printVATInvoice": "Stampa la fattura IVA",
  "printer_actions": "Azioni di stampa",
  "format": "Formato",

  "quantity_types": "Tipi di servizio",

  "enter_category": "Inserisci il nome della categoria",
  "enter_Drink_menuitem": "Inserisci il nome del pasto",
  "enter_Meal_menuitem": "Inserisci il nome della bevanda",

  "scan_to_pay_table": "Scansiona con il tuo telefono\r\n per visualizzare il consumo al tavolo n. {0}.\r\ne per saldare il conto online!",
  "scan_to_join_table": "!LF!ESC|cA!ESC|4CTavolo {0}!LF!LF!ESC|4C!ESC|cAScansiona e ordina!LF!ESC|cAe gestisci il tuo consumo online!",

  "print_receipt": "Stampa ricevuta",
  "choose_customer_for": "Scegli il cliente per la persona {0}.",
  "search_pattern": "Modello di ricerca",
  "clear": "Cancella",

  "discount_types": "Tipi di sconto",
  "discount": "Sconto",
  "scope": "Ambito",
  "when": "Quando",
  "how_much": "Quanto",
  "cumulative": "Cumulativo",
  "categories_or_menuitems": "Elementi del menu o categorie",
  "labels": "Etichette",
  "fromDate": "Da data",
  "toDate": "A data",
  "fromTime": "Da ora",
  "toTime": "A ora",
  "datetime": "Data e ora",
  "discount_percent": "Percentuale",
  "discount_amount": "Importo fisso",
  "on_day": "Giorno della settimana",
  "day1": "Domenica",
  "day2": "Lunedì",
  "day3": "Martedì",
  "day4": "Mercoledì",
  "day5": "Giovedì",
  "day6": "Venerdì",
  "day7": "Sabato",
  "per_item": "Per elemento",
  "label": "Etichetta",
  "are_you_sure_you_want_to_delete_the_production_line": "Sei sicuro di voler eliminare l'etichetta <b>{0}</b>",
  "enter_labels": "Scegli le tue etichette",
  "discounts": "Sconti",
  "apply_type_discount": "Applica lo sconto",
  "manual": "Manuale",
  "automatic": "Automatico",
  "to_whom": "A chi",
  "store": "Negozio",
  "stores": "Negozi",
  "input_stores": "Negozi di input",
  "output_stores": "Negozi di output",
  "cost": "Costo",
  "unit_price_estimation": "Prezzo unitario proposto",
  "cost_estimation": "Stima dei costi",
  "suppliers": "Fornitori",
  "supplier": "Fornitore",
  "incomingStock": "Stoccaggio in ingresso",
  "incomingStocks": "Stoccaggi in ingresso",
  "date": "Data",
  "default_store": "Negozio predefinito",
  "invoice_id": "Numero fattura",
  "delivery_note_id": "ID nota di consegna",
  "supplier_code": "Codice fornitore",
  "product": "Prodotto",
  "quantity": "Quantità",
  "_quantity": "Quantità",
  "quantity_type": "Tipo di quantità",
  "expiry_date": "Data di scadenza",
  "net_unit_price": "Prezzo unitario netto",
  "brutto_unit_price": "Prezzo unitario lordo",
  "net_price": "Prezzo netto",
  "brutto_price": "Prezzo lordo",
  "_net_unit_price": "N. u. prezzo",
  "_brutto_unit_price": "B. u. prezzo",
  "_net_price": "Prezzo netto",
  "_brutto_price": "Prezzo lordo",
  "total_incoming_stock_entries": "<span>{0}</span> <span class='browser-only'> voci in totale</span>",
  "entry_count": "Conteggio voci",
  "_entry_count": "E.",
  "incoming_stock_entry_text_input": "Input testo",
  "are_you_sure_you_want_to_delete_the_incoming_stock": "Sei sicuro di voler eliminare la voce di stoccaggio in ingresso selezionata?",
  "are_you_sure_you_want_to_delete_the_inventory": "Sei sicuro di voler eliminare la voce di inventario selezionata?",
  "delete_all_order_history": "Elimina tutto la cronologia degli ordini",

  "tableOccupations": "Occupazioni tavolo",
  "traffic": "Traffico",
  "menu": "Menu",
  "time": "Tempo",
  "seqnr": "Nr.",
  "duration": "Durata",
  "person_count": "Numero di persone",
  "amount_paid": "Pagato",
  "tip": "Mancia",
  "cancelled_orders": "Annullati",
  "ordered_by": "Ordinato da",
  "orders": "Ordini",
  "drink": "Bevanda",
  "meal": "Pasto",
  "last_status": "Ultimo stato",
  "deleted": "Eliminato",
  "yes": "Sì",
  "no": "No",
  "order_sequence_number": "Numero ordine",
  "is_paid": "Pagato",
  "paid_by": "Pagato da",
  "price": "Prezzo",
  "amount_to_pay": "Da pagare",
  "payments": "Pagamenti",
  "currency": "Valuta",
  "amount": "Importo",
  "replace": "Sostituisci",
  "menuitem_ordered": "Cosa",
  "daily_closing": "Chiusura giornaliera",
  "table_occupations": "Tavoli",
  "count": "Conteggio",
  "test_system": "Sistema di test",
  "test_mode": "Modalità di test",
  "multiple_physical_printers_try_to_server_the_virtual_server": "Sono configurate più stampanti fisiche per la stampante virtuale {0}! '{1}' e '{2}'!",
  "The device communications channel could not be opened, check the device and retry.": "La stampante sembra essere spenta o configurata male.",
  "pay_himself": "Paga da solo",
  "unknown_person_pays": "La persona {0}. paga",
  "change_table": "Cambia tavolo",

  "confirm_person_change_to_empty_table": "La persona {0}. si sposta al tavolo vuoto n. {1}? <br/>Come nuova occupazione o come parte di quella corrente?",
  "confirm_person_change_to_table": "La persona {0}. si unisce al tavolo già occupato n. {1}?",
  "confirm_person_change_to_shared_table": "La persona {0}. si sposta al tavolo già occupato n. {1}?<br/>Come nuova occupazione o si unisce a quella esistente?",
  "confirm_person_change_to_multiple_occupation_table": "La persona {0}. si sposta al tavolo già occupato n. {1}?",

  "new_occupation": "Come nuova occupazione",
  "extend_occupation": "Estendi l'occupazione corrente",
  "joins": "Si unisce",
  "joins_table1": "Si unisce alla prima occupazione con {0} persone",
  "joins_table": "Si unisce alla {0}ª occupazione con {1} persone",
  "new_independent_occupation": "Nuova occupazione",
  "target_table_same_and_source": "Il tavolo di destinazione è lo stesso della sorgente!",
  "sitting_at_table": "Seduto al tavolo n. {0}",
  "confirm_additional_table": "Aggiungi il tavolo n. {0} all'occupazione corrente?",
  "confirm_change_to_table": "Tutti si spostano al tavolo n. {0}?",
  "confirm_table_change_to_shared_table": "Il tavolo di destinazione è un tavolo condivisibile. Scegli tra le opzioni disponibili.",
  "default": "Predefinito",
  "role_names": {
    "admin": "Amministratore",
    "waiter": "Cameriere",
    "cook": "Cuoco",
    "store": "Responsabile inventario",
    "printerserver": "Server stampanti",
    "finance": "Finanza",
    "courier": "Corriere"
  },
  "restaurant": "Ristorante",
  "pending": "In sospeso",
  "suspend_ordering": "Sospendi l'ordinazione",
  "continue_ordering": "Continua l'ordinazione",
  "split_invoice": "Dividi la fattura",
  "_split_invoice": "Dividi",
  "pays": "Paga",
  "comments": "Commenti",
  "invoice_printed": "Fattura inviata con successo alla stampante",
  "room": "Stanza",
  "move_forward": "Sposta avanti",
  "move_backward": "Sposta indietro",
  "move_top": "Sposta in alto",
  "move_back": "Sposta indietro",
  "all": "Tutto",
  "isInventoryItem": "Oggetto d'inventario",
  "isInventoryItem2": "Oggetto d'inventario/Materia prima",
  "isInventoryItem3": "Oggetto d'inventario",
  "_isInventoryItem": "I.",
  "total": "Totale",
  "menuitems": "Elementi",
  "menuitem": "Elemento",
  "item": "Elemento",
  "_item": "Elemento",
  "category": "Categoria",
  "_category": "Cat.",
  "you_need_admin_right_for_this_action": "Hai bisogno di privilegi amministrativi per eseguire questa azione!",
  "pricePerCostRatio": "Rapporto prezzo/unità di costo",
  "price_estimation": "Prezzo proposto",
  "add_option": "Nuova opzione",
  "option": "Opzione",
  "_option": "Opt.",
  "enter_cost": "Imposta il costo",
  "enter_price": "Imposta il prezzo: ad esempio 500 o 40%",

  "checkbox_option": "È possibile selezionare più opzioni dalla categoria durante l'ordinazione",
  "checkbox_option2": "Mantieni come categoria di opzione separata. È possibile selezionare più opzioni dalla categoria durante l'ordinazione",
  "optional_radio_option": "È possibile selezionare al massimo una opzione dalla categoria durante l'ordinazione",
  "mandatory_radio_option": "Dovrà essere selezionata un'opzione dalla categoria durante l'ordinazione",

  "deletion_not_allowed": "Eliminazione non consentita",
  "paid_order_cannot_be_deleted": "Gli ordini pagati non possono essere eliminati!",
  "order_cannot_be_deleted_while_mate_is_paying": "Gli ordini di una persona in fase di pagamento non possono essere eliminati!<br/>Rimuovere prima lo stato di pagamento della persona!",
  "order_cannot_be_deleted_while_order_was_already_selected_for_payment_one": "Gli ordini selezionati una volta per il pagamento possono essere eliminati solo con i diritti dell'amministratore!",
  "original_quantity": "Quantità originale",
  "recalculateConsumption": "Ricalcola il consumo",
  "recalculateConsumptionAll": "Ricalcola tutto il consumo",
  "_recalculateConsumption": "Ricalc. consumo",
  "inventory": "Inventario",
  "incoming_stock": "Entrate",
  "stock": "Scorte",
  "inventory_surplus": "Surplus",
  "inventory_shortage": "Mancanza",
  "minute": "   ",
  "hour": "Ora",
  "day": "Giorno",
  "week": "Settimana",
  "month": "Mese",
  "consumption": "Volume venduto",
  "price_goal": "Prezzo obiettivo",
  "today": "Oggi",
  "yesterday": "Ieri",
  "last_7_days": "Ultimi 7 giorni",
  "last_30_days": "Ultimi 30 giorni",
  "last_3_months": "Ultimi 3 mesi",
  "this_week": "Questa settimana",
  "this_month": "Questo mese",
  "this_quarter": "Questo trimestre",
  "last_week": "Settimana scorsa",
  "last_month": "Mese scorso",
  "last_quarter": "Trimestre scorso",
  "customRange": "Intervallo personalizzato",
  "failed_to_execute_action": "Azione non riuscita!",
  "no_inventory_quantity": "Nessuna quantità è stata selezionata per la gestione dell'inventario!",
  "income": "Entrate",
  "profit_margin": "Margine di profitto",
  "_profit_margin": "Margine",
  "there_is_a_loop_in_the_additions": "C'è un ciclo di riferimento tra gli elementi del menu!",

  "are_you_sure_you_want_to_delete_the_floorplan": "Sei sicuro di voler eliminare il piano <b>{0}</b>?",
  "floorplans": "Piani",
  "tooltip_printed": "Stampato",
  "tooltip_printfailed": "Errore di stampa!",
  "tooltip_partprinted": "Errore di stampa! Nuovo tentativo di stampa in corso!",
  "tooltip_printing": "Stampa in corso!",
  "tooltip_notprinted": "Non ancora stampato!",
  "controlled_production_lines": "Cambio di stato in blocco",
  "producing_rules": "Stato dopo la stampa",
  "pos_producing_rules": "Stato dopo la stampa per il punto vendita",
  "manualOnly": "Manuale",
  "afterPrint": "Dopo la stampa",
  "install_printer_server": "Installazione del server di stampa",
  "failed_to_print_kitchen_slip": "Impossibile stampare lo scontrino della cucina!",
  "failed_to_print_invoice": "Impossibile stampare la fattura!",
  "ok": "Ok",
  "secret_key": "Chiave segreta",
  "global_id": "ID globale",
  "server_url": "URL WAN",
  "local_url": "URL LAN",
  "backup_url": "URL di backup",
  "enabled-features": "Funzionalità abilitate",
  "online": "Online",
  "_online": "O.",
  "visible_online": "Visibile agli ospiti",
  "book_online": "Prenotabile dagli ospiti",
  "make_nonVisibleOnline": "Nascondi il tavolo agli ospiti",
  "make_visibleOnline": "Rendi il tavolo visibile agli ospiti",
  "make_nonBookOnline": "Disabilita l'occupazione da parte degli ospiti",
  "make_bookOnline": "Abilita l'occupazione da parte degli ospiti",
  "mate_already_joined_table_in_this_restaurant": "{0} è già al tavolo n. {1}!",
  "confirm_not_printing": "Vuoi davvero saltare la stampa degli ordini?",
  "save": "Salva",
  "save_and_close": "Salva e Chiudi",
  "save_and_open": "Salva e Testa",
  "table_types": {
    "rectangle_two_seats": "Tavolo per due posti",
    "rectangle_four_seats": "Tavolo per quattro posti",
    "rectangle_six_seats": "Tavolo per sei posti",
    "circle_four_seats": "Tavolo rotondo per quattro posti",
    "rounded_four_seats": "Tavolo arrotondato per quattro posti",
    "rounded_six_seats": "Tavolo arrotondato per sei posti",
    "person": "Persona",
    "persons": "Persone",
    "pool_table": "Tavolo da biliardo"
  },
  "seat_types": {
    "normal_chair": "Sedia normale",
    "wooden_chair": "Sedia in legno"
  },
  "tip_amount_seems_excesive": "L'importo sembra eccessivo! Continuare?",
  "non_printer_configured": "Nessuna stampante configurata",
  "disposed_quantity": "Quantità smaltita",
  "comment": "Commento",
  "entries": "Voci",
  "entry": "Voce",
  "other": "Altro",
  "created_by": "Creato da",
  "modified_by": "Modificato da",
  "start_count": "Previsto",
  "change": "Modifica",
  "changeit": "Modifica",
  "end_count": "Effettivo",
  "inventory_disposal": "Smaltimento",
  "extra_frame_left": "Contenuto a sinistra",
  "extra_frame_right": "Contenuto a destra",
  "extra_frame_top": "Contenuto superiore",
  "extra_frame_bottom": "Contenuto inferiore",
  "empty": "Vuoto",
  "serving": "Servizio",
  "drinkbar": "Bar delle bevande",
  "point_of_sale": "Punto vendita",
  "new_sale": "Nuova vendita",
  "invoice": "Fattura",
  "payment": "Pagamento",
  "for_all": "Applica a tutti",
  "for_selected_none": "Applica per l'ordinazione selezionata",
  "for_selected_order": "Applica per l'ordinazione selezionata",
  "for_selected_tableMate": "Applica per la persona selezionata",
  "cancel": "Annulla",
  "persons": "Persone",
  "phonenumber": "Numero di telefono",
  "company": "Nome ristorante/Azienda",
  "company_name": "Nome azienda",
  "address": "Indirizzo",
  "city": "Città",
  "country": "Paese",
  "provide_phonenumber": "Fornisci il tuo numero di telefono",
  "provide_company": "Fornisci il nome del tuo ristorante e/o il nome dell'azienda",
  "provide_address": "Fornisci il tuo indirizzo",
  "provide_city": "Fornisci la tua città",
  "provide_country": "Fornisci il tuo paese",
  "manufacturer": "Produttore",
  "for_store": "Per il negozio",
  "are_you_sure_you_want_to_delete_the_store": "Sei sicuro di voler eliminare il negozio <b>{0}</b>",
  "stock_movement": "Movimento di magazzino",
  "from_store": "Negozio di origine",
  "to_store": "Negozio di destinazione",
  "_from_store": "Origine",
  "_to_store": "Destinazione",
  "moved_quantity": "Quantità trasferita",
  "view": "Visualizza",
  "list": "Lista",
  "calendar": "Calendario",
  "thanks_for_buying": "Grazie per l'acquisto",
  "sale": "Vendita",
  "add_room": "Aggiungi stanza",
  "room_editor": "Stanza",
  "seats": "posti",
  "room_tables": "{0} tavoli",
  "room_tables_seats": "{0} tavoli con {1} posti",
  "next": "Avanti",
  "back": "Indietro",
  "book_a_table": {
    "title": "Prenota un tavolo",
    "number_of_seats": "{0} posti",
    "seats": "Posti",
    "tables": "Tavoli",
    "room": "Stanza",
    "rooms": "Stanze",
    "provide_email": "Email",
    "provide_name_or_email": "Inserisci il nome o l'email dell'ospite",
    "provide_phone": "Numero di telefono",
    "provide_name": "Nome",
    "provide_comment": "Commento",
    "not_enough_free_tables": "Non ci sono abbastanza tavoli liberi in nessuna delle stanze!",
    "not_free_table": "Non ci sono tavoli liberi in nessuna delle stanze!"
  },
  "activate_booking": "Attiva la prenotazione",
  "cancel_booking": "Annulla la prenotazione",
  "cancel_b": "Annulla",
  "extensions": "Estensioni",
  "table_extentions_placeholder": "+numero_tavolo=numero_posti_totali, ad esempio +4=8, +4+5=12",
  "quantity_must_be_unique": "Le quantità disponibili devono essere uniche!",
  "menus": {
    "tableOverview": "Panoramica tavolo",
    "bar": "Bar",
    "serving": "Servizio",
    "productionLines": "Linee di produzione",
    "reports": "Rapporti",
    "stockManagement": "Gestione delle scorte",
    "administration": "Amministrazione",
    "restaurants": "Ristoranti",
    "users": "Utenti",
    "currencies": "Valute",
    "paymentMethods": "Metodi di pagamento",
    "manage_reports": "Gestisci rapporti",
    "monitoring": "Monitoraggio",
    "drinks": "Bevande",
    "meals": "Pasti",
    "tables": "Tavoli",
    "VATCategories": "Categorie IVA",
    "printers": "Stampanti",
    "servings": "Porzioni",
    "discounts": "Sconti",
    "labels": "Etichette",
    "profile": "Il mio profilo",
    "stockList": "Gestione scorte",
    "incomingStocks": "Entrate di scorta",
    "stockMovements": "Movimenti di magazzino",
    "inventory": "Inventario",
    "inventoryScrapping": "Scarto dell'inventario",
    "suppliers": "Fornitori",
    "stores": "Negozi",
    "superAdminMenu": "Super amministratore",
    "pointOfSales": "Punto vendita",
    "tableBooking": "Prenotazione tavolo",
    "stockReport": "Statistiche di magazzino",
    "orders": "Ordini",
    "orders_quick_action": "Imposta lo stato dell'ordine",
    "cashRegisters": "Registratori di cassa",
    "bankTerminals": "Terminali bancari",
    "scales": "Bilance",
    "daily_closing": "Chiusura giornaliera",
    "support": "Supporto",
    "updateSettings": "Aggiornamenti",
    "barcodes": "Codici a barre",
    "wolt": "Wolt",
    "foodpanda": "FoodPanda",
    falatozz: "Falatozz",
    "wolt_administration": "Amministrazione Wolt",
    "advertisements": "Pubblicità",
    "products": "Prodotti",
    "menuitems": "Prodotti",
    "traffic": "Traffico",
    "todaytraffic": "Traffico quotidiano",
    "comments": "Commenti",
    "analytics": "Analisi",
    "plans": "Piani",
    "opening_hours": "Orari di apertura"
  },
  "cashRegister": "Cassa",
  "stable_versions": "Versioni stabili",
  "test_versions": "Versioni di test",
  "status": "Stato",
  "test": "Test",
  "Grid": {
    "Pager": {
      "Button": {
        "backButtonText": "Indietro",
        "nextButtonText": "Avanti"
      },
      "Description": {
        "no_records": "Nessun %{recordType} disponibile",
        "records_displayed": "%{firstIndex} - %{lastIndex} di %{total} %{recordType} visualizzati"
      }
    }
  },
  "map": "Mappa",
  "basic_data": "Dati di base",
  "restaurant_settings": {
    "default_language": "Lingua predefinita"
  },
  "show_deleted": "Mostra elementi eliminati",
  "image": "Immagine",
  "select_a_product": "Seleziona un prodotto",
  "select_a_category": "Seleziona una categoria",
  "select_a_quantity_type": "Seleziona un tipo di quantità",
  "select_a_vat_category": "Seleziona una categoria IVA",
  "select_a_store": "Seleziona un negozio",
  "select_a_supplier": "Seleziona un fornitore",
  "source_and_target_store_should_be_different": "Il negozio di origine e il negozio di destinazione dovrebbero essere diversi",
  "custom_range": "Intervallo personalizzato",
  "initial_stock": "Scorta iniziale",
  "final_stock": "Scorta finale",
  "outgoing_stock": "Movimenti di magazzino",
  "scrapping": "Scarto",
  "shortage": "Mancanza",
  "surplus": "Eccedenza",
  "minStock": "Scorta minima",
  "maxStock": "Scorta massima",
  "total_stock_cost": "Valore di acquisto delle scorte",
  "total_stock_value": "Valore di vendita delle scorte",
  "initialCost": "Costo iniziale",
  "finalCost": "Costo finale",
  "averageCost": "Costo medio",
  "initialPrice": "Prezzo iniziale",
  "averagePrice": "Prezzo medio",
  "finalPrice": "Prezzo finale",
  "minPrice": "Prezzo minimo",
  "maxPrice": "Prezzo massimo",
  "netto_income": "Reddito netto",
  "vat": "IVA",
  "brutto_income": "Reddito",
  "customer_details": "Dettagli cliente",
  "tomorrow": "Domani",
  "available": "Disponibile",
  "_available": "Disponibile",
  "order_summary": "Riepilogo ordine",
  "product_serving": "Tipo",
  "download": "Scarica",
  "upload": "Carica",
  "order_no": "N. ordine",
  "no_matches_found": "Nessuna corrispondenza trovata",
  "could_not_find_this_order": "Ordine n. {0} non trovato!",
  "could_not_find_this_order2": "Ordine non trovato!",
  "open": "Aperto",
  "fix_quantity": "Quantità fissa",
  "are_you_sure_you_want_to_delete_the_printer": "Sei sicuro di voler eliminare la stampante <b>{0}</b>",
  "are_you_sure_you_want_to_delete_the_quantity_type": "Sei sicuro di voler eliminare la quantità <b>{0}</b>",
  "are_you_sure_you_want_to_delete_the_vat_category": "Sei sicuro di voler eliminare la categoria IVA <b>{0}</b>",
  "are_you_sure_you_want_to_delete_the_discount_type": "Sei sicuro di voler eliminare la categoria di sconto <b>{0}</b>",
  "are_you_sure_you_want_to_delete_the_cashregister": "Sei sicuro di voler eliminare il registratore di cassa <b>{0}</b>",
  "are_you_sure_you_want_to_delete": "Sei sicuro di voler eliminare",
  "connection": "Connessione",
  "type": "Tipo",
  "delete_order_history": "Cancella tutti gli ordini passati",
  "ready_for_delivery": "Pronto per la consegna",
  "table_ready_for_delivery": "!ESC|bC!ESC|4CTavolo n. {0}!LF!ESC|bC!ESC|4CN. ordine {1}!LF!ESC|bC!ESC|4C{2}!LF!ESC|bC!ESC|4CPronto per la consegna!",
  "proportional_addition_tooltip": "Aggiunta proporzionale. L'aggiunta effettiva sarà il prodotto della quantità selezionata dell'articolo base e della quantità dell'aggiunta.",
  "you_are_not_authorized_to_access_this_restaurant_with_an_app": "In base alla tua sottoscrizione, non sei autorizzato ad accedere a questa unità.",
  "you_are_not_authorized_to_access_this_feature": "In base alla tua sottoscrizione, non sei autorizzato ad accedere a questa funzione.",
  "barcode": "Codice a barre",
  "test_scale": "Test della bilancia",
  "tare_weight": "Peso a vuoto",
  "gross_weight": "Peso lordo",
  "full_inventory": "Inventario completo",
  "all_items": "Tutti",
  "new": "Nuovo",
  "parent_category": "Categoria principale",
  "choose_parent_category": "Scegli una categoria principale",
  "save_my_details_for_later_use": "Acconsento che i miei dati personali siano memorizzati e gestiti da {0} e dal sistema di gestione dei ristoranti basato su cloud EatWithMe.",
  "saved_my_details_for_later_use": "Ho accettato che i miei dati personali siano memorizzati e gestiti da {0} e dal sistema di gestione dei ristoranti basato su cloud EatWithMe.",
  "agree": "Fornisci il mio consenso",
  "accept_homedelivery_terms": "Accetto le condizioni per la consegna a domicilio",
  "accept_pickup_terms": "Accetto le condizioni per il ritiro",
  "accept_privacy_terms": "Accetto l'informativa sulla privacy",
  "privacy_policy": "Informativa sulla privacy",
  "privacy_policy_link": "https://eatwithme.online/adatvedelmi-szabalyzat/",
  "door": "Piano/porta",
  "backup": "Backup",
  "withdraw": "Prelievo",
  "tax_number": "Numero di partita IVA",
  "tax_number_pattern": "(^$)|(^\\d\\d\\d\\d\\d\\d\\d\\d-\\d-\\d\\d$)",
  "email": "E-mail",
  "email_pattern": "[^@]+@[^@]+(\\.[^@]+)+",
  "not_empty_pattern": "\\w+",
  "postcode": "Codice postale",
  "postcode_pattern": "^\\d\\d\\d\\d$",
  "address_pattern": "^.+ (via|piazza|parco) \\d+\\.?$",
  "address_placeholder": "Formato: nome (via|piazza|ecc.) numero.",
  "repay_invoice_dialog": "Desideri modificare i dettagli di pagamento?",
  "except": "Eccetto",
  "first_modify_start_date": "Prima modifica l'orario di inizio",
  "uncategorized_items": "Prodotti senza categoria impostata",
  "wolt": {
    "reload": "Aggiorna da Wolt",
    "upload_selected_elements": "Carica prodotti selezionati su Wolt",
    "resync_selected_elements": "Risincronizza prodotti selezionati su Wolt",
    "delete_selected_elements": "Elimina prodotti selezionati da Wolt",
    "elements_in_sync": "Numero di prodotti sincronizzati",
    "elements_in_unsync": "Numero di prodotti non sincronizzati",
    "elements_in_to_upload": "Numero di prodotti mancanti su Wolt",
    "elements_in_to_delete": "Numero di prodotti extra su Wolt",
    "delete_menu_items": "Elimina prodotti da Wolt",
    "delete_category": "Elimina categoria di prodotti da Wolt",
    "delete_category_items": "Elimina da Wolt i prodotti che esistono solo per questa categoria",
    "sync_category_items": "Risincronizza i prodotti che esistono già sia su EatWithMe che su Wolt",
    "upload_category_items": "Carica i prodotti mancanti su Wolt",
    "sync_menu_item": "Sincronizza prodotto su Wolt",
    "delete_menu_item": "Elimina prodotto da Wolt",
    "upload_menu_item": "Carica prodotto su Wolt",
    "upload_category": "Elimina categoria di prodotti da Wolt"
  },
  "vat_invoice": "Fattura IVA",
  "there_already_exists_a_table_with_this_number": "Esiste già un tavolo con questo numero!",
  "access_tokens": "Token di accesso",
  "generate_access_token": "Genera token di accesso",
  "printer_server": "Server della stampante",
  "purchase_price": "Prezzo di acquisto netto",
  "selling_price": "Prezzo di vendita lordo",
  "unit_of_consumption": "Unità di vendita/utilizzo",
  "picture": "Immagine",
  "homedelivery": "Consegna a domicilio",
  "pickup": "Ritiro",
  "onsite": "Consumo locale",
  "by_occupation_start": "All'apertura dell'ordine",
  "by_payment": "Al momento del pagamento",
  "are_you_sure_you_cancel_the_payment": "Sei sicuro di voler annullare la registrazione dei dettagli del pagamento?",
  "value_difference": "Differenza di valore",
  "total_value_difference": "Differenza di valore totale",
  "subscription": "Abbonamento",
  "print": "Stampa",
  "something_strange_happened_resync": "È successo qualcosa di inaspettato! Risincronizzazione...",
  "payment_due_in": "Scadenza del pagamento tra {0} giorno(i)!",
  "payment_was_due_in": "Scadenza del pagamento scaduta da {0} giorno(i)!",
  "continue_in": "Dopo {0} minuto(i) questo avviso si chiuderà. Attendere prego...",
  "profitandloss": {
    "open_stock": "Inventario iniziale",
    "cost": "Costo",
    "purchase": "Acquisto",
    "shortage": "Eccedenza/mancanza",
    "scrapping": "Scarto",
    "closing_stock": "Inventario finale",
    "stock_change": "Variazione di inventario",
    "income": "Entrate",
    "profit": "Profitto",
    "profitp": "Profitto (%)"
  },
  "service_fee": "Tariffa di servizio",
  "service_fee_net": "Tariffa di servizio netta",
  "fiscat_service_fee": "Tariffa di servizio",
  "rounding": "Arrotondamento",
  "income_without_cost": "Entrate senza costo?",
  "not_used_ingredient": "Ingrediente non utilizzato",
  "disconnected": "Non connesso",
  "error": "Errore di connessione",
  "cover-open": "Copertura aperta",
  "out-of-paper": "Finito la carta",
  "refresh_my_data": "Aggiorna i miei dettagli",
  "skin_picker": "Selettore di tema",
  "skins": [
    { "id": "default", "name": "Predefinito" },
    { "id": "fancy", "name": "Elegante" },
    { "id": "flat", "name": "Piatto" },
    { "id": "night", "name": "Notte" }
  ],
  "draft": "Bozza",
  "detailed": "Conto dettagliato",
  "service_fee_vat": "Tariffa di servizio separata",
  "too_many_bluetooth_cashiers": "Può essere associata solo una cassa Bluetooth contemporaneamente!",
  "no_bluetooth_cashiers": "Si prega di collegare o associare una macchina cassa Bluetooth!",
  "no_bluetooth_adapter": "L'interfaccia Bluetooth non è disponibile!",
  "no_serial_cashiers": "Si prega di collegare una macchina cassa con cavo USB-Serial!",
  "locked": "Bloccato",
  "syncronize_barcodes": "Sincronizza",
  "download_barcodes": "Scarica",
  "upload_barcodes": "Carica",
  "local_product": "Prodotto collegato",
  "name_of_customer": "Imposta nome del cliente",
  "product_modified_save_it": "Prodotto modificato!",
  "drop": "Rimuovi",
  "openned": "Aperto",
  "opening_time": "Orario di apertura",
  "planned_closing_time": "Orario di chiusura pianificato",
  "open_day": "Giorno aperto",
  "continue_without_open_day": "Continua in ogni caso",
  "timetable": "Orari di apertura",
  "closed": "Chiuso",
  "failed_to_send_order": "Impossibile inviare l'ordine: {0}",
  "resend": "Prova di nuovo",
  "opened": "Aperto",
  "local_addition_tooltip": "Applicato per il consumo locale",
  "takeaway_addition_tooltip": "Applicato per l'asporto o la consegna a domicilio",
  "top_category": "Categoria principale",
  "_top_category": "Cat. principale",
  "takeaway": "Asporto",
  "cashier": "Cassa",
  "download_template": "Carica modello",
  "user_missing": "Nessun utente è registrato con l'indirizzo email fornito",
  "revert_table_occupation_multi_payment": "Vuoi davvero ripristinare? Ci sono stati diversi pagamenti, il pagamento può essere ripristinato nella scheda pagamenti!",
  "revert_table_occupation": "Vuoi davvero ripristinare?",
  "revert": "Ripristina",
  "revert_table_mate_payment": "Ripristina anche i pagamenti",
  "revert_payment": "Vuoi davvero annullare il pagamento?",
  "no_inventory_quantity_selected": "Elemento di inventario ma nessuna quantità di inventario è stata selezionata!",
  "multiple_inventory_quantity_selected": "Vuoi davvero impostare più quantità di inventario individuali?",
  "preorder_possible": "Preordinabile",
  "allowed_installments": {
    "start": "In anticipo",
    "by_each": "Giornaliero",
    "end": "Alla fine"
  },
  "installments_strategy": "Programma di pagamento",
  "payer": "Pagante",
  "couriers": "Corrieri",
  "courier": "Corriere",
  "days": ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"],
  "do_you_realy_want_to_order_so_many": "Vuoi davvero ordinare <strong>{0}</strong> pezzi?",
  "you_cannot_order_so_many": "Non puoi ordinare <strong>{0}</strong> pezzi in una sola volta!",
  "day_must_be_open": "Apri prima i giorni per questa azione!",
  "more_addresses": "Altri indirizzi",
  "payment_received_by": "Pagamento ricevuto da",
  "product_receipe_modified_recalculate_it": "Ricetta modificata. Vuoi ricalcolare l'inventario per il passato",
  "minute": "minuto",
  "id": "ID interno",
  "activate_sound": "Suono attivato",
  "barcode_not_found": "Codice a barre sconosciuto!",
  "linked_product": "Prodotto proprio collegato",
  "new_in_central_database": "Disponibile dal database globale",
  "exists_in_central_database": "Esiste nel database globale",
  "doesnt_exists_in_central_database": "Non esiste nel database globale",
  "local_value": "Valore locale",
  "global_value": "Valore globale",
  tooltip: {
    "set_new_incoming_order": "Nuovo ordine in arrivo in attesa di conferma!",
    "set_accepted_order": "Ordine confermato! Doppio clic per confermare l'ordine selezionato!",
    "set_order_in_production": "Ordine in produzione! Doppio clic per impostare questo stato!",
    "set_order_ready": "Ordine é pronto! Doppio clic per impostare questo stato!",
    "set_order_under_delivering": "Ordine in consegna! Doppio clic per impostare questo stato!",
    "set_order_delivered": "Ordine consegnato! Doppio clic per impostare questo stato!",
    "header_new_incoming_order": "Nuovo ordine in arrivo in attesa di conferma!",
    "header_accepted_order": "Ordine confermato! Doppio clic per confermare tutti gli ordini sospesi o selezionati!",
    "header_order_in_production": "Ordine in produzione! Doppio clic per impostare questo stato per tutti gli ordini o quelli selezionati!",
    "header_order_ready": "Ordine é pronto! Doppio clic per impostare questo stato per tutti gli ordini o quelli selezionati!",
    "header_order_under_delivering": "Ordine in consegna! Doppio clic per impostare questo stato per tutti gli ordini o quelli selezionati!",
    "header_order_delivered": "Ordine consegnato! Doppio clic per impostare questo stato per tutti gli ordini o quelli selezionati!",
    "header_filter_new_incoming_order": "Filtra nuovi ordini",
    "header_filter_accepted_order": "Filtra ordini confermati",
    "header_filter_order_in_production": "Filtra ordini in preparazione",
    "header_filter_order_ready": "Filtra ordini pronti",
    "header_filter_order_under_delivering": "Filtra ordini in consegna",
    "header_filter_order_delivered": "Filtra ordini consegnati",
    "unlink_courier": "Doppio clic per scollegare l'ordine dal corriere!",
    "link_courier": "Doppio clic per collegare l'ordine a un corriere selezionato!",
    "header_print_receipt": "Doppio clic per stampare tutti gli ordini non ancora stampati o quelli selezionati!",
    "header_print_invoice": "Doppio clic per stampare la fattura di tutti gli ordini o quelli selezionati!",
    "header_unlock": "Doppio clic per sbloccare o bloccare tutti gli ordini sbloccabili (consegnati e pagati)!",
    "print_receipt": "Doppio clic per stampare l'ordine!",
    "reprint_receipt": "Doppio clic per ristampare l'ordine!",
    "print_invoice": "Doppio clic per stampare la fattura dell'ordine!",
    "unlock": "Doppio clic per sbloccare o bloccare l'ordine!",
    "delivery_time": "Orario di consegna pianificato",
    "pickup_time": "Orario di ritiro pianificato",
    "couriers": "Corrieri disponibili (utenti con autorizzazioni di corriere). L'autorizzazione può essere concessa tramite l'amministrazione agli utenti registrati.",
    "printing": "Stampa in corso",
    "printfailed": "Tentativo di stampa fallito. Doppio clic per un nuovo tentativo di stampa.",
    "printed": "Stampa completata con successo! Doppio clic per stampare una copia.",
    "unprinted": "L'ordine non è ancora stato stampato. Doppio clic per stampare.",
    "qrcode": "Doppio clic per stampare il codice QR del corriere.",
    "register_payment": "Doppio clic per registrare i dettagli del pagamento.",
    "paid": "Fattura pagata.",
    "user_comment": "Imposta un commento permanente per il tuo cliente. Questo commento verrà visualizzato anche per i prossimi ordini!",
    "comment": "Imposta un commento solo per questo ordine!",
    "address": "Fornisci l'indirizzo completo, ad esempio 'Árpád köz 2.'. Se il sistema non trova l'indirizzo, accetta qualsiasi indirizzo con '/' all'inizio, ad esempio '/All'ingresso della stazione ferroviaria'",
  },
  tablesoverview: {
    tooltip: {
      "printing": "Stampa in corso",
      "printfailed": "Tentativo di stampa fallito. Riprova la stampa.",
      "printed": "Stampato con successo",
      "unprinted": "L'ordine non è ancora stato stampato."
    }
  },
  menuitems: {
    "tooltip": {
      "expected_profit": "Margine di profitto previsto",
      "suggested_price": "Prezzo suggerito",
      "unit_price": "Prezzo di vendita per una singola unità per il consumo locale",
      "is_inventory_item": "Non può essere selezionato come opzione di confezionamento durante la vendita. Questa unità è la base per la gestione dell'inventario. Può essere ricevuto e inventariato in questa unità.",
      "non_inventory_item": "Non può essere selezionato come opzione di confezionamento durante la vendita. Quantità normale del prodotto. Non è un'unità di inventario.",
      "on_menu_is_inventory_item": "Può essere selezionato come opzione di confezionamento durante la vendita. Questa unità è la base per la gestione dell'inventario. Può essere ricevuto e inventariato in questa unità.",
      "on_menu_non_inventory_item": "Può essere selezionato come opzione di confezionamento durante la vendita. Quantità normale del prodotto. Non è un'unità di inventario.",
      "def_is_inventory_item": "Predefinito ma non può essere selezionato come opzione di confezionamento durante la vendita. Questo non è buono. Seleziona un confezionamento vendibile predefinito! Questa unità è la base per la gestione dell'inventario. Può essere ricevuto e inventariato in questa unità.",
      "def_non_inventory_item": "Predefinito ma non può essere selezionato come opzione di confezionamento durante la vendita. Questo non è buono. Seleziona un confezionamento vendibile predefinito! Quantità normale del prodotto. Non è un'unità di inventario.",
      "def_on_menu_is_inventory_item": "Predefinito e può essere selezionato come opzione di confezionamento durante la vendita. Questa unità è la base per la gestione dell'inventario. Può essere ricevuto e inventariato in questa unità.",
      "def_on_menu_non_inventory_item": "Predefinito e può essere selezionato come opzione di confezionamento durante la vendita. Quantità normale del prodotto. Non è un'unità di inventario.",
      "unit_cost": "Prezzo di acquisto netto",
      "profit_ratio": "Margine di profitto",
      "on_menu": "Prodotto principale, ordinabile direttamente",
      "not_on_menu": "Non è un prodotto principale, non ordinabile direttamente, solo come opzione per altri prodotti",
      "active": "Prodotto attivo",
      "deactivated": "Prodotto disattivato",
      "online": "Prodotto visibile nel negozio online",
      "not_online": "Prodotto non visibile nel negozio online",
      "available_online": "Prodotto disponibile per l'ordine online",
      "not_available_online": "Prodotto al momento non disponibile per l'ordine online",
      "default_inventory_item": "Prodotto gestito dall'inventario (materia prima). Eredita questa proprietà dalla sua categoria.",
      "default_not_inventory_item": "Prodotto non gestito dall'inventario. Eredita questa proprietà dalla sua categoria.",
      "inventory_item": "Prodotto gestito dall'inventario (materia prima)",
      "not_inventory_item": "Prodotto non gestito dall'inventario (materia prima)",
      "not_free_quantity": "Durante l'ordinazione, è possibile selezionare solo opzioni di confezionamento predefinite (quantità) ",
      "free_quantity": "Durante l'ordinazione, è possibile modificare liberamente la quantità venduta (ad esempio, necessaria per la pesatura dei prodotti).",
      "sold_inventory": "Prodotto finito gestito dall'inventario venduto, il che significa che il prodotto venduto è anche una materia prima inventariata.",
      "sold_inventory_ingredient": "Prodotto finito gestito dall'inventario venduto, il che significa che il prodotto venduto è anche una materia prima inventariata e un ingrediente di un altro prodotto.",
      "sold_inventory_ingredient_option": "Prodotto finito gestito dall'inventario venduto, il che significa che il prodotto venduto è anche una materia prima inventariata e un ingrediente di un altro prodotto, oltre a essere un'opzione.",
      "sold_inventory_option": "Prodotto finito gestito dall'inventario venduto, il che significa che il prodotto venduto è anche una materia prima inventariata e un'opzione di un altro prodotto.",
      "sold_service": "Servizio venduto, dove il prodotto non è inventariato (non è una materia prima) e non contiene altri prodotti (ricetta vuota), diventando un servizio.",
      "sold_service_ingredient": "Sotto-prodotto del servizio venduto, dove il prodotto non è inventariato (non è una materia prima) e non contiene altri prodotti (ricetta vuota), diventando un servizio, ma è citato nella ricetta di un altro prodotto.",
      "sold_service_ingredient_option": "Opzione del sotto-prodotto del servizio venduto, il che significa che il prodotto stesso non è stoccato (non è un ingrediente) e non contiene altri prodotti (ricetta vuota), quindi è un servizio, e altri prodotti lo citano nella loro ricetta anche come opzione.",
      "sold_service_option": "Opzione del servizio venduto, il che significa che il prodotto stesso non è stoccato (non è un ingrediente) e non contiene altri prodotti (ricetta vuota), quindi è un servizio, che è citato da altri prodotti come opzione.",
      "sold_complex_product": "Prodotto complesso venduto, che consiste in altri prodotti",
      "sold_complex_product_option": "Prodotto complesso venduto, che consiste in altri prodotti, oltre a essere un'opzione per un altro prodotto",
      "sold_linked_product": "Prodotto collegato venduto",
      "sold_linked_product_ingredient": "Prodotto collegato venduto, ingrediente",
      "sold_linked_product_ingredient_option": "Prodotto collegato venduto, ingrediente opzione",
      "_complex_product": "Prodotto complesso non venduto direttamente, che consiste in altri prodotti",
      "_complex_product_option": "Prodotto complesso non venduto direttamente, che consiste in altri prodotti, oltre a essere un'opzione per un altro prodotto",
      "_complex_product_ingredient": "Prodotto complesso non venduto direttamente, che consiste in altri prodotti, ed è citato in un'altra ricetta del prodotto",
      "_complex_product_ingredient_option": "Prodotto complesso non venduto direttamente, che consiste in altri prodotti, ed è citato in un'altra ricetta del prodotto, oltre a essere un'opzione",
      "_linked_product": "Prodotto collegato non venduto direttamente (prezzo diverso, quantità speciale, o semplicemente una copia di un altro prodotto)",
      "_linked_product_option": "Prodotto collegato non venduto direttamente (prezzo diverso, quantità speciale, o semplicemente una copia di un altro prodotto), oltre a essere un'opzione per un altro prodotto",
      "_linked_product_ingredient": "Prodotto collegato non venduto direttamente (prezzo diverso, quantità speciale, o semplicemente una copia di un altro prodotto), ed è incluso nella ricetta di un altro prodotto",
      "_linked_product_ingredient_option": "Prodotto collegato non venduto direttamente (prezzo diverso, quantità speciale, o semplicemente una copia di un altro prodotto), ed è citato in un'altra ricetta del prodotto, oltre a essere un'opzione",
      "_service_ingredient": "Sotto-prodotto del servizio non venduto direttamente, citato in un'altra ricetta del prodotto",
      "_service_ingredient_option": "Sotto-prodotto del servizio non venduto direttamente, citato in un'altra ricetta del prodotto, oltre a essere un'opzione",
      "_service_option": "Servizio non venduto direttamente, citato da un altro prodotto come opzione",
      "_inventory": "Ingrediente stoccato non venduto/utilizzato da altri prodotti",
      "_inventory_ingredient": "Ingrediente stoccato non venduto",
      "_inventory_ingredient_option": "Ingrediente stoccato non venduto e opzione",
      "_inventory_option": "Ingrediente stoccato non venduto citato da un altro prodotto come opzione",
      "_inventory": "Ingrediente stoccato non utilizzato/referenziato da altri prodotti",
      "_service": "Servizio non venduto"
    }
  },
  _settings: {

    "hu": "Ungherese",
    "en": "Inglese",
    "states": ["cancellato", "selezionato", "ordinato", "accettato", "in corso", "pronto", "in consegna", "consegnato"],
    "day": "giorno",
    "minute": "minuto",
    "days": ["lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato", "domenica"],
    "from_hour": "da",
    "to_hour": "a",
    "email": "indirizzo email",
    "payment_method": {
      "title": "Metodi di pagamento disponibili",
      "meth": {
        "title": "Metodo"
      },
      "term": {
        "title": "Termini di pagamento",
        "description": "Termini di pagamento per il negozio online"
      }
    },
    "enabled_features": {
      "homedelivery": {
        "zip": {
          "title": "Codice postale",
          "description": "Quali codici postali accetta il sistema (espressione regolare)"
        },
        "dayends": {
          "title": "Chiusura giornaliera",
          "description": "Fino a che ora è possibile effettuare ordini nello stesso giorno nel negozio online"
        },
        "timeahead": {
          "title": "Ritardo",
          "description": "Per ordini manuali o del negozio online, quanti ore in anticipo garantisce la consegna"
        },
        "min-duration": {
          "title": "Durata dello slot di consegna",
          "description": "Quante ore dura lo slot di consegna"
        },
        "homedelivery_menuitem": {
          "title": "ID prodotto per il costo della consegna a domicilio",
          "description": "Quando si applica un costo di consegna a domicilio basato sul codice postale, quale prodotto utilizza il sistema. Se un tale prodotto non esiste ancora, crea un nuovo prodotto chiamato 'Costo di consegna a domicilio', salvane una copia, aprila per modificarla, annota il suo ID e inseriscilo qui."
        },
        "instantOrderOnly": {
          "title": "Solo ordini immediati",
          "description": "Accetta solo ordini immediati nel negozio online"
        },
        "copy_order_email": {
          "title": "Notifiche via email",
          "description": "Chi dovrebbe ricevere notifiche via email sugli ordini del negozio online e sui cambi di stato"
        },
        "send_email_on": {
          "title": "Invia email allo stato",
          "description": "Quando gli ordini del negozio online raggiungono quale stato, invia notifiche via email agli indirizzi sopra indicati"
        },
        "price": {
          "title": "Costo di consegna",
          "description": "Hai la possibilità di addebitare automaticamente una tariffa di consegna in base al codice postale e al valore dell'ordine",
          "name": "Nome",
          "zip": "Codice postale",
          "above_amount": "Sopra l'importo",
          "price": "Tariffa di consegna"
        },
        "orderedStatus": {
          "title": "Stato dopo l'ordine",
          "description": "Stato iniziale degli articoli ordinati nel negozio online"
        },
        "available-timeslots": {
          "title": "Slot di consegna",
          "description": "Questi slot di tempo sono disponibili per la consegna attraverso il negozio online"
        },
        "terms-and-conditions": {
          "title": "Termini e condizioni generali",
          "description": "Informa i tuoi clienti sulle condizioni di consegna a domicilio al momento dell'ordine nel negozio online"
        },
        "delays": {
          "title": "Tempo di consegna stimato",
          "description": "Diverse durate disponibili per la preparazione/consegna confermate in minuti per Wolt/Foodpanda"
        },
        "blockWaiterClose": {
          "title": "Blocca chiusura per i camerieri",
          "description": "Solo gli amministratori possono chiudere una consegna a domicilio"
        },
        "printInvoiceOnOrder": {
          "title": "Stampa automatica della fattura",
          "description": "Dopo la sottomissione di nuovi ordini, stampa automaticamente la fattura."
        },
        "allowed_installments": {
          "title": "Opzioni di pagamento a rate",
          "description": "Opzioni di pagamento a rate per ordini del menu",
          "start": "Anticipo",
          "by_each": "Pagamento giornaliero",
          "end": "Pagamento alla fine"
        },
        "user_allowed_installments": {
          "title": "Opzioni di pagamento a rate nel negozio online",
          "description": "Opzioni di pagamento a rate per ordini del menu nel negozio online",
          "start": "Anticipo",
          "by_each": "Pagamento giornaliero",
          "end": "Pagamento alla fine"
        },
        "table-number": {
          "title": "Numero del tavolo",
          "description": "Il numero del tavolo per la gestione degli ordini di consegna a domicilio"
        }
      },
      "pickup": {
        "dayends": {
          "title": "Chiusura giornaliera",
          "description": "Fino a che ora è possibile effettuare ordini nello stesso giorno nel negozio online"
        },
        "timeahead": {
          "title": "Ritardo",
          "description": "Per ordini manuali o del negozio online, quanti ore in anticipo garantisce la preparazione dell'ordine"
        },
        "min-duration": {
          "title": "Durata dello slot di ritiro",
          "description": "Quante ore dura lo slot di ritiro"
        },
        "instantOrderOnly": {
          "title": "Solo ordini immediati",
          "description": "Accetta solo ordini immediati nel negozio online"
        },
        "copy_order_email": {
          "title": "Notifiche via email",
          "description": "Chi dovrebbe ricevere notifiche via email sugli ordini del negozio online e sui cambi di stato"
        },
        "send_email_on": {
          "title": "Invia email allo stato",
          "description": "Quando gli ordini del negozio online raggiungono quale stato, invia notifiche via email agli indirizzi sopra indicati"
        },
        "orderedStatus": {
          "title": "Stato dopo l'ordine",
          "description": "Stato iniziale degli articoli ordinati nel negozio online"
        },
        "available-timeslots": {
          "title": "Slot di ritiro",
          "description": "Questi slot di tempo sono disponibili per gli ordini di ritiro tramite il negozio online"
        },
        "terms-and-conditions": {
          "title": "Termini e condizioni generali",
          "description": "Informa i tuoi clienti sulle condizioni di ritiro al momento dell'ordine nel negozio online"
        },
        "delays": {
          "title": "Tempo di ritiro stimato",
          "description": "Diverse durate disponibili per la preparazione confermata per Wolt/Foodpanda in minuti"
        },
        "allowed_installments": {
          "title": "Opzioni di pagamento a rate",
          "description": "Opzioni di pagamento a rate per ordini del menu",
          "start": "Anticipo",
          "by_each": "Pagamento giornaliero",
          "end": "Pagamento alla fine"
        },
        "user_allowed_installments": {
          "title": "Opzioni di pagamento a rate nel negozio online",
          "description": "Opzioni di pagamento a rate per ordini del menu nel negozio online",
          "start": "Anticipo",
          "by_each": "Pagamento giornaliero",
          "end": "Pagamento alla fine"
        },
        "table-number": {
          "title": "Numero del tavolo",
          "description": "Il numero del tavolo per la gestione degli ordini di ritiro"
        }
      }
    },
    "tables-overview": {
      "autolock": {
        "title": "Blocco automatico dello schermo",
        "description": "Dopo un certo tempo, lo schermo si blocca e può essere sbloccato solo con un codice o una password."
      },
      "timeout": {
        "title": "Ritardo del blocco",
        "description": "Dopo quanti secondi dovrebbe bloccarsi lo schermo"
      },
      "bigOrderNumber": {
        "title": "Numero d'ordine grande",
        "description": "Stampa un numero d'ordine più grande sulla ricevuta della cucina rispetto all'impostazione predefinita"
      },
      "perPersonKitchenReceipt": {
        "title": "Ricevuta di cucina separata per persona",
        "description": "Ad esempio, quando l'ordine di ciascuna persona deve essere servito su un vassoio separato"
      },
      "bigAdditions": {
        "title": "Dimensioni carattere più grandi sulla ricevuta di cucina",
        "description": "Caratteri +/- più grandi sulla ricevuta di cucina"
      },
      "autoDeliverOnUnlock": {
        "title": "Modalità di consegna automatica",
        "description": "Dopo aver liberato il tavolo, tutti gli articoli non cancellati vengono automaticamente impostati come 'consegnati' e dedotti dall'inventario"
      },
      "unlockOnPayment": {
        "title": "Rilascio automatico",
        "description": "Rilascio automatico del tavolo al momento del pagamento se non ci sono più articoli non pagati"
      },
      "lessKioskButtons": {
        "title": "Meno pulsanti in modalità registratore di cassa",
        "description": "Mostra il minor numero possibile di pulsanti in modalità registratore di cassa"
      },
      "print_accepted_customer_orders": {
        "title": "Stampa automatica alla conferma",
        "description": "Stampa automaticamente gli ordini del negozio online alla conferma"
      },
      "always_allow_delete": {
        "title": "Consenti sempre l'eliminazione",
        "description": "Consenti sempre l'eliminazione anche per i permessi dei camerieri"
      },
      "block_waiter_print_invoice": {
        "title": "Blocca la stampa della fattura",
        "description": "Blocca la stampa della fattura da parte dei camerieri"
      },
      "block_waiter_register_payment": {
        "title": "Blocca la registrazione del pagamento",
        "description": "Blocca la registrazione del pagamento da parte dei camerieri"
      },
      "block_waiter_print_daily_closing": {
        "title": "Divieto di interrogare i dati di traffico veloci",
        "description": "Divieto di interrogare i dati di traffico attuali per i camerieri"
      },
      "waiter_print_daily_closing_full": {
        "title": "Dati di traffico veloci completi",
        "description": "Dati di traffico attuali completi per i camerieri"
      },
      "drinkOrderStatus": {
        "title": "Stato dell'ordine delle bevande all'ordine",
        "description": ""
      },
      "mealOrderStatus": {
        "title": "Stato dell'ordine dei pasti all'ordine",
        "description": ""
      },
      "table-qrcode": {
        "title": "Funzionalità del codice QR stampato per il tavolo",
        "description": "",
        "enumNames": ["Nessuno", "Solo pagamento", "Connessione"]
      },
      "auto-table-qrcode": {
        "title": "Stampa QR-code automaticamente",
        "description": "Con la stampa dei primi ordini, stampa anche il codice QR."
      },
      "notify-waiter-when-part-ready": {
        "title": "Notifica al cameriere quando una parte del piatto è pronta",
        "description": "Quando un piatto è preparato su più linee di produzione, notifica al cameriere anche se non tutte le linee di produzione hanno terminato la loro parte."
      },
      "notify-customer-when-part-ready": {
        "title": "Notifica al cliente quando una parte del piatto è pronta",
        "description": "Quando un piatto è preparato su più linee di produzione, notifica al cliente anche se non tutte le linee di produzione hanno terminato la loro parte."
      },
      "states": {
        "title": "Stati del filtro",
        "description": "Quali stati dell'ordine considerare"
      },
      "statesShown": {
        "title": "Stati mostrati",
        "description": "Quali stati (colonne) mostrare quando si apre un tavolo"
      },
      "newOrdersStatesShown": {
        "title": "Stati mostrati all'ingresso dell'ordine",
        "description": "Quali stati (colonne) mostrare per i nuovi inserimenti dell'ordine"
      },
      "newOrdersKioskStatesShown": {
        "title": "Stati mostrati in modalità registratore di cassa",
        "description": "Quali stati (colonne) mostrare per i tavoli in modalità registratore di cassa"
      }
    }
  },
  "receipts": "Scontrini",
  "receipt": "Scontrino",
  "save_update_address": "Salva e aggiorna l'indirizzo",
  "sort_abc": "Ordina",
  "reprint_receipt": "Vuoi davvero ristampare lo scontrino?",
  "ntak": {
    "ntak": "NTAK",
    "taxid": "Codice fiscale",
    "ntak_id": "ID NTAK",
    "save_ntak_id": "Salva ID NTAK",
    "cert_request_generate": "Genera nuova richiesta certificato",
    "cert_request_download": "Scarica richiesta certificato",
    "cert_upload": "Carica certificato",
    "cert_request": "Richiesta certificato (CSR)",
    "test": "Testa connessione",
    "cert": "Certificato (CER)",
    "key": "Chiave privata (KEY)",
    "categories": "NTAK",
    "status": "Stato NTAK",
    "default_ntak_main_category": "Categoria principale NTAK",
    "_default_ntak_main_category": "Categoria principale",
    "default_ntak_sub_category": "Sottocategoria NTAK",
    "_default_ntak_sub_category": "Sottocategoria",
    "mainCategoryNotSet": "La categoria principale NTAK non è impostata per nessun prodotto! Può essere impostata a livello di categoria (verrà ereditata) o a livello di prodotto.",
    "subCategoryNotSet": "La sottocategoria NTAK non è impostata per nessun prodotto! Può essere impostata a livello di categoria (verrà ereditata) o a livello di prodotto.",
    "main_categories": [
      {
        "id": 5, "name": "Cibo", "shortName": "Cibo",
        "sub_categories": [
          { "id": 1, "name": "Colazione" },
          { "id": 2, "name": "Panino" },
          { "id": 3, "name": "Antipasto" },
          { "id": 4, "name": "Zuppa" },
          { "id": 5, "name": "Portata principale" },
          { "id": 6, "name": "Contorno" },
          { "id": 7, "name": "Sottaceti/Insalata", "shortName": "Sottaceti/Insalata" },
          { "id": 8, "name": "Dolce" },
          { "id": 9, "name": "Spuntino" },
          { "id": 30, "name": "Portata principale con contorno" },
          { "id": 31, "name": "Pacco cibo" },
          { "id": 10, "name": "Altro" },
        ]
      },
      {
        "id": 1, "name": "Bevanda analcolica locale", "shortName": "Bev. loc.",
        "sub_categories": [
          { "id": 11, "name": "Limonata/sciroppo/bevanda fresca", "shortName": "Limonata/Sciroppo/Fresca" },
          { "id": 12, "name": "Cocktail analcolico", "shortName": "Cocktail analcolico" },
          { "id": 13, "name": "Tè/cioccolata calda" },
          { "id": 14, "name": "Caffè" },
          { "id": 32, "name": "Pacco bevande" },
        ]
      },
      {
        "id": 2, "name": "Bevanda analcolica non locale", "shortName": "Bev. non loc.",
        "sub_categories": [
          { "id": 15, "name": "Acqua" },
          { "id": 16, "name": "Bibita gassata" },
          { "id": 17, "name": "Bibita non gassata" },
          { "id": 33, "name": "Bibita non gassata" },
          { "id": 32, "name": "Pacco bevande" },
        ]
      },
      {
        "id": 3, "name": "Bevanda alcolica", "shortName": "Bev. alcol.",
        "sub_categories": [
          { "id": 18, "name": "Cocktail" },
          { "id": 19, "name": "Liquore" },
          { "id": 20, "name": "Spirito" },
          { "id": 21, "name": "Birra" },
          { "id": 22, "name": "Vino" },
          { "id": 23, "name": "Champagne" },
          { "id": 32, "name": "Pacco bevande" },
        ]
      },
      {
        "id": 4, "name": "Altro", "shortName": "Altro",
        "sub_categories": [
          { "id": 10, "name": "Altro" },
          { "id": 26, "name": "Tariffa di consegna" },
          { "id": 27, "name": "Confezione ecologica", "shortName": "Confez. ecol." },
          { "id": 28, "name": "Confezione in plastica", "shortName": "Confez. plast." },
          { "id": 29, "name": "Sconto" },
          { "id": 34, "name": "Non ristorazione" },
        ]
      },
    ]
  },
  "cashier_open_amount": "Apertura cassa",
  "cashier_dayopen": "Apri giornata",
  "cashier_dayclose": "Chiudi giornata",
  "storno": "Storno",
  "stornoreason_1": "Annullamento cliente",
  "stornoreason_2": "Inserimento errato",
  "stornoreason_3": "Inserimento errato del metodo di pagamento",
  "stornoreason_4": "Prodotto non disponibile",
  "stornoreason_5": "Emissione errata del tipo di documento",
  "stornoreason_6": "Utilizzo fallito del metodo di pagamento",
  "stornoreason_7": "Dati cliente errati/Inserimento errato",
  "stornoreason_8": "Acquisto di prova",
  "stornoreason_0": "Altro",
  "ap": "Numero AP",
  "receiptNumber": "Numero scontrino",
  "customerTaxNum": "Numero fiscale cliente",
  "customerName": "Nome cliente",
  "customerPostCode": "Codice postale cliente",
  "customerTown": "Città cliente",
  "customerAddress": "Indirizzo cliente",
  "dont_print": "Non stampare",
  "history": "Cronologia",
  "action_not_allowed": "Non hai il permesso per eseguire questa azione",
  "already_paid_reprint_invoice": "Conto già saldato! Vuoi davvero ristampare la fattura?",
  "documentation": "Documentazione",
  "channels": {
    "console": "Console",
    "webshop_homedelivery": "Webshop",
    "webshop_pickup": "Webshop",
    "webshop_onsite": "Webshop",
    "wolt": "Wolt",
    "foodora": "Foodora",
    "falatozz": "Falatozz"
  },
  "webshopOrder": "Ordine webshop",
  auto_translate: "Tradurre",
  webshop: "Webshop",
  authenticate: "Autenticazione",
  reauthenticate: "Riautenticazione",
  store: "Negozio",
  terminal: "Terminale",
  choose_terminal_to_use: "Scegli un terminale bancario",
  the_table_is_not_occupied: "Questo tavolo è libero",
  "Are you sure you want to change for a different customer? The new phone number is different to the already given one!": "Sei sicuro di voler cambiare cliente? Il nuovo numero di telefono è diverso da quello già fornito!",
  "Are you sure you want to change for a different customer? The new email is different to the already given one!": "Sei sicuro di voler cambiare cliente? La nuova email è diversa da quella già fornita!",
  "The address.city must be at least 2 characters.": "La città deve essere lunga almeno 2 caratteri.",
  "The address.address field is required.": "Il campo indirizzo è obbligatorio.",
  "The address.address must be at least 2 characters.": "L'indirizzo deve essere lungo almeno 2 caratteri.",
  "The name field is required.": "Il campo nome è obbligatorio.",
  "The address.post code field is required.": "Il campo codice postale è obbligatorio.",
  "The address.city field is required.": "Il campo città è obbligatorio.",
};
export default admin_local_en;
