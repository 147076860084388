import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { I18n, Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import { ready, clearData, reload, saveInventory, deleteInventory, addNewEntry, deleteEntry, checkEntry, setSelectedScale, findEntry, populateAllItems, setViewType, waiterAccessOnly } from "../js/admin/inventories";
import LogoutButton from "../components/icon-buttons/LogoutButton";
import AICommandBox from "../js/ai/AICommandBox";
import auth, { getMyImageUrl, setRem, scanbarcode, ajaxErrorEnd, messageDialog } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import MenuItemHeader from "../components/MenuItemHeader";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { isInventoryItem, getBarcode, getMenuItem } from "../js/admin";
import MyDateRangePicker from "../components/MyDateRangePicker";
import $ from "jquery";
import ScaleMeasure from "./ScaleMeasure";
import menuitemutil from "../js/admin/menuitem";

class Inventories extends Component {
    state = {
        initialized: false,
        startDate: moment(Number(localStorage.admin_stocklist_start)),
        endDate: moment(Number(localStorage.admin_stocklist_end)),
        scale: {
            showScaleMeasure: false
        }
    };

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {

        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id) {
            ready(this.props);
            this.setState({ initialized: true });
        }
        auth.minfontsize = 10;
        setRem();
        window.saveInventory = saveInventory;
        window.deleteInventory = deleteInventory;
        window.addNewEntry = addNewEntry;
        window.isInventoryItem = isInventoryItem;
        window.deleteEntry = deleteEntry;
        window.phoneScanBarcodeScaleMeasure = this.phoneScanBarcodeScaleMeasure;
        window.phoneScanBarcode = this.phoneScanBarcode;
        window.scaleMeasure = this.scaleMeasure2;
        window.setSelectedScale = setSelectedScale;
        window.populateAllItems = populateAllItems;
        window.setViewType = setViewType;
        window.waiterAccessOnly = waiterAccessOnly;
        window.ajaxErrorEnd = ajaxErrorEnd;
        window.increase_count = this.increase_count;
        window.decrease_count = this.decrease_count;
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            ready(this.props);
            this.setState({ initialized: true });
        }
    }

    componentWillUnmount() {
        clearData();
        this.setState({ initialized: false });
        delete window.saveInventory;
        delete window.deleteInventory;
        delete window.addNewEntry;
        delete window.deleteEntry;
        delete window.isInventoryItem;
        delete window.phoneScanBarcodeScaleMeasure;
        delete window.phoneScanBarcode;
        delete window.scaleMeasure;
        delete window.setSelectedScale;
        delete window.populateAllItems;
        delete window.setViewType;
        delete window.waiterAccessOnly;
        delete window.ajaxErrorEnd;
        delete window.increase_count;
        delete window.decrease_count;
    }

    render() {

        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id) {

            return (
                <div id="Inventories" className="stock-actions" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "100%" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.inventory" />} />
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                                <div style={{ marginLeft: "10px", width: "25px", display: "flex", justifyContent: "space-between" }}>
                                    <LogoutButton query="keep=true&redirect=/tablesOverview" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div style={{ display: "flex", flexFlow: "column", justifyItems: "center", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", flexGrow: 0, flexShrink: 0, fontSize: "20px" }}>
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                            <MyDateRangePicker startDate={this.state.startDate} endDate={this.state.endDate} onApply={this.dateRangeChanged}>
                            </MyDateRangePicker>
                        </div>
                    </div>
                    <div id="main"></div>
                    <input id="file" type="file" className="hidden"></input>
                    <ScaleMeasure {...this.state.scale} closeHandler={this.stopScaleMeasurment} resultHandler={this.updateEntryWithWeight} />
                    {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
                </div >
            );
        } else return null;
    }

    stopScaleMeasurment = () => {
        this.setState({ scale: { ...this.state.scale, show: false } })
    }

    dateRangeChanged = (event, values) => {
        this.setState({
            startDate: values.startDate,
            endDate: values.endDate
        })
        localStorage.admin_stocklist_start = values.startDate._d.getTime();
        localStorage.admin_stocklist_end = values.endDate._d.getTime();
        reload(this.state);
    }

    phoneScanBarcodeScaleMeasure = () => {
        const instance = this;
        //var barcode = "5907595452144";
        scanbarcode().done(barcode => {
            getBarcode(barcode, barcodeEntry => {
                if (barcodeEntry && barcodeEntry.menuItem) {

                    const menuItem = getMenuItem(barcodeEntry.menuItem.id);
                    var quantity = barcodeEntry.quantity;
                    //var tareWeight = barcodeEntry.tareWeight;
                    if (menuItem) {
                        switch (menuItem.quantityType) {
                            case "dl":
                                quantity /= 10;
                                break;
                            case "l":
                                quantity /= 100;
                                break;
                            case "cl":
                                break;
                        }
                        var inventoryQuantity = menuitemutil.getInventoryQuantity(menuItem, quantity);
                        var entry = findEntry(barcodeEntry.menuItem.id, inventoryQuantity);
                        if (entry.length)
                            entry = $(entry[0]);
                        else
                            entry = addNewEntry({ menuItem: { id: menuItem.id }, quantityType: menuItem.quantityType, quantity: inventoryQuantity });
                        entry.find('input.count').focus();
                        setTimeout(() => {
                            this.scaleMeasure2(entry, barcodeEntry).then(() => {
                                setTimeout(instance.phoneScanBarcodeScaleMeasure, 5000);
                            });
                        }, 200);

                    } else {
                        messageDialog(I18n.t("local.error_message"), I18n.t("admin_local.barcode_found_but_no_menuitem"));
                    }
                } else {
                    messageDialog(I18n.t("local.error_message"), I18n.t("admin_local.barcode_found_but_no_menuitem"));
                }
            }, () => {
                messageDialog(I18n.t("local.error_message"), I18n.t("admin_local.barcode_not_found"));
                auth.ajaxError = false;
            });
        });
    }

    scaleMeasure2 = (entry, barcode) => {

        return new Promise(resolve => {
            entry = $(entry.get(0));
            var menuItemId = entry.find('select.product').val();
            const menuItem = getMenuItem(menuItemId);
            var quantity = Number(entry.find('select.quantity_type').val().split(";")[0]);
            this.setState({ scale: { resolve: resolve, entry: entry, show: true, menuItemId: menuItemId, quantity: quantity, menuItem: menuItem, barcode: barcode } });



        })

    }

    updateEntryWithWeight = (weight) => {
        this.state.scale.entry.find('input.count').val(Number(this.state.scale.entry.find('input.count').data("cleave").getRawValue()) + weight);
        checkEntry({ currentTarget: this.state.scale.entry.get(0) });
        this.state.scale.resolve()
    }

    phoneScanBarcode = () => {

        const barcodef = (barcode) => {
            getBarcode(barcode, barcodeEntry => {
                if (barcodeEntry) {
                    const menuItem = getMenuItem(barcodeEntry.menuItem.id);
                    var quantity = barcodeEntry.quantity;
                    switch (menuItem.quantityType) {
                        case "dl":
                            quantity /= 10;
                            break;
                        case "l":
                            quantity /= 100;
                            break;
                        case "cl":
                            break;
                    }
                    if (menuItem) {
                        const entry = addNewEntry({ menuItem: { id: menuItem.id }, quantityType: menuItem.quantityType, quantity: menuitemutil.getInventoryQuantity(menuItem, quantity) });
                        entry.find('input.count').focus();
                        checkEntry({ currentTarget: entry });
                    }
                }
            }, () => {
                auth.ajaxError = true;
                messageDialog(I18n.t("local.error_message"), I18n.t("admin_local.barcode_not_found"));
            });
        }

        scanbarcode().done(barcode => {
            barcodef(barcode)
        })
    }

    increase_count = (target) => {
        const t = $(target).parents('tr').find('td input.input_count.count');
        t.val(Number(t.val()) + 1);
        t.trigger("change");
    }
    decrease_count = (target) => {
        const t = $(target).parents('tr').find('td input.input_count.count');
        t.val(Number(t.val()) - 1);
        t.trigger("change");
    }

}
const mapStateToProps = (props, state) => {
    if (typeof localStorage.admin_stocklist_start === "undefined") {
        localStorage.admin_stocklist_start = new Date().getTime();
        localStorage.admin_stocklist_end = new Date().getTime();
    }
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(Inventories));
