import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import AICommandBox from "../js/ai/AICommandBox";
import auth, { get, messageDialog, post, setRem } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import moment from "moment";
import { withRouter } from "react-router-dom";
import LabeledInputStyled from "../widgets/LabeledInputStyled";
import { Map as MapContainer, Marker, TileLayer, useLeaflet as useMapEvents } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { Button } from 'react-bootstrap';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const provider = new OpenStreetMapProvider();
var map;
function MyComponent({ t, position }) {
    map = useMapEvents({
        click(event) {
            t.setState({ longitude: event.latlng.lat, latitude: event.latlng.lng });
        },
    })
    if (position[0] && position[1])
        map.flyTo(position);
    return position[0] && position[1] ? (
        <Marker position={position}>
        </Marker>
    ) : null;
}

class Consent extends Component {
    state = {
        initialized: false,
        startDate: moment(Number(localStorage.admin_stocklist_start)),
        endDate: moment(Number(localStorage.admin_stocklist_end)),
    };

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {
        this.initialize();
    }


    initialize = () => {
        const instance = this;
        if (this.state.initialized)
            return;
        const { myStatus } = this.props;
        orderListUtil.props = this.props;
        if (myStatus && myStatus.restaurant_id) {
            let query = new URLSearchParams(this.props.history.location.search);
            const qrToken = query.get("q");

            if (qrToken) {
                get("orderService/" + sessionStorage.restaurantSelected + "/getTableOccupation/" + qrToken).done(occupation => {
                    const bookedemail = occupation.bookedProperties.email;
                    const mate = occupation.tableMates.find(t => t.tableMate && t.tableMate.email === bookedemail);
                    instance.setState({ occupationId: occupation.id, ...occupation.bookedProperties, approved: !!mate });
                })
            }
            auth.minfontsize = 10;
            setRem();

            this.setState({ initialized: true });
        }
    }

    componentDidUpdate = () => {
        this.initialize();
    }

    componentWillUnmount() {
        this.setState({ initialized: false });
    }

    render() {
        const { myStatus } = this.props;
        const { occupationId } = this.state;
        if (myStatus && myStatus.restaurant_id && occupationId) {

            const bookedProperties = this.state;

            return (
                <div id="Profile" className="stock-actions" style={{ display: "flex", flexFlow: "column", flexGrow: 1, overflow: "hidden" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <div style={{ fontSize: "24px" }}>{this.props.myStatus.restaurant_name}</div>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div style={{ flexGrow: "1", flexShrink: 1, display: "flex", flexFlow: "row", justifyContent: "center", alignItems: "center", "flexWrap": "wrap", overflowY: "auto", marginBottom: "10px", "height": "100%" }}>
                        <div style={{ flexBasis: "50%", flexGrow: "0", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", margin: "20px", fontSize: "1.5rem" }}>
                            <LabeledInputStyled key="2" tabindex="2" className="labeled-input table-style" id="email" value={bookedProperties.email} change={this.onChange} label={I18n.t("local.email")} type="text" />
                            <LabeledInputStyled key="3" tabindex="3" className="labeled-input table-style" id="surname" value={bookedProperties.surname} change={this.onChange} label={I18n.t("local.surname")} type="text" />
                            <LabeledInputStyled key="4" tabindex="4" className="labeled-input table-style" id="name" value={bookedProperties.name} change={this.onChange} label={I18n.t("local.first_name")} type="text" />
                            <LabeledInputStyled key="5" tabindex="5" className="labeled-input table-style" id="postcode" value={bookedProperties.postcode} change={this.onChange} label={I18n.t("local.postcode")} type="text" />
                            <LabeledInputStyled key="6" tabindex="6" className="labeled-input table-style" id="town" value={bookedProperties.town} change={this.onChange} label={I18n.t("local.town")} type="text" />
                            <LabeledInputStyled key="7" tabindex="7" className="labeled-input table-style" id="address" value={bookedProperties.address} change={this.onChange} label={I18n.t("local.address")} type="text" />
                            <LabeledInputStyled key="8" tabindex="8" className="labeled-input table-style" id="door" value={bookedProperties.door} change={this.onChange} label={I18n.t("local.door")} type="text" />
                            <LabeledInputStyled key="9" tabindex="9" className="labeled-input table-style" id="country" value={bookedProperties.country} change={this.onChange} label={I18n.t("local.country")} type="text" />
                            <LabeledInputStyled key="10" tabindex="10" className="labeled-input table-style" id="phone" value={bookedProperties.phone} change={this.onChange} label={I18n.t("local.phone")} type="text" />
                        </div>
                        <div style={{ flexShrink: 0, flexBasis: "50%", height: "max(200px,30vh)", maxWidth: "max(400px,100% - 2rem)", alignSelf: "center", flexGrow: 1, overflow: "hidden" }}>
                            <MapContainer style={{ width: "100%", height: "calc(100% - 20px)", margin: "10px" }} center={[bookedProperties.longitude ? bookedProperties.longitude : 0, bookedProperties.latitude ? bookedProperties.latitude : 0]} zoom={13} scrollWheelZoom={false} attributionControl={false} >
                                <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" id="mapbox.streets" maxZoom="18" accessToken="pk.eyJ1IjoiY3N6YXN6IiwiYSI6ImNqeHhqMTY1NDAwM2EzbW83aTJqZGlkM3MifQ.QeTnxqdNsSXLkBtBHsgkBQ" />
                                <MyComponent t={this} position={[bookedProperties.longitude ? bookedProperties.longitude : 0, bookedProperties.latitude ? bookedProperties.latitude : 0]} />
                            </MapContainer>
                        </div>
                    </div>
                    <div style={{ flexShrink: 0, display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", borderTop: "1px solid lightgray" }}>
                        {this.state.approved ?
                            <React.Fragment>
                                <FormControlLabel
                                    control={
                                        <Checkbox defaultChecked={this.state.agree} checked={this.state.agree} onChange={this.onChange} id="agree" name="agree" />
                                    } label={String.format(I18n.t('admin_local.saved_my_details_for_later_use'), myStatus.restaurant_name)} />
                                <div style={{ display: "flex" }}>
                                    <Button key="withdraw" onClick={this.withdraw}>{I18n.t('admin_local.withdraw')}</Button><div style={{ width: 20 }} />
                                    <Button key="update" onClick={this.update}>{I18n.t('admin_local.refresh_my_data')}</Button>
                                </div>
                                <a style={{ marginTop: "1rem" }} href={I18n.t('admin_local.privacy_policy_link')}>{I18n.t('admin_local.privacy_policy')}</a>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <FormControlLabel
                                    control={
                                        <Checkbox defaultChecked={this.state.agree} checked={this.state.agree} onChange={this.onChange} id="agree" name="agree" />
                                    } label={String.format(I18n.t('admin_local.save_my_details_for_later_use'), myStatus.restaurant_name)} />
                                <Button onClick={this.submit} disabled={!this.state.agree}>{I18n.t('admin_local.agree')}</Button>
                                <a style={{ marginTop: "1rem" }} href={I18n.t('admin_local.privacy_policy_link')}>{I18n.t('admin_local.privacy_policy')}</a>
                            </React.Fragment>
                        }
                    </div>

                    {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
                </div >
            );
        } else return null;
    }

    submit = () => {
        const instance = this;
        let query = new URLSearchParams(this.props.history.location.search);
        post("adminService/" + this.props.myStatus.restaurant_id + "/gdprConsent/" + query.get("q"), this.state).done(data => {
            messageDialog(I18n.t('local.success_message'), I18n.t('local.consent_successfull')).done(() => {
                instance.setState({ initialized: false });
                instance.initialize();
            });
        })
    }

    update = () => {
        const instance = this;
        let query = new URLSearchParams(this.props.history.location.search);
        post("adminService/" + this.props.myStatus.restaurant_id + "/gdprConsent/" + query.get("q"), this.state).done(data => {
            messageDialog(I18n.t('local.success_message'), I18n.t('local.update_successfull')).done(() => {
                instance.setState({ initialized: false });
                instance.initialize();
            });
        })
    }

    withdraw = () => {
        const instance = this;
        let query = new URLSearchParams(this.props.history.location.search);
        post("adminService/" + this.props.myStatus.restaurant_id + "/gdprWithdrawConsent/" + query.get("q"), this.state).done(data => {
            messageDialog(I18n.t('local.success_message'), I18n.t('local.consent_withdrawal_successfull')).done(() => {
                instance.setState({ initialized: false });
                instance.initialize();
            });
        })
    }

    onChange = async (event) => {
        const state = { ...this._state, ...this.state };
        state.focus = false;
        state[event.currentTarget.id] = event.currentTarget.value ? "" + event.currentTarget.value : event.currentTarget.checked;
        state.booking = { ...this.state.booking, changed: true };
        switch (event.currentTarget.id) {
            case "address":
            case "town":
            case "postcode":
                this.updateMap(state);
                break;
            default:
        }
        this.setState(state);
    }

    updateMap = async (state) => {
        try {
            if (this.updateMapTimeout)
                clearTimeout(this.updateMapTimeout);
            this.updateMapTimeout = setTimeout(async () => {
                this.updateMapTimeout = null;
                const results = await provider.search({ query: state.address + ", " + state.postcode + ", " + state.town });
                if (results.length > 0) {
                    this.setState({ longitude: "" + results[results.length - 1].y, latitude: "" + results[results.length - 1].x })
                    map.flyTo([results[results.length - 1].y, results[results.length - 1].x], 16);
                }
            }, 1000);
        } catch (ex) {

        }
    };

}
const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(Consent));
