import $ from "jquery";
import admin, { getMyStatus } from "../admin";
import auth, { startpoll, confirmDialog, get, getString, post } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";
import "../bankTerminals";

class BankTerminalsCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveBankTerminal', 'deleteBankTerminal', 'activateBankTerminal', 'installBankTerminalServer']);
	}

	clearData = () => {
		if (this.verify) {
			clearTimeout(this.verify);
			delete this.verify;
		}
	}

	ready = () => {

		$('#main').replaceWith($('#bankTerminalsMainTemplate').tmpl({
			...tmplparams()
		}));

		const instance = this;

		$("select#type").on("change", event => {
			console.log(event.target.value)
			if (event.target.value === "teya" && $("#id").val()) {
				$("#teya").removeClass("hidden");
				var data = instance.BankTerminals.getActiveNode().data;
				if (data.accessToken)
					$("button#authentication").text(I18n.t("admin_local.reauthenticate"));
				else
					$("button#authentication").text(I18n.t("admin_local.authenticate"));
				$("button#authentication").removeClass("hidden");
				$("img#qrcode").addClass("hidden")
			} else {
				$("#teya").addClass("hidden")
			}
			if (event.target.value === "paynance" && $("#id").val()) {
				$("#paynance").removeClass("hidden");
				var data = instance.BankTerminals.getActiveNode().data;
				$("div#paynance_authentication").removeClass("hidden");
			} else {
				$("#paynance").addClass("hidden")
			}
		})


		$("select#store").on("change", event => {
			instance.refreshTerminals();
		})

		$("button#authentication").on("click", () => {
			if (this.verify) {
				clearTimeout(this.verify);
				delete this.verify;
			}
			get("teya/" + sessionStorage.restaurantSelected + "/" + $("#id").val() + "/authorize").done(data => {
				$("img#qrcode").attr("src", data.qr_code);
				$("img#qrcode").removeClass("hidden")
				function verify() {
					get("teya/" + sessionStorage.restaurantSelected + "/" + $("#id").val() + "/verify/" + data.device_code).done(data => {
						var bankterminal = instance.BankTerminals.getActiveNode().data;
						bankterminal.accessToken = data.accessToken;
						bankterminal.refreshToken = data.refreshToken;
						$("img#qrcode").addClass("hidden");
						$("button#authentication").addClass("hidden");
						delete this.verify;
						instance.refreshStores();
					}).fail(data => {
						console.log(data);
						auth.ajaxError = false;
						this.verify = setTimeout(verify, 5000);
					});
				}
				this.verify = setTimeout(verify, 5000);
			})

		});

		$("div#paynance_authentication").on("click", () => {

			const paynanceServer = auth.myStatus.test_mode ? "https://ecr-api-tst.k8spaynance.com/api/ecr" : "https://ecr-api.paynance.hu/api/ecr";

			post(paynanceServer + "/merchant-token",
				{ merchantId: $("#paynance_merchantid").val(), password: $("#paynance_password").val() },
				undefined, undefined, undefined, undefined, undefined, false).done(data => {
					var bankterminal = instance.BankTerminals.getActiveNode().data;
					bankterminal.accessToken = data.accessToken;
					bankterminal.refreshToken = $("#paynance_merchantid").val() + "/" + $("#paynance_password").val();
					bankterminal.store = "";
					$("button#paynance_authentication").addClass("hidden");
					instance.refreshPaynanceTerminals();
				})

		});

		this.loadBankTerminals();

		startpoll(this.serverSideMessageHandler);

		this.updateHooks();


	}

	refreshStores = () => {
		const bankterminal = this.BankTerminals.getActiveNode().data;
		if (bankterminal.accessToken)
			get("teya/" + sessionStorage.restaurantSelected + "/" + bankterminal.id + "/stores").done(({ error, stores }) => {
				if (error) {

				} else {
					const s = $("select#store");
					s.empty();
					$("<option value=''>" + "</option>").appendTo(s);
					stores.forEach(store => {
						if (store.id === bankterminal.store) {
							$("<option value='" + store.id + "' selected>" + store.name + "</option>").appendTo(s);
							this.refreshTerminals();
						} else {
							$("<option value='" + store.id + "'>" + store.name + "</option>").appendTo(s);
						}
					})
				}
			}).fail(error => {
				auth.ajaxError = false;
				console.log(error);
			})
	}

	refreshTerminals = () => {
		const bankterminal = this.BankTerminals.getActiveNode().data;
		if (bankterminal.accessToken)
			get("teya/" + sessionStorage.restaurantSelected + "/" + bankterminal.id + "/" + $("select#store").val() + "/terminals").done(({ terminals }) => {
				const s = $("select#terminal");
				s.empty();
				$("<option value=''>" + "</option>").appendTo(s);
				terminals.forEach(terminal => {
					if (terminal.terminal_id === bankterminal.terminal) {
						$("<option value='" + terminal.terminal_id + "' selected>" + terminal.terminal_name + "</option>").appendTo(s);
					} else
						$("<option value='" + terminal.terminal_id + "'>" + terminal.terminal_name + "</option>").appendTo(s);
				})
			})
	}

	refreshPaynanceTerminals = () => {
		const paynanceServer = auth.myStatus.test_mode ? "https://ecr-api-tst.k8spaynance.com/api/ecr" : "https://ecr-api.paynance.hu/api/ecr";

		const bankterminal = this.BankTerminals.getActiveNode().data;
		if (bankterminal.accessToken && bankterminal.refreshToken.split("/").length > 1) {
			post(paynanceServer + "/merchant-token",
				{ merchantId: bankterminal.refreshToken.split("/")[0], password: bankterminal.refreshToken.split("/")[1] },
				undefined, undefined, undefined, undefined, undefined, false).done(data => {
					post(paynanceServer + "/devices/search", {}, {
						Authorization: "Bearer " + data.accessToken,
						accept: "application/json"
					}, undefined, undefined, undefined, undefined, false).done((terminals) => {
						const s = $("select#paynance_terminal");
						s.empty();
						$("<option value=''>" + "</option>").appendTo(s);
						terminals.forEach(terminal => {
							if (terminal.terminalId === bankterminal.terminal) {
								$("<option value='" + terminal.terminalId + "' selected>" + terminal.terminalId + "</option>").appendTo(s);
							} else
								$("<option value='" + terminal.terminalId + "'>" + terminal.terminalId + "</option>").appendTo(s);
						})
					})
				});
		}
	}

	reload = () => {
		this.loadBankTerminals();
	}

	loadBankTerminals = () => {
		admin.getBankTerminals(this.processBankTerminals);
	}

	serverSideMessageHandler(data) {
	}


	BankTerminals = null;
	processBankTerminals = (data) => {
		data.forEach(function (v, i) {
			v.title = v.name;
			v.type2 = v.type;
			v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";
			if (v.shareable)
				v.extraClasses += " itemShareable";
		});
		if (this.BankTerminals == null)
			this.BankTerminals = createTree("table#BankTerminals", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 2
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = true;

					$tdList.eq(1).text(node.data.name);
					$tdList.eq(2).text(node.data.connectedDevice);
					$tdList.eq(3).text(node.data.format);
					$tdList.eq(4).text(node.data.type2);
				}
			});
		else {
			this.BankTerminals.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editBankTerminal').on('show.bs.modal', function (event) {
			var modal = $(this)
			modal.find("#teya #terminal").val("");
			modal.find("#teya #store").val("");
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			modal.find('#payment_methods label').removeClass('active');
			modal.find('#payment_methods input').prop('checked', false);
			// attributes
			if (recipient === 'edit') {
				var data = instance.BankTerminals.getActiveNode().data;
				modal.find('#id').val(data.id);
				modal.find('#name').val(data.name);
				modal.find('#format').val(data.format);
				modal.find('#type').val(data.type2);
				if (data.isActive === true)
					modal.find('#active').prop("checked", 'true');
				else
					modal.find('#active').removeAttr("checked");
				if (data.type2 === "teya") {
					instance.refreshStores();
				} else if (data.type2 === "paynance") {
					instance.refreshPaynanceTerminals();
				}
			} else {
				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#active').prop("checked", 'true');
				modal.find('#format').val("qrcode");
				modal.find('#type').val("fiscat");
				$("#teya").addClass("hidden");
			}
			modal.find('#type').trigger("change");
		})
		const t = this;
		$('div#editBankTerminal').on('hide.bs.modal', (event) => {
			if (t.verify) {
				clearTimeout(t.verify);
				delete t.verify;
			}
		});
	}

	saveBankTerminal = () => {
		var modal = $('div#editBankTerminal');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		var format = modal.find('#format').val();
		var type = modal.find('#type').val();
		var active = modal.find('#active').prop('checked');
		var store = modal.find('#store').val();
		var terminal = type === "teya" ? modal.find('#terminal').val() : type === "paynance" ? modal.find("#paynance_terminal").val() : "";
		var data = this.BankTerminals.getActiveNode() ? this.BankTerminals.getActiveNode().data : {};
		var connectedDevice = type === "teya" ? modal.find('select#store option:selected').html() + "/" + modal.find('select#terminal option:selected').html() :
			type === "paynance" ? modal.find('select#paynance_terminal option:selected').html() : "";
		if (id !== '') {
			admin.modifyBankTerminal({
				id: id,
				name: name,
				isActive: active,
				format: format,
				type: type,
				store,
				terminal,
				connectedDevice,
				accessToken: data.accessToken,
				refreshToken: data.refreshToken
			},
				this.loadBankTerminals);
		} else {
			admin.addBankTerminal({
				name: name,
				isActive: active,
				format: format,
				type: type,
			}, this.loadBankTerminals);
		}
	}

	activateBankTerminal = (active) => {
		var data = this.BankTerminals.getActiveNode().data;
		console.log(data);
		admin.modifyBankTerminal({
			id: data.id,
			name: data.name,
			isActive: active,
			format: data.format,
			type: data.type2,
		}, this.loadBankTerminals);
	}

	deleteBankTerminal = () => {
		var data = this.BankTerminals.getActiveNode().data;
		const instance = this;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_cashregister'), data.name)).done(function () {
			admin.deleteBankTerminal(data.id, instance.loadBankTerminals);
		});

	}

}

export default BankTerminalsCode;