import { I18n } from "react-redux-i18n";
import auth from "../auth";
import { getPaymentMethod } from "../tableMatePaid";
import moment from "moment";

const TAB = "\t";

export const generate_a234 = (def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat, tip, type = "cash") => {
    var url = "";
    var a234 = {
        5: "A",
        18: "B",
        27: "C",
        s5: "A",
        s18: "B",
        s27: "C",
        undefined: "E",
        null: "E"
    };

    var id = 0;

    const prefix = "";
    var services = {};
    var count = 1;

    var totalAmount = 0;


    url = "";

    const ClerkID = "1", Password = "0", ExternalPower = "0", InvoiceType = "";

    url += "Command=ofr" + TAB + ClerkID + TAB + Password + TAB + ExternalPower + TAB + InvoiceType + "\r\n";

    Object.values(amounts).sort((a, b) => a.entryOrder - b.entryOrder).filter(category => category.amount + category.service_amount).forEach(category => {
        if (category.amount) {
            //eslint-disable-next-line no-useless-concat
            url += "Command=sbt" + TAB + TAB + category.name + TAB + TAB + TAB + TAB + TAB + a234[category.vat] + TAB + "00" + TAB + (category.amount) + TAB + category.count + TAB + "0,0" + "\r\n";
        }

        totalAmount += category.amount;
        count++;
        if (category.service_amount > 0) {
            if (!services[category.vat]) {
                services[category.vat] = 0;
            }
            services[category.vat] += category.service_amount * category.count;
            totalAmount += category.service_amount * category.count;
        }
    });

    /*
    if (Object.keys(services).length && Math.abs(payable - totalAmount) < 5) {
        services[Object.keys(services)[0]] -= totalAmount - payable;
    }*/

    var serviceUrl = "";

    Object.keys(services).forEach(key => {
        url += "Command=sbt" + TAB + TAB + (services[key] > 2 ? I18n.t("admin_local.fiscat_service_fee") + " (" + I18n.t("admin_local.vat") + " " + key + "% ÁFA)" : I18n.t("admin_local.rounding")) + TAB + TAB + TAB + TAB + TAB + a234["s" + key] + TAB + "00" + TAB + ("" + services[key]).replace(",", ".") + TAB + 1 + TAB + "0,0" + "\r\n";
        count++;
    });


    var paymentMethods = {
        "cash": "P",
        "card": "N",
        "hellopay": "N",
        "online": "N",
        "teya": "N",
        "bizpay": "N",
        "paynance": "N",
    }

    types.filter(t => t.amount > 0).forEach((t, ind) => {
        //if (ind > 0)
        //  url += "|";
        const paymentMethod = getPaymentMethod(t.type);

        var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
        var amount = t.amount;
        // eslint-disable-next-line no-eval
        //amount = eval(roundFunction.replace("amount", t.amount));

        if (t.currency === "EUR" && paymentMethod.method.type === "cash")
            url += "Command=pmt" + TAB + TAB + TAB + "F0" + TAB + Math.round(100 * t.amount) / 100 + "\r\n";
        else {
            url += "Command=pmt" + TAB + TAB + TAB + paymentMethods[paymentMethod.method.type] + TAB + amount + "\r\n";
        }
    });

    url += "Command=cfr" + TAB + "\r\n";

    if (types.filter(t => t.amount > 0).length === 0) {
        url = "";
    }

    if (tip > 0) {
        if (type === "cash")
            url += "Command=" + "ra" + TAB + "6" + TAB + TAB + "P," + tip + "|" + "COPY1" + "\r\n";
        else
            url += "Command=" + "ra" + TAB + "6" + TAB + TAB + "N," + tip + "|" + "COPY1" + "\r\n";
    }

    console.log(url);
    def.resolve(url);
}


export const dayOpen_a234 = (amount = 0) => {
    return "command=fod" + TAB + "R\r\ncommand=fod" + TAB + "P," + amount;
}

export const dayClose_a234 = () => {
    return "command=pgr" + TAB + "1" + TAB + "19\r\n";
}

export const storno_a234 = (data) => {
    return "command=sfr" + TAB + data.ap + TAB + moment().format("YYMMDD") + TAB + "F" + TAB + Number(data.receiptNumber.substring(0, 4)) + TAB + Number(data.receiptNumber.substring(5)) + TAB + data.reason + TAB + data.customerTaxNum + TAB + data.customerName + TAB + data.customerPostCode + TAB + data.customerTown + TAB + data.customerAddress + TAB + TAB + TAB + "P";
}

export const cashin_a234 = (reason, amount) => {
    return "Command=" + "ra" + TAB + reason + TAB + TAB + "N," + amount + "|" + "COPY1" + "\r\n";
}
export const cashout_a234 = (reason, amount) => {
    return "Command=" + "po" + TAB + reason + TAB + TAB + "N," + amount + "|" + "COPY1" + "\r\n";
}