import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { get, post } from "../../js/auth";

class SupersetMenu extends AbstractMenu {
  state = {
    reports: []
  };

  getDashboards = async () => {
    if (this.props?.myStatus?.restaurant_name && this.state.reports.length === 0) {
      const name = this.props.myStatus.restaurant_name;
      get("https://superset.eatwithme.online/api/v1/dashboard/?q=(filters:!((col:dashboard_title,opr:title_or_slug,value:'" + name + "')),order_column:changed_on_delta_humanized,order_direction:desc,page:0,page_size:25,select_columns:!(id,dashboard_title,published,url,slug,changed_by,changed_on_delta_humanized,owners.id,owners.first_name,owners.last_name,owners,tags.id,tags.name,tags.type,status,certified_by,certification_details,changed_on))").done(data => {
        this.setState({ reports: data.result.filter(r => r.slug.startsWith(localStorage.instance + "_")) });
      });
    }
  }

  componentDidUpdate = () => {
    this.getDashboards();
  }

  componentDidMount = () => {
    this.getDashboards();
  }

  render() {
    const { admin_local, features } = this.props;
    const background = "#847556";
    if (admin_local)
      return (
        <Switch>
          < Route path="/supersetMenu" render={props => (
            <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto" }}>
              <SubmenuHeader {...this.props} header={admin_local.menus.reports} />
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 0, flexGrow: 1 }}>
                {this.state.reports.filter(r => r).map(r =>
                  <MenuButton href={"/supersetMenu/dashboard/" + r.slug} i="i_report" background={background} name={r.name} myStatus={this.props.myStatus}>{r.dashboard_title.split(" - ")[1]}</MenuButton>
                )}
              </div>
            </div>)} />
        </Switch >
      );
    else return null;
  }
}

const mapStateToProps = state => {
  return {
    myStatus: state.rootReducer.myStatus,
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local,
    features: state.rootReducer.myStatus.restaurant_settings ? state.rootReducer.myStatus.restaurant_settings["enabled-features"] : undefined
  };
};

export default connect(mapStateToProps)(SupersetMenu);
