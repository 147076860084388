import React, { Component } from "react";
import EatWithMeText from "../../components/EatWIthMeText";
import MenuItemHeader from "../../components/MenuItemHeader";
import { Translate } from "react-redux-i18n";
import UserImageButton from "../../components/icon-buttons/UserImageButton";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import { Button } from "react-bootstrap";
import { get } from "../../js/auth";

class AI extends Component {
  state = {};
  render() {
    return (
      <div style={{ display: "flex", flexFlow: "column", flexGrow: 1 }}>
        <div
          className="menuItemHeader"
          style={{ flexShrink: 0, display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingRight: "30px" }}
        >
          <EatWithMeText />
          <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.users" />} />
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <ChangeLanguage />
            <UserImageButton />
          </div>
        </div>
        <div id="main">
          <Button onClick={this.updateAI} className="btn btn-primary">
            <Translate value="admin_local.update_ai" onClick={this.updateAI} />
          </Button>
        </div>
      </div>
    );
  }

  updateAI = () => {
    get("aiService/" + sessionStorage.restaurantSelected + "/update");
  };
}

export default AI;
