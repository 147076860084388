import { I18n } from "react-redux-i18n";
import auth from "../auth";
import { getPaymentMethod } from "../tableMatePaid";



export const generate_fiscat = (def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat, tip, tiptype) => {
    var url = "";
    var fiscat = {
        5: 1,
        18: 2,
        27: 3,
        0: 5,
        null: 4,
        undefined: 4,
        s5: serviceVat ? 6 : 1,
        s18: serviceVat ? 7 : 2,
        s27: serviceVat ? 8 : 3,
        s0: serviceVat ? 10 : 5
    };
    if (auth.myStatus.restaurant_settings.fiscat) {
        try {
            var f = auth.myStatus.restaurant_settings.fiscat.replace(/([\d\w]+):/g, '"$1":');
            function evaluateExpression(match, p1, p2, p3) {
                return eval(`${serviceVat} ? ${p2} : ${p3}`);
            }
            f = f.replace(/(serviceVat\s*\?\s*)(\d+)\s*:\s*(\d+)/g, evaluateExpression);
            fiscat = JSON.parse(f);
        } catch (ex) {
            console.log(auth.myStatus.restaurant_settings.fiscat);
            console.error(ex);
        }
    }

    var paymentMethods = {
        "cash": "P",
        "card": "N",
        "teya": "N",
        "bizpay": "N",
        "paynance": "N",
        "hellopay": "N",
        "online": "N"
    }
    const prefix = "http://www.fiscat.com/AEE|SLD|1||" + ("fiscat" === cashierType || "fiscat3" === cashierType || "fiscat4" === cashierType || "fiscat5" === cashierType ? "|" : "") + "|||";
    url = "|";
    var services = {};
    var count = 0;

    var totalAmount = 0;

    Object.values(amounts).sort((a, b) => a.entryOrder - b.entryOrder).filter(category => category.amount || category.service_amount).forEach(category => {
        if (category.amount) {
            //eslint-disable-next-line no-useless-concat
            url += category.name + " " + "|" + fiscat[category.vat] + "|" + ("" + category.amount).replace(",", ".") + "|" + category.count + "|";
            totalAmount += category.amount;
            if ("fiscat3" === cashierType)
                url += "|";
            if ("fiscat5" === cashierType) {
                url += "|";
            }
            count++;
        }
        if (category.service_amount > 0) {
            if (!services[category.vat]) {
                services[category.vat] = 0;
            }
            services[category.vat] += category.service_amount * category.count;
            totalAmount += category.service_amount * category.count;
        }
    });

    //if (Object.keys(services).length && Math.abs(payable - totalAmount) < 5) {
    //  services[Object.keys(services)[0]] -= totalAmount - payable;
    //}

    var serviceUrl = "";
    Object.keys(services).forEach(key => {
        serviceUrl += (services[key] > 2 ? I18n.t("admin_local.fiscat_service_fee") + " (" + I18n.t("admin_local.vat") + " " + key + "%)" : I18n.t("admin_local.rounding")) + "|" + fiscat["s" + key] + "|" + ("" + services[key]).replace(",", ".") + "|1|";
        if ("fiscat3" === cashierType)
            serviceUrl += "|";
        if ("fiscat5" === cashierType) {
            serviceUrl += "|";
        }

        count++;
    });

    url = prefix + count + url + serviceUrl;


    if ((typeof auth.myStatus.restaurant_settings['register-tip'] === "undefined" || auth.myStatus.restaurant_settings['register-tip'])) {
        var a = 0;
        types.forEach(t => a = a + t.amount);
        var done = false;
        types.forEach(payment => {
            if (done) return;
            const paymentMethod = getPaymentMethod(payment.type);
            if ("cash" === paymentMethod.method.type) {
                done = true;
                payment.amount = Math.max(payment.amount, Math.round(1000 * (payment.amount - (a - payable / payment.exchangeRate))) / 1000);

                if (payment.currency === auth.myStatus.restaurant_base_currency.name) {
                    var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
                    var amount = payment.amount;
                    // eslint-disable-next-line no-eval
                    payment.amount = eval(roundFunction.replace("amount", payment.amount));
                }
            }
        })
    }

    types.filter(t => t.amount > 0).forEach((t, ind) => {
        if (ind > 0)
            url += "|";
        const paymentMethod = getPaymentMethod(t.type);

        //var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
        var amount = t.amount;
        // eslint-disable-next-line no-eval
        //amount = eval(roundFunction.replace("amount", t.amount));

        if (t.currency === "EUR" && paymentMethod.method.type === "cash")
            url += "F|" + Math.round(100 * t.amount) / 100;
        else
            url += paymentMethods[paymentMethod.method.type] + "|" + amount;
        /*
      if ("fiscat3" === cashierType)
        url += "|";*/
    })


    //url += "|" + 50;
    //url += type;
    // try {
    url = url.replace(/&nbsp;/g, ' ');

    if (types.filter(t => t.amount > 0).length === 0) url = "";

    if (cashierType === "fiscat3a")
        url = url.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    //url = "http://www.fiscat.com/AEE|SLD|11|123457|5|T1|T2|T3|T4|T5|05925935859|fiscat@.com|xiamen|2||1||||DEP40|40|125|1.234||F";
    //} catch (ex) { }

    if (tip > 0) {
        if (url) url += "\r\n";
        if (tiptype === "cash")
            url += "http://www.fiscat.com/AEE|RA|07||1||LOCA|" + tip + "|";
        //else
        //  url += "http://www.fiscat.com/AEE|RA|07||1||LOCA|" + tip + "|";
    }

    console.log(url);

    def.resolve(url);
}

export const dayOpen_fiscat = (amount = 0) => {
    return "http://www.fiscat.com/AEE|OP|LOCA||" + amount;
}

export const dayClose_fiscat = () => {
    return "http://www.fiscat.com/AEE|DC|1|1|1|1";
}

export const storno_fiscat = (data) => {
    return "http://www.fiscat.com/AEE|STORNO||" + data.reason + "|" + data.receiptNumber + "|" + data.customerTaxNum + "|" + data.customerName + "|" + data.customerPostCode + "|" + data.customerTown + "|" + data.customerAddress;
}

export const cashin_fiscat = (reason, amount) => {
    return "http://www.fiscat.com/AEE|RA|" + reason + "||1||LOCA|" + amount + "|";
}

export const cashout_fiscat = (reason, amount) => {
    return "http://www.fiscat.com/AEE|RA|" + reason + "||1||LOCA|" + amount + "|";
}