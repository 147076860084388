import React from "react";
import { I18n, Translate } from "react-redux-i18n";
import AreaEditor from "../components/AreaEditor";
import { read } from "fs";
import TimeEditor from "../components/TimeEditor";
import JsonTabEditor from "../components/JsonTabEditor";
import DateEditor from "../components/DateEditor";

const states = ["cancelled", "selected", "ordered", "accepted", "producing", "ready", "delivering", "delivered"];
//const timing = [ "asap", "", "appetizer", "maincourse", "desert", "last"];
const langs = ["hu", "en", "it"];
const days = [1, 2, 3, 4, 5, 6, 7];
const payment_terms = ["cash_payment", "card_payment", "transfer_payment", "online_payment", "cash_card_payment", "cash_transfer_payment", "transfer_payment", "card_transfer_payment"];
const themes = ['', 'fancy', 'elegant', "elegant-red", "pizzahome", "zafir", "bazsalito", "basic"];


const getLangsValueUISchema = (langs) => {
  const res = {};
  langs.forEach(lang => {
    res[lang] = {
      "ui:widget": "textarea"
    }
  })
  return res;
}

const hidden = (readonly, def = undefined) => {
  return {
    "ui:widget": readonly ? "hidden" : def,
  }
}

export const restaurant_settings_schema_ui_schema = (langs, readonly = false) => {
  return {
    "default-language": hidden(readonly),
    "theme": hidden(readonly),
    timezone: hidden(readonly),
    "enabled-features": {
      "ui:ObjectFieldTemplate": JsonTabEditor,
      ntak: {
        startdate: {
          "ui:widget": DateEditor
        }
      },
      pickup: {
        "terms-and-conditions": getLangsValueUISchema(langs),
        "payment_method": {
          items: {
            terms: getLangsValueUISchema(langs)
          }
        },
        "available-timeslots": {
          items: {
            from: {
              "ui:widget": TimeEditor
            },
            to: {
              "ui:widget": TimeEditor
            }
          }
        }
      },
      homedelivery: {
        "terms-and-conditions": getLangsValueUISchema(langs),
        "payment_method": {
          items: {
            terms: getLangsValueUISchema(langs)
          }
        },
        price: {
          items: {
            area: {
              classNames: "grid-row-2 grid-column-start-0 grid-column-end-7",
              "ui:widget": AreaEditor
            }
          }
        },
        "available-timeslots": {
          items: {
            from: {
              "ui:widget": TimeEditor
            },
            to: {
              "ui:widget": TimeEditor
            }
          }
        }
      },
      ordering: {
        "privacy-policy": getLangsValueUISchema(langs),
      }
    },
    "description": hidden(readonly, getLangsValueUISchema(langs)),
    "description_header": getLangsValueUISchema(langs),
    "description_footer": getLangsValueUISchema(langs),
    drinks: {
      icon: hidden(readonly, {
        "ui:widget": "textarea"
      })
    },
    terms: hidden(readonly, {
      "ui:widget": "textarea"
    }),
    fiscat: hidden(readonly, {
      "ui:widget": "textarea"
    }),
    meals: {
      icon: hidden(readonly, {
        "ui:widget": "textarea"
      })
    },
    "tables-overview": {
      "pre-paying": hidden(readonly)
    },
    drinkbar: hidden(readonly),
    kitchen: hidden(readonly),
    production_lines: hidden(readonly),
    social: hidden(readonly),
    ai: hidden(readonly),
    roles: hidden(readonly),
  }
}

const orderListProperties = {
  states: () => {
    return {
      type: "array",
      format: "table",
      headerTemplate: "states {{ self }}",
      uniqueItems: true,
      required: true,
      items: {
        type: "string",
        enum: states,
        enumNames: I18n.t("admin_local._settings.states")
      },
      default: states
    }
  },
  statesShown: function (prop) {
    return {
      type: "array",
      format: "table",
      headerTemplate: prop + "  {{ self }}",
      uniqueItems: true,
      required: true,
      items: {
        type: "string",
        enum: states,
        enumNames: I18n.t("admin_local._settings.states")
      },
      default: states
    };
  },
  "hide-paid-state": {
    type: "boolean",
    required: true,
    default: false
  }
};

const getLangsValue = (langs) => {
  const res = {};
  langs.forEach(lang => {
    res[lang] = {
      type: "string",
      required: true,
      title: I18n.t("admin_local._settings." + lang),
    }
  })
  return res;
}

const paymentMethod = (langs, myStatus) => {
  return {
    type: "array",
    required: true,
    title: I18n.t("admin_local._settings.payment_method.title"),
    items: {
      type: "object",
      properties: {
        meth: {
          type: "number",
          title: I18n.t("admin_local._settings.payment_method.meth.title"),
          enum: myStatus.restaurant_payment_methods.map(m => { return m.method.id }),
          enumNames: myStatus.restaurant_payment_methods.map(m => { return m.method.name })
        },
        terms: {
          type: "object",
          title: I18n.t("admin_local._settings.payment_method.term.title"),
          description: I18n.t("admin_local._settings.payment_method.term.description"),
          properties: getLangsValue(langs)
        }
      }
    }
  }
}

export const featureCategories = (langs, myStatus, readonly) => {
  return {
    ntak: {
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        startdate: {
          required: true,
          type: "string",
          pattern: "\d\d\d\d-\d\d-\d\d",
          default: "2024-01-01"
        },
        status: {
          required: true,
          type: "object",
          properties: {
            connectionOK: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: false
            },
          }
        },
      }
    },
    "table-overview": {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        }
      }
    },
    login: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        separateClientBase: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        }
      }
    },
    "see-menu": {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        }
      }
    },
    appAccess: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
      }
    },
    pickup: {
      required: true,
      type: "object",
      properties: {
        "table-number": {
          type: "number",
          default: "0",
          required: true,
          readOnly: readonly,
          title: I18n.t("admin_local._settings.enabled_features.pickup.table-number.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.table-number.description"),
        },
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true,
          readOnly: readonly,
        },
        webshop: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          readOnly: readonly,
        },
        closed: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          readOnly: readonly,
        },
        instantOrderOnly: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          title: I18n.t("admin_local._settings.enabled_features.pickup.instantOrderOnly.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.instantOrderOnly.description"),
        },
        "phone_order_fee": {
          type: "number",
          title: I18n.t("admin_local._settings.enabled_features.pickup.phone_order_fee.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.phone_order_fee.description"),
        },
        "webshop_order_fee": {
          type: "number",
          title: I18n.t("admin_local._settings.enabled_features.pickup.webshop_order_fee.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.webshop_order_fee.description"),
        },
        "copy_order_email": {
          type: "array",
          format: "table",
          uniqueItems: true,
          required: true,
          items: {
            title: I18n.t("admin_local._settings.email"),
            type: "string",
          },
          default: "",
          title: I18n.t("admin_local._settings.enabled_features.pickup.copy_order_email.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.copy_order_email.description"),
        },
        send_email_on: {
          description: "send email notification for states",
          type: "array",
          format: "table",
          headerTemplate: "states {{ self }}",
          uniqueItems: true,
          required: true,
          items: {
            type: "string",
            enum: states,
            title: I18n.t("admin_local._settings.enabled_features.pickup.send_email_on.email"),
            enumNames: I18n.t("admin_local._settings.states"),
          },
          title: I18n.t("admin_local._settings.enabled_features.pickup.send_email_on.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.send_email_on.description"),
        },
        "dayends": {
          type: "number",
          default: "0",
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.pickup.dayends.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.dayends.description"),
        },
        "timeahead": {
          type: "number",
          default: "0",
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.pickup.timeahead.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.timeahead.description"),
        },
        orderedStatus: {
          type: "string",
          enum: states,
          enumNames: I18n.t("admin_local._settings.states"),
          default: "ordered",
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.pickup.orderedStatus.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.orderedStatus.description"),
        },
        "payment_method": paymentMethod(langs, myStatus),
        allowed_installments: {
          required: true,
          type: "object",
          title: I18n.t("admin_local._settings.enabled_features.pickup.allowed_installments.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.allowed_installments.description"),
          properties: {
            start: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.pickup.user_allowed_installments.start"),
            },
            by_each: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.pickup.user_allowed_installments.by_each"),
            },
            end: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.pickup.user_allowed_installments.end"),
            },
          }
        },
        user_allowed_installments: {
          required: true,
          type: "object",
          title: I18n.t("admin_local._settings.enabled_features.pickup.user_allowed_installments.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.user_allowed_installments.description"),
          properties: {
            start: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.pickup.user_allowed_installments.start"),
            },
            by_each: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.pickup.user_allowed_installments.by_each"),
            },
            end: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.pickup.user_allowed_installments.end"),
            },
          }
        },
        "datetime_adjustable": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true,
          readOnly: readonly
        },
        "available-timeslots": {
          type: "array",
          required: true,
          format: "table",
          title: I18n.t("admin_local._settings.enabled_features.pickup.available-timeslots.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.available-timeslots.description"),
          items: {
            type: "object",
            properties: {
              day: {
                type: "number",
                enum: days,
                enumNames: I18n.t("admin_local._settings.days"),
                required: true,
                title: I18n.t("admin_local._settings.day"),
              },
              from: {
                type: "number",
                required: true,
                title: I18n.t("admin_local._settings.from_hour"),
              },
              to: {
                type: "number",
                required: true,
                title: I18n.t("admin_local._settings.to_hour"),
              }
            }
          },
        },
        "terms-and-conditions": {
          type: "object",
          title: I18n.t("admin_local._settings.enabled_features.pickup.terms-and-conditions.title"),
          description: I18n.t("admin_local._settings.enabled_features.pickup.terms-and-conditions.description"),
          properties: getLangsValue(langs),
        },
        "fieldsSettings": {
          type: "string",
          readOnly: true,
        },
      }
    },
    homedelivery: {
      required: true,
      type: "object",
      properties: {
        "table-number": {
          type: "number",
          default: "0",
          required: true,
          readOnly: readonly,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.table-number.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.table-number.description"),
        },
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true,
          readOnly: readonly
        },
        webshop: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          readOnly: readonly,
        },
        courierapp: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          readOnly: readonly,
        },
        closed: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          readOnly: readonly,
        },
        "homedelivery_menuitem": {
          type: "number",
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.homedelivery_menuitem.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.homedelivery_menuitem.description"),
        },
        "phone_order_fee": {
          type: "number",
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.phone_order_fee.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.phone_order_fee.description"),
        },
        "webshop_order_fee": {
          type: "number",
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.webshop_order_fee.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.webshop_order_fee.description"),
        },
        "datetime_adjustable": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true,
          readOnly: readonly
        },
        instantOrderOnly: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.instantOrderOnly.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.instantOrderOnly.description"),
        },
        blockWaiterClose: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.blockWaiterClose.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.blockWaiterClose.description"),
        },
        printInvoiceOnOrder: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.printInvoiceOnOrder.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.printInvoiceOnOrder.description"),
        },
        "dayends": {
          type: "number",
          default: "0",
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.dayends.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.dayends.description"),
        },
        "timeahead": {
          type: "number",
          default: "0",
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.timeahead.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.timeahead.description"),
        },
        "min-duration": {
          type: "number",
          default: "2",
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.min-duration.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.min-duration.description"),
        },
        "delays": {
          type: "array",
          format: "table",
          uniqueItems: true,
          required: true,
          items: {
            type: "number",
            title: I18n.t("admin_local._settings.minute"),
          },
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.delays.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.delays.description"),
          default: [0, 20, 25, 30, 35, 40, 60],
        },
        "zip": {
          type: "string",
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.zip.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.zip.description"),
        },
        "copy_order_email": {
          type: "array",
          format: "table",
          uniqueItems: true,
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.copy_order_email.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.copy_order_email.description"),
          items: {
            title: I18n.t("admin_local._settings.email"),
            type: "string",
          },
          default: "",
        },
        send_email_on: {
          description: "send email notification for states",
          type: "array",
          format: "table",
          headerTemplate: "states {{ self }}",
          uniqueItems: true,
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.send_email_on.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.send_email_on.description"),
          items: {
            type: "string",
            enum: states,
            enumNames: I18n.t("admin_local._settings.states"),
          }
        },
        orderedStatus: {
          type: "string",
          enum: states,
          enumNames: I18n.t("admin_local._settings.states"),
          default: "ordered",
          required: true,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.orderedStatus.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.orderedStatus.description"),
        },
        "payment_method": paymentMethod(langs, myStatus),
        "available-timeslots": {
          type: "array",
          required: true,
          format: "table",
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.available-timeslots.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.available-timeslots.description"),
          items: {
            type: "object",
            properties: {
              day: {
                type: "number",
                enum: days,
                enumNames: I18n.t("admin_local._settings.days"),
                required: true,
                title: I18n.t("admin_local._settings.day"),
              },
              from: {
                type: "number",
                required: true,
                title: I18n.t("admin_local._settings.from_hour"),
              },
              to: {
                type: "number",
                required: true,
                title: I18n.t("admin_local._settings.to_hour"),
              }
            }
          }
        },
        allowed_installments: {
          required: true,
          type: "object",
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.allowed_installments.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.allowed_installments.description"),
          properties: {
            start: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.homedelivery.user_allowed_installments.start"),
            },
            by_each: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.homedelivery.user_allowed_installments.by_each"),
            },
            end: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.homedelivery.user_allowed_installments.end"),
            },
          }
        },
        user_allowed_installments: {
          required: true,
          type: "object",
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.user_allowed_installments.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.user_allowed_installments.description"),
          properties: {
            start: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.homedelivery.user_allowed_installments.start"),
            },
            by_each: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.homedelivery.user_allowed_installments.by_each"),
            },
            end: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: true,
              title: I18n.t("admin_local._settings.enabled_features.homedelivery.user_allowed_installments.end"),
            },
          }
        },
        "price": {
          type: "array",
          required: true,
          readOnly: false,
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.price.description"),
          items: {
            type: "object",
            readOnly: false,
            properties: {
              "name": {
                type: "object",
                title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.name"),
                properties: getLangsValue(langs)
              },
              "zip": {
                title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.zip"),
                type: "string"
              },
              "courierFee": {
                title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.courierFee.title"),
                description: I18n.t("admin_local._settings.enabled_features.homedelivery.price.courierFee.description"),
                type: "string"
              },
              "prices": {
                type: "array",
                readOnly: false,
                required: true,
                title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.price"),
                items: {
                  type: "object",
                  properties: {
                    enabled: {
                      required: true,
                      type: "boolean",
                      format: "checkbox",
                      default: true,
                      title: I18n.t("admin_local.active"),
                    },
                    "above_amount": {
                      required: true,
                      title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.above_amount"),
                      type: "number"
                    },
                    "price": {
                      required: true,
                      title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.price"),
                      type: "number"
                    },
                  },
                },
                default: [{ enabled: true, above_amount: 0, price: 0 }],
              },
              "except": {
                title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.except.title"),
                description: I18n.t("admin_local._settings.enabled_features.homedelivery.price.except.description"),
                type: "array",
                items: {
                  type: "string"
                }
              },
              "only": {
                title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.only.title"),
                description: I18n.t("admin_local._settings.enabled_features.homedelivery.price.only.description"),
                type: "array",
                items: {
                  type: "string"
                }
              },
              area: {
                title: I18n.t("admin_local._settings.enabled_features.homedelivery.price.area.title"),
                description: I18n.t("admin_local._settings.enabled_features.homedelivery.price.area.description"),
                type: "string",
                readOnly: false
              }
            }
          }
        },
        "terms-and-conditions": {
          type: "object",
          title: I18n.t("admin_local._settings.enabled_features.homedelivery.terms-and-conditions.title"),
          description: I18n.t("admin_local._settings.enabled_features.homedelivery.terms-and-conditions.description"),
          properties: getLangsValue(langs),
        },
        "fieldsSettings": {
          type: "string",
          readOnly: true,
        },

      }
    },
    prebooking: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          readOnly: readonly,
          default: true
        },
        defaultDuration: {
          required: true,
          type: "number",
          format: "number",
          title: I18n.t("admin_local._settings.enabled_features.prebooking.defaultDuration.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.defaultDuration.description"),
          default: 120
        },
        bookingDelay: {
          required: true,
          type: "number",
          format: "number",
          title: I18n.t("admin_local._settings.enabled_features.prebooking.bookingDelay.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.bookingDelay.description"),
          default: 0
        },
        showBefore: {
          required: true,
          type: "number",
          format: "number",
          default: 180,
          title: I18n.t("admin_local._settings.enabled_features.prebooking.showBefore.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.showBefore.description"),
        },
        maxLateDuration: {
          required: true,
          type: "number",
          format: "number",
          default: 45,
          title: I18n.t("admin_local._settings.enabled_features.prebooking.maxLateDuration.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.maxLateDuration.description"),
        },
        minBookingMinutes: {
          required: true,
          type: "number",
          format: "number",
          default: 60,
          title: I18n.t("admin_local._settings.enabled_features.prebooking.minBookingMinutes.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.minBookingMinutes.description"),
        },
        maxBookingMinutes: {
          required: true,
          type: "number",
          format: "number",
          default: 180,
          title: I18n.t("admin_local._settings.enabled_features.prebooking.maxBookingMinutes.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.maxBookingMinutes.description"),
        },
        defaultBookingMinutes: {
          required: true,
          type: "number",
          format: "number",
          default: 60,
          title: I18n.t("admin_local._settings.enabled_features.prebooking.defaultBookingMinutes.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.defaultBookingMinutes.description"),
        },
        slotMinutes: {
          required: true,
          type: "number",
          format: "number",
          default: 30,
          title: I18n.t("admin_local._settings.enabled_features.prebooking.slotMinutes.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.slotMinutes.description"),
        },
        seats: {
          type: "array",
          required: true,
          format: "table",
          title: I18n.t("admin_local._settings.enabled_features.prebooking.seats.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.seats.description"),
          default: [2, 4, 6, 8, 10],
          items: {
            type: "number"
          }
        },
        openingHours: {
          type: "array",
          required: true,
          format: "table",
          title: I18n.t("admin_local._settings.enabled_features.prebooking.openingHours.title"),
          description: I18n.t("admin_local._settings.enabled_features.prebooking.openingHours.description"),
          items: {
            type: "object",
            properties: {
              day: {
                type: "number",
                enum: days,
                enumNames: I18n.t("admin_local._settings.days"),
                required: true,
                title: I18n.t("admin_local._settings.day"),
              },
              fromday: {
                type: "string",
                format: "date",
                required: true,
                title: I18n.t("admin_local._settings.fromday"),
              },
              today: {
                type: "string",
                format: "date",
                required: true,
                title: I18n.t("admin_local._settings.today"),
              },
              slots: {
                type: "array",
                required: true,
                title: I18n.t("admin_local._settings.enabled_features.prebooking.openingHours.slots.title"),
                description: I18n.t("admin_local._settings.enabled_features.prebooking.openingHours.slots.description"),
                items: {
                  type: "object",
                  properties: {
                    from: {
                      type: "string",
                      required: true,
                      pattern: "\d\d:\d\d",
                      title: I18n.t("admin_local._settings.from_hour"),
                    },
                    to: {
                      type: "string",
                      pattern: "\d\d:\d\d",
                      required: true,
                      title: I18n.t("admin_local._settings.to_hour"),
                    }
                  }
                }
              }
            }
          }
        },
        "self-booking": {
          required: true,
          type: "object",
          properties: {
            enabled: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: false
            }
          }
        },
        "privacy-policy": {
          required: true,
          type: "string",
          readOnly: readonly
        },
        "success-link": {
          required: true,
          type: "string",
          readOnly: readonly
        },
      }
    },
    inventory: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
      }
    },
    franchize: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
      }
    },
    cashier: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
      }
    },
    occupying: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        "self-occupy": {
          type: "object",
          required: true,
          properties: {
            enabled: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: false
            },
            "label-condition": {
              required: true,
              type: "string",
              default: "",
              enum: ["", "authenticated", "paid-online"]
            }
          }
        },
        join: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        "add-person": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        "waiter-occupy": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        unlock: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        leave: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        "i-dont-pay": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        "i-pay": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        }
      }
    },
    ordering: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        withoutLogin: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        menuorder: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        menuCutOfTime: {
          required: true,
          type: "string",
          pattern: "\d\d:\d\d",
          default: "11:00"
        },
        price: {
          required: true,
          type: "string",
          format: "false",
          default: "100%"
        },
        order: {
          required: true,
          type: "object",
          properties: {
            enabled: {
              required: true,
              type: "boolean",
              format: "checkbox",
              default: false
            },
            "label-condition": {
              required: true,
              type: "string",
              default: "",
              enum: ["", "authenticated", "paid-online"]
            }
          }
        },
        "reorder-timing": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        "reorder-forwhom": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        cancel: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        "call-waiter": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        drinkOrderStatus: {
          type: "string",
          enum: states,
          enumNames: I18n.t("admin_local._settings.states"),
          default: "ordered",
          required: true
        },
        mealOrderStatus: {
          type: "string",
          enum: states,
          enumNames: I18n.t("admin_local._settings.states"),
          default: "ordered",
          required: true
        },
        barionPixel: {
          type: "string",
          default: "",
          readOnly: readonly,
          required: true
        },
        "privacy-policy": {
          type: "object",
          properties: getLangsValue(langs),
        },
        "payment_method": paymentMethod(langs, myStatus),
      }
    },
    mautic: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        client_id: {
          required: true,
          type: "string",
        },
        client_secret: {
          required: true,
          type: "string",
        }
      }
    },
    paying: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        "online-invoice": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        "ask-for-bill": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        "pay-online": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        }
      }
    },
    bi: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
      }
    },
    history: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        },
        "scan-a-bill": {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        }
      }
    },
    invoice: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          default: false
        },
        platform: {
          type: "string",
          enum: ["billingo", "szamlazz.hu"],
          default: "billingo",
        },
        billingo_api_key: {
          required: true,
          type: "string",
          default: "d6066d9e-9c60-11eb-a7b3-065a61000a6e"
        },
        szamlazz_api_key: {
          required: true,
          type: "string",
          default: "f4xdxkeid24gf4xdxks97bmaf4xdxk4uypsif4xdxk"
        }
      }
    },
    online_szamla: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          default: false
        },
        login: {
          required: true,
          type: "string",
          default: ""
        },
        password: {
          required: true,
          type: "string",
          default: ""
        },
        xml_signature: {
          required: true,
          type: "string",
          default: ""
        },
        tax_number: {
          required: true,
          type: "string",
          default: ""
        },
        towns: {
          required: true,
          type: "string",
          default: ""
        },
      }
    },
    "pos": {
      required: true,
      type: "object",
      options: {
        disable_edit_json: true
      },
      properties: {
        "customerScreenButton": {
          required: true,
          type: "object",
          properties: {
            enabled: {
              required: true,
              type: "boolean",
              default: false
            },
          }
        },
      }
    },
    wolt: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "false",
          default: false
        },
        places: {
          type: "array",
          required: true,
          format: "table",
          items: {
            type: "object",
            properties: {
              "name": {
                required: true,
                type: "string",
              },
              enabled: {
                required: true,
                type: "boolean",
                format: "false",
                default: false
              },
              price: {
                required: true,
                type: "string",
                format: "false",
                default: "100%"
              },
              test: {
                required: true,
                type: "boolean",
                format: "false",
                default: false
              },
              closed: {
                required: true,
                type: "boolean",
                format: "false",
                default: false
              },
              direct: {
                required: true,
                type: "boolean",
                format: "true",
                default: true
              },
              "restaurantId": {
                required: true,
                type: "string",
                default: "63909fbb398c49e750668f9e"
              },
              "restUser": {
                required: true,
                type: "string",
                default: "SDmenuAPiEWM"
              },
              "restPassword": {
                required: true,
                type: "string",
                default: "e98f2aabdd79d61abfa5a369b62790e5a14c628ec093a65c0179d47cfa2df3d6"
              },
              "API_KEY": {
                required: true,
                type: "string",
                default: "9i81ebVzV-7sSfo5dLAnUoiT8xsUlQZ5vrmXbZRa9xU="
              },
              "restApi": {
                required: true,
                type: "string",
                default: "https://merchant-api.creditornot.com"
              },
              "restToken": {
                required: true,
                type: "string",
                default: "9cd82ed74445bdcd8377d6e51fdbed82"
              }
            }
          }
        }
      }
    },
    foodpanda: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "false",
          default: false
        },
        places: {
          type: "array",
          required: true,
          format: "table",
          items: {
            type: "object",
            properties: {
              "name": {
                required: true,
                type: "string",
              },
              enabled: {
                required: true,
                type: "boolean",
                format: "false",
                default: true
              },
              price: {
                required: true,
                type: "string",
                format: "false",
                default: "100%"
              },
              closed: {
                required: true,
                type: "boolean",
                format: "false",
                default: true
              },
              direct: {
                required: true,
                type: "boolean",
                format: "true",
                default: true
              },
              "restApi": {
                required: true,
                type: "string",
                default: "https://integration-middleware.eu.restaurant-partners.com/"
              },
              "vendor": {
                required: true,
                type: "string",
                default: "demo"
              },
              "id": {
                required: true,
                type: "string",
                default: "demz"
              }
            }
          }
        }
      }
    },
    falatozz: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "false",
          default: false
        },
        places: {
          type: "array",
          required: true,
          format: "table",
          items: {
            type: "object",
            properties: {
              "name": {
                required: true,
                type: "string",
              },
              enabled: {
                required: true,
                type: "boolean",
                format: "false",
                default: false
              },
              price: {
                required: true,
                type: "string",
                format: "false",
                default: "100%"
              },
              test: {
                required: true,
                type: "boolean",
                format: "false",
                default: false
              },
              closed: {
                required: true,
                type: "boolean",
                format: "false",
                default: false
              },
              direct: {
                required: true,
                type: "boolean",
                format: "true",
                default: true
              },
              "restaurantId": {
                required: true,
                type: "string",
                default: ""
              },
              "menuSyncUser": {
                required: true,
                type: "string",
                default: "menusync_eatwithme"
              },
              "menuSyncPassword": {
                required: true,
                type: "string",
                default: "Jg41!07g*Sa2OBz"
              },
              "menuSyncApi": {
                required: true,
                type: "string",
                default: "https://falatozz.hu/xml-api"
              },
              "xmlApiKey": {
                required: true,
                type: "string",
                default: "TESTREST23"
              },
              "xmlApi": {
                required: true,
                type: "string",
                default: "https://falatozz.hu/api/xml/v1/"
              },
            }
          }
        }
      }
    },
    rating: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: true
        }
      }
    },
    voipphone: {
      required: true,
      type: "object",
      properties: {
        enabled: {
          required: true,
          type: "boolean",
          format: "checkbox",
          default: false
        },
        numbers: {
          type: "array",
          items: {
            type: "object",
            properties: {
              name: {
                required: true,
                type: "string"
              },
              username: {
                required: true,
                type: "string"
              },
              password: {
                required: true,
                type: "string"
              }
            }
          }
        }
      }
    }
  }
};

var localization = function (en, hu, it) {
  return {
    type: "object",
    required: true,
    format: "table",
    title: "",
    properties: {
      enabled: {
        title: I18n.t("admin_local._settings.timings.enabled.title"),
        required: true,
        type: "boolean",
        format: "checkbox",
        default: true
      },
      enabled_online: {
        title: I18n.t("admin_local._settings.timings.enabled_online.title"),
        required: true,
        type: "boolean",
        format: "checkbox",
        default: true
      },
      langs: {
        title: I18n.t("admin_local._settings.timings.langs.title"),
        type: "array",
        required: true,
        format: "table",
        items: {
          type: "object",
          properties: {
            lang: {
              title: I18n.t("admin_local._settings.timings.langs.lang.title"),
              type: "string",
              enum: langs,
              required: true
            },
            name: {
              title: I18n.t("admin_local._settings.timings.langs.lang.name"),
              type: "string",
              required: true
            },
            name_online: {
              title: I18n.t("admin_local._settings.timings.langs.lang.name_online"),
              type: "string",
              required: true
            }
          }
        }
      }
    },
    default: {
      enabled: true,
      enabled_online: true,
      langs: [
        {
          lang: "en",
          name: en,
          name_online: en
        },
        {
          lang: "hu",
          name: hu,
          name_online: hu
        },
        {
          lang: "it",
          name: it,
          name_online: it
        }
      ]
    }
  };
};

export const restaurant_settings_schema = (langs, myStatus, readonly = false) => {
  return {
    type: "object",
    headerTemplate: "settings",
    options: {
      disable_edit_json: true
    },
    properties: {
      "default-language": {
        type: "string",
        title: <Translate value="admin_local.restaurant_settings.default_language" />,
        default: "hu",
        enum: langs,
        required: true
      },
      "theme": {
        type: "string",
        default: "basic",
        enum: themes,
        required: true
      },
      "enabled-features": {
        required: true,
        type: "object",
        readOnly: readonly,
        properties: featureCategories(langs, myStatus, readonly)
      },
      "description": {
        type: "object",
        properties: getLangsValue(langs)
      },
      "description_header": {
        type: "object",
        title: I18n.t("admin_local._settings.description_header.title"),
        properties: getLangsValue(langs)
      },
      "description_footer": {
        type: "object",
        title: I18n.t("admin_local._settings.description_footer.title"),
        properties: getLangsValue(langs)
      },
      "business-day-starts-at": {
        type: "number",
        default: "6",
        required: true,
        title: I18n.t("admin_local._settings.business_day_starts_at.title"),
        description: I18n.t("admin_local._settings.business_day_starts_at.description"),
      },
      "business-day-ends-at": {
        type: "number",
        default: "0",
        required: true,
        title: I18n.t("admin_local._settings.business_day_ends_at.title"),
        description: I18n.t("admin_local._settings.business_day_ends_at.description"),
      },
      timezone: {
        type: "string",
        default: "CET",
        required: true,
        title: I18n.t("admin_local._settings.timezone.title"),
        description: I18n.t("admin_local._settings.timezone.description"),
      },
      "service-fee": {
        type: "number",
        default: "10",
        required: false,
        title: I18n.t("admin_local._settings.service_fee.title"),
        description: I18n.t("admin_local._settings.service_fee.description"),
      },
      "compulsory-service-fee": {
        type: "boolean",
        default: false,
        required: false,
        title: I18n.t("admin_local._settings.compulsory_service_fee.title"),
        description: I18n.t("admin_local._settings.compulsory_service_fee.description"),
      },
      "compulsory-service-fee-for-pos": {
        type: "boolean",
        default: false,
        required: false,
        title: I18n.t("admin_local._settings.compulsory_service_fee_for_pos.title"),
        description: I18n.t("admin_local._settings.compulsory_service_fee_for_pos.description"),
      },
      "serviceVat": {
        type: "boolean",
        default: false,
        title: I18n.t("admin_local._settings.serviceVat.title"),
        description: I18n.t("admin_local._settings.serviceVat.description"),
      },
      "register-tip": {
        description: "If checked the received amount includes the tip, otherwise the received amount and the differene is the returning money to the customer.",
        required: true,
        type: "boolean",
        default: true,
        title: I18n.t("admin_local._settings.register_tip.title"),
        description: I18n.t("admin_local._settings.register_tip.description"),
      },
      "terms": {
        type: "string",
        default: "",
        required: true,
      },
      "fiscat": {
        type: "string",
        default: "",
        required: true,
        readOnly: readonly,
      },
      "printing": {
        type: "object",
        title: I18n.t("admin_local._settings.printing.title"),
        properties: {
          "invoice": {
            type: "object",
            title: I18n.t("admin_local._settings.printing.invoice.title"),
            properties: {
              "showOrderNumber": {
                type: "boolean",
                default: false,
                required: true,
                title: I18n.t("admin_local._settings.printing.showOrderNumber.title"),
                description: I18n.t("admin_local._settings.printing.showOrderNumber.description"),
              },
              "showAllComments": {
                type: "boolean",
                default: false,
                required: true,
                title: I18n.t("admin_local._settings.printing.showAllComments.title"),
                description: I18n.t("admin_local._settings.printing.showAllComments.description"),
              }
            }
          }
        }
      },
      drinks: {
        type: "object",
        title: I18n.t("admin_local._settings.drinks.title"),
        properties: {
          enabled: {
            required: true,
            type: "boolean",
            format: "checkbox",
            default: true,
            title: I18n.t("admin_local._settings.drinks.enabled.title"),
          },
          default: {
            required: true,
            type: "boolean",
            format: "checkbox",
            default: true,
            title: I18n.t("admin_local._settings.drinks.default.title"),
            description: I18n.t("admin_local._settings.drinks.default.description"),
          },
          "label": {
            type: "object",
            properties: getLangsValue(langs),
            title: I18n.t("admin_local._settings.drinks.label.title"),
          },
          icon: {
            required: true,
            type: "string",
            default: ""
          },
        }
      },
      meals: {
        type: "object",
        title: I18n.t("admin_local._settings.meals.title"),
        properties: {
          enabled: {
            required: true,
            type: "boolean",
            format: "checkbox",
            default: true,
            title: I18n.t("admin_local._settings.meals.enabled.title"),
          },
          default: {
            required: true,
            type: "boolean",
            format: "checkbox",
            default: false,
            title: I18n.t("admin_local._settings.meals.default.title"),
            description: I18n.t("admin_local._settings.meals.default.description"),
          },
          "label": {
            type: "object",
            properties: getLangsValue(langs),
            title: I18n.t("admin_local._settings.meals.label.title"),
          },
          icon: {
            required: true,
            type: "string",
            default: ""
          },
        }
      },
      menu: {
        type: "object",
        required: true,
        title: I18n.t("admin_local._settings.menu.title"),
        properties: {
          "show-category-image": {
            type: "boolean",
            default: true,
            required: true,
            title: I18n.t("admin_local._settings.menu.show_category_image.title"),
          },
          "show-menuitem-image": {
            type: "boolean",
            default: true,
            required: true,
            title: I18n.t("admin_local._settings.menu.show_menuitem_image.title"),
          }
        }
      },
      takeaway: {
        type: "object",
        required: true,
        title: I18n.t("admin_local._settings.takeaway.title"),
        properties: {
          enabled: {
            type: "boolean",
            default: true,
            required: true,
            title: I18n.t("admin_local._settings.takeaway.enabled.title"),
            description: I18n.t("admin_local._settings.takeaway.enabled.description"),
          },
          default: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.takeaway.default.title"),
            description: I18n.t("admin_local._settings.takeaway.default.description"),
          },
          vat: {
            title: I18n.t("admin_local._settings.takeaway.vat.title"),
            description: I18n.t("admin_local._settings.takeaway.vat.description"),
            type: "number",
            default: "27",
            required: true,
            readOnly: readonly,
          },
          price: {
            title: I18n.t("admin_local._settings.takeaway.price.title"),
            description: I18n.t("admin_local._settings.takeaway.price.description"),
            //description: "Set to true if you want that takeaway prices can be set separately",
            type: "boolean",
            default: false,
            required: true
          },
          containerChargeItem: {
            title: I18n.t("admin_local._settings.takeaway.containerChargeItem.title"),
            type: "number",
            required: true,
            readOnly: readonly
          },
          systemFeeItem: {
            title: I18n.t("admin_local._settings.takeaway.systemFeeItem.title"),
            type: "number",
            required: true,
            readOnly: readonly
          },
          packageChargeItem: {
            title: I18n.t("admin_local._settings.takeaway.packageChargeItem.title"),
            type: "number",
            required: true,
            readOnly: readonly
          }
        }
      },
      timings: {
        type: "object",
        required: true,
        title: I18n.t("admin_local._settings.timings.title"),
        properties: {
          asap: localization("Asap", "Azonnal", "Il prima possibile"),
          appetizer: localization("Appetizer", "Előétel", "Antipasto"),
          maincourse: localization("Main course", "Főétel", "Secondo piatto"),
          desert: localization("Dessert", "Desszert", "Dessert"),
          last: localization("Last", "A végén", "Alla fine")
        }
      },
      "price-calculations": {
        type: "object",
        title: I18n.t("admin_local._settings.price_calculations.title"),
        properties: {
          rounding: {
            type: "number",
            default: "1",
            required: true,
            title: I18n.t("admin_local._settings.price_calculations.rounding.title"),
            description: I18n.t("admin_local._settings.price_calculations.rounding.description"),
          },
          "lower-price": {
            type: "object",
            title: I18n.t("admin_local._settings.price_calculations.lower_price.title"),
            description: I18n.t("admin_local._settings.price_calculations.lower_price.description"),
            properties: {
              above: {
                type: "number",
                default: "0",
                required: true,
                title: I18n.t("admin_local._settings.price_calculations.lower_price.above.title"),
                description: I18n.t("admin_local._settings.price_calculations.lower_price.above.description"),
              },
              measure: {
                type: "string",
                enum: ["percent", "amount"],
                default: "percent",
                required: true,
                title: I18n.t("admin_local._settings.price_calculations.lower_price.measure.title"),
                description: I18n.t("admin_local._settings.price_calculations.lower_price.measure.description"),
              }
            }
          },
          "increase-price": {
            type: "object",
            title: I18n.t("admin_local._settings.price_calculations.increase_price.title"),
            properties: {
              above: {
                type: "number",
                default: "0",
                required: true,
                title: I18n.t("admin_local._settings.price_calculations.increase_price.above.title"),
                description: I18n.t("admin_local._settings.price_calculations.increase_price.above.description"),
              },
              measure: {
                type: "string",
                enum: ["percent", "amount"],
                default: "percent",
                required: true,
                title: I18n.t("admin_local._settings.price_calculations.increase_price.measure.title"),
                description: I18n.t("admin_local._settings.price_calculations.increase_price.above.description"),
              }
            }
          }
        }
      },
      "tables-overview": {
        title: I18n.t("admin_local._settings.tables_overview.title"),
        required: true,
        type: "object",
        options: {
          disable_edit_json: true
        },
        headerTemplate: "tables-overview",
        properties: {

          autolock: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.autolock.title"),
            description: I18n.t("admin_local._settings.tables-overview.autolock.description"),
          },
          timeout: {
            type: "number",
            default: "20",
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.timeout.title"),
            description: I18n.t("admin_local._settings.tables-overview.timeout.description"),
          },
          askPersonCount: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.askPersonCount.title"),
            description: I18n.t("admin_local._settings.tables-overview.askPersonCount.description"),
          },
          "show-category-image": {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.hideMenuCategoryImage.title"),
            description: I18n.t("admin_local._settings.tables-overview.hideMenuCategoryImage.description"),
          },
          "show-menuitem-image": {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.hideMenuItemImage.title"),
            description: I18n.t("admin_local._settings.tables-overview.hideMenuItemImage.description"),
          },
          bigOrderNumber: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.bigOrderNumber.title"),
            description: I18n.t("admin_local._settings.tables-overview.bigOrderNumber.description"),
          },
          perPersonKitchenReceipt: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.perPersonKitchenReceipt.title"),
            description: I18n.t("admin_local._settings.tables-overview.perPersonKitchenReceipt.description"),
          },
          bigAdditions: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.bigAdditions.title"),
            description: I18n.t("admin_local._settings.tables-overview.bigAdditions.description"),
          },
          "kitchenQrCode": {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.kitchenQrCode.title"),
            description: I18n.t("admin_local._settings.tables-overview.kitchenQrCode.description"),
          },
          autoDeliverOnUnlock: {
            type: "boolean",
            default: true,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.autoDeliverOnUnlock.title"),
            description: I18n.t("admin_local._settings.tables-overview.autoDeliverOnUnlock.description"),
          },
          unlockOnPayment: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.unlockOnPayment.title"),
            description: I18n.t("admin_local._settings.tables-overview.unlockOnPayment.description"),
          },
          lessKioskButtons: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.lessKioskButtons.title"),
            description: I18n.t("admin_local._settings.tables-overview.lessKioskButtons.description"),
          },
          print_accepted_customer_orders: {
            type: "boolean",
            default: true,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.print_accepted_customer_orders.title"),
            description: I18n.t("admin_local._settings.tables-overview.print_accepted_customer_orders.description"),
          },
          delayed_orders_browser: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.delayed_orders_browser.title"),
            description: I18n.t("admin_local._settings.tables-overview.delayed_orders_browser.description"),
          },
          delayed_orders_mobile: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.delayed_orders_mobile.title"),
            description: I18n.t("admin_local._settings.tables-overview.delayed_orders_mobile.description"),
          },
          pos_delayed_orders_browser: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.pos_delayed_orders_browser.title"),
            description: I18n.t("admin_local._settings.tables-overview.pos_delayed_orders_browser.description"),
          },
          pos_delayed_orders_mobile: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.pos_delayed_orders_mobile.title"),
            description: I18n.t("admin_local._settings.tables-overview.pos_delayed_orders_mobile.description"),
          },
          always_allow_delete: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.always_allow_delete.title"),
            description: I18n.t("admin_local._settings.tables-overview.always_allow_delete.description"),
          },
          block_waiter_print_invoice: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.block_waiter_print_invoice.title"),
            description: I18n.t("admin_local._settings.tables-overview.block_waiter_print_invoice.description"),
          },
          block_waiter_register_payment: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.block_waiter_register_payment.title"),
            description: I18n.t("admin_local._settings.tables-overview.block_waiter_register_payment.description"),
          },
          block_waiter_print_daily_closing: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.block_waiter_print_daily_closing.title"),
            description: I18n.t("admin_local._settings.tables-overview.block_waiter_print_daily_closing.description"),
          },
          waiter_print_daily_closing_full: {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.waiter_print_daily_closing_full.title"),
            description: I18n.t("admin_local._settings.tables-overview.waiter_print_daily_closing_full.description"),
          },
          drinkOrderStatus: {
            type: "string",
            enum: states,
            enumNames: I18n.t("admin_local._settings.states"),
            default: "accepted",
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.drinkOrderStatus.title"),
            description: I18n.t("admin_local._settings.tables-overview.drinkOrderStatus.description"),
          },
          mealOrderStatus: {
            type: "string",
            enum: states,
            enumNames: I18n.t("admin_local._settings.states"),
            default: "accepted",
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.mealOrderStatus.title"),
            description: I18n.t("admin_local._settings.tables-overview.mealOrderStatus.description"),
          },
          "table-qrcode": {
            type: "string",
            enum: ["none", "pay-only", "join"],
            enumNames: I18n.t("admin_local._settings.tables-overview.table-qrcode.enumNames"),
            default: "join",
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.table-qrcode.title"),
            description: I18n.t("admin_local._settings.tables-overview.table-qrcode.description"),
          },
          "auto-table-qrcode": {
            type: "boolean",
            default: false,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.auto-table-qrcode.title"),
            description: I18n.t("admin_local._settings.tables-overview.auto-table-qrcode.description"),
          },
          "notify-waiter-when-part-ready": {
            type: "boolean",
            default: true,
            required: true,
            title: I18n.t("admin_local._settings.tables-overview.notify-waiter-when-part-ready.title"),
            description: I18n.t("admin_local._settings.tables-overview.notify-waiter-when-part-ready.description"),
          },
          "pre-paying": {
            type: "object",
            readOnly: readonly,
            properties: {
              "print-invoice": {
                type: "boolean",
                default: true
              }
            }
          },
          states: {
            ...orderListProperties.states(),
            readOnly: readonly,
            title: I18n.t("admin_local._settings.tables-overview.states.title"),
            description: I18n.t("admin_local._settings.tables-overview.states.description"),
          },
          statesShown: {
            ...orderListProperties.states(),
            title: I18n.t("admin_local._settings.tables-overview.statesShown.title"),
            description: I18n.t("admin_local._settings.tables-overview.statesShown.description"),
            default: ["cancelled", "accepted", "delivered"]
          },
          newOrdersStatesShown: {
            ...orderListProperties.states(),
            title: I18n.t("admin_local._settings.tables-overview.newOrdersStatesShown.title"),
            description: I18n.t("admin_local._settings.tables-overview.newOrdersStatesShown.description"),
            default: ["selected"]
          },
          newOrdersKioskStatesShown: {
            ...orderListProperties.states(),
            title: I18n.t("admin_local._settings.tables-overview.newOrdersKioskStatesShown.title"),
            description: I18n.t("admin_local._settings.tables-overview.newOrdersKioskStatesShown.description"),
            default: ["selected", "accepted", "delivered"]
          },

        },
        defaultProperties: ["states", "statesShown", "timeout", "table-qrcode"],
        additionalProperties: false

      },
      drinkbar: {
        required: true,
        type: "object",
        options: {
          disable_edit_json: true
        },
        headerTemplate: "drinkbar",
        properties: {
          states: orderListProperties.states,
          statesShown: orderListProperties.statesShown("statesShown"),
          "hide-paid-state": orderListProperties["hide-paid-state"],
          showDeliveredTimeout: {
            type: "number",
            default: "60",
            required: true
          }
        },
        defaultProperties: ["showDeliveredTimeout", "states", "statesShown", "hide-paid-state"],
        additionalProperties: false
      },
      kitchen: {
        required: true,
        type: "object",
        options: {
          disable_edit_json: true
        },
        headerTemplate: "kitchen",
        properties: {
          states: orderListProperties.states,
          statesShown: orderListProperties.statesShown("statesShown"),
          "hide-paid-state": orderListProperties["hide-paid-state"],
          showDeliveredTimeout: {
            type: "number",
            default: "60",
            required: true
          }
        },
        defaultProperties: ["states", "statesShown", "hide-paid-state", "showDeliveredTimeout"],
        additionalProperties: false
      },
      production_lines: {
        required: true,
        type: "object",
        options: {
          disable_edit_json: true
        },
        properties: {
          stateTransitions: {
            type: "object",
            properties: {
              left: {
                ...orderListProperties.states(),
                default: ["delivering", "cancelled"],
              },
              middle: {
                ...orderListProperties.states(),
                default: ["producing"],
              },
              right: {
                ...orderListProperties.states(),
                default: ["accepted"],
              },
              delivered: {
                type: "object",
                properties: {
                  left: {
                    ...orderListProperties.states(),
                    default: [],
                  },
                  right: {
                    ...orderListProperties.states(),
                    default: ["delivered"],
                  },
                }
              },
              delivering: {
                type: "object",
                properties: {
                  left: {
                    ...orderListProperties.states(),
                    default: [],
                  },
                  right: {
                    ...orderListProperties.states(),
                    default: ["producing"],
                  },
                }
              },
              ready: {
                type: "object",
                properties: {
                  left: {
                    ...orderListProperties.states(),
                    default: [],
                  },
                  right: {
                    ...orderListProperties.states(),
                    default: ["producing"],
                  },
                }
              },
              cancelled: {
                type: "object",
                properties: {
                  left: {
                    ...orderListProperties.states(),
                    default: [],
                  },
                  right: {
                    ...orderListProperties.states(),
                    default: ["producing"],
                  },
                }
              },
              producing: {
                type: "object",
                properties: {
                  left: {
                    ...orderListProperties.states(),
                    default: ["ready", "cancelled"],
                  },
                  right: {
                    ...orderListProperties.states(),
                    default: ["accepted"],
                  },
                }
              },
              accepted: {
                type: "object",
                properties: {
                  left: {
                    ...orderListProperties.states(),
                    default: ["producing"],
                  },
                  right: {
                    ...orderListProperties.states(),
                    default: [],
                  },
                }
              }
            }
          }
        },
        defaultProperties: ["states", "statesShown", "hide-paid-state", "showDeliveredTimeout"],
        additionalProperties: false
      },
      social: {
        type: "object",
        required: true,
        properties: {
          facebook: {
            type: "object",
            required: true,
            properties: {
              enabled: {
                type: "boolean",
                default: false,
                required: true
              },
              facebookpage: {
                type: "string",
                required: true
              },
              accesstoken: {
                type: "string",
                required: true
              }
            },
          },
          google: {
            type: "object",
            required: true,
            properties: {
              enabled: {
                type: "boolean",
                default: false,
                required: true
              },
              embed: {
                type: "string",
                required: true
              }
            }
          }
        }
      },
      ai: {
        required: true,
        type: "object",
        properties: {
          enabled: {
            type: "boolean",
            default: false,
            required: true
          },
          witai: {
            required: true,
            type: "object",
            properties: {
              enabled: {
                type: "boolean",
                default: false,
                required: true
              },
              accesstokenhu: {
                type: "string",
                required: true
              },
              accesstokenen: {
                type: "string",
                required: true
              },
              accesstokenit: {
                type: "string",
                required: true
              }
            }
          }
        }
      },
      roles: {
        required: true,
        type: "object",
        properties: {
          waiter: {
            required: true,
            type: "object",
            properties: {
              delete: {
                type: "boolean",
                default: false,
                required: true
              }
            }
          }
        }
      }
    },
    defaultProperties: ["business-day-starts-at", "tables-overview", "drinkbar", "kitchen"],
    additionalProperties: false
  }
};
