const local_cn = {
  ml: "毫升",
  dl: "分升",
  cl: "厘升",
  l: "升",
  pcs: "件",
  g: "克",
  dkg: "十克",
  kg: "千克",
  none: "无",
  day: "天",
  hour: "小时",
  min: "分钟",
  sign_up: "注册",
  login: "登录",
  first_name: "名字",
  provide_first_name: "请提供您的名字",
  surname: "姓氏",
  family_name: "姓氏",
  provide_surname: "请提供您的姓氏",
  email: "邮箱地址",
  provide_email: "请提供您的邮箱地址",
  password: "密码",
  quick_access_code: "快速代码",
  require_quick_access_code: "必填",
  provide_password: "请提供您的密码",
  forgot_password: "忘记密码？",
  need_sign_up: "需要注册？",
  registration: "注册",
  register: "注册",
  back_to_login: "返回登录",
  social_login: "或使用已有账户登录",
  email_already_in_use: "该邮箱已被占用！",
  activation_email_sent: "激活邮件已发送到 {0}。请检查您的邮箱（包括垃圾邮件文件夹）！",
  activation_email_sent2: "激活邮件已发送到 {0}。请检查您的邮箱（包括垃圾邮件文件夹）！",
  password_reset_email_sent: "密码重置邮件已成功发送至 {0}！",
  password_reset_email_sent2: "密码重置邮件已成功发送至 %{email}！",
  confirm_account_deletion: "您确定要删除您的账户吗？",
  account_deleted_successfully: "您的账户已成功删除！",
  bad_password_request_token: "密码重置令牌无效或已过期！请重新申请。",
  password_reset_successfully: "新密码设置成功！",
  missing_role: "访问被拒绝！您没有适当的权限！",
  password_forgotten_text: "请输入您注册时的邮箱地址。我们将向您发送一封允许您设置新密码的邮件。",
  register_first: "访问被拒绝！您没有适当的权限！请先注册。",

  login_failed: "登录失败！邮箱或密码错误！",
  communication_error: "与服务器通信错误（错误代码：{0}）！",

  join: "加入",
  occupy: "占用",
  ok: "确定",
  cancel: "取消",
  apply: "应用",

  get_a_table: "选择桌子",
  locked_get_a_table_one_table: "桌子: {0}\n人数: {2}",
  locked_get_a_table_more_tables: "桌子: {0} 张\n人数: {2}",
  joined_get_a_table: "已加入\n桌子: {0}",
  joined_not_authorized_get_a_table: "等待批准加入",
  let_other_to_join_your_table: "让其他人加入您的桌子",

  the_lead_customer_cannot_be_unapproved: "组织者的加入状态无法更改！",

  confirmation: "确认",
  warning: "警告",
  do_you_want_to_be_the_organizer: "您真的想成为组织者吗？",
  approvedBy: "{0} 批准了",
  paidBy: "{0} 支付了",
  waiting_for_approval_from_somebody: "等待批准！",
  your_mate_is_not_intending_to_pay_his_bill: "您的桌友不打算支付并未邀请任何人！",
  your_mate_intends_to_pay_his_bill: "您的桌友愿意支付，但不介意被邀请！",
  your_mate_wants_to_pay: "您的桌友想支付并拒绝被邀请",
  shared_table_tooltip: "未被占用的座位可能会被他人占用！",
  request_from: "{0} 请求加入！",
  message_from: "{0} 的信息：",
  error_message: "错误信息",
  warning_message: "警告信息",
  question: "问题",
  suggestion: "建议",
  information: "信息",
  success_message: "成功执行",
  organizer_request_denied: "不允许您成为组织者！",
  organizer_request: "请允许我成为组织者！",
  organizer_confirmation: "您现在是组织者！",
  let_me_pay: "我来支付",
  approve_joining: "批准加入",
  reject_joining: "拒绝加入",
  reject_paying: "我不会为此支付",
  i_am_paying: "我来支付",
  does_not_agree_invite: "{0} 不允许被邀请！",
  do_you_want_to_leave: "您真的想离开这张桌子吗？",
  lock_another_table: "再占一张桌子",
  unlock_table: "释放桌子",
  scanToAddTable: "再占一张桌子",
  scanToChangeTable: "换到这张桌子",
  somebody: "某人",
  sending: "发送密码重置邮件",
  password_reset: "保存新密码",
  ask_password_reset: "设置新密码",
  password_forgotten: "忘记密码",
  message_already_joined: "您已经加入了这张桌子！",
  message_nobody_to_join: "这张桌子没有人可以加入！",
  message_nobody_allows_joining: "目前没有人允许加入这张桌子！",
  message_do_you_want_to_switch_table: "您已经加入了 {0} 号桌！您想换到 {1} 号桌吗？",
  message_do_you_want_: "这张桌子已由 {0} 预订，需要批准才能加入！您想加入吗？",
  message_do_you_want_to_join_table_without_approval: "这张桌子已预订，但可以无需批准直接加入！您想加入吗？",
  message_do_you_want_to_join_table_with_approval: "这张桌子已预订，仅可通过批准加入！您想加入吗？",
  massage_you_do_not_want_to_pay: "根据设置，您不想支付！如果您想支付，请更改设置！",
  message_do_you_really_want_to_change_table: "您确定要将您的同桌 {0} 移动到 {1} 号桌吗？",
  message_do_you_really_want_to_occupy_another_table: "您已经为 {1} 人占用了 {0} 张桌子！您确定还要为 {2} 人占用另一张桌子吗？",
  message_you_can_join_only_one_company: "您已经加入了一张桌子，只能转到同一公司预订的桌子！",
  choose_between_joining_or_occupying_a_table: "您是想预订这张桌子，还是加入已经坐在这里的团体？",
  do_you_really_want_to_occupy_this_shareable_table: "这张桌子不允许独占预订！您确定要预订这张桌子吗？",
  this_table_is_occupied: "这张桌子已被占用！",
  this_table_is_occupied_by_multiple_parties_join_directly: "这张桌子已被多个团体占用！请扫描其中一位已加入人员手机上的二维码，以确定您想加入的团体！",
  would_you_create_a_picture_as_well: "请为其拍一张照片！这样以后更容易识别！",
  message_do_you_really_want_to_unlock_table: "您确定要解锁这张桌子吗？",
  message_do_you_really_want_to_leave_the_table: "您确定要离开这张桌子吗？",
  message_do_you_really_want_to_unlock_table_not_yourself: "您确定要解锁这张桌子吗？",
  are_you_sure_you_want_to_pay_for_yourself: "您确定要代替 {0} 支付自己的消费吗？",
  are_you_sure_you_want_to_pay_instead: "您确定要代替 {0} 支付 {1} 的消费吗？",
  are_you_sure_you_want_to_pay_instead_and_more: "您确定要代替 {0} 支付 {1} 和另外 {2} 个人的消费吗？",
  reject_invite: "{0} 不允许您支付他们的消费！",


  qr_code_generation_failed: "无法读取二维码！要再试一次吗？",

  leave_table: "离开桌子",
  leave_the_table: "离开桌子",
  message_table_join_cancelled: "您已成功离开桌子！",
  message_subprofile_join_cancelled: "{0} 已成功从参与者中移除！",
  profileUpdatedSuccessfully: "个人资料已成功保存！",

  new_subprofile: "添加新子配置文件（例如您的孩子）",
  profile_picture: "个人&nbsp;头像",
  my_favorites: "我的收藏",
  favorite_of: "我是&nbsp;某人的收藏",
  subprofiles: "我的子配置文件",
  subprofile_tooltip:
    "您可以为没有电子邮件地址或目前无法访问电子邮件账户的人（例如您的孩子或父母）创建配置文件！<br/><br/>这样，您可以单独为他们下单，而无需他们注册到系统中！",
  my_favorites_tooltip: "您<b>确认的</b>收藏可以随时加入您的桌子而无需对方同意！同样，他们也可以在无需您允许的情况下加入您的桌子！",
  favorite_of_tooltip: "那些<b>确认</b>您为收藏的人可以随时将您加入他们的桌子而无需您的同意！同样，您也可以在无需他们允许的情况下加入他们的桌子！",

  couldNotFindCustomer: "找不到与此电子邮件地址关联的用户！",
  subprofileAlreadyUsedAsTableMate: "{0} 已经参与了一个活动！您确定要删除吗？",

  favorite_approved_tooltip: "已批准",
  favorite_pending_tooltip: "等待批准",
  placeholder_add_an_email: "请输入电子邮件地址...",
  placeholder_add_a_name: "请输入一个名字...",
  subprofileAlreadyExists: "子配置文件已存在！",
  subprofile: "子配置文件",
  name: "名称",
  do_you_really_want_to_delete_your_favoriteOf: "<b>{0}</b> 已将您标记为收藏！您确定要删除吗？",
  do_you_really_want_to_delete_your_favorite: "您确定要将 <b>{0}</b> 从您的收藏中移除吗？",
  do_you_really_want_to_delete_your_subprofile: "您确定要删除子配置文件 <b>{0}</b> 吗？",
  do_you_really_want_to_unjoin_your_subprofile: "您确定要将 <b>{0}</b> 从参与者中移除吗？",

  thisEmailIsAlreadyBoundWithAnotherAccount: "此电子邮件地址已绑定到另一个账户！",

  people_tab: "桌友",
  tables_tab: "我的桌子",
  table_tab: "我的桌子",
  settings_tab: "设置",

  paying: "付款人",
  wants_to_pay: "想要支付",
  i_want_to_pay: "我来支付",
  i_do_not_pay: "我不支付",
  i_could_pay: "我可以支付",
  i_will_pay: "我将支付",
  i_am_invited: "我被邀请",

  paid_for: "我的桌友",
  i_pay_for: "我支付他们的消费",
  others: "其他人",
  tableMates: "未决定谁支付他们的消费",
  joiningTableMates: "想要加入的人",
  your_joined_tables: "我加入的桌子",
  your_tables: "我的桌子",

  add_sub_profile: "添加子配置文件",
  add_participant: "添加新参与者",
  close: "关闭",
  scan_to_join_table: "{0} 号桌。扫描二维码以加入！",
  add_additional_participants_as_subprofiles: "子配置文件",
  add_additional_participants_without_table: "没有桌子的参与者",
  add_mate: "添加桌友",

  table_picker_title: "选择一张桌子！",
  my_table_picker: "{0} 号桌（我在这里）",
  table_picker: "{0} 号桌",
  my_mate_picker: "{0}（我）",
  mate_picker: "{0}",
  everybody: "所有人（{0}）",
  allTables: "所有桌子",
  i_pay_for2: "我支付（{0}）",


  order: "订单",
  orderMeals: "食物",
  orderDrinks: "饮料",
  unknown: "???",
  byCustomer: "按每人",
  byDrinkOrMeal: "按饮料/食物",
  byDrink: "按饮料",
  byMeal: "按食物",

  do_you_really_want_to_delete_all_new_orders: "您确定要清空购物车吗？",
  do_you_really_want_to_delete_all_orders: "您确定要删除所有未确认的选中项吗？",

  please_choose_mate_for_this_order: "{0} {1} {2} - 您要为谁点餐？",
  select: "选择",

  order_items: "数量：",
  people_count: "人数：",

  do_you_really_want_to_submit_all_new_orders: "您确定要下单所有选中的项目吗？",

  show_table_qr: "显示桌子二维码",

  seats_wanted: "需要多少个座位：",

  placeholder: "预定位置",
  my_profile: "我的个人资料",

  unlocking_not_possible_there_are_pending_orders: "在未支付订单的情况下，无法释放桌子！",
  unjoining_somebody_not_possible_there_are_pending_orders: "在有待处理的订单时，不能离开桌子！",
  unjoining_yourself_not_possible_there_are_pending_orders: "在有待处理的订单时，您不能离开桌子！",
  unjoining_somebody_not_possible_paying_for_others: "不能离开桌子，因为他正在为其他人付款！",

  comment: "备注",
  enter_comment: "输入备注（可选）",
  this_item_was_originally_ordered_by: "此项原本由 {0} 点餐！",
  this_item_was_ordered_by: "此项由 {0} 选择！",
  cancelled: "已取消",
  selected: "已选",
  ordered: "已订购",
  accepted: "已接受",
  producing: "制作中",
  ready: "已完成",
  delivering: "配送中",
  delivered: "已配送",
  paid: "已支付",

  rejection_reason: ["错误输入", "不可行", "已售完", "不在菜单中", "未准备好", "完成得太晚"],

  timing: "时间安排",
  timing_inherit: "继承",
  timing_asap: "尽快",
  timing_appetizer: "前菜/汤",
  timing_maincourse: "主菜",
  timing_desert: "甜点",
  timing_last: "最后",

  for_whom: "为谁",

  unknown_person: "{0} 号人",

  additions: "附加配料，配料",
  enter_additions: "选择其他配料或配料，调整您的饮品或食物",
  inherited_additions: "继承的配料，选项",
  noResultsMessageText: '没有找到 "%s"',
  options: "选项",

  reload_page: "刷新页面",
  timeout: "超时",
  server_unavailable: "与服务器的通信错误！服务器可能正在重启，建议等待几分钟，通常连接会自动恢复！",
  error_code_500: "服务器错误",
  error_code_400: "错误操作",
  continue: "忽略并继续",
  modify: "修改",
  delete: "删除",
  order_cannot_be_deleted: "该订单无法删除",
  orders_only_in_selected_or_ordered_state_can_be_deleted: "只能删除处于 '已选择' 或 '已订购' 状态的订单！",
  left_the_table: "已离开桌子",
  deleted_or_not_found: "已删除或未找到",

  qr_code: "二维码",
  person: "人",

  do_you_really_want_to_quit_pending_orders: "您确定要退出吗？有待处理的订单！",
  quantity: "数量",
  paying_online: "在线支付...",

  feedback: {
    label: "反馈",
    header: "反馈",
    nextLabel: "下一步",
    reviewLabel: "确认",
    sendLabel: "发送",
    closeLabel: "关闭",
    messageSuccess: "您的反馈已成功发送！",
    messageError: "发送反馈时发生错误！",
    pleaseDescribe: "请描述您的问题",
    issue: "问题",
    blackout: "遮盖",
    highlight: "突出显示",
    highlight_or_blackout: "突出或遮盖重要细节"
  },

  help: {
    next: "下一步",
    back: "返回",
    done: "完成",
    skip: "跳过"
  },

  nameformat: "{0} {1}",
  service_fee: "服务费",

  daysOfWeek: ["一", "二", "三", "四", "五", "六", "日"],
  monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
  server_restart: "服务器正在更新！请耐心等待，直到服务器重新启动并自动恢复连接",
  email_not_associated_with_an_account: "提供的邮箱没有关联任何账户！",
  the_chosen_restauarant_online_services_seems_to_be_unavailable: "{0} 当前不可用！",
  phone: "电话号码",
  address: "地址",
  town: "城市",
  country: "国家",
  postcode: "邮政编码",
  allDay: "全天",
  download_progress: "更新下载中 ({0}%)",
  installing_update: "安装更新",
  new_version_is_available: "有新版本的应用程序可用！",
  applelogin: "使用 Apple 登录",
  facebooklogin: "使用 Facebook 登录",
  door: "楼层/门号",
  consent_successfull: "感谢您的同意！",
  update_successfull: "您的信息已成功更新",
  consent_withdrawal_successfull: "您已撤回对个人数据处理的同意！我们已从系统中删除您的个人数据！",
  switch_back_to_live: "主服务器已恢复！正在切换回主服务器！",
  switch_to_backup: "主服务器当前不可用！正在切换到备份服务器！",
  access_denied: "登录成功，但您目前没有访问任何环境的权限。如果您认为这有误，请通过 support@eatwithme.online 联系我们。",
  pay_later: "稍后支付",
  activation_code: "激活码",
  bad_activation_code: "激活码无效或过期",
  activate: "激活",
  activation: "激活",
  save_update_address: "保存并更新地址",
  no_internet: "无网络连接！",
  request: "请求",
  please_perform_ntak_registration: "请完成 NTAK 注册！",

};
export default local_cn;
