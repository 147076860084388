import $ from "jquery";
import orderListUtil, { processAllOrders, updateOrders, tmplparams, updateTableMates, update, deleteOrders, simpleChangeStateByTableAndMenuItem, resetOrderListUtil, deleteTableOccupations, getTableName, getTableOccupation, isTakeawayTable, playNewAcceptedOrderSound, getOrderById } from "./order-list-util";
import admin, { getLocalPrinterSettings, getProductionLine, getTableOccupations } from "./admin";
import auth, { startpoll, replaceContent, select, get } from "./auth";
import tableLayout from "./table-layout";
import moment from "moment";
import { alltimings } from "./order-grouping";

var updateCycle;
export const stopIntervals = () => {
  delete window.printLabel;
  delete window.printAllLabels;
  clearInterval(updateCycle);
};

export const ready = () => {
  resetOrderListUtil();

  delete sessionStorage.tableOccupationSelected;

  window.toggleProductionLineSelected = toggleProductionLineSelected;
  window.simpleChangeStateByTableAndMenuItem = simpleChangeStateByTableAndMenuItem;
  window.getTableName = getTableName;
  window.getTableOccupation = getTableOccupation;
  window.moment = moment;
  window.isTakeawayTable = isTakeawayTable;
  window.printLabel = printLabel;
  window.printAllLabels = printAllLabels;

  orderListUtil.showTableNumber = true;
  orderListUtil.filterOrders = false;
  orderListUtil.states = auth.myStatus.restaurant_settings["tables-overview"].states;

  orderListUtil.processOrdersDone = function () {
    topUpdateHooks();
  };

  updateCycle = setInterval(function () {
    if (typeof orderListUtil.ordersCache != "undefined") {
      processAllOrders(orderListUtil.ordersCache);
      updateOrders([]);
    }
  }, 20000);

  orderListUtil.show2 = function (order) {
    return sessionStorage.productionLineSelected.split(",").find(productionLineSelected =>
      typeof order.productionLinesInvolved["pl" + productionLineSelected] != "undefined"
    );
  };

  orderListUtil.show = function (order) {
    var ok = Object.values(order.productionLinesInvolved).find(pl => {
      return !!sessionStorage.productionLineSelected.split(",").find(productionLineSelected => {
        if (Number(pl.id) === Number(productionLineSelected)) {
          return true;
        } else {
          return getProductionLine(pl.id).collaboratingProductionLineIds.indexOf(Number(productionLineSelected.id)) >= 0;
        }
      });
    });
    return !!ok;
  };

  orderListUtil.filter = function (order) {
    var ok = false;
    if (order.defaultProductionLinesIds === "") return false;
    order.defaultProductionLinesIds.split(",").forEach(function (pl) {
      sessionStorage.productionLineSelected.split(",").forEach(productionLineSelected => {
        if (Number(pl) === Number(productionLineSelected)) {
          ok = ok || true;
        } else {
          ok = ok || getProductionLine(pl).collaboratingProductionLineIds.indexOf(Number(productionLineSelected)) >= 0;
        }
      });
    });
    return ok;
  };

  orderListUtil.isFiltered = () => {
    return true;
  };
  orderListUtil.orderTemplate = data => {
    var t = $(".order-table-box-outer .order-table-box");
    t.data("scrollTop", t.scrollTop());
    t.data("scrollLeft", t.scrollLeft());

    orderListUtil.statesShown.forEach(function (state) {
      var t = $("<div class='tcontent'/>");
      var target = $("#" + state + ".order-table-box .tcontent,#" + state + ".order-table-box.tcontent");
      if (target.length === 0) return;


      data = data.filter(order => orderListUtil.show(order)).sort(function (a, b) {
        const aNumber = a.tableNumber;
        const bNumber = b.tableNumber;
        const aIsScheduled = isTakeawayTable(aNumber);
        const bIsScheduled = isTakeawayTable(bNumber);
        const aTableOccupation = getTableOccupation(a.tableOccupation);
        const bTableOccupation = getTableOccupation(b.tableOccupation);
        var atime = !aIsScheduled ? a.inStateTimeStamp[state] : aTableOccupation.bookedOccupationStart;
        var btime = !bIsScheduled ? b.inStateTimeStamp[state] : bTableOccupation.bookedOccupationStart;
        return atime - btime;
      });
      data.sort((a, b) => {
        const timestampA = sessionStorage.productionLineSelected.split(",").map(pl => a.inStateTimeStampByProductionLine[pl] && a.inStateTimeStampByProductionLine[pl][state] ? a.inStateTimeStampByProductionLine[pl][state] : 0).reduce((a, b) => Math.max(a, b));
        const timestampB = sessionStorage.productionLineSelected.split(",").map(pl => b.inStateTimeStampByProductionLine[pl] && b.inStateTimeStampByProductionLine[pl][state] ? b.inStateTimeStampByProductionLine[pl][state] : 0).reduce((a, b) => Math.max(a, b));
        return timestampA - timestampB;
      }).forEach(function (order) {
        $("script#ordersTemplateCancelledAcceptedProducingDeliveringStateTableStyle")
          .tmpl({ da: order, state: state, stateTransitions: auth.myStatus.restaurant_settings.production_lines.stateTransitions, ...tmplparams() })
          .appendTo(t);
      });
      replaceContent(target, $(t));
    });
  };

  orderListUtil.updateOrdersDone = function () {
    topUpdateHooks();
  };

  $("#productionLineMainTemplate")
    .tmpl({ ...tmplparams(), hidePaid: true })
    .appendTo($("#main"));

  $("script#ordersTemplateCancelledAcceptedProducingDeliveringTableStyle")
    .tmpl({ stateTransitions: auth.myStatus.restaurant_settings.production_lines.stateTransitions, ...tmplparams() })
    .appendTo($("#orderList"));

  var name = "";
  auth.myStatus.restaurant_production_lines.forEach(function (line) {
    var selected = false;
    if (typeof sessionStorage.productionLineSelected != "undefined")
      sessionStorage.productionLineSelected.split(",").forEach(productionLineSelected => {
        if (Number(productionLineSelected) === line.id) {
          selected = true;
        }
      });
    name += '<button onclick="toggleProductionLineSelected(' + line.id + ',this)" class="btn btn-info ' + (selected ? "active" : "") + '" data-toggle="toggle">' + line.name + "</button>";
  });
  $(".production-line-index-title").html(name);

  select($("#middle"));

  startpoll(serverSideMessageHandler, [
    {
      Restaurant: sessionStorage.restaurantSelected,
      Order: "*"
    },
    {
      Restaurant: sessionStorage.restaurantSelected,
      Menu: "*"
    },
    {
      Restaurant: sessionStorage.restaurantSelected,
      TableOccupation: "*"
    }
  ]);

  admin.getTables(function (tableLayouts) {
    tableLayout.tables = tableLayouts.restaurantTables;
    tableLayouts.restaurantTables.forEach(function (table) {
      tableLayout.tableMap.set(Number(table.number), table);
    });

    getTableOccupations(function (data) {
      orderListUtil.tableOccupations = data;
      updateTableMates();
      admin.getActiveMenuItems(function (categs) {
        admin.categories = categs;

        admin.getUnprocessedOrders([], orders => {
          orderListUtil.ordersCache = orders;
          orderListUtil.processOrders(orders)
        });

      }, localStorage.language);


      $("div#rejectionReason").on("shown.bs.modal", function (event) {
        var modal = $(this);
        var r = $(modal).find("#reason1");
        $(r).trigger("focus");
      });
    });
  });

  $("#orderList").click(event => {
    if (event.isDefaultPrevented()) return;

    if (event.originalEvent.selectionDone === true) return;

    $(".mainrow.selectable.selected").removeClass("selected");
  });
};

var printLabelTimeout;

const printLabel = (event, pl, orderss, reprint = false) => {
  event.stopPropagation();
  event.preventDefault();
  if (printLabelTimeout) {
    clearTimeout(printLabelTimeout);
  }
  const orders = orderss || $(event.target).parents(".orderrow").attr("orders");
  const tableOccupation = $(event.target).parents(".mainrow").attr("tableOccupation");
  const productionLine = auth.myStatus.restaurant_production_lines.find(p => p.id == pl)
  printLabelTimeout = setTimeout(() => {
    printLabelTimeout = undefined;
    get("adminService/" + sessionStorage.restaurantSelected + "/" + tableOccupation + "/printLabel?orderIds=" + orders + "&printer=" + productionLine.labelPrinter.id + "&productionLines=" + pl + "&reprint=" + reprint);
  }, 500);
}
const printAllLabels = (event, pl, reprint = false) => {
  event.stopPropagation();
  event.preventDefault();
  const orderss = $(event.currentTarget).parent().attr("orders");
  const orders = [];
  orderss.split(",").forEach(o => {
    orders.push(o);
    getOrderById(o).childOrders.filter(co => o.addition).forEach(co => {
      orders.push(co.id)
    })
  });
  const tableOccupation = $(event.currentTarget).parent().parent().attr("tableOccupation");
  const productionLine = auth.myStatus.restaurant_production_lines.find(p => p.id == pl)
  get("adminService/" + sessionStorage.restaurantSelected + "/" + tableOccupation + "/printLabel?orderIds=" + orders + "&printer=" + productionLine.labelPrinter.id + "&productionLines=" + pl + "&reprint=" + reprint);
}

const toggleProductionLineSelected = (id, item) => {
  if (!sessionStorage.productionLineSelected && sessionStorage.productionLineSelected !== "")
    return;
  var pls = sessionStorage.productionLineSelected.split(",");
  if (pls.indexOf("" + id) !== -1) {
    pls.splice(pls.indexOf("" + id), 1);
    $(item).removeClass("active");
  } else {
    pls.push(id);
    $(item).addClass("active");
  }
  var s = "";
  pls.forEach(pl => {
    s += (s === "" ? "" : ",") + pl;
  });
  sessionStorage.productionLineSelected = s;
  processAllOrders(orderListUtil.ordersCache);
  updateOrders([]);
};

function serverSideMessageHandler(message) {
  if (message.type === "timeout" && message.message === "true") return;
  if (message.type === "refresh") {
    if (message.message === "order changed" || message.message === "order state changed" || message.message === "new order" || message.message === "new order selected") {
      if (message.data) {
        if (message.data.orders.filter(o => o && orderListUtil.filter(o) && o.state === "accepted" && getOrderById(o.id)?.state !== "accepted").length) {
          playNewAcceptedOrderSound();
        }
        update(message.data);
      } else admin.getOrder(message.TableOccupation, message.Order, updateOrders);
    } else if (message.message === "order cancelled") {
      if (message.data) {
        deleteOrders(message.Order, true);
        update(message.data);
      } else {
        deleteOrders(message.Order);
      }
    } else if (message.message === "menu changed") {
      admin.getActiveMenuItems(function (categs) {
        admin.categories = categs;
        updateOrders([]);
      }, localStorage.language);
    } else if (message.message === "table unlocked") {
      if (message.data) update(message.data);
      else {
        deleteTableOccupations(message.TableOccupation);
        updateOrders([]);
      }
    } else {
      if (message.data) {
        update(message.data);
      }
      // reload();
    }
  }
}

/*
const reload = () => {
  var id = orderListUtil.selected.attr("id");
  var orderId = orderListUtil.selected.attr("orderId");
  var details = $("tr#" + id + ".detailrow.nothidden").length > 0;
  admin.getUnprocessedOrders(function(data) {
    processOrders(data);
    setSelected(id);
    if (details) {
      _show(orderListUtil.selected, true);
      $("tr#" + id + ".detailrow.nothidden").each(function(ind, row) {
        if ($(row).attr("orderId") === orderId) {
          _show($(row));
        }
      });
    }
  });
};
*/

function topUpdateHooks() {
  $(".orderTable.table-style .order-table-box-outer .order-table-box .mainrow").each((ind, box) => {
    var lastChild = $(box).find("div.order-table-box-content > div:not(.change-state-to) > div:last-child");

    var pos = lastChild.offset();
    var boxpos = $(box).offset();
    var bottom = pos.top - boxpos.top + lastChild.get(0).getBoundingClientRect().height;
    var right = pos.left - boxpos.left + lastChild.get(0).getBoundingClientRect().width;
    var height = $(box).outerHeight();
    var width = $(box).outerWidth();

    if (right > width + 1 || bottom > height + 1) {
      $(box).css("min-width", "calc(" + (20 + Math.floor(right / width) * 20) + "em)");
    }
  });

  var t = $(".order-table-box-outer .order-table-box");
  t.scrollTop(t.data("scrollTop"));
  t.scrollLeft(t.data("scrollLeft"));

  orderListUtil.updateHooks();
}
