import admin, { getMenuItems, getEntities, getBookedTableOccupations, getStores, getStockStatistics, clearSelect2Cache } from "../js/admin";
import $ from "jquery";
import moment from "moment";
import languages from "../langs/languages";
import { tmplparams, getQuantity2 } from "../js/order-list-util";
import { startpoll, getLocale, _floatTableHead } from "../js/auth";
import { createTree } from "jquery.fancytree";
import TableToExcel from "@linways/table-to-excel";
import { conversion, getInventoryQuantity } from "../js/admin/stocklist";

const { local } = languages;

var stockStatistics = {
	stockStatistics: null
}

export const clearData = () => {
	stockStatistics = {};
	$('body').unbind('keyup', keyUpHandler);
}

export const ready = (props, state) => {
	clearSelect2Cache();
	const def = $.Deferred();
	localStorage.input_price = typeof localStorage.input_price == "undefined" ? 'net_unit_price' : localStorage.input_price;
	getStores(data => {
		stockStatistics.stores = data;
		getMenuItems(function (data) {
			stockStatistics.categories = data;
			updateData(stockStatistics.categories);
			getEntities("Suppliers", function (data) {
				stockStatistics.suppliers = data;
				$('#orderSummaryTemplate').tmpl(tmplparams()).appendTo('#main');

				$("#excel").click(() => {
					$("#menuItemsFreezeTable > table#menuItems tr.fancytree-hide").remove();
					TableToExcel.convert($("#menuItemsFreezeTable > table#menuItems").get(0));
				})

				$('#date.datepicker').datepicker({
					format: 'yyyy-mm-dd',
					uiLibrary: 'bootstrap4',
				});
				$('#time.timepicker').timepicker({
					format: 'HH:MM',
					uiLibrary: 'bootstrap4'
				});

				startpoll(serverSideMessageHandler, [{ Restaurant: sessionStorage.restaurantSelected }]);

				updateHooks();


				if (typeof localStorage.admin_stocklist_start == 'undefined') {
					localStorage.admin_stocklist_end = moment().toDate().getTime();
					localStorage.admin_stocklist_start = moment().subtract(1, 'month').toDate().getTime();
				}
				/*
				if (typeof localStorage.admin_stocklist_range != 'undefined') {
					$("[name='range'][id='" + localStorage.admin_stocklist_range + "']").parent().button('toggle');
				}

				$("[name='range']").change((event) => {
					localStorage.admin_stocklist_range = $("[name='range']:checked").attr('id');
					reload(state);
				});
				*/

				floatTableHead();
				//floatMainTableHead();

				admin.initializeSelect2($('#stockStatistics select.select2')).done(() => {
					reload(state);
					$("div#menuItemsFreezeTable table thead input,div#menuItemsFreezeTable table thead select.select2").change(function (e) {
						reload(state);
					});
				});


				def.resolve();
			});


			$('body').keyup(keyUpHandler);

		});
	});

	return def.promise();

}

function keyUpHandler(e) {
	switch (e.key) {
		case 'ArrowDown':
			break;
		case 'ArrowUp':
			break;
		case 'Insert':
			break;
		case 'Delete':
			break;
		case 'Enter':
		default:
	}
}

export const reload = (state) => {
	loadOrders(state);
}

function emptyIfNull(val) {
	if (val == null)
		return "";
	return val;
}

function loadOrders(state) {
	var filter = {
		from: state.startDate,
		to: state.endDate,
		menuItem: emptyIfNull($('select#product_filter').val()),
	};
	var filter2 = {
		from: state.startDate,
		to: state.endDate,
		menuItem: emptyIfNull($('select#product_filter').val()),
	};
	getBookedTableOccupations(filter, (demand) => {
		getStockStatistics(filter2, statistics => {
			processStockStatistics(demand, statistics);
		})
	});
}

function serverSideMessageHandler(data) {
}

function updateData(data, level) {
	if (typeof level === "undefined") level = 0;
	if (data.name) {
		data.title = data.name[localStorage.language] ? data.name[localStorage.language] : getLocale(data.name, localStorage.language);
	}
	if (data.description) {
		data.title = data.title
			+ (data.description[localStorage.language] !== undefined ? (" - " + data.description[localStorage.language])
				: ((data.description['all'] ? (" - (" + data.description['all'] + ")") : "")));
	}
	data.depth = level;
	delete data.statistics;
	if (data.shortName)
		data.title += ' (<i>' + data.shortName + '</i>)';
	if (data.children) {
		var children = [];
		data.children.forEach(function (v) {
			if (v.availableQuantities) {
				if (v.availableQuantities.length > 1) {
					var o = false;
					v.availableQuantities.forEach((a) => {
						if (a.inventoryItem === true) {
							o = true;
							var v1 = Object.assign({}, v);
							v1.quantity = a.quantity;
							stockStatistics.stores.forEach(store => {
								var v2 = Object.assign({}, v1);
								delete v2.statistics;
								v2.store = store.id;
								children.push(v2);
							})
						}
					});
					if (!o) {
						stockStatistics.stores.forEach(store => {
							var v2 = Object.assign({}, v);
							delete v2.statistics;
							v2.store = store.id;
							children.push(v2);
						})
					}
				} else if (v.availableQuantities.length === 1 && (v.defaultInventoryItem === true || v.availableQuantities[0].inventoryItem === true)) {
					v.quantity = v.availableQuantities[0].quantity;
					stockStatistics.stores.forEach(store => {
						var v2 = Object.assign({}, v);
						delete v2.statistics;
						v2.store = store.id;
						children.push(v2);
					})
				} else {
					stockStatistics.stores.forEach(store => {
						var v2 = Object.assign({}, v);
						delete v2.statistics;
						v2.store = store.id;
						children.push(v2);
					})
				}
			} else {
				v.quantity = 1;
				var v2 = Object.assign({}, v);
				delete v2.statistics;
				children.push(v2);
			}
		});
		data.children = children;
		data.children.forEach(function (v) {
			//if (!v.isActive)
			//	v.extraClasses += " itemDeactivated";
			updateData(v, level + 1);
			v.key = v.entityType + v.id + "-" + v.quantity;
		});
	}
}


function processStockStatistics(tableOccupations, stocks) {

	var from = moment(Number(localStorage.admin_stocklist_start));
	var to = moment(Number(localStorage.admin_stocklist_end));

	const statistics = new Map();
	tableOccupations.forEach((tableOccupation, ind) => {
		const date = moment(Number(tableOccupation.bookedOccupationStart)).startOf('day').valueOf();
		tableOccupation.orders.forEach(order => {
			if (order.recordState === 'DELETED')
				return;
			if (order.state === 'cancelled')
				return;

			const menuitem = getMenuItem(order.menuItem.id);
			if (!menuitem.defaultInventoryItem) {
				order.ingredients[order.id + ";" + order.menuItem.id + ";" + (getInventoryQuantity(menuitem, order.quantity) ? getInventoryQuantity(menuitem, order.quantity) : '1.0')] = order.quantity;
			}
			var date2 = date;
			if (order.todeliver) {
				date2 = order.todeliver;
			}
			if (date2 < from || date2 > to) {
				return;
			}

			Object.keys(order.ingredients).forEach(ingredient => {
				const ings = ingredient.split(";");
				const key = ings[1] + "-" + ings[2];
				var stat = statistics.get(key);
				var inventoryQuantity = getInventoryQuantity(getMenuItem(ings[1]), order.quantity);
				if (!inventoryQuantity) inventoryQuantity = 1;
				if (!stat) {
					stat = {
						menuitem: ings[1],
						quantity: ings[2],
						dates: new Map()
					}
					stat.dates.set(date2, order.ingredients[ingredient] / inventoryQuantity);
					statistics.set(key, stat);
				} else {
					var count = stat.dates.get(date2);
					stat.dates.set(date2, (count ? count : 0) + order.ingredients[ingredient] / inventoryQuantity);
				}
			});

			order.childOrders.forEach(order => {
				const menuitem = getMenuItem(order.menuItem.id);
				if (!menuitem.defaultInventoryItem) {
					order.ingredients[order.id + ";" + order.menuItem.id + ";" + (getInventoryQuantity(menuitem, order.quantity) ? getInventoryQuantity(menuitem, order.quantity) : '1.0')] = order.quantity;
				} else {
					return;
				}

				Object.keys(order.ingredients).forEach(ingredient => {
					const ings = ingredient.split(";");
					const key = ings[1] + "-" + ings[2];
					//var date2 = date;
					var menuItem = getMenuItem(order.menuItem.id, order.quantity);
					//if (menuItem.defaultFromDate) {
					//date2 = menuItem.defaultFromDate;
					//}
					var stat = statistics.get(key);
					if (!stat) {
						stat = {
							menuitem: ings[1],
							quantity: ings[2],
							dates: new Map()
						}
						stat.dates.set(date2, (order.addition ? 1 : -1) * order.ingredients[ingredient]);
						statistics.set(key, stat);
					} else {
						var count = stat.dates.get(date2);
						stat.dates.set(date2, (count ? count : 0) + (order.addition ? 1 : -1) * order.ingredients[ingredient]);
					}
				});
			})

		})
	});


	var dates = [];

	while (from.isBefore(to)) {
		dates.push(from.clone());
		from.add(1, 'day');
	}

	$('#orders').remove();
	$('#orderSummaryTemplate').tmpl({ dates, ...tmplparams() }).appendTo('#main');
	stockStatistics.stockStatistics = null;

	statistics.forEach(function (v, i) {
		var vv = getMenuItem(v.menuitem, v.quantity);
		vv.statistics = v;
	});

	if (stocks)
		stocks.forEach(function (v, i) {
			var vv = getMenuItem(v.menuitem, v.quantity);
			if (vv.statistics)
				vv.statistics = { ...vv.statistics, ...v };
			else
				vv.statistics = v;
		});

	function inventoryChild(data) {
		if (data.defaultInventoryItem !== true)
			return false;
		if (data.statistics && data.statistics.dates) {
			return true;
		}
		var t = false;
		if (data.children)
			data.children.forEach((data) => {
				t = t || inventoryChild(data);
			});
		return t;
	}

	function filter(children) {
		const result = [];
		children.forEach(child => {
			//if (true || inventoryChild(child)) {
			const childcopy = { ...child };
			if (child.children)
				childcopy.children = filter(child.children);
			result.push(childcopy);

			//}
		})
		return result;
	}

	const data = filter(stockStatistics.categories.children);

	if (!(stockStatistics.stockStatistics)) {
		stockStatistics.stockStatistics = createTree("#menuItemsFreezeTable > table#menuItems", {
			checkbox: false,
			titlesTabbable: true, // Add all node titles to TAB chain
			source: { children: data },
			zindex: 1000,
			icon: false,
			keyboard: true,
			extensions: ["table", "gridnav", "persist", "filter"],
			filter: {  // override default settings
				counter: false,
				mode: "hide"
			},
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			persist: {
				cookiePrefix: 'fancytree-1-incomingStock',
				expandLazy: false,
				overrideSource: false, // true: cookie takes precedence
				// over `source` data
				// attributes.
				store: "auto" // 'cookie', 'local': use localStore,
				// 'session': sessionStore
			},
			gridnav: {
				autofocusInput: true, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},
			activate: function (event, data) {
			},
			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = true;

				function round(amount, x, showzero) {
					if (!x) x = 1;
					if (!amount && !showzero)
						return ""
					if (!amount)
						return "";
					return new Intl.NumberFormat('hu').format((Math.round(amount * x) / x));
				}

				// $tdList.eq(1).text(moment(node.data.date).format('DD/MM/YYYY'));
				var c = 1;
				//$tdList.eq(c++).text(getLocale(menuItem.name));
				if (node.data.entityType === "Meal" || node.data.entityType === "Drink") {
					const menuItem = getMenuItem(node.data.id, node.data.quantity, node.data.store);
					var label = getQuantity2(node.data.quantity ? node.data.quantity : 1, menuItem.quantityType, "");
					if (conversion[label])
						label = conversion[label];
					$tdList.eq(c).text(label);
				}
				c++;

				$tdList.eq(c++).text(node.data.manufacturer);
				if (node.data.availableQuantities) {
					const store = getStore(node.data.store);
					$tdList.eq(c++).text(store.name);
				}
				if (node.data.statistics) {
					const statistics = node.data.statistics;
					$tdList.eq(c++).text(round(statistics.initialStock, 1, true));
					if (statistics.dates) {
						dates.forEach(d => {
							if (statistics.dates.get(d.valueOf()))
								$tdList.eq(c++).text(round(statistics.dates.get(d.valueOf()), 1000, true));
							else
								$tdList.eq(c++).text("");

						})
					}
				}
			}
		});

		stockStatistics.stockStatistics.filterNodes(function (node) {
			function inventoryChild(data) {
				if (node.data.defaultInventoryItem !== true)
					return false;
				if (node.data.statistics && node.data.statistics.dates.length)
					return true;
				var t = false;
				if (data.children)
					data.children.forEach((data) => {
						t = t || inventoryChild(data);
					});
				return t;
			}
			return node.data.statistics && node.data.statistics.dates;
			//return true || inventoryChild(node.data);
		});

	} else {
		stockStatistics.stockStatistics.reload({ children: [...stockStatistics.categories.children] });
	}
	floatTableHead();
	//updateFloatTableHead();
}

function updateHooks() {
}

function getMenuItem(id, quantity) {
	var menuItem = _getMenuItem(id, quantity, stockStatistics.categories.activeMenuCategories ? stockStatistics.categories.activeMenuCategories : stockStatistics.categories.children);
	if (menuItem === null || menuItem === '') {
		return {
			id: id,
			type: 'deleted',
			name: local.deleted_or_not_found + '(' + id + ')'
		};
	}
	return menuItem;
}

function _getMenuItem(id, quantity, stockStatisticsscategories) {
	var val = null;
	stockStatisticsscategories.forEach(function (item) {
		if (val !== null)
			return;
		if (item.entityType.indexOf('Category') === -1) {
			if (item.id === Number(id)) {
				val = item;
				delete val.image;
			}
		} else {
			if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
				val = _getMenuItem(id, quantity, item.activeMenuCategories);
			if (val === null && item.activeMenuItems && item.activeMenuItems.length > 0)
				val = _getMenuItem(id, quantity, item.activeMenuItems);
			if (val === null && item.children && item.children.length > 0)
				val = _getMenuItem(id, quantity, item.children);
		}
	});
	return val;
}

/*
function floatMainTableHead() {

	if (window.mobileAndTabletcheck())
		return;

	floatTableHead("#entriesFreezeTable");

}
*/

function floatTableHead() {

	_floatTableHead("#menuItemsFreezeTable");

}



function getStore(id) {
	var store = null;
	stockStatistics.stores.forEach(s => {
		if (s.id === Number(id))
			store = s;
	});
	return store;
}