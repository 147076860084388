import admin, { getMenuItems, getEntities, getStockStatistics, getStores, getMenuCategory, getMenuItem, clearSelect2Cache } from "../admin";
import $ from "jquery";
import moment from "moment";
import languages from "../../langs/languages";
import { tmplparams, getQuantity2 } from "../order-list-util";
import { startpoll, getLocale } from "../auth";
import { createTree } from "jquery.fancytree";
import { conversion } from "./stocklist";
import TableToExcel from "@linways/table-to-excel";

const { local } = languages;

var stockStatistics = {
	stockStatistics: null
}

export const clearData = () => {
	stockStatistics = {};
	$('body').unbind('keyup', keyUpHandler);
}

export const ready = (props) => {

	clearSelect2Cache();

	const def = $.Deferred();
	localStorage.input_price = typeof localStorage.input_price == "undefined" ? 'net_unit_price' : localStorage.input_price;
	getStores(data => {
		stockStatistics.stores = stockStatistics.storesSelected = data;

		stockStatistics.stores.forEach(store => {
			if (typeof localStorage['stocklist_stores_selected_' + store.id] == undefined) {
				localStorage['stocklist_stores_selected_' + store.id] = true;
			}
		});

		getMenuItems(function (data) {
			stockStatistics.categories = data;
			updateData(stockStatistics.categories);
			getEntities("Suppliers", function (data) {
				stockStatistics.suppliers = data;
				$('#stockStatisticsTemplate').tmpl({ stores: stockStatistics.stores, ...tmplparams() }).appendTo('#main');

				$("#excel").click(() => {
					stockStatistics.stockStatistics.expandAll();
					$("#menuItemsFreezeTable > table#stockStatistics td").each((ind, td) => {
						$(td).data("val2", td.html);
						$(td).html($(td).data('val'));
					});
					TableToExcel.convert($("#menuItemsFreezeTable > table#stockStatistics").get(0));
				})

				$('#date.datepicker').datepicker({
					format: 'yyyy-mm-dd',
					uiLibrary: 'bootstrap4',
				});
				$('#time.timepicker').timepicker({
					format: 'HH:MM',
					uiLibrary: 'bootstrap4'
				});

				startpoll(serverSideMessageHandler, [{ Restaurant: sessionStorage.restaurantSelected }]);

				updateHooks();


				if (typeof localStorage.admin_stocklist_start == 'undefined') {
					localStorage.admin_stocklist_end = moment().toDate().getTime();
					localStorage.admin_stocklist_start = moment().subtract(1, 'month').toDate().getTime();
				}

				if (typeof localStorage.admin_stocklist_range != 'undefined') {
					$("[name='range'][id='" + localStorage.admin_stocklist_range + "']").parent().button('toggle');
				}

				$("[name='range']").change((event) => {
					localStorage.admin_stocklist_range = $("[name='range']:checked").attr('id');
					reload();
				});

				admin.initializeSelect2($('#stockStatistics select.select2')).done(() => {
					loadStockStatistics();
					$("div#menuItemsFreezeTable table thead input,div#menuItemsFreezeTable table thead select.select2").change(function (e) {
						reload();
					});

					$("select#product_filter.select2").change(function (e) {
						stockStatistics.menuItemSelected = emptyIfNull($('select#product_filter').select2('val'));
						//stockStatistics.stockStatistics && stockStatistics.stockStatistics.filterNodes(filter);
						processStockStatistics(stockStatistics.statistics);
					});

				});

				$('input.store').change(event => {
					var id = $(event.currentTarget).attr('id');
					var checked = $(event.currentTarget).prop('checked');
					localStorage['stocklist_stores_selected_' + id] = checked;
					processStockStatistics(stockStatistics.statistics);
				});


				def.resolve();
			});


			$('body').keyup(keyUpHandler);

		});
	});

	return def.promise();

}

function keyUpHandler(e) {
	switch (e.key) {
		case 'ArrowDown':
			break;
		case 'ArrowUp':
			break;
		case 'Insert':
			break;
		case 'Delete':
			break;
		case 'Enter':
		default:
	}
}

export const reload = () => {
	loadStockStatistics();
}

function emptyIfNull(val) {
	if (val == null)
		return "";
	return val;
}

function loadStockStatistics() {
	var filter = {
		from: localStorage.admin_stocklist_start,
		to: localStorage.admin_stocklist_end,
	};
	getStockStatistics(filter, processStockStatistics);
}

function serverSideMessageHandler(data) {
}

function updateData(data, level) {
	if (typeof level === "undefined") level = 0;
	if (data.name) {
		data.title = getLocale(data.name, localStorage.language);
	}
	data.depth = level;
	delete data.statistics;
	if (data.shortName)
		data.title += ' (<i>' + data.shortName + '</i>)';
	if (data.children) {
		var children = [];
		data.children.forEach(function (v) {
			if (v.availableQuantities) {
				v.parentCategories = getCategoryId(v.id);
				if (v.availableQuantities.length > 1) {
					var o = false;
					v.availableQuantities.forEach((a) => {
						if (a.inventoryItem === true) {
							o = true;
							var v1 = Object.assign({}, v);
							v1.quantity = a.quantity;
							stockStatistics.stores.forEach(store => {
								var v2 = Object.assign({}, v1);
								delete v2.statistics;
								v2.store = store.id;
								children.push(v2);
							})
						}
					});
					if (!o) {
						stockStatistics.stores.forEach(store => {
							var v2 = Object.assign({}, v);
							delete v2.statistics;
							v2.store = store.id;
							children.push(v2);
						})
					}
				} else if (v.availableQuantities.length === 1 && (v.defaultInventoryItem === true || v.availableQuantities[0].inventoryItem === true)) {
					v.quantity = v.availableQuantities[0].quantity;
					stockStatistics.stores.forEach(store => {
						var v2 = Object.assign({}, v);
						delete v2.statistics;
						v2.store = store.id;
						children.push(v2);
					})
				} else {
					stockStatistics.stores.forEach(store => {
						var v2 = Object.assign({}, v);
						delete v2.statistics;
						v2.store = store.id;
						children.push(v2);
					})
				}
			} else {
				v.parentCategories = ['category-' + v.id, ...getCategoryCategoryId(v.id)];
				v.quantity = 1;
				var v2 = Object.assign({}, v);
				delete v2.statistics;
				children.push(v2);
			}
		});
		data.children = children;
		data.children.forEach(function (v) {
			//if (!v.isActive)
			//	v.extraClasses += " itemDeactivated";
			updateData(v, level + 1);
			v.key = v.entityType + v.id + "-" + v.quantity;
		});
	}
}


function processStockStatistics(statistics) {

	stockStatistics.storesSelected = stockStatistics.stores.filter(s => $("input#" + s.id + ".store:checked").length > 0);

	stockStatistics.statistics = statistics;

	function clearStatistics(node) {
		//delete node.statistics;
		if (node.children)
			node.children.forEach(clearStatistics);
	}

	if (stockStatistics.categories) stockStatistics.categories.children.forEach(clearStatistics);

	function filter2(node) {
		node = { ...node };
		if (node.store && stockStatistics.storesSelected.filter(s => s.id === node.store).length === 0)
			return false;
		function inventoryChild(data) {
			if (data.defaultInventoryItem === true)
				return true;
			var t = false;
			if (data.children)
				data.children.forEach((data) => {
					t = t || inventoryChild(data);
				});
			else {
				//eslint-disable-next-line eqeqeq
				const res = node.defaultInventoryItem === true && (!stockStatistics.menuItemSelected || !stockStatistics.menuItemSelected.length || stockStatistics.menuItemSelected.filter(e => Number(e) == Number(data.id)).length > 0 || data.parentCategories.filter(id => stockStatistics.menuItemSelected.filter(e => e == id).length > 0).length > 0);
				return res;
			}
			return t;
		}
		if (node.children)
			node.children = node.children.map(filter2).filter(m => m);
		return inventoryChild(node) ? node : null;
	}

	statistics.forEach(function (v, i) {
		var vv = getMenuItemForQuantityAndStore(v.menuitem, v.quantity, v.store);
		vv.statistics = v;
	});

	const children = stockStatistics.categories ? stockStatistics.categories.children.map(filter2).filter(m => m) : [];


	if (!(stockStatistics.stockStatistics)) {
		stockStatistics.stockStatistics = createTree("#menuItemsFreezeTable > table#stockStatistics", {
			checkbox: false,
			titlesTabbable: true, // Add all node titles to TAB chain
			source: { children: children },
			zindex: 1000,
			icon: false,
			keyboard: true,
			extensions: ["table", "gridnav", "persist", "filter"],
			filter: {  // override default settings
				counter: false,
				mode: "hide"
			},
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			persist: {
				cookiePrefix: 'fancytree-1-stocklist',
				expandLazy: false,
				overrideSource: false, // true: cookie takes precedence
				// over `source` data
				// attributes.
				store: "auto" // 'cookie', 'local': use localStore,
				// 'session': sessionStore
			},
			gridnav: {
				autofocusInput: true, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},
			activate: function (event, data) {
			},
			expand: () => {
			},
			collapse: () => {
			},
			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				//node.expanded = true;

				function round(amount, x, showzero) {
					if (!x) x = 1;
					if (!amount && !showzero)
						return ""
					return new Intl.NumberFormat('hu').format((Math.round(amount * x) / x));
				}

				// $tdList.eq(1).text(moment(node.data.date).format('DD/MM/YYYY'));
				var c = 1;
				//$tdList.eq(c++).text(getLocale(menuItem.name));
				const menuItem = getMenuItem(node.data.id, node.data.quantity, node.data.store);
				var label = getQuantity2(node.data.quantity, menuItem.quantityType, "");
				if (conversion[label])
					label = conversion[label];
				if (node.data.availableQuantities) {
					$tdList.eq(c++).text(label);
					const store = getStore(node.data.store);
					$tdList.eq(c++).text(store.name);
				} else
					c++;
				if (node.data.statistics) {
					var cc = c;
					const statistics = node.data.statistics;
					statistics.averageCost = statistics.averageCost !== undefined ? statistics.averageCost : statistics.finalCost;
					$tdList.eq(c++).text(round(statistics.initialStock, 1000, true));
					$tdList.eq(c++).text(round(statistics.incoming, 1000));
					$tdList.eq(c++).text(round(statistics.consumption, 1000));
					$tdList.eq(c++).text(statistics.shortage < 0 ? round(-statistics.shortage, 1000) : "");
					$tdList.eq(c++).text(statistics.shortage > 0 ? round(statistics.shortage, 1000) : "");
					$tdList.eq(c++).text(round(statistics.scrapping, 1000));
					$tdList.eq(c++).text(round(statistics.outgoing, 1000));
					$tdList.eq(c++).text(round(statistics.finalStock, 1000));
					$tdList.eq(c++).text(round(statistics.minStock, 1000, true));
					$tdList.eq(c++).text(round(statistics.maxStock, 1000, true));
					$tdList.eq(c++).text(round(statistics.initialCost));
					$tdList.eq(c++).text(round(statistics.finalCost, 1000));
					$tdList.eq(c++).text(round(statistics.averageCost));
					$tdList.eq(c++).text(round(statistics.initialPrice));
					$tdList.eq(c++).text(round(statistics.finalPrice));
					$tdList.eq(c++).text(round(statistics.minPrice));
					$tdList.eq(c++).text(round(statistics.maxPrice));
					$tdList.eq(c++).text(round(statistics.averagePrice));
					$tdList.eq(c++).text(round(statistics.netto_income));
					$tdList.eq(c++).text(round(statistics.brutto_income - statistics.netto_income));
					$tdList.eq(c++).text(round(statistics.brutto_income));
					$tdList.eq(c++).text(round(statistics.averagePrice * statistics.consumption - statistics.averageCost * statistics.consumption));
					const margin = round((statistics.averagePrice - statistics.averageCost) / (statistics.averageCost) * 100);
					if (margin)
						$tdList.eq(c++).text(margin + "%");
					else c++;
					$tdList.eq(c++).text(round(Math.max(statistics.finalStock, 0) * statistics.finalCost));
					$tdList.eq(c++).text(round(Math.max(statistics.finalStock, 0) * statistics.finalPrice));

					c = cc;
					$tdList.eq(c++).data('val', statistics.initialStock);
					$tdList.eq(c++).data('val', statistics.incoming);
					$tdList.eq(c++).data('val', statistics.consumption);
					$tdList.eq(c++).data('val', statistics.shortage > 0 ? statistics.shortage : 0);
					$tdList.eq(c++).data('val', statistics.shortage <= 0 ? -statistics.shortage : 0);
					$tdList.eq(c++).data('val', statistics.scrapping);
					$tdList.eq(c++).data('val', statistics.outgoing);
					$tdList.eq(c++).data('val', statistics.finalStock);
					$tdList.eq(c++).data('val', statistics.minStock);
					$tdList.eq(c++).data('val', statistics.maxStock);
					$tdList.eq(c++).data('val', statistics.initialCost);
					$tdList.eq(c++).data('val', statistics.finalCost);
					$tdList.eq(c++).data('val', statistics.averageCost);
					$tdList.eq(c++).data('val', statistics.initialPrice);
					$tdList.eq(c++).data('val', statistics.finalPrice);
					$tdList.eq(c++).data('val', statistics.minPrice);
					$tdList.eq(c++).data('val', statistics.maxPrice);
					$tdList.eq(c++).data('val', statistics.averagePrice);
					$tdList.eq(c++).data('val', statistics.netto_income);
					$tdList.eq(c++).data('val', statistics.brutto_income - statistics.netto_income);
					$tdList.eq(c++).data('val', statistics.brutto_income);
					$tdList.eq(c++).data('val', statistics.averagePrice * statistics.consumption - statistics.averageCost * statistics.consumption);
					if (margin)
						$tdList.eq(c++).data('val', margin / 100);
					else c++;
					$tdList.eq(c++).data('val', Math.max(statistics.finalStock, 0) * statistics.finalCost);
					$tdList.eq(c++).data('val', Math.max(statistics.finalStock, 0) * statistics.finalPrice);
				}

			}
		});

		//stockStatistics.stockStatistics.filterNodes(filter);

	} else {
		stockStatistics.stockStatistics.reload(children);
	}
}

function updateHooks() {
}

function getCategoryId(item) {
	const menuItem = getMenuItem(item);
	const category = getMenuCategory(menuItem.menuCategoryId);
	const res = ['category-' + category.id, ...getCategoryCategoryId(category)]
	return res;
}

function getCategoryCategoryId(item) {
	if (item.menuCategoryId) {
		const category = getMenuCategory(item.menuCategoryId);
		return ['category-' + item.menuCategoryId, ...getCategoryCategoryId(category)];
	}
	return [];
}

function getMenuItemForQuantityAndStore(id, quantity, store) {
	var menuItem = _getMenuItem(id, quantity, store, stockStatistics.categories.activeMenuCategories ? stockStatistics.categories.activeMenuCategories : stockStatistics.categories.children);
	if (menuItem === null || menuItem === '') {
		return {
			id: id,
			type: 'deleted',
			name: local.deleted_or_not_found + '(' + id + ')'
		};
	}
	return menuItem;
}


function _getMenuItem(id, quantity, store, stockStatisticsscategories) {
	var val = null;
	stockStatisticsscategories.forEach(function (item) {
		if (val !== null)
			return;
		if (item.entityType.indexOf('Category') === -1 && item.id === Number(id) && Number(item.quantity) === Number(quantity) && Number(item.store) === Number(store)) {
			val = item;
			delete val.image;
		} else {
			if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
				val = _getMenuItem(id, quantity, store, item.activeMenuCategories);
			if (val === null && item.activeMenuItems && item.activeMenuItems.length > 0)
				val = _getMenuItem(id, quantity, store, item.activeMenuItems);
			if (val === null && item.children && item.children.length > 0)
				val = _getMenuItem(id, quantity, store, item.children);
		}
	});
	return val;
}

function getStore(id) {
	var store = null;
	stockStatistics.stores.forEach(s => {
		if (s.id === Number(id))
			store = s;
	});
	return store;
}