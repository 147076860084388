import React, { Component } from "react";
import ReactDOM from "react-dom";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interaction from "@fullcalendar/interaction";
import listview from "@fullcalendar/list";
import "@fullcalendar/common/main.css";
import "@fullcalendar/timeline/main.css";
import "@fullcalendar/resource-timeline/main.css";
import { connect } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import moment from "moment";
import $ from "jquery";
import { post, messageDialog } from "../../js/auth";
import { update, getTableOccupation, getTableName } from "../../js/order-list-util";
import admin, { resultHandler, settings } from "../../js/admin";
import "./style.css";
import { getTable } from "../../reducers/rootReducer";
import ControlledInput from "../../components/ControlledInput";
import { CustomerEmailEditor } from "../../renderers/tablecelleditors";
import { Button } from "react-bootstrap";

class TableReservationComponent extends Component {
  state = {
    view: localStorage.tableReservationComponentView ? localStorage.tableReservationComponentView : "rooms",
    view2: localStorage.tableReservationComponentView2 ? localStorage.tableReservationComponentView2 : "resourceTimelineDay15",
    slotDuration: "00:15",
    selectedEvents: [],
    email: "",
    name: "",
    phone: "",
    comment: "",
    minTime: "00:00:00",
    maxTime: "23:59:59",
    print: false,
    width: "100%",
  };
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myTopRef = React.createRef();
    this.emailInput = React.createRef();
    this.nameInput = React.createRef();
    this.duration = props.myStatus.restaurant_settings["enabled-features"].prebooking.defaultDuration;
    this.state = {
      ...this.state,
      ...this.getSsEe(),
      booking: {
        start: moment(this.timeSelected),
        end: moment(this.timeSelected).add(this.duration, "Hour"),
        groupId: "booking"
      }
    };
  }

  componentDidMount() {
    this.updateEvents();
    this.setState({ minTime: this.minTime(), maxTime: this.maxTime() })
  }

  getSsEe(date) {
    var ss = moment(date).startOf("day");
    ss.add(this.props.myStatus.restaurant_settings["business-day-starts-at"], "Hour");

    var ee = moment(date).startOf("day");
    ee.add(1, "Day").add(-1, "Second");
    if (this.props.myStatus.restaurant_settings["business-day-starts-at"] > this.props.myStatus.restaurant_settings["business-day-ends-at"])
      ee.add(this.props.myStatus.restaurant_settings["business-day-ends-at"], "Hour");
    else ee.add(this.props.myStatus.restaurant_settings["business-day-ends-at"] - 24, "Hour");
    return {
      ss: ss,
      ee: ee
    };
  }

  scrolled = false
  render() {
    this.updateEvents();
    if (this.myRef.current && this.slotsShrinked && !this.scrolled) {
      this.scrolled = true;
      setTimeout(() => {
        this.myRef.current.getApi().changeView(this.myRef.current.getApi().view.type, moment(this.state.start).format("YYYY-MM-DD"));
      }, 10);
    }
    var slotMinWidth = 60;

    const nameClass = this.state.eventSelected && !this.state.name ? "highlight-5" : "";
    const phoneClass = this.state.eventSelected && !this.state.phone ? "highlight-5" : "";
    const currentView = this.myRef.current ? this.myRef.current.getApi().view.type : this.state.view2;
    const events = this.state.view === "tables" ? (currentView === "listWeek" || currentView === "listDay" ? this.convertToList(this.state.tableEvents) : this.state.tableEvents) : this.state.roomEvents;
    const filteredEvents = this.state.print && events ? events.filter(e => e.booked || !this.state.print) : events;

    return (
      <React.Fragment>
        <div ref={this.myTopRef} style={{ flexGrow: 1, flexShrink: 1, position: "relative" }}>
          <div style={{ height: "100%", position: "absolute", overflow: "hidden", width: this.state.width }}>
            <FullCalendar
              selectLongPressDelay={1}
              ref={this.myRef}
              initialView={this.state.view2}
              locale={localStorage.language}
              schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
              plugins={[resourceTimelinePlugin, interaction, listview]}
              resources={this.state.view === "tables" ? this.props.tables : this.props.rooms}
              resourceAreaColumns={this.resourceColumns(this.state.print)}
              events={filteredEvents}
              headerToolbar={this.header}
              views={this.views}
              resourceOrder={["order", "seats", "tableNumber"]}
              customButtons={this.customButtons(this)}

              eventClick={this.eventClick}
              eventDrop={this.eventDrop}
              eventDragStart={this.eventDragStart}
              eventDragStop={this.eventDragStop}
              eventClassNames={this.eventClassNames}
              eventContent={this.eventContent}
              slotLabelClassNames={this.slotLabelClassNames}
              slotLaneClassNames={this.slotLaneClassNames}
              slotMinWidth={slotMinWidth}

              dateClick={this.dateClick}
              select={this.select}
              unselect={this.unselect}
              unselectCancel=".fc-button"
              editable={true}
              selectable={true}
              aspectRatio="1.8"
              resourceAreaWidth="15em"
              slotDuration={this.state.slotDuration}
              resourcesInitiallyExpanded="false"
              height="100%"
              handleWindowResize={true}
              nowIndicator={!this.state.print}

              viewDidMount={this.viewDidMount}

              //visibleRange={t => this.getVisibleRange(t)}
              //validRange={t => this.getVisibleRange(t)}
              slotMinTime={this.state.minTime}
              slotMaxTime={this.state.maxTime}

              scrollTime={moment().add(-2, "Hour").format("HH:mm:ss")}
              businessHours={this.props.businessHours}

            />
          </div>
        </div>
        <div className="fc fc-toolbar fc-footer-toolbar hidden-print">
          {this.props.onClose ?
            <div className="fc-left">
              <button onClick={this.props.onClose} className="fc-button fc-button-primary">
                <Translate value="admin_local.close" />
              </button>
            </div> : null}
          <div className="fc-right">
            <div className="fc-button-group">
              <button style={{ flexGrow: 0 }} disabled={!this.state.eventSelected} onClick={this.edit} className="fc-button fc-button-primary">
                <Translate value="admin_local.edit" />
              </button>
              <div style={{ display: "flex", flexWrap: "wrap", flexGrow: 1 }}>
                <div style={{ minWidth: "11rem", flexBasis: "25%", flexGrow: 1 }}>
                  <CustomerEmailEditor style={{ flexGrow: 1 }} ref={this.emailInput} id="email" value={this.state.email} dropup={true} {...this.props} disabled={!this.state.eventSelected} myStatus={this.props.myStatus} onSelectionChange={this.onCustomerChange} placeholder={I18n.t("admin_local.book_a_table.provide_email")} />
                </div>
                <CustomerEmailEditor style={{ flexGrow: 1 }} className={nameClass} ref={this.nameInput} id="name" value={this.state.name} dropup={true} {...this.props} key={"name" + (this.state.eventSelected ? this.state.eventSelected.id : "")} disabled={!this.state.eventSelected} myStatus={this.props.myStatus} onSelectionChange={this.onCustomerChange} onChange={this.onChange} placeholder={I18n.t("admin_local.book_a_table.provide_name")} />
                <CustomerEmailEditor style={{ flexGrow: 1 }} className={phoneClass} ref={this.phoneInput} id="phone" value={this.state.phone} dropup={true} {...this.props} key={"phone" + (this.state.eventSelected ? this.state.eventSelected.id : "")} disabled={!this.state.eventSelected} myStatus={this.props.myStatus} onSelectionChange={this.onCustomerChange} onChange={this.onChange} placeholder={I18n.t("admin_local.book_a_table.provide_phone")} />
                <ControlledInput id="comment" key={"comment" + (this.state.eventSelected ? this.state.eventSelected.id : "")} disabled={!this.state.eventSelected} onChange={this.onChange} value={this.state.comment} placeholder={I18n.t("admin_local.book_a_table.provide_comment")}></ControlledInput>
              </div>
              <button style={{ flexGrow: 0 }} disabled={!this.thereAreChanges()} onClick={this.save} className="fc-button fc-button-primary">
                <Translate value="admin_local.save" />
              </button>
              {this.props.onClose ?
                <button style={{ flexGrow: 0 }} disabled={!this.thereAreChanges()} onClick={this.save_and_close} className="fc-button fc-button-primary">
                  <Translate value="admin_local.save_and_close" />
                </button> : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  minTime = () => {
    var minTime = this.props.businessHours.map(b => moment(b.startTime, "HH:mm")).reduce((prev, current) => {
      return prev.isBefore(current) ? prev : current
    });
    return minTime.format("HH:mm:ss");
  }

  maxTime = () => {
    var maxTime = this.props.businessHours.map(b => moment(b.endTime, "HH:mm")).reduce((prev, current) => {
      return prev.isAfter(current) ? prev : current
    });
    return maxTime.format("HH:mm:ss");
  }

  thereAreChanges = () => {
    const count = this.myRef && this.myRef.current && this.myRef.current.getApi().getEvents().filter(e => this.eventChanged(e));
    if (count) {
      return count.length > 0;
    } else {
      return false;
    }
  }

  onCustomerChange = value => {
    const state = { ...this.state };
    if (value[0]) {
      if (value[0].phone)
        state.phone = value[0].phone;
      if (value[0].name)
        state.name = value[0].name;
      state.email = value[0].email;
      if (this.state.eventSelected) {
        this.state.eventSelected.setExtendedProp("email", state.email);
        this.state.eventSelected.setExtendedProp("phone", state.phone);
        this.state.eventSelected.setExtendedProp("name", state.name);
        this.state.eventSelected.setExtendedProp("comment", state.comment);
        this.state.eventSelected.setExtendedProp("changed", !this.eventChanged(this.state.eventSelected));
      }
    }
    this.setState(state);
  }

  onChange = (event) => {
    const state = { ...this.state };
    if (this.state.eventSelected) {
      state[event.target.id] = event.target.value;
      this.state.eventSelected.setExtendedProp("changed", !this.eventChanged(this.state.eventSelected));
      this.state.eventSelected.setExtendedProp(event.target.id, event.target.value);
      this.onChangeTimeout = null;
      this.setState(state);
    }
  };

  convertToList = events => {
    if (events) {
      events = events.filter(e => (e.booked && moment(e.start).isAfter(moment().add('minutes', -this.duration))) || (e.extendedProps.wasBooked && moment(e.start).isAfter(moment().add('minutes', -30))));
      return events;
    }
    return [];
  }

  createBooking = count => {
    var events = this.state.selectedEvents;
    var counts = [];

    if (events.length === 0 && !this.state.start) {
      if (!this.state.start)
        messageDialog(I18n.t('local.information'), I18n.t('admin_local.please_click_on_a_time_slot_first'));
      return;
    }

    //var defaultRoom = undefined;
    if (events.length > 0) {
      events = events.map(event => this.myRef.current.getApi().getEventById(event.id));
      Object.keys(events[0].extendedProps.seats).forEach(c => {
        events[0].extendedProps.seats[c].forEach(q => {
          //if (Array.isArray(q)) defaultRoom = getTable(q[0]).roomId;
          //else defaultRoom = getTable(q).roomId;
          counts.push(Number(c));
        });
      });
    }
    var start = events.length ? events[0].start : this.state.start;
    var end = events.length ? events[0].end : this.state.end;
    counts.push(count);

    var freeResources = null;
    counts = counts.sort((a, b) => b - a);
    //if (defaultRoom !== undefined) freeResources = this.findFreeResources(counts, moment(start), moment(end), defaultRoom);
    if (freeResources == null) freeResources = this.findFreeResources(counts, moment(start), moment(end));
    if (freeResources == null) {
      if (counts.length > 1) messageDialog(I18n.t("local.information"), I18n.t("admin_local.book_a_table.not_enough_free_tables"));
      else messageDialog(I18n.t("local.information"), I18n.t("admin_local.book_a_table.not_free_table"));
      counts.splice(count.length - 1, 1);
      return;
    }

    var tables = {};
    var seats = {};

    counts.forEach((c, ind) => {
      if (seats[c] === undefined) {
        if (freeResources) seats[c] = [freeResources[ind]];
      } else {
        seats[c].push(freeResources[ind]);
      }
    });

    Object.values(seats).forEach(c => {
      c.forEach(c => {
        if (Array.isArray(c)) {
          c.forEach(c => {
            var table = getTable(c);
            if (tables[table.seats] === undefined) tables[table.seats] = 1;
            else tables[table.seats]++;
          });
        } else {
          var table = getTable(c);
          if (tables[table.seats] === undefined) tables[table.seats] = 1;
          else tables[table.seats]++;
        }
      });
    });

    var t = "";

    Object.keys(tables)
      .sort((a, b) => {
        return a - b;
      })
      .forEach(s => {
        if (t !== "") t += ", ";
        t += tables[s] + "x" + s;
      });
    $("#bookTable span#tables").html(t);

    tables = [];

    freeResources.forEach((resource, ind) => {
      const table = getTable(resource);
      tables.push(resource);
      if (ind < events.length) {
        event = events[ind];
        if (Array.isArray(resource)) {
          var resourceIds = [];
          resource.forEach(r => {
            if (r.startsWith("room")) return;
            var table = getTable(r);
            var roomResourceId = "room-" + table.roomId + "-" + table.seats;
            resourceIds.push(r);
            if (resourceIds.indexOf(roomResourceId) === -1) resourceIds.push(roomResourceId);
          });
          event._def.resourceIds = resourceIds;
          //event._def.title = String.format(I18n.t("admin_local.book_a_table.number_of_seats"), counts[ind]) + " - " + event.extendedProps.email + " - " + event.extendedProps.comment;
        } else {
          var roomResourceId = "room-" + table.roomId + "-" + table.seats;
          event._def.resourceIds = [roomResourceId, resource];
          //event._def.resourceIds = [resource];
          //event._def.title = String.format(I18n.t("admin_local.book_a_table.number_of_seats"), counts[ind]) + " - " + event.extendedProps.email + " - " + event.extendedProps.comment;
        }
      }
    });

    events.forEach(event => {
      event.setExtendedProp("seats", seats);
      event.setProp("startEditable", true);
    });
    var event = {
      title: "",
      start: events.length ? events[0].start : this.state.start,
      end: events.length ? events[0].end : this.state.end,
      endEditable: true,
      startEditable: true,
      editable: true,
      id: (events.length ? events[0].groupId : "booking") + "-" + events.length,
      groupId: events.length ? events[0].groupId : "booking",
      extendedProps: {
        booking: true,
        booked: true,
        seats: seats,
        editable: true,
        removeEnabled: true,
        changed: true,
        count: counts[counts.length - 1],
        email: this.state.email,
        name: this.state.name,
        phone: this.state.phone,
        comment: this.state.comment
      }
    };

    var newEvent = this.myRef.current.getApi().addEvent(event);
    var resource = freeResources[freeResources.length - 1];
    if (Array.isArray(resource)) {
      var resourceIds = [];
      resource.forEach(r => {
        var table = getTable(r);
        var roomResourceId = "room-" + table.roomId + "-" + table.seats;
        resourceIds.push(r);
        if (resourceIds.indexOf(roomResourceId) === -1) resourceIds.push(roomResourceId);
      });
      newEvent._def.resourceIds = resourceIds;
    } else {
      var table = getTable(resource);
      var roomResourceId = "room-" + table.roomId + "-" + table.seats;
      newEvent._def.resourceIds = [roomResourceId, resource];
    }

    this.setState({
      booking: {
        ...this.state.booking,
        seats: seats,
        groupId: event.groupId
      },
      eventSelected: newEvent,
      selectedEvents: [...events, newEvent]
    });
    var resourceHtmlElement = $("tr[data-resource-id = '" + freeResources[freeResources.length - 1].id + "']");
    resourceHtmlElement.parents(".fc-scroller").centerTo(resourceHtmlElement);
    //this.myRef.current.getApi().rerenderEvents();
    setTimeout(() => {
      ReactDOM.findDOMNode(this.nameInput.current).focus();
    }, 100);

    //this.enableButtons(true);
  };

  findFreeResources = (counts, start, end, selectedRoom) => {
    var instance = this;
    var result = null;
    var resultsPerRoom = [];

    //console.log(this.props.myStatus.restaurant_rooms);
    //console.log(this.props.tables);


    this.props.myStatus.restaurant_rooms
      .sort(roomComparator)
      .forEach(room => {
        //if (result !== null) return;

        if (selectedRoom !== undefined && room.id !== selectedRoom) {
          return result;
        }

        const tablesInRoom = this.props.tables.filter((table, ind) => table.roomId === room.id);

        var results = [];

        for (let s = 0; s < tablesInRoom.length; s++) {

          var resources = [];
          var foundCount = 0;
          function sortit(a, b) {
            if (resources.length === 0)
              return a.id - b.id;
            var distancea = 0;
            var distanceb = 0;
            resources.forEach(t => {
              const table = getTable(t[1]);
              const x = table.x;
              const y = table.y;
              distancea += Math.sqrt(Math.pow(a.x - x, 2) + Math.pow(a.y - y, 2));
              distanceb += Math.sqrt(Math.pow(b.x - x, 2) + Math.pow(b.y - y, 2));
            })
            return distancea - distanceb;
          }
          counts.forEach(c => {
            var count = instance.getTableSeatCount(c);
            var found = false;
            const tables = tablesInRoom.filter((table, ind) => ind >= s).sort(sortit);
            console.log(s, tablesInRoom, tables);
            tables.forEach((table, ind) => {
              if (found) return;
              if (table.seats === count) {
                if (resources.find(r => r[1] === table.id)) {
                  return;
                }
                if (instance.isFree(table.id, start, end)) {
                  if (!resources.length)
                    s = tablesInRoom.indexOf(table);
                  resources.push([table.seats, table.id]);
                  found = true;
                  foundCount++;
                }
              }
            });
          });
          if (resources.length === 0)
            break;
          if (foundCount === counts.length) {
            results.push(resources);
          }
        }
        if (!results.length)
          return;

        console.log(results);

        results = results.map(resources => {
          var distance = 0;
          resources.forEach(r1 => {
            resources.forEach(r2 => {
              const t1 = getTable(r1[1]);
              const t2 = getTable(r2[1]);
              distance += Math.sqrt(Math.pow(t1.x - t2.x, 2) + Math.pow(t1.y - t2.y, 2));
            });
          })
          return {
            distance,
            resources
          }
        }).sort((a, b) => a.distance - b.distance);

        if (results.length) {
          resultsPerRoom.push(results[0].resources);
        }
      });
    console.log(resultsPerRoom);
    resultsPerRoom = resultsPerRoom.sort((a, b) => {
      var ac = 0, bc = 0;
      a.forEach(r => ac += r[0]);
      b.forEach(r => bc += r[0]);
      return ac - bc;
    })
    if (resultsPerRoom.length > 0)
      result = resultsPerRoom[0].map(r => r[1]);
    console.log("perfect match", resultsPerRoom)

    resultsPerRoom = [];
    if (result == null) {
      //let's try first to fit the need with bigger tables than needed
      this.props.myStatus.restaurant_rooms
        .sort(roomComparator)
        .forEach(room => {
          //if (result !== null) return;
          if (selectedRoom !== undefined && room.id !== selectedRoom) {
            return result;
          }

          var resources = [];
          var foundCount = 0;
          counts.forEach(c => {
            var count = instance.getTableSeatCount(c);
            var found = false;
            this.props.tables.forEach(table => {

              if (found) return;
              if (table.roomId === room.id && table.seats >= count) {
                var f = false;
                resources.forEach(res => {
                  f = f || res[1] === table.id;
                });
                if (f) return;
                if (instance.isFree(table.id, start, end)) {
                  resources.push([table.seats, table.id]);
                  found = true;
                  foundCount++;
                }
              }
            });
          });
          if (foundCount === counts.length) {
            resultsPerRoom.push(resources);
          }
        });
    }
    resultsPerRoom = resultsPerRoom.sort((a, b) => {
      var ac = 0, bc = 0;
      a.forEach(r => ac += r[0]);
      b.forEach(r => bc += r[0]);
      return ac - bc;
    })
    if (resultsPerRoom.length > 0)
      result = resultsPerRoom[0].map(r => r[1]);

    console.log("bigger table match", resultsPerRoom)
    resultsPerRoom = [];
    if (result == null) {
      //let's try to extend some tables if possible to meet the needed seat number
      this.props.myStatus.restaurant_rooms
        .sort(roomComparator)
        .forEach(room => {
          if (selectedRoom !== undefined && room.id !== selectedRoom) {
            return result;
          }

          //if (result !== null) return;
          var resources = [];
          var tablesUsed = [];
          var foundCount = 0;
          counts.forEach(c => {
            var count = instance.getTableSeatCount(c);
            var found = false;
            this.props.tables.forEach(table => {
              if (found) return;
              var seats = table.seats;
              if (table.roomId === room.id && seats >= count) {
                var f = tablesUsed.filter(res => res === table.id).length > 0;
                if (f) return;
                if (instance.isFree(table.id, start, end)) {
                  resources.push([count, table.id]);
                  tablesUsed.push(table.id);
                  found = true;
                  foundCount++;
                }
              }
            });
            var free = true;
            if (!found) {
              var tables = [];
              this.props.tables.forEach(table => {
                free = true;
                var t = getTable(table.id);
                if (t.extensions === "" || !t.extensions) {
                  return;
                }
                t.extensions.split(",").forEach(extension => {
                  var totalSeats = extension.split("=")[1];
                  if (Number(table.roomId) === Number(room.id) && Number(totalSeats) >= Number(count)) {
                    var res = [table.id];
                    var f = resources.filter(res => res[1] === table.id).length > 0;
                    //console.log(resources, table, f);
                    if (f) return;
                    if (instance.isFree(table.id, start, end)) {
                      extension
                        .split("=")[0]
                        .split("+")
                        .forEach(otherTable => {
                          if (otherTable === "" || isNaN(otherTable)) return;

                          var f = tablesUsed.filter(res => res === otherTable).length > 0;
                          if (f) {
                            free = false;
                            return;
                          }
                          if (instance.isFree(otherTable, start, end)) {
                            res.push(otherTable);
                          } else {
                            free = false;
                          }
                        });

                      if (free) {
                        tables.push({ totalSeats: totalSeats, resource: res });
                      }
                      else free = false;
                    } else {
                      free = false;
                    }
                  }
                });
              });
              if (free) {
                tables.sort((a, b) => {
                  return a.totalSeats - b.totalSeats;
                });
                if (tables.length > 0) {
                  var table = tables[0];
                  resources.push([count, table.resource]);
                  table.resource.forEach(t => {
                    tablesUsed.push(t);
                  })
                  foundCount++;
                  found = true;
                }
              }
            }
          });
          if (foundCount === counts.length) {
            resultsPerRoom.push(resources);
          }
        });
    }
    resultsPerRoom = resultsPerRoom.sort((a, b) => {
      var ac = 0, bc = 0;
      a.forEach(r => ac += r[0]);
      b.forEach(r => bc += r[0]);
      return ac - bc;
    })
    console.log("joined table match", resultsPerRoom)
    if (resultsPerRoom.length > 0)
      result = resultsPerRoom[0].map(r => r[1]);
    return result;
  };

  moveToRoom = (events, room, start, end) => {
    var counts = [];
    Object.keys(events[0].extendedProps.seats).forEach(c => {
      events[0].extendedProps.seats[c].forEach(cc => {
        counts.push(c);
      });
    });
    var freeResources = this.findFreeResources(counts, start, end, room);
    if (!freeResources) {
      if (counts.length > 1) messageDialog(I18n.t("local.information"), I18n.t("admin_local.book_a_table.not_enough_free_tables"));
      else messageDialog(I18n.t("local.information"), I18n.t("admin_local.book_a_table.not_free_table"));
      //eventDropInfo.revert();
      return;
    }

    var seats = {};
    counts.forEach((c, ind) => {
      if (!seats[c]) {
        if (freeResources) seats[c] = [freeResources[ind]];
      } else {
        seats[c].push(freeResources[ind]);
      }
    });

    var tables = {};
    counts.forEach(c => {
      if (!tables[c]) tables[c] = 1;
      else tables[c]++;
    });
    var t = "";
    Object.keys(tables)
      .sort((a, b) => {
        return a - b;
      })
      .forEach(s => {
        if (t !== "") t += ", ";
        t += tables[s] + "x" + s;
      });
    $("#bookTable span#tables").html(t);

    tables = [];

    freeResources.forEach((resource, ind) => {
      let event = events[ind];
      if (Array.isArray(resource)) {
        var resourceIds = [];
        resource.forEach(r => {
          if (r.startsWith("room")) return;
          var table = getTable(r);
          var roomResourceId = "room-" + table.roomId + "-" + table.seats;
          resourceIds.push(r);
          if (resourceIds.indexOf(roomResourceId) === -1) resourceIds.push(roomResourceId);
        });
        event._def.resourceIds = resourceIds;
        event.setExtendedProp("seats", seats);
        //event.setProp("title", String.format(I18n.t("admin_local.book_a_table.number_of_seats"), counts[ind]) + " - " + $("#bookTable input#email").val() + " - " + $("#bookTable input#comment").val());
      } else {
        var table = getTable(resource);
        var roomResourceId = "room-" + table.roomId + "-" + table.seats;
        event._def.resourceIds = [roomResourceId, resource];
        //event._def.title = String.format(I18n.t("admin_local.book_a_table.number_of_seats"), counts[ind]) + " - " + $("#bookTable input#email").val() + " - " + $("#bookTable input#comment").val();
      }
    });
    events.forEach(event => {
      event.setExtendedProp("seats", seats);
    });
    this.setState({
      booking: {
        ...this.state.booking,
        seats: seats,
        start: start,
        end: end
      }
    });
  };

  getTableSeatCount = count => {
    var c = count;
    this.props.tables.forEach(room => {
      if (room.children)
        room.children.forEach(tables => {
          if (tables.seats >= count && tables.seats < c) {
            c = tables.seats;
          }
        });
    });
    return c;
  };

  reveal = resource => {
    var parent = this.calendar.getResourceById(resource._resource.parentId);
    if (!parent) return;
    var id = parent.id;
    this.calendar.dispatch({
      type: "SET_RESOURCE_ENTITY_EXPANDED",
      id: id,
      isExpanded: true
    });
    this.reveal(parent);
  };

  clearBooking = rerender => {
    /*
    if (this.state.booking)
      this.getSelectedEvents().forEach(event => {
        //event.setExtendedProp("changed", false);
        //event.setProp("title", event.title.split(" - ")[0] + " - " + event.extendedProps.email + " - " + event.extendedProps.comment);
      });
  */

    this.counts = [];
    this.setState({
      booking: {
        events: [],
        seats: [],
        rooms: [],
        tables: [],
        start: moment(this.timeSelected),
        end: moment(this.timeSelected).add(this.duration, "Minutes"),
        groupId: "booking",
        customer: null,
        phone: null,
        name: null
      },
      tables: "",
      email: "",
      name: "",
      phone: "",
      comment: "",
      selectedEvents: [],
      eventSelected: undefined
    });
    $("#bookTable span#tables").html("");

    //if (this.calendar && rerender !== false) this.calendar.rerenderEvents();
  };

  static updateBookingTable = (tableOccupationId, oldTable, newTable) => {
    var tableOccupation = getTableOccupation(tableOccupationId);
    var bookedProperties = tableOccupation.bookedProperties;
    Object.values(bookedProperties.seats).forEach(tables => {
      tables.forEach((table, ind) => {
        if (Array.isArray(table)) {
          table.forEach((t, ind) => {
            if (t === oldTable) tables[ind] = newTable;
          });
        } else {
          if (table === oldTable) tables[ind] = newTable;
        }
      });
    });
    post("adminService/" + sessionStorage.restaurantSelected + "/updateBooking/" + tableOccupationId, {
      start: tableOccupation.bookedOccupationStart,
      end: tableOccupation.bookedOccupationEnd,
      seats: bookedProperties.seats,
      email: bookedProperties.email,
      name: bookedProperties.name,
      phone: bookedProperties.phone,
      comment: bookedProperties.comment
    }).done(data => {
      update(data);
    });
  };

  isFree = (resource, start, end) => {
    var free = true;
    const instance = this;
    this.state.tableOccupations_data.forEach(tableOccupation => {
      if (String(tableOccupation.id) === instance.state.booking.groupId) {
        return;
      }
      tableOccupation.tableNumbers.forEach(table => {
        console.log(table, resource)
        if (Number(table) === Number(resource)) {
          var s = moment(tableOccupation.occupationStart == null ? tableOccupation.bookedOccupationStart : tableOccupation.occupationStart);
          var e = moment(tableOccupation.occupationEnd == null ? tableOccupation.bookedOccupationEnd == null ? s.clone().add(2, "hour").valueOf() : tableOccupation.bookedOccupationEnd : tableOccupation.occupationEnd);
          console.log(table, s, e);
          if (s.isBefore(end) && e.isAfter(start)) {
            free = false;
          }
        }
      });
    });
    console.log("table " + resource + " is free:" + free);
    return free;
  };

  updateHeader = () => {
    var tables = {};
    if (!this.myRef.current)
      return;

    const event = this.myRef.current.getApi().getEvents().find(event => event.groupId === this.state.booking.groupId);
    event && Object.values(event.extendedProps.seats).forEach(c => {
      if (Array.isArray(c)) {
        c.forEach(c => {
          if (Array.isArray(c)) {
            c.forEach(c => {
              var table = getTable(c);
              if (!tables[table.seats]) tables[table.seats] = 1;
              else tables[table.seats]++;
            })
          } else {
            var table = getTable(c);
            if (!tables[table.seats]) tables[table.seats] = 1;
            else tables[table.seats]++;
          }
        });
      } else {
        var table = getTable(c);
        if (!tables[table.seats]) tables[table.seats] = 1;
        else tables[table.seats]++;
      }
    });

    var t = "";
    Object.keys(tables)
      .sort((a, b) => {
        return a - b;
      })
      .forEach(s => {
        if (t !== "") t += ", ";
        t += tables[s] + "x" + s;
      });
    $("#bookTable span#tables").html(t);
  };
  eventDragStart = ({ event }) => {
    this.originalResourceIds = event._def.resourceIds;
    this.draggedEventId = event._def.defId;
  }
  eventDragStop = () => {
    delete this.originalResourceIds;
    delete this.draggedEventId;
  }
  eventDrop = eventDropInfo => {
    var event = eventDropInfo.event;
    const booking = this.state.booking;
    //booking.changed = true;
    booking.start = moment(event.start);
    booking.end = moment(event.end);
    if (this.state.view === "tables" && eventDropInfo.oldResource) {
      const seats = JSON.parse(JSON.stringify(event.extendedProps.seats));
      Object.values(seats).forEach(tables => {
        tables.forEach((table, ind) => {
          if (Array.isArray(table)) {
            table.forEach((t, ind) => {
              if (t === eventDropInfo.oldResource.id) table[ind] = eventDropInfo.newResource.id;
            });
          } else {
            if (table === eventDropInfo.oldResource.id) tables[ind] = eventDropInfo.newResource.id;
          }
        });
      });
      event.setExtendedProp("seats", seats);
      event.setExtendedProp("changed", this.eventChanged(event));
      booking.seats = seats;
      //booking.changed = true;
      this.setState({ booking: booking });
      //calendar.rerenderEvents();
    } else if (this.state.view === "rooms" && eventDropInfo.oldResource) {
      var roomId = "";
      event.getResources().forEach(res => {
        if (res) {
          roomId = res.extendedProps.roomId;
        }
      });
      this.moveToRoom(this.getSelectedEvents(), eventDropInfo.newResource ? eventDropInfo.newResource.extendedProps.roomId : roomId, moment(event.start), moment(event.end), eventDropInfo);
      this.setState({ booking: booking });
      //     this.calendar.rerenderEvents();
    } else {
      event.setExtendedProp("changed", this.eventChanged(event));
      //console.log(booking)
      this.setState({ booking: booking });
    }
    this.updateHeader();
  };
  eventResize = eventResizeInfo => {
    this.booking.start = moment(eventResizeInfo.event.start);
    this.booking.end = moment(eventResizeInfo.event.end);
    if (eventResizeInfo.event.id.startsWith("booking")) {
      this.booking.changed = true;
      this.calendar.getEvents().forEach(ev => {
        if (ev.groupId === eventResizeInfo.event.groupId) {
          ev.setExtendedProp("changed", true);
        }
      });
    } else {
      this.booking.changed = true;
      this.calendar.getEvents().forEach(ev => {
        if (ev.groupId === eventResizeInfo.event.groupId) {
          ev.setExtendedProp("changed", true);
        }
      });
    }
    this.calendar.rerenderEvents();
  };

  eventContent = ({ event }) => {

    if (event.extendedProps.seats)
      var count = Object.keys(event.extendedProps.seats).filter(s => {
        const ss = event.extendedProps.seats[s];
        if (typeof ss === "object") {
          return ss.filter(ss => {
            if (Array.isArray(ss)) {
              return ss.filter(ss => {
                return event._def.resourceIds.indexOf("" + ss) !== -1;
              }).length > 0;
            } else {
              return event._def.resourceIds.indexOf("" + ss) !== -1;
            }
          }).length > 0;
        } else {
          return true;
        }
      }).reduce((a, b) => b, undefined);
    switch (this.myRef.current.getApi().view.type) {
      case "listWeek":
      case "listDay":
        return (<div className="fc-event-title fc-event-title-list" style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "10rem", flexBasis: "0" }}>
            <span style={{ width: "100%" }}>
              {String.format(I18n.t("admin_local.book_a_table.number_of_seats"), count)}
            </span>
            <span className="icon-address-card-o">
              {event.extendedProps.name ?
                event.extendedProps.name
                : null}
            </span>
            <span className="icon-mobile">
              {event.extendedProps.phone ?
                event.extendedProps.phone
                : null}
            </span>
            <span>
              {event._def.resourceIds.map(s => (
                <span key={s}>{getTableName(s)}</span>
              ))}
            </span>
            <span className="icon-comment">
              {event.extendedProps.comment ?
                event.extendedProps.comment
                : null}
            </span>
          </div>
          <div style={{ flexBasis: "0", flexGrow: "0", zIndex: 1000 }}>
            <Button id="cancel" className="btn-danger icon-cancel-circled"></Button>
            <Button style={{ visibility: event.extendedProps.booked ? "visible" : "hidden" }} id="activate" className="icon-download"></Button>
          </div>
        </div>)
      default:

        var resourceIds = event._def.defId === this.draggedEventId ? this.originalResourceIds : event._def.resourceIds;
        resourceIds.forEach(r => {
          if (!count && getTable(r))
            count = getTable(r).seats;
        });

        if (event.id.startsWith("room")) {
          return event.extendedProps.occupied;
        }

        const eventf = event;

        return ["resourceTimelineDay15", "resourceTimelineDay30", "resourceTimelineDayHour"].indexOf(this.myRef.current.getApi().view.type) !== -1 ?
          (<div className="fc-event-title" style={{ display: "flex", flexWrap: "wrap" }}>
            <span style={{ width: "100%" }}>
              {String.format(I18n.t("admin_local.book_a_table.number_of_seats"), count)}
            </span>
            {event.extendedProps.name ?
              <span className="icon-address-card-o">
                {event.extendedProps.name}
              </span>
              : null}
            {event.extendedProps.phone ?
              <span className="icon-mobile">
                {event.extendedProps.phone}
              </span>
              : null}
            {event.extendedProps.comment ?
              <span className="icon-comment">
                {event.extendedProps.comment}
              </span>
              : null}
            <div style={{ zIndex: 10 }} onClick={() => this.cancelEvent(eventf)} className='closeon icon-cancel-circled'></div>
            {event.extendedProps.booked && (!event.extendedProps.name || !event.extendedProps.phone) ? <i className='problem icon-attention'></i> : null}
          </div>) :
          (<div className="fc-event-title" style={{ display: "flex", flexWrap: "wrap" }}>
            <span style={{ width: "100%" }}>
              {count}
            </span>
          </div>)
    }
  }
  activateBooking = (event) => {
    const instance = this;
    admin.activateBooking(event.groupId, -1, function (data) {
      update(data);
      instance.activeStart = null;
      instance.updateEvents();
    });
  }

  cancelEvent = (event) => {
    const instance = this;
    const groupId = event.groupId;
    var seats = { ...event.extendedProps.seats };
    event = this.myRef.current.getApi().getEventById(event.id);
    event.remove();

    const newState = {
      ...instance.state,
    }
    if (instance.state.eventSelected && instance.state.eventSelected.id === event.id) {
      newState.eventSelected = undefined;
    }
    newState.selectedEvents = newState.selectedEvents.filter(e => e.id !== event.id);
    Object.keys(seats).forEach(c => {
      var tables = [];
      var oldTables = seats[c];
      instance.myRef.current.getApi().getEvents().forEach(e => {
        if (e.groupId === groupId) {
          e._def.resourceIds.forEach(resource => {
            if (resource !== null && oldTables.indexOf(resource) !== -1) tables.push(resource);
          });
        }
      });
      seats[c] = tables;
    });
    Object.keys(seats).forEach(c => {
      if (seats[c].lenght === 0) {
        delete seats[c];
      }
    });
    var count = 0;
    instance.myRef.current.getApi().getEvents().forEach(e => {
      if (e.groupId === groupId) {
        count++;
        e.setExtendedProp("seats", seats);
        e.setExtendedProp("changed", instance.eventChanged(e));
      }
    });
    if (count === 0) {
      if (groupId.startsWith("booking")) {
      } else {
        admin.unbookTable(groupId, -1, false, data => {
          instance.activeStart = null;
          instance.updateEvents();
        });
      }
      instance.clearBooking();
    } else {
    }
    instance.setState(newState);

    instance.updateHeader();
  }


  eventClassNames = arg => {
    let event = arg.event;
    const instance = this;
    var style = [];
    const clazz = [];
    if (event.extendedProps.tables) {
      if (event.extendedProps.occupied >= event.extendedProps.tables) {
        clazz.push("occupied_10");
      } else if (event.extendedProps.occupied - event.extendedProps.tables === -1 && event.extendedProps.tables > 1) {
        clazz.push("occupied_" + Math.round(10 * event.extendedProps.occupied / event.extendedProps.tables));
      } else {
        clazz.push("occupied_" + Math.round(10 * event.extendedProps.occupied / event.extendedProps.tables));
      }
      style.border = "unset";
      style["border-left"] = "1px dotted #ddd";
      style["border-top"] = "1px solid #ddd";
      style.bottom = "0";
      style["margin-bottom"] = "0";
    }
    if (event.extendedProps.booked) {
      clazz.push("booked");
      //style.backgroundColor = "white";
    } else {
      clazz.push("occupied");
    }
    if (this.state.view === "rooms")
      clazz.push("room");
    if (event.startEditable) {
      clazz.push("editable");
    }
    if (event.extendedProps.changed) {
      clazz.push("changed");
      style.backgroundColor = "yellow";
    }
    if (event.extendedProps.booking) {
      clazz.push("new");
      style.backgroundColor = "orange";
    }
    if (event.extendedProps.booked === true || event.extendedProps.booking === true) {
      event.editable = event.extendedProps.editable === true;
      var seatsAvailable = 0;
      var seatsUsed = 0;
      Object.keys(event.extendedProps.seats).forEach(c => {
        event.extendedProps.seats[c].forEach(cc => {
          if (Array.isArray(cc)) {
            if (event._def.resourceIds.indexOf(cc[0]) !== -1) seatsUsed += Number(c);
          } else {
            if (event._def.resourceIds.indexOf(cc) !== -1) seatsUsed += Number(c);
          }
        });
      });
      event._def.resourceIds.forEach(resource => {
        if (Array.isArray(resource)) {
          resource.forEach(resource => {
            seatsAvailable += getTable(resource).seats;
          })
        } else if (!resource.startsWith("room")) seatsAvailable += getTable(resource).seats;
      });
      if (instance.view === "rooms" && event.extendedProps.tables) {
        //event._def.resourceEditable = false;
        /*
        $(el)
          .find(".fc-title")
          .html(event.extendedProps.tables[event.id.split("booking-")[1]] + " - " + event.extendedProps.email + " - " + event.extendedProps.comment);
        return;
        */
      }
      if (seatsUsed > seatsAvailable) {
        clazz.push("overbooking");
        style.backgroundColor = "red";
        style.color = "yellow";
      } else if (seatsUsed < seatsAvailable) {
        clazz.push("underbooking");
        style.backgroundColor = "yellow";
        style.color = "black";
      }
      var ts = [];
      event._def.resourceIds.forEach(resource => {
        if (!resource.startsWith("room")) ts.push(resource);
      });
      /*
      if (ts.length > 1) {
        var tables = "";
        var seats = 0;
        ts.forEach(res => {
          if (tables !== "") tables += "+";
          tables += res;
          seats += getTable(res).seats;
        });
        $(el)
          .find(".fc-title")
          .html(tables + " = " + String.format(I18n.t("admin_local.book_a_table.number_of_seats"), seats) + " - " + event.extendedProps.email + " - " + event.extendedProps.comment);
    }*/
    }
    if (instance.state.eventSelected && event.groupId === instance.state.eventSelected.groupId) {
      clazz.push("selected");
      style["border-width"] = "3px";
    }
    if (event.groupId === instance.state.booking.groupId) {
      if (event.startEditable === true) {
        if (event.extendedProps.removeEnabled === true || event.extendedProps.booked === true) {
          //TODO
          /*
          $(el).append($("<i class='closeon icon-cancel-circled'></i>"));
     
          $(el)
            .find(".closeon")
            .on("click", function () {
              event.remove();
              const newState = {
                ...instance.state
              }
              if (instance.state.eventSelected && instance.state.eventSelected.id === event.id) {
                newState.eventSelected = undefined;
              }
              newState.selectedEvents = newState.selectedEvents.filter(e => e.id !== event.id);
              instance.setState(newState);
              Object.keys(instance.state.booking.seats).forEach(c => {
                var tables = [];
                var oldTables = event.extendedProps.seats[c];
                instance.myRef.current.getApi().getEvents().forEach(event => {
                  if (event.groupId === instance.state.booking.groupId) {
                    event._def.resourceIds.forEach(resource => {
                      if (resource !== null && oldTables.indexOf(resource) !== -1) tables.push(resource);
                    });
                  }
                });
                instance.state.booking.seats[c] = tables;
              });
              Object.keys(instance.state.booking.seats).forEach(c => {
                if (instance.state.booking.seats[c].lenght === 0) {
                  delete instance.state.booking.seats[c];
                }
              });
              console.log("seats", instance.state.booking.seats);
              instance.myRef.current.getApi().getEvents().forEach(event => {
                if (event.groupId === instance.state.booking.groupId) {
                  event.setExtendedProp("seats", instance.state.booking.seats);
                }
              });
     
              instance.updateHeader();
            });
            */
        }
      }
    }
    return clazz;
  }
  eventRender = eventInfo => {
    const instance = this;
    var el = eventInfo.el;
    let style = {};
    var event = eventInfo.event;
    if (event.extendedProps.tables) {
      if (event.extendedProps.occupied === event.extendedProps.tables) {
        style.backgroundColor = "#F05050";
        style.color = "white";
      } else if (event.extendedProps.occupied - event.extendedProps.tables === -1 && event.extendedProps.tables > 1) {
        style.backgroundColor =
          "linear-gradient(to top, orange, orange " +
          Math.round((100 * event.extendedProps.occupied) / event.extendedProps.tables) +
          "%, transparent " +
          Math.round((100 * event.extendedProps.occupied) / event.extendedProps.tables) +
          "%,transparent 100%)";
      } else {
        style.backgroundColor =
          "linear-gradient(to top, darkgray, darkgray " +
          Math.round((100 * event.extendedProps.occupied) / event.extendedProps.tables) +
          "%, transparent " +
          Math.round((100 * event.extendedProps.occupied) / event.extendedProps.tables) +
          "%, transparent 100%)";
      }
      style.border = "unset";
      style["border-left"] = "1px dotted #ddd";
      style["border-top"] = "1px solid #ddd";
      style.bottom = "0";
      style["margin-bottom"] = "0";
    }
    if (event.extendedProps.booked) {
      style.backgroundColor = "white";
    }
    if (event.extendedProps.changed) {
      style.backgroundColor = "yellow";
    }
    if (event.extendedProps.booking) {
      style.backgroundColor = "orange";
    }
    if (event.extendedProps.booked === true || event.extendedProps.booking === true) {
      event.editable = event.extendedProps.editable === true;
      var seatsAvailable = 0;
      var seatsUsed = 0;
      Object.keys(event.extendedProps.seats).forEach(c => {
        event.extendedProps.seats[c].forEach(cc => {
          if (Array.isArray(cc)) {
            if (event._def.resourceIds.indexOf(cc[0]) !== -1) seatsUsed += Number(c);
          } else {
            if (event._def.resourceIds.indexOf(cc) !== -1) seatsUsed += Number(c);
          }
        });
      });
      event._def.resourceIds.forEach(resource => {
        if (!resource.startsWith("room")) seatsAvailable += getTable(resource).seats;
      });
      if (instance.view === "rooms" && event.extendedProps.tables) {
        //event._def.resourceEditable = false;
        $(el)
          .find(".fc-title")
          .html(event.extendedProps.tables[event.id.split("booking-")[1]] + " - " + event.extendedProps.email + " - " + event.extendedProps.comment);
        return;
      }
      if (seatsUsed > seatsAvailable) {
        style.backgroundColor = "red";
        style.color = "yellow";
      } else if (seatsUsed < seatsAvailable) {
        style.backgroundColor = "yellow";
        style.color = "black";
      }
      var ts = [];
      event._def.resourceIds.forEach(resource => {
        if (!resource.startsWith("room")) ts.push(resource);
      });
      if (ts.length > 1) {
        var tables = "";
        var seats = 0;
        ts.forEach(res => {
          if (tables !== "") tables += "+";
          tables += res;
          seats += getTable(res).seats;
        });
        $(el)
          .find(".fc-title")
          .html(tables + " = " + String.format(I18n.t("admin_local.book_a_table.number_of_seats"), seats) + " - " + event.extendedProps.email + " - " + event.extendedProps.comment);
      }
    }
    if (instance.state.eventSelected && event.groupId === instance.state.eventSelected.groupId) {
      style["border-width"] = "3px";
    }
    if (event.groupId === instance.state.booking.groupId) {
      if (event.startEditable === true) {
        if (event.extendedProps.removeEnabled === true || event.extendedProps.booked === true) {
          $(el).append($("<i class='closeon icon-cancel-circled'></i>"));

          $(el)
            .find(".closeon")
            .on("click", function () {
              event.remove();
              const newState = {
                ...instance.state
              }
              if (instance.state.eventSelected && instance.state.eventSelected.id === event.id) {
                newState.eventSelected = undefined;
              }
              newState.selectedEvents = newState.selectedEvents.filter(e => e.id !== event.id);
              instance.setState(newState);
              Object.keys(instance.state.booking.seats).forEach(c => {
                var tables = [];
                var oldTables = event.extendedProps.seats[c];
                instance.myRef.current.getApi().getEvents().forEach(event => {
                  if (event.groupId === instance.state.booking.groupId) {
                    event._def.resourceIds.forEach(resource => {
                      if (resource !== null && oldTables.indexOf(resource) !== -1) tables.push(resource);
                    });
                  }
                });
                instance.state.booking.seats[c] = tables;
              });
              Object.keys(instance.state.booking.seats).forEach(c => {
                if (instance.state.booking.seats[c].lenght === 0) {
                  delete instance.state.booking.seats[c];
                }
              });
              instance.myRef.current.getApi().getEvents().forEach(event => {
                if (event.groupId === instance.state.booking.groupId) {
                  event.setExtendedProp("seats", instance.state.booking.seats);
                }
              });

              instance.updateHeader();
            });
        }
      }
    }
  };
  slotLaneClassNames = ({ date, level }) => {
    if (!this.myRef.current)
      return;
    switch (this.myRef.current.getApi().view.type) {
      case "resourceTimelineDay15":
      case "resourceTimelineDay30":
      case "resourceTimelineDayHour":
        if (level === 0)
          return "hidden";
        break;
      default: return "";
    }
    //var el = dayRenderInfo.el;
    // var offset = ee.utcOffset()*60000 -
    // date.utcOffset()*60000;
    //console.log(this);
    var show = false;
    this.props.businessHours.forEach(businessHour => {
      var day = date.getDay();
      if (businessHour.daysOfWeek.indexOf(day) === -1) {
        return;
      }
      var d = new Date(date.getTime());
      d.setSeconds(0);
      d.setMilliseconds(0);
      var startHour = new Date(date.getTime());
      startHour.setHours(businessHour.startTime.split(":")[0]);
      startHour.setMinutes(businessHour.startTime.split(":")[1]);
      startHour.setSeconds(0);
      startHour.setMilliseconds(0);
      var endHour = new Date(date.getTime());
      endHour.setHours(businessHour.endTime.split(":")[0]);
      endHour.setMinutes(businessHour.endTime.split(":")[1]);
      endHour.setSeconds(0);
      endHour.setMilliseconds(0);
      endHour = moment(endHour)
        .add(-this.state.slotDuration.split(":")[1], "minute")
        .toDate();

      if (startHour.getTime() <= date.getTime() && endHour.getTime() >= date.getTime() - 60000) {
        const visibleRange = this.getVisibleRange(this.myRef.current.getApi().view.activeStart);
        if (visibleRange.start.getTime() <= date.getTime() && visibleRange.end.getTime() >= date.getTime())
          show = true;
      }
      /*/
            if (date.getTime() === startHour.getTime() && businessHour.showStart === true) {
            el.style["border-left"] = "5px dashed darkslategray";
              el.style["margin-left"] = "3px";
            } else if (date.getTime() === endHour.getTime() && businessHour.showEnd === true) {
            el.style["border-right"] = "5px dashed darkslategray";
              el.style["margin-right"] = "3px";
            }
            */
    });
    if (!show)
      return "no-width"
    if (this.state.start && date.getTime() === this.state.start.getTime())
      return "selected selected-left";
    if (this.state.end && date.getTime() === this.state.end.getTime())
      return "selected-left";
    if (this.state.start && this.state.end && date.getTime() > this.state.start.getTime() && date.getTime() < this.state.end.getTime())
      return "selected";
  }
  slotsShrinked = false
  slotLabelClassNames = ({ date, level }) => {
    this.slotsShrinked = true;
    if (!this.myRef.current)
      return;
    switch (this.myRef.current.getApi().view.type) {
      case "resourceTimelineDay15":
      case "resourceTimelineDay30":
      case "resourceTimelineDayHour":
        if (level === 0 && this.props.businessHours.length > 1)
          return "hidden";
        break;
      default: return "";
    }
    //var el = dayRenderInfo.el;
    // var offset = ee.utcOffset()*60000 -
    // date.utcOffset()*60000;
    //console.log(this);
    var show = false;
    this.props.businessHours.forEach(businessHour => {
      var day = date.getDay();
      if (businessHour.daysOfWeek.indexOf(day) === -1) {
        return;
      }
      var d = new Date(date.getTime());
      d.setSeconds(0);
      d.setMilliseconds(0);
      var startHour = new Date(date.getTime());
      startHour.setHours(businessHour.startTime.split(":")[0]);
      startHour.setMinutes(businessHour.startTime.split(":")[1]);
      startHour.setSeconds(0);
      startHour.setMilliseconds(0);
      var endHour = new Date(date.getTime());
      endHour.setHours(businessHour.endTime.split(":")[0]);
      endHour.setMinutes(businessHour.endTime.split(":")[1]);
      endHour.setSeconds(0);
      endHour.setMilliseconds(0);
      endHour = moment(endHour)
        .add(-this.state.slotDuration.split(":")[1], "minute")
        .toDate();

      if (startHour.getTime() <= date.getTime() && endHour.getTime() >= date.getTime() - 60000) {
        const visibleRange = this.getVisibleRange(this.myRef.current.getApi().view.activeStart);
        if (visibleRange.start.getTime() <= date.getTime() && visibleRange.end.getTime() >= date.getTime())
          show = true;
      }
      /*/
            if (date.getTime() === startHour.getTime() && businessHour.showStart === true) {
            el.style["border-left"] = "5px dashed darkslategray";
              el.style["margin-left"] = "3px";
            } else if (date.getTime() === endHour.getTime() && businessHour.showEnd === true) {
            el.style["border-right"] = "5px dashed darkslategray";
              el.style["margin-right"] = "3px";
            }
            */
    });
    if (!show)
      return "no-width"
  }
  dateClick = dateClickInfo => {
    this.setState({
      start: dateClickInfo.date,
      end: moment(dateClickInfo.date).add("minute", this.duration).toDate(),
      resource: dateClickInfo.resource
    })
    this.clearBooking();
  }
  select = selectionInfo => {
    this.setState({
      start: selectionInfo.start,
      end: selectionInfo.end,
      resource: selectionInfo.resource
    })
  }
  unselect = (event) => {
    if (event.jsEvent.target && event.jsEvent.target.id === "count")
      return;
    this.setState({
      start: null,
      end: null,
      resource: null
    })
  }
  eventClick = eventClickInfo => {
    //if (!$(eventClickInfo.jsEvent.target).hasClass("fc-event-title"))
    //      return;
    const instance = this;
    var event = eventClickInfo.event;
    if (eventClickInfo.jsEvent && eventClickInfo.jsEvent.target.id === "cancel") {
      instance.cancelEvent(event);
      return;
    }
    if (eventClickInfo.jsEvent && eventClickInfo.jsEvent.target.id === "activate") {
      instance.activateBooking(event);
      return;
    }

    if (!(event.extendedProps.booked || event.extendedProps.isPickup || event.extendedProps.isHomeDelivery) && !event.extendedProps.booking) {
      this.setState({
        start: moment(event.start).toDate(),
        end: moment(event.start).add(this.duration, "minutes").toDate()
      });
      instance.clearBooking();
      return;
    }
    instance.clearBooking();

    this.setState({
      start: moment(event.start).toDate(),
      end: moment(event.end).toDate(),
      email: event.extendedProps.email,
      name: event.extendedProps.name,
      phone: event.extendedProps.phone,
      comment: event.extendedProps.comment,
      booking: {
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
        groupId: event.groupId,
        seats: event.extendedProps.seats,
        changed: event.extendedProps.changed
      },
      eventSelected: event,
      selectedEvents: this.getSelectedEvents(event.groupId)
    });
    instance.updateHeader(event);
    /*
      instance.enableButtons(true);
   
      instance.myRef.current.getApi().rerenderEvents();
      */
  };
  datesRender = () => {
  };

  mounted = false

  viewDidMount = (ref) => {
    if (this.mounted)
      return;
    this.mounted = true;
    const el = $(ref.el).parents("#bookTable");

    //$(".fc-personCount-button").html($('<input id="count"></input>'));
    //el.find(".fc-personCount-button").addClass("icon-minus");
    el.find(".fc-persons-button").addClass("icon-plus");
    el.find(".fc-persons-button").addClass("hidden-print");
    el.find(".fc-persons-button").html("");
    el.find(".fc-icon-cancel-circled").addClass("icon-cancel-circled");
    el.find(".fc-tables2-button").html($('<span id="tables"></span>'));

    var persons = el.find(".fc-persons-button");
    var personsCount = $('<input class="hidden-print" id="count"></input>');
    this.personCount = personsCount;
    personsCount.insertBefore(persons);

    personsCount.focusin(() => {
      el.find("#numbersBlock")
        .parent()
        .show();
    });
    personsCount.focusout(() => {
      el.find("#numbersBlock").parent().hide();
    });

    var t2 = el.find(".fc-t2-button");
    var comment = $('<span id="tables"></span>');
    comment.insertBefore(t2);

    $(".fc-icon-cancel-circled").addClass("icon-cancel-circled");
    this.updateHeader();
  };

  setView = (view) => {
    localStorage.tableReservationComponentView = view;
    this.setState({ view: view });
    //instance.myRef.current.getApi().refetchResources();
    //instance.myRef.current.getApi().refetchEvents();
  }

  resourceColumns = (print) => [
    {
      group: !print,
      headerContent: I18n.t("admin_local.book_a_table.room"),
      field: "room",
    },
    {
      group: !print,
      headerContent: I18n.t("admin_local.book_a_table.seats"),
      field: "seats"
    },
    {
      headerContent: I18n.t("admin_local.book_a_table.tables"),
      field: "title"
    }
  ];

  footer = {
    left: "cancel",
    right: "edit,save2"
  };

  views = {
    week: {
      type: "resourceTimeline",
      buttonText: 'week',
      slotDuration: '06:00',
      snapDuration: '06:00'
    },
    resourceTimelineDay15: {
      type: "resourceTimeline",
      buttonText: '15"',
      slotDuration: '00:15',
      snapDuration: '00:15',
      visibleRange: t => this.getVisibleRange(t),
    },
    resourceTimelineDay30: {
      type: "resourceTimeline",
      buttonText: '30"',
      slotDuration: "00:30",
      visibleRange: t => this.getVisibleRange(t)
    },
    resourceTimelineDayHour: {
      type: "resourceTimeline",
      buttonText: '60"',
      slotDuration: "00:60",
      visibleRange: t => this.getVisibleRange(t)
    }
  };

  header = {
    left: "title today prevButton,nextButton tables,rooms",
    center: "resourceTimelineDay15,resourceTimelineDay30,resourceTimelineDayHour,resourceTimelineWeek,resourceTimelineMonth listDay,listWeek",
    right: "twoPerson,fourPerson,sixPerson,eightPerson,tenPerson,persons t1,t2 print,settings"
  };

  dayRender = dayRenderInfo => {
    var date = dayRenderInfo.date;
    var el = dayRenderInfo.el;
    // var offset = ee.utcOffset()*60000 -
    // date.utcOffset()*60000;
    //console.log(this);
    this.props.businessHours.forEach(businessHour => {
      var day = date.getDay();
      if (businessHour.daysOfWeek.indexOf(day) === -1) {
        return;
      }
      var d = new Date(date.getTime());
      d.setSeconds(0);
      d.setMilliseconds(0);
      var startHour = new Date(date.getTime());
      startHour.setHours(businessHour.startTime.split(":")[0]);
      startHour.setMinutes(businessHour.startTime.split(":")[1]);
      startHour.setSeconds(0);
      startHour.setMilliseconds(0);
      var endHour = new Date(date.getTime());
      endHour.setHours(businessHour.endTime.split(":")[0]);
      endHour.setMinutes(businessHour.endTime.split(":")[1]);
      endHour.setSeconds(0);
      endHour.setMilliseconds(0);
      endHour = moment(endHour)
        .add(-this.state.slotDuration.split(":")[1], "minute")
        .toDate();
      if (date.getTime() === startHour.getTime() && businessHour.showStart === true) {
        el.style["border-left"] = "5px dashed darkslategray";
        el.style["margin-left"] = "3px";
      } else if (date.getTime() === endHour.getTime() && businessHour.showEnd === true) {
        el.style["border-right"] = "5px dashed darkslategray";
        el.style["margin-right"] = "3px";
      }
    });
  };

  getVisibleRange = date => {
    var ss = moment(date).startOf("day");
    ss.add(this.props.myStatus.restaurant_settings["business-day-starts-at"], "Hour");
    var ee = moment(date).startOf("day");
    ee.add(1, "Day").add(-1, "Second");
    if (this.props.myStatus.restaurant_settings["business-day-starts-at"] > this.props.myStatus.restaurant_settings["business-day-ends-at"])
      ee.add(this.props.myStatus.restaurant_settings["business-day-ends-at"], "Hour");
    else ee.add(this.props.myStatus.restaurant_settings["business-day-ends-at"] - 24, "Hour");

    const visibleRange = {
      start: ss.toDate(),
      end: ee.toDate()
    };
    return visibleRange;
  };

  customButtons = instance => {
    return {
      today: {
        text: I18n.t("admin_local.today"),
        click: function () {
          instance.myRef.current.getApi().gotoDate(moment().toDate());
          instance.updateEvents();
        }
      },
      tables: {
        text: I18n.t("admin_local.book_a_table.tables"),
        click: function () {
          instance.setView("tables");
          localStorage.tableReservationComponentView2 = "resourceTimelineDay15";
          instance.myRef.current.getApi().changeView('resourceTimelineDay15', moment(instance.state.start).format("YYYY-MM-DD"));
          instance.updateEvents();
        }
      },
      rooms: {
        text: I18n.t("admin_local.book_a_table.rooms"),
        click: () => {
          instance.setView("rooms");
          localStorage.tableReservationComponentView2 = "resourceTimelineDay15";
          instance.myRef.current.getApi().changeView('resourceTimelineDay15', moment(instance.state.start).format("YYYY-MM-DD"));
          instance.updateEvents();
        }
      },
      daypicker: {
        text: I18n.t("admin_local.date"),
        click: function () {
          var w = $(instance.datePickerWidget).css("width");
          if (w === "0px") {
            $(instance.datePickerWidget).css("width", "39em");
          } else {
            $(instance.datePickerWidget).css("width", "0em");
          }
        }
      },
      nextButton: {
        icon: "chevron-right",
        click: function () {
          instance.myRef.current.getApi().next();
          instance.updateEvents();
        }
      },
      prevButton: {
        icon: "chevron-left",
        click: function () {
          const date = instance.myRef.current.getApi().view.activeStart;
          switch (instance.myRef.current.getApi().view.type) {
            case "resourceTimelineDay15":
            case "resourceTimelineDay30":
            case "resourceTimelineDayHour":
              instance.myRef.current.getApi().gotoDate(moment(date).add('day', -1).toDate());
              break;
            case "resourceTimelineWeek":
              instance.myRef.current.getApi().gotoDate(moment(date).add('week', -1).toDate());
              break;
            case "resourceTimelineMonth":
              instance.myRef.current.getApi().gotoDate(moment(date).add('month', -1).toDate());
              break;
            default:
              instance.myRef.current.getApi().prev();
          }
          //instance.myRef.current.getApi().prev();
          //console.log(instance.myRef.current.getApi().view.activeStart);
          instance.updateEvents();
        }
      },
      resourceTimelineDay15: {
        text: '15"',
        click: () => {
          localStorage.tableReservationComponentView2 = "resourceTimelineDay15";
          instance.myRef.current.getApi().changeView('resourceTimelineDay15', moment(instance.myRef.current.getApi().view.activeStart).format("YYYY-MM-DD"));
          instance.updateEvents();
        }
      },
      resourceTimelineDay30: {
        text: '30"',
        click: () => {
          localStorage.tableReservationComponentView2 = "resourceTimelineDay30";
          instance.myRef.current.getApi().changeView('resourceTimelineDay30', moment(instance.myRef.current.getApi().view.activeStart).format("YYYY-MM-DD"));
          instance.updateEvents();
        }
      },
      resourceTimelineDayHour: {
        text: '60"',
        click: () => {
          localStorage.tableReservationComponentView2 = "resourceTimelineDayHour";
          instance.myRef.current.getApi().changeView('resourceTimelineDayHour', moment(instance.myRef.current.getApi().view.activeStart).format("YYYY-MM-DD"));
          instance.updateEvents();
        }
      },
      resourceTimelineWeek: {
        text: I18n.t("admin_local.week"),
        click: () => {
          localStorage.tableReservationComponentView2 = "resourceTimelineWeek";
          instance.myRef.current.getApi().changeView('resourceTimelineWeek', moment(instance.myRef.current.getApi().view.activeStart).format("YYYY-MM-DD"));
          instance.updateEvents();
        }
      },
      resourceTimelineMonth: {
        text: I18n.t("admin_local.month"),
        click: () => {
          localStorage.tableReservationComponentView2 = "resourceTimelineMonth";
          instance.myRef.current.getApi().changeView('resourceTimelineMonth');
          instance.updateEvents();
        }
      },
      listDay: {
        text: <div className="icon-list">{I18n.t("admin_local.day")}</div>,
        click: () => {
          localStorage.tableReservationComponentView2 = "listDay";
          instance.myRef.current.getApi().changeView('listDay');
          instance.setState({ view: "tables" })
          instance.updateEvents();
        }
      },
      listWeek: {
        text: <div className="icon-list">{I18n.t("admin_local.week")}</div>,
        click: () => {
          localStorage.tableReservationComponentView2 = "listWeek";
          instance.myRef.current.getApi().changeView('listWeek');
          instance.setState({ view: "tables" })
          instance.updateEvents();
        }
      },
      twoPerson: {
        text: <div className={this.getPersonButtonClass()} disabled={!this.state.start}>2</div>,
        click: function () {
          instance.createBooking(2);
        }
      },
      fourPerson: {
        text: <div className={this.getPersonButtonClass()} disabled={!this.state.start}>4</div>,
        click: function () {
          instance.createBooking(4);
        }
      },
      sixPerson: {
        text: <div className={this.getPersonButtonClass()} disabled={!this.state.start}>6</div>,
        click: function () {
          instance.createBooking(6);
        }
      },
      eightPerson: {
        text: <div className={this.getPersonButtonClass()} disabled={!this.state.start}>8</div>,
        click: function () {
          instance.createBooking(8);
        }
      },
      tenPerson: {
        text: <div className={this.getPersonButtonClass()} disabled={!this.state.start}>10</div>,
        click: function () {
          instance.createBooking(10);
        }
      },
      persons: {
        text: "",
        click: function () {
          var count = instance.personCount.val();
          if (isNaN(count))
            return;
          instance.personCount.val("");

          //instance.modal.find("#numbersBlock").parent().hide();
          if (count > 0) {
            instance.createBooking(count);
          }
        }
      },
      edit: {
        text: I18n.t("admin_local.edit"),
        click: function () {
          instance.getSelectedEvents().forEach(event => {
            event = instance.myRef.current.getApi().getEventById(event.id);
            event.setProp("editable", !(event.startEditable === true));
            instance.myRef.current.getApi().rerenderEvents();
          });
        }
      },
      t1: {
        text: I18n.t("admin_local.tables")
      },
      t2: {
        text: " X ",
        icon: "cancel-circled",
        click: function () {
          var events = instance.getSelectedEvents();
          if (events.length > 0) {
            if (events[0].id.startsWith("booking")) {
              events.forEach(event => {
                event.remove();
              });
            } else {
              admin.unbookTable(events[0].groupId, -1, false, function (data) {
                //update(data);
                instance.updateEvents();
                //instance.myRef.current.getApi().refetchEvents();
              });
            }
            instance.clearBooking();
          }
        }
      },
      print: {
        text: I18n.t("admin_local.print"),
        click: function () {
          var events = instance.myRef.current.getApi().getEvents();
          var start, end;
          events.filter(event => event.extendedProps.booked).forEach(event => {
            if (!start || moment(start).isAfter(moment(event.start)))
              start = moment(event.start);
            if (!end || moment(end).isBefore(moment(event.end)))
              end = moment(event.end);
          })
          instance.setState({ minTime: start.format("HH:mm:ss"), maxTime: end.format("HH:mm:ss"), print: true, width: "210mm" })
          setTimeout(() => {
            window.print();
            instance.setState({ minTime: instance.minTime(), maxTime: instance.maxTime(), print: true, width: "0mm" });
            instance.setState({ minTime: instance.minTime(), maxTime: instance.maxTime(), print: true, width: "100%" });
          }, 100);

        }
      },
      settings: {
        text: I18n.t("admin_local.settings"),
        click: function () {
          settings("preBooking");
        }
      },
      cancel: {
        text: I18n.t("admin_local.close"),
        click: function () {
          instance.modal.modal("hide");
        }
      }
    };
  };

  getPersonButtonClass = () => {
    return "fc-button " + (this.state.start ? 'fc-button-primary' : 'disabled');
  }

  getAllTableOccupations = (handler, errorHandler) => {
    if (!this.myRef.current)
      return;
    const instance = this;
    var validRangeStart, validRangeEnd;
    switch (this.myRef.current.getApi().view.type) {
      case "resourceTimelineDay15":
      case "resourceTimelineDay30":
      case "resourceTimelineDayHour":
        validRangeStart = this.getVisibleRange(this.myRef.current.getApi().view.activeStart);
        validRangeEnd = this.getVisibleRange(this.myRef.current.getApi().view.activeStart);
        break;
      default:
        validRangeStart = this.getVisibleRange(this.myRef.current.getApi().view.activeStart);
        validRangeEnd = this.getVisibleRange(this.myRef.current.getApi().view.activeEnd);
    }
    var search = {
      fromDate: validRangeStart.start,
      toDate: validRangeEnd.end,
      active: true
    };
    post("adminService/" + sessionStorage.restaurantSelected + "/filterTableOccupationsOnly?lang=" + localStorage.language, search).done((data) => {
      instance.activeStart = instance.myRef.current.getApi().view.activeStart.getTime();
      instance.activeEnd = instance.myRef.current.getApi().view.activeEnd.getTime();
      resultHandler(data, handler, errorHandler);
    });
  };

  updateEvents = tableOccupations_data => {
    if (!this.myRef.current || (this.activeStart === this.myRef.current.getApi().view.activeStart.getTime() && this.activeEnd === this.myRef.current.getApi().view.activeEnd.getTime())) {
      return;
    }

    var events = [];
    var roomEvents = [];

    function getRoomEvent(roomId, start, seats) {
      var event = null;
      roomEvents.forEach(e => {
        if (e.id === "room-" + roomId + "-" + seats + "-" + start.toDate().getTime()) {
          event = e;
        }
      });
      return event;
    }

    const instance = this;
    const isPickupTableNumber = this.props.myStatus.restaurant_settings["enabled-features"].pickup["table-number"];
    const isHomeDeliveryTableNumber = this.props.myStatus.restaurant_settings["enabled-features"].homedelivery["table-number"];

    var validRangeStart, validRangeEnd;
    switch (this.myRef.current.getApi().view.type) {
      case "resourceTimelineDay15":
      case "resourceTimelineDay30":
      case "resourceTimelineDayHour":
        validRangeStart = this.getVisibleRange(this.myRef.current.getApi().view.activeStart);
        validRangeEnd = this.getVisibleRange(this.myRef.current.getApi().view.activeStart);
        break;
      default:
        validRangeStart = this.getVisibleRange(this.myRef.current.getApi().view.activeStart);
        validRangeEnd = this.getVisibleRange(this.myRef.current.getApi().view.activeEnd);
    }


    this.getAllTableOccupations(tableOccupations_data => {

      tableOccupations_data.forEach(function (v, i) {
        v.title = "";
        v.selected = false;
        v.isActive = v.active;
        if (!v.bookedProperties) {
          v.bookedProperties = {
            email: "",
            phone: "",
            name: "",
            comment: "",
            seats: {},
          };
        }
        delete v.active;
        v.folder = false;
        var editable = false;
        const isPickup = v.tableNumbers[0] === isPickupTableNumber;
        const isHomeDelivery = v.tableNumbers[0] === isHomeDeliveryTableNumber;
        var start = null;
        var end = null;

        var title;

        var backgroundColorClass = [];

        if (isPickup || isHomeDelivery) {
          return;
        } else {
          start = new Date(v.occupationStart && !isPickup && !isHomeDelivery ? v.occupationStart : Math.max(v.bookedOccupationStart, instance.myRef.current.getApi().view.activeStart.getTime()));
          if (moment(start).isBefore(moment(instance.myRef.current.getApi().view.activeStart))) start = instance.myRef.current.getApi().view.activeStart;
          end = !v.occupationEnd ? (!v.bookedOccupationEnd ? new Date(Math.max(moment().add(15, "minutes"), moment(start).add(instance.duration, "minutes"))) : new Date(v.bookedOccupationEnd)) : new Date(v.occupationEnd);
          end = new Date(Math.min(end.getTime(), validRangeEnd.end.getTime()));
          start = new Date(Math.max(start.getTime(), validRangeStart.start.getTime()));
        }

        var startQuarter = moment(start);
        var endQuarter = moment(end);
        startQuarter = startQuarter.set({ minutes: Math.floor(startQuarter.get("minute") / 15) * 15, seconds: 0, milliseconds: 0 });
        endQuarter = endQuarter.set({ minutes: Math.ceil(endQuarter.get("minute") / 15) * 15, seconds: 0, milliseconds: 0 });

        var tableNumbers = [];

        if (!v.booked || isPickup || isHomeDelivery) {
          v.tableNumbers.forEach(table => {
            tableNumbers.push(table);
            var e;
            e = {
              start: start,
              end: end,
              started: v.occupationStart !== null,
              finished: v.occupationEnd !== null,
              id: v.id + "-" + table,
              resourceId: "" + table,
              title: title,
              editable: editable,
              resourceEditable: v.booked || isPickup || isHomeDelivery,
              booked: v.booked,
              isPickup: isPickup,
              isHomeDelivery: isHomeDelivery,
              tableOccupation: v.id,
              classNames: backgroundColorClass,
              groupId: v.id,
              extendedProps: {
                seats: v.bookedProperties.seats,
                email: v.bookedProperties.email,
                name: v.bookedProperties.name,
                phone: v.bookedProperties.phone,
                comment: v.bookedProperties.comment,
                booked: v.booked,
                wasBooked: v.bookedProperties.seats && Object.keys(v.bookedProperties.seats).length > 0,
                original: {
                  start: start,
                  end: end,
                  email: v.bookedProperties.email,
                  name: v.bookedProperties.name,
                  phone: v.bookedProperties.phone,
                  comment: v.bookedProperties.comment,
                  seats: v.bookedProperties.seats,
                }
              }
            };
            events.push(e);
          });
        } else {
          var seats = v.bookedProperties.seats;
          var changed = false;

          if (instance.state.booking.groupId === v.id) {
            start = instance.state.booking.start.toDate();
            end = instance.state.booking.end.toDate();
            editable = instance.state.booking.editable;
            seats = instance.state.booking.seats;
            changed = instance.state.booking.changed;
            v.bookedProperties.email = this.state.email;
            v.bookedProperties.name = this.state.name;
            v.bookedProperties.phone = this.state.phone;
            v.bookedProperties.comment = this.state.comment;
          }
          Object.keys(seats).forEach(s => {
            seats[s].forEach((t, ind) => {
              if (Array.isArray(t))
                t.forEach(t => {
                  tableNumbers.push(t);
                });
              else tableNumbers.push(t);

              var resourceIds = Array.isArray(t) ? t : [t];

              var e = {
                start: start,
                end: end,
                started: v.occupationStart !== null,
                finished: v.occupationEnd !== null,
                id: v.id + "-" + ind,
                resourceIds: resourceIds,
                title: "",
                editable: editable,
                resourceEditable: v.booked,
                booked: v.booked,
                tableOccupation: v.id,
                groupId: v.id,
                extendedProps: {
                  seats: seats,
                  count: t,
                  email: v.bookedProperties.email,
                  name: v.bookedProperties.name,
                  phone: v.bookedProperties.phone,
                  comment: v.bookedProperties.comment,
                  changed: changed,
                  original: {
                    start: start,
                    end: end,
                    seats: seats,
                    email: v.bookedProperties.email,
                    name: v.bookedProperties.name,
                    phone: v.bookedProperties.phone,
                    comment: v.bookedProperties.comment,
                  }
                }
              }
              events.push(e);
            });

            //newEvent._def.resourceIds=seats[s];
          });
        }

        var period = 15;
        switch (instance.myRef.current.getApi().view.type) {
          case "resourceTimelineDay15":
            period = 15;
            break;
          case "resourceTimelineDay30":
            period = 15;
            break;
          case "resourceTimelineDayHour":
            period = 30;
            break;
          case "resourceTimelineWeek":
            period = 60 * 6;
            break;
          case "resourceTimelineMonth":
            period = 60 * 24;
            break;
          default:
        }

        tableNumbers.forEach(t => {
          t = getTable(t);
          if (t && t.roomId) {
            var room = admin.getRoom(t.roomId);
            if (room.isActive === false) return;
            for (let tt = startQuarter.clone(); tt.isBefore(endQuarter); tt.add(period, "minutes")) {
              var seats = Object.keys(room.tableCount).sort(function (a, b) {
                return a - b;
              });
              seats.forEach(seatCount => {
                var event = getRoomEvent(t.roomId, tt, seatCount);
                if (!event) {
                  //events.push(
                  (event = {
                    start: tt.toDate(),
                    end: tt
                      .clone()
                      .add(period, "minutes")
                      .toDate(),
                    id: "room-" + t.roomId + "-" + seatCount + "-" + tt.toDate().getTime(),
                    resourceId: "room-" + t.roomId + "-" + seatCount,
                    title: "",
                    editable: false,
                    resourceEditable: false,
                    extendedProps: {
                      tables: room.tableCount[seatCount],
                      occupied: 0,
                      seats: seatCount,
                      original: {
                        start: tt.toDate(),
                        end: tt
                          .clone()
                          .add(period, "minutes")
                          .toDate(),
                        tables: room.tableCount[seatCount],
                        occupied: 0,
                        seats: seatCount,
                      }
                    }
                  })
                  //);
                  roomEvents.push(event);
                }
              });
              var event = getRoomEvent(t.roomId, tt, t.seats);
              if (!event)
                return;
              event.extendedProps.occupied++;
              var lines = "\r\n";
              event.title = lines + event.extendedProps.occupied;

              event = getRoomEvent(t.roomId, tt, "undefined");
              if (!event) {
                //events.push(
                (event = {
                  start: tt.toDate(),
                  end: tt
                    .clone()
                    .add(period, "minutes")
                    .toDate(),
                  id: "room-" + t.roomId + "-undefined-" + tt.toDate().getTime(),
                  resourceId: "room-" + t.roomId,
                  title: "",
                  editable: false,
                  resourceEditable: false,
                  extendedProps: {
                    tables: room.tables.length,
                    occupied: 0,
                    original: {
                      tables: room.tables.length,
                      start: tt.toDate(),
                      end: tt
                        .clone()
                        .add(period, "minutes")
                        .toDate()
                    }
                  }
                })
                //);
                roomEvents.push(event);
              }
              event = getRoomEvent(t.roomId, tt, "undefined");
              event.extendedProps.occupied++;
              lines = "\r\n";
              event.title = lines + event.extendedProps.occupied;
            }
          }
        });
      });
      /*
      events = events.map(event => {
            event.extendedProps.original = { ...event.extendedProps };
      })
      /*
      roomEvents = roomEvents.map(event => {
            event.extendedProps.original = { ...event.extendedProps };
      })*/
      this.myRef.current.getApi().removeAllEvents();

      this.setState({ tableEvents: events, roomEvents: roomEvents, tableOccupations_data: tableOccupations_data });
    });
  };


  getSelectedEvents = (groupId) => {
    groupId = groupId ? groupId : this.state.booking ? this.state.booking.groupId : "";
    if (!groupId)
      return [];
    var events = [];
    this.myRef.current.getApi().getEvents().forEach(event => {
      if (event.groupId === groupId)
        events.push(event);
    })
    return events;
  };

  edit = () => {

    let event = this.state.selectedEvents[0];
    //event.setProp("editable", !(event.startEditable === true));
    //console.log(event);
    const id = event.id;
    event = this.myRef.current.getApi().getEventById(id);
    //console.log(event.editable, event.startEditable, event.durationEditable)
    const startEditable = !event.startEditable;
    event.setProp("eventResourceEditable", startEditable);
    //event = this.myRef.current.getApi().getEventById(id);
    event.setProp("editable", startEditable);
    //event = this.myRef.current.getApi().getEventById(id);
    event.setProp("startEditable", startEditable);
    //event = this.myRef.current.getApi().getEventById(id);
    event.setProp("durationEditable", startEditable);
    //event = this.myRef.current.getApi().getEventById(id);
    //console.log(event.editable, event.startEditable, event.durationEditable)
    //instance.myRef.current.getApi().rerenderEvents();
    //});
  };

  save_and_close = () => {
    this._save(this.props.onClose);
  }
  save = (handler) => {
    this._save();
  }
  _save = (handler) => {
    const instance = this;
    const groupIds = [];
    this.myRef.current.getApi().getEvents().filter(event => event.extendedProps.booked && (this.eventChanged(event) || event.groupId === "booking")).forEach(event => {
      if (groupIds.indexOf(event.groupId) !== -1)
        return;
      groupIds.push(event.groupId);
      if (event.groupId && event.groupId !== "booking") {
        if (!event.extendedProps.name || !event.extendedProps.phone) {
          instance.eventClick({ event: event });
          return;
        }
        post("adminService/" + sessionStorage.restaurantSelected + "/updateBooking/" + event.groupId, {
          start: event.start.getTime(),
          end: event.end.getTime(),
          seats: event.extendedProps.seats,
          email: event.extendedProps.email,
          name: event.extendedProps.name,
          phone: event.extendedProps.phone,
          comment: event.extendedProps.comment
        }).done(data => {
          update(data);
          if (handler) {
            handler();
            return;
          }

          instance.myRef.current.getApi().removeAllEvents();
          this.setState({
            booking: {
              events: [],
              seats: [],
              rooms: [],
              tables: [],
              start: null,
              end: null,
              customer: null,
              phone: null,
              name: null
            },
            selectedEvents: [],
            eventSelected: undefined,
            email: "",
            name: "",
            phone: "",
            comment: "",
            tables: ""
          });
          this.activeStart = null;
          this.updateEvents();
          this.clearBooking();
          //this.refetchEvents();
        });
      } else {
        if (!event.extendedProps.name || !event.extendedProps.phone) {
          instance.eventClick({ event: event });
          return;
        }
        post("adminService/" + sessionStorage.restaurantSelected + "/bookTable?lang=" + localStorage.language, {
          start: event.start.getTime(),
          end: event.end.getTime(),
          seats: event.extendedProps.seats,
          email: event.extendedProps.email,
          name: event.extendedProps.name,
          phone: event.extendedProps.phone,
          comment: event.extendedProps.comment
        }).done(data => {
          update(data);
          if (handler) {
            handler();
            return;
          }
          if (instance.props.update) instance.props.update(data);

          instance.myRef.current.getApi().removeAllEvents();

          this.setState({
            booking: {
              events: [],
              seats: [],
              rooms: [],
              tables: [],
              start: null,
              end: null,
              customer: null,
              phone: null,
              name: null
            },
            selectedEvents: [],
            eventSelected: undefined,
            email: "",
            name: "",
            phone: "",
            comment: "",
            tables: ""
          });
          this.activeStart = null;
          this.updateEvents();
          this.clearBooking();
          //this.refetchEvents();
        });
      }
    });
  };

  eventChanged = event => {
    if (!event.extendedProps.original) {
      return true;
    }
    if (event.start && event.start.getTime() !== event.extendedProps.original.start.getTime())
      return true;
    if (event.end && event.end.getTime() !== event.extendedProps.original.end.getTime())
      return true;
    if (event.extendedProps.email !== event.extendedProps.original.email)
      return true;
    if (event.extendedProps.name !== event.extendedProps.original.name)
      return true;
    if (event.extendedProps.phone !== event.extendedProps.original.phone)
      return true;
    if (event.extendedProps.comment !== event.extendedProps.original.comment)
      return true;
    //console.log(event.extendedProps.seats, event.extendedProps.original.seats)
    if (JSON.stringify(event.extendedProps.seats) !== JSON.stringify(event.extendedProps.original.seats))
      return true;

    return false;
  }
}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
      rooms: props.rootReducer.rooms,
      tables: props.rootReducer.tablesByRooms.filter(t => t.roomId),
      businessHours: props.rootReducer.businessHours
    };
  }
  return {};
};

function roomComparator(a, b) {
  if (a.order === b.order && a.id !== b.id) {
    if (a.room === b.room && a.id !== b.id) {
      return a.seats - b.seats;
    }
    return a.room.localeCompare(b.room);
  }
  return a.order - b.order;
}

export default connect(mapStateToProps)(TableReservationComponent);
