import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ReactDOM from "react-dom";
import Form from 'react-jsonschema-form';
import '../css/settings.css';
import { I18n } from 'react-redux-i18n';
import { getMyStatus } from '../js/admin';
import auth, { post } from '../js/auth';
import { locals } from '../langs/languages';

class SettingsEditor extends Component {

    state = {
        changed: false
    }

    static getDerivedStateFromProps(props, state) {
        return {
            formdata: state.formdata || props.settings,
            changed: state.changed
        }
    }
    unmount = () => {
        ReactDOM.unmountComponentAtNode(this.props.container);
    }

    setupCKEditor = (id, lang) => {
        const lid = "root_" + id.replace(/\//g, "_") + "_" + lang;
        if (document.getElementById(lid) && !window.CKEDITOR.instances[lid]) {
            window.CKEDITOR.replace(lid);
            if (this.state.formdata[id] && this.state.formdata[id][lang]) {
                window.CKEDITOR.instances[lid].setData(this.state.formdata[id][lang]);
            }
            window.CKEDITOR.instances[lid].on('change', () => {
                const newState = { formdata: { ...this.state.formdata }, changed: true };
                var obj = newState.formdata;
                id.split("/").forEach(id => {
                    const obj2 = obj[id];
                    if (obj2 === undefined) {
                        obj[id] = {};
                        obj = obj[id]
                        return obj;
                    }
                    obj = obj2;
                })
                newState.formdata[lid] = "";
                obj[lang] = window.CKEDITOR.instances[lid].getData();
                this.setState(newState);
            });
        }
    }

    destroyCKEditor = (id, lang) => {
        const lid = "root_" + id.replace(/\//g, "_") + "_" + lang;
        console.log(lid);
        if (window.CKEDITOR.instances[lid])
            window.CKEDITOR.instances[lid].destroy();
        //delete window.CKEDITOR.instances[lid];
    }

    componentDidMount = () => {
        this.setupCKEditor("description_header", "hu");
        this.setupCKEditor("description_header", "en");
        this.setupCKEditor("description_footer", "hu");
        this.setupCKEditor("description_footer", "en");
    }
    componentDidUpdate = () => {

    }

    componentWillUnmount() {
        this.destroyCKEditor("description_header", "hu");
        this.destroyCKEditor("description_header", "en");
        this.destroyCKEditor("description_footer", "hu");
        this.destroyCKEditor("description_footer", "en");
    }

    render = () => {
        return (<Modal className="settings" id="restaurantSuperAdmin" show={true}>
            <Modal.Header>
                <h1>{I18n.t("admin_local.settings")}</h1>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onChange={this.onChange}
                    schema={this.props.schema}
                    uiSchema={this.props.uiSchema}
                    formData={this.state.formdata}>
                    <div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={this.unmount}>{I18n.t("admin_local.close")}</button>
                <button className="btn btn-primary" onClick={this.save}>{I18n.t("admin_local.save")}</button>
            </Modal.Footer>
        </Modal >)
    }

    save = () => {
        if (this.state.changed) {
            post("adminService/" + sessionStorage.restaurantSelected + "/updateSettings?path=" + this.props.path + "&property=" + this.props.property + "&lang=" + localStorage.language, this.state.formdata).done(data => {
                Object.keys(data).forEach(function (key) {
                    auth.myStatus[key] = data[key];
                });
                auth.setMyStatus(auth.myStatus);
                this.unmount()
            });
        } else {
            this.unmount();
        }
    }

    onChange = ({ formData }, e) => {
        this.setState({ formdata: formData, changed: true });
    };

}

export default SettingsEditor;