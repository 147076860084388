import $ from "jquery";
import admin from "../admin";
import auth, { startpoll, confirmDialog, getLocale } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";
import SortedSet from "js-sorted-set";
import moment from "moment";
import { crop, onPhotoDataSuccess } from "../camera-util";

class AdvertisementsCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveAdvertisement', 'deleteAdvertisement', 'activateAdvertisement']);
	}

	ready = () => {

		try {

			$('#main').replaceWith($('#advertisementsMainTemplate').tmpl({
				...tmplparams()
			}));

			$('.datepicker#fromDate').datepicker({
				format: 'yyyy-mm-dd',
				uiLibrary: 'bootstrap4'
			});
			$('.datepicker#toDate').datepicker({
				format: 'yyyy-mm-dd',
				uiLibrary: 'bootstrap4'
			});
			$('.timepicker#fromTime').timepicker({
				uiLibrary: 'bootstrap4'
			});
			$('.timepicker#toTime').timepicker({
				uiLibrary: 'bootstrap4'
			});

			$("body").on("paste", function (event) {

				var items = (event.clipboardData || event.originalEvent.clipboardData).items;
				for (let index in items) {
					var item = items[index];
					if (item.kind === 'file') {
						var blob = item.getAsFile();
						var reader = new FileReader();
						reader.onload = function (event) {
							onPhotoDataSuccess(event.target.result, undefined, 82, 82, 828, 828);
						}; // data url!
						reader.readAsDataURL(blob);
					}
				}

			})

			const instance = this;

			admin.getMenuItems(function (data) {
				instance.categories = {
					children: data.children
				};
			});

			admin.getLabels(function (data) {
				instance.labels = data;
			});


			this.loadAdvertisements();


			startpoll(this.serverSideMessageHandler);


			this.updateHooks();


			this.hooks();

		} catch (ex) {
			console.log(ex);
			console.log(ex.stack)
		}

	}

	reload = () => {
		this.loadAdvertisements();
	}

	loadAdvertisements = () => {
		admin.getAdvertisements(this.processAdvertisements);
	}

	serverSideMessageHandler = (data) => {
	}

	advertisements = null;
	processAdvertisements = (data) => {

		data.forEach(function (v, i) {
			v.title = v.name;
			v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";

		});
		if (this.advertisements === null)
			this.advertisements = createTree("table#advertisements", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 1
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");
					node.expanded = true;
					$tdList.eq(1).text(node.data.name);
				}
			});
		else {
			this.advertisements.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editAdvertisement').on('show.bs.modal', function (event) {

			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			modal.find('#name').val("");
			[1, 2, 3, 4, 5, 6, 7].forEach(function (day) {
				modal.find('input#' + day + '.day').prop('checked', null);
			})
			instance.clearTokens(modal.find('#scope').tokenize2());
			instance.clearTokens(modal.find('#labels').tokenize2());
			instance.clearTokens(modal.find('#whom_labels').tokenize2());
			instance.clearTokens(modal.find('#excluded_scope').tokenize2());
			instance.clearTokens(modal.find('#excluded_labels').tokenize2());
			instance.clearTokens(modal.find('#excluded_whom_labels').tokenize2());
			auth.myStatus.restaurant_languages.forEach(function (lang) {
				modal.find('#name_' + lang).val("");
			});
			modal.find('#name_all').val("");

			if (recipient === 'edit') {
				var data = instance.advertisements.getActiveNode().data;
				modal.find('#id').val(data.id);
				modal.find('#name').val(data.name);

				if (data.image && data.image !== "") {
					modal.find("#smallImage").attr("src", auth.server + "/" + auth.war + "/adminService/" + sessionStorage.restaurantSelected + "/getImage/" + data.image);
					$(modal.find("#smallImageDiv")).removeClass("hidden");
				} else {
					modal.find("#smallImage").attr("src", "");
				}

				if (data.fromDate) {
					modal.find('#fromDate').val(moment(data.fromDate).format("YY-MM-DD"));
				}
				if (data.toDate)
					modal.find('#toDate').val(moment(data.toDate).format("YY-MM-DD"));
				if (data.fromTime) {
					var h = ('0' + Math.round(data.fromTime / 60));
					var m = ('0' + data.fromTime % 60);
					modal.find('#fromTime').val(h.substring(h.length - 2, h.length) + ":" + m.substring(m.length - 2, m.length));
				}
				if (data.toTime) {
					h = ('0' + Math.round(data.toTime / 60));
					m = ('0' + data.toTime % 60);
					modal.find('#toTime').val(h.substring(h.length - 2, h.length) + ":" + m.substring(m.length - 2, m.length));
				}
				data.days.forEach(function (day) {
					modal.find('input#' + (day + 1) + '.day').prop('checked', true);
				})

				data.menuCategoryIds.forEach(function (id) {
					var menuCategory = instance.getMenuCategory(id);
					modal.find('#scope').trigger('tokenize:tokens:add',
						['menuCategory;' + menuCategory.entityType + ';' + id + ';', instance.getLocal(menuCategory.name)]);
				});
				data.excludedMenuCategoryIds.forEach(function (id) {
					var menuCategory = instance.getMenuCategory(id);
					modal.find('#excluded_scope').trigger('tokenize:tokens:add',
						['menuCategory;' + menuCategory.entityType + ';' + id + ';', instance.getLocal(menuCategory.name)]);
				});
				data.menuItemIds.forEach(function (id) {
					var menuItem = instance.getMenuItem(id);
					modal.find('#scope').trigger('tokenize:tokens:add',
						['menuItem;' + menuItem.entityType + ';' + id + ';' + menuItem.quantity, instance.getLocal(menuItem.name)]);
				});
				data.excludedMenuItemIds.forEach(function (id) {
					var menuItem = instance.getMenuItem(id);
					modal.find('#excluded_scope').trigger('tokenize:tokens:add',
						['menuItem;' + menuItem.entityType + ';' + id + ';' + menuItem.quantity, instance.getLocal(menuItem.name)]);
				});
				data.labels.forEach(function (label) {
					modal.find('#labels').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name]);
				});
				data.whomLabels.forEach(function (label) {
					modal.find('#whom_labels').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name]);
				});
				data.exclusionLabels.forEach(function (label) {
					modal.find('#excluded_labels').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name]);
				});
				data.exclusionWhomLabels.forEach(function (label) {
					modal.find('#excluded_whom_labels').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name]);
				});
				modal.find('input#active').prop('checked', data.isActive);


			} else {
				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#fromDate').val("");
				modal.find('#toDate').val("");
				modal.find('#fromTime').val("");
				modal.find('#toTime').val("");
				modal.find("#smallImage").attr("src", "");
				modal.find('input#active').prop('checked', true);
			}


		})
	}

	saveAdvertisement = () => {
		var modal = $('div#editAdvertisement');
		var id = modal.find('#id').val();

		var advertisement = {

		}
		advertisement.name = modal.find('input#name').val();
		advertisement.fromDate = modal.find('input#fromDate').val();
		advertisement.toDate = modal.find('input#toDate').val();
		advertisement.fromTime = modal.find('input#fromTime').val();
		if (advertisement.fromTime !== '') {
			advertisement.fromTime = Number(advertisement.fromTime.split(':')[0] * 60) + Number(advertisement.fromTime.split(':')[1]);
		} else {
			advertisement.fromTime = null;
		}
		advertisement.toTime = modal.find('input#toTime').val();
		if (advertisement.toTime !== '') {
			advertisement.toTime = Number(advertisement.toTime.split(':')[0] * 60) + Number(advertisement.toTime.split(':')[1]);
		} else {
			advertisement.toTime = null;
		}
		advertisement.advertisement = modal.find('input#amount_type').prop('checked') ? 'amount' : 'percent';
		advertisement.days = [];
		[1, 2, 3, 4, 5, 6, 7].forEach(function (day) {
			var d = modal.find('input#' + day + '.day').prop('checked');
			if (d)
				advertisement.days.push(day - 1);
		})
		advertisement.isActive = modal.find('input#active').prop('checked');
		advertisement.menuItems = [];
		advertisement.menuCategories = [];
		advertisement.excludedMenuItems = [];
		advertisement.excludedMenuCategories = [];
		advertisement.labels = [];
		advertisement.whomLabels = [];
		advertisement.exclusionLabels = [];
		advertisement.exclusionWhomLabels = [];

		var sm = $(modal.find("img#smallImage"));
		if ($.data(sm.get(0), "rcrop")) {
			crop(sm, 828, 828);
		}
		advertisement.imageSrc = $(modal.find("#smallImage")).attr("src");
		if (advertisement.imageSrc === "") advertisement.image = null;

		var a = modal.find('#scope');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			var entityType = id[1];
			if (id[0] === 'menuItem')
				advertisement.menuItems.push({
					id: id[2],
					type: entityType,
					quantity: id[3]
				});
			else
				advertisement.menuCategories.push({
					id: id[2],
					type: entityType,
				});
		});
		a = modal.find('#excluded_scope');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			var entityType = id[1];
			if (id[0] === 'menuItem')
				advertisement.excludedMenuItems.push({
					id: id[2],
					type: entityType,
					quantity: id[3]
				});
			else
				advertisement.excludedMenuCategories.push({
					id: id[2],
					type: entityType,
				});
		});
		a = modal.find('#labels');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			advertisement.labels.push({
				id: id[1],
				name: id[0]
			});
		});
		a = modal.find('#excluded_labels');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			advertisement.exclusionLabels.push({
				id: id[1],
				name: id[0]
			});
		});
		a = modal.find('#whom_labels');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			advertisement.whomLabels.push({
				id: id[1],
				name: id[0]
			});
		});
		a = modal.find('#excluded_whom_labels');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			advertisement.exclusionWhomLabels.push({
				id: id[1],
				name: id[0]
			});
		});


		if (id !== undefined && id !== '') {
			advertisement.id = Number(id);
			admin.modifyAdvertisement(advertisement, this.loadAdvertisements);
		} else {
			admin.addAdvertisement(advertisement, this.loadAdvertisements);
		}
	}

	activateAdvertisement = (active) => {
		var data = { ...this.advertisements.getActiveNode().data };
		data.imageSrc = data.image;
		data.isActive = active;
		delete data.menuItemSpecific;
		delete data.tableMateSpecific;
		admin.modifyAdvertisement(data, this.loadAdvertisements);
	}

	deleteAdvertisement = () => {
		const instance = this;
		var data = this.advertisements.getActiveNode().data;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_discount_type'), getLocale(data.name))).done(function () {
			admin.deleteAdvertisement(data.id, instance.loadAdvertisements);
		});
	}

	hooks = () => {
		const instance = this;
		var options = {
			placeholder: "",
			tokensAllowCustom: true,
			searchMinLength: 0,
			dataSource: function (term, object) {
				object.trigger('tokenize:dropdown:fill', [instance.getMenuItems(term)]);
			},
			displayNoResultsMessage: true,
			noResultsMessageText: I18n.t('local.noResultsMessageText'),
			dropdownMaxItems: 7,
			searchFromStart: false,
		};
		var options2 = {
			placeholder: "",
			tokensAllowCustom: true,
			searchMinLength: 0,
			dataSource: function (term, object) {
				object.trigger('tokenize:dropdown:fill', [instance.getLabels(term)]);
			},
			displayNoResultsMessage: true,
			noResultsMessageText: I18n.t('local.noResultsMessageText'),
			dropdownMaxItems: 7,
			searchFromStart: false,
		};
		$('div#editAdvertisement #scope').tokenize2(options);
		$('div#editAdvertisement #labels').tokenize2(options2);
		$('div#editAdvertisement #whom_labels').tokenize2(options2);
		$('div#editAdvertisement #excluded_scope').tokenize2(options);
		$('div#editAdvertisement #excluded_labels').tokenize2(options2);
		$('div#editAdvertisement #excluded_whom_labels').tokenize2(options2);
	}


	populateTokenizers = (modal, recipient, data, menuCategoryId) => {
		this.clearTokens(modal.find('#scope').tokenize2());
		this.clearTokens(modal.find('#labels').tokenize2());
		this.clearTokens(modal.find('#whom_labels').tokenize2());
		this.clearTokens(modal.find('#excluded_scope').tokenize2());
		this.clearTokens(modal.find('#excluded_labels').tokenize2());
		this.clearTokens(modal.find('#excluded_whom_labels').tokenize2());
	}

	clearTokens = (tokenizer) => {
		tokenizer.toArray().forEach(function (addition) {
			tokenizer.trigger('tokenize:tokens:remove', addition);
		});
	}

	getMenuItems = (search) => {
		var set = new SortedSet({
			comparator: function (a, b) {
				if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase()))
					return -1;
				if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase()))
					return 1;
				return a.text.localeCompare(b.text);
			}
		});
		var menuItem = this._getMenuItems(search, set, this.categories.activeMenuCategories ? this.categories.activeMenuCategories : this.categories.children);
		if (menuItem === '') {
			return null;
		}
		var result = [];
		set.forEach(function (e) {
			result[result.length] = e;
		});
		return result;
	}


	_getMenuItems = (s, set, categories) => {
		const instance = this;
		categories.forEach(function (item) {
			var regexp = new RegExp(s, 'ig');
			if (typeof item.activeMenuItems === 'undefined' && typeof item.children === 'undefined') {
				var name = instance.getLocal(item.name);
				var r = regexp.test(name);
				if (r) {
					if (item.availableQuantities.length === 1)
						set.insert({
							value: "menuItem;" + item.entityType + ";" + item.id + ";" + item.availableQuantities[0].quantity,
							text: name
						})
					else {
						item.availableQuantities.forEach(function (q) {
							set.insert({
								value: "menuItem;" + item.entityType + ";" + item.id + ";" + q.quantity,
								text: name + " " + q.quantity + " " + I18n.t('local.' + item.advertisement)
							})
						});
					}
				}
			} else {
				name = instance.getLocal(item.name);
				r = regexp.test(name);
				if (r) {
					set.insert({
						value: "menuCategory;" + item.entityType + ";" + item.id + ";",
						text: name
					})
				}
				if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
					instance._getMenuItems(s, set, item.activeMenuCategories);
				if (item.activeMenuItems && item.activeMenuItems.length > 0)
					instance._getMenuItems(s, set, item.activeMenuItems);
				if (item.children && item.children.length > 0)
					instance._getMenuItems(s, set, item.children);
			}
		});
	}

	getLabels = (search) => {
		var set = new SortedSet({
			comparator: function (a, b) {
				if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase()))
					return -1;
				if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase()))
					return 1;
				return a.text.localeCompare(b.text);
			}
		});
		var menuItem = this._getLabels(search, set, this.labels);
		if (menuItem === '') {
			return null;
		}
		var result = [];
		set.forEach(function (e) {
			result[result.length] = e;
		});
		return result;
	}

	_getLabels = (s, set, labels) => {
		labels.forEach(function (item) {
			var regexp = new RegExp(s, 'ig');
			var name = item.name;
			var r = regexp.test(name);
			if (r) {
				set.insert({
					value: "label;" + item.id + ";",
					text: name
				})
			}
		});
	}

	getLocal = (name) => {
		if (typeof name === 'string')
			return name;
		return name[localStorage.language] ? name[localStorage.language] : name['all'];
	}


	getMenuItem = (id) => {
		var menuItem = this._getMenuItem(id, this.categories.activeMenuCategories ? this.categories.activeMenuCategories : this.categories.children);
		if (menuItem === '') {
			return null;
		}
		return menuItem;
	}

	_getMenuItem = (id, categories) => {
		const instance = this;
		var val = null;
		categories.forEach(function (item) {
			if (val !== null)
				return;
			if (item.entityType.indexOf('Category') < 0 && Number(item.id) === Number(id)) {
				val = item;
			} else {
				if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
					val = instance._getMenuItem(id, item.activeMenuCategories);
				if (val == null && item.activeMenuItems && item.activeMenuItems.length > 0)
					val = instance._getMenuItem(id, item.activeMenuItems);
				if (val == null && item.children && item.children.length > 0)
					val = instance._getMenuItem(id, item.children);
			}
		});
		return val;
	}

	getMenuCategory = (id) => {
		var menuItem = this._getMenuCategory(id, this.categories.activeMenuCategories ? this.categories.activeMenuCategories : this.categories.children);
		if (menuItem === '') {
			console.log("failed to find menu item " + id);
			return null;
		}
		return menuItem;
	}

	_getMenuCategory = (id, categories) => {
		const instance = this;
		var val = null;
		categories.forEach(function (item) {
			if (val != null)
				return;
			if (item.entityType.indexOf('Category') > 0 && Number(item.id) === Number(id)) {
				val = item;
			} else {
				if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
					val = instance._getMenuCategory(id, item.activeMenuCategories);
				if (val == null && item.activeMenuItems && item.activeMenuItems.length > 0)
					val = instance._getMenuCategory(id, item.activeMenuItems);
				if (val == null && item.children && item.children.length > 0)
					val = instance._getMenuCategory(id, item.children);
			}
		});
		return val;
	}

	clearTokens = (tokenizer) => {
		tokenizer.toArray().forEach(function (addition) {
			tokenizer.trigger('tokenize:tokens:remove', addition);
		});

	}
}

export default AdvertisementsCode;