import $ from "jquery";
import admin, { resultHandler } from "../admin";
import { startpoll, confirmDialog, del, patch, post } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";

class LabelsCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveLabel', 'deleteLabel', 'activateLabel']);
	}

	ready = () => {
		$('#main').replaceWith($('#labelsMainTemplate').tmpl({
			...tmplparams()
		}));

		this.loadLabels();

		startpoll(this.serverSideMessageHandler);

		this.updateHooks();


	}

	reload = () => {
		this.loadLabels();
	}

	loadLabels = () => {
		admin.getLabels(this.processLabels);
	}

	serverSideMessageHandler = (data) => {
	}


	labels = null;
	processLabels = (data) => {
		data.forEach(function (v, i) {
			v.title = v.number;
			v.folder = false;
			v.key = v.id;
		});
		if (this.labels == null)
			this.labels = createTree("table#labels", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 1
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = true;

					$tdList.eq(1).text(node.data.name);
					$tdList.eq(2).text(node.data.percent);
				}
			});
		else {
			this.labels.reload(data);
			data = this.labels.getActiveNode();
			$('#edit').prop('disabled', data == null);
			$('#delete').prop('disabled', data == null);
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editLabel').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			if (recipient === 'edit') {
				var data = instance.labels.getActiveNode().data;
				modal.find('#id').val(data.id);
				modal.find('#name').val(data.name);
				modal.find('#percent').val(data.percent);
				if (data.isActive === true)
					modal.find('#active').prop("checked", 'true');
				else
					modal.find('#active').removeAttr("checked");
			} else {
				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#percent').val("");
				modal.find('#active').prop("checked", 'true');

			}
		})
	}

	saveLabel = () => {
		var modal = $('div#editLabel');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		if (id !== '') {
			this.modifyLabel({ id: id, name: name }, this.loadLabels);
		} else {
			this.addLabel({ name: name }, this.loadLabels);
		}
	}

	deleteLabel = () => {
		const instance = this;
		var data = this.labels.getActiveNode().data;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_label'), data.name)).done(function () {
			instance._deleteLabel(data.id, instance.loadLabels);
		});

	}

	addLabel = (discountType, handler, errorHandler) => {
		post("adminService/" + sessionStorage.restaurantSelected + "/labels", discountType).done(function (data) { resultHandler(data, handler, errorHandler) });
	}

	modifyLabel = (discountType, handler, errorHandler) => {
		patch("adminService/" + sessionStorage.restaurantSelected + "/labels", discountType).done(function (data) { resultHandler(data, handler, errorHandler) });
	}

	_deleteLabel = (id, handler, errorHandler) => {
		del("adminService/" + sessionStorage.restaurantSelected + "/labels/" + id).done(function (data) { resultHandler(data, handler, errorHandler) });
	}

}

export default LabelsCode;