import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { I18n, Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import LogoutButton from "../components/icon-buttons/LogoutButton";
import auth, { get, getLocale, getMyImageUrl, setRem } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import MenuItemHeader from "../components/MenuItemHeader";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import moment from "moment";
import { withRouter } from "react-router-dom";
import "select2/dist/css/select2.css"
import MyDateRangePicker from "../components/MyDateRangePicker";
import FlexGrowSpace from "../widgets/FlexGrowSpace";

import { TreeTable, TreeState } from 'cp-react-tree-table';
import { getMenuCategory, getMenuItem, getMenuItems, getQuantityForCount, resultHandler } from "../js/admin";
import SortedSet from "js-sorted-set";
import "./ProfitAndLoss.css";
import menuitemutil from "../js/admin/menuitem";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import 'react-reflex/styles.css'

import Tab from "@material/react-tab";
import TabBar from "@material/react-tab-bar";
import "@material/react-tab-bar/dist/tab-bar.css";
import "@material/react-tab/dist/tab.css";
import "@material/react-tab-scroller/dist/tab-scroller.css";

class ProfitAndLoss extends Component {
    state = {
        initialized: false,
        startDate: moment(Number(localStorage.admin_stocklist_start)),
        endDate: moment(Number(localStorage.admin_stocklist_end)),
        activeIndex: 0
    };

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;
        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            getMenuItems((data) => {
                this.updateData(data);
                const cats = data.children.map(a => this.convert(a));
                this.setState({ categories: cats, initialized: true });
                this.reload();
            }, undefined, true);
        }
        auth.minfontsize = 10;
        setRem();
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            getMenuItems((data) => {
                this.updateData(data);
                const cats = data.children.map(a => this.convert(a));
                this.setState({ categories: cats, initialized: true });
                this.reload();
            }, undefined, true);
        }
    }

    componentWillUnmount() {
        this.setState({ initialized: false });
    }

    reload = () => {
        var filter = {
            from: localStorage.admin_stocklist_start,
            to: localStorage.admin_stocklist_end,
        };
        this.getStockStatistics(filter, this.processStockStatistics);
    }

    getStockStatistics = (filter, handler, errorHandler) => {
        var filterQuery = "";
        Object.keys(filter).forEach(f => {
            filterQuery += "&" + f + "=" + filter[f];
        });
        const serv = auth.server.indexOf("8444") === -1 ? auth.server.replace(/instance.eatwithme/g, "backup.eatwithme") : auth.server;
        get(serv + "/eatwithme.server/" + "adminService/" + sessionStorage.restaurantSelected + "/getStockStatistics2?" + filterQuery, localStorage.id, undefined, 180000).done(function (data) {
            resultHandler(data, handler, errorHandler);
        });
    }

    copy = (nodes, getColumnData, filter, height) => {
        return nodes.filter(filter).map(node => {
            const n = {
                ...node,
            }
            n.data = { ...n.data };
            n.data.getColumnData = getColumnData;
            n.children = node.children ? this.copy(node.children, getColumnData, filter, height) : undefined;
            n.height = height(node);
            return n;
        });
    }

    processStockStatistics = data => {
        const set = new SortedSet();
        data.stockStatistics.forEach(a => !set.contains(a.date) && set.insert(a.date));
        const dates = set.toArray();

        const isInventoryItem = (node) => {
            if (node.data.defaultInventoryItem)
                return true;
            else
                return node.children.filter(isInventoryItem).length > 0
        }

        //const income = (node, c) => node.data && node.data.dates && node.data.dates[c] ? Math.round(node.data.dates[c].income) : 0;
        //const purchases = (node, c) => node.data && node.data.dates && node.data.dates[c] ? Math.round(node.data.dates[c].purchases) : 0;
        const stock = (node, c) => {
            var style = { fontWeight: "bold" }
            if (node.data && node.data.dates && node.data.dates[c]) {
                const initialStock = Math.round(node.data.dates[c].stock - node.data.dates[c].stockChange);
                var previousFinalStock = initialStock;
                if (this.state.dates.indexOf(c) > 0) {
                    const cc = this.state.dates[this.state.dates.indexOf(c) - 1];
                    if (node.data.dates[cc]) {
                        previousFinalStock = Math.round(node.data.dates[cc].stock);
                    }
                }
                //const purchases = -(previousFinalStock - node.data.dates[c].stock + node.data.dates[c].shortage + node.data.dates[c].cost);
                const purchases = -(node.data.dates[c].purchases);

                const profit = Math.round(node.data.dates[c].income + node.data.dates[c].cost + node.data.dates[c].shortage + node.data.dates[c].scrapping);

                return (
                    <div style={{ textAlign: "right", width: "100%" }} >
                        <div >{previousFinalStock.toLocaleString()}</div>
                        <div>{(Math.round(node.data.dates[c].cost)).toLocaleString()}</div>
                        <div>{(Math.round(purchases)).toLocaleString()}</div>
                        <div>{(Math.round(node.data.dates[c].shortage)).toLocaleString()}</div>
                        <div>{(Math.round(node.data.dates[c].scrapping)).toLocaleString()}</div>
                        <div>{(Math.round(node.data.dates[c].stock)).toLocaleString()}</div>
                        <div>{(Math.round(node.data.dates[c].stockChange)).toLocaleString()}</div>
                        <div style={style}>{node.data.dates[c].income ? (Math.round(node.data.dates[c].income)).toLocaleString() : "-"}</div>
                        <div>{profit ? profit.toLocaleString() : "-"}</div>
                        <div>{profit && node.data.dates[c].income ? Math.round(profit / node.data.dates[c].income * 100).toLocaleString() + "%" : "-"}</div>
                    </div >)
            } else return "";
        }
        const product = (node, c) => {
            var style = { fontWeight: "bold" }
            if (node.data && node.data.dates && node.data.dates[c]) {
                return (
                    <div style={{ textAlign: "right", width: "100%" }} >
                        <div>{(Math.round(node.data.dates[c].count)).toLocaleString()}</div>
                        <div style={style}>{(Math.round(node.data.dates[c].netto_income)).toLocaleString()}</div>
                        {node.data.availableQuantities ?
                            <React.Fragment>
                                <div>{(Math.round(node.data.dates[c].consumption * 1000) / 1000).toLocaleString()}</div>
                                <div>{(Math.round(node.data.dates[c].minPrice)).toLocaleString()}</div>
                                <div>{(Math.round(node.data.dates[c].maxPrice)).toLocaleString()}</div>
                                <div>{(Math.round(node.data.dates[c].averagePrice)).toLocaleString()}</div>
                                <div>{(Math.round(node.data.dates[c].averageDiscount)).toLocaleString()}</div>
                                <div>{(Math.round(node.data.dates[c].averageActualPrice)).toLocaleString()}</div>
                                <div>{(Math.round(node.data.dates[c].minCost)).toLocaleString()}</div>
                                <div>{(Math.round(node.data.dates[c].maxCost)).toLocaleString()}</div>
                                <div>{(Math.round(node.data.dates[c].averageCost)).toLocaleString()}</div>
                            </React.Fragment> : null}
                        <div>{(Math.round(node.data.dates[c].cost)).toLocaleString()}</div>
                        <div>{(Math.round(node.data.dates[c].netto_income - node.data.dates[c].cost)).toLocaleString()}</div>
                        <div>{(Math.round(100 * (node.data.dates[c].netto_income - node.data.dates[c].cost) / node.data.dates[c].netto_income)).toLocaleString()}%</div>
                    </div >)
            } else return "";
        }

        const incomeChildren = this.copy(this.state.categories, stock, isInventoryItem, node => 210);
        const incomeProductChildren = this.copy(this.state.categories, product, () => true, node =>
            node.data.availableQuantities ? 290 : 110
        );

        const treeData1 = [
            {
                data: {
                    name: 'Alapanyagok',
                    getColumnData: stock,
                    children: incomeChildren
                },
                children: incomeChildren,
                height: 210
            }
        ];
        const treeData2 = [
            {
                data: {
                    name: 'Termékek',
                    getColumnData: product,
                    children: incomeProductChildren
                },
                children: incomeProductChildren,
                height: 110
            },
        ];
        const processStockStatistics = (v, i) => {
            const menuitem = getMenuItem(v.menuitem);
            const quantity = menuitemutil.getInventoryQuantity(menuitem, v.quantity);
            var vv = this.getMenuItemForQuantityAndStore(v.menuitem, quantity, incomeChildren);
            v.income = v.netto_income;///v.consumption;
            v.purchases = v.netto_purchase;
            v.cost = -v.consumption_cost;
            v.stock = v.finalStock * v.finalCost;
            v.stockChange = v.finalStock * v.finalCost - v.initialStock * v.initialCost;
            v.shortage = v.shortage_cost;
            v.scrapping = -v.scrapping_cost;
            if (!vv.dates)
                vv.dates = {};
            if (vv.dates[v.date]) {
                vv.dates[v.date].income += v.income;
                vv.dates[v.date].cost += v.cost;
                vv.dates[v.date].purchases += v.purchases;
                vv.dates[v.date].stock += v.stock;
                vv.dates[v.date].stockChange += v.stockChange;
                vv.dates[v.date].shortage += v.shortage;
                vv.dates[v.date].scrapping += v.scrapping;
            } else
                vv.dates[v.date] = v;
            vv.hasNoCost = Object.values(vv.dates).filter(c => c.income > 0 && c.cost === 0).length > 0;
            vv.hasNoUsage = Object.values(vv.dates).filter(c => c.stockChange > 0 || c.income > 0).length === 0;
        }



        data.stockStatistics.forEach(processStockStatistics);



        const processProductStatistics = (v, i) => {
            const menuitem = getMenuItem(v.menuitem);
            var quantity = menuitem.defaultInventoryItem ? menuitemutil.getInventoryQuantity(menuitem, v.quantity) : v.quantity;
            quantity = quantity ? quantity : v.quantity;
            var vv = this.getMenuItemForQuantityAndStore(v.menuitem, quantity, incomeProductChildren);
            if (!vv.dates)
                vv.dates = {};
            if (vv.dates[v.date]) {
                Object.keys(v).filter(k => k !== "date").forEach(k => {
                    switch (k) {
                        case "consumption":
                            vv.dates[v.date].count += v[k];
                            vv.dates[v.date][k] += v[k] * v.quantity / quantity;
                            break;
                        case "minPrice":
                        case "maxPrice":
                        case "averageDiscount":
                        case "averagePrice":
                        case "averageActualPrice":
                            vv.dates[v.date][k] += v[k] / v.quantity * quantity;
                            break;
                        default:
                            vv.dates[v.date][k] += v[k];
                    }
                })
            } else {
                vv.dates[v.date] = {
                    date: v.date
                };
                Object.keys(v).filter(k => k !== "date").forEach(k => {
                    switch (k) {
                        case "consumption":
                            vv.dates[v.date][k] = v[k] * v.quantity / quantity;
                            vv.dates[v.date].count = v[k];
                            break;
                        case "minPrice":
                        case "maxPrice":
                        case "averageDiscount":
                        case "averagePrice":
                        case "averageActualPrice":
                            vv.dates[v.date][k] = v[k] / v.quantity * quantity;
                            break;
                        default:
                            vv.dates[v.date][k] = v[k];
                    }
                })
            }
            vv.dates[v.date].quantity = quantity;
            vv.hasNoCost = Object.values(vv.dates).filter(c => c.netto_income > 0 && c.cost === 0).length > 0;

        }


        data.productStatistics.forEach(processProductStatistics);

        const sumStockStatistics = (treeData) => {
            if (!treeData.data.dates)
                treeData.data.dates = {

                };

            if (treeData.children && treeData.children.length) {
                const map = treeData.children.map(sumStockStatistics);
                treeData.data.dates = map.reduce((a, b) => {
                    Object.keys(b).forEach(key => {
                        if (!a[key])
                            a[key] = {
                                income: 0,
                                cost: 0,
                                purchases: 0,
                                stock: 0,
                                stockChange: 0,
                                shortage: 0,
                                scrapping: 0
                            }
                        a[key].income += b[key].income;
                        a[key].cost += b[key].cost;
                        a[key].purchases += b[key].purchases;
                        a[key].stock += b[key].stock;
                        a[key].stockChange += b[key].stockChange;
                        a[key].shortage += b[key].shortage;
                        a[key].scrapping += b[key].scrapping;
                    })
                    return a;
                }, treeData.data.dates);
            }
            treeData.data.hasdates = Object.values(treeData.data.dates).filter(d => d.income + d.cost + d.stock > 0).length > 0;
            if (!treeData.data.hasNoCost)
                treeData.data.hasNoCost = treeData.children.filter(d => d.data.hasNoCost).length > 0;
            if (!treeData.data.hasNoUsage)
                treeData.data.hasNoUsage = treeData.children.filter(d => d.data.hasNoUsage).length > 0;
            treeData.data.stock = true;
            return treeData.data.dates;
        }

        sumStockStatistics(treeData1[0]);

        const sumProductStatistics = (treeData) => {
            if (!treeData.data.dates)
                treeData.data.dates = {

                };

            if (treeData.children && treeData.children.length) {
                const map = treeData.children.map(sumProductStatistics);

                treeData.data.dates = map.reduce((a, b) => {
                    Object.keys(b).forEach(key => {
                        if (!a[key]) {
                            a[key] = {};
                            Object.keys(b[key]).filter(k => k !== "date").forEach(k => a[key][k] = 0);
                        }
                        a[key].count += b[key].count;
                        a[key].consumption += b[key].consumption;
                        a[key].minPrice = "";
                        a[key].maxPrice = "";
                        a[key].averagePrice = "";
                        a[key].averageDiscount = "";
                        a[key].averageActualPrice = "";
                        a[key].netto_income += b[key].netto_income;
                        a[key].brutto_income += b[key].brutto_income;
                        a[key].minCost = "";
                        a[key].maxCost = "";
                        a[key].averageCost = "";
                        a[key].cost += b[key].cost;
                    })
                    return a;
                }, treeData.data.dates)
            }
            treeData.data.hasdates = Object.values(treeData.data.dates).filter(d => d.consumption > 0).length > 0;
            if (!treeData.data.hasNoCost)
                treeData.data.hasNoCost = treeData.children.filter(d => d.data.hasNoCost).length > 0;
            treeData.data.product = true;
            return treeData.data.dates;
        }

        sumProductStatistics(treeData2[0]);

        function filter(array) {
            return array.filter(a => {
                a.children = filter(a.children);
                return a.data.recordState !== "DELETED" || a.data.hasdates;
            });
        }

        treeData1[0].children = filter(treeData1[0].children);
        treeData2[0].children = filter(treeData2[0].children);

        this.setState({
            dates: dates,
            treeValue1: TreeState.create(treeData1),
            treeValue2: TreeState.create(treeData2)
        })
    }

    handleActiveIndexUpdate = (index) => {
        this.setState({ activeIndex: index });
    }

    render() {
        const { myStatus } = this.props;


        if (myStatus && myStatus.restaurant_id) {

            return (
                <div id="StockStatistics" className="stock-actions" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "100%" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.analytics" />} />
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                                <div style={{ marginLeft: "10px", width: "35px", display: "flex", justifyContent: "space-between" }}>
                                    <LogoutButton query="keep=true&redirect=/tablesOverview" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div style={{ display: "flex", flexFlow: "column", justifyItems: "center", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", flexGrow: 0, fontSize: "20px" }}>
                        <div style={{ display: "flex", flexWrap: "nowrap", width: "100%" }}>
                            <FlexGrowSpace />
                            <MyDateRangePicker startDate={this.state.startDate} endDate={this.state.endDate} onApply={this.dateRangeChanged}>
                            </MyDateRangePicker>
                            <FlexGrowSpace />
                        </div>
                    </div>
                    <div id="profitandloss">
                        {this.state.treeValue1 ?
                            <div>
                                <TabBar activeIndex={this.state.activeIndex} handleActiveIndexUpdate={this.handleActiveIndexUpdate}>
                                    <Tab>
                                        <span className="mdc-tab__text-label">{I18n.t("admin_local.stockAnalitics")}</span>
                                    </Tab>
                                    <Tab>
                                        <span className="mdc-tab__text-label">{I18n.t("admin_local.productAnalitics")}</span>
                                    </Tab>
                                </TabBar>
                                <div class="tabContent" >
                                    {this.state.activeIndex === 0 && <div>
                                        <div className="table">
                                            <div>
                                                <TreeTable
                                                    key="ingredients"
                                                    value={this.state.treeValue1}
                                                    onChange={this.handleOnChange1}
                                                    height={1300}>

                                                    <TreeTable.Column className="fixed"
                                                        renderCell={this.renderIndexCell}
                                                        renderHeaderCell={() => <span>{I18n.t("admin_local.name")}</span>}
                                                        grow={1}
                                                        basis="max(30%, 400px)" />
                                                    <TreeTable.Column className="fixed"
                                                        renderCell={this.renderLegendCell}
                                                        renderHeaderCell={() => <span></span>}
                                                        grow={1}
                                                        basis="300px" />
                                                    {this.state.dates.map(date =>
                                                        <TreeTable.Column
                                                            basis="150px"
                                                            key={date}
                                                            renderCell={node => this.renderColumnCell(node, date)}
                                                            renderHeaderCell={() => <span>{moment(date).format("YYYY-MM-DD")}</span>} />
                                                    )}
                                                </TreeTable>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.activeIndex === 1 && <div>
                                        <div className="table">
                                            <div>
                                                <TreeTable
                                                    key="products"
                                                    value={this.state.treeValue2}
                                                    onChange={this.handleOnChange2}
                                                    height={1300}>

                                                    <TreeTable.Column className="fixed"
                                                        renderCell={this.renderIndexCell}
                                                        renderHeaderCell={() => <span>{I18n.t("admin_local.name")}</span>}
                                                        grow={1}
                                                        basis="max(30%, 400px)" />
                                                    <TreeTable.Column className="fixed"
                                                        renderCell={this.renderLegendCell}
                                                        renderHeaderCell={() => <span></span>}
                                                        grow={1}
                                                        basis="300px" />
                                                    {this.state.dates.map(date =>
                                                        <TreeTable.Column
                                                            basis="150px"
                                                            key={date}
                                                            renderCell={node => this.renderColumnCell(node, date)}
                                                            renderHeaderCell={() => <span>{moment(date).format("YYYY-MM-DD")}</span>} />
                                                    )}
                                                </TreeTable>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div> : null}
                    </div>
                </div >
            );
        } else return null;
    }

    handleOnChange1 = (newValue) => {
        this.setState({ treeValue1: newValue });
    }
    handleOnChange2 = (newValue) => {
        this.setState({ treeValue2: newValue });
    }

    renderLegendCell = (row) =>
        row.data.stock ?
            <div>
                <div>{I18n.t("admin_local.profitandloss.open_stock")}</div>
                <div>{I18n.t("admin_local.profitandloss.cost")}</div>
                <div>{I18n.t("admin_local.profitandloss.purchase")}</div>
                <div>{I18n.t("admin_local.profitandloss.shortage")}</div>
                <div>{I18n.t("admin_local.profitandloss.scrapping")}</div>
                <div>{I18n.t("admin_local.profitandloss.closing_stock")}</div>
                <div>{I18n.t("admin_local.profitandloss.stock_change")}</div>
                <div>{I18n.t("admin_local.profitandloss.income")}</div>
                <div>{I18n.t("admin_local.profitandloss.profit")}</div>
                <div>{I18n.t("admin_local.profitandloss.profitp")}</div>
            </div>
            : <div>
                <div>{I18n.t("admin_local.profitandloss.count")}</div>
                <div>{I18n.t("admin_local.profitandloss.income")}</div>
                {row.data.availableQuantities ?
                    <React.Fragment>
                        <div>{I18n.t("admin_local.profitandloss.consumption")}</div>
                        <div>{I18n.t("admin_local.profitandloss.minPrice")}</div>
                        <div>{I18n.t("admin_local.profitandloss.maxPrice")}</div>
                        <div>{I18n.t("admin_local.profitandloss.avgPrice")}</div>
                        <div>{I18n.t("admin_local.profitandloss.avgDiscount")}</div>
                        <div>{I18n.t("admin_local.profitandloss.avgActualPrice")}</div>
                        <div>{I18n.t("admin_local.profitandloss.minCost")}</div>
                        <div>{I18n.t("admin_local.profitandloss.maxCost")}</div>
                        <div>{I18n.t("admin_local.profitandloss.avgCost")}</div>
                    </React.Fragment> : null}
                <div>{I18n.t("admin_local.profitandloss.cost")}</div>
                <div>{I18n.t("admin_local.profitandloss.profit")}</div>
                <div>{I18n.t("admin_local.profitandloss.profitp")}</div>
            </div>


    renderIndexCell = (row) => {
        return (
            <div style={{ height: "unset", paddingLeft: (row.metadata.depth * 15) + 'px', textDecoration: row.data.recordState === "DELETED" ? "line-through" : "" }}
                className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
                {(row.metadata.hasChildren)
                    ? (
                        row.$state.isExpanded ?
                            <button className="toggle-button expanded" onClick={row.toggleChildren}></button>
                            :
                            <button className="toggle-button" onClick={row.toggleChildren}></button>
                    )
                    : ''
                }

                <span>{this.renderNameCell(row)}</span>
            </div>
        );
    }

    renderNameCell = (node, c) => {
        const styleHasNoCost = {}
        if (node.data.hasNoCost)
            styleHasNoCost.color = "orangered";
        const styleHasNoUsage = {}
        if (node.data.styleHasNoUsage)
            styleHasNoUsage.color = "orange";

        if (node.data.availableQuantities)
            return (
                <React.Fragment>
                    <div >
                        <div>{getLocale(node.data.name)}</div>
                        <div>{getQuantityForCount(node.data, node.data.quantity, 1)}</div>
                    </div>
                    {node.data.hasNoCost ?
                        <div style={styleHasNoCost} className="icon-attention">{I18n.t("admin_local.income_without_cost")}</div>
                        : null}
                    {node.data.hasNoUsage ?
                        <div style={styleHasNoUsage} className="icon-attention">{I18n.t("admin_local.not_used_ingredient")}</div>
                        : null}
                </React.Fragment>);
        else
            return (
                < React.Fragment >
                    <span>{getLocale(node.data.name)}</span>
                    {
                        node.data.hasNoCost ?
                            <i data-toggle="tooltip" title={I18n.t("admin_local.income_without_cost")} style={styleHasNoCost} className="icon-attention" />
                            : null
                    }
                    {
                        node.data.hasNoUsage ?
                            <i data-toggle="tooltip" title={I18n.t("admin_local.not_used_ingredient")} style={styleHasNoUsage} className="icon-attention" />
                            : null
                    }
                </React.Fragment >);
    }

    renderColumnCell = (node, c) => {
        return node.data.getColumnData.call(this, node, c);
    }

    dateRangeChanged = (event, values) => {
        this.setState({
            startDate: values.startDate,
            endDate: values.endDate
        })
        localStorage.admin_stocklist_start = values.startDate._d.getTime();
        localStorage.admin_stocklist_end = values.endDate._d.getTime();
        this.reload(this.state);
    }

    updateData = (data, level) => {
        if (typeof level === "undefined") level = 0;
        if (data.name) {
            data.title = getLocale(data.name, localStorage.language);
        }
        data.depth = level;
        delete data.dates;

        const instance = this;

        if (data.shortName)
            data.title += ' (<i>' + data.shortName + '</i>)';
        if (data.children) {
            var children = [];
            data.children.forEach(function (v) {
                if (v.availableQuantities) {
                    v.parentCategories = instance.getCategoryId(v.id);
                    if (v.availableQuantities.length > 0) {
                        v.availableQuantities.forEach((a) => {
                            var v1 = Object.assign({}, v);
                            v1.quantity = a.quantity;
                            children.push(v1);
                        });
                    } else {
                        v.quantity = 1;
                        children.push(v);
                    }
                } else {
                    v.parentCategories = ['category-' + v.id, ...instance.getCategoryCategoryId(v.id)];
                    v.quantity = 1;
                    var v2 = Object.assign({}, v);
                    delete v2.statistics;
                    children.push(v2);
                }
            });
            data.children = children;
            data.children.forEach(function (v) {
                //if (!v.isActive)
                //	v.extraClasses += " itemDeactivated";
                v.key = v.entityType + v.id + "-" + v.quantity;
                instance.updateData(v, level + 1);
            });
        }

    }

    convert = (node) => {
        return {
            data: { ...node },
            children: node.children ? node.children.map(a => this.convert(a)) : [],
        }
    }

    getCategoryId = (item) => {
        const menuItem = getMenuItem(item);
        const category = getMenuCategory(menuItem.menuCategoryId);
        const res = ['category-' + category.id, ...this.getCategoryCategoryId(category)]
        return res;
    }

    getCategoryCategoryId = (item) => {
        if (item.menuCategoryId) {
            const category = getMenuCategory(item.menuCategoryId);
            return ['category-' + item.menuCategoryId, ...this.getCategoryCategoryId(category)];
        }
        return [];
    }

    getMenuItemForQuantityAndStore = (id, quantity, categories) => {
        var menuItem = this._getMenuItem(id, quantity, categories);
        if (menuItem === null || menuItem === '') {
            return {
                id: id,
                type: 'deleted',
                name: I18n.t("local.deleted_or_not_found") + '(' + id + ')'
            };
        }
        return menuItem;
    }

    _getMenuItem = (id, quantity, categories) => {
        var val = null;
        categories.forEach((item) => {
            if (val)
                return val;
            if (item.data.entityType.indexOf('Category') === -1) {
                if (Number(item.data.id) === Number(id) && Number(item.data.quantity) === Number(quantity)) {
                    val = item.data;
                    delete val.image;
                }
            } else {
                if (item.children && item.children.length > 0)
                    val = this._getMenuItem(id, quantity, item.children);
            }
        });
        return val;
    }


}
const mapStateToProps = (props, state) => {
    if (typeof localStorage.admin_stocklist_start === "undefined") {
        localStorage.admin_stocklist_start = new Date().getTime();
        localStorage.admin_stocklist_end = new Date().getTime();
    }

    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(ProfitAndLoss));
