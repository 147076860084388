import React from "react";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import { connect } from "react-redux";
import { get, post, put, del, _log } from "../../js/auth";
import { I18n } from "react-redux-i18n";

import SchedulerRoot from "material-ui-cron";

class SupersetDashboard extends AbstractMenu {
  state = {
    isChecked: false,
    cronExpression: "0 0 * * *", // Default: Runs daily at midnight
    isEditing: false // To track if we are in edit mode
  };

  getReportSchedule = async () => {
    if (!this.state.reportScheduleQueryDone) {
      _log("Superset dashboard " + this.props.match.params.dashboard);
      this.setState({ reportScheduleQueryDone: true });
      get("https://superset.eatwithme.online/lang/" + localStorage.language).done(data => {
        this.setState({ languageSet: true });
        get("https://superset.eatwithme.online/api/v1/dashboard/" + this.props.match.params.dashboard).done(dashboard => {
          get("https://superset.eatwithme.online/api/v1/report/?q=(filters:!((col:type,opr:eq,value:Report),(col:dashboard_id,opr:eq,value:" + dashboard.result.id + ")),order_column:name,order_direction:desc,page:0,page_size:25)").done(data => {
            this.setState({ dashboard: dashboard, reportSchedule: data?.result.length > 0 ? data.result[0] : undefined, isChecked: data?.result.length > 0, cronExpression: data?.result.length > 0 ? data.result[0].crontab : "" });
          });
        });
      })
    }
  };

  componentDidUpdate = () => {
    this.getReportSchedule();
  };

  componentDidMount = () => {
    this.getReportSchedule();
  };

  handleCheckboxChange = () => {
    this.setState(prevState => {
      const isChecked = !prevState.isChecked;
      // When the checkbox is checked, automatically enter edit mode
      if (!isChecked) {
        _log("Superset del schedule for " + this.props.match.params.dashboard);
        del("https://superset.eatwithme.online/api/v1/report/" + this.state.reportSchedule.id).done(data => {
          this.setState({ reportSchedule: undefined });
        });
      }
      return { isChecked, isEditing: isChecked };
    });
  };

  handleCronChange = cronExpression => {
    this.setState({ cronExpression });
  };

  saveCronExpression = () => {
    const { cronExpression } = this.state;
    _log("Superset save schedule for " + this.props.match.params.dashboard);
    // Here you can save the cron expression to your server or wherever needed
    if (this.state.reportSchedule?.id)
      put("https://superset.eatwithme.online/api/v1/report/" + this.state.reportSchedule.id, {
        "active": true,
        "crontab": cronExpression,
        context_markdown: "string",
        creation_method: "alerts_reports",
        "custom_width": 1000,
        "dashboard": this.state.dashboard.id,
        "description": this.state.dashboard.result.dashboard_title,
        "email_subject": this.state.dashboard.result.dashboard_title,
        "extra": {},
        "force_screenshot": true,
        "grace_period": 14400,
        "log_retention": 90,
        "name": this.state.dashboard.result.dashboard_title,
        "owners": [this.state.dashboard.result.owners[0].id],
        "recipients": [
          {
            "recipient_config_json": {
              "target": this.props.myStatus.email
            },
            "type": "Email"
          }
        ],
        "report_format": "PNG",
        "type": "Report",
        "working_timeout": 3600
      }).done(data => {
        get("https://superset.eatwithme.online/api/v1/report/?q=(filters:!((col:type,opr:eq,value:Report),(col:dashboard_id,opr:eq,value:" + this.state.dashboard.result.id + ")),order_column:name,order_direction:desc,page:0,page_size:25)").done(data => {
          this.setState({ isEditing: false, reportSchedule: data?.result.length > 0 ? data.result[0] : undefined, isChecked: data?.result.length > 0, cronExpression: data?.result.length > 0 ? data.result[0].crontab : "" });
        });
      });
    else
      post("https://superset.eatwithme.online/api/v1/report/", {
        "active": true,
        "crontab": cronExpression,
        "custom_width": 1000,
        "dashboard": this.state.dashboard.result.id,
        "description": this.state.dashboard.result.dashboard_title,
        "email_subject": this.state.dashboard.result.dashboard_title,
        "extra": {},
        "force_screenshot": true,
        "grace_period": 14400,
        "log_retention": 90,
        "name": this.state.dashboard.result.dashboard_title,
        "owners": [this.state.dashboard.result.owners[0].id],
        "recipients": [
          {
            "recipient_config_json": {
              "target": this.props.myStatus.email
            },
            "type": "Email"
          }
        ],
        "report_format": "PNG",
        "selected_tabs": [
          0
        ],
        "type": "Report",
        "working_timeout": 3600
      }).done(data => {
        get("https://superset.eatwithme.online/api/v1/report/?q=(filters:!((col:type,opr:eq,value:Report),(col:dashboard_id,opr:eq,value:" + this.state.dashboard.result.id + ")),order_column:name,order_direction:desc,page:0,page_size:25)").done(data => {
          this.setState({ isEditing: false, reportSchedule: data?.result.length > 0 ? data.result[0] : undefined, isChecked: data?.result.length > 0, cronExpression: data?.result.length > 0 ? data.result[0].crontab : "" });
        });
      });
    console.log("Cron Expression saved:", this.state.cronExpression);
  };
  editCronExpression = () => {
    this.setState({ isEditing: true, cronExpression: this.state.cronExpression || "0 0 * * *" });
  };

  translateFn = (text) => {
    return I18n.t("admin_local.cron." + text);
  };
  //<!--Cron value={cronExpression} onChange={this.handleCronChange} translateFn={this.translateFn} /-->

  render() {
    const { admin_local } = this.props;
    const { isChecked, cronExpression, isEditing } = this.state;

    if (admin_local)
      return (
        <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto", }}>
          <SubmenuHeader {...this.props} header={admin_local.menus.reports} />
          {/* Checkbox for enabling scheduling */}
          <div style={{ padding: "10px", fontSize: "20px" }} >
            <label style={{ margin: 0 }}>
              <input style={{ width: "15px", height: "15px", marginRight: "10px" }} type="checkbox" checked={isChecked} onChange={this.handleCheckboxChange} />
              {I18n.t("admin_local.Enable Report Scheduling")}
            </label>

            {/* Show cron scheduler only if checkbox is checked and in edit mode */}
            {
              isChecked && isEditing && (
                <div style={{ padding: "10px" }}>
                  <SchedulerRoot
                    cron={cronExpression || "0 0 * * *"}
                    setCron={this.handleCronChange}
                    setCronError={() => { }}
                    isAdmin={false}
                    locale={localStorage.language}
                    customLocale={admin_local.cron}
                  />
                  <button className="btn btn-positive" onClick={this.saveCronExpression}>{I18n.t("admin_local.save")}</button>
                  {cronExpression}
                </div>
              )
            }
            {
              isChecked && !isEditing && (
                <>
                  <button className="icon-edit" style={{ border: "unset", background: "unset" }} onClick={this.editCronExpression}></button>
                  {this.state.reportSchedule.crontab_humanized} - {cronExpression}
                </>
              )
            }
          </div>
          {!isEditing && this.state.languageSet && (
            <iframe src={`https://superset.eatwithme.online/superset/dashboard/${this.props.match.params.dashboard}/?standalone=true&locale=${localStorage.language}`}
              style={{ width: "100%", height: "100%", border: "none" }}></iframe>
          )}
        </div >
      );
    else return null;
  }
}

const mapStateToProps = state => {
  return {
    myStatus: state.rootReducer.myStatus,
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local,
  };
};

export default connect(mapStateToProps)(SupersetDashboard);
