const admin_local_en = {
  confirm_customer_deletion: "Are you sure you want to delete user <b>{0}<b>?",
  confirm_role_deletion: "Are you sure you want to revoke the <b>{1}</b> access to <b>{2}</b> from <b>{0}</b>?",
  do_you_really_want_to_delete_the_menu_category: "Are you sure you want to delete menu category?",
  do_you_really_want_to_delete_the_menu_item: "Are you sure you want to delete menu item?",
  failed_to_delete_user: "Deletion failed. <br>Error: {0}",
  failed_to_delete_user_anonimize: "The user cannnot be deleted! Would you like to anonimize instead? Name, email, password will be deleted!",
  are_you_sure_you_want_to_delete_the_table: "Are you sure you want to delete <b>table {0}</b>",
  are_you_sure_you_want_to_delete_the_room: "Are you sure you want to delete <b>room {0}</b>",
  are_you_sure_you_want_to_delete: "Are you sure you want to delete?",
  failed_to_delete_table_in_use: "The table cannot be deleted! It was already used! Deactivate it instead!",
  failed_to_create_table_not_unique: "Failed to create table. Table number is in use already!",
  failed_to_delete_drink_category_in_use: "Failed to delete the drink category! It is in use already. Deactivate it instead!",
  failed_to_delete_drink_in_use: "Failed to delete the drink! It is in use already. Deactivate it instead!",
  failed_to_delete_meal_category_in_use: "Failed to delete the meal category! It is in use already. Deactivate it instead!",
  failed_to_delete_meal_in_use: "Failed to delete the meal! It is in use already. Deactivate it instead!",
  failed_to_delete_discount_type_in_use: "Failed to delete the discount type! It is in use already. Deactivate it instead!",
  failed_to_delete_label_in_use: "Failed to delete the label! It is in use already!",

  table_number_label: "Table number",
  number_of_seats: "Seats available",
  shareable: "Shareable",
  free_seats: "Free seats",
  active: "Active",
  _active: "Act.",
  table_editor: "Table",

  add: "Add",
  edit: "Edit",
  delete: "Delete",
  activate: "Activate",
  deactivate: "Deactivate",
  make_shareable: "Make table shareable",
  make_unshareable: "Make table non-shareable",
  make_pos: "It is point of sale",
  make_non_pos: "It is not a point of sale",
  lock: "Occupy",
  new_lock: "+ Occupy",
  lock_pickup: "New pickup",
  lock_homedelivery: "New home delivery",
  unlock: "Free it up",

  tables: "Tables",
  enter_table_number: "Enter table number",
  enter_table_seats: "Enter available seats",
  table_is_shareable: "Table is shareable",
  table_is_active: "Table is active",
  table_free_seats: "Free seats available",

  unit_price: "Unit price",
  _unit_price: "Price",

  drinks: "Drinks",
  drink_name: "Name",
  drink_quantity_type: "Quantity type",
  drink_available_quantity: "Available quantities",
  drink_quantity_name: "Name",
  drink_quantity: "Quantity",
  drink_price: "Price",
  drink_editor: "Drink editor",
  drink_category_name: "Category",
  drink_category_editor: "Drink category editor",
  add_category: "New category",
  _add_category: "New cat.",
  add_item: "New item",
  _add_item: "New item",

  meals: "Meals",
  meal_name: "Name",
  meal_quantity_type: "Quantity type",
  meal_available_quantity: "Available quantities",
  meal_quantity_name: "Name",
  meal_quantity: "Quantity",
  meal_price: "Price",
  meal_editor: "Meal editor",
  meal_category_name: "Category",
  meal_category_editor: "Meal category editor",
  add_meal_category: "New category",
  add_meal: "New meal",

  available_quantity: "Available quantities",
  _available_quantity: "Quantities",

  description: "Description",
  enter_description: "Enter description if needed",

  language: "Language",
  default_language: "Default",
  not_defined: "???",
  close: "Close",
  close_order: "Close",
  save_and_new: "Save+Copy",
  pay: "Pay",
  save_and_unlock: "Pay and free up",

  rejection_reason: "Reason of rejection",
  typical_rejection_reasons: "Typical reasons",
  custom_reason: "Custom reason",

  table_number: "Table number: {0}",
  team_number: "Team: {0}",
  organizer: "Organizer: {0}",
  order_number: "Order number: {0}",
  oldest_order: "Latest order: {0}",
  table_occupation_number: "Occupation nr. {0}",

  orders_tab: "Order list",
  by_person_tab: "By person",
  by_timing_tab: "By course",
  by_drink_or_meal_tab: "By meal or drink",

  inherit: "Inherit",
  meal_timing: "Timing",
  timing_inherit: "Inherit",
  timing_asap: "As soon as possible",
  timing_appetizer: "Appetizer",
  timing_maincourse: "Main course",
  timing_desert: "Dessert",
  timing_last: "At the end",
  timing: "Timing",
  timing_null: "",

  production_lines: "Production lines",
  production_line_editor: "Production line",

  production_line: "Production line",
  _production_line: "P. line",
  production_line_waiting: "Dependent production line",
  production_line_length: "Queue",
  production_line_title: "{0} production line",
  all_production_lines: "All production lines",
  default_production_line: "Default production line",
  _default_production_line: "Prod. line",
  production_line_name: "Name",
  enter_production_line_name: "Enter name for production line ",
  production_line_is_active: "Production line is active",
  are_you_sure_you_want_to_delete_the_production_line: "Do you really want to delete production line <b>{0}</b>?",
  failed_to_delete_production_line_in_use: "Failed to delete the production line! It is in use already. Deactivate it instead!",
  failed_to_create_production_line_not_unique: "Failed to create production line! Not unique!",

  vat_categories: "VAT categories",
  vat_category_name: "VAT category name",
  vat_category: "VAT category",
  _vat_category: "VAT",
  vat_category_editor: "VAT category",
  enter_vat_category_name: "Please enter the VAT category name",
  percent_name: "Percent",
  enter_percent: "Please enter the VAT value as percent (e.g. 27)",
  default_vat_category: "VAT",
  _default_vat_category: "VAT",
  default_takeaway_vat_category: "Takeaway VAT",
  _default_takeaway_vat_category: "Taway. VAT",

  occupy: "Occupy",
  number_of_persons: "Number of persons",
  unoccupy: "Free up",
  new_order: "New order",
  submit_new_orders: "Submit new orders",
  submit_and_accept_new_orders: "Submit and accept new orders",
  submit_and_accept_and_print_new_orders: "Print new orders",
  submit_and_accept_and_print_and_pay_new_orders: "Payment",
  paying: "Paying",
  cancel_paying: "Cancel bill",
  ordering_allowed: "Bill revoked, ordering allowed again!",
  paid: "Paid",

  please_choose_mate_wanting_to_pay: "Who would like to pay?",
  please_choose_mate_who_paid: "Who paid?",
  print_invoice: "Print invoice",

  roles: "Roles",
  currencies: "Currencies",
  payment_methods: "Payment methods",
  connected_restaurants: "Connected restaurants",
  connected_restaurant: "Connected restaurant",
  alias: "Global id",

  role: "Role",
  payment_method: "Payment method",
  _payment_method: "Payment m.",
  user: "User",
  users: "Users",
  entity_type: "Entity type",
  entity: "Entity",
  entities: "Entities",

  restaurant_editor: "Restaurant",
  name: "Name",
  _name: "Name",
  languages: "Languages",
  town: "Town",
  longitude: "Longitude",
  latitude: "Latitude",

  exchange_rate: "Exchange rate",
  _exchange_rate: "Exch.r.",

  please_select_a_restaurant: "Please select a restaurant",

  invoice_heading: "Invoice heading",
  invoice_footer: "Invoice footer",
  enter_invoice_heading: "Please provide the heading of the invoices in html format!",
  settings: "Settings",

  not_allowed_order_paid: "Action not allowed with a paid order!",
  not_allowed_order_is_being_paid: "Action not allowed with an order being paid!",

  more_than_a_day: "> 1 day",

  active_average_acceptance_duration: "Average order acceptance",
  active_average_delivering_duration: "Average order delivery",
  active_max_acceptance_duration: "Highest order acceptance",
  active_max_delivering_duration: "Highest order delivery",
  active_min_acceptance_duration: "Lowest order acceptance",
  active_min_delivering_duration: "Lowest order delivery",

  mandatory: "Mandatory",
  included: "Removable",
  optional: "Options",
  references: "References",
  isTop: "On menu",
  _isTop: "M.",

  failed_to_get_restaurant_layout: "No restaurant layout defined! Please upload one first!",

  qr_code: "QR code",
  person: "Person",

  printers: "Printers",
  printer: "Printer",
  label_printer: "Label printer",
  connected_from: "Connected",

  collaborating_production_lines: "Collaborating",
  short_name: "Short name",
  do_you_really_want_to_print_the_orders_until_timing: "Do you really want to print the orders only until <b>{0}</b> course?",
  do_you_really_want_to_print_only_the_selected_orders: "Do you really want to print only the selected order?",
  do_you_really_want_to_reprint_the_orders_until_timing: "Do you really want to <b><u>reprint</u></b> the orders until the <b>{0}</b>?",
  do_you_really_want_to_reprint_only_the_selected_orders: "Do you really want to <b><u>reprint</u></b> the selected orders?",
  enable_edit_mode: "Change table layout",
  disable_edit_mode: "Restore table layout",
  change_table_number: "Change table",
  save_layout: "Save table layout",
  layout_succesfully_saved: "Table layout saved!",
  table: "Table",
  seat: "Seat",
  rotate_right: "Rotate right (45°)",
  rotate_left: "Rotate left (45°)",
  duplicate: "Duplicate",
  enter_barion_pos_key: "Please provide your Barion Secret key (POSKey)",

  printer_server_name: "Printer server name",
  printInvoice: "Print invoice",
  printTableSlip: "Print table QR code",
  printVATInvoice: "Print VAT invoice",
  printer_actions: "Printing actions",
  format: "Format",

  quantity_types: "Serving types",

  enter_category: "Enter category name",
  enter_Drink_menuitem: "Enter meal name",
  enter_Meal_menuitem: "Enter drink name",

  scan_to_pay_table: "Scan with your phone\r\n to see the consumption at the table nr. {0}.\r\nand to online settle the bill!",
  scan_to_join_table: "!LF!ESC|cA!ESC|4CTable {0}!LF!LF!ESC|4C!ESC|cAScan and order!LF!ESC|cAand manage your consumption online!",

  print_receipt: "Print receipt",
  choose_customer_for: "Choose customer for person {0}.",
  search_pattern: "Search pattern",
  clear: "Clear",

  discount_types: "Discount types",
  discount: "Discount",
  _discount: "Disc.",
  scope: "Scope",
  when: "When",
  how_much: "How much",
  cumulative: "Cumulative",
  categories_or_menuitems: "Menu items or categories",
  labels: "Labels",
  fromDate: "From date",
  toDate: "To date",
  fromTime: "From time",
  datetime: "Date and time",
  discount_percent: "Percent",
  discount_amount: "Fix amount",
  on_day: "On day of week",
  day1: "Sunday",
  day2: "Monday",
  day3: "Tuesday",
  day4: "Wednesday",
  day5: "Thursday",
  day6: "Friday",
  day7: "Saturday",
  per_item: "Per item",
  label: "Label",
  are_you_sure_you_want_to_delete_the_production_line: "Are you sure you want to delete the label <b>{0}</b>",
  enter_labels: "Choose your labels",
  discounts: "Discounts",
  apply_type_discount: "Apply discount",
  manual: "Manual",
  automatic: "Automatic",
  to_whom: "To whom",
  store: "Store",
  stores: "Stores",
  input_stores: "Input stores",
  output_stores: "Output stores",
  cost: "Cost",
  unit_price_estimation: "Proposed unit price",
  cost_estimation: "Cost estimation",
  suppliers: "Suppliers",
  supplier: "Supplier",
  incomingStock: "Incoming stock",
  incomingStocks: "Incoming stocks",
  date: "Date",
  default_store: "Default store",
  invoice_id: "Invoice number",
  delivery_note_id: "Delivery note id",
  supplier_code: "Supplier code",
  _supplier_code: "Supplier c.",
  product: "Product",
  quantity: "Quantity",
  _quantity: "Quantity",
  quantity_type: "Quantity type",
  _quantioty_type: "Q. t.",
  expiry_date: "Expiry",
  net_unit_price: "Net unit price",
  brutto_unit_price: "Brutto unit price",
  net_price: "Net price",
  brutto_price: "Brutto price",
  _net_unit_price: "N. u. price",
  _brutto_unit_price: "B. u. price",
  _net_price: "Net price",
  _brutto_price: "Brutto price",
  total_incoming_stock_entries: "<span>{0}</span> <span class='browser-only'> entries in total</span>",
  entry_count: "Entry count",
  _entry_count: "E.",
  incoming_stock_entry_text_input: "Text input",
  are_you_sure_you_want_to_delete_the_incoming_stock: "Are you sure you want to delete the selected incoming stock entry?",
  are_you_sure_you_want_to_delete_the_inventory: "Are you sure you want to delete the selected inventory entry?",
  delete_all_order_history: "Delete all order history",

  tableOccupations: "Table occupations",
  traffic: "Traffic",
  delete_traffic: "Delete traffic",
  until_when_delete_the_traffic: "Until when do you want to delete past traffic (format: YYYY-MM-DD)",
  are_you_sure_you_want_to_delete_traffic_until: "Are you sure you want to delete the traffic until <strong>{0}</strong>",
  menu: "Menu",
  time: "Time",
  seqnr: "Sequence number",
  _seqnr: "Seq.nr.",
  duration: "Duration",
  _duration: "Dur.",
  person_count: "Person count",
  amount_paid: "Paid",
  tip: "Tip",
  _tip: "Tip",
  cancelled_orders: "Deleted",
  ordered_by: "Ordered by",
  orders: "Orders",
  _orders: "O.nr.",
  drink: "Drink",
  meal: "Meal",
  last_status: "Last status",
  _last_status: "Last st.",
  deleted: "Deleted",
  _deleted: "Del.",
  yes: "Yes",
  no: "No",
  order_sequence_number: "Order number",
  _order_sequence_number: "O. nr.",
  is_paid: "Paid",
  paid_by: "Paid by",
  _paid_by: "Paid",
  price: "Price",
  amount_to_pay: "Payable",
  payments: "Payments",
  currency: "Currency",
  amount: "Amount",
  replace: "Replace",
  menuitem_ordered: "What",
  daily_closing: "Daily closing",
  table_occupations: "Tables",
  count: "Count",
  test_system: "Test system",
  test_mode: "Test mode",
  multiple_physical_printers_try_to_server_the_virtual_server: "Multiple physical printers are configured for virtual printer {0}! The '{1}' and the '{2}'!",
  "The device communications channel could not be opened, check the device and retry.": "The priter seems to be switched off or badly configured.",
  pay_himself: "Pays himself",
  unknown_person_pays: "Person {0}. pays",
  change_table: "Change table",

  confirm_person_change_to_empty_table: "Person {0}. changes to empty table no. {1}? <br/>As new occupation or as part of the current one?",
  confirm_person_change_to_table: "Person {0}. joins the already occupied table no. {1}?",
  confirm_table_change_to_table: "The whole table joins the already occupied table {0}.?",
  confirm_person_change_to_shared_table: "Person {0}. changes to the already occupied table no. {1}?<br/>As new occupation or joins the existing one?",
  confirm_person_change_to_multiple_occupation_table: "Person {0}. changes already occupied table no. {1}?",

  new_occupation: "As new occupation",
  extend_occupation: "Extend the current occupation",
  joins: "Joins",
  joins_table1: "Joins the first occupation with {0} people",
  joins_table: "Joins the {0}th occupation with {1} people",
  new_independent_occupation: "New occupation",
  target_table_same_and_source: "Target table is the same as the source!",
  sitting_at_table: "Sitting at table no. {0}",
  confirm_additional_table: "Do you add table no. {0} to the current occupation?",
  confirm_change_to_table: "Everybody changes to table no. {0}?",
  confirm_change_to_homedelivery: "Change for homedelivery?",
  confirm_change_to_pickup: "Change for pickup?",
  confirm_table_change_to_shared_table: "Target table is a shareable table. Please choose between the available options.",
  default: "Default",
  role_names: {
    admin: "Administrator",
    waiter: "Waiter",
    cook: "Cook",
    store: "Inventory manager",
    printerserver: "PrinterServer",
    finance: "Finance",
    courier: "Courier",
  },
  restaurant: "Restaurant",
  pending: "Pending",
  suspend_ordering: "Suspend ordering",
  continue_ordering: "Continue ordering",
  split_invoice: "Split invoice",
  _split_invoice: "Split",
  pays: "Pays",
  comments: "Comments",
  invoice_printed: "Invoice successfully sent to printer",
  room: "Room",
  move_forward: "Move forward",
  move_backward: "Move backward",
  move_top: "Move on top",
  move_back: "Move to back",
  all: "All",
  isInventoryItem: "Inventory item",
  isInventoryItem2: "Inventory item/Raw material",
  isInventoryItem3: "Inventory item",
  _isInventoryItem: "I.",
  total: "Total",
  menuitems: "Items",
  menuitem: "Item",
  item: "Item",
  _item: "Item",
  category: "Category",
  _category: "Cat.",
  you_need_admin_right_for_this_action: "You need administrator privileges to perform this action!",
  pricePerCostRatio: "Unitprice / cost ratio",
  price_estimation: "Proposed price",
  add_option: "New option",
  option: "Option",
  _option: "Opt.",
  enter_cost: "Set cost",
  enter_price: "Set price: eg. 500 or 40%",

  checkbox_option: "Multiple options can be selected from the category when ordering",
  checkbox_option2: "Keep as separate option category. Multiple options can be selected from the category when ordering",
  optional_radio_option: "Maximum one options can be selected from the category when ordering",
  mandatory_radio_option: "One options will have to selected from the category when ordering",

  deletion_not_allowed: "Deletion not possible",
  paid_order_cannot_be_deleted: "Paid orders cannot be deleted!",
  order_cannot_be_deleted_while_mate_is_paying: "Orders of a person in process of payment cannot be deleted!<br/>Remove first the payment state of the person!",
  order_cannot_be_deleted_while_order_was_already_selected_for_payment_one: "Orders selected once for payment can be deleted only with administrator rights!",
  original_quantity: "Original quantity",
  recalculateConsumption: "Recalculate stock",
  recalculateConsumptionAll: "Recalculate all stock",
  _recalculateConsumption: "Recalc. stock",
  inventory: "Stock-taking",
  incoming_stock: "Incoming",
  stock: "Stock",
  inventory_surplus: "Surplus",
  inventory_shortage: "Shortage",
  minute: "   ",
  hour: "Hour",
  day: "Day",
  week: "Week",
  month: "Month",
  consumption: "Sold volume",
  price_goal: "Target price",
  today: "Today",
  yesterday: "Yesterday",
  last_7_days: "Last 7 days",
  last_30_days: "Last 30 days",
  last_3_months: "Last 3 months",
  this_week: "This week",
  this_month: "This month",
  this_quarter: "This quarter",
  last_week: "Previous weeek",
  last_month: "Previous month",
  last_quarter: "Previous quarter",
  customRange: "Custom range",
  failed_to_execute_action: "Action failed!",
  no_inventory_quantity: "No quantity was selected for inventory handling!",
  income: "Income",
  profit_margin: "Profit margin",
  _profit_margin: "Margin",
  there_is_a_loop_in_the_additions: "There is a reference loop between the menu items!",

  are_you_sure_you_want_to_delete_the_floorplan: "Are you sure you want to delete the floorplan <b>{0}</b>?",
  floorplans: "Floorplans",
  tooltip_printed: "Printed",
  tooltip_printfailed: "Printing error!",
  tooltip_partprinted: "Printing error! New printing attempt in progress!",
  tooltip_printing: "Printing in progress!",
  tooltip_notprinted: "Not printed yet!",
  controlled_production_lines: "Changing status altogether",
  producing_rules: "State after printing",
  pos_producing_rules: "State after printing for point of sale",
  manualOnly: "Manual",
  afterPrint: "After printing",
  install_printer_server: "Installation of printer server",
  failed_to_print_kitchen_slip: "Failed to print kitchen slip!",
  failed_to_print_invoice: "Failed to print invoice!",
  ok: "Ok",
  secret_key: "Secret key",
  global_id: "Global id",
  server_url: "WAN url",
  local_url: "LAN url",
  backup_url: "BCK url",
  "enabled-features": "Enabled features",
  online: "Online",
  _online: "O.",
  visible_online: "Visible to guests",
  book_online: "Bookable to guests",
  make_nonVisibleOnline: "Hide table from guests",
  make_visibleOnline: "Make table visible to guests",
  make_nonBookOnline: "Disable guest occupation",
  make_bookOnline: "Enable guest occupation",
  mate_already_joined_table_in_this_restaurant: "{0} already joined table no. {1}!",
  confirm_not_printing: "Dou you really want to skip printing the orders?",
  save: "Save",
  save_and_close: "Save & Close",
  save_and_open: "Save & Test",
  table_types: {
    rectangle_two_seats: "Two seats table",
    rectangle_four_seats: "Four seats table",
    rectangle_six_seats: "Six seats table",
    circle_four_seats: "Four seats round table",
    rounded_four_seats: "Four seats rounded table",
    rounded_six_seats: "Six seats rounded table",
    person: "Person",
    persons: "Persons",
    pool_table: "Pool table"
  },
  seat_types: {
    normal_chair: "Normal chair",
    wooden_chair: "Wooden chair"
  },
  tip_amount_seems_excesive: "Amount seems excesive! Continue?",
  non_printer_configured: "No printer configured",
  disposed_quantity: "Disposed quantity",
  comment: "Megjegyzés",
  entries: "Entries",
  entry: "Entry",
  other: "Other",
  created_by: "Created",
  modified_by: "Modified",
  start_count: "Expected",
  change: "Change",
  changeit: "Change it",
  end_count: "Real",
  inventory_disposal: "Disposal",
  extra_frame_left: "Left frame content",
  extra_frame_right: "Right frame content",
  extra_frame_top: "Top frame content",
  extra_frame_bottom: "Bottom frame content",
  empty: "Empty",
  serving: "Serving",
  drinkbar: "Drinkbar",
  point_of_sale: "POS",
  new_sale: "New sale",
  invoice: "Invoice",
  payment: "Payment",
  for_all: "Apply for all",
  for_selected_none: "Apply for selected",
  for_selected_order: "Apply for selected order",
  for_selected_tableMate: "Apply for selected person",
  cancel: "Cancel",
  persons: "Persons",
  _persons: "P.",
  phonenumber: "Phone",
  company: "Restaurant/Company name",
  company_name: "Company name",
  address: "Address",
  city: "City",
  country: "Country",
  provide_phonenumber: "Please provide your phone number",
  provide_company: "Please provide the name of your restaurant and/or company name",
  provide_address: "Please provide your address",
  provide_city: "Please provide your city",
  provide_country: "Please provide your country",
  manufacturer: "Manufacturer",
  for_store: "For store",
  are_you_sure_you_want_to_delete_the_store: "Are you sure you want to delete the store <b>{0}</b>",
  stock_movement: "Stock movemement",
  from_store: "Source store",
  to_store: "Target store",
  _from_store: "Source",
  _to_store: "Target",
  moved_quantity: "Transfered quantity",
  view: "View",
  list: "List",
  calendar: "Calendar",
  thanks_for_buying: "Thanks for buying",
  sale: "Sale",
  add_room: "Add room",
  room_editor: "Room",
  seats: "seats",
  room_tables: "{0} tables",
  next: "Next",
  back: "Back",
  room_tables_seats: "{0} tables with {1} seats",
  book_a_table: {
    title: "Book a table",
    number_of_seats: "{0} seats",
    seats: "Seats",
    tables: "Tables",
    room: "Room",
    rooms: "Rooms",
    provide_email: "Email",
    provide_name_or_email: "Enter guest's name or email",
    provide_phone: "Phone number",
    provide_name: "Name",
    provide_comment: "Comment",
    not_enough_free_tables: "There are not enough free tables in any of the rooms!",
    not_free_table: "There are no free table in any of the rooms!"
  },
  activate_booking: "Activate booking",
  cancel_booking: "Cancel booking",
  cancel_b: "Cancel",
  extensions: "Extensions",
  table_extentions_placeholder: "+table_number=total_seats_count e.g. +4=8,+4+5=12",
  quantity_must_be_unique: "The available quantities must be unique!",
  menus: {
    tableOverview: "Table overview",
    bar: "Bar",
    serving: "Serving",
    productionLines: "Production lines",
    reports: "Reports",
    stockManagement: "Stock management",
    administration: "Administration",
    restaurants: "Restaurants",
    users: "Users",
    currencies: "Currencies",
    paymentMethods: "Payment methods",
    manage_reports: "Manage reports",
    monitoring: "Monitoring",
    drinks: "Drinks",
    meals: "Meals",
    tables: "Tables",
    VATCategories: "VAT categories",
    printers: "Printers",
    servings: "Servings",
    discounts: "Discounts",
    labels: "Lables",
    profile: "My profile",
    stockList: "Stock management",
    shoppingList: "Shopping list",
    incomingStocks: "Incoming stocks",
    stockMovements: "Stock movements",
    inventory: "Inventory",
    inventoryScrapping: "Inventory scrapping",
    suppliers: "Suppliers",
    stores: "Stores",
    superAdminMenu: "Super admin",
    pointOfSales: "Point of sales",
    tableBooking: "Table reservations",
    stockReport: "Stock statistics",
    orders: "Orders",
    orders_quick_action: "Set order status",
    cashRegisters: "Cash registers",
    bankTerminals: "Bank terminals",
    scales: "Scales",
    daily_closing: "Daily closing",
    support: "Support",
    updateSettings: "Updates",
    barcodes: "Barcodes",
    wolt: "Wolt",
    foodpanda: "FoodPanda",
    falatozz: "Falatozz",
    wolt_administration: "Wolt administration",
    advertisements: "Advertisements",
    products: "Products",
    menuitems: "Products",
    traffic: "Traffic",
    todaytraffic: "Daily traffic",
    comments: "Comments",
    analytics: "Analytics",
    plans: "Plans",
    opening_hours: "Opening hours",
    bi: "Business intelligence",
    mautic: "CRM",
    send_reseller_fees: "Send resellers fees",
    send_reseller_fees_to_me: "Send resellers fee to me only",
    check_subscriptions: "Check subscriptions",
    waiterScreen: "Waiter screen",
    guestScreen: "Guest screen",
    voipphone: "VOIP Phone",
  },
  cashRegister: "Cash register",
  stable_versions: "Stable versions",
  test_versions: "Test versions",
  status: "Status",
  test: "Test",
  Grid: {
    Pager: {
      Button: {
        backButtonText: "Back",
        nextButtonText: "Next"
      },
      Description: {
        no_records: "No %{recordType} Available",
        records_displayed: "%{firstIndex} through %{lastIndex} of %{total} %{recordType} Displayed"
      }
    }
  },
  map: "Map",
  basic_data: "Basic data",
  restaurant_settings: {
    default_language: "Default language"
  },
  show_deleted: "Show deleted items",
  image: "Image",
  select_a_product: "select a product",
  select_a_category: "select a category",
  select_a_quantity_type: "select a quantity type",
  select_a_vat_category: "select a VAT category",
  select_a_store: "select a store",
  select_a_supplier: "select a supplier",
  source_and_target_store_should_be_different: "Source and target store should be different",
  custom_range: "Custom range",
  initial_stock: "Starting stock",
  final_stock: "End stock",
  outgoing_stock: "Stock movements",
  scrapping: "Scrapping",
  shortage: "Shortage",
  surplus: "Surplus",
  minStock: "Minimum stock",
  maxStock: "Maximum stock",
  total_stock_cost: "Stock purchase value",
  total_stock_value: "Stock sale value",
  initialCost: "Starting cost",
  finalCost: "End cost",
  averageCost: "Average cost",
  initialPrice: "Starting price",
  averagePrice: "Average price",
  finalPrice: "End price",
  minPrice: "Minimum price",
  maxPrice: "Maximum price",
  netto_income: "Net income",
  vat: "VAT",
  brutto_income: "Income",
  customer_details: "Customer details",
  tomorrow: "Tomorrow",
  available: "Available",
  _available: "A",
  order_summary: "Order summary",
  product_serving: "Type",
  download: "Download",
  upload: "Feltöltés",
  order_no: "Order no.",
  no_matches_found: "No match found",
  could_not_find_this_order: "Order no. {0} not found!",
  could_not_find_this_order2: "Order not found!",
  open: "Open",
  fix_quantity: "Fix quantity",
  are_you_sure_you_want_to_delete_the_printer: "Are you sure you want to delete printer <b>{0}</b>",
  are_you_sure_you_want_to_delete_the_quantity_type: "Are you sure you want to delete serving <b>{0}</b>",
  are_you_sure_you_want_to_delete_the_vat_category: "Are you sure you want to delete VAT category <b>{0}</b>",
  are_you_sure_you_want_to_delete_the_discount_type: "Are you sure you want to delete discount type <b>{0}</b>",
  are_you_sure_you_want_to_delete_the_cashregister: "Are you sure you want to delete cash register <b>{0}</b>",
  are_you_sure_you_want_to_delete: "Are you sure you want to delete",
  connection: "Connection",
  type: "Type",
  delete_order_history: "Delete all past orders",
  ready_for_delivery: "Serve it",
  table_ready_for_delivery: "!ESC|bC!ESC|4CTable no. {0}!LF!ESC|bC!ESC|4COrder no. {1}!LF!ESC|bC!ESC|4C{2}!LF!ESC|bC!ESC|4CServe it!",
  proportional_addition_tooltip: "Proportional addition. The actual addition will be the product of the selected quantity of the base item and the quantity of the addition.",
  you_are_not_authorized_to_access_this_restaurant_with_an_app: "Based on your subscription you are not allowed to access this unit.",
  you_are_not_authorized_to_access_this_feature: "Based on your subscription you are not allowed to access this feature.",
  barcode: "Barcode",
  test_scale: "Scale test",
  tare_weight: "Tare weight",
  gross_weight: "Gross weight",
  full_inventory: "Full inventory",
  all_items: "All",
  new: "New",
  parent_category: "Parent category",
  choose_parent_category: "Choose parent category",
  save_my_details_for_later_use: "I consent that my personal data to be stored and managed by {0} and the cloud based retaurant management system EatWithMe.",
  saved_my_details_for_later_use: "I agreed that my personal data to be stored and managed by {0} and the cloud based retaurant management system EatWithMe.",
  agree: "Provide my consent",
  accept_homedelivery_terms: "I agree with the home delivery conditions",
  accept_pickup_terms: "I agree with the pickup conditions",
  accept_privacy_terms: "I accept the privacy policy",
  privacy_policy: "Privacy policy",
  privacy_policy_link: "https://eatwithme.online/privacy-policy/",
  door: "Floor/door",
  backup: "Backup",
  withdraw: "Withdraw",
  tax_number: "Tax number",
  tax_number_pattern: "(^$)|(^\\d\\d\\d\\d\\d\\d\\d\\d-\\d-\\d\\d$)",
  email: "E-mail",
  postcode: "Postal code",
  postcode_pattern: "^\\d\\d\\d\\d$",
  not_empty_pattern: "\\w+",
  email_pattern: "[^@]+@[^@]+(\\.[^@]+)+",
  address_pattern: "^.+ (street|square|park) \\d+\\.?$",
  address_pattern2: "[^,].+ (hrsz|arok|atjaro|dulo|dulout|erdosor|fasor|fordulo|gat|hatarsor|határút|kapu|körönd|körtér|körút|köz|lakótelep|lejáró|lejtő|lépcső|liget|mélyút|orom|ösvény|park|part|pincesor|rakpart|sétány|sikátor|sor|sugárút|tér|udvar|ut|utca|üdülőpart)",
  address_placeholder: "Format: name (street|square|etc.) number.",
  repay_invoice_dialog: "Would you like to modify the payment details?",
  except: "Except",
  first_modify_start_date: "First change the start time",
  uncategorized_items: "Products without a category set",
  wolt: {
    reload: "Refresh from Wolt",
    upload_selected_elements: "Upload selected products to Wolt",
    resync_selected_elements: "Resynchronized selected products to Wolt",
    delete_selected_elements: "Delete selected products from Wolt",
    elements_in_sync: "Number of products in sync",
    elements_in_unsync: "Number of products out of sync",
    elements_in_to_upload: "Number of products missing from Wolt",
    elements_in_to_delete: "Number of extra products on Wolt",
    delete_menu_items: "Delete products from Wolt",
    delete_category: "Delete product category from Wolt",
    delete_category_items: "Delete from Wolt products with exists only there for this category",
    sync_category_items: "Resynchronize the products which already exists both in EatWithMe and Wolt",
    upload_category_items: "Upload products missing from Wolt",
    sync_menu_item: "Synchronize product to Wolt",
    delete_menu_item: "Delete product from Wolt",
    upload_menu_item: "Upload product to Wolt",
    upload_category: "Delete product category from Wolt",
  },
  vat_invoice: "VAT invoice",
  there_already_exists_a_table_with_this_number: "You already placed a table with this number!",
  access_tokens: "Access tokens",
  generate_access_token: "Generate access token",
  printer_server: "Printer server",
  purchase_price: "Net purchase price",
  selling_price: "Gross selling prince",
  unit_of_consumption: "Selling/Usage unit",
  picture: "Picture",
  homedelivery: "Home delivery",
  pickup: "Pickup",
  onsite: "Local consumption",
  by_occupation_start: "By time of order",
  by_payment: "By time of payment",
  are_you_sure_you_cancel_the_payment: "Are you sure you cancel registering the payment details?",
  value_difference: "Value difference",
  total_value_difference: "Total value difference",
  subscription: "Subscription",
  print: "Print",
  something_strange_happened_resync: "Something unexpected happened! Resynchronizing...",
  payment_due_in: "Payment due in {0} day(s)!",
  payment_was_due_in: "Payment overdue since {0} day(s)!",
  continue_in: "After {0} minute(s) this warning will close. Please wait...",
  profitandloss: {
    open_stock: "Open stock",
    cost: "Cost",
    purchase: "Purchase",
    shortage: "Surplus/shortage",
    scrapping: "Scrapping",
    closing_stock: "Closing stock",
    stock_change: "Stock change",
    income: "Income",
    profit: "Profit",
    profitp: "Profit (%)",
    count: "Sale count",
    consumption: "Sold quantity",
    minPrice: "Min price",
    maxPrice: "Max price",
    avgPrice: "Average list price",
    avgDiscount: "Average discount",
    avgActualPrice: "Average price",
    minCost: "Min cost",
    maxCost: "Max cost",
    avgCost: "Average cost"

  },
  service_fee: "Service fee",
  _service_fee: "Service fee",
  service_fee_net: "Service fee net",
  fiscat_service_fee: "Service fee",
  rounding: "Rounding",
  income_without_cost: "Income without cost?",
  not_used_ingredient: "Not used ingredient",
  disconnected: "Not connected",
  error: "Connection error",
  "cover-open": "Cover open",
  "out-of-paper": "Out of paper",
  refresh_my_data: "Update my details",
  skin_picker: "Theme picker",
  skins: [
    { id: 'default', name: "Default" },
    { id: 'fancy', name: "Fancy" },
    { id: 'flat', name: "Flat" },
    { id: 'night', name: "Night" },
    { id: 'old', name: "Old style" },
  ],
  draft: "Tervezet",
  detailed: "Detailed bill",
  service_fee_vat: "Separated service fees",
  too_many_bluetooth_cashiers: "Only one bluetooth cachier can be paired simultaneously!",
  no_bluetooth_cashiers: "Please connect or pair a bluetooth cashier machine!",
  no_bluetooth_adapter: "Bluetooth iterface is not available!",
  no_serial_cashiers: "Please connect a cashier machine with usb-serial cable!",
  locked: "locked",
  addlogin: "Login additional user",
  syncronize_barcodes: "Synchronize",
  download_barcodes: "Download",
  upload_barcodes: "Upload",
  local_product: "Linked product",
  name_of_customer: "Set name of guest",
  product_modified_save_it: "Modified product!",
  drop: "Drop",
  opened: "Open",
  opening_time: "Opening time",
  planned_closing_time: "Planned closing time",
  open_day: "Open day",
  continue_without_open_day: "Continue anyhow",
  timetable: "Opening hours",
  closed: "Closed",
  failed_to_send_order: "Failed to order: {0}",
  resend: "Try again",
  closed: "Closed",
  opened: "open",
  local_addition_tooltip: "Applied for local consumption",
  takeaway_addition_tooltip: "Applied for takeaway or home delivery",
  top_category: "Top category",
  _top_category: "Top cat.",
  takeaway: "Takeaway",
  cashier: "Cashier",
  download_template: "Upload template",
  user_missing: "No user is registered for the provided email address",
  revert_table_occupation: "Do you really want to revert?",
  revert_table_occupation_multi_payment: "Do you really want to revert? There were multiple payers, the payment can be reverted on the payments tab!",
  revert: "Let's revert",
  revert_table_mate_payment: "Revert payments as well",
  revert_payment: "Do you really want to revert the payment?",
  no_inventory_quantity_selected: "Inventory item but no inventory quantity is selected!",
  multiple_inventory_quantity_selected: "Do you really want to set multiple individual inventory quantities!",
  preorder_possible: "Preorder",
  allowed_installments: {
    start: "In advance",
    by_each: "Daily",
    end: "At the end"
  },
  installments_strategy: "Payment schedule",
  payer: "Payer",
  couriers: "Couriers",
  courier: "Courier",
  access_denied: "Access denied: {0}",
  days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  do_you_realy_want_to_order_so_many: "Do you really want to order <strong>{0}</strong> pieces?",
  you_cannot_order_so_many: "You cannot order <strong>{0}</strong> pieces at on time!",
  day_must_be_open: "Open the days first for this action!",
  ntak: {
    ntak: "NTAK",
    taxid: "Tax ID",
    ntak_id: "NTAK ID",
    save_ntak_id: "Save NTAK ID",
    cert_request_generate: "Generate new certificate request",
    cert_request_download: "Download certificate request",
    cert_upload: "Upload certificate",
    cert_request: "Certificate request (CSR)",
    test: "Test connection",
    cert: "Certificate (CER)",
    key: "Private key (KEY)",
    categories: "NTAK",
    status: "NTAK status",
    status_error: "NTAK error",
    default_ntak_main_category: "NTAK Main category",
    _default_ntak_main_category: "Main category",
    default_ntak_sub_category: "NTAK Subcategory",
    _default_ntak_sub_category: "Subcategory",
    mainCategoryNotSet: "The NTAK main category is not set for any product! It can be set at the category level (it will be inherited) or at the product level.",
    subCategoryNotSet: "The NTAK sub category is not set for any product! It can be set at the category level (it will be inherited) or at the product level.",

    main_categories: [
      {
        id: 5, name: "Food", shortName: "Food",
        sub_categories: [
          { id: 1, name: "Breakfast" },
          { id: 2, name: "Sandwich" },
          { id: 3, name: "Appetizer" },
          { id: 4, name: "Soup" },
          { id: 5, name: "Main course" },
          { id: 6, name: "Side dish" },
          { id: 7, name: "Pickles/salad", shortName: "Pickles/Salad" },
          { id: 8, name: "Dessert" },
          { id: 9, name: "Snack" },
          { id: 30, name: "Main course with side dish" },
          { id: 31, name: "Food package" },
          { id: 10, name: "Other" },
        ]
      },
      {
        id: 1, name: "Locally made non-alcoholic beverage", shortName: "Loc. bev.",
        sub_categories: [
          { id: 11, name: "Lemonade/syrup/freshly squeezed drink", shortName: "Lem/Syr/Fresh" },
          { id: 12, name: "Non-alcoholic cocktail", shortName: "Non-alc. cocktail" },
          { id: 13, name: "Tea/hot chocolate" },
          { id: 14, name: "Coffee" },
          { id: 32, name: "Beverage package" },
        ]
      },
      {
        id: 2, name: "Non-locally made non-alcoholic beverage", shortName: "Non-loc. bev.",
        sub_categories: [
          { id: 15, name: "Water" },
          { id: 16, name: "Carbonated soft drink" },
          { id: 17, name: "Non-carbonated soft drink" },
          { id: 33, name: "Non-carbonated soft drink" },
          { id: 32, name: "Beverage package" },
        ]
      },
      {
        id: 3, name: "Alcoholic beverage", shortName: "Alc. bev.",
        sub_categories: [
          { id: 18, name: "Cocktail" },
          { id: 19, name: "Liqueur" },
          { id: 20, name: "Spirit" },
          { id: 21, name: "Beer" },
          { id: 22, name: "Wine" },
          { id: 23, name: "Champagne" },
          { id: 32, name: "Beverage package" },
        ]
      },
      {
        id: 4, name: "Other", shortName: "Other",
        sub_categories: [
          { id: 10, name: "Other" },
          { id: 26, name: "Delivery fee" },
          { id: 27, name: "Environmentally friendly packaging", shortName: "Eco. pack." },
          { id: 28, name: "Plastic packaging", shortName: "Plas. pack." },
          { id: 29, name: "Discount" },
          { id: 34, name: "Non-catering" },
        ]
      },
    ]
  },
  more_addresses: "Other addresses",
  payment_received_by: "Payment received by",
  product_receipe_modified_recalculate_it: "Receipe changed. Would you like to recalculate the inventory for the past",
  minute: "minute",
  id: "Internal id",
  activate_sound: "Bell activated",
  barcode_not_found: "Unknown barcode!",
  linked_product: "Linked own product",
  new_in_central_database: "Available from global database",
  exists_in_central_database: "Exists in global database",
  doesnt_exists_in_central_database: "Does not exist in global database",
  local_value: "Local value",
  global_value: "Global value",
  tooltip: {
    "set_new_incoming_order": "New order awaiting confirmation!",
    "set_accepted_order": "Order confirmed! You can confirm the order again by double-clicking!",
    "set_order_in_production": "Order under preparation! You can set this status by double-clicking!",
    "set_order_ready": "Order ready! You can set this status by double-clicking!",
    "set_order_under_delivering": "Order under delivery! You can set this status by double-clicking!",
    "set_order_delivered": "Order delivered! You can set this status by double-clicking!",
    "header_new_incoming_order": "New order awaiting confirmation!",
    "header_accepted_order": "Order confirmed! You can reconfirm all pending or selected orders by double-clicking!",
    "header_order_in_production": "Order under preparation! You can set this status for all orders or selected ones by double-clicking!",
    "header_order_ready": "Order ready! You can set this status for all orders or selected ones by double-clicking!",
    "header_order_under_delivering": "Order under delivery! You can set this status for all orders or selected ones by double-clicking!",
    "header_order_delivered": "Order delivered! You can set this status for all orders or selected ones by double-clicking!",
    "header_filter_new_incoming_order": "Filter new orders",
    "header_filter_accepted_order": "Filter confirmed orders",
    "header_filter_order_in_production": "Filter order under preparation",
    "header_filter_order_ready": "Filters ready orders",
    "header_filter_order_assigned": "Orders assigned to courier",
    "header_filter_order_under_delivering": "Filter orders under delivery",
    "header_filter_order_delivered": "Filter orders delivered",
    "unlink_courier": "Double-click to remove the order from the courier!",
    "link_courier": "Double-click to assign the selected orders to this courier!",
    "header_print_receipt": "Double-click to print all yet unprinted orders or selected orders!",
    "header_print_invoice": "Double-click to print the invoices for all or selected orders!",
    "header_unlock": "Double-click to unlock or lock all unlockable (delivered and paid) orders!",
    "print_receipt": "Double-click to print the order!",
    "reprint_receipt": "Double-click to print the order again!",
    "print_invoice": "Double-click to print the invoice!",
    "unlock": "Double-click to unlock or lock the order!",
    "delivery_time": "Planned delivery time",
    "pickup_time": "Planned pickup time",
    "couriers": "Possible couriers (users with courier privileges). This permission can be granted to registered users in the administration.",
    "printing": "Printing in progress",
    "printfailed": "Unsuccessful printing attempt. Double-click to make a new printing attempt.",
    "printed": "Successful printing! Double-click to print a copy.",
    "unprinted": "The order has not been printed yet. Double-click to print.",
    "qrcode": "Double-click to print the courier's block.",
    "register_payment": "Double-click to register payment details.",
    "paid": "Invoice paid.",
    user_comment: "Set a permanent comment for your customer. This comment will show up for next orders as well!",
    comment: "Set a comment for this order only!",
    address: "Please provide the full address, e.g., 'Árpád köz 2.'. If the system cannot find the address, the system accepts any address with a '/' at the beginning, e.g., '/At the entrance of the train station'",
  },
  tablesoverview: {
    tooltip: {
      "printing": "Printing in progress",
      "printfailed": "Unsuccessful printing attempt. Please print again.",
      "printed": "Successfully printed.",
      "unprinted": "The order has not been printed yet."
    }
  },
  menuitems: {
    tooltip: {
      expected_profit: "Expected profit ratio",
      suggested_price: "Suggested price",
      unit_price: "Selling price for one unit for local consumption",
      is_inventory_item: "Cannot be selected as a packaging option during sale. This unit is the basis for inventory management. It can be received and inventoried in this unit.",
      non_inventory_item: "Cannot be selected as a packaging option during sale. Normal product quantity. Not an inventory unit.",
      on_menu_is_inventory_item: "Can be selected as a packaging option during sale. This unit is the basis for inventory management. It can be received and inventoried in this unit.",
      on_menu_non_inventory_item: "Can be selected as a packaging option during sale. Normal product quantity. Not an inventory unit.",
      def_is_inventory_item: "Default but cannot be selected as a packaging option during sale. This is not good. Select a default sellable packaging! This unit is the basis for inventory management. It can be received and inventoried in this unit.",
      def_non_inventory_item: "Default but cannot be selected as a packaging option during sale. This is not good. Select a default sellable packaging! Normal product quantity. Not an inventory unit.",
      def_on_menu_is_inventory_item: "Default and can be selected as a packaging option during sale. This unit is the basis for inventory management. It can be received and inventoried in this unit.",
      def_on_menu_non_inventory_item: "Default and can be selected as a packaging option during sale. Normal product quantity. Not an inventory unit.",
      unit_cost: "Net purchase price",
      profit_ratio: "Profit margin",
      on_menu: "Main product, directly orderable",
      not_on_menu: "Not a main product, not directly orderable, only as an option for other products",
      active: "Active product",
      deactivated: "Deactivated product",
      online: "Product visible in the online store",
      not_online: "Product not visible in the online store",
      available_online: "Product available for order online",
      not_available_online: "Product currently not available for order online",
      default_inventory_item: "Inventory-managed product (raw material). Inherits this property from its category.",
      default_not_inventory_item: "Non-inventory-managed product. Inherits this property from its category.",
      inventory_item: "Inventory-managed product (raw material)",
      not_inventory_item: "Non-inventory-managed product (raw material)",
      not_free_quantity: "During ordering, only predefined packaging options (quantities) can be selected.",
      free_quantity: "During ordering, the sold quantity can be freely modified (e.g., necessary for weighing goods).",

      sold_inventory: "Sold inventory-managed finished product, meaning the sold product is also an inventoried raw material.",
      sold_inventory_ingredient: "Sold inventory-managed finished product, meaning the sold product is also an inventoried raw material and ingredient of another product.",
      sold_inventory_ingredient_option: "Sold inventory-managed finished product, meaning the sold product is also an inventoried raw material and ingredient of another product, as well as an option.",
      sold_inventory_option: "Sold inventory-managed finished product, meaning the sold product is also an inventoried raw material and option of another product.",
      sold_service: "Sold service, where the product is not inventoried (not a raw material) and does not contain other products (empty recipe), making it a service.",
      sold_service_ingredient: "Sold service sub-product, where the product is not inventoried (not a raw material) and does not contain other products (empty recipe), making it a service, but it is referenced in the recipe of another product.",
      sold_service_ingredient_option: "sold service ingredient option, meaning the product itself is not stocked (not an ingredient) and does not contain any other products (empty recipe), so it is a service, and other products reference it in their recipe as well as an option",
      sold_service_option: "sold service option, meaning the product itself is not stocked (not an ingredient) and does not contain any other products (empty recipe), so it is a service, which is referenced by other products as an option",
      sold_complex_product: "sold complex product, which consists of other products",
      sold_complex_product_option: "sold complex product, which consists of other products, as well as an option for another product",
      sold_complex_product_ingredient: "complex subproduct sold, composed of other productsl",
      sold_linked_product: "sold linked product",
      sold_linked_product_ingredient: "sold linked product, ingredient",
      sold_linked_product_ingredient_option: "sold referenced product, subproduct, options, i.e., it only refers to one product",
      sold_linked_product_option: "sold referenced product, options, i.e., it only refers to one product",

      _complex_product: "Not directly sold complex product, which consists of other products",
      _complex_product_option: "Not directly sold complex product, which consists of other products, as well as an option for another product",
      _complex_product_ingredient: "Not directly sold complex product, which consists of other products, and is referenced by another product in its recipe",
      _complex_product_ingredient_option: "Not directly sold complex product, which consists of other products, and is referenced by another product in its recipe as well as an option",
      _linked_product: "Not directly sold referenced (priced differently, special quantity, or just a copy of another product) product",
      _linked_product_option: "Not directly sold referenced (priced differently, special quantity, or just a copy of another product) product, as well as an option for another product",
      _linked_product_ingredient: "Not directly sold referenced (priced differently, special quantity, or just a copy of another product) product, and is included in another product's recipe",
      _linked_product_ingredient_option: "Not directly sold referenced (priced differently, special quantity, or just a copy of another product) product, and is referenced by another product in its recipe as well as an option",
      _service_ingredient: "Not directly sold service, which is referenced by another product in its recipe",
      _service_ingredient_option: "Not directly sold service, which is referenced by another product in its recipe as well as an option",
      _service__option: "Not directly sold service, which is referenced by another product as an option",
      _inventory_ingredient: "Not directly sold stocked ingredient, which is referenced by another product in its recipe",
      _inventory_ingredient_option: "Not directly sold stocked ingredient, which is referenced by another product in its recipe as well as an option",
      _inventory_option: "Not directly sold stocked ingredient, which is referenced by another product as an option",
      _inventory: "Stocked ingredient not used/referenced by other products",
      _service: "Not sold service, which is not referenced by any other product as a recipe or option",
      no_inventory_quantity_selected: "The product is a stocked inventory item, but no inventory quantity is selected.",
      to_many_inventory_quantity_selected: "The product has multiple inventory quantities, so the system cannot determine which one to reduce inventory from. Please select only one inventory quantity."
    },
    "sold_inventory": "sold stocked product",
    "sold_inventory_ingredient": "sold stocked product ingredient",
    "sold_inventory_ingredient_option": "sold stocked product ingredient option",
    "sold_inventory_option": "sold stocked product option",
    "sold_service": "sold service",
    "sold_service_ingredient": "sold service sub-product",
    "sold_service_ingredient_option": "sold service sub-product and option",
    "sold_service_option": "sold service option",
    "sold_linked_product": "sold linked product",
    "sold_linked_product_ingredient": "sold referenced stocked product ingredient",
    "sold_linked_product_ingredient_option": "sold referenced stocked product ingredient option",
    "sold_complex_product": "sold complex product",
    "sold_complex_product_option": "sold complex product and option",
    "sold_complex_product_ingredient": "sold complex sub-product",
    "sold_complex_product_ingredient_option": "sold complex sub-product and option",
    "_complex_product": "not sold complex product",
    "_complex_product_option": "not sold complex product and option",
    "_complex_product_ingredient": "not sold complex product sub-product",
    "_complex_product_ingredient_option": "not sold complex product sub-product and option",
    "_linked_product": "not sold referenced product",
    "_linked_product_option": "not sold referenced product and option",
    "_linked_product_ingredient": "not sold referenced product sub-product",
    "_linked_product_ingredient_option": "not sold referenced product sub-product and option",
    "_service_ingredient": "not sold service sub-product",
    "_service_ingredient_option": "not sold service sub-product and option",
    "_service_option": "not sold service option",
    "_inventory": "not sold stocked unused ingredient",
    "_inventory_ingredient": "not sold stocked ingredient",
    "_inventory_ingredient_option": "not sold stocked ingredient and option",
    "_inventory_option": "not sold stocked option",
    "_service": "not sold service"
  },
  _settings: {
    "hu": "Hungarian",
    "en": "English",
    "states": ["deleted", "selected", "ordered", "confirmed", "in progress", "ready", "delivery", "delivered"],
    "day": "day",
    "fromday": "from date",
    "today": "to date",
    "minute": "minute",
    "days": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "date"],
    "from_hour": "from hour",
    "to_hour": "to hour",
    "email": "email address",
    "payment_method": {
      "title": "Available Payment Methods",
      "meth": {
        "title": "Method"
      },
      "term": {
        "title": "Payment Terms",
        "description": "Payment terms for webshop"
      }
    },
    "description_header": {
      "title": "Webshop Header Content"
    },
    "description_footer": {
      "title": "Webshop Footer Content"
    },
    "business_day_starts_at": {
      "title": "Business Day Start",
      "description": "In hours"
    },
    "business_day_ends_at": {
      "title": "Business Day End",
      "description": "In hours, may be less than the start, e.g., 5 AM when the business day ends after midnight"
    },
    "service_fee": {
      "title": "Service Charge",
      "description": "e.g., 12 if a 12% service charge is typically applied. The service charge's application and rate can be set or adjusted on invoice printing."
    },
    "timezone": {
      "title": "Timezone"
    },
    "compulsory_service_fee": {
      "title": "Mandatory Service Charge",
      "description": "Automatic service charge application for non-cash register tables, adjustable before invoice printing."
    },
    "compulsory_service_fee_for_pos": {
      "title": "Mandatory Service Charge for Cash Register",
      "description": "Automatic service charge application for cash register tables."
    },
    "serviceVat": {
      "title": "Separated Service Charge",
      "description": "Separate service charges by VAT categories on invoice/receipt."
    },
    "register_tip": {
      "title": "Register Tip",
      "description": "Register the difference as a tip if the amount paid exceeds the amount due."
    },
    "printing": {
      "title": "Printing Settings",
      "invoice": {
        "title": "Invoice Layout"
      },
      "showOrderNumber": {
        "title": "Show Order Number on Normal Tables",
        "description": "Default for cash orders."
      },
      "showAllComments": {
        "title": "Show All Comments on Invoice",
        "description": "By default, only cost-modifying comments are visible."
      }
    },
    "drinks": {
      "title": "Settings: Drinks",
      "enabled": {
        "title": "Enabled"
      },
      "default": {
        "title": "Default",
        "description": "Default display of drinks or meals on ordering by waiter and webshop."
      },
      "label": {
        "title": "Label",
        "description": "Label to display on webshop."
      }
    },
    "meals": {
      "title": "Settings: Meals",
      "enabled": {
        "title": "Enabled"
      },
      "default": {
        "title": "Default",
        "description": "Default display of drinks or meals on ordering by waiter and webshop."
      },
      "label": {
        "title": "Label",
        "description": "Label to display on webshop."
      }
    },
    "menu": {
      "title": "Ordering Interface, Product Selector",
      "show_category_image": {
        "title": "Show Images for Product Categories"
      },
      "show_menuitem_image": {
        "title": "Show Images for Products"
      }
    },
    "takeaway": {
      "title": "Takeaway",
      "enabled": {
        "title": "Enabled"
      },
      "default": {
        "title": "Default",
        "description": "Enable automatic takeaway for cash registers"
      },
      "vat": {
        "title": "Default VAT for Takeaway"
      },
      "price": {
        "title": "Separate Takeaway Price",
        "description": "Option to specify an extra takeaway price on the product page."
      },
      "containerChargeItem": {
        "title": "Deposit Product Identifier"
      },
      "packageChargeItem": {
        "title": "Packaging Product Identifier"
      },
      systemFeeItem: {
        title: "System usage fee Product Identifier",
      },
    },
    "timings": {
      "title": "Courses",
      "enabled": {
        "title": "Enabled"
      },
      "enabled_online": {
        "title": "Enabled for Onsite Orders on Webshop"
      },
      "langs": {
        "title": "Language Labels",
        "lang": {
          "title": "Language"
        },
        "name": {
          "title": "Label"
        },
        "name_online": {
          "title": "Label on Webshop"
        }
      }
    },
    "price_calculations": {
      "title": "Price Calculation Logic",
      "rounding": {
        "title": "Rounding",
        "description": "e.g., 1 -> rounds to nearest whole number, 50 -> rounds to 50 or 100."
      },
      "lower_price": {
        "title": "Price Reduction Logic",
        "description": "Default components/options can be removed/disabled or replaced when ordering.",
        "above": {
          "title": "Price Reduction If",
          "description": "Price decreases if the new price is lower than the original price by a specified percentage or amount. e.g., 100% means any reduction will apply."
        },
        "measure": {
          "title": "Measure Type"
        }
      },
      "increase_price": {
        "title": "Price Increase Logic",
        "above": {
          "title": "Price Increase If",
          "description": "Price increases if the new price is higher than the original price by a specified percentage or amount. e.g., 0% means any increase will apply."
        },
        "measure": {
          "title": "Measure Type"
        }
      }
    },
    enabled_features: {
      homedelivery: {
        zip: {
          title: "Zip code",
          description: "Which zip codes does the system accept (regexp)"
        },
        dayends: {
          title: "Day cutoff",
          description: "Until what time can orders be placed for the same day in the webshop"
        },
        timeahead: {
          title: "Delay",
          description: "For manual or webshop orders, how many hours in advance do you guarantee delivery"
        },
        "min-duration": {
          title: "Delivery time slot duration",
          description: "How long is the delivery time slot in hours"
        },
        homedelivery_menuitem: {
          title: "Product ID for home delivery fee",
          description: "When applying a zip code-based home delivery fee, which product does the system use. If such a product does not exist yet, create a new product named 'Home Delivery Fee', save it, reopen it for editing, note down its ID, and enter it here."
        },
        phone_order_fee: {
          title: "System useage fee",
          description: "Rendszerhasználati díj telefonos rendelés esetén",
        },
        webshop_order_fee: {
          title: "System useage fee",
          description: "System usage fee for webshop orders",
        },
        instantOrderOnly: {
          title: "Instant orders only",
          description: "Only accept instant orders in the webshop"
        },
        copy_order_email: {
          title: "Email notification",
          description: "Who should receive email notifications about webshop orders and status changes"
        },
        send_email_on: {
          title: "Send email on status",
          description: "When webshop orders reach which status, send email notifications to the addresses above"
        },
        price: {
          title: "Delivery fee",
          description: "You have the option to automatically charge a delivery fee based on zip code and order value",
          name: "Name",
          zip: "Zip code",
          courierFee: {
            title: "Címpénz",
            description: "Courier fee for home delivery"
          },
          above_amount: "Above amount",
          price: "Delivery fee",
          except: {
            title: "Except",
            description: "Except for products with label"
          },
          only: {
            title: "Only",
            description: "Only for products with label"
          },
          area: {
            title: "Map",
            description: "Draw the delivery region on the map"
          }
        },
        orderedStatus: {
          title: "Status after order",
          description: "Initial status of items ordered on the webshop"
        },
        "available-timeslots": {
          title: "Delivery time slots",
          description: "These time slots are available for delivery through the webshop"
        },
        "terms-and-conditions": {
          title: "General terms and conditions",
          description: "Inform your customers about the home delivery conditions when placing webshop orders"
        },
        delays: {
          title: "Estimated delivery time",
          description: "Available durations for confirmed preparation/delivery in minutes for Wolt/Foodpanda"
        },
        blockWaiterClose: {
          title: "Block closure to waiters",
          description: "Only administrators can close a homedelivery."
        },
        printInvoiceOnOrder: {
          title: "Automatic invoice printing",
          description: "After new orders is submited automatically print the invoice as well."
        },
        allowed_installments: {
          title: "Payment installment options",
          description: "Payment installment options for menu orders",
          start: "Prepayment",
          by_each: "Daily payment",
          end: "Payment at the end"
        },
        user_allowed_installments: {
          title: "Payment installment options in the webshop",
          description: "Payment installment options for menu orders in the webshop",
          start: "Prepayment",
          by_each: "Daily payment",
          end: "Payment at the end"
        },
        "table-number": {
          title: "Table number",
          description: "The table number for handling home delivery orders"
        }
      },
      pickup: {
        dayends: {
          title: "Day cutoff",
          description: "Until what time can orders be placed for the same day in the webshop"
        },
        timeahead: {
          title: "Delay",
          description: "For manual or webshop orders, how many hours in advance do you guarantee order preparation"
        },
        "min-duration": {
          title: "Pickup time slot duration",
          description: "How long is the pickup time slot in hours"
        },
        instantOrderOnly: {
          title: "Instant orders only",
          description: "Only accept instant orders in the webshop"
        },
        phone_order_fee: {
          title: "System useage fee",
          description: "Rendszerhasználati díj telefonos rendelés esetén",
        },
        webshop_order_fee: {
          title: "System useage fee",
          description: "System usage fee for webshop orders",
        },
        copy_order_email: {
          title: "Email notification",
          description: "Who should receive email notifications about webshop orders and status changes"
        },
        send_email_on: {
          title: "Send email on status",
          description: "When webshop orders reach which status, send email notifications to the addresses above"
        },
        orderedStatus: {
          title: "Status after order",
          description: "Initial status of items ordered on the webshop"
        },
        "available-timeslots": {
          title: "Pickup time slots",
          description: "These time slots are available for pickup orders through the webshop"
        },
        "terms-and-conditions": {
          title: "General terms and conditions",
          description: "Inform your customers about the pickup order conditions when placing webshop orders"
        },
        delays: {
          title: "Estimated pickup time",
          description: "Available durations for confirmed preparation for Wolt/Foodpanda in minutes"
        },
        allowed_installments: {
          title: "Payment installment options",
          description: "Payment installment options for menu orders",
          start: "Prepayment",
          by_each: "Daily payment",
          end: "Payment at the end"
        },
        user_allowed_installments: {
          title: "Payment installment options in the webshop",
          description: "Payment installment options for menu orders in the webshop",
          start: "Prepayment",
          by_each: "Daily payment",
          end: "Payment at the end"
        },
        "table-number": {
          title: "Table number",
          description: "The table number for handling pickup orders"
        }
      },
      prebooking: {
        defaultDuration: {
          title: "Default Duration",
          description: "Default duration (minutes) when making a reservation"
        },
        bookingDelay: {
          title: "Booking lead time",
          description: "Booking can be made only this amount of minutes after now"
        },
        showBefore: {
          title: "Show This Many Minutes Before",
          description: "Show reservations on the floor plan this many minutes before"
        },
        maxLateDuration: {
          title: "Allowed Lateness",
          description: "Allowed grace period (in minutes) for guest arrival before the reservation is deleted"
        },
        minBookingMinutes: {
          title: "Minimum Duration",
          description: "Minimum duration (in minutes) for reservations on the web"
        },
        maxBookingMinutes: {
          title: "Maximum Duration",
          description: "Maximum duration (in minutes) for reservations on the web"
        },
        defaultBookingMinutes: {
          title: "Default Duration on Webshop",
          description: "Default duration (minutes) when making a reservation on the web"
        },
        slotMinutes: {
          title: "Durations",
          description: "These durations can be chosen on the web"
        },
        seats: {
          title: "Number of Persons",
          description: "These numbers of persons can be chosen on the web"
        },
        openingHours: {
          title: "Opening Hours",
          description: "Opening hours on the web",
          slots: {
            title: "Time Slot",
            description: "Time slot for opening hours (e.g., from 10:00 to 14:00)"
          }
        }
      }
    },
    "tables-overview": {
      autolock: {
        title: "Automatic screen lock",
        description: "After a set time, the screen locks and can only be unlocked with a passcode or password."
      },
      timeout: {
        title: "Lock delay",
        description: "After how many seconds should the screen lock"
      },
      "hideMenuCategoryImage": {
        title: "Hide category pictures",
        description: "Hide category pictures from ordering screen"
      },
      "hideMenuItemImage": {
        title: "Hide product pictures",
        description: "Hide protduct pictures from ordering screen"
      },
      bigOrderNumber: {
        title: "Large order number",
        description: "Print a larger order number on the kitchen receipt than the default setting"
      },
      perPersonKitchenReceipt: {
        title: "Per-person separated kitchen receipt",
        description: "For example, when each person's order needs to be served on a separate tray"
      },
      "bigAdditions": {
        title: "Bigger font size on kitchen receipt",
        description: "Bigger +/- lines on kitchen receipt"
      },
      autoDeliverOnUnlock: {
        title: "Automatic delivery mode",
        description: "After freeing up the table, any non-deleted items are automatically set to 'delivered' status and deducted from inventory"
      },
      unlockOnPayment: {
        title: "Automatic release",
        description: "Automatic release of the table upon payment if there are no more unpaid items"
      },
      lessKioskButtons: {
        title: "Fewer buttons in cash register mode",
        description: "Display the fewest possible buttons in cash register mode"
      },
      print_accepted_customer_orders: {
        title: "Automatic printing upon confirmation",
        description: "Automatically print webshop orders upon confirmation"
      },
      "delayed_orders_browser": {
        "title": "Delayed Order Submission from Browser",
        "description": "Orders are sent to the server only upon confirmation."
      },
      "delayed_orders_mobile": {
        "title": "Delayed Order Submission from Mobile App",
        "description": "Orders are sent to the server only upon confirmation."
      },
      "pos_delayed_orders_browser": {
        "title": "Delayed Order Submission from Browser in POS Mode",
        "description": "Orders are sent to the server only upon confirmation."
      },
      "pos_delayed_orders_mobile": {
        "title": "Delayed Order Submission from Mobile App in POS Mode",
        "description": "Orders are sent to the server only upon confirmation."
      },
      always_allow_delete: {
        title: "Allow deletion",
        description: "Allow deletion even for waiter permissions"
      },
      block_waiter_print_invoice: {
        title: "Block printing of invoice",
        description: "Block printing of invoice by waiters"
      },
      block_waiter_register_payment: {
        title: "Block payment registration",
        description: "Block payment registration by waiters"
      },
      block_waiter_print_daily_closing: {
        title: "Prohibition of fast traffic data",
        description: "Prohibition of querying current traffic data for waiters",
      },
      waiter_print_daily_closing_full: {
        title: "Full fast traffic data",
        description: "Complete current traffic data for waiters",
      },
      drinkOrderStatus: {
        title: "Drink order status on ordering",
        description: ""
      },
      mealOrderStatus: {
        title: "Meal order status on ordering",
        description: ""
      },
      "table-qrcode": {
        title: "Functionality of the printed QR code for the table",
        description: "",
        enumNames: ["None", "Payment only", "Connection"]
      },
      "auto-table-qrcode": {
        title: "Print QR-code autmatically",
        description: "With the printing of the first orders print the qr-code as well",
      },
      "notify-waiter-when-part-ready": {
        title: "Notify waiter when course part ready",
        description: "When a course is prepared on multiple production lines notify the waiter even when not all production lines are ready with their part",
      },
      "notify-customer-when-part-ready": {
        title: "Notify customer when course part ready",
        description: "When a course is prepared on multiple production lines notify the customer even when not all production lines are ready with their part",
      },
      states: {
        title: "Filter states",
        description: "Which order states to consider"
      },
      statesShown: {
        title: "States shown",
        description: "Which statuses (columns) to show when opening a table"
      },
      newOrdersStatesShown: {
        title: "States shown on order entry",
        description: "Which statuses (columns) to show for new order entries"
      },
      newOrdersKioskStatesShown: {
        title: "States shown in cash register mode",
        description: "Which statuses (columns) to show for tables in cash register mode"
      }
    }
  },
  receipts: "Receipts",
  receipt: "Receipt",
  save_update_address: "Save and update address",
  sort_abc: "Sort",
  hellopay: {
    "error": {
      "1": "Unknown or other error",
      "2": "Timeout or interruption, card not found",
      "3": "Invalid or faulty card",
      "4": "Insufficient balance on the card",
      "5": "Wrong card inserted",
      "6": "Non-reversible because it's not the last transaction",
      "7": "Not a HelloPay card",
      "8": "Card communication error",
      "9": "Tablet communication error",
      "10": "User is in the menu",
      "11": "Failed external_identifier validation",
      "12": "Failed transaction loading",
      "13": "Insufficient permissions",
      "14": "Terminal is not in the correct state (controlled by OTP/Unipass)"
    }
  },
  saltplay: {
    error: {
      "CANCELLING": "Cancelling card payment in progress",
      "CANCELLED": "Credit card payment cancelled",
      "FAILED": "Failed card payment",
      "NEW": "Failed card payment. No response from terminal.",
    }
  },
  reprint_receipt: "Do you really want to reprint the receipt?",
  cashier_open_amount: "Opening cash",
  cashier_dayopen: "Open day",
  cashier_dayclose: "Close day",
  storno: "Storno",
  stornoreason_1: "Customer cancellation",
  stornoreason_2: "Incorrect input",
  stornoreason_3: "Incorrect payment method input",
  stornoreason_4: "Product out of stock",
  stornoreason_5: "Incorrect document type issuance",
  stornoreason_6: "Failed payment method usage",
  stornoreason_7: "Incorrect customer data/incorrect input",
  stornoreason_8: "Test purchase",
  stornoreason_0: "Other",
  ap: "AP Number",
  receiptNumber: "Receipt number",
  customerTaxNum: "Customer tax number",
  customerName: "Customer name",
  customerPostCode: "Customer postal code",
  customerTown: "Customer city",
  customerAddress: "Customer address",
  dont_print: "Don't print",
  history: "History",
  action_not_allowed: "You have no permission to perform this action",
  already_paid_reprint_invoice: "Bill already settled! Do you really want to reprint the invoice?",
  documentation: "Documentation",
  "user_comment": "Other guest information",
  "booking_comment": "Note",
  channels: {
    console: "Console",
    webshop_homedelivery: "Webshop",
    webshop_pickup: "Webshop",
    webshop_onsite: "Webshop",
    wolt: "Wolt",
    foodora: "Foodora",
    falatozz: "Falatozz",
    console_homedelivery: "",
    console_pickup: "",
  },
  auto_translate: "Translate",
  webshopOrder: "Webshop order",
  webshop: "Webshop",
  authenticate: "Authenticate",
  reauthenticate: "Reauthenticate",
  store: "Store",
  terminal: "Terminal",
  choose_terminal_to_use: "Choose bank terminal",
  restart_printerserver: "Reconnecting the printers",
  reboot_printerserver: "Reboot the local printer server",
  restart_printer: "Please restart the following printer: {0}",
  restart_needed: "(Re)start the printer",
  "External HealthCheck: Successful": "Printer seems ok",
  "Internal HealthCheck: Successful": "Printer seems ok",
  charwidth: "Character width",
  the_table_is_not_occupied: "This table is free!",
  do_you_want_to_catch_incoming_calls: "Would you like us to use call identification for incoming calls on this phone for home delivery/pickup data recording? If yes, please log in with the same user in the browser!",
  upload_successful: "Upload successful",
  pointout: "Point accumulation",
  pointin: "Point redemption",
  if: "Condition",
  waiterOrder: "Waiter order",
  customerOrder: "Consumer order (webshop)",
  localConsumption: "Local consumption",
  takeawayConsumption: "Takeaway consumption",
  default_cashregister_change: "<strong>{0}</strong> should be the default cash register on this machine?",
  container_charge: "Container charge",
  label_count: "Label count",
  tooltip_label_count: "Number of label to be printed for this product in case label printer is in use",
  package_charge: "Package charge",
  action_failed: "Unsuccessful operation. It's possible that something isn't up-to-date; let's synchronize and then try the operation again!",
  container_charge_onsite: "Also in case of local consumption",
  id: "Id",
  product_search: "Product search",
  reprint: "Print",

  print_qrcode: "Print QR Code",
  minimum_price: "The final price cannot be less than the base price, even if default options are turned off at the time of ordering. For example, with pizzas, even if a topping is removed, the pizza should not become cheaper.",
  stockAnalitics: "Stock analitics",
  productAnalitics: "Product analitics",
  cartsize: "Cart size",
  customer_orders: "New customer orders",
  deliverable_tables: "Meals ready for tables",
  assistance_needed: "Waiter called",
  fiscal_receipt_already_printed: "This fiscal receipt has already been printed (receipt no.: {0})",
  multiplicator: "Multiplicator",
  _multiplicator: "Mult.",

  invoice_count: "Original quantity",
  auto_import_incoming_stocks: "Aut. intake",
  auto_commit_imcoming_stocks: "Aut. commit",
  invoice_not_found: "Invoice number not found in the online számla system",
  online_szamla_feature: "Online Invoice module is currently not activated in your subscription. The module can download VAT invoices received in the Online Invoice system and automatically/semi-automatically record them as needed.",

  receipe: "Receipe",
  mandatory_recipe: "Ingredients that the product must contain. For example, in the case of pizza, the pizza base.",
  included_recipe: "Ingredients that are included in the product but can be removed or substituted when ordering. For example, in a cappuccino, milk can be replaced with lactose-free milk.",
  optional_recipe: "Additional options that can either enhance the product or replace a default ingredient when ordering.",
  auto_import_incoming_stocks_tooltip: "Generate stock entries automatically based on incoming invoices from this supplier.",
  auto_commit_incoming_stocks_tooltip: "Generate automatic and final (non-draft) stock entries based on incoming invoices from this supplier, instantly updating the inventory.",
  no_permission_to_perform_this_action: "You do not have permission to perform this operation.",

  events: "Events",
  lastSale: "Last Usage",
  lastBuy: "Last Purchase",
  lastInventory: "Last Inventory",
  nextInventory: "Next Planned Inventory",
  criticalStock: "Critical quantity that jeopardizes business operations",
  minimumStock: "Minimum quantity when we typically restock",
  refillStock: "Target quantity to which we typically restock",
  below_criticalStock: "Product below critical quantity, endangering business operations",
  below_minimumStock: "Product below minimum quantity, when we typically restock",
  daysAgo: "{0} days ago",
  inDays: "In {0} days",
  monthsAgo: "{0} months ago",
  inMonths: "In {0} months",
  yearsAgo: "{0} years ago",
  inYears: "In {0} years",

  new: "new",
  refresh: "Refresh",
  differences_in_additions: "Difference in recipe options",
  differences_in_name: "Difference in naming",
  differences_in_availableQuantities: "Difference in available quantities",
  differences_in_unitPrice: "Difference in unit price",
  pickupyourorder: "Your order is ready",
  add_user: "New user",
  add_role: "New role",
  courier_off: "Off work",
  courier_ready: "Waiting for delivery",
  courier_pause: "Pause",
  courier_delivering: "Delivering",
  courier_delivery_continue: "Continue delivery",
  courier_delivery_pause: "Pause delivery",
  package: "package",
  delivery_count: "Delivery Count",
  delivery_addresses: "Address Count",
  delivery_distance: "Distance (Km)",
  delivery_avg_time: "Average Time",
  confirm_position_update: "Refresh GPS coordinates for address",
  refresh_position: "Refresh",
  distance_to_big: "{0}km away from the address set for this location! Are you really at this address now? If so, shall we update the address GPS coordinates based on your current position?",
  delivered_refresh_position: "Delivered with GPS update",
  getting_location: "Determining your current location...",
  phone_order_fee: "System usage fee for phone orders",
  webshop_order_fee: "System usage fee for webshop orders",
  order_fee: "System usage fee",
  enter_phone_number: "Enter phone number",
  not_selected: "Not selected",
  "voipphone-Registration failed:Authentication Error": "Registration failed:Authentication Error",
  "voipphone-undefined": "Offline",
  do_you_really_delete_the_customer: "Do you really want to delete this customer?",
  new_position: "No GPS coordinates were set for this address. Do you want to set it to your current location?",
  exit_rearrange_mode: "Exit rearrange mode",
  rearrange_fields: "Rearrange fields",
  reset_to_defaults: "Reset to default",
  unassigned_orders: "Unassigned orders",
  assign_to_me: "Assign to me",
  local_consumption: "Local consumption",
  takeaway_consumption: "Takeaway consumption",
  barcode_found_but_no_menuitem: "Barcode found, but no local product is connected in the system.",
  courierFee: "Courier fee",
  "cashin": {
    "title": "Cash Deposit",
    "reason": "Reason for Cash Deposit",
    "fiscat": {
      "01": "Cash Float Deposit",
      "02": "Cashier Cash Withdrawal",
      "03": "Fee Collection",
      "04": "Lottery Ticket Sale",
      "05": "Advance Payment",
      "06": "Cash Register Shortage",
      "07": "Tip",
      "08": "Other Deposit"
    },
    "a234": {
      "0": "Cash Float Deposit",
      "1": "Cashier Cash Withdrawal",
      "2": "Fee Collection",
      "3": "Lottery Ticket Sale",
      "4": "Advance Payment",
      "5": "Cash Register Shortage",
      "6": "Tip",
      "7": "Other Deposit"
    }
  },
  "cashout": {
    "title": "Cash Payout",
    reason: "Reason for Cash Payout",
    "fiscat": {
      "31": "Skim",
      "32": "Cashier Payout",
      "33": "Voucher Payout",
      "34": "Gift Card Payout",
      "35": "Salary Payout",
      "36": "Wage Advance",
      "37": "Postage Cost",
      "38": "Other Expense",
      "39": "Goods Purchase",
      "40": "Total Payout",
      "41": "Other Payout"
    },
    "a234": {
      "0": "Skim",
      "1": "Cashier Payout",
      "2": "Voucher Payout",
      "3": "Gift Card Payout",
      "4": "Salary Payout",
      "5": "Wage Advance",
      "6": "Postage Cost",
      "7": "Other Expense",
      "8": "Goods Purchase",
      "9": "Total Payout",
      "10": "Other Payout"
    }
  },
  "Enable Report Scheduling": "Enable Report Scheduling",
  "reload_console": "Reload console",
};
export default admin_local_en;

