import { Component } from 'react';
import { withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import { StarHalfOutlined } from '@material-ui/icons';

class CenterTransition extends Component {

    state = {
        shift: 0
    }

    componentDidUpdate(prevProps) {
        const { map } = this.props.leaflet;
        const { shift, newCenter, duration = 1.0, zoom = null, fly = false } = this.props;
        const oldShift = this.state.shift;

        var newCenter2 = [...newCenter];

        newCenter2[1] = newCenter2[1] + oldShift - shift;


        //if (newCenter2 !== prevProps.newCenter) {
        if (fly && map._loaded) {
            const currentCenter = map.getCenter();
            const distance = currentCenter.distanceTo(L.latLng(newCenter2)); // Distance in meters
            if (distance > 10) {
                // Use flyTo for a smooth zoom-out-and-in effect
                map.flyTo(newCenter2, zoom ?? map.getZoom(), {
                    animate: true,
                    duration: duration,
                });
            }
        } else {
            // Use setView for a simple pan transition
            if (map._loaded) {
                const currentCenter = map.getCenter();
                const distance = currentCenter.distanceTo(L.latLng(newCenter2)); // Distance in meters
                if (distance > 10) {
                    map.setView(newCenter2, zoom ?? map.getZoom(), {
                        animate: true,
                        duration: duration,
                    });
                }
            } else {
                map.setView(newCenter2, zoom ?? map.getZoom(), {
                    animate: true,
                    duration: duration,
                });
            }
        }
        if (shift !== oldShift) {
            this.setState({ shift: shift })
        }
        //}
    }

    render() {
        return null; // No rendering, just side-effects
    }
}

export default withLeaflet(CenterTransition);
