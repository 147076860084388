import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import auth, { featureEnabled, messageDialog, startpoll } from "../../js/auth";
import { I18n } from "react-redux-i18n";
import { Map as MapContainer, TileLayer, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import admin from "../../js/admin";
import moment from "moment";

class MainMenu extends AbstractMenu {
  state = {};

  componentDidMount = () => {
    if (this.props.myStatus.sessionId) startpoll(auth.serverSideMessageHandler, [{ Restaurant: sessionStorage.restaurantSelected }]);
  };

  ntakCheck = () => {
    if (!featureEnabled("ntak"))
      return;
    const { local, admin_local, myStatus, features } = this.props;
    if (this.props.myStatus.roles && !this.state.warningShown) {
      const ntakConnectionOK = this.props.myStatus?.ntakstatus?.status?.connectionOK;
      this.setState({ warningShown: true });
      admin.getActiveMenuItems(data => {
        function checkNtak(category) {
          if (category.activeMenuItems.find(m => m.isTop && (!m.defaultMainCategoryId || !m.defaultSubCategoryId))) return true;
          if (category.activeMenuCategories.filter(m => m.isActive && m.isTop).find(checkNtak)) return true;
        }
        const drinkproblem = data.activeMenuCategories.filter(m => m.isActive && m.isTop && m.entityType === "DrinkCategory").find(checkNtak);
        const mealproblem = data.activeMenuCategories.filter(m => m.isActive && m.isTop && m.entityType === "MealCategory").find(checkNtak);
        this.setState({ ntakProblem: !ntakConnectionOK, mealNtakProblem: mealproblem, drinkNtakProblem: drinkproblem });
        if (!ntakConnectionOK || mealproblem || drinkproblem)
          if (!this.props.myStatus.test_mode)
            messageDialog(local.warning_message, local.please_perform_ntak_registration);
      })
    }
  }
  componentDidUpdate = () => {
    if (this.props.myStatus.sessionId) startpoll(auth.serverSideMessageHandler, [{ Restaurant: sessionStorage.restaurantSelected }]);
    this.ntakCheck();
  };

  render() {
    const { admin_local, myStatus, features } = this.props;
    const background = "#847556";
    const superAdminBackground = "red";
    if (admin_local && myStatus.roles) {
      const ntakProblem = this.state.ntakProblem || this.state.drinkNtakProblem || this.state.mealNtakProblem;
      return (
        <React.Fragment>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 1, overflow: "visible" }}>
            {(this.props.activeRestaurant && this.props.activeRestaurant !== 0) ? (
              <React.Fragment>
                <MenuButton myStatus={myStatus} roles={["superadmin"]} id="restaurants" href={"superAdminMenus"} background={superAdminBackground} name={admin_local.menus.superAdminMenu} />
                <MenuButton
                  myStatus={myStatus}
                  roles={["superadmin", "admin", "waiter"]}
                  href="/tablesOverview"
                  i="i_floor"
                  id="table-overview"
                  data-step="1"
                  data-intro="This is a tooltip"
                  background={background}
                  backgroundImage={require("../../img/tables-overview.png")}
                  name={admin_local.menus.tableOverview}
                />
                {this.props.pointOfSales.length > 0 ?
                  <MenuButton
                    myStatus={myStatus}
                    roles={["superadmin", "admin", "waiter"]}
                    id="restaurants"
                    href={"pointOfSales"}
                    i="i_cash_register"
                    background={background}
                    backgroundImage={require("../../img/point-of-sale.jpg")}
                    name={admin_local.menus.pointOfSales}
                  /> : null}
                {featureEnabled('ntak') && moment(auth.myStatus.restaurant_settings["enabled-features"]?.ntak?.startdate).isBefore(moment()) ?
                  <MenuButton
                    href="/opening_hours"
                    roles={["superadmin", "admin"]}
                    myStatus={this.props.myStatus}
                    i="i_system_parameter"
                    background={background}
                    backgroundImage={require("../../img/administration.jpg")}
                    name={I18n.t("admin_local.menus.opening_hours")} /> : null}
                <MenuButton
                  myStatus={myStatus}
                  features={["prebooking"]}
                  roles={["superadmin", "admin", "waiter"]}
                  href="/tableReservations"
                  i="i_floor"
                  background={background}
                  backgroundImage={require("../../img/reserved.jpg")}
                  name={admin_local.menus.tableBooking}
                ></MenuButton>
                {(featureEnabled("homedelivery") && auth.device.platform !== "abrowser") ?
                  <MenuButton
                    myStatus={myStatus}
                    features={["homedelivery.courierapp"]}
                    roles={["superadmin", "admin", "courier"]}
                    id="restaurants"
                    href={"ordersSetStatus"}
                    i="i_takeaway"
                    background={background}
                    backgroundImage={require("../../img/point-of-sale.jpg")}
                    name={admin_local.courier}
                  /> : null}
                <MenuButton
                  myStatus={myStatus}
                  roles={["superadmin", "admin", "bar"]}
                  href="/drinkbar"
                  id="drinkbar"
                  i="i_esspresso_machine bold"
                  background={background}
                  backgroundImage={require("../../img/bar.png")}
                  name={admin_local.menus.bar}
                ></MenuButton>
                <MenuButton
                  myStatus={myStatus}
                  roles={["superadmin", "admin", "waiter"]}
                  href="/waiterScreen"
                  id="waiterScreen"
                  i="i_hand_serving bold"
                  background={background}
                  backgroundImage={require("../../img/serving.png")}
                  name={admin_local.menus.waiterScreen}
                ></MenuButton>
                <MenuButton
                  myStatus={myStatus}
                  roles={["superadmin", "admin", "waiter"]}
                  href="/guestScreen"
                  id="guestScreen"
                  i="i_hand_serving bold"
                  background={background}
                  backgroundImage={require("../../img/serving.png")}
                  name={admin_local.menus.guestScreen}
                ></MenuButton>
                <MenuButton
                  myStatus={myStatus}
                  roles={["superadmin", "admin", "cook"]}
                  href={"/productionLinesMenu"}
                  i="i_production"
                  background={background}
                  backgroundImage={require("../../img/tracks.png")}
                  name={admin_local.menus.productionLines}
                ></MenuButton>
                <MenuButton
                  myStatus={myStatus}
                  roles={["superadmin", "admin", "finance"]}
                  href={"/reportsMenu"}
                  i="i_report"
                  background={background}
                  backgroundImage={require("../../img/reports.png")}
                  name={admin_local.menus.reports}
                ></MenuButton>
                <MenuButton
                  myStatus={myStatus}
                  features={["inventory"]}
                  roles={["superadmin", "admin", "store", "waiter"]}
                  href={"/stockManagementMenu"}
                  i="i_warehouse_inventory"
                  background={background}
                  backgroundImage={require("../../img/stockmanagement.png")}
                  name={admin_local.menus.stockManagement}
                ></MenuButton>
                <MenuButton
                  myStatus={myStatus}
                  href={"adminMenu"}
                  i="i_administration"
                  background={background}
                  backgroundImage={require("../../img/administration.jpg")}
                  error={ntakProblem}
                  name={admin_local.menus.administration}>
                  {!ntakProblem ? null : <i className="icon-attention" style={{ color: "yellow", animation: "opacity 3s infinite steps(10)" }} >ntak</i>}
                </MenuButton>
                {features?.mautic?.enabled ?
                  <MenuButton
                    href={"https://" + localStorage.instance + ".mautic.eatwithme.online"}
                    roles={["superadmin", "admin"]}
                    external={true}
                    i="icon-paper-plane-empty"
                    background={background}
                    backgroundImage={require("../../img/administration.jpg")}
                    name={admin_local.menus.mautic} myStatus={this.props.myStatus}></MenuButton>
                  : null
                }
              </React.Fragment>
            ) : (null)
            }
            <div id="updateLabel"></div>
          </div >
        </React.Fragment >
      );
    } else return null;
  }
}

const mapStateToProps = state => {
  return {
    features: state.rootReducer.myStatus.restaurant_settings ? state.rootReducer.myStatus.restaurant_settings["enabled-features"] : undefined,
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local,
    myStatus: state.rootReducer.myStatus,
    activeRestaurant: state.rootReducer.activeRestaurant,
    pointOfSales: state.rootReducer.pointOfSales ? state.rootReducer.pointOfSales : [],
  };
};

export default connect(mapStateToProps)(withRouter(MainMenu));
