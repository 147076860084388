import $ from "jquery";
import { getEntities, resultHandler } from "../admin";
import { startpoll, confirmDialog, post, get, _floatTableHead } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";


var suppliers = {
	suppliers: null
}

export const ready = (props) => {
	const def = $.Deferred();

	$("#suppliersTemplate").tmpl(tmplparams()).appendTo("#main");

	_floatTableHead("#suppliersFreezeTable");

	loadSuppliers();

	startpoll(serverSideMessageHandler);

	updateHooks();
	def.resolve();
	return def.promise();
}

export const clearData = () => {
	suppliers = {
		suppliers: null
	};
}

export const reload = () => {
	loadSuppliers();
}

function loadSuppliers() {
	getEntities("Suppliers", processSuppliers);
}

function serverSideMessageHandler(data) {
}

function processSuppliers(data) {
	data.forEach(function (v, i) {
		v.title = v.name;
		v.folder = false;
		v.key = v.id;
	});
	if (suppliers.suppliers == null)
		suppliers.suppliers = createTree("table#suppliers", {
			checkbox: false,
			titlesTabbable: true, // Add all node titles to TAB chain
			source: data,
			zindex: 1000,
			icon: false,
			extensions: ["table", "gridnav", "persist"],
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				// column index (default: nodeColumnIdx)
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},
			dblclick: (event, data) => {
				if (suppliers.clickTimestamp && suppliers.selectedNodeKey === data.node.key && new Date().getTime() - suppliers.clickTimestamp < 1000) {
					$('button#edit').click();
				};
				suppliers.clickTimestamp = new Date().getTime();
				suppliers.selectedNodeKey = data.node.key;
			},
			activate: function (event, data) {
				// A node was activated: display its title:
				var node = data.node;
				$('#edit').removeAttr('disabled');
				$('#delete').removeAttr('disabled');
				if (node.data.isActive) {
					$('#deactivate').removeAttr('disabled');
					$('#activate').prop('disabled', 'true');
				} else {
					$('#deactivate').prop('disabled', 'true');
					$('#activate').removeAttr('disabled');
				}

			},
			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				$tdList.eq(0).text(node.data.longTaxnumber);
				$tdList.eq(1).text(node.data.name);
				$($tdList.eq(2).find("input")).prop("checked", node.data.processInvoices);
				$($tdList.eq(2).find("input")).prop("id", "processInvoices" + node.data.longTaxnumber);
				$($tdList.eq(2).find("label")).prop("for", "processInvoices" + node.data.longTaxnumber);
				$($tdList.eq(2).find("input")).on("change", event => {
					post("adminService/" + sessionStorage.restaurantSelected + "/modifySupplier", { ...node.data, processInvoices: event.currentTarget.checked });
				})
				$($tdList.eq(3).find("input")).prop("checked", node.data.autoCommit);
				$($tdList.eq(3).find("input")).prop("id", "commit" + node.data.longTaxnumber);
				$($tdList.eq(3).find("label")).prop("for", "commit" + node.data.longTaxnumber);
				$($tdList.eq(3).find("input")).on("change", event => {
					post("adminService/" + sessionStorage.restaurantSelected + "/modifySupplier", { ...node.data, autoCommit: event.currentTarget.checked });
				})

			}
		});
	else {
		suppliers.suppliers.reload(data);
		data = suppliers.suppliers.getActiveNode();
		$('#edit').prop('disabled', data == null);
		$('#delete').prop('disabled', data == null);
	}
}

function updateHooks() {
	$('div#editSupplier').on('show.bs.modal', function (event) {
		var modal = $(this)
		var button = $(event.relatedTarget) // Button that triggered the modal
		var recipient = button.data('whatever') // Extract info from data-*
		// attributes
		if (recipient === 'edit') {
			var data = suppliers.suppliers.getActiveNode().data;
			modal.find('#id').val(data.id);
			modal.find('#name').val(data.name);
			modal.find('#percent').val(data.percent);
			if (data.isActive === true)
				modal.find('#active').prop("checked", 'true');
			else
				modal.find('#active').removeAttr("checked");
		} else {
			modal.find('#id').val("");
			modal.find('#name').val("");
			modal.find('#percent').val("");
			modal.find('#active').prop("checked", 'true');

		}
	})
}

export const saveSupplier = () => {
	var modal = $('div#editSupplier');
	var id = modal.find('#id').val();
	var name = modal.find('#name').val();
	if (id !== '') {
		modifySupplier({ id: id, name: name }, loadSuppliers);
	} else {
		addSupplier({ name: name }, loadSuppliers);
	}
}

export const activateSupplier = (active) => {
	var data = suppliers.suppliers.getActiveNode().data;
	modifySupplier(data.id, data.name, data.percent, active, loadSuppliers);
}

export const deleteSupplier = () => {
	var data = suppliers.suppliers.getActiveNode().data;
	confirmDialog(I18n.t("local.confirmation"), String.format(I18n.t("admin_local.are_you_sure_you_want_to_delete_the_label"), data.name)).done(function () {
		_deleteSupplier(data.id, loadSuppliers);
	});

}

function addSupplier(discountType, handler, errorHandler) {
	post("adminService/" + sessionStorage.restaurantSelected + "/addSupplier", discountType).done(function (data) { resultHandler(data, handler, errorHandler) });
}

function modifySupplier(discountType, handler, errorHandler) {
	post("adminService/" + sessionStorage.restaurantSelected + "/modifySupplier", discountType).done(function (data) { resultHandler(data, handler, errorHandler) });
}

function _deleteSupplier(id, handler, errorHandler) {
	get("adminService/" + sessionStorage.restaurantSelected + "/deleteSupplier/" + id).done(function (data) { resultHandler(data, handler, errorHandler) });
}

